import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-22/img1.png";
import img2 from "../../../images/Lectures/C19-22/img2.png";

const Eqn1 = `\\begin{align}
F_{st} &= 0.87\\:f_{yk}\\:A_s\\\\\\\\
F_{st} &= 0.87\\times 500 \\times 3220\\\\\\\\
F_{st} &= 1400.7\\:kN
\\end{align}`

const Eqn2 = `\\begin{align}
F_{cf} &= 0.567\\:f_{ck}\\:b_{\\text{eff}}\\: h_f\\\\\\\\
F_{cf} &= 0.567\\times 30\\times 500\\times 125\\\\\\\\
F_{cf} &= 1063.1\\:kN
\\end{align}`

const Eqn3 = `\\begin{align}
F_{cw} &= F_{st} - F_{cf}\\\\\\\\
F_{cw} &= 1400.4-1063.1\\\\\\\\
F_{cw} &=337.3\\:kN
\\end{align}`

const Eqn4 = `\\begin{align}
F_{cw} &= 0.567\\:f_{ck}\\:b_w\\:(s-h_f)\\\\\\\\
\\therefore s &=\\frac{F_{cw}}{0.567\\:f_{ck}\\:b_w} + h_f\\\\\\\\
s &= 175\\:mm
\\end{align}`

const Eqn5 = `\\begin{align}
x&=\\frac{s}{0.8}\\\\\\\\
x&=219\\:mm
\\end{align}`

const Eqn6 = `\\begin{align}
z_1 = d=\\frac{h_f}{2}\\\\\\\\
z_1 = 537.5\\:mm
\\end{align}`

const Eqn7 = `\\begin{align}
h_{\\text{w,comp}} &= s-h_f\\\\\\\\
h_{\\text{w,comp}} &=175-125\\\\\\\\
h_{\\text{w,comp}} &=50\\:mm
\\end{align}`

const Eqn8 = `\\begin{align}
z_2 &= d-h_f-\\frac{h_{\\text{w,comp}}}{2}\\\\\\\\
z_2 &= 600 - 125 -25\\\\\\\\
z_2 &=450\\:mm
\\end{align}`

const Eqn9 = `\\begin{align}
M_{Rd} &= F_{cf}\\times z_1 + F_{cw}\\times z_2\\\\\\\\
M_{Rd} &= 1063.1\\times 0.538 + 337.3\\times 0.45\\\\\\\\
\\end{align}`

const Eqn10 = `\\begin{equation}
\\boxed{M_{Rd} = 723.7\\:kNm}
\\end{equation}`

class Lecture_19_23 extends React.Component {
  state={
    course: 19,
    lecture: 23, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider the flanged cross-section in Fig 5 below and determine the moment of resistance for the section. Assume the following material properties, <MathJax.Node inline formula={"f_{yk}=500\\:N/mm^2"} /> and <MathJax.Node inline formula={"f_{ck}=30\\:N/mm^2"} />. Make sure to try and work through this example yourself before reading through the worked solution. Note that in this case, we have a pre-defined flange width, so there is no need to calculate <MathJax.Node inline formula={"b_{eff}"} /> using the guidance we saw in the last lecture.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Singly reinforced flanged cross-section.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >      

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>  

                  <Typography component="h3" className={classes.H3} > 2.1 Neutral axis position </Typography>            

                  <Typography paragraph className={classes.bodytext}>
                    We start by assuming that the steel has yielded at the ultimate limit state. This assumption allows us to determine the force in the steel at the point of failure, 
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can check to see what is the maximum compression force that could be developed in the flange, <MathJax.Node inline formula={"F_{cf}"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since <MathJax.Node inline formula={"F_{st}>F_{cf}"} />, we know that for equilibrium to be maintained at the ultimate limit state, the compression block must extend down into the web of the beam. The compression force that develops in the web, <MathJax.Node inline formula={"F_{cw}"} /> is,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    From here, we can calculate how far the compression block extends into the web. If we let <MathJax.Node inline formula={"s"} /> be the height of the compression block, we have,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now that we know the depth of the compression block, we can calculate the corresponding neutral axis position. Remember, for an equivalent rectangular stress block, <MathJax.Node inline formula={"s=0.8x"} />, so,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    For a ductile failure, <MathJax.Node inline formula={"x_{lim}=0.45\\:d = 0.45\\times 600 = 270\\:mm"} />. So, the cross-section is not over-reinforced, and we do have a ductile failure. We're free to move on and calculate the moment of resistance.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Moment of resistance </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    At this point, it's worth sketching out the cross-section again, along with the stress distribution diagram, force resultants and lever-arms calculated based on our analysis so far, Fig 2.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Singly reinforced flanged cross-section with stress distribution diagram.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The lever-arm between the compression force that develops in the flange and the force in the tension steel, <MathJax.Node inline formula={"z_1"} />, is,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    To work out the lever arm between the compression force in the web and the force in the steel, <MathJax.Node inline formula={"z_2"} />, we first calculate the height of the web in compression, <MathJax.Node inline formula={"h_{\\text{w,comp}}"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore <MathJax.Node inline formula={"z_2"} /> is,
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, the moment of resistance can be calculated,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>                  
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_23));
