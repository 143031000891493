import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../../../actions";
import Captcha from "../../../components/utils/Recaptcha";
import Grid from "@material-ui/core/Grid";
import Feedback from "../../../components/utils/Feedback";
import LayoutPublic from "../../../components/common/LayoutPublic";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";
import Particles from "react-particles-js";
import logoTubes from "../../../images/Landing/labs-tubes-coral.png";
import SpinningLogo from "../../../components/3D/SpinningLogo"
import CircularProgress from "@material-ui/core/CircularProgress";


const colorRed = red[500];

function Copyright() {
	return (
		<div style={{ padding: "10px" }}>
			<Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
				Copyright &copy; {new Date().getFullYear()} Mind Map Media Ltd. All rights
				reserved.
			</Typography>
		</div>
	);
}

//Config for particles.js
const particleConfig = {
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
				value_area: 800
			}
		},
		color: {
			value: "#33cc99"
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000"
			},
			polygon: {
				nb_sides: 5
			},
			image: {
				src: "img/github.svg",
				width: 100,
				height: 100
			}
		},
		opacity: {
			value: 0.06313181133058181,
			random: false,
			anim: {
				enable: false,
				speed: 0.5,
				opacity_min: 0.1,
				sync: false
			}
		},
		size: {
			value: 3,
			random: true,
			anim: {
				enable: false,
				speed: 40,
				size_min: 0.1,
				sync: false
			}
		},
		line_linked: {
			enable: true,
			distance: 150,
			color: "#33cc99",
			opacity: 0.11048066982851817,
			width: 1
		},
		move: {
			enable: true,
			speed: 2,
			direction: "none",
			random: false,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200
			}
		}
	},
	interactivity: {
		detect_on: "canvas",
		events: {
			onhover: {
				enable: false,
				mode: "repulse"
			},
			onclick: {
				enable: false,
				mode: "push"
			},
			resize: true
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1
				}
			},
			bubble: {
				distance: 400,
				size: 40,
				duration: 2,
				opacity: 8,
				speed: 3
			},
			repulse: {
				distance: 200,
				duration: 0.4
			},
			push: {
				particles_nb: 4
			},
			remove: {
				particles_nb: 2
			}
		}
	},
	retina_detect: true
};

//Custom styling
const styles = theme => ({
	particleBackground: {
    background: '#1a1a1a',
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "fixed !important",
		opacity: "100%",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -2
  },
  outsidePaper:{
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8), 
  },
	paper: {       
    background: '#25252550',    
		marginTop: theme.spacing(),
		display: "flex",
		flexDirection: "column",
    alignItems: "center",
    color:'#33cc99',
    borderRadius:'25px',    
	},
	avatar: {
		margin: '50px',
		width: '25%',
		height: '25%'
  },
  spinner: {
		color: "#33cc99"
	}
});

class CreatePassword extends React.Component {
  state = {
		renderReCaptcha: false
	};

	componentDidMount() {
    //Make sure recapcha is fully loaded before trying to render it
    this.checkRecaptcha = setInterval(() => {   
      //Check if recapcha library loaded    
      if(window.grecaptcha){    
        if(window.grecaptcha.render){        
          this.setState({renderReCaptcha: true})
          clearInterval(this.checkRecaptcha);
        }  
      }     
    }, 500);

		window.scrollTo(0, 0);
		//Reset data input errors for login form
		this.props.fetchUser();
	}

	componentWillUnmount() {
		this.props.clearFlash();
	}

	//Required when combining Matrial UI <TextField /> with redux-form
	renderTextField({ errors, id, name, input, label, ...custom }) {
		let filteredErrors = [];
		if (typeof errors !== "undefined") {
			if (errors.length > 0) {
				//Identify the relevant errors
				filteredErrors = errors.filter(err => {
					return err.param === id;
				});
			}
		}

		if (typeof filteredErrors !== "undefined") {
			if (filteredErrors.length > 0) {
				for (let i = 0; i < filteredErrors.length; i++) {
					if (filteredErrors[i].param === id) {
						return (
							<TextField
                style={{
                  backgroundColor: "#13131350"
                }}
                InputProps={{
                    style: {
                        color: "#33cc99"
                    }
                }}  
                InputLabelProps={{
                  style:{
                    color:'red'
                  }
                }}   
                color="secondary"    
								error
								label={filteredErrors[i].msg}
								id="outlined-error"
								variant="outlined"
								fullWidth
								// autoFocus
								margin="normal"
								{...input}
								{...custom}
							/>
						);
					}
				}
			}
		}
		return (
			<TextField
        style={{
          backgroundColor: "#13131350",                     
        }}
        InputProps={{
            style: {
                color: "#33cc99",                          
            }
        }}
        InputLabelProps={{
          style:{
            color:'#33cc99'
          }
        }}
        color="secondary"
				required
				id={id}
				name={name}
				label={label}
				variant="outlined"
				fullWidth
				// autoFocus
				margin="normal"
				{...input}
				{...custom}
			/>
		);
	}

	// Return error message if calidation error returned
	returnErrorText(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return (
						<Typography variant="body2" style={{ color: colorRed }}>
							{errors[i].msg}
						</Typography>
					);
				}
			}
		}
		return;
	}

  //Return recapcha component after a specified time delay
  returnReCaptcha(){
    const { classes } = this.props; //Destructure data off the components props
    if(this.state.renderReCaptcha){
      return(
        <Field type="text" name="captcharesponse" component={Captcha} />
    );
    } else{
      return(         
        <Grid container justify="center" spacing={4}>
          <Grid item xs={10} sm={10} md={12} style={{display:"flex"}}>
            <CircularProgress className={classes.spinner} />
            <Typography component="h1" style={{marginLeft:"25px", marginTop:"10px"}}>
              Waiting for recaptcha...🤖
            </Typography>
          </Grid>
        </Grid>
        
      );
    }    
  }

	render() {
		const { classes } = this.props; //Destructure data off the components props
		return (
			<React.Fragment>
				<LayoutPublic />
				<Particles className={classes.particleBackground} params={particleConfig} />
				<Feedback />

				<Grid container justify="center" spacing={4}>
					<Grid item xs={10} sm={10} md={4} lg={4}>
						<CssBaseline />

            <div className={classes.outsidePaper}>
						  <Paper className={classes.paper} elevation={2}>		

                <div >
                  <SpinningLogo/>
                </div>

								<Typography component="h1" variant="h5">
									CREATE NEW PASSWORD
								</Typography>

								<form
                  style={{marginTop:"50px"}}  
									noValidate
									onSubmit={this.props.handleSubmit(
										values => {
											const str = window.location.href;
											const token = str.split("/").pop();
											this.props.createPasswordRequest(values, token);
										} //wrapped in arrow function so it won't be called on initial load
									)}
								>
									<Grid container justify="center">
										<Grid item xs={11} sm={11}>
											<Field
												type="password"
												name="password"
												id="password"
												label="Password"
												errors={this.props.errors}
												component={this.renderTextField}
											/>

											<Field
												type="password"
												name="password2"
												id="password2"
												label="Confirm Password"
												errors={this.props.errors}
												component={this.renderTextField}
											/>

											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "14px" }}>
                          {this.returnReCaptcha()}
													{this.returnErrorText("captcharesponse")}
												</Grid>
											</Grid>

											<Grid container style={{ marginBottom: "50px" }}>
												<Grid item xs={12} sm={12} style={{ marginTop: "50px" }}>
													<Button
														size="large"
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className={classes.submit}
                            style={{ color: "white", backgroundColor:"#f13f64" }}
													>
														Submit
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
                <Copyright />
              </form>
            </Paper>
          </div>
					
          </Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

//Create a formWrapped version of Login component
const formWrapped = reduxForm({
	form: "createPasswordForm"
})(CreatePassword);

function mapStateToProps(state) {
	return { errors: state.errors };
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(formWrapped));
