import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-39/img1.jpg";

const codeString1 = 
`def plotResult(Axial_Forces=False, 
               label_offset=0.02, 
               xMargin=0.2, 
               yMargin=0.4, 
               scaleFactor=1, 
               arrowScale=0.05, 
               arrowWidth=0.01, 
               scaleDivs=30, 
               Load_Increment=100,
               Final_config=True):
        
    #Select the correct load increment to display
    if Final_config:
        #Select final load increment
        Load_Increment = -1 
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten()
    else:
        #Load increment selected based on slider
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten()
    
    fig = plt.figure() 
    axes = fig.add_axes([0.1,0.1,3,3]) 
    fig.gca().set_aspect('equal', adjustable='box')
    
    
    #Plot original nodal positions and node number labels
    for n, node in enumerate(nodes):
        axes.plot([node[0]],[node[1]],'o', color='#33cc99') 
        label = str(n+1) #The node number label string
        axes.text(node[0]+label_offset, node[1]+label_offset, label, fontsize=16) 
    
    
    #Plot applied forces
    for n, Node in enumerate(nodes):       
        node=n+1 #Node number                
        i_hor = 2*node-2 #horizontal DoF at this node
        i_ver = 2*node-1 #vertical DoF at this node                           
        ix = nodes[node-1,0] #x-coord of this node
        iy = nodes[node-1,1] #y-coord of this node
        
        #New deflected coords of each node (with scale factor applied)
        ixN = ix + ug[i_hor,0]*scaleFactor
        iyN = iy + ug[i_ver,0]*scaleFactor
        
        if abs(forceVector[i_hor])>0:
            #Horizontal force applied at this node
            arrowLength = forceVector[i_hor]/max(abs(forceVector)) * arrowScale
            plt.arrow(ixN, iyN, arrowLength, 0, width=arrowWidth, facecolor='#33cc99', 
              edgecolor="black")            
            plt.annotate(str(round(forceVector[i_hor]/1000,2))+' kN', 
              xy = (ixN+arrowLength+label_offset, iyN+label_offset),weight='bold') 
        if abs(forceVector[i_ver])>0:
            #Vertical force applied at this node
            arrowLength = forceVector[i_ver]/max(abs(forceVector))  * arrowScale
            plt.arrow(ixN, iyN, 0, arrowLength, width=arrowWidth, facecolor='#33cc99', 
              edgecolor="black")
            plt.annotate(str(round(forceVector[i_ver]/1000,2))+' kN', 
              xy = (ixN+label_offset, iyN+arrowLength+label_offset),weight='bold') 

        #🚨 Plot reaction forces
        if i_hor in restrainedIndex:
            #Horizontal reaction at this node
            arrowLength = fi[i_hor,0]/max(abs(forceVector)) * arrowScale
            plt.arrow(ixN, iyN, arrowLength, 0, width=arrowWidth, facecolor='red', 
              edgecolor="black")  
            plt.annotate(str(round(fi[i_hor,0]/1000,2))+' kN', 
              xy = (ixN+arrowLength+label_offset, iyN+label_offset),weight='bold') 
        if i_ver in restrainedIndex:
            #Vertical reaction at this node
            arrowLength = fi[i_ver,0]/max(abs(forceVector)) * arrowScale
            plt.arrow(ixN, iyN, 0, arrowLength, width=arrowWidth, facecolor='red', 
              edgecolor="black") 
            plt.annotate(str(round(fi[i_ver,0]/1000,2))+' kN', 
              xy = (ixN+label_offset, iyN+arrowLength+label_offset),weight='bold') 
        
    
    
    #Create color scale for member forces
    if(mbrForces.min(0)<0 and mbrForces.max(0)<0): 
        #All member forces are compression
        norm = matplotlib.colors.TwoSlopeNorm(vmin = mbrForces.min(0), 
                  vcenter = mbrForces.min(0)+0.5*(mbrForces.max(0)-mbrForces.min(0)),
                  vmax = mbrForces.max(0))
        cmap = plt.cm.Reds_r #Define color scale to use
        #Define max a min forces for the colorbar
        cBarMaxForce = 0
        cBarMinForce = round(0.99*mbrForces.min(0)) 
        
    elif(mbrForces.min(0)>0 and mbrForces.max(0)>0):
        #All member forces are tension
        norm = matplotlib.colors.TwoSlopeNorm(vmin=0, 
                  vcenter=mbrForces.min(0)+0.5*(mbrForces.max(0)-mbrForces.min(0)),
                  vmax=mbrForces.max(0))#set midpoint of colormap to zero
        cmap = plt.cm.Blues #Define color scale to use
        #Define max a min forces for the colorbar
        cBarMaxForce = round(0.99*mbrForces.max(0))
        cBarMinForce = 0
        
    else:
        #Tension and compression member forces develop
        norm = matplotlib.colors.TwoSlopeNorm(vmin=mbrForces.min(0), 
                  vcenter=0, 
                  vmax=mbrForces.max(0))#set midpoint of colormap to zero
        cmap = plt.cm.seismic_r #Define color scale to use
        #Define max a min forces for the colorbar
        cBarMaxForce = round(0.99*mbrForces.max(0))
        cBarMinForce = round(0.99*mbrForces.min(0))  
    
    #Add colorbar to figure and format    
    sm = plt.cm.ScalarMappable(cmap=cmap, norm=norm)    

    #Format colorbar scale
    N = scaleDivs    
    delta = 100*round(((cBarMaxForce - cBarMinForce)/N)/100) #Round to 0.1 kN
    if delta<1:
        delta = round(((cBarMaxForce - cBarMinForce)/N))
               
 
    ticks=list(np.round(np.arange(cBarMinForce, cBarMaxForce, delta)))
    cbar = fig.colorbar(sm, ticks=ticks, orientation='horizontal')
    cbar.ax.set_xlabel('Axial Force (kN)', fontsize = 16, weight='bold')  
    cbar.ax.set_xticklabels(['{:.3f}'.format(x/1000) for x in ticks], 
                        fontsize=16, 
                        weight='bold') 
    
    
    #Plot members
    for n, mbr in enumerate(members):  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member   

        ix = nodes[node_i-1,0] #x-coord of node i of this member
        iy = nodes[node_i-1,1] #y-coord of node i of this member
        jx = nodes[node_j-1,0] #x-coord of node j of this member
        jy = nodes[node_j-1,1] #y-coord of node j of this member

        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member
        
        #New deflected coords of each node (with scale factor applied)
        ixN = ix + ug[ia,0]*scaleFactor
        iyN = iy + ug[ib,0]*scaleFactor
        jxN = jx + ug[ja,0]*scaleFactor
        jyN = jy + ug[jb,0]*scaleFactor
                
        #Plot each member in undeformed and deformed position
        axes.plot([ix,jx],[iy,jy],color='#33cc99',linestyle='--',lw=2)
        if(abs(mbrForces[n])>0.001): 
            #Deformed member with 'non-zero' axial force
            axes.plot([ixN, jxN], [iyN, jyN], '-', lw=3, 
                      color=cmap(norm(mbrForces[n])), 
                      markerfacecolor='white', 
                      markeredgecolor='black')     
        else:    
            #Deformed member with 'zero' axial force
            axes.plot([ixN, jxN], [iyN, jyN],'grey',linestyle='-')
            
        #Axial force label for each member
        if Axial_Forces:
            preTen = P0[n]/1000
            axialForce = mbrForces[n]/1000 - preTen            
            label = f'{round(axialForce,2)} kN (+{round(preTen,2)} PT)'
            axes.text(ixN + 0.5*(jxN-ixN) + label_offset, 
                      iyN + 0.5*(jyN-iyN) + label_offset, 
                      label, 
                      fontsize=16,
                      weight='bold',
                      color='#33cc99',
                      bbox=dict(facecolor='black', alpha=0.7))
        
    #Housekeeping - set axis limits to provide margin around structure
    maxX = nodes.max(0)[0]
    maxY = nodes.max(0)[1]
    minX = nodes.min(0)[0]
    minY = nodes.min(0)[1]
    axes.set_xlim([minX-xMargin,maxX+xMargin])
    axes.set_ylim([minY-yMargin,maxY+yMargin])
    axes.set_xlabel('Distance (m)', fontsize=16)
    axes.set_ylabel('Distance (m)', fontsize=16)
    if reactionsFlag:
        axes.set_title('Deflected shape, axial forces & reactions 
        (All SW added to reactions - member forces ignore half of 
        SW for members connected to supports)', 
        fontsize=16, weight='bold')
    else:
        axes.set_title('Deflected shape, axial forces & reactions 
        (Half of SW of members connected to supports not added to reactions)',
        fontsize=16, weight='bold')
    axes.grid()            
    
#Call the interact widget
rows, convergedIncs = UG_FINAL.shape
widgets.interact(plotResult, 
                 label_offset=(0.01, 0.1, 0.01), 
                 xMargin=(0.2, 5, 0.2),
                 yMargin=(0.2, 5, 0.2),
                 scaleFactor=(1,5,0.5), 
                 arrowScale=(0.1,1,0.1), 
                 arrowWidth=(0.01,0.05,0.01), 
                 scaleDivs=(5,30,1), 
                 Load_Increment=(10,convergedIncs-1,1))
plt.show()`


class Lecture_17_39 extends React.Component {
  state={
    course: 17,
    lecture: 39, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The final update in this lecture is quite simple and really just a repetition of what we did in the previous lecture, with some minor edits. To add the reaction forces (lines 67 to 80), as we're cycling through each node, we need to check if the horizontal or vertical DoF index appears in the list of restrained DoFs indices. If so, then we know we need to plot a horizontal/vertical reaction force at the node. The code to accomplish this is the same as for externally applied forces. However, we extract the reaction force value from the vector of internal forces, <code className={classes.code}>fi</code>.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The same <code className={classes.code}>arrowScale</code> and <code className={classes.code}>arrowWidth</code> widgets can be used to control the size of the reaction actions. The only other difference is that we plot these reaction arrows in red, to distinguish them from externally applied force arrows.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>
                  
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Plot of the undeformed and deformed structure showing reaction forces.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    This completes our results visualisation section. We now have a code that gives us an excellent view of the structure’s behaviour and allows us to visualise how this evolves as more load is applied. This will serve us well as we proceed to analyse more complex structures in the rest of the course.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    <b>Remember that you can download the complete code for this section from the resources panel at the top of this lecture.</b>
                  </Typography>
                                                 
                </Grid>
                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_39));
