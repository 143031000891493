import { DELETE_2DTRUSS, FETCH_2DTRUSS } from "../actions/types";

export default function(state = {}, action) {
	switch (action.type) {
		case FETCH_2DTRUSS:
			return action.payload; //returns user object or false    
    case DELETE_2DTRUSS:
      return {}
		default:
			return state;
	}
}