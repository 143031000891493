import React from "react";
import { ReCaptcha  } from "react-recaptcha-google";

class Captcha extends React.Component {
	componentDidMount() {        
   //Reset the captcha
		if (this.captchaLogin) {
      this.captchaLogin.reset();
		}
	}

	onLoadRecaptcha = () => {      
		if (this.captchaLogin) {
      this.captchaLogin.reset();      
		}
  };

  //Pass recapcha token to backend via action creator
	verifyCallback = recaptchaToken => {		
		this.props.input.onChange(recaptchaToken);
	};
  
  //Return ReCaptcha component
	returnReCaptcha = () => {   
     
      return (
        <div>        
          <ReCaptcha
            ref={el => {
              this.captchaLogin = el;
            }}
            sitekey={"6LeAy9YUAAAAALyUbxyIO7W3yuFOlOcmSxNANobM"}
            theme="dark"
            size="normal"
            render="explicit"
            verifyCallback={this.verifyCallback}   
            onloadCallback={this.onLoadRecaptcha}    
            
          />
        </div>
      );             
	};

	render() {
		return (
      this.returnReCaptcha()
		);
	}
}

export default Captcha;
