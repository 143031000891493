import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-6/img1.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
y = -\\frac{H}{w_s}\\cosh\\left(-\\frac{w_s}{H}x+C_1 \\right) + C_2
\\end{equation}`

const eqn2 = `
\\begin{equation}
\\tag{2}
\\text{at } x=\\frac{L}{2}\\text{,}\\:\\:\\frac{\\mathrm{d}y}{\\mathrm{d}x}=0
\\end{equation}`

const eqn3 = `
\\begin{equation}
\\tag{3}
\\text{at } x=0\\text{,}\\:\\:y=0
\\end{equation}`

const eqn4 = `
\\begin{equation}
\\tag{4}
0=\\sinh\\left(-\\frac{w_s}{H}\\frac{L}{2} + C_1  \\right)
\\end{equation}`

const eqn5 = `
\\begin{equation}
\\tag{5}
\\boxed{
C_1 = \\frac{w_s}{H}\\frac{L}{2}
}
\\end{equation}`

const eqn6 = `
\\begin{equation}
\\tag{6}
y = -\\frac{H}{w_s}\\cosh\\left[-\\frac{w_s}{H}\\left( x-\\frac{L}{2} \\right)  \\right] + C_2
\\end{equation}`

const eqn7 = `
\\begin{equation}
\\tag{7}
0=-\\frac{H}{w_s}\\cosh\\left[-\\frac{w_s}{H}\\left(-\\frac{L}{2} \\right)\\right]+C_2
\\end{equation}`

const eqn8 = `
\\begin{equation}
\\tag{8}
\\boxed{
C_2 = \\frac{H}{w_s}\\cosh\\left(\\frac{w_s L}{2H} \\right)
}
\\end{equation}`

const eqn9 = `
\\begin{equation}
\\tag{9}
\\boxed{
y=-\\frac{H}{w_s}\\left\\{\\cosh\\left[-\\frac{w_s}{H}\\left(x-\\frac{L}{2} \\right) \\right] - \\cosh\\left(\\frac{w_s L}{2H} \\right) \\right\\}
}
\\end{equation}`



class Lecture_17_6 extends React.Component {
  state={
    course: 17,
    lecture: 6, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we're going to apply the specific boundary conditions for our cable to obtain the unknown constants of integration. This will convert the general catenary equation into a specific solution that applies to our cable and its boundary conditions. The catenary equation and cable arrangement figure are repeated below for convenience.
                  </Typography>    

                  <MathJax.Node formula={eqn1} className={classes.formula}/>                 
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Cable hanging under its own weight.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    If you're familiar with the process of applying boundary conditions to obtain constants of integration, the process here is no different. For our first boundary condition, we note that at <MathJax.Node inline formula={"x=L/2"} />, the slope of a tangent to the cable is zero,
                  </Typography>

                  <MathJax.Node formula={eqn2} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    For the second boundary condition, we note that the cable deflection is zero at supports,
                  </Typography>
                  
                  <MathJax.Node formula={eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Applying the first boundary condition to Eqn. 1,
                  </Typography>
                  
                  <MathJax.Node formula={eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore we have,
                  </Typography>

                  <MathJax.Node formula={eqn5} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Substituting <MathJax.Node inline formula={"C_1"} /> into Eqn. 1 and simplifying yields,
                  </Typography>

                  <MathJax.Node formula={eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now applying the second boundary condition to Eqn. 6,
                  </Typography>

                  <MathJax.Node formula={eqn7} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Rearranging for <MathJax.Node inline formula={"C_2"} />,
                  </Typography>

                  <MathJax.Node formula={eqn8} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we can substitute <MathJax.Node inline formula={"C_2"} /> into Eqn. 6 to obtain the catenary equation that applies for our specific set of boundary conditions,
                  </Typography>

                  <MathJax.Node formula={eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Looking at Eqn. 9 we can make some observations; if <MathJax.Node inline formula={"L"} />, <MathJax.Node inline formula={"H"} /> and <MathJax.Node inline formula={"w_s"} /> are known, <MathJax.Node inline formula={"y"} /> can be easily obtained for any position, <MathJax.Node inline formula={"x"} />, along the cable. However, say <MathJax.Node inline formula={"y_{max}"} />, the maximum cable sag, is specified and we know the cable span, <MathJax.Node inline formula={"L"} /> and self-weight <MathJax.Node inline formula={"w_s"} />. It is not trivial to determine <MathJax.Node inline formula={"H"} />  using Eqn. 9 since <MathJax.Node inline formula={"H"} /> appears on both sides of the equation and is not easy to isolate. We recognise the equation as being transcendental. As such, we would need to solve it numerically to identify the value of <MathJax.Node inline formula={"H"} /> that satisfies the equation.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    To demonstrate the numerical solution process, let's assume the following parameter values:
                  </Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}>sag, <MathJax.Node inline formula={"y_{max}=2\\:m"} /></Typography></li>
                    
                    <li><Typography paragraph className={classes.bodytext}>span, <MathJax.Node inline formula={"L=10\\:m"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>self-weight, <MathJax.Node inline formula={"w_s=100\\:kg/m = 981 \\:N/m"} /></Typography></li>
                  </ol>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We know that the horizontal component of the tension force in the cable, <MathJax.Node inline formula={"H"} />, is constant throughout the cable and that the vertical component, <MathJax.Node inline formula={"V"} />, varies along the length of the cable, with its maximum value occurring where a tangent to the cable makes its steepest angle relative to the horizontal plane. Therefore we can conclude that the maximum tension will occur at the cable supports. In the next lecture, we'll solve Eqn. 9 numerically to identify the maximum tension force in the cable.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Remember, everything we've done over the last few lectures assumes that the cable does not change shape under load. If the cable stretched appreciably, the geometry would change, which means the force in the cable would change, and the problem becomes non-linear! It's important to keep this assumption in mind because without this, Eqn. 9, which assumes linear behaviour, breaks down and becomes invalid. 
                  </Typography>                     
                              
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_6));
