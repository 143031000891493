import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
R = P\\:\\frac{L_o+u}{L_o+e} = P\\:\\frac{L_o+u}{\\sqrt{(L_o+u)^2+v^2}}
\\end{equation}`

const eqnA = `\\begin{align*}
u & \\rightarrow u+\\delta u\\\\
v & \\rightarrow v+\\delta v\\\\
P & \\rightarrow P+\\delta P\\\\
\\end{align*}`

const eqn2 = `\\begin{equation}
\\tag{2}
\\delta R = \\frac{\\partial R}{\\partial P} \\delta P + \\frac{\\partial R}{\\partial u}\\delta u + \\frac{\\partial R}{\\partial v}\\delta v
\\end{equation}`

const eqn3 = `\\begin{equation}
\\tag{3}
\\delta S = \\frac{\\partial S}{\\partial P} \\delta P + \\frac{\\partial S}{\\partial u}\\delta u + \\frac{\\partial S}{\\partial v}\\delta v
\\end{equation}`

const eqn4 = `\\begin{equation}
\\tag{4}
\\begin{bmatrix}
\\delta R \\\\
\\delta S
\\end{bmatrix} = 
\\begin{bmatrix}
\\frac{\\partial R}{\\partial P}\\\\
\\frac{\\partial S}{\\partial P}
\\end{bmatrix} [\\delta P] + 
\\begin{bmatrix}
\\frac{\\partial R}{\\partial u} & \\frac{\\partial R}{\\partial v} \\\\
\\frac{\\partial S}{\\partial u} & \\frac{\\partial S}{\\partial v}
\\end{bmatrix}
\\begin{bmatrix}
\\delta u\\\\
\\delta v
\\end{bmatrix}
\\end{equation}`

const eqn5 = `\\begin{equation}
\\tag{5}
\\begin{bmatrix}
\\delta R \\\\
\\delta S
\\end{bmatrix} = 
\\underbrace{
\\begin{bmatrix}
\\frac{L_o+u}{L_o+e}\\\\
\\frac{v}{L_o+e}
\\end{bmatrix}}_{[AA]^T}
[\\delta P] + 
\\underbrace{
\\begin{bmatrix}
\\frac{Pv^2}{(L_o+e)^3} & \\frac{-Pv(L_o+u)}{(L_o+e)^3} \\\\
\\frac{-Pv(L_o+u)}{(L_o+e)^3} & \\frac{P(L_o+u)^2}{(L_o+e)^3}
\\end{bmatrix}}_{[d]}
\\begin{bmatrix}
\\delta u \\\\
\\delta v
\\end{bmatrix}
\\end{equation}`

const eqn6 = `\\begin{equation}
\\tag{6}
[\\delta R] = [AA]^T[\\delta P] + [d][\\delta U]
\\end{equation}`

const eqn7 = `\\begin{equation}
\\tag{7}
[\\delta P] = \\frac{EA}{L_o}[\\delta e]
\\end{equation}`

const eqnB = `\\begin{equation}
[\\delta e] = [AA][\\delta U]
\\end{equation}`

const eqn8 = `\\begin{equation}
\\tag{8}
[\\delta P] = \\frac{EA}{L_o}[AA][\\delta U]
\\end{equation}`

const eqn9 = `\\begin{equation}
\\tag{9}
[\\delta R] = \\underbrace{\\left\\{[AA]^T\\frac{EA}{L_o}[AA] + [d]\\right\\}}_{[2\\times 2]\\text{ stiffness matrix}}[\\delta U]
\\end{equation}`



class Lecture_17_16 extends React.Component {
  state={
    course: 17,
    lecture: 16, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  In this lecture, we're going to consider the influence of an incremental change in <MathJax.Node inline formula={"u"} />, <MathJax.Node inline formula={"v"} /> and <MathJax.Node inline formula={"P"} /> on <MathJax.Node inline formula={"R"} /> and <MathJax.Node inline formula={"S"} />, the local element forces. This will ultimately lead us to an equation that relates <MathJax.Node inline formula={"[\\delta R]"} />, the vector of incremental change in local element forces, with <MathJax.Node inline formula={"[\\delta U]"} />, the vector of incremental change in local element displacements. The matrix that relates these two vectors is, of course, the local stiffness matrix. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  We'll start with <MathJax.Node inline formula={"R"} />, from Eqn. 12 in the previous lecture, we have,
                </Typography>

                <MathJax.Node formula={eqn1} className={classes.formula}/>
                                
                <Typography paragraph className={classes.bodytext}>
                  We recognise that <MathJax.Node inline formula={"R"} /> is a function of <MathJax.Node inline formula={"u"} />, <MathJax.Node inline formula={"v"} /> and <MathJax.Node inline formula={"P"} />, and so we can consider the influence of a simultaneous incremental change in these parameters, using the same incremental change approach we saw in the previous lecture, so if
                </Typography>

                <MathJax.Node formula={eqnA} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  we can determine the corresponding incremental change in <MathJax.Node inline formula={"R"} /> as,
                </Typography>

                <MathJax.Node formula={eqn2} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Similarly, for <MathJax.Node inline formula={"S"} />, we have,
                </Typography>

                <MathJax.Node formula={eqn3} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Combining equations 2 and 3 into a single matrix expression, we have,
                </Typography>

                <MathJax.Node formula={eqn4} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  After evaluating all of the partial derivatives, Eqn. 4 becomes,
                </Typography>
                
                <MathJax.Node formula={eqn5} className={classes.formula}/>

                <Typography paragraph className={classes.bodytext}>
                  If we write Eqn. 5 in a condensed format, we get,
                </Typography> 

                <MathJax.Node formula={eqn6} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Now, we can let,
                </Typography>

                <MathJax.Node formula={eqn7} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Recall, Eqn. 7 from the previous lecture,
                </Typography>

                <MathJax.Node formula={eqnB} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Substituting this into Eqn. 7 above, we have,
                </Typography>

                <MathJax.Node formula={eqn8} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Now we substitute Eqn. 8 into Eqn. 6 and get,
                </Typography>

                <MathJax.Node formula={eqn9} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Equation 9 links the incremental change in element local displacements, <MathJax.Node inline formula={"[u, v]"} /> with the incremental change in local element forces <MathJax.Node inline formula={"[R, S]"} />. In doing so, it takes into account the elongation <MathJax.Node inline formula={"e"} /> along a deformed element axis. It is, therefore, the local non-linear stiffness matrix we've been looking for. In the next lecture, we'll perform the final step of converting this to a global element stiffness matrix using the previously defined transformation matrix. 
                </Typography>                   
                            
                </Grid>                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_16));
