import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions/";
import Layout from "../../../components/common/Layout"; 
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TableWrapper from "../../../components/utils/TableWrapper";
import EditAccount from "./EditAccount/EditAccount";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

//Table configuration
const columns = [  
	{
		name: "email",
		label: "Subsciber email",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "expires",
		label: "Access expires",
		options: {
			filter: true,
			sort: true,      
		}
  }
];

const columns_ActiveUsers =[
  {
		name: "avatar",
		label: "Profile",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "username",
		label: "Username",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "email",
		label: "Email",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "dateJoined",
		label: "Joined",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "dateLastLoggedIn",
		label: "Last login",
		options: {
			filter: true,
			sort: true
		}
	},
];

const columns_SolverUsage = [  
	{
		name: "avatar",
		label: "Profile",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "username",
		label: "Username",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "email",
		label: "Email",
		options: {
			filter: true,
			sort: true
		}
	},
  {
    name: "truss2D",
		label: "2D Truss",
		options: {
			filter: true,
			sort: true
		}
  }
];

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5),    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {    
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	}
});

class AccessManagement extends React.Component {
  state = {
		initialAccountData: {
			email: "",
			expiry: "",		
			renderSwitch: 0
		}
	};

  componentDidMount() {  
    window.scrollTo(0, 0);

    if (this.hasAccess) {
			//Fetch data from server
			this.props.fetchCustomerData();
      this.props.fetchActiveUsers();
      // this.props.fetchSolverUsage();
		}
  }

  //Method to check user has view access
	hasAccess = () => {
		if (
			this.props.auth.email === "sean.carroll201@gmail.com" ||
			this.props.auth.email === "sean@degreetutors.com"
		) {
			return true;
		} else {
			return false;
		}
	};

  //Function to handle row click
	handleRowClick = (data, meta, index) => {    
    this.setState({
			initialAccountData: {
				email: data[0],
				expiry: data[1],
				renderSwitch: this.state.initialAccountData.renderSwitch + 1
			}
		});    
	};

  //Function to call update action creator and set table values back to empty
  onEditSubmit = values => {
    this.props.editCustomerData(values);

		this.setState({
			initialAccountData: {
				email: "",
				expiry: "",
				renderSwitch: 0
			}
		});
	};

  //Return table for editing access data
	returnEditComponent = () => {
    const { classes } = this.props;
    return (
      <EditAccount
        initialValues={this.state.initialAccountData}
        onSubmit={values => this.onEditSubmit(values)} //Pass in callback as props to handle 'Send Invite' click
      />
    );		
	};

  //Process table data and return data suitable for table display
	returnUsersTableData = data => {
    if(data){
      const { classes } = this.props; //Destructure data off the components props

      // Sort alphabetically by username
      data.sort((a, b) =>
        a.username > b.username ? 1 : b.username > a.username ? -1 : 0
      );

      return data.map(user => {
        const userData = {				
          avatar: (
            <Avatar alt={user.username} src={user.avatar} className={classes.avatar} />
          ),
          username: user.username,
          email: user.email,
          dateJoined: user.dateJoined,
          dateLastLoggedIn: user.dateLastLoggedIn
        };
        return userData;
      });
    } else {
      return []
    }
	};

  //Return solver usage data
  returnUsageTableData = data =>{
    if(data){
      const { classes } = this.props; //Destructure data off the components props
      // Sort alphabetically by username
      data.sort((a, b) =>
        a.username > b.username ? 1 : b.username > a.username ? -1 : 0
      );

      return data.map(user => {
        const userData = {				
          avatar: (
            <Avatar alt={user.username} src={user.avatar} className={classes.avatar} />
          ),
          username: user.username,
          email: user.email,
          truss2D: user.analysisTruss2D.length
        };
        return userData;
      });
    } else {
      return []
    }
  }

	render() {
    const { classes } = this.props;    
    
    //Table options
		const options = {			      
      selectableRows:false,
      download:true,			
			print: true,			
			onRowClick: (data, meta, index) => this.handleRowClick(data, meta, index) //Event hander for row click
		};
    const options_ActiveUsers = {			      
      selectableRows:false,
      download:true,			
			print: true,					
		};
    const options_SolverUsage = {			      
      selectableRows:false,
      download:true,			
			print: true,					
		};

		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={"ACCESS MANAGENENT"}
				>
          <Grid container justify="center" spacing={4} style={{paddingLeft:"5%", paddingRight:"5%"}}>   
            <Grid item xs={12} sm={12}>                          
              <Paper className={classes.paper} elevation={2}>
                <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                 
                    {this.returnEditComponent()}   
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                    <TableWrapper
                      title={"Members who can access Labs and their expiry date"}
                      data={this.props.expiryData}
                      columns={columns}
                      options={options}
                    />   
                  </Grid>  

                </Grid>
              </Paper>   
            </Grid>   

            <Grid item xs={12} sm={12}>                          
              <Paper className={classes.paper} elevation={2}>
                <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                 
                    <Grid container direction="row" display="flex" justify="space-between">
                      <Grid item>
                        <Typography
                          variant="h2"
                          align="left"
                          className={classes.subTitle}
                          color="secondary"
                        >
                          Active Users
                        </Typography>                        
                      </Grid>
                    </Grid>  
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                    <TableWrapper
                      title={"All Access Members who have registered an account"}
                      data={this.returnUsersTableData(this.props.activeUsers)}                      
                      columns={columns_ActiveUsers}
                      options={options_ActiveUsers}
                    />   
                  </Grid>  

                </Grid>
              </Paper>   
            </Grid>    

            <Grid item xs={12} sm={12}>                          
              <Paper className={classes.paper} elevation={2}>
                <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                 
                    <Grid container direction="row" display="flex" justify="space-between">
                      <Grid item>
                        <Typography
                          variant="h2"
                          align="left"
                          className={classes.subTitle}
                          color="secondary"
                        >
                          Solver Usage
                        </Typography>                        
                      </Grid>
                    </Grid>  
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                    <TableWrapper
                      title={"Solver usage by user"}
                      data={this.returnUsageTableData(this.props.activeUsers)}                      
                      columns={columns_SolverUsage}
                      options={options_SolverUsage}
                    />   
                  </Grid>  

                </Grid>
              </Paper>   
            </Grid> 

          </Grid>       
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,		
		expiryData: state.expiryData.users,			
    activeUsers: state.activeUsers.users,
    solverUsage: state.solverUsage.analysis
	};
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(AccessManagement));
