import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_11 extends React.Component {
  state={
    course: 19,
    lecture: 11, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Reinforced concrete elements resist bending through the composite action of reinforcing steel and concrete. To design for flexure, we need to fully understand this composite action and develop models that describe it. Our focus in this section will be on understanding this composite behaviour and the models that describe it. We’ll also layer on the relevant parameter limits applied by Eurocode 2.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We start by briefly discussing some of the key material properties that characterise concrete and steel individually. After this, we begin a detailed analysis of the cross-section and how an internal moment is developed in response to external loading. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Our cross-section analysis will begin with the simplest reinforced concrete section, the singly reinforced section. We’ll build on this and expand into doubly reinforced and flanged cross-sections. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    At each stage, we’ll use worked examples to demonstrate the equations and models we develop. To get the most from the worked examples, in each case, you should attempt them on your own first before I work through my solution.  You’ll learn far more this way! Also, note that I referenced <i>my</i> solution; since we’re dealing with design - there will usually be more than one valid solution. Keep this in mind as we progress.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Having a solid grasp of how to analyse the moment capacity of a reinforced concrete cross-section is fundamental to everything that we will subsequently discuss. Once this section is complete, you should be comfortable both analysing the capacity of existing sections and designing sections to meet a specified design moment. 
                  </Typography>
                                
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_11));
