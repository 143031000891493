import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-29/img1.jpg";


const codeString1 =
`i=0 #Initialise an iteration counter (zeros out for each load increment)
inc=0 #Initialise load increment counter
notConverged = True #Initialise convergence flag

while notConverged and i<10000: 
    
    #Calculate the cumulative internal forces Fi_total = Fa + Fb + Fc + ...    
    Fi_total = np.matrix(np.sum(F_inc,axis=1)).T #Sum across columns of F_inc     
        
    #Calculate the cumulative incremental displacements UG_total = Xa + Xb + Xc + ...
    UG_total = np.matrix(np.sum(UG_inc,axis=1)).T #Sum across columns of UG_inc  
   
    #Inequilibrium force vector used in this iteration F_EXT - Fi_total 
    F_inequilibrium = forceVector - Fi_total  
            
    #Build structure stiffness matrix based on current position
    Ks = buildStructureStiffnessMatrix(UG_total)     

    #Solve for global (incremental) displacement vector [Xn] for this iteration
    UG = solveDisplacements(Ks, F_inequilibrium)
    
    #Calculate a new transformation matrix for each member
    TMs = calculateTransMatrices(UG_total)
    
    #Calculate the internal force system based on new incremental displacements, [Fn]
    F_int = updateInternalForceSystem(UG)       
        
    #Save incremental displacements and internal forces for this iteration
    UG_inc = np.append(UG_inc, UG, axis=1)
    F_inc = np.append(F_inc, F_int, axis=1)       
        
    #Test for convergence
    notConverged = testForConvergence(i, convThreshold, F_inequilibrium)
    
    i+=1
    
    #If converged, save displacements, forces and increment external loading
    if not notConverged: 
        inc +=1
        
        """
        TO DO:
        Save a 'Snapshot' of the system at this converged load increment
        - Save displacements 
        - Save internal force system
        - Save member axial forces
        - Save the external forces
        
        - Add another external force increment
        - Reset the notConverged flag
        - Reset the iteration counter
        """`

const codeBlock2 = 
`#Constants
E = 70*10**9 #(N/m^2) Young's modulus 
A = 0.0025 #(m^2) Cross-sectional area 

gamma = 0 #(kg/m) Cable mass per unit length
Areas = A*np.ones([len(members)]) #Array to hold individual member area
P0 = 0*np.ones([len(members)]) #Array to hold individual member pre-tension
P = -10000 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

convThreshold = 30 #(N) Threshold on maximum value of F_inequilibrium
nForceIncrements = 1000 #🚨 The number of force increments`

const codeBlock3 = 
`#Container to hold the set of global displacements for each external load increment
UG_FINAL = np.empty([nDoF,0])

#Container to hold the set of internal forces for each external load increment
FI_FINAL = np.empty([nDoF,0])

#Container to hold the set of axial forces for each external load increment
EXTFORCES = np.empty([nDoF,0])

#Container to hold the set of axial forces for each external load increment
MBRFORCES = np.empty([len(members),0])`

const codeBlock4 = 
`forceIncrement = forceVector/nForceIncrements #Force increment for each convergence test
maxForce = forceVector #Define a vector to store the total external force applied
forceVector = forceIncrement #Initialise the forceVector to the first increment of load`

const codeBlock5 =
`i=0 #Initialise an iteration counter (zeros out for each load increment)
inc=0 #Initialise load increment counter
notConverged = True #Initialise convergence flag

while notConverged and i<10000: 
    
    #Calculate the cumulative internal forces Fi_total = Fa + Fb + Fc + ...    
    Fi_total = np.matrix(np.sum(F_inc,axis=1)).T #Sum across columns of F_inc     
        
    #Calculate the cumulative incremental displacements UG_total = Xa + Xb + Xc + ...
    UG_total = np.matrix(np.sum(UG_inc,axis=1)).T #Sum across columns of UG_inc  
   
    #Inequilibrium force vector used in this iteration F_EXT - Fi_total 
    F_inequilibrium = forceVector - Fi_total  
            
    #Build structure stiffness matrix based on current position
    Ks = buildStructureStiffnessMatrix(UG_total)     

    #Solve for global (incremental) displacement vector [Xn] for this iteration
    UG = solveDisplacements(Ks, F_inequilibrium)
    
    #Calculate a new transformation matrix for each member
    TMs = calculateTransMatrices(UG_total)
    
    #Calculate the internal force system based on new incremental displacements, [Fn]
    F_int = updateInternalForceSystem(UG)       
        
    #Save incremental displacements and internal forces for this iteration
    UG_inc = np.append(UG_inc, UG, axis=1)
    F_inc = np.append(F_inc, F_int, axis=1)       
        
    #Test for convergence
    notConverged = testForConvergence(i, convThreshold, F_inequilibrium)   
    
    i+=1
        
    #🚨 If converged, save displacements, forces and increment external loading
    if not notConverged: 
        inc +=1
        print(f'System has converged for load increment {inc} after {i-1} iterations')
        
        #Save displacement data for this increment and prep for next one
        UG_FINAL = np.append(UG_FINAL, UG_total, axis=1) 
        UG_inc = np.empty([nDoF,0]) 
        UG_inc = np.array(np.append(UG_inc, UG_total, axis=1)) 

        #Save force data for this increment and prep for next one
        FI_FINAL = np.append(FI_FINAL, Fi_total, axis=1) 
        F_inc = np.empty([nDoF,0]) 
        F_inc = np.array(np.append(F_inc, Fi_total, axis=1)) 
        
        #Save snapshot of member axial forces for this increment
        mbrForces = calculateMbrForces(UG_FINAL[:,-1]) 
        MBRFORCES = np.append(MBRFORCES, np.matrix(mbrForces).T, axis=1) 
        
        #Save snapshot of externally applied forces for this increment
        EXTFORCES = np.append(EXTFORCES, forceVector, axis=1) 
          
        #Test if all external loading has been applied
        if abs(sum(forceVector).item()) < abs(sum(maxForce).item()):
            i=0 #Reset counter for next load increment
            forceVector = forceVector + forceIncrement #Increment load 
            notConverged = True #Reset notConverged flag
                 `

class Lecture_17_29 extends React.Component {
  state={
    course: 17,
    lecture: 29, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll implement a key feature; the ability to break our applied force into multiple load increments. This is a key feature because if we want our structures to converge, we need to apply the total load in smaller increments and seek convergence for each increment. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Recall that back in lecture 21, when we were blocking out our main convergence loop, we implemented an <code className={classes.code}>if</code> condition that would only execute if our structure had converged. At the time, we just put a multi-line comment in and said we'd return to it later. Well, the time has come! For convenience, I've repeated the main convergence loop in the code block below.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock> 

                  <Typography paragraph className={classes.bodytext}>
                    Now, we'll deal with the <i>to do</i> list of items in the multi-line comment. In broad terms, the task is as follows; when the structure has converged, and we actually enter this conditional block of code, we need to save the current state of the structure - this means the current set of displacements and applied forces. Saving a snapshot like this will allow us to visualise the structure for different levels of the applied load.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We also need to add another load increment and continue our iterations to try and reach convergence again between the new external force system and internal forces induced by the new displacements. These are the last big modifications we need to make to our solver. After this, we'll have something we can actually run!
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll start off by adding a new simulation parameter, <code className={classes.code}>nForceIncrements</code>. As you might have guessed, this is the number of force increments we want to break the total applied loading into. We can add it to the manual data entry block, repeated below.
                  </Typography>

                  <CodeBlock>{codeBlock2}</CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Initialise data containers to hold converged data for each external load increment </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we need to initialise some data containers to store the snapshot data for each load increment. Remember, we already defined containers to hold data from each <b>analysis iteration</b>, now, we want to define the containers that will store the data for the structure from each converged <b>load increment</b>. We can include these in a block, after all of our function definitions but before the main convergence loop. We're defining four empty arrays in total.
                  </Typography>

                  <CodeBlock>{codeBlock3}</CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Break up the external force vector into increments </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now we need to break up the vector of external forces, <code className={classes.code}>forceVector</code>, which we built in lecture 19. First, we'll determine an incremental force vector by simply dividing the <code className={classes.code}>forceVector</code> by the number of force increments specified at the top of the code, <code className={classes.code}>nForceIncrements</code> (line 1).
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Then, we define a new variable called <code className={classes.code}>maxForce</code> which will store the original full force vector (line 2). Finally, we can redefine <code className={classes.code}>forceVector</code> to be equal to <code className={classes.code}>forceIncrement</code>, defined on line one. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This way, the first vector of loading that's applied to the structure is actually the first increment of loading. When the structure has converged for this applied load, we can simply add another <code className={classes.code}>forceIncrement</code> to <code className={classes.code}>forceVector</code> and start iterating towards equilibrium all over again. All of this needs to be done just before we enter the main execution loop.
                  </Typography>

                  <CodeBlock>{codeBlock4}</CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Updating the main execution loop </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can update the main execution loop itself. I've repeated it below, but we'll focus only on the code inside the <code className={classes.code}>if not</code> condition from line 38 down. So, we will only find ourselves inside this code block if our convergence test has been passed and the structure is in equilibrium (based on our convergence threshold). 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first thing we do is add <code className={classes.code}>+1</code> to our load increment counter, <code className={classes.code}>inc</code>. Next, we can work through saving our data snapshots. On line 43, we’re appending the current set of displacements, <code className={classes.code}>UG_total</code> as a new column in our snapshot container, <code className={classes.code}>UG_FINAL</code>. On line 44, we reinitialise the vector of incremental displacements, <code className={classes.code}>UG_inc</code>, ready to start storing data on our next round of analysis iterations. Then, on line 45, we append the current set of displacements as the first column within <code className={classes.code}>UG_inc</code>. Recall that when we first set up <code className={classes.code}>UG_inc</code> back in lecture 21, we appended a column of zeros here because the displacement at that time (before the simulation started) was zero. Clearly, it's not zero anymore. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We repeat this pattern on lined 48-50 to record a snapshot of the internal force system and reinitialise our containers for the next round of analysis iterations. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On line 53, we call the function to calculate member axial forces that we wrote in the last lecture. Then we save this data on line 54. The final snapshot saving operation takes place on line 57, where we save the current value of the <code className={classes.code}>forceVector</code>. We could work backwards at the end to figure out what the force vector was at this load increment, but it's slightly more convenient (albeit less efficient) to just save it now. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    All that remains to do now is set ourselves up for the next load increment. Before we do this, we check if we still have another load increment to apply (line 60). If all of the loading has not yet been applied, we reset the analysis iteration counter, <code className={classes.code}>i</code>, add another <code className={classes.code}>forceIncrement</code> to <code className={classes.code}>forceVector</code> and reset the <code className={classes.code}>notConverged</code> flag to <code className={classes.code}>True</code>. By resetting the flag, the parent <code className={classes.code}>while</code> loop entry condition will be met, and we'll continue iterating on the structure. 
                  </Typography>

                  <CodeBlock>{codeBlock5}</CodeBlock> 

                  <Typography paragraph className={classes.bodytext}>
                    At this point, we've fully implemented the logic required to analyse our structure. All that remains to do now is to try and run the code. If you've watched the video lecture above you'll know that one of the downsides of writing our code the way we have, is that we haven't been able to run it since lecture 20. This means that all of the typos one usually makes in coding are saved up until now! So, if you're about to run your own code - be prepared for some typos, unless you've copied your code directly from these lectures in which case you shouldn’t have any typos. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So, provided all minor typos have been eliminated, running your Jupyter Notebook from top to bottom should result in a series of output statements for each converged load increment, Fig 1. 
                  </Typography>
                        
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Output print statements generated by the main loop.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Great - this means that our code is working...or at least it's running. But we haven't actually investigated the data it generates yet. So, in the next lecture, we'll write some code to generate a text summary of the data. This will give us our first indication as to whether or not the code is functioning as we expect. 
                  </Typography>
                                             
                </Grid>                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_29));
