import { FETCH_ACTIVE_USERS } from "../actions/types";

export default function(state = {}, action) {
	switch (action.type) {
		case FETCH_ACTIVE_USERS:
			return action.payload; //returns user object or false    
		default:
			return state;
	}
}
