import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_2 extends React.Component {
  state={
    course: 17,
    lecture: 2, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    In this short lecture, I want to give you an idea of what you should cover before taking this course. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Much of what we cover in this course is about how to implement an iterative solver for the tricky problem of geometric non-linearity. This essentially involves implementing and updating the direct stiffness method in a loop. So, you should have a good understanding of the direct stiffness method for axially loaded elements. I’ll rely on the fact that you understand how this matrix-based analysis method works and won’t spend time explaining this in any great detail.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In fact, I’d probably go further and say even if you have knowledge of the direct stiffness method - you should take my course on applying the direct stiffness method to truss structures. That course is called, <Link to="/course-11/lecture-1" className={classes.link} target="_blank">The Direct Stiffness Method for Truss Analysis with Python</Link> and it’s my entry point into matrix-based structural analysis. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The reason I strongly recommend completing that course first is that a lot of the code we write in this course, for example, how to build the overall structure stiffness matrix, solving for displacements and basically all of the nuts and bolts of the basic direct stiffness method, was written and explained in more detail in that course. You’ll lower the cognitive load on yourself if you encounter this code in a less demanding context first. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The next question to address is how much Python do you need to know? Usually, I say before all of my courses that you don’t need to know Python, you can pick it up while taking the course. Technically this is still true for this course. However, because there is a lot to contend with in this course, I would say trying to learn Python on top is going to be quite demanding. It’s not that the coding we’ll do is any more complex than what we’ve done in previous courses, it’s just about managing your mental workload.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    This is another great reason to take the pre-requisite course. Get familiar with Python and how I implement the direct stiffness method in that introductory course first. This will leave you in a great position to tackle this course and really focus on what’s new and challenging about analysing non-linear structures.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Ok, that’s about all we need to say on prerequisites. If you’ve made it to this point and are happy that this course is right for you, let’s get stuck into it.   
                  </Typography>           
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_2));
