import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_25 extends React.Component {
  state={
    course: 19,
    lecture: 25, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this section, our aim is to develop a means of safely designing shear reinforcement. Essentially we will build a mechanical model that can describe the mechanism of shear resistance in concrete beams. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We start by first discussing crack formation in concrete beams by drawing the link between applied loading, principal stresses and the formation of cracks in different regions of a beam. When we understand the fundamental mechanics behind crack formation, we can start to think about how we might reinforce a beam to mitigate against it.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The model of shear resistance we will develop is called the <b>Variable Strut Inclination Model</b>. We’ll build this model using only basic mechanics. Once established, the model will allow us to identify a suitable arrangement of shear reinforcement. As usual, we’ll use the model to complete several design examples, which again, you should try and tackle on your own first. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the previous section, we discussed flanged cross-sections. One feature of this structural form is the development of complementary shear stresses at the beam’s web-flange interface. We’ll finish this section by exploring how the variable strut inclination model can be applied to design shear reinforcement to resist these interface shear stresses. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Once this section is complete, you will be comfortable designing shear reinforcement for reinforced concrete beams. Note that, just like the previous section on bending - although we focus on beam design, the models used will carry over into many other element design procedures. As such, this section is another fundamental building block in the study of reinforced concrete design. 
                  </Typography>                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_25));
