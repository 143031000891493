import axios from "axios";
import history from "../history";
import {  FETCH_FLASH, 
          FETCH_ERRORS, 
          FETCH_CUSTOMER_DATA, 
          FETCH_ACTIVE_USERS, 
          FETCH_SOLVER_USAGE,
          FETCH_COMPLETEDLECTURES } from "./types";

// ========================================================
export const clearFlash = () => {
	return {
		type: FETCH_FLASH,
		payload: {
			type: "",
			message: ""
		}
	};
};

// ========================================================
export const setFlash = flash => {
	return {
		type: FETCH_FLASH,
		payload: {
			type: flash.type,
			message: flash.message
		}
	};
};

// ========================================================
export const betaRequest = values => async dispatch => {
	const res = await axios.post("/api/betarequest", values);

	//Destructure returned data
	const { flash, errors } = res.data;

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}
};

// ========================================================
export const sendFeedback = values => async dispatch => {
	const res = await axios.post("/api/userfeedback", values);

	//Destructure returned data
	const { flash, errors } = res.data;

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}
};

// ========================================================
export const fileDownload = (course, lecture, filename, isProject) => async dispatch =>{
  await axios.post("/api/fileDownload", {
    course,
    lecture,
    filename,
    isProject
  })
  .then((response)=>{    
    const link = document.createElement('a');
    link.href = response.data;
    link.setAttribute('download', `Name`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  })  
}

// ========================================================
export const download3DSpaceTemplate = () => async dispatch =>{ 
  await axios.post("/api/download3DTrussTemplate")
  .then((response)=>{    
    const link = document.createElement('a');
    link.href = response.data;
    link.setAttribute('download', `Name`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  })  
}

// ========================================================
export const fetchCustomerData = () => async dispatch => {
	const res = await axios.get("/api/management/fetchcustomerdata");
  
  //Dispatch an action to reducer
	dispatch({ type: FETCH_CUSTOMER_DATA, payload: res.data });
};

// ========================================================
export const editCustomerData = values => async dispatch => {
	let res = await axios.patch("/api/management/editcustomerdata", values);
  let { errors } = res.data;

  //Fetch the full updated customer list
  res = await axios.get("/api/management/fetchcustomerdata");
  //Dispatch an action to reducer
	dispatch({ type: FETCH_CUSTOMER_DATA, payload: res.data });
  
	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}
};

// ========================================================
export const fetchActiveUsers = () => async dispatch => {
	const res = await axios.get("/api/management/fetchactiveusers");
  
  //Dispatch an action to reducer
	dispatch({ type: FETCH_ACTIVE_USERS, payload: res.data });
};

// ========================================================
export const fetchSolverUsage = () => async dispatch => {
	const res = await axios.get("/api/management/fetchsolverusage");
  
  //Dispatch an action to reducer
	dispatch({ type: FETCH_SOLVER_USAGE, payload: res.data });
};

// ========================================================
export const certificateRequest = values => async dispatch => {    
	const res = await axios.post("/api/management/certificaterequest", values);
  
	//Destructure returned data
	const { flash, errors } = res.data;

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	dispatch({ type: FETCH_ERRORS, payload: errors });

	//Programmatic navigation here  
	if (errors.length < 1) {
		history.push("/revcertrequestsubmitted");
	}
  
};

// ========================================================
export const completeLecture = (format, course, lecture) => async dispatch => {          
  const res = await axios.post("/api/management/completelecture", {format, course, lecture});
  
	//Destructure returned data
	const { data, flash } = res.data;   

  if (data){    
    dispatch({ type: FETCH_COMPLETEDLECTURES, payload: data });	 
  }	 
  if(flash)  {
    dispatch({ type: FETCH_FLASH, payload: flash });
  }  
};

// ========================================================
export const undoCompleteLecture = (format, course, lecture) => async dispatch => {          
  const res = await axios.post("/api/management/undocompletelecture", {format, course, lecture});
  
	//Destructure returned data
	const { data, flash } = res.data;   

	if (data){    
    dispatch({ type: FETCH_COMPLETEDLECTURES, payload: data });	 
  }	 
  if(flash)  {
    dispatch({ type: FETCH_FLASH, payload: flash });
  }  
};

// ========================================================
export const fetchCompletions = () => async dispatch => {          
  const res = await axios.get("/api/management/fetchcompletions");
  
	//Destructure returned data
	const { data } = res.data;   

  if (data){
    // Dispatch data messaging	
    dispatch({ type: FETCH_COMPLETEDLECTURES, payload: data });	 
  }	 
};

