import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-11/img1.png";
import img2 from "../../../images/Lectures/C17-11/img2.png";
import img3 from "../../../images/Lectures/C17-11/img3.png";
import img4 from "../../../images/Lectures/C17-11/img4.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `\\begin{equation}
[X] = [X_A] + [X+B] + [X_C]
\\end{equation}`


class Lecture_17_11 extends React.Component {
  state={
    course: 17,
    lecture: 11, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

              <Grid item xs={12} sm={12} md={10} >                  
                <Typography paragraph className={classes.bodytext}>
                  In this lecture, we'll discuss the iterative process that we use to minimise the force imbalance in our structure. Our approach is based on the Newton-Raphson method. Rather than discussing the Newton-Raphson method in the abstract, we'll explain it in the context of our particular problem. By the end of this lecture, you'll understand, in principle, how we will solve for the behaviour of our non-linear structures.
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  We start with a simple force versus deflection graph showing non-linear softening, Fig 1. Let's imagine that the structure experiences some vector of forces indicated here by <MathJax.Node inline formula={"[F_{EXT}]"} />, and as a result, the final deflection is represented by the vector or displacements <MathJax.Node inline formula={"[X]"} />. Our task now is to determine this final vector of displacements.
                </Typography>                                            
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img1} />                  
                <figcaption className={classes.caption}>Fig 1. Force-displacement graph for a structure exhibiting non-linear softening.</figcaption>
              </figure>  


              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  We can start by calculating the initial stiffness of the structure. This will be a tangent to the curve at the origin and is represented in Fig. 2 by <MathJax.Node inline formula={"[K_0]"} />. When we apply the external forces, <MathJax.Node inline formula={"[F_{EXT}]"} />, to the structure characterised by stiffness <MathJax.Node inline formula={"[K_0]"} />, this will yield a set of displacements, <MathJax.Node inline formula={"[X_A]"} />.
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  Similarly to the simplified structure in the previous lecture, this set of displacements can be used to calculate an internal force vector, <MathJax.Node inline formula={"[F_A]"} />. Now, if the structure was linear, <MathJax.Node inline formula={"[F_A]"} /> would equal <MathJax.Node inline formula={"[F_{EXT}]"} />, and we would be done. But since the structure is exhibiting non-linear behaviour, <MathJax.Node inline formula={"[F_{EXT}\\neq [F_A]"} />. As a result, there is a residual force imbalance indicated in Fig. 2 as <MathJax.Node inline formula={"[F_{EXT}]-[F_A]"} />.
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  At this point, we can calculate an updated stiffness matrix for the structure, <MathJax.Node inline formula={"[K_A]"} />, that takes into account its new displaced shape. This is represented by a new tangent to the curve at the position of the green dot in Fig. 2. This marks the end of the first iteration.
                </Typography>
              
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img2} />                  
                <figcaption className={classes.caption}>Fig 2. Force-displacement graph showing initial structural stiffness and detailing analysis iteration 1.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  Remember, ultimately we must have agreement between the internal and external force systems. So, iteration two begins with us applying the residual force imbalance, <MathJax.Node inline formula={"[F_{EXT}]-[F_A]"} />, to the structure with updated stiffness <MathJax.Node inline formula={"[K_A]"} />, Fig. 3. This results in a further set of deflections, <MathJax.Node inline formula={"[X_B]"} />. These can then be used to calculate an additional set of internal forces, <MathJax.Node inline formula={"[F_B]"} />, allowing the residual force imbalance to be reduced to <MathJax.Node inline formula={"[F_{EXT}] - [F_A] - [F_B]"} />. The structure stiffness matrix can be updated once again to <MathJax.Node inline formula={"[K_B]"} />. 
                </Typography>                                   
                            
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img3} />                  
                <figcaption className={classes.caption}>Fig 3. Force-displacement graph showing updated structural stiffness and detailing analysis iteration 2.</figcaption>
              </figure>

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  Notice that in each iteration, as we are updating the stiffness estimate, we are more closely approximating the root of the non-linear curve. Here, we're defining the root as the intersection between the curve and the horizontal dashed line at <MathJax.Node inline formula={"[\\bar{F}]=[F_{EXT}]"} />. This is how the Newton-Raphson method works; by projecting forward with tangents to the function and, in doing so, converging on the root of the function.
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  At the end of iteration two, we have reduced the force imbalance to <MathJax.Node inline formula={"[F_{EXT}] - [F_A] - [F_B]"} /> and accumulated an overall set of deflections given by <MathJax.Node inline formula={"[X_A] +[X_B]"} />. The iteration process will be repeated again, further reducing the force imbalance and adding to the accumulated set of deflections, Fig. 4. This will continue until the force imbalance has become negligibly small and, for all practical purposes, the internal and external force vectors are in equilibrium. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  At this point, the final set of displacements for the structure is obtained as the sum of all incremental displacements from each iteration. In Fig 4. this is indicated as,
                </Typography>

                <MathJax.Node formula={eqn1} className={classes.formula}/>

                <Typography paragraph className={classes.bodytext}>
                  We can see how closely the iterative process has managed to approximate the <i>true</i> behaviour of the structure by how close the green dot in Fig. 4 lies to the theoretical root of the curve, as defined above. 
                </Typography>
                                
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img4} />                  
                <figcaption className={classes.caption}>Fig 4. Force-displacement graph showing updated structural stiffness and detailing analysis iteration 3.</figcaption>
              </figure>

              <Grid item xs={12} sm={12} md={10} >                  
                
                <Typography paragraph className={classes.bodytext}>
                  By building an algorithm to implement this strategy, we'll be able to approximate non-linear behaviour reasonably well. Now, what we've discussed above has all been at a very high level. There are various steps in the process that I have intentionally brushed over for the sake of clarifying the overall picture. Probably the biggest question at this stage is <i>how do we update the stiffness matrix to take into account the displaced shape of the structure?</i>  We're going to cover this in great detail in the next section. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  Another important point to make here is that everything we've discussed above relates to the application of a single increment of loading. In reality, we will almost always need to break up the applied loading into smaller increments. Then, for each load increment, we will seek convergence as discussed above. Once convergence is achieved, i.e. the force imbalance has been sufficiently minimised, we apply another increment of load and repeat the process. Therefore, we introduce the concept of multiple analysis <b>iterations</b> within a single load <b>increment</b>. Understanding the distinction between iterations and increments will be important as we move on to writing code. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  Breaking the applied load into smaller increments and treating each increment as its own convergence exercise greatly increased the likelihood that our structure will converge and equilibrium will ultimately be achieved. Generally, if we apply all of the loading in a single increment, the force imbalance will not continue to reduce on successive analysis iterations, and convergence won't be achieved. Like any numerical solution process, a balance must be struck between computational efficiency and simulation accuracy and convergence. 
                </Typography>                                   
              
              </Grid>
                
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_11));
