import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../../../actions";
import Captcha from "../../../components/utils/Recaptcha";
import Grid from "@material-ui/core/Grid";
import Feedback from "../../../components/utils/Feedback";
import LayoutPublic from "../../../components/common/LayoutPublic";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import logo from "../../../images/Landing/DTLabs_logo_small.png";
import logoTubes from "../../../images/Landing/labs-tubes-coral.png";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";
import Particles from "react-particles-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import SpinningLogo from "../../../components/3D/SpinningLogo"
import CheckIcon from '@material-ui/icons/Check';
import pavillion from '../../../images/Landing/Pavillion.png'
import { useMediaQuery } from "react-responsive";

//Media Queries
const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1281 });
	return isDesktop ? children : null;
};
const Laptop = ({ children }) => {
	const isLaptop = useMediaQuery({ minWidth: 992, maxWidth: 1280 });
	return isLaptop ? children : null;
};
const Tablet = ({ children }) => {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	return isTablet ? children : null;
};
const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile ? children : null;
};

const colorRed = red[500];

function Copyright() {
	return (
		<div style={{ padding: "10px" }}>
			<Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
				Copyright &copy; {new Date().getFullYear()} Mind Map Media Ltd. All rights
				reserved.
			</Typography>
		</div>
	);
}

//Config for particles.js
const particleConfig = {
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
				value_area: 800
			}
		},
		color: {
			value: "#33cc99"
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000"
			},
			polygon: {
				nb_sides: 5
			},
			image: {
				src: "img/github.svg",
				width: 100,
				height: 100
			}
		},
		opacity: {
			value: 0.06313181133058181,
			random: false,
			anim: {
				enable: false,
				speed: 0.5,
				opacity_min: 0.1,
				sync: false
			}
		},
		size: {
			value: 3,
			random: true,
			anim: {
				enable: false,
				speed: 40,
				size_min: 0.1,
				sync: false
			}
		},
		line_linked: {
			enable: true,
			distance: 150,
			color: "#33cc99",
			opacity: 0.11048066982851817,
			width: 1
		},
		move: {
			enable: true,
			speed: 2,
			direction: "none",
			random: false,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200
			}
		}
	},
	interactivity: {
		detect_on: "canvas",
		events: {
			onhover: {
				enable: false,
				mode: "repulse"
			},
			onclick: {
				enable: false,
				mode: "push"
			},
			resize: true
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1
				}
			},
			bubble: {
				distance: 400,
				size: 40,
				duration: 2,
				opacity: 8,
				speed: 3
			},
			repulse: {
				distance: 200,
				duration: 0.4
			},
			push: {
				particles_nb: 4
			},
			remove: {
				particles_nb: 2
			}
		}
	},
	retina_detect: true
};

//Custom styling
const styles = theme => ({
	particleBackground: {
    background: '#1a1a1a',
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "fixed !important",
		opacity: "100%",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -2
  },  
  outsidePaper:{
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8), 
  },
	paper: {       
    background: '#25252550',    
		marginTop: theme.spacing(),
		display: "flex",
		flexDirection: "column",
    alignItems: "center",
    color:'#33cc99',
    borderRadius:'25px', 
    backdropFilter: "blur( 4px )",     
	},
	avatar: {
		margin: '50px',
		width: '25%',
		height: '25%'
  },
  spinner: {
		color: "#33cc99"
	},
	rightPaper: {       
    background: '#25252550',    
		marginTop: theme.spacing(),
		display: "flex",
		flexDirection: "column",    
    color:'#33cc99',
    borderRadius:'25px',  
    padding:"50px",
    boxShadow: "0 8px 32px 0 rgba( 51, 204, 153, 0.15 )",
    backdropFilter: "blur( 4px )",  
	},
  rightTitle:{
    color: theme.palette.coral.main,
    fontSize:"2.5rem",     
    textAlign:"center",
    fontFamily: ['"Barlow"', 'SansSerif'].join(',')
  },
  rightSubtitle:{
    fontSize:"1.75rem",
    fontWeight:"bold",
    color: "#fff",
    fontFamily: ['"Barlow"', 'SansSerif'].join(','),
    textAlign:"center",
    marginBottom:"50px"
  },
  bullet:{
    fontSize:"1.2rem",
    color: "#fff",
    textAlign:"left",
    marginBottom:"20px"
  },
  tickIcon:{
    color: "#33cc99", 
    fontSize:"1.5rem",
    marginRight:"10px"
  },
  link:{
    color: "#33cc99",
		"&:hover": {
			color: "#33cc99"
		},
    textDecoration: "underline" 
  },
  headerImage:{
    width:"100%",
    marginTop:"-10px",
    marginBottom:"30px"
  },
  quote:{
    background: '#25252550',
    border: "0.5px solid #33cc99",
    borderRadius:"10px",
    fontSize:"1.1rem",
    textAlign:"center",
    marginBottom:"20px",
    marginTop:"30px",
    padding:"5px"
  }

});

class Login_V2 extends React.Component {
  state = {
		renderReCaptcha: false
	};

	componentDidMount() {    
    //Make sure recapcha is fully loaded before trying to render it
    this.checkRecaptcha = setInterval(() => {   
      //Check if recapcha library loaded    
      if(window.grecaptcha){    
        if(window.grecaptcha.render){        
          this.setState({renderReCaptcha: true})
          clearInterval(this.checkRecaptcha);
        }  
      }     
    }, 500);

		window.scrollTo(0, 0);
		//Reset data input errors for login form
		this.props.fetchUser();    
	}

	componentWillUnmount() {
		this.props.clearFlash();
	}

	//Send login request
	onSubmit = values => {    
		this.props.loginRequest(values);
	};

	//Required when combining Matrial UI <TextField /> with redux-form
	renderTextField({ errors, id, name, input, label, ...custom }) {

		let filteredErrors = [];
		if (typeof errors !== "undefined") {
			if (errors.length > 0) {
				//Identify the relevant errors
				filteredErrors = errors.filter(err => {
					return err.param === id;
				});
			}
		}

		if (typeof filteredErrors !== "undefined") {
			if (filteredErrors.length > 0) {
				for (let i = 0; i < filteredErrors.length; i++) {
					if (filteredErrors[i].param === id) {
						return (
              <TextField
                style={{
                  backgroundColor: "#13131350"
                }}
                InputProps={{
                    style: {
                        color: "#33cc99"
                    }
                }}  
                InputLabelProps={{
                  style:{
                    color:'red'
                  }
                }}   
                color="secondary"                           
								error
								label={filteredErrors[i].msg}
								id="outlined-error"
								variant="outlined"
								fullWidth
								// autoFocus
								margin="normal"
								{...input}
								{...custom}
							/>
						);
					}
				}
			}
		}
		return (
			<TextField
        style={{
            backgroundColor: "#13131350",                     
        }}
        InputProps={{
            style: {
                color: "#33cc99",                          
            }
        }}
        InputLabelProps={{
          style:{
            color:'#33cc99'
          }
        }}
        color="secondary"
				required
				id={id}
				name={name}
				label={label}
				variant="outlined"
				fullWidth
				// autoFocus
				margin="normal"
				{...input}
				{...custom}
			/>
		);
	}

	// Return error message if calidation error returned
	returnErrorText(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return (
						<Typography variant="body2" style={{ color: colorRed }}>
							{errors[i].msg}
						</Typography>
					);
				}
			}
		}
		return;
  } 
  
  //Return recapcha component after a specified time delay
  returnReCaptcha(){
    const { classes } = this.props; //Destructure data off the components props
    if(this.state.renderReCaptcha){
      return(
        <Field type="text" name="captcharesponse" component={Captcha} />
    );
    } else{
      return(         
        <Grid container justify="center" spacing={4}>
          <Grid item xs={10} sm={10} md={12} style={{display:"flex"}}>
            <CircularProgress className={classes.spinner} />
            <Typography component="h1" style={{marginLeft:"25px", marginTop:"10px"}}>
              Waiting for recaptcha...🤖
            </Typography>
          </Grid>
        </Grid>
        
      );
    }    
  }

  //Return left side - login form
  returnLeftSide = () =>{
    const { classes } = this.props; //Destructure data off the components props
    return(
      <Grid container justify="center" spacing={4}>
					<Grid item xs={12} sm={12} md={10} lg={8}>
         
            <div className={classes.outsidePaper}>            
						<Paper className={classes.paper} elevation={2}>							
								
                <div >
                  <SpinningLogo/>
                </div>

								<Typography component="h1" variant="h5">
									MEMBER SIGN IN
								</Typography>  

                <div style={{ paddingTop: "20px" }}>
                  <Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
                    Not a member? <a href="https://www.degreetutors.com/membership/" target="_blank"><span style={{color:"#f13f64"}}>Join here</span></a>
                  </Typography>
                </div>              

                <form   
                  style={{marginTop:"50px", width:"75%"}}                                             
									noValidate
									onSubmit={this.props.handleSubmit(
										values => this.onSubmit(values) //wrapped in arrow function so it won't be called on initial load
									)}
								>
									<Grid container justify="center">
										<Grid item xs={11} sm={11}>
											<Field                        
												name="email"
												id="email"
												label="Email"
                        errors={this.props.errors}                        
												component={this.renderTextField}
											/>

											<Field
												type="password"
												name="password"
												id="password"
												label="Password"
												errors={this.props.errors}
												component={this.renderTextField}
											/>

											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "14px" }}>
                          {this.returnReCaptcha()}													
													{this.returnErrorText("captcharesponse")}
												</Grid>
											</Grid>                     

											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "50px" }}>
													<Button
                            // disabled
														size="large"
														type="submit"
														fullWidth
														variant="contained"
														color="secondary"
														className={classes.submit}
														style={{ color: "white", backgroundColor:"#f13f64" }}
													>
														SIGN IN
													</Button>
												</Grid>
											</Grid>
											<Grid container style={{ marginBottom: "50px" }}>
												<Grid item xs>
													<Link to="/resetpassword">
														<Typography variant="body2" style={{color:"#8e8e8e"}}>
															Forgot password?
														</Typography>
													</Link>
												</Grid>
                      <Grid item>
											<Link to="/register">
												<Typography variant="body2" style={{color:"#8e8e8e"}}>
													Don't have an account? Register
												</Typography>
											</Link>
										  </Grid>
											</Grid>
										</Grid>
									</Grid>
                  <Copyright />
								</form>														
						</Paper>
            </div>
					</Grid>
				</Grid>

    );
  }

  //Return right side - promo panel
  returnRightSide = () =>{
    const { classes } = this.props; //Destructure data off the components props
    return(
      <div className={classes.rightSideBackground}>
        <Grid container justify="center" spacing={4}>
					<Grid item xs={10} sm={10} md={10} lg={10}>
          <div className={classes.outsidePaper}>            
						<Paper className={classes.rightPaper} elevation={2} >                           
              <img className={classes.headerImage} src={pavillion} />               

              <Typography component="h1" className={classes.rightTitle}>
                ALL ACCESS MEMBERSHIP
              </Typography>              
            
              <Typography component="h1" className={classes.rightSubtitle}>
                ACCESS THE COMPLETE COURSE LIBRARY
              </Typography>                

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Over <Link to="/lectures" className={classes.link} target="_blank">740 lectures</Link> & over 140 hours of HD video content
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Access all downloads, pdf guides & Python codes
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Unlimited access to <Link to="/structureworks" className={classes.link} target="_blank">online structural analysis toolboxes</Link> 🛠️
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Access to DegreeTutors:Labs member’s hub 🧪
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Price Guarantee – avoid future price rises as we grow
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Priority Q&A support
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Course completion certificates
              </Typography> 

              <Typography component="h1" className={classes.bullet}>
                <CheckIcon className={classes.tickIcon}/>
                Early access to new courses
              </Typography> 

              <Typography component="h1" className={classes.quote}>
                <i>"This is the best online course I have found to date on complex engineering calculations. Seán has a thorough and well considered approach to sharing his knowledge of the art of structural engineering. I look forward to keeping an eye on his future material!" </i> - <b>Drew Beattie</b>
              </Typography>  

              
              <Button                                
                size="large"                                
                variant="contained"              
                href="/membership"                
                style={{ color: "white", backgroundColor:"#33cc99", fontWeight:"bold", fontSize:"1.1rem"}}
              >
                SUBSCRIBE
              </Button>
              

            </Paper>
          </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  DefaultLayout = ()=>{
    const { classes } = this.props; //Destructure data off the components props       
    return(
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>          
          {this.returnLeftSide()}
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          {this.returnRightSide()}
        </Grid>
      </Grid>
    )
  }

  MobileLayout = () => {
    const { classes } = this.props; //Destructure data off the components props
    return(
      <Grid container justify="center" spacing={4}>
					<Grid item xs={10} sm={10} md={4} lg={3}>
         
            <div className={classes.outsidePaper}>            
						<Paper className={classes.paper} elevation={2}>							
								
                <div >
                  <SpinningLogo/>
                </div>

								<Typography component="h1" variant="h5">
									MEMBER SIGN IN
								</Typography>  

                <div style={{ paddingTop: "20px" }}>
                  <Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
                    Not a member? <a href="https://www.degreetutors.com/membership/" target="_blank"><span style={{color:"#f13f64"}}>Join here</span></a>
                  </Typography>
                </div>              

                <form   
                  style={{marginTop:"50px"}}                                             
									noValidate
									onSubmit={this.props.handleSubmit(
										values => this.onSubmit(values) //wrapped in arrow function so it won't be called on initial load
									)}
								>
									<Grid container justify="center">
										<Grid item xs={11} sm={11}>
											<Field                        
												name="email"
												id="email"
												label="Email"
                        errors={this.props.errors}                        
												component={this.renderTextField}
											/>

											<Field
												type="password"
												name="password"
												id="password"
												label="Password"
												errors={this.props.errors}
												component={this.renderTextField}
											/>

											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "14px" }}>
                          {this.returnReCaptcha()}													
													{this.returnErrorText("captcharesponse")}
												</Grid>
											</Grid>                     

											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "50px" }}>
													<Button
                            // disabled
														size="large"
														type="submit"
														fullWidth
														variant="contained"
														color="secondary"
														className={classes.submit}
														style={{ color: "white", backgroundColor:"#f13f64" }}
													>
														SIGN IN
													</Button>
												</Grid>
											</Grid>
											<Grid container style={{ marginBottom: "50px" }}>
												<Grid item xs>
													<Link to="/resetpassword">
														<Typography variant="body2" style={{color:"#8e8e8e"}}>
															Forgot password?
														</Typography>
													</Link>
												</Grid>
                      <Grid item>
											<Link to="/register">
												<Typography variant="body2" style={{color:"#8e8e8e"}}>
													Don't have an account? Register
												</Typography>
											</Link>
										  </Grid>
											</Grid>
										</Grid>
									</Grid>
                  <Copyright />
								</form>														
						</Paper>
            </div>
					</Grid>
				</Grid> 

    )

  }

	render() {
    const { classes } = this.props; //Destructure data off the components props       
		return (
			<React.Fragment>        
				<LayoutPublic />								
        <Particles className={classes.particleBackground} params={particleConfig} />
				<Feedback />
        <CssBaseline />

        <Desktop>
					<this.DefaultLayout />
				</Desktop>
				<Laptop>
					<this.DefaultLayout />
				</Laptop>
				<Tablet>
					<this.MobileLayout />
				</Tablet>
				<Mobile>
					<this.MobileLayout />
				</Mobile>			

			</React.Fragment>
		);
	}
}

//Create a formWrapped version of Login component
const formWrapped = reduxForm({
	form: "loginForm"
})(Login_V2);

function mapStateToProps(state) {
	return { errors: state.errors };
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(formWrapped));
