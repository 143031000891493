export default {
	courseList: [
    {
      courseId: 20,
      title: "Analytical Modelling of Plate and Shell Structures: Part 1 - Plates",
      tagline: "A practical guide to the analysis of circular and rectangular plates under load, from first principles.",
      statusMessage:"",
      duration: 12,
      nlectures: 41,
      path: "course-20/lecture-1",
      infoURL:"#",
      video: true,
      text: false,
      visible: true,
      completeLectures: [1,2,9,26,41],      
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Course Introduction",
          title: "Course overview",          
          vimeoURL: "https://player.vimeo.com/video/861597049?h=626f24edfd",          
          duration: "07:10",
          preview: true,
          topicId: 1009,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 2,
          section: "An Introduction to Plate and Shell Structures",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/861524918?h=eee0b5ec1f",          
          duration: "02:02",
          preview: true,
          topicId: 1010,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 3,
          section: "An Introduction to Plate and Shell Structures",
          title: "Geometric considerations and assumptions",          
          vimeoURL: "https://player.vimeo.com/video/841499314?h=a8187c1c8d",          
          duration: "11:40",
          preview: false,
          topicId: 1011,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 4,
          section: "An Introduction to Plate and Shell Structures",
          title: "Shell structures in the built environment",          
          vimeoURL: "https://player.vimeo.com/video/841499470?h=2138632ff9",          
          duration: "25:42",
          preview: false,
          topicId: 1012,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 5,
          section: "An Introduction to Plate and Shell Structures",
          title: "Fundamental load resistance mechanisms",          
          vimeoURL: "https://player.vimeo.com/video/841499903?h=9ddc88eea0",          
          duration: "17:25",
          preview: false,
          topicId: 1013,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 6,
          section: "An Introduction to Plate and Shell Structures",
          title: "Deformation curvature for 2D plates",          
          vimeoURL: "https://player.vimeo.com/video/841500075?h=27a4480670",          
          duration: "29:22",
          preview: false,
          topicId: 1014,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 7,
          section: "An Introduction to Plate and Shell Structures",
          title: "Curvature and axis rotation",          
          vimeoURL: "https://player.vimeo.com/video/841500493?h=4e66046834",          
          duration: "10:15",
          preview: false,
          topicId: 1015,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 8,
          section: "An Introduction to Plate and Shell Structures",
          title: "Undeformed shell curvature",          
          vimeoURL: "https://player.vimeo.com/video/841498893?h=9d2bd3aae3",          
          duration: "26:54",
          preview: false,
          topicId: 1016,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 9,
          section: "Analysis of Circular Plates",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/861524948?h=5d7bdd161b",          
          duration: "03:14",
          preview: true,
          topicId: 1017,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 10,
          section: "Analysis of Circular Plates",
          title: "Coordinates, limitations, assumptions and the plan",          
          vimeoURL: "https://player.vimeo.com/video/845916029?h=775756801b",          
          duration: "18:57",
          preview: false,
          topicId: 1018,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 11,
          section: "Analysis of Circular Plates",
          title: "Establishing the radial and hoop strain",
          vimeoURL: "https://player.vimeo.com/video/845916378?h=e0abe99fe6",          
          duration: "18:54",
          preview: false,
          topicId: 1019,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 12,
          section: "Analysis of Circular Plates",
          title: "Jumping to stresses via Hooke’s Law",          
          vimeoURL: "https://player.vimeo.com/video/845916806?h=f1f1afd5a2",          
          duration: "09:43",
          preview: false,
          topicId: 1020,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 13,
          section: "Analysis of Circular Plates",
          title: "Determining the stress resultants",          
          vimeoURL: "https://player.vimeo.com/video/845917104?h=bb4347c8cb",          
          duration: "13:33",
          preview: false,
          topicId: 1021,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 14,
          section: "Analysis of Circular Plates",
          title: "Equilibrium of the infinitesimal element",          
          vimeoURL: "https://player.vimeo.com/video/845917415?h=705ca66439",          
          duration: "16:57",
          preview: false,
          topicId: 1022,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 15,
          section: "Analysis of Circular Plates",
          title: "From equilibrium to the governing differential equation",          
          vimeoURL: "https://player.vimeo.com/video/845917826?h=6f62ac5949",
          duration: "24:04",
          preview: false,
          topicId: 1023,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 15 Jupyter Notebook",
              filename: "Lecture 15.zip" 
            }
          ]
        }, 
        {
          lectureId: 16,
          section: "Analysis of Circular Plates",
          title: "Evaluating the shear force term",          
          vimeoURL: "https://player.vimeo.com/video/845918121?h=6839689348",
          duration: "07:24",
          preview: false,
          topicId: 1024,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 17,
          section: "Analysis of Circular Plates",
          title: "The influence of plate boundary conditions",
          vimeoURL: "https://player.vimeo.com/video/845918357?h=c5dec88758",
          duration: "14:12",
          preview: false,
          topicId: 1025,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 18,
          section: "Analysis of Circular Plates",
          title: "Case study #1: Simply supported with edge moment",
          vimeoURL: "https://player.vimeo.com/video/847058479?h=bb6661ffde",          
          duration: "20:10",
          preview: false,
          topicId: 1026,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 19,
          section: "Analysis of Circular Plates",
          title: "Case study #2: Simply supported with UDL",          
          vimeoURL: "https://player.vimeo.com/video/847058507?h=86d61d06cc",
          duration: "33:04",
          preview: false,
          topicId: 1027,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 19 Jupyter Notebook",
              filename: "Lecture 19.zip" 
            }
          ]
        }, 
        {
          lectureId: 20,
          section: "Analysis of Circular Plates",
          title: "Case study #2: Further investigation & visualisation",          
          vimeoURL: "https://player.vimeo.com/video/847058551?h=1dacf366f3",
          duration: "29:41",
          preview: false,
          topicId: 1028,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 20 Jupyter Notebook",
              filename: "Lecture 20.zip" 
            }
          ]
        }, 
        {
          lectureId: 21,
          section: "Analysis of Circular Plates",
          title: "Case study #3: Built-in with central point load",          
          vimeoURL: "https://player.vimeo.com/video/847058607?h=4edbadda11",          
          duration: "18:10",
          preview: false,
          topicId: 1029,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 22,
          section: "Analysis of Circular Plates",
          title: "Case study #4: Built-in annular plate with UDL",          
          vimeoURL: "https://player.vimeo.com/video/850836388?h=4f0b9a1a14",          
          duration: "30:09",
          preview: false,
          topicId: 1030,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 22 Jupyter Notebook",
              filename: "Lecture 22.zip" 
            }
          ]
        }, 
        {
          lectureId: 23,
          section: "Analysis of Circular Plates",
          title: "Case study #5: Simply supported with linearly varying load",          
          vimeoURL: "https://player.vimeo.com/video/850836614?h=ffbcaeb084",          
          duration: "20:25",
          preview: false,
          topicId: 1031,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 24,
          section: "Analysis of Circular Plates",
          title: "Case study #5: Code solution and plotting",          
          vimeoURL: "https://player.vimeo.com/video/850837084?h=b3cf430eeb",          
          duration: "13:13",
          preview: false,
          topicId: 1032,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 24 Jupyter Notebook",
              filename: "Lecture 24.zip" 
            }
          ]
        }, 
        {
          lectureId: 25,
          section: "Analysis of Circular Plates",
          title: "Building a 3D visualisation of plate deflection",          
          vimeoURL: "https://player.vimeo.com/video/850837210?h=ec71a09f64",          
          duration: "31:33",
          preview: false,
          topicId: 1033,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 25 Jupyter Notebook",
              filename: "Lecture 25.zip" 
            }
          ]
        }, 
        {
          lectureId: 26,
          section: "Analysis of Rectangular Plates",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/861525004?h=6633e3b006",          
          duration: "04:35",
          preview: true,
          topicId: 1034,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 27,
          section: "Analysis of Rectangular Plates",
          title: "Setting up our derivation",          
          vimeoURL: "https://player.vimeo.com/video/856481381?h=8348361b7b",          
          duration: "12:00",
          preview: false,
          topicId: 1035,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 28,
          section: "Analysis of Rectangular Plates",
          title: "Stress and strain analysis",          
          vimeoURL: "https://player.vimeo.com/video/856481885?h=bf76d1035f",          
          duration: "20:34",
          preview: false,
          topicId: 1036,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 29,
          section: "Analysis of Rectangular Plates",
          title: "Equilibrium and the governing differential equation",          
          vimeoURL: "https://player.vimeo.com/video/856483086?h=20e49873d4",          
          duration: "24:11",
          preview: false,
          topicId: 1037,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 30,
          section: "Analysis of Rectangular Plates",
          title: "Stress distributions",          
          vimeoURL: "https://player.vimeo.com/video/856484365?h=9d40665723",          
          duration: "06:14",
          preview: false,
          topicId: 1038,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 31,
          section: "Analysis of Rectangular Plates",
          title: "Fixed and simply supported boundary conditions",          
          vimeoURL: "https://player.vimeo.com/video/856484667?h=6787176752",          
          duration: "07:50",
          preview: false,
          topicId: 1039,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 32,
          section: "Analysis of Rectangular Plates",
          title: "Free edges",          
          vimeoURL: "https://player.vimeo.com/video/856484937?h=9530ceaecc",          
          duration: "13:22",
          preview: false,
          topicId: 1040,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 33,
          section: "Analysis of Rectangular Plates",
          title: "The corner effect",          
          vimeoURL: "https://player.vimeo.com/video/856485694?h=3b97b28a3c",          
          duration: "09:04",
          preview: false,
          topicId: 1041,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 34,
          section: "Analysis of Rectangular Plates",
          title: "Navier’s solution for rectangular plate deflection",          
          vimeoURL: "https://player.vimeo.com/video/856486285?h=f646111cfd",          
          duration: "30:45",
          preview: false,
          topicId: 1042,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 35,
          section: "Analysis of Rectangular Plates",
          title: "Determining plate moments",          
          vimeoURL: "https://player.vimeo.com/video/857561397?h=3a47243506",          
          duration: "09:10",
          preview: false,
          topicId: 1043,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 36,
          section: "Analysis of Rectangular Plates",
          title: "Case study #6: Navier’s solution for UDL",          
          vimeoURL: "https://player.vimeo.com/video/858709306?h=71c3a979f9",          
          duration: "19:58",
          preview: false,
          topicId: 1044,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 37,
          section: "Analysis of Rectangular Plates",
          title: "Case study #7: Navier’s solution for linearly varying loading",          
          vimeoURL: "https://player.vimeo.com/video/858693673?h=98ef947894",          
          duration: "31:43",
          preview: false,
          topicId: 1045,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 37 Jupyter Notebook",
              filename: "Lecture 37.zip" 
            }
          ]
        }, 
        {
          lectureId: 38,
          section: "Analysis of Rectangular Plates",
          title: "Case study #8: Navier’s solution for patch loading",          
          vimeoURL: "https://player.vimeo.com/video/858694132?h=8ed15b799a",          
          duration: "20:08",
          preview: false,
          topicId: 1046,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 38 Jupyter Notebook",
              filename: "Lecture 38.zip" 
            }
          ]
        }, 
        {
          lectureId: 39,
          section: "Analysis of Rectangular Plates",
          title: "Case study #9: Navier’s solution for point loading",          
          vimeoURL: "https://player.vimeo.com/video/858694603?h=7d42180cd5",          
          duration: "07:30",
          preview: false,
          topicId: 1047,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 40,
          section: "Analysis of Rectangular Plates",
          title: "Building a ‘Plate Calculator’ script",          
          vimeoURL: "https://player.vimeo.com/video/861035719?h=eb2410bbcc",          
          duration: "36:18",
          preview: false,
          topicId: 1048,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[
            {
              linkText: "Lecture 40 Jupyter Notebook",
              filename: "Lecture 40.zip" 
            }
          ]
        }, 
        {
          lectureId: 41,
          section: "Analysis of Rectangular Plates",
          title: "Course wrap up and certificate of completion",          
          vimeoURL: "https://player.vimeo.com/video/862880992?h=933e374b19",          
          duration: "03:40",
          preview: false,
          topicId: 1049,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "#"
            }
          ],
          files:[]
        }, 
      ]
    },
    {
      courseId: 19,
      title: "Fundamentals of Reinforced Concrete Design to Eurocode 2",
      tagline: "An introduction to ultimate limit state design for bending and shear with an optional module on calculation automation with Python.",
      statusMessage:"",
      duration: 12,
      nlectures: 41,
      path: "course-19/lecture-1",
      infoURL:"#",
      video: true,
      text: false,
      visible: true,
      completeLectures: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,41],      
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "",
      discourse: true,
      lectures: [ 
        {
          lectureId: 1,
          section: "Introduction, Course Breakdown",
          title: "Course overview",          
          vimeoURL: "https://player.vimeo.com/video/795317981?h=9a44c61ef8",          
          duration: "06:01",
          preview: true,
          topicId: 12,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-course-breakdown/12"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 2,
          section: "Introduction, Course Breakdown",
          title: "Who is this course for?",          
          vimeoURL: "https://player.vimeo.com/video/795332108?h=85cad810ea",          
          duration: "03:53",
          preview: true,
          topicId: 13,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-who-is-this-course-for/13"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 3,
          section: "Introduction, Course Breakdown",
          title: "The optional role of Python in this course",          
          vimeoURL: "https://player.vimeo.com/video/795336737?h=2041b27753",          
          duration: "03:25",
          preview: true,
          topicId: 14,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-the-optional-role-of-python-in-this-course/14"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Actions and Limit State Design",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/795294576?h=2e216a331f",          
          duration: "03:54",
          preview: true,
          topicId: 15,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-section-overview/15"
            }
          ],
          files:[]
        },   
        {
          lectureId: 5,
          section: "Actions and Limit State Design",
          title: "The relevant codes for this course",          
          vimeoURL: "https://player.vimeo.com/video/760361066?h=df4ab5239b",          
          duration: "10:21",
          preview: false,
          topicId: 16,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-the-relevant-codes-for-this-course/16"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 6,
          section: "Actions and Limit State Design",
          title: "Actions on structures",          
          vimeoURL: "https://player.vimeo.com/video/760405417?h=08994772a2",          
          duration: "14:54",
          preview: false,
          topicId: 17,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-actions-on-structures/17"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 7,
          section: "Actions and Limit State Design",
          title: "Ultimate limit state design",          
          vimeoURL: "https://player.vimeo.com/video/760537663?h=b9394a8709",          
          duration: "32:02",
          preview: false,
          topicId: 18,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-ultimate-limit-state-design/18"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 8,
          section: "Actions and Limit State Design",
          title: "Serviceability limit state design",          
          vimeoURL: "https://player.vimeo.com/video/760544597?h=af8357dcea",          
          duration: "02:51",
          preview: false,
          topicId: 19,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-serviceability-limit-state-design/19"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 9,
          section: "Actions and Limit State Design",
          title: "Worked example #1: Determining ULS actions",          
          vimeoURL: "https://player.vimeo.com/video/760551004?h=fe89ec821a",          
          duration: "15:06",
          preview: false,
          topicId: 20,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-worked-example-1-determining-uls-actions/20"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 10,
          section: "Actions and Limit State Design",
          title: "Worked example #2: Determining ULS actions",          
          vimeoURL: "https://player.vimeo.com/video/760562008?h=ade86cb63d",          
          duration: "23:30",
          preview: false,
          topicId: 21,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-worked-example-2-determining-uls-actions/21"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 11,
          section: "Bending of Reinforced Concrete",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/795303643?h=59e7eca093",          
          duration: "03:45",
          preview: true,
          topicId: 22,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-section-overview/22"
            }
          ],
          files:[]
        },   
        {
          lectureId: 12,
          section: "Bending of Reinforced Concrete",
          title: "Material properties",          
          vimeoURL: "https://player.vimeo.com/video/765466661?h=96434e2217",          
          duration: "23:18",
          preview: false,
          topicId: 23,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-material-properties/23"
            }
          ],
          files:[]
        },  
        {
          lectureId: 13,
          section: "Bending of Reinforced Concrete",
          title: "Cross-section analysis",          
          vimeoURL: "https://player.vimeo.com/video/765468073?h=5fbb4c37f7",          
          duration: "34:14",
          preview: true,
          topicId: 24,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-cross-section-analysis/24"
            }
          ],
          files:[]
        },  
        {
          lectureId: 14,
          section: "Bending of Reinforced Concrete",
          title: "Ultimate moment capacity",          
          vimeoURL: "https://player.vimeo.com/video/765491301?h=e69665ad83",          
          duration: "20:38",
          preview: true,
          topicId: 25,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-ultimate-moment-capacity/25"
            }
          ],
          files:[]
        },  
        {
          lectureId: 15,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #3: Basic section design",          
          vimeoURL: "https://player.vimeo.com/video/766398448?h=e6e1289b0d",          
          duration: "16:43",
          preview: false,
          topicId: 26,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-worked-example-3-basic-section-design/26"
            }
          ],
          files:[]
        },  
        {
          lectureId: 16,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #4: Calculate section moment capacity",          
          vimeoURL: "https://player.vimeo.com/video/766398506?h=2431462373",          
          duration: "10:31",
          preview: false,
          topicId: 27,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-worked-example-4-calculate-section-moment-capacity/27"
            }
          ],
          files:[]
        },  
        {
          lectureId: 17,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #5a: Over-reinforced sections",          
          vimeoURL: "https://player.vimeo.com/video/767087279?h=dbee6968ac",          
          duration: "23:07",
          preview: false,
          topicId: 28,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-worked-example-5a-over-reinforced-sections/28"
            }
          ],
          files:[]
        },  
        {
          lectureId: 18,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #5b: Automating the search for x",          
          vimeoURL: "https://player.vimeo.com/video/767209423?h=853855242b",          
          duration: "20:04",
          preview: false,
          topicId: 29,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-worked-example-5b-automating-the-search-for-x/29"
            }
          ],
          files:[
            {
              linkText: "Lecture 18 Excel and Python solutions",
              filename: "Lecture 18.zip"                        
          }
          ]
        },  
        {
          lectureId: 19,
          section: "Bending of Reinforced Concrete",
          title: "Doubly reinforced sections",          
          vimeoURL: "https://player.vimeo.com/video/773105607?h=a778aaa3ee",          
          duration: "21:36",
          preview: false,
          topicId: 30,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-doubly-reinforced-sections/30"
            }
          ],
          files:[]
        },  
        {
          lectureId: 20,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #6: Doubly reinforced section design",          
          vimeoURL: "https://player.vimeo.com/video/773108501?h=9b8ec19f49",          
          duration: "12:32",
          preview: false,
          topicId: 31,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-worked-example-6-doubly-reinforced-section-design/31"
            }
          ],
          files:[]
        },  
        {
          lectureId: 21,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #7: Calculating doubly reinforced moment capacity",          
          vimeoURL: "https://player.vimeo.com/video/773109345?h=971ec1d8f4",          
          duration: "10:59",
          preview: false,
          topicId: 32,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-worked-example-7-calculating-doubly-reinforced-moment-capacity/32"
            }
          ],
          files:[]
        },  
        {
          lectureId: 22,
          section: "Bending of Reinforced Concrete",
          title: "Flanged beam design",          
          vimeoURL: "https://player.vimeo.com/video/773677527?h=ecb791c61e",          
          duration: "19:33",
          preview: false,
          topicId: 33,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-flanged-beam-design/33"
            }
          ],
          files:[]
        },  
        {
          lectureId: 23,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #8: Flanged section moment capacity",          
          vimeoURL: "https://player.vimeo.com/video/773701737?h=84f591f80b",          
          duration: "12:56",
          preview: false,
          topicId: 34,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-worked-example-8-flanged-section-moment-capacity/34"
            }
          ],
          files:[]
        },  
        {
          lectureId: 24,
          section: "Bending of Reinforced Concrete",
          title: "Worked example #9: Flanged section design",          
          vimeoURL: "https://player.vimeo.com/video/773897841?h=6d14361793",          
          duration: "07:57",
          preview: false,
          topicId: 35,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-worked-example-9-flanged-section-design/35"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/795305751?h=d41cbb4250",          
          duration: "02:48",
          preview: true,
          topicId: 626,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-section-overview-rc-design/626"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 26,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Shear behaviour in beams",          
          vimeoURL: "https://player.vimeo.com/video/783566106?h=32b39aaefa",          
          duration: "32:58",
          preview: false,
          topicId: 627,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-shear-behaviour-in-beams/627"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 27,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Reinforced shear resistance (Variable Strut Method)",          
          vimeoURL: "https://player.vimeo.com/video/783568833?h=b4c726892c",          
          duration: "36:35",
          preview: false,
          topicId: 628,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-a-model-of-reinforced-shear-resistance/628"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 28,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Worked example #10: Bending and shear design",          
          vimeoURL: "https://player.vimeo.com/video/783589074?h=16a2e661d6",          
          duration: "33:26",
          preview: false,
          topicId: 629,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-worked-example-10-bending-and-shear-design/629"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 29,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Worked example #11: Shear design example",          
          vimeoURL: "https://player.vimeo.com/video/783592592?h=4ad2582ec8",          
          duration: "14:38",
          preview: false,
          topicId: 630,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-worked-example-11-shear-design-example/630"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 30,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Longitudinal shear in flanged beams",          
          vimeoURL: "https://player.vimeo.com/video/783706768?h=166a76e8e5",          
          duration: "18:37",
          preview: false,
          topicId: 631,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-longitudinal-shear-in-flanged-beams/631"
            }
          ],
          files:[]
        }, 
        {
          lectureId: 31,
          section: "Shear Resistance of Reinforced Concrete",
          title: "Worked example #12: Transverse shear reinforcement design",          
          vimeoURL: "https://player.vimeo.com/video/783725104?h=b55c8f565d",          
          duration: "21:54",
          preview: false,
          topicId: 632,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-worked-example-12-transverse-shear-reinforcement-design/632"
            }
          ],
          files:[]
        },   
        {
          lectureId: 32,
          section: "Automating section analysis in Python",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/795307250?h=624a2f891f",          
          duration: "02:28",
          preview: true,
          topicId: 932,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-section-overview/932"
            }
          ],
          files:[]
        },  
        {
          lectureId: 33,
          section: "Automating section analysis in Python",
          title: "Designing singly reinforced sections",          
          vimeoURL: "https://player.vimeo.com/video/790742856?h=882cf43489",          
          duration: "20:08",
          preview: false,
          topicId: 933,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-designing-singly-reinforced-sections/933"
            }
          ],
          files:[
            {
              linkText: "Lecture 33 Jupyter Notebook",
              filename: "Lecture 33.zip" 
            }
          ]
        },  
        {
          lectureId: 34,
          section: "Automating section analysis in Python",
          title: "Expanding to doubly reinforced sections",          
          vimeoURL: "https://player.vimeo.com/video/790800518?h=9364311a77",          
          duration: "22:33",
          preview: false,
          topicId: 934,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-expanding-to-doubly-reinforced-sections/934"
            }
          ],
          files:[
            {
              linkText: "Lecture 34 Jupyter Notebook",
              filename: "Lecture 34.zip" 
            }
          ]
        },  
        {
          lectureId: 35,
          section: "Automating section analysis in Python",
          title: "Expanding to singly reforced flanged sections",          
          vimeoURL: "https://player.vimeo.com/video/790800922?h=127bd8c37a",          
          duration: "39:00",
          preview: false,
          topicId: 935,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-expanding-to-singly-reinforced-flanged-sections/935"
            }
          ],
          files:[
            {
              linkText: "Lecture 35 Jupyter Notebook",
              filename: "Lecture 35.zip" 
            }
          ]
        },  
        {
          lectureId: 36,
          section: "Automating section analysis in Python",
          title: "Determining the correct analysis case",          
          vimeoURL: "https://player.vimeo.com/video/790801704?h=5e7b367a03",          
          duration: "21:36",
          preview: false,
          topicId: 936,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-determining-the-correct-analysis-case/936"
            }
          ],
          files:[
            {
              linkText: "Lecture 36 Jupyter Notebook",
              filename: "Lecture 36.zip" 
            }
          ]
        },  
        {
          lectureId: 37,
          section: "Automating section analysis in Python",
          title: "Under-reinforced section analysis",          
          vimeoURL: "https://player.vimeo.com/video/791169426?h=d915bd853d",          
          duration: "10:48",
          preview: false,
          topicId: 937,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-under-reinforced-section-analysis/937"
            }
          ],
          files:[
            {
              linkText: "Lecture 37 Jupyter Notebook",
              filename: "Lecture 37.zip" 
            }
          ]
        },  
        {
          lectureId: 38,
          section: "Automating section analysis in Python",
          title: "Over-reinforced section analysis",          
          vimeoURL: "https://player.vimeo.com/video/791323556?h=6488136012",          
          duration: "31:50",
          preview: false,
          topicId: 938,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-over-reinforced-section-analysis/938"
            }
          ],
          files:[
            {
              linkText: "Lecture 38 Jupyter Notebook",
              filename: "Lecture 38.zip" 
            }
          ]
        },  
        {
          lectureId: 39,
          section: "Automating section analysis in Python",
          title: "Doubly reinforced section analysis",          
          vimeoURL: "https://player.vimeo.com/video/791326429?h=7d9bd51cfc",          
          duration: "40:48",
          preview: false,
          topicId: 939,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-doubly-reinforced-section-analysis/939"
            }
          ],
          files:[
            {
              linkText: "Lecture 39 Jupyter Notebook",
              filename: "Lecture 39.zip" 
            }
          ]
        },  
        {
          lectureId: 40,
          section: "Automating section analysis in Python",
          title: "Flanged section analysis",          
          vimeoURL: "https://player.vimeo.com/video/792180301?h=e1f6d079a5",          
          duration: "24:55",
          preview: false,
          topicId: 940,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-flanged-section-analysis/940"
            }
          ],
          files:[
            {
              linkText: "Lecture 40 Jupyter Notebook",
              filename: "Lecture 40.zip" 
            }
          ]
        },  
        {
          lectureId: 41,
          section: "Course wrap up and certificate of completion",
          title: "Course wrap up and certificate of completion",          
          vimeoURL: "https://player.vimeo.com/video/795308522?h=4f42243829",          
          duration: "04:06",
          preview: false,
          topicId: 941,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-course-wrap-up-and-certificate-of-completion/941"
            }
          ],
          files:[]
        },    
      ]
    },
    {
      courseId: 18,
      title: "Modelling and Analysis of Non-linear Cablenet Structures using Python and Blender",
      tagline: "Learn how to combine parametric modelling, exploratory form-finding and iterative analysis techniques to simulate 3D tensile structures",
      duration: 12,
      nlectures: 50,
      path: "course-18/lecture-1",
      infoURL:"https://www.degreetutors.com/modelling-and-analysis-of-non-linear-cablenet-structures-in-python/",
      video: true,
      text: false,
      visible: true,
      completeLectures: [1,2,3,5,8,12,30,35,40,45],
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/728151141?h=d7a9a88755",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction, Course Breakdown & Prerequisites",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/728183506?h=5710afda0f",          
          duration: "06:37",
          preview: true,          
          topicId: 495,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-cablenet-structures/495"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Introduction, Course Breakdown & Prerequisites",
          title: "Course prerequisites",          
          vimeoURL: "https://player.vimeo.com/video/728185305?h=17512bb03e",          
          duration: "02:17",
          preview: true,
          topicId: 496,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-course-prerequisites-cablenet-structures/496"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Introduction, Course Breakdown & Prerequisites",
          title: "Moving to JupyterLab",          
          vimeoURL: "https://player.vimeo.com/video/728185825?h=3f5e1c49b2",          
          duration: "06:52",
          preview: true,
          topicId: 497,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-moving-to-jupyterlab/497"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Introduction, Course Breakdown & Prerequisites",
          title: "Cablenet structures",          
          vimeoURL: "https://player.vimeo.com/video/728187445?h=2604981feb",          
          duration: "31:25",
          preview: true,
          topicId: 498,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-cablenet-structures/498"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Developing the 3D non-linear stiffness matrix",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/728197203?h=500275afcd",          
          duration: "01:12",
          preview: true,
          topicId: 499,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-section-overview-cablenet-structures/499"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Developing the 3D non-linear stiffness matrix",
          title: "Building the 3D transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/728197415?h=81b9819c82",          
          duration: "18:18",
          preview: false,
          topicId: 500,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-building-the-3d-transformation-matrix/500"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Developing the 3D non-linear stiffness matrix",
          title: "Extending the non-linear stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/728200892?h=507669f964",          
          duration: "10:55",
          preview: false,
          topicId: 501,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-extending-the-non-linear-stiffness-matrix/501"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Extending our Blender Utility Scripts",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/728201835?h=420d21d623",          
          duration: "01:17",
          preview: true,
          topicId: 502,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-section-overview/502"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Extending our Blender Utility Scripts",
          title: "Generate a simple cablenet",          
          vimeoURL: "https://player.vimeo.com/video/715499947?h=e64969dad8",          
          duration: "07:02",
          preview: false,
          topicId: 503,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-generate-a-simple-cablenet/503"
            }
          ],
          files:[{
            linkText: "Lecture 9 Blender File",
            filename: "Lecture 9.zip"                        
        }]
        },
        {
          lectureId: 10,
          section: "Extending our Blender Utility Scripts",
          title: "Extending our Blender export scripts",          
          vimeoURL: "https://player.vimeo.com/video/715507113?h=09fc00a439",          
          duration: "10:00",
          preview: false,
          topicId: 504,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-extending-our-blender-export-scripts/504"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Extending our Blender Utility Scripts",
          title: "Visualising axial forces and deflections in Blender",          
          vimeoURL: "https://player.vimeo.com/video/715508769?h=4c0b40725d",          
          duration: "14:17",
          preview: false,
          topicId: 505,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-visualising-axial-forces-and-deflections-in-blender/505"
            }
          ],
          files:[{
            linkText: "Lectures 9-11 Blender File",
            filename: "Lecture 9-11.zip"                        
        }]
        },
        {
          lectureId: 12,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/728202119?h=0f3c85d733",          
          duration: "01:33",
          preview: true,
          topicId: 506,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-section-overview-cablenet-structures/506"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Updating our notebook structure",          
          vimeoURL: "https://player.vimeo.com/video/715511174?h=7af500f3a9",          
          duration: "15:16",
          preview: false,
          topicId: 507,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-updating-our-notebook-structure/507"
            }
          ],
          files:[{
            linkText: "Lecture 13 Jupyter Notebook",
            filename: "Lecture 13.zip"                        
        }]
        },
        {
          lectureId: 14,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Basic updates to our preprocessing code",          
          vimeoURL: "https://player.vimeo.com/video/715513942?h=5c5df897e1",          
          duration: "07:40",
          preview: false,
          topicId: 508,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-basic-updates-to-our-preprocessing-code/508"
            }
          ],
          files:[{
            linkText: "Lecture 14 Jupyter Notebook",
            filename: "Lecture 14.zip"                        
        }]
        },
        {
          lectureId: 15,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Implementing a basic 3D plot",          
          vimeoURL: "https://player.vimeo.com/video/715515284?h=f9564656b2",          
          duration: "10:03",
          preview: false,
          topicId: 509,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-implementing-a-basic-3d-plot/509"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Fixing the 3D scale problem",          
          vimeoURL: "https://player.vimeo.com/video/715516969?h=e3875858a2",          
          duration: "08:38",
          preview: false,
          topicId: 510,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-fixing-the-3d-scale-problem/510/1"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Implement support type visualisation",          
          vimeoURL: "https://player.vimeo.com/video/715518776?h=606f3df91d",          
          duration: "10:16",
          preview: false,
          topicId: 511,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-implement-support-type-visualisation/511"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Implementing 3D arrows",          
          vimeoURL: "https://player.vimeo.com/video/715520599?h=2bfc131282",          
          duration: "12:56",
          preview: false,
          topicId: 512,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-implementing-3d-arrows/512"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Implementing 3D annotations",          
          vimeoURL: "https://player.vimeo.com/video/715523253?h=00695fe1e1",          
          duration: "11:47",
          preview: false,
          topicId: 513,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-implementing-3d-annotations/513"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Building the transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/715525785?h=843714cee3",          
          duration: "15:58",
          preview: false,
          topicId: 514,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-building-the-transformation-matrix/514"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Calculating the pre-tension force vector",          
          vimeoURL: "https://player.vimeo.com/video/715533954?h=862afa4123",          
          duration: "01:46",
          preview: false,
          topicId: 515,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-calculating-the-pre-tension-force-vector/515"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Building the stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/715534393?h=5c17645a01",          
          duration: "06:30",
          preview: false,
          topicId: 516,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-building-the-stiffness-matrix/516"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Calculating the internal force system and axial forces",          
          vimeoURL: "https://player.vimeo.com/video/715535250?h=df408ca9d1",          
          duration: "06:18",
          preview: false,
          topicId: 517,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-calculating-the-internal-force-system-and-axial-forces/517"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Recap of the main loop logic",          
          vimeoURL: "https://player.vimeo.com/video/715536196?h=b79670a5ba",          
          duration: "26:16",
          preview: false,
          topicId: 518,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-recap-of-the-main-loop-logic/518"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Updating the results plot - phase 1",          
          vimeoURL: "https://player.vimeo.com/video/715683767?h=2ef0f796f7",          
          duration: "21:58",
          preview: false,
          topicId: 519,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-updating-the-results-plot-phase-1/519"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Updating the results plot - phase 2",          
          vimeoURL: "https://player.vimeo.com/video/715685541?h=39b5841fa0",          
          duration: "23:40",
          preview: false,
          topicId: 520,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-updating-the-results-plot-phase-2/520"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Updating the results plot - phase 3",          
          vimeoURL: "https://player.vimeo.com/video/715688025?h=fd895d9738",          
          duration: "14:58",
          preview: false,
          topicId: 521,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-updating-the-results-plot-phase-3/521"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Using Pandas DataFrames to represent our data",          
          vimeoURL: "https://player.vimeo.com/video/716085875?h=5e8720d748",          
          duration: "24:15",
          preview: false,
          topicId: 522,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-using-pandas-dataframes-to-represent-our-data/522"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Extending our solver toolbox from 2D to 3D",
          title: "Visualising deflection and axial force data in Blender",          
          vimeoURL: "https://player.vimeo.com/video/726690882?h=e7c13993f0",          
          duration: "16:40",
          preview: false,
          topicId: 523,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-visualising-deflection-and-axial-force-data-in-blender/523"
            }
          ],
          files:[
            {
            linkText: "Lecture 13-29 Jupyter Notebook",
            filename: "Lecture 13-29.zip"                        
            },{
              linkText: "Lecture 29 Blender File",
              filename: "Lecture 29.zip"                        
          }]
        },
        {
          lectureId: 30,
          section: "Cable-stayed antenna tower…in 3D",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/728202470?h=ed794abaaa",          
          duration: "01:06",
          preview: true,
          topicId: 524,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-section-overview/524"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Cable-stayed antenna tower…in 3D",
          title: "Model generation in Blender",          
          vimeoURL: "https://player.vimeo.com/video/726692841?h=c535d83adf",          
          duration: "21:00",
          preview: false,
          topicId: 525,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-model-generation-in-blender/525"
            }
          ],
          files:[{
            linkText: "Lecture 31 Blender File",
            filename: "Lecture 31.zip"                        
        }]
        },
        {
          lectureId: 32,
          section: "Cable-stayed antenna tower…in 3D",
          title: "Solving the antenna tower",          
          vimeoURL: "https://player.vimeo.com/video/726694202?h=b119060580",          
          duration: "13:21",
          preview: false,
          topicId: 526,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-solving-the-antenna-tower/526"
            }
          ],
          files:[
            {
              linkText: "Lecture 32 Jupyter Notebook",
              filename: "Lecture 32a.zip"                        
              },{
                linkText: "Lecture 32 Blender File",
                filename: "Lecture 32b.zip"                        
            }
          ]
        },
        {
          lectureId: 33,
          section: "Cable-stayed antenna tower…in 3D",
          title: "Saving and exporting all simulation data",          
          vimeoURL: "https://player.vimeo.com/video/726695431?h=4ca0c8f41b",          
          duration: "15:11",
          preview: false,
          topicId: 527,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-saving-and-exporting-all-simulation-data/527"
            }
          ],
          files:[{
            linkText: "Lecture 33 Jupyter Notebook",
            filename: "Lecture 33.zip"                        
        }]
        },
        {
          lectureId: 34,
          section: "Cable-stayed antenna tower…in 3D",
          title: "Building a results viewer notebook",          
          vimeoURL: "https://player.vimeo.com/video/726696161?h=722b04d1bb",          
          duration: "20:21",
          preview: false,
          topicId: 528,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-building-a-results-viewer-notebook/528"
            }
          ],
          files:[{
            linkText: "Lecture 34 Jupyter Notebook",
            filename: "Lecture 34.zip"                        
        }]
        },
        {
          lectureId: 35,
          section: "Parametric modelling and form-finding in Blender",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/728202710?h=4998f3bb8f",          
          duration: "01:24",
          preview: true,
          topicId: 529,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-section-overview-cablenet-structures/529"
            }
          ],
          files:[]
        },
        {
          lectureId: 36,
          section: "Parametric modelling and form-finding in Blender",
          title: "Simulation initial geometry in Blender - Part 1",          
          vimeoURL: "https://player.vimeo.com/video/726700554?h=ccc2017803",          
          duration: "38:02",
          preview: false,
          topicId: 530,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-simulation-initial-geometry-in-blender-part-1/530"
            }
          ],
          files:[]
        },
        {
          lectureId: 37,
          section: "Parametric modelling and form-finding in Blender",
          title: "Simulation initial geometry in Blender - Part 2",          
          vimeoURL: "https://player.vimeo.com/video/726745537?h=e82d0ca4e0",          
          duration: "25:09",
          preview: false,
          topicId: 531,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-simulation-initial-geometry-in-blender-part-2/531"
            }
          ],
          files:[{
            linkText: "Lectures 36-37 Blender File",
            filename: "Lecture 36-37.zip"                        
        }]
        },
        {
          lectureId: 38,
          section: "Parametric modelling and form-finding in Blender",
          title: "The relationship between form-finding and pre-tension",          
          vimeoURL: "https://player.vimeo.com/video/728202997?h=886015c05c",          
          duration: "13:49",
          preview: false,
          topicId: 533,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-the-relationship-between-form-finding-and-pre-tension/533"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Parametric modelling and form-finding in Blender",
          title: "Procedural modelling with geometry nodes [Optional]",          
          vimeoURL: "https://player.vimeo.com/video/729115527?h=6d5d2dc18e",          
          duration: "37:21",
          preview: false,
          topicId: 534,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-procedural-modelling-with-geometry-nodes/534"
            }
          ],
          files:[{
            linkText: "Lecture 39 Blender File",
            filename: "Lecture 39.zip"                        
        }]
        },
        {
          lectureId: 40,
          section: "Cablenet Pavilion - Hyperbolic Paraboloid",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/729122067?h=79b42a2567",          
          duration: "01:00",
          preview: true,
          topicId: 535,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-section-overview/535"
            }
          ],
          files:[]
        },
        {
          lectureId: 41,
          section: "Cablenet Pavilion - Hyperbolic Paraboloid",
          title: "Accommodating different cable types",          
          vimeoURL: "https://player.vimeo.com/video/729122298?h=e638c65ac9",          
          duration: "12:41",
          preview: false,
          topicId: 536,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-accommodating-different-cable-types/536"
            }
          ],
          files:[]
        },
        {
          lectureId: 42,
          section: "Cablenet Pavilion - Hyperbolic Paraboloid",
          title: "Generating initial geometry and pre-tension",          
          vimeoURL: "https://player.vimeo.com/video/729123977?h=6629803c30",          
          duration: "28:12",
          preview: false,
          topicId: 537,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/42-generating-initial-geometry-and-pre-tension/537"
            }
          ],
          files:[{
            linkText: "Lectures 41-42 Blender File",
            filename: "Lecture 41-42.zip"                        
        }]
        },
        {
          lectureId: 43,
          section: "Cablenet Pavilion - Hyperbolic Paraboloid",
          title: "Analysis iteration 1 - Establishing pre-tension",          
          vimeoURL: "https://player.vimeo.com/video/729129075?h=a646a0cf6d",          
          duration: "21:00",
          preview: false,
          topicId: 538,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/43-analysis-iteration-1-establishing-pre-tension/538"
            }
          ],
          files:[]
        },
        {
          lectureId: 44,
          section: "Cablenet Pavilion - Hyperbolic Paraboloid",
          title: "Analysis iteration 2 - Adding props and stay cables",          
          vimeoURL: "https://player.vimeo.com/video/729132070?h=ede2eb6c42",          
          duration: "08:06",
          preview: false,
          topicId: 539,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/44-analysis-iteration-2-adding-props-and-stay-cables/539"
            }
          ],
          files:[
            {
              linkText: "Lecture 41-44 Jupyter Notebook",
              filename: "Lecture 41-44.zip"                        
              },{
                linkText: "Lecture 44 Blender File",
                filename: "Lecture 44.zip"                        
            }]          
        },
        {
          lectureId: 45,
          section: "Frei Otto's Dancing Fountain in Cablenet form",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/729133893?h=17d62ec338",          
          duration: "01:35",
          preview: true,
          topicId: 540,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/45-section-overview-cablenet-structures/540"
            }
          ],
          files:[]
        },
        {
          lectureId: 46,
          section: "Frei Otto's Dancing Fountain in Cablenet form",
          title: "Form-finding the initial geometry",          
          vimeoURL: "https://player.vimeo.com/video/729134164?h=45b1da40bc",          
          duration: "38:02",
          preview: false,
          topicId: 541,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/46-form-finding-the-initial-geometry/541"
            }
          ],
          files:[]
        },
        {
          lectureId: 47,
          section: "Frei Otto's Dancing Fountain in Cablenet form",
          title: "Roof pre-tension simulation",          
          vimeoURL: "https://player.vimeo.com/video/729142654?h=6e13cb6e64",          
          duration: "21:36",
          preview: false,
          topicId: 542,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/47-roof-pre-tension-simulation/542"
            }
          ],
          files:[]
        },
        {
          lectureId: 48,
          section: "Frei Otto's Dancing Fountain in Cablenet form",
          title: "Full structure pre-tension simulation",          
          vimeoURL: "https://player.vimeo.com/video/729146566?h=a0ed85e3f7",          
          duration: "18:27",
          preview: false,
          topicId: 543,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/48-full-structure-pre-tension-simulation/543"
            }
          ],
          files:[{
            linkText: "Lectures 47-48 Jupyter Notebook",
            filename: "Lecture 47-48.zip"                        
        }]
        },
        {
          lectureId: 49,
          section: "Frei Otto's Dancing Fountain in Cablenet form",
          title: "Applying load to the pre-tensioned structure",          
          vimeoURL: "https://player.vimeo.com/video/729150138?h=4b80493f0d",          
          duration: "23:38",
          preview: false,
          topicId: 544,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/49-applying-load-to-the-pre-tensioned-structure/544"
            }
          ],
          files:[
            {
              linkText: "Lecture 49 Jupyter Notebook",
              filename: "Lecture 49.zip"                        
              },{
                linkText: "Lecture 46-49 Blender File",
                filename: "Lecture 46-49.zip"                        
            }]            
        },
        {
          lectureId: 50,
          section: "Course wrap up",
          title: "Course wrap up",          
          vimeoURL: "https://player.vimeo.com/video/729155569?h=57b4323084",          
          duration: "02:47",
          preview: false,
          topicId: 545,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/50-course-wrap-up-cablenet-structures/545"
            }
          ],
          files:[]
        },
        {
          lectureId: 51,
          section: "Appendix: Building Axial Force Visualisations in Blender",
          title: "Blender data-viz - Structure setup & force data",          
          vimeoURL: "https://player.vimeo.com/video/706988385?h=98a121593e",
          duration: "19:02",
          preview: false,
          topicId: 546,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/51-blender-data-viz-structure-setup-force-data/546"
            }
          ],
          files:[{
            linkText: "Lecture 51 Jupyter Notebook",
            filename: "Lecture 51.zip"            
          }]
        },
        {
          lectureId: 52,
          section: "Appendix: Building Axial Force Visualisations in Blender",
          title: "Blender data-viz - Blocking out the code",          
          vimeoURL: "https://player.vimeo.com/video/706989515?h=7d6699cad6",
          duration: "22:56",
          preview: false,
          topicId: 547,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/52-blender-data-viz-blocking-out-the-code/547"
            }
          ],
          files:[]
        },
        {
          lectureId: 53,
          section: "Appendix: Building Axial Force Visualisations in Blender",
          title: "Blender data-viz - The transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/706990611?h=8485033203",
          duration: "22:00",
          preview: false,
          topicId: 548,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/53-blender-data-viz-the-transformation-matrix/548"
            }
          ],
          files:[]
        },
        {
          lectureId: 54,
          section: "Appendix: Building Axial Force Visualisations in Blender",
          title: "Blender data-viz - Generating materials from force data",          
          vimeoURL: "https://player.vimeo.com/video/706991530?h=d75eb0ea51",
          duration: "21:36",
          preview: false,
          topicId: 549,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/54-blender-data-viz-generating-materials-from-force-data/549"
            }
          ],
          files:[{
            linkText: "Lectures 51-54 Blender File",
            filename: "Lecture 51-54.zip"                        
        }]
        }  
      ]
    },
    {
      courseId: 17,
      title: "Non-linear finite element analysis of 2D catenary & cable structures using Python",
      tagline: "Build an iterative solution toolbox to analyse structures that exhibit geometric non-linearity due to large deflections",
      duration: 11,
      nlectures: 56,
      path: "course-17/lecture-1",
      infoURL:"https://www.degreetutors.com/non-linear-finite-element-analysis-of-2d-catenary-and-cable-structures/",
      video: true,
      text: true,
      visible: true,
      completeLectures: null,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/698919991?h=4a2d2d296f",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction and Course Breakdown",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/696204888?h=83dd8f6caf",          
          duration: "08:10",
          preview: true,          
          topicId: 556,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-2d-catenary-cable/556"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Introduction and Course Breakdown",
          title: "Course prerequisites",          
          vimeoURL: "https://player.vimeo.com/video/696208131?h=6086895b5c",          
          duration: "02:32",
          preview: true,
          topicId: 557,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-course-prerequisites-2d-catenary-cable/557"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "‘Heavy’ Cables - the Linear Solution",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696209293?h=5bf8348cbb",          
          duration: "02:09",
          preview: true,
          topicId: 558,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-section-overview-2d-catenary-cable/558"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "‘Heavy’ Cables - the Linear Solution",
          title: "Deriving a linear heavy cable equation",          
          vimeoURL: "https://player.vimeo.com/video/696210010?h=a4cb92ecb5",          
          duration: "10:06",
          preview: false,
          topicId: 559,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-deriving-a-linear-heavy-cable-equation/559"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "‘Heavy’ Cables - the Linear Solution",
          title: "Accounting for cable self-weight",          
          vimeoURL: "https://player.vimeo.com/video/696210801?h=8c29af0c49",          
          duration: "09:37",
          preview: false,
          topicId: 560,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-accounting-for-cable-self-weight/560"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "‘Heavy’ Cables - the Linear Solution",
          title: "Problem-specific boundary conditions",          
          vimeoURL: "https://player.vimeo.com/video/696211426?h=c9a06072d9",          
          duration: "11:12",
          preview: false,
          topicId: 561,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-problem-specific-boundary-conditions/561"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "‘Heavy’ Cables - the Linear Solution",
          title: "Solving for max cable tension",          
          vimeoURL: "https://player.vimeo.com/video/696212440?h=935158b63f",          
          duration: "23:34",
          preview: false,
          topicId: 562,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-solving-for-max-cable-tension/562"
            }
          ],
          files:[{
            linkText: "Lecture 7 Jupyter Notebook",
            filename: "Lecture 7.zip"            
          }]
        },
        {
          lectureId: 8,
          section: "Getting Comfortable with Non-linearity",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696213819?h=c33e0908b5",          
          duration: "01:05",
          preview: true,
          topicId: 563,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-section-overview-2d-catenary-cable/563"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Getting Comfortable with Non-linearity",
          title: "What is non-linear structural behaviour?",          
          vimeoURL: "https://player.vimeo.com/video/696214301?h=828f5f8af5",          
          duration: "12:50",
          preview: false,
          topicId: 564,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-what-is-non-linear-structural-behaviour/564"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Getting Comfortable with Non-linearity",
          title: "Large deflections and geometric non-linearity",          
          vimeoURL: "https://player.vimeo.com/video/696215486?h=5911668c62",          
          duration: "23:24",
          preview: false,
          topicId: 565,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-large-deflections-and-geometric-non-linearity/565"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Getting Comfortable with Non-linearity",
          title: "An iterative solution strategy",          
          vimeoURL: "https://player.vimeo.com/video/696218120?h=9950289b21",          
          duration: "14:24",
          preview: false,
          topicId: 566,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-an-iterative-solution-strategy/566"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "The Non-linear Stiffness Matrix",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696219606?h=20c44e6b57",          
          duration: "01:30",
          preview: true,
          topicId: 567,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-section-overview/567"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "The Non-linear Stiffness Matrix",
          title: "Building the transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/696225872?h=bcd59ef72b",          
          duration: "09:24",
          preview: false,
          topicId: 568,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-building-the-transformation-matrix/568"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "The Non-linear Stiffness Matrix",
          title: "The linear stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/696226974?h=a052d98187",          
          duration: "09:40",
          preview: false,
          topicId: 569,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-the-linear-stiffness-matrix/569"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "The Non-linear Stiffness Matrix",
          title: "Additional force due to large deflections",          
          vimeoURL: "https://player.vimeo.com/video/696227973?h=7f05cd1f31",          
          duration: "12:14",
          preview: false,
          topicId: 570,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-additional-force-due-to-large-deflections/570"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "The Non-linear Stiffness Matrix",
          title: "The local non-linear stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/696229475?h=b49dcc6ee1",          
          duration: "08:22",
          preview: false,
          topicId: 571,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-the-local-non-linear-stiffness-matrix/571"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "The Non-linear Stiffness Matrix",
          title: "The global non-linear stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/696230232?h=bb12e606fa",          
          duration: "13:04",
          preview: false,
          topicId: 572,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-the-global-non-linear-stiffness-matrix/572"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Building our 2D Solver Toolbox",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696233142?h=64f14ca8c6",          
          duration: "01:33",
          preview: true,
          topicId: 573,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-section-overview-2d-catenary-cable/573"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Building our 2D Solver Toolbox",
          title: "Initial setup and data import",          
          vimeoURL: "https://player.vimeo.com/video/696233683?h=f5fcdbcfed",          
          duration: "29:36",
          preview: false,
          topicId: 574,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-initial-setup-and-data-import/574"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Building our 2D Solver Toolbox",
          title: "Plotting the initial configuration",          
          vimeoURL: "https://player.vimeo.com/video/696235308?h=9ce6413f50",          
          duration: "17:07",
          preview: false,
          topicId: 575,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-plotting-the-initial-configuration/575"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Building our 2D Solver Toolbox",
          title: "Blocking out the main convergence loop",          
          vimeoURL: "https://player.vimeo.com/video/696236327?h=c2d215e9ea",          
          duration: "28:32",
          preview: false,
          topicId: 576,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-blocking-out-the-main-convergence-loop/576"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Building our 2D Solver Toolbox",
          title: "Building the transformation matrices",          
          vimeoURL: "https://player.vimeo.com/video/696238337?h=973295b734",          
          duration: "09:39",
          preview: false,
          topicId: 577,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-building-the-transformation-matrices/577"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Building our 2D Solver Toolbox",
          title: "Adding pre-tension to each member",          
          vimeoURL: "https://player.vimeo.com/video/696238980?h=756362f8c1",          
          duration: "08:08",
          preview: false,
          topicId: 578,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-adding-pre-tension-to-each-member/578"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Building our 2D Solver Toolbox",
          title: "Building the stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/696239623?h=4304c72c35",          
          duration: "14:48",
          preview: false,
          topicId: 579,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-building-the-stiffness-matrix/579"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Building our 2D Solver Toolbox",
          title: "Solving for displacements",          
          vimeoURL: "https://player.vimeo.com/video/696240681?h=334795cd78",          
          duration: "07:08",
          preview: false,
          topicId: 580,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-solving-for-displacements/580"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Building our 2D Solver Toolbox",
          title: "Updating the internal force system",          
          vimeoURL: "https://player.vimeo.com/video/696241223?h=d9c6746f6e",          
          duration: "07:10",
          preview: false,
          topicId: 581,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-updating-the-internal-force-system/581"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Building our 2D Solver Toolbox",
          title: "Building a convergence test function",          
          vimeoURL: "https://player.vimeo.com/video/696241805?h=83ddf212f4",          
          duration: "06:39",
          preview: false,
          topicId: 582,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-building-a-convergence-test-function/582"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Building our 2D Solver Toolbox",
          title: "Calculating axial forces",          
          vimeoURL: "https://player.vimeo.com/video/696242570?h=232fb2ac35",          
          duration: "04:34",
          preview: false,
          topicId: 583,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-calculating-axial-forces/583"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Building our 2D Solver Toolbox",
          title: "Allowing for smaller external force increments",          
          vimeoURL: "https://player.vimeo.com/video/696242968?h=d813d1be0f",          
          duration: "24:56",
          preview: false,
          topicId: 584,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-allowing-for-smaller-external-force-increments/584"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Building our 2D Solver Toolbox",
          title: "Generating a text summary output",          
          vimeoURL: "https://player.vimeo.com/video/696246270?h=e53feeac2a",          
          duration: "12:11",
          preview: false,
          topicId: 585,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-generating-a-text-summary-output/585"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Building our 2D Solver Toolbox",
          title: "Adding self-weight calculation",          
          vimeoURL: "https://player.vimeo.com/video/696247628?h=7334101f09",          
          duration: "14:46",
          preview: false,
          topicId: 586,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-adding-self-weight-calculation/586"
            }
          ],
          files:[{
            linkText: "Lectures 19-31 Jupyter Notebook",
            filename: "Lectures 19-31.zip"            
          }]
        },
        {
          lectureId: 32,
          section: "Visualising the Results",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696249397?h=b4e6024d9b",          
          duration: "00:57",
          preview: true,
          topicId: 587,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-section-overview-2d-catenary-cable/587"
            }
          ],
          files:[]
        },
        {
          lectureId: 33,
          section: "Visualising the Results",
          title: "Plot setup and data selection",          
          vimeoURL: "https://player.vimeo.com/video/696249707?h=6ec2bfe072",          
          duration: "09:26",
          preview: false,
          topicId: 588,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-plot-setup-and-data-selection/588"
            }
          ],
          files:[]
        },
        {
          lectureId: 34,
          section: "Visualising the Results",
          title: "Plotting the undeformed structure",          
          vimeoURL: "https://player.vimeo.com/video/696250406?h=9204ee315a",          
          duration: "05:09",
          preview: false,
          topicId: 589,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-plotting-the-undeformed-structure/589"
            }
          ],
          files:[]
        },
        {
          lectureId: 35,
          section: "Visualising the Results",
          title: "Building a colour scale",          
          vimeoURL: "https://player.vimeo.com/video/696251205?h=9186fe401f",          
          duration: "16:23",
          preview: false,
          topicId: 590,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-building-a-colour-scale/590"
            }
          ],
          files:[]
        },
        {
          lectureId: 36,
          section: "Visualising the Results",
          title: "Plotting the deformed structure",          
          vimeoURL: "https://player.vimeo.com/video/696253001?h=f983540f62",          
          duration: "05:46",
          preview: false,
          topicId: 591,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-plotting-the-deformed-structure/591"
            }
          ],
          files:[]
        },
        {
          lectureId: 37,
          section: "Visualising the Results",
          title: "Adding axial force labels",          
          vimeoURL: "https://player.vimeo.com/video/696253756?h=983e0fe1ea",          
          duration: "05:18",
          preview: false,
          topicId: 592,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-adding-axial-force-labels/592"
            }
          ],
          files:[]
        },
        {
          lectureId: 38,
          section: "Visualising the Results",
          title: "Plotting the applied forces",          
          vimeoURL: "https://player.vimeo.com/video/696254215?h=b1c395c471",          
          duration: "14:21",
          preview: false,
          topicId: 593,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-plotting-the-applied-forces/593"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Visualising the Results",
          title: "Plotting the reactions",          
          vimeoURL: "https://player.vimeo.com/video/696256016?h=9a81db3191",          
          duration: "04:51",
          preview: false,
          topicId: 594,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-plotting-the-reactions/594"
            }
          ],
          files:[{
            linkText: "Lectures 33-39 Jupyter Notebook",
            filename: "Lectures 33-39.zip"            
          }]
        },
        {
          lectureId: 40,
          section: "‘Heavy’ Cables - the Non-linear Solution",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696256477?h=2e67967226",          
          duration: "01:15",
          preview: true,
          topicId: 595,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-section-overview-2d-catenary-cable/595"
            }
          ],
          files:[]
        },
        {
          lectureId: 41,
          section: "‘Heavy’ Cables - the Non-linear Solution",
          title: "Exploring the convergence behaviour",          
          vimeoURL: "https://player.vimeo.com/video/696256973?h=614fec6c78",          
          duration: "20:45",
          preview: false,
          topicId: 596,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-exploring-the-convergence-behaviour/596"
            }
          ],
          files:[{
            linkText: "Lecture 41 Jupyter Notebook",
            filename: "Lecture 41.zip"            
          }]
        },
        {
          lectureId: 42,
          section: "‘Heavy’ Cables - the Non-linear Solution",
          title: "Modelling the cable with large axial stiffness",          
          vimeoURL: "https://player.vimeo.com/video/696259553?h=0a9d8e7639",          
          duration: "09:46",
          preview: false,
          topicId: 597,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/42-modelling-the-cable-with-large-axial-stiffness/597"
            }
          ],
          files:[]
        },
        {
          lectureId: 43,
          section: "‘Heavy’ Cables - the Non-linear Solution",
          title: "Introducing non-linearity by reducing the axial stiffness",          
          vimeoURL: "https://player.vimeo.com/video/696260979?h=7cf051db0d",          
          duration: "05:37",
          preview: false,
          topicId: 598,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/43-introducing-non-linearity-by-reducing-the-axial-stiffness/598"
            }
          ],
          files:[{
            linkText: "Lectures 42-43 Jupyter Notebook",
            filename: "Lectures 42-43.zip"            
          }]
        },
        {
          lectureId: 44,
          section: "‘Heavy’ Cables - the Non-linear Solution",
          title: "Linear vs. Non-linear comparison for a simple truss",          
          vimeoURL: "https://player.vimeo.com/video/696261734?h=de4f0d69ed",          
          duration: "11:40",
          preview: false,
          topicId: 599,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/44-linear-vs-non-linear-comparison-for-a-simple-truss/599"
            }
          ],
          files:[{
            linkText: "Lecture 44 Jupyter Notebook",
            filename: "Lecture 44.zip"            
          }]
        },
        {
          lectureId: 45,
          section: "Modelling Initial Geometry in Blender",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696270320?h=692fd99a0f",          
          duration: "01:15",
          preview: true,
          topicId: 600,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/45-section-overview-2d-catenary-cable/600"
            }
          ],
          files:[]
        },
        {
          lectureId: 46,
          section: "Modelling Initial Geometry in Blender",
          title: "Simulating initial catenary geometry",          
          vimeoURL: "https://player.vimeo.com/video/696270957?h=bbf04e46e6",          
          duration: "14:20",
          preview: false,
          topicId: 601,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/46-simulating-initial-catenary-geometry/601"
            }
          ],
          files:[]
        },
        {
          lectureId: 47,
          section: "Modelling Initial Geometry in Blender",
          title: "Basic geometry data export",          
          vimeoURL: "https://player.vimeo.com/video/696272160?h=052f2410bb",          
          duration: "08:41",
          preview: false,
          topicId: 602,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/47-basic-geometry-data-export/602"
            }
          ],
          files:[]
        },
        {
          lectureId: 48,
          section: "Modelling Initial Geometry in Blender",
          title: "Exporting cable definitions",          
          vimeoURL: "https://player.vimeo.com/video/696272860?h=664ba18e52",          
          duration: "05:28",
          preview: false,
          topicId: 603,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/48-exporting-cable-definitions/603"
            }
          ],
          files:[]
        },
        {
          lectureId: 49,
          section: "Modelling Initial Geometry in Blender",
          title: "Exporting restraint data",          
          vimeoURL: "https://player.vimeo.com/video/696273333?h=f7546190d4",          
          duration: "10:18",
          preview: false,
          topicId: 604,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/49-exporting-restraint-data/604"
            }
          ],
          files:[]
        },
        {
          lectureId: 50,
          section: "Modelling Initial Geometry in Blender",
          title: "Exporting force location data",          
          vimeoURL: "https://player.vimeo.com/video/696274320?h=686b05b601",          
          duration: "05:26",
          preview: false,
          topicId: 605,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/50-exporting-force-location-data/605"
            }
          ],
          files:[{
            linkText: "Lectures 46-50 Blender File ",
            filename: "Lectures 46-50.zip"            
          }]
        },
        {
          lectureId: 51,
          section: "Mixing Cables and Bars in the Same Model",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/696274860?h=b30f93c900",          
          duration: "01:00",
          preview: true,
          topicId: 606,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/51-section-overview-2d-catenary-cable/606"
            }
          ],
          files:[]
        },
        {
          lectureId: 52,
          section: "Mixing Cables and Bars in the Same Model",
          title: "Modifying our code for different element types",          
          vimeoURL: "https://player.vimeo.com/video/696275322?h=815743e184",          
          duration: "17:25",
          preview: false,
          topicId: 607,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/52-modifying-our-code-for-different-element-types/607"
            }
          ],
          files:[{
            linkText: "Lecture 52 Jupyter Notebook",
            filename: "Lecture 52.zip"            
          }]
        },
        {
          lectureId: 53,
          section: "Mixing Cables and Bars in the Same Model",
          title: "Analysing a combined cable and bar structure",          
          vimeoURL: "https://player.vimeo.com/video/696277611?h=d9a3f10c8c",          
          duration: "09:45",
          preview: false,
          topicId: 608,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/53-analysing-a-combined-cable-and-bar-structure/608"
            }
          ],
          files:[]
        },
        {
          lectureId: 54,
          section: "Mixing Cables and Bars in the Same Model",
          title: "Removing slack cable elements",          
          vimeoURL: "https://player.vimeo.com/video/696278775?h=40461cb47e",          
          duration: "20:53",
          preview: false,
          topicId: 609,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/54-removing-slack-cable-elements/609"
            }
          ],
          files:[{
            linkText: "Lecture 54 Jupyter Notebook",
            filename: "Lecture 54.zip"            
          }]
        },
        {
          lectureId: 55,
          section: "Mixing Cables and Bars in the Same Model",
          title: "Antenna tower - modelling and analysis",          
          vimeoURL: "https://player.vimeo.com/video/696281784?h=3f9625612d",          
          duration: "15:46",
          preview: true,
          topicId: 610,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/55-antenna-tower-modelling-and-analysis/610"
            }
          ],
          files:[{
            linkText: "Lecture 55 Jupyter Notebook",
            filename: "Lecture 55a.zip"            
          },
          {
            linkText: "Lecture 55 Blender File",
            filename: "Lecture 55b.zip"            
          }]
        },
        {
          lectureId: 56,
          section: "Mixing Cables and Bars in the Same Model",
          title: "Course wrap up & Certificate of Completion",          
          vimeoURL: "https://player.vimeo.com/video/696283700?h=382d706c2a",          
          duration: "02:54",
          preview: false,
          topicId: 611,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/56-course-wrap-up-certificate-of-completion/611"
            }
          ],
          files:[]
        },
        {
          lectureId: 57,
          section: "Appendix - Introduction to Blender",
          title: "How can Blender help us?",          
          vimeoURL: "https://player.vimeo.com/video/616777065?h=bfab4fcffc",          
          duration: "06:18",
          preview: false,
          topicId: 612,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/57-how-can-blender-help-us-2d-catenary-cable/612"
            }
          ],
          files:[]
        },
        {
          lectureId: 58,
          section: "Appendix - Introduction to Blender",
          title: "Downloading and installing Blender",          
          vimeoURL: "https://player.vimeo.com/video/616779499?h=949a580c75",          
          duration: "03:22",
          preview: false,
          topicId: 613,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/58-downloading-and-installing-blender/613"
            }
          ],
          files:[]
        },
        {
          lectureId: 59,
          section: "Appendix - Introduction to Blender",
          title: "Blender overview and interface",          
          vimeoURL: "https://player.vimeo.com/video/616779855?h=ac4404ebc6",          
          duration: "15:36",
          preview: false,
          topicId: 614,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/59-blender-overview-and-interface/614"
            }
          ],
          files:[]
        },
        {
          lectureId: 60,
          section: "Appendix - Introduction to Blender",
          title: "Object versus edit mode",          
          vimeoURL: "https://player.vimeo.com/video/616782819?h=8b8ca42301",          
          duration: "10:06",
          preview: false,
          topicId: 615,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/60-object-versus-edit-mode/615"
            }
          ],
          files:[]
        },
        {
          lectureId: 61,
          section: "Appendix - Introduction to Blender",
          title: "Rectilinear modelling",          
          vimeoURL: "https://player.vimeo.com/video/616784766?h=3ba9abf76e",          
          duration: "10:50",
          preview: false,
          topicId: 616,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/61-rectilinear-modelling/616"
            }
          ],
          files:[]
        },
        {
          lectureId: 62,
          section: "Appendix - Introduction to Blender",
          title: "Organic modelling",          
          vimeoURL: "https://player.vimeo.com/video/616786886?h=d1e5ccd7a1",          
          duration: "18:26",
          preview: true,
          topicId: 617,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/62-organic-modelling/617"
            }
          ],
          files:[{
            linkText: "Lecture 62 Blender File",
            filename: "Lecture 62.zip"                        
        }]
        },
      ]
    },
    {
      courseId: 16,
      title: "Multi-Degree of Freedom Dynamics, Modal Analysis and Seismic Response Simulation in Python",
      tagline: "Build the knowledge and tools to decode the dynamic response of real-world structures to real-world loads",
      duration: 9.5,
      nlectures: 40,
      path: "course-16/lecture-1",
      infoURL:"https://www.degreetutors.com/multi-degree-of-freedom-dynamics-modal-analysis-and-seismic-response-simulation-in-python/",
      video: true,
      text: true,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/643861933?h=d8ae87d046",
      discourse: true,
      lectures:[
        {
          lectureId: 1,
          section: "Welcome and preliminaries",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/643048650?h=9f05a62107",          
          duration: "04:24",
          preview: true,          
          topicId: 454,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-mdof-dynamics/454"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and preliminaries",
          title: "Course prerequisites and do I need to know Python?",          
          vimeoURL: "https://player.vimeo.com/video/643049647?h=6c042ccf54",          
          duration: "03:55",
          preview: true,
          topicId: 455,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-course-prerequisites-and-do-i-need-to-know-python/455"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Welcome and preliminaries",
          title: "Getting your coding environment set up",          
          vimeoURL: "https://player.vimeo.com/video/643065657?h=de1a3935e6",          
          duration: "12:04",
          preview: true,
          topicId: 456,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-getting-your-coding-environment-set-up/456"
            },
            {
              linkText: "Anaconda website",
              url: "https://www.anaconda.com/"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Introduction to ground motion modelling",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/643064320?h=a9ea757aab",          
          duration: "03:53",
          preview: true,
          topicId: 457,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-section-overview-mdof-dynamics/457"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Introduction to ground motion modelling",
          title: "Overview of earthquake ground motion",          
          vimeoURL: "https://player.vimeo.com/video/643029843?h=d6239b5666",          
          duration: "14:57",
          preview: false,
          topicId: 458,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-overview-of-earthquake-ground-motion/458"
            },
            {
              linkText:"20 Largest Earthquakes in the World as of September 2021, USGS",
              url: "https://www.usgs.gov/natural-hazards/earthquake-hazards/science/20-largest-earthquakes-world?qt-science_center_objects=0#qt-science_center_objects"
            },
            {
              linkText: "The Severity of an Earthquake, USGS",
              url: "https://pubs.usgs.gov/gip/earthq4/severitygip.html"
            },
            {
              linkText: "Modified Mercalli Intensity Scale, USGS",
              url: "https://www.usgs.gov/media/images/modified-mercalli-intensity-scale"
            },
            {
              linkText: "Isoseismal map of the Tohoku Earthquake, Japan, USGD",
              url: "https://earthquake.usgs.gov/earthquakes/eventpage/official20110311054624120_30/map"
            },
            {
              linkText: "El Centro earthquake record source",
              url: "http://www.vibrationdata.com/elcentro.htm"
            },
          ],
          files:[{
            linkText: "El-Centro N-S ground acceleration record (.csv)",
            filename: "Lecture 5.zip"            
          }]
        },
        {
          lectureId: 6,
          section: "Introduction to ground motion modelling",
          title: "Capturing the influence of ground motion",          
          vimeoURL: "https://player.vimeo.com/video/641061450?h=b081bd17e2",          
          duration: "22:10",
          preview: false,
          topicId: 459,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-capturing-the-influence-of-ground-motion/459"
            }
          ],
          files:[{
            linkText: "Lecture 6 Jupyter Notebook",
            filename: "Lecture 6.zip"            
          }]
        },
        {
          lectureId: 7,
          section: "Introduction to ground motion modelling",
          title: "Motion transmissibility and isolation",          
          vimeoURL: "https://player.vimeo.com/video/641062450?h=b779e8a9a1",          
          duration: "12:20",
          preview: false,
          topicId: 460,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-motion-transmissibility-and-isolation/460"
            }
          ],
          files:[{
            linkText: "Lecture 7 Jupyter Notebook",
            filename: "Lecture 7.zip"            
          }]
        },
        {
          lectureId: 8,
          section: "Introduction to ground motion modelling",
          title: "Simulating ground motion response",          
          vimeoURL: "https://player.vimeo.com/video/641062827?h=387198c0ba",          
          duration: "19:26",
          preview: false,
          topicId: 461,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-simulating-ground-motion-response/461"
            }
          ],
          files:[{
            linkText: "Lecture 8 Jupyter Notebook",
            filename: "Lecture 8.zip"            
          }]
        },
        {
          lectureId: 9,
          section: "Introduction to ground motion modelling",
          title: "Realistic ground motion response",          
          vimeoURL: "https://player.vimeo.com/video/641063698?h=6ac69396c2",          
          duration: "06:56",
          preview: false,
          topicId: 462,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-realistic-ground-motion-response/462"
            }
          ],
          files:[{
            linkText: "Lectures 9-10 Jupyter Notebook",
            filename: "Lectures 9-10.zip"            
          }]
        },
        {
          lectureId: 10,
          section: "Introduction to ground motion modelling",
          title: "Integrating ground acceleration",          
          vimeoURL: "https://player.vimeo.com/video/641063921?h=10609e40ac",          
          duration: "16:48",
          preview: false,
          topicId: 463,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-integrating-ground-acceleration/463"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Introduction to ground motion modelling",
          title: "Introduction to response spectra",          
          vimeoURL: "https://player.vimeo.com/video/641064462?h=b48cd00fa5",          
          duration: "08:00",
          preview: false,
          topicId: 464,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-introduction-to-response-spectra/464"
            }
          ],
          files:[{
            linkText: "Lecture 11 Jupyter Notebook",
            filename: "Lecture 11.zip"            
          }]
        },
        {
          lectureId: 12,
          section: "Modelling Multi-DoF Dynamic Systems",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/643178548?h=226bf4a5e7",          
          duration: "02:00",
          preview: true,
          topicId: 465,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-section-overview-mdof-dynamics/465"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Modelling Multi-DoF Dynamic Systems",
          title: "Introduction to MDoF systems",          
          vimeoURL: "https://player.vimeo.com/video/641064856?h=b44eafd460",          
          duration: "16:58",
          preview: false,
          topicId: 466,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-introduction-to-mdof-systems/466"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Modelling Multi-DoF Dynamic Systems",
          title: "MDoF Equations of Motion",          
          vimeoURL: "https://player.vimeo.com/video/641065608?h=9c75ce36ec",          
          duration: "09:54",
          preview: false,
          topicId: 467,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-mdof-equations-of-motion/467"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Modelling Multi-DoF Dynamic Systems",
          title: "Direct integration of the coupled EoM",          
          vimeoURL: "https://player.vimeo.com/video/641066071?h=790542e8f8",          
          duration: "18:25",
          preview: false,
          topicId: 468,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-direct-integration-of-the-coupled-eom/468"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Modelling Multi-DoF Dynamic Systems",
          title: "Solving the coupled EoM by direct integration",          
          vimeoURL: "https://player.vimeo.com/video/641066969?h=a26e0b760c",          
          duration: "17:29",
          preview: false,
          topicId: 469,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-solving-the-coupled-eom-by-direct-integration/469"
            }
          ],
          files:[{
            linkText: "Lectures 16-17 Jupyter Notebook",
            filename: "Lectures 16-17.zip"            
          }]
        },
        {
          lectureId: 17,
          section: "Modelling Multi-DoF Dynamic Systems",
          title: "Using SciPy’s ODEINT solver",          
          vimeoURL: "https://player.vimeo.com/video/641067547?h=bdfd96781a",          
          duration: "19:56",
          preview: false,
          topicId: 470,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-using-scipy-s-odeint-solver/470"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/643178935?h=021454193d",          
          duration: "03:06",
          preview: true,
          topicId: 471,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-section-overview-mdof-dynamics/471"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "What is modal analysis and how does it help us?",          
          vimeoURL: "https://player.vimeo.com/video/641068323?h=78b0837566",          
          duration: "20.24",
          preview: false,
          topicId: 472,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-what-is-modal-analysis-and-how-does-it-help-us/472"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Finding natural frequencies and mode shapes",          
          vimeoURL: "https://player.vimeo.com/video/641069220?h=aa16b67c5d",          
          duration: "32:50",
          preview: false,
          topicId: 473,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-finding-natural-frequencies-and-mode-shapes/473"
            }
          ],
          files:[{
            linkText: "Lecture 20 Jupyter Notebook",
            filename: "Lecture 20.zip"            
          }]
        },
        {
          lectureId: 21,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Matrix representation and the eigenproblem",          
          vimeoURL: "https://player.vimeo.com/video/641070434?h=a93ae2cb69",          
          duration: "27:18",
          preview: false,
          topicId: 474,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-matrix-representation-and-the-eigenproblem/474"
            }
          ],
          files:[{
            linkText: "Lecture 21 Jupyter Notebook",
            filename: "Lecture 21.zip"            
          }]
        },
        {
          lectureId: 22,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Modal matrix and mass normalisation",          
          vimeoURL: "https://player.vimeo.com/video/641071776?h=70a00997a8",          
          duration: "16:34",
          preview: false,
          topicId: 475,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-modal-matrix-and-mass-normalisation/475"
            }
          ],
          files:[{
            linkText: "Lecture 22 Jupyter Notebook",
            filename: "Lecture 22.zip"            
          }]
        },
        {
          lectureId: 23,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Orthogonality and Decoupling Equations of Motion",          
          vimeoURL: "https://player.vimeo.com/video/641072446?h=fd8d5e19cf",          
          duration: "26:39",
          preview: false,
          topicId: 476,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-orthogonality-and-decoupling-equations-of-motion/476"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Modal superposition - forced vibration",          
          vimeoURL: "https://player.vimeo.com/video/641074106?h=877ae962a6",          
          duration: "22:51",
          preview: false,
          topicId: 477,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-modal-superposition-forced-vibration/477"
            }
          ],
          files:[{
            linkText: "Lectures 24-26 Jupyter Notebook",
            filename: "Lectures 24-26.zip"            
          }]
        },
        {
          lectureId: 25,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Comparing modal superposition with direct integration - forced response",          
          vimeoURL: "https://player.vimeo.com/video/641074974?h=77e134a8ae",          
          duration: "12:12",
          preview: false,
          topicId: 478,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-comparing-modal-superposition-with-direct-integration-forced-response/478"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Visualising the forced vibration response",          
          vimeoURL: "https://player.vimeo.com/video/641075687?h=460f48add6",          
          duration: "25:01",
          preview: false,
          topicId: 479,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-visualising-the-forced-vibration-response/479"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Modal superposition - free vibration",          
          vimeoURL: "https://player.vimeo.com/video/641077202?h=1d30099828",          
          duration: "18:28",
          preview: false,
          topicId: 480,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-modal-superposition-free-vibration/480"
            }
          ],
          files:[{
            linkText: "Lectures 27-28 Jupyter Notebook",
            filename: "Lectures 27-28.zip"            
          }]
        },
        {
          lectureId: 28,
          section: "Modal Analysis and Decoupling the Equations of Motion",
          title: "Compare modal superposition with direct integration - free vibration",          
          vimeoURL: "https://player.vimeo.com/video/641078509?h=1a19962ad1",          
          duration: "05:46",
          preview: false,
          topicId: 481,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-compare-modal-superposition-with-direct-integration-free-vibration/481"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Damping Orthogonality",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/643179458?h=45dab86076",          
          duration: "03:02",
          preview: true,
          topicId: 482,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-section-overview-mdof-dynamics/482"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Damping Orthogonality",
          title: "Rayleigh damping",          
          vimeoURL: "https://player.vimeo.com/video/641406758?h=1a520012eb",          
          duration: "21:01",
          preview: false,
          topicId: 483,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-rayleigh-damping/483"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Damping Orthogonality",
          title: "General determination of the damping matrix",          
          vimeoURL: "https://player.vimeo.com/video/641407653?h=3ee8713477",          
          duration: "07:50",
          preview: false,
          topicId: 484,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-general-determination-of-the-damping-matrix/484"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Damping Orthogonality",
          title: "Calculating the damping matrix",          
          vimeoURL: "https://player.vimeo.com/video/641425915?h=154987e66f",          
          duration: "14:30",
          preview: false,
          topicId: 485,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-calculating-the-damping-matrix/485"
            }
          ],
          files:[{
            linkText: "Lecture 32 Jupyter Notebook",
            filename: "Lecture 32.zip"            
          }]
        },
        {
          lectureId: 33,
          section: "Damping Orthogonality",
          title: "Direct integration versus modal superposition for a damped system",          
          vimeoURL: "https://player.vimeo.com/video/641426668?h=2f33015df2",          
          duration: "08:38",
          preview: false,
          topicId: 486,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-direct-integration-versus-modal-superposition-for-a-damped-system/486"
            }
          ],
          files:[{
            linkText: "Lecture 33 Jupyter Notebook",
            filename: "Lecture 33.zip"            
          }]
        },
        {
          lectureId: 34,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/643180014?h=c99d825111",          
          duration: "03:01",
          preview: true,
          topicId: 487,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-section-overview-mdof-dynamics/487"
            }
          ],
          files:[]
        },
        {
          lectureId: 35,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Equations of motion N-storey shear building",          
          vimeoURL: "https://player.vimeo.com/video/642669026?h=556231d679",          
          duration: "13:52",
          preview: false,
          topicId: 488,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-equations-of-motion-n-storey-shear-building/488"
            }
          ],
          files:[{
            linkText: "Lectures 35-39 Jupyter Notebook",
            filename: "Lectures 35-39.zip"            
          }]
        },
        {
          lectureId: 36,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Automating modal analysis for NDoF",          
          vimeoURL: "https://player.vimeo.com/video/642669133?h=a4a77c1d00",          
          duration: "25:44",
          preview: false,
          topicId: 489,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-automating-modal-analysis-for-ndof/489"
            }
          ],
          files:[]
        },
        {
          lectureId: 37,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Ground motion response using modal superposition",          
          vimeoURL: "https://player.vimeo.com/video/642669386?h=7f75c673ae",          
          duration: "11:15",
          preview: false,
          topicId: 490,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-ground-motion-response-using-modal-superposition/490"
            }
          ],
          files:[]
        },
        {
          lectureId: 38,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Base shear and overturning moments",          
          vimeoURL: "https://player.vimeo.com/video/642683785?h=fc0233e006",          
          duration: "11:33",
          preview: false,
          topicId: 491,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-base-shear-and-overturning-moments/491"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Visualising the structural response for NDoF",          
          vimeoURL: "https://player.vimeo.com/video/642719803?h=a3d3753c78",          
          duration: "25:22",
          preview: false,
          topicId: 492,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-visualising-the-structural-response-for-ndof/492"
            }
          ],
          files:[]
        },
        {
          lectureId: 40,
          section: "Bringin it all together: N-storey response to earthquake ground motion",
          title: "Course wrap up and completion certificate",          
          vimeoURL: "https://player.vimeo.com/video/643065292?h=9b70792cc2",          
          duration: "03:17",
          preview: false,
          topicId: 493,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-course-wrap-up-and-completion-certificate/493"
            }
          ],
          files:[]
        }                
      ]
    },
    {
      courseId: 15,      
      title: "Finite Element Analysis of Continuum Structures in Python",
      tagline: "Use the Isoparametric Finite Element Method to build an analysis tool for 2D structures in Python",duration: 18.5,
      nlectures: 81,
      path: "course-15/lecture-1",
      infoURL: "https://www.degreetutors.com/isoparametric-finite-element-analysis-of-continuum-structures-in-python/",
      video: true,
      text: false,
      visible: true,
      access: "private",
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/594081250?h=47b57cbbb3",
      discourse: true,
      lectures: [        
        {
          lectureId: 1,
          section: "Welcome and preliminaries",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/594082308",          
          duration: "08:11",
          
          preview: true,          
          topicId: 381,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-2d-continuum-structures/381"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and preliminaries",
          title: "What you should cover before this course",          
          vimeoURL: "https://player.vimeo.com/video/594086677",
          duration: "04:58",
          
          preview: true,
          topicId: 382,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-what-you-should-cover-before-this-course-2d-continuum-structures/382"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "The Isoparametric Finite Element Method",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/594088652",
          duration: "01:39",
          
          preview: true,
          topicId: 383,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-section-overview-2d-continuum-structures/383"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "The Isoparametric Finite Element Method",
          title: "Bar stiffness using generalised coordinates",
          vimeoURL: "https://player.vimeo.com/video/594089704",
          duration: "15:54",
          
          preview: false,
          topicId: 384,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-bar-stiffness-using-generalised-coordinates/384"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "The Isoparametric Finite Element Method",
          title: "Bar stiffness using shape functions",
          vimeoURL: "https://player.vimeo.com/video/594091928",
          duration: "18:52",
          
          preview: false,
          topicId: 385,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-bar-stiffness-using-shape-functions/385"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "The Isoparametric Finite Element Method",
          title: "Quad elements and shape functions",
          vimeoURL: "https://player.vimeo.com/video/594094501",
          duration: "12:39",
          
          preview: false,
          topicId: 386,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-quad-elements-and-shape-functions/386"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "The Isoparametric Finite Element Method",
          title: "The strain-displacement matrix, B",
          vimeoURL: "https://player.vimeo.com/video/594096309",
          duration: "14:32",
          
          preview: false,
          topicId: 387,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-the-strain-displacement-matrix-b/387"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "The Isoparametric Finite Element Method",
          title: "The Jacobian Matrix, J",
          vimeoURL: "https://player.vimeo.com/video/594097975",
          duration: "09:18",
          
          preview: false,
          topicId: 388,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-the-jacobian-matrix-j/388"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "The Isoparametric Finite Element Method",
          title: "Calculating the strain-displacement matrix",
          vimeoURL: "https://player.vimeo.com/video/594099052",
          duration: "21:56",
          
          preview: false,
          topicId: 389,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-calculating-the-strain-displacement-matrix/389"
            }
          ],
          files:[{
            linkText: "Lecture 9 Jupyter Notebook",
            filename: "Lecture 9.zip"            
          }]
        },
        {
          lectureId: 10,
          section: "The Isoparametric Finite Element Method",
          title: "Accounting for plane-stress/strain",
          vimeoURL: "https://player.vimeo.com/video/594100873",
          duration: "17:36",
          
          preview: false,
          topicId: 390,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-accounting-for-plane-stress-strain/390"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "The Isoparametric Finite Element Method",
          title: "The equivalent surface force vector",
          vimeoURL: "https://player.vimeo.com/video/594104831",
          duration: "22:37",
          
          preview: false,
          topicId: 391,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-the-equivalent-surface-force-vector/391"
            }
          ],
          files:[{
            linkText: "Lectures 11-12 Jupyter Notebook",
            filename: "Lectures 11-12.zip"            
          }]
        },
        {
          lectureId: 12,
          section: "The Isoparametric Finite Element Method",
          title: "The equivalent body force vector",
          vimeoURL: "https://player.vimeo.com/video/594106534",
          duration: "09:17",
          
          preview: false,
          topicId: 392,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-the-equivalent-body-force-vector/392"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Gauss-Legendre Numerical Integration",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/594107420",
          duration: "01:25",
          
          preview: true,
          topicId: 393,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-section-overview-2d-continuum-structures/393"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Gauss-Legendre Numerical Integration",
          title: "Gauss numerical integration scheme",
          vimeoURL: "https://player.vimeo.com/video/594108179",
          duration: "20:08",
          
          preview: false,
          topicId: 394,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-gauss-numerical-integration-scheme/394"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Gauss-Legendre Numerical Integration",
          title: "Numerical integration in 2D",
          vimeoURL: "https://player.vimeo.com/video/594111402",
          duration: "17:25",
          
          preview: false,
          topicId: 395,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-numerical-integration-in-2d/395"
            }
          ],
          files:[{
            linkText: "Lecture 15 Jupyter Notebook",
            filename: "Lecture 15.zip"            
          }]
        },
        {
          lectureId: 16,
          section: "Gauss-Legendre Numerical Integration",
          title: "Calculating the element stiffness matrix",
          vimeoURL: "https://player.vimeo.com/video/594113152",
          duration: "20:43",
          
          preview: false,
          topicId: 396,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-calculating-the-element-stiffness-matrix/396"
            }
          ],
          files:[{
            linkText: "Lectures 16-17 Jupyter Notebook",
            filename: "Lectures 16-17.zip"            
          }]
        },
        {
          lectureId: 17,
          section: "Gauss-Legendre Numerical Integration",
          title: "Stiffness matrix convergence",
          vimeoURL: "https://player.vimeo.com/video/594115889",
          duration: "07:57",
          
          preview: false,
          topicId: 397,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-stiffness-matrix-convergence/397"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Generating Structure and Mesh Data",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/594116883",
          duration: "01:11",
          
          preview: true,
          topicId: 398,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-section-overview-2d-continuum-structures/398"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Generating Structure and Mesh Data",
          title: "Generating a simple quad mesh in Blender",
          vimeoURL: "https://player.vimeo.com/video/594117562",
          duration: "07:09",
          
          preview: false,
          topicId: 399,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-generating-a-simple-quad-mesh-in-blender/399"
            }
          ],
          files:[{
            linkText: "Lectures 19-21 Blender File",
            filename: "Lectures 19-21.zip"            
          }]
        },
        {
          lectureId: 20,
          section: "Generating Structure and Mesh Data",
          title: "Exporting mesh data from Blender",
          vimeoURL: "https://player.vimeo.com/video/594118226",
          duration: "21:02",
          
          preview: false,
          topicId: 400,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-exporting-mesh-data-from-blender/400"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Generating Structure and Mesh Data",
          title: "Exporting support and point load data",
          vimeoURL: "https://player.vimeo.com/video/594121415",
          duration: "27:09",
          
          preview: false,
          topicId: 401,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-exporting-support-and-point-load-data/401"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Generating Structure and Mesh Data",
          title: "Importing support, loading and mesh data",
          vimeoURL: "https://player.vimeo.com/video/594123075",
          duration: "21:58",
          
          preview: false,
          topicId: 402,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-importing-support-loading-and-mesh-data/402"
            }
          ],
          files:[{
            linkText: "Lecture 22 Jupyter Notebook",
            filename: "Lecture 22.zip"            
          }]
        },
        {
          lectureId: 23,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/594124577",
          duration: "01:18",
          
          preview: true,
          topicId: 403,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-section-overview-2d-continuum-structures/403"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Setting analysis parameters and plotting the structure",
          vimeoURL: "https://player.vimeo.com/video/594124957",
          duration: "15:07",
          
          preview: false,
          topicId: 404,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-setting-analysis-parameters-and-plotting-the-structure/404"
            }
          ],
          files:[{
            linkText: "Lectures 24-32 Jupyter Notebook",
            filename: "Lectures 24-32.zip"            
          }]
        },
        {
          lectureId: 25,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Building the global force vector",
          vimeoURL: "https://player.vimeo.com/video/594126040",
          duration: "07:12",
          
          preview: false,
          topicId: 405,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-building-the-global-force-vector/405"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Defining the element stiffness matrix",
          vimeoURL: "https://player.vimeo.com/video/594129434",
          duration: "06:23",
          
          preview: false,
          topicId: 406,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-defining-the-element-stiffness-matrix/406"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Assigning the element stiffness coefficients",
          vimeoURL: "https://player.vimeo.com/video/595781855",
          duration: "14:56",
          
          preview: false,
          topicId: 407,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-assigning-the-element-stiffness-coefficients/407"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Building the structure stiffness matrix",
          vimeoURL: "https://player.vimeo.com/video/595782515",
          duration: "10:58",
          
          preview: false,
          topicId: 408,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-building-the-structure-stiffness-matrix/408"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Solving the structure",
          vimeoURL: "https://player.vimeo.com/video/595782941",
          duration: "08:11",
          
          preview: false,
          topicId: 409,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-solving-the-structure/409"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Visualising the deformed structure",
          vimeoURL: "https://player.vimeo.com/video/595783282",
          duration: "06:12",
          
          preview: false,
          topicId: 410,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-visualising-the-deformed-structure/410"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Generating detailed text output",
          vimeoURL: "https://player.vimeo.com/video/595783643",
          duration: "08:27",
          
          preview: false,
          topicId: 411,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-generating-detailed-text-output/411"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Implementing the Isoparametric Finite Element Method",
          title: "Euler-Bernoulli deflection validation",
          vimeoURL: "https://player.vimeo.com/video/595783925",
          duration: "13:10",
          
          preview: false,
          topicId: 412,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-euler-bernoulli-deflection-validation/412"
            }
          ],
          files:[]
        },
        {
          lectureId: 33,
          section: "Visualising the Strain and Stress Fields",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/595785139",
          duration: "02:24",
          
          preview: true,
          topicId: 413,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-section-overview/413"
            }
          ],
          files:[]
        },
        {
          lectureId: 34,
          section: "Visualising the Strain and Stress Fields",
          title: "Generating the stress calculation grid",
          vimeoURL: "https://player.vimeo.com/video/595785812",
          duration: "08:49",
          
          preview: false,
          topicId: 414,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-generating-the-stress-calculation-grid/414"
            }
          ],
          files:[{
            linkText: "Lectures 34-42 Jupyter Notebook",
            filename: "Lectures 34-42.zip"            
          }]
        },
        {
          lectureId: 35,
          section: "Visualising the Strain and Stress Fields",
          title: "Visualising the stress calculation grid",
          vimeoURL: "https://player.vimeo.com/video/595786478",
          duration: "04:32",
          
          preview: false,
          topicId: 415,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-visualising-the-stress-calculation-grid/415"
            }
          ],
          files:[]
        },
        {
          lectureId: 36,
          section: "Visualising the Strain and Stress Fields",
          title: "Calculating the strain and stress fields",
          vimeoURL: "https://player.vimeo.com/video/595788761",
          duration: "17:15",
          
          preview: false,
          topicId: 416,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-calculating-the-strain-and-stress-fields/416"
            }
          ],
          files:[]
        },
        {
          lectureId: 37,
          section: "Visualising the Strain and Stress Fields",
          title: "Visualising the normal stress distribution",
          vimeoURL: "https://player.vimeo.com/video/595789783",
          duration: "18:46",
          
          preview: false,
          topicId: 417,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-visualising-the-normal-stress-distribution/417"
            }
          ],
          files:[]
        },
        {
          lectureId: 38,
          section: "Visualising the Strain and Stress Fields",
          title: "Building normal strain/stress contour plots",
          vimeoURL: "https://player.vimeo.com/video/595790910",
          duration: "15:17",
          
          preview: false,
          topicId: 418,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-building-normal-strain-stress-contour-plots/418"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Visualising the Strain and Stress Fields",
          title: "Building shear stress/strain contour plots",
          vimeoURL: "https://player.vimeo.com/video/595791653",
          duration: "10:34",
          
          preview: false,
          topicId: 419,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-building-shear-stress-strain-contour-plots/419"
            }
          ],
          files:[]
        },
        {
          lectureId: 40,
          section: "Visualising the Strain and Stress Fields",
          title: "Building a stress/strain interpolation query function",
          vimeoURL: "https://player.vimeo.com/video/595792289",
          duration: "13:22",
          
          preview: false,
          topicId: 420,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-building-a-stress-strain-interpolation-query-function/420"
            }
          ],
          files:[]
        },
        {
          lectureId: 41,
          section: "Visualising the Strain and Stress Fields",
          title: "Visualising stress distributions at a vertical section",
          vimeoURL: "https://player.vimeo.com/video/595792927",
          duration: "11:34",
          
          preview: false,
          topicId: 421,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-visualising-stress-distributions-at-a-vertical-section/421"
            }
          ],
          files:[]
        },
        {
          lectureId: 42,
          section: "Visualising the Strain and Stress Fields",
          title: "Euler-Bernoulli stress validation",
          vimeoURL: "https://player.vimeo.com/video/595793437",
          duration: "09:30",
          
          preview: false,
          topicId: 422,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/42-euler-bernoulli-stress-validation/422"
            }
          ],
          files:[]
        },
        {
          lectureId: 43,
          section: "Implementing Area and Volume Forces",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/595793927",
          duration: "01:13",
          
          preview: true,
          topicId: 423,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/43-section-overview-2d-continuum-structures/423"
            }
          ],
          files:[]
        },
        {
          lectureId: 44,
          section: "Implementing Area and Volume Forces",
          title: "Implementing self-weight in our analysis",
          vimeoURL: "https://player.vimeo.com/video/595794185",
          duration: "15:23",
          
          preview: false,
          topicId: 424,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/44-implementing-self-weight-in-our-analysis/424"
            }
          ],
          files:[{
            linkText: "Lectures 44-52 Jupyter Notebook",
            filename: "Lectures 44-52.zip"            
          }]
        },
        {
          lectureId: 45,
          section: "Implementing Area and Volume Forces",
          title: "Correcting self-weight reactions",
          vimeoURL: "https://player.vimeo.com/video/595794905",
          duration: "18:59",
          
          preview: false,
          topicId: 425,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/45-correcting-self-weight-reactions/425"
            }
          ],
          files:[]
        },
        {
          lectureId: 46,
          section: "Implementing Area and Volume Forces",
          title: "Distributed loading - Interpolation limits",
          vimeoURL: "https://player.vimeo.com/video/595799888",
          duration: "15:28",
          
          preview: false,
          topicId: 426,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/46-distributed-loading-interpolation-limits/426"
            }
          ],
          files:[{
            linkText: "Lectures 46-47 Blender File",
            filename: "Lectures 46-47.zip"            
          }]
        },
        {
          lectureId: 47,
          section: "Implementing Area and Volume Forces",
          title: "Distributed loading - Data export from Blender",
          vimeoURL: "https://player.vimeo.com/video/595801646",
          duration: "11:13",
          
          preview: false,
          topicId: 427,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/47-distributed-loading-data-export-from-blender/427"
            }
          ],
          files:[]
        },
        {
          lectureId: 48,
          section: "Implementing Area and Volume Forces",
          title: "Importing distributed loading data",
          vimeoURL: "https://player.vimeo.com/video/595802967",
          duration: "06:34",
          
          preview: false,
          topicId: 428,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/48-importing-distributed-loading-data/428"
            }
          ],
          files:[]
        },
        {
          lectureId: 49,
          section: "Implementing Area and Volume Forces",
          title: "Surface force vector - Setup",
          vimeoURL: "https://player.vimeo.com/video/595803890",
          duration: "21:18",
          
          preview: false,
          topicId: 429,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/49-surface-force-vector-setup/429"
            }
          ],
          files:[]
        },
        {
          lectureId: 50,
          section: "Implementing Area and Volume Forces",
          title: "Surface force vector - Integration",
          vimeoURL: "https://player.vimeo.com/video/595806648",
          duration: "13:12",
          
          preview: false,
          topicId: 430,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/50-surface-force-vector-integration/430"
            }
          ],
          files:[]
        },
        {
          lectureId: 51,
          section: "Implementing Area and Volume Forces",
          title: "Updating the global force vector and validating",
          vimeoURL: "https://player.vimeo.com/video/595809057",
          duration: "15:49",
          
          preview: false,
          topicId: 431,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/51-updating-the-global-force-vector-and-validating/431"
            }
          ],
          files:[]
        },
        {
          lectureId: 52,
          section: "Implementing Area and Volume Forces",
          title: "Orthogonal surface forces",
          vimeoURL: "https://player.vimeo.com/video/595811868",
          duration: "10:30",
          
          preview: false,
          topicId: 432,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/52-orthogonal-surface-forces/432"
            }
          ],
          files:[]
        },
        {
          lectureId: 53,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/595813584",
          duration: "01:47",
          
          preview: true,
          topicId: 433,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/53-section-overview-2d-continuum-structures/433"
            }
          ],
          files:[]
        },
        {
          lectureId: 54,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "A recap of principal stresses",
          vimeoURL: "https://player.vimeo.com/video/595814441",
          duration: "16:49",
          
          preview: false,
          topicId: 434,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/54-a-recap-of-principal-stresses/434"
            }
          ],
          files:[]
        },
        {
          lectureId: 55,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Calculating principal angles and stresses",
          vimeoURL: "https://player.vimeo.com/video/595816510",
          duration: "08:38",
          
          preview: false,
          topicId: 435,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/55-calculating-principal-angles-and-stresses/435"
            }
          ],
          files:[{
            linkText: "Lectures 55-60 Jupyter Notebook",
            filename: "Lectures 55-60.zip"            
          }]
        },
        {
          lectureId: 56,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Sorting principal stresses",
          vimeoURL: "https://player.vimeo.com/video/600084213",
          duration: "05:10",
          
          preview: false,
          topicId: 436,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/56-sorting-principal-stresses/436"
            }
          ],
          files:[]
        },
        {
          lectureId: 57,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Identifying max ten/comp principal stresses",
          vimeoURL: "https://player.vimeo.com/video/600084534",
          duration: "11:24",
          
          preview: false,
          topicId: 437,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/57-identifying-max-ten-comp-principal-stresses/437"
            }
          ],
          files:[]
        },
        {
          lectureId: 58,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Visualising the principal stress magnitude field",
          vimeoURL: "https://player.vimeo.com/video/600085058",
          duration: "12:37",
          
          preview: false,
          topicId: 438,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/58-visualising-the-principal-stress-magnitude-field/438"
            }
          ],
          files:[]
        },
        {
          lectureId: 59,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Visualising the principal stress vector field",
          vimeoURL: "https://player.vimeo.com/video/600086061",
          duration: "18:42",
          
          preview: false,
          topicId: 439,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/59-visualising-the-principal-stress-vector-field/439"
            }
          ],
          files:[]
        },
        {
          lectureId: 60,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Combining the magnitude and vector fields",
          vimeoURL: "https://player.vimeo.com/video/600087737",
          duration: "06:22",
          
          preview: false,
          topicId: 440,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/60-combining-the-magnitude-and-vector-fields/440"
            }
          ],
          files:[]
        },
        {
          lectureId: 61,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "The von Mises failure theory",
          vimeoURL: "https://player.vimeo.com/video/600088968",
          duration: "22:34",
          
          preview: false,
          topicId: 441,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/61-the-von-mises-failure-theory/441"
            }
          ],
          files:[]
        },
        {
          lectureId: 62,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Visualising the von Mises stress field",
          vimeoURL: "https://player.vimeo.com/video/600091707",
          duration: "14:49",
          
          preview: false,
          topicId: 442,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/62-visualising-the-von-mises-stress-field/442"
            }
          ],
          files:[{
            linkText: "Lecture 62 Jupyter Notebook",
            filename: "Lecture 62.zip"            
          }]
        },
        {
          lectureId: 63,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Perimeter stresses and updating plot edges - Planning",
          vimeoURL: "https://player.vimeo.com/video/600094324",
          duration: "14:29",
          
          preview: false,
          topicId: 443,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/63-perimeter-stresses-and-updating-plot-edges-planning/443"
            }
          ],
          files:[]
        },
        {
          lectureId: 64,
          section: "Principal Stresses and von Mises Failure Theory",
          title: "Perimeter stresses and updating plot edges - Coding",
          vimeoURL: "https://player.vimeo.com/video/600097287",
          duration: "28:02",
          
          preview: false,
          topicId: 444,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/64-perimeter-stresses-and-updating-plot-edges-coding/444"
            }
          ],
          files:[{
            linkText: "Lecture 64 Jupyter Notebook",
            filename: "Lecture 64.zip"            
          }]
        },
        {
          lectureId: 65,
          section: "Generating and Analysing Complex Meshes",
          title: "Section overview",
          vimeoURL: "https://player.vimeo.com/video/600102009",
          duration: "01:37",
          
          preview: true,
          topicId: 445,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/65-section-overview-2d-continuum-structures/445"
            }
          ],
          files:[]
        },
        {
          lectureId: 66,
          section: "Generating and Analysing Complex Meshes",
          title: "Generating 2D geometry in Blender",
          vimeoURL: "https://player.vimeo.com/video/600108937",
          duration: "15:39",
          
          preview: false,
          topicId: 446,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/66-generating-2d-geometry-in-blender/446"
            }
          ],
          files:[{
            linkText: "Lectures 66-68 Blender File",
            filename: "Lectures 66-68.zip"            
          }]
        },
        {
          lectureId: 67,
          section: "Generating and Analysing Complex Meshes",
          title: "Meshing complex geometries",
          vimeoURL: "https://player.vimeo.com/video/600110633",
          duration: "13:12",
          
          preview: false,
          topicId: 447,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/67-meshing-complex-geometries/447"
            }
          ],
          files:[]
        },
        {
          lectureId: 68,
          section: "Generating and Analysing Complex Meshes",
          title: "Exporting an irregular mesh",
          vimeoURL: "https://player.vimeo.com/video/600112527",
          duration: "22:48",
          
          preview: false,
          topicId: 448,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/68-exporting-an-irregular-mesh/448"
            }
          ],
          files:[]
        },
        {
          lectureId: 69,
          section: "Generating and Analysing Complex Meshes",
          title: "Generalising our solver",
          vimeoURL: "https://player.vimeo.com/video/600115609",
          duration: "11:15",
          
          preview: false,
          topicId: 449,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/69-generalising-our-solver/449"
            }
          ],
          files:[{
            linkText: "Lectures 69-70 Jupyter Notebook",
            filename: "Lectures 69-70.zip"            
          }]
        },
        {
          lectureId: 70,
          section: "Generating and Analysing Complex Meshes",
          title: "Stress distribution masks",
          vimeoURL: "https://player.vimeo.com/video/600117891",
          duration: "21:35",
          
          preview: false,
          topicId: 450,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/70-stress-distribution-masks/450"
            }
          ],
          files:[]
        },
        {
          lectureId: 71,
          section: "Generating and Analysing Complex Meshes",
          title: "Course wrap up",
          vimeoURL: "https://player.vimeo.com/video/600121086",
          duration: "06:24",
          
          preview: false,
          topicId: 451,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/71-course-wrap-up/451"
            }
          ],
          files:[]
        },
        {
          lectureId: 72,
          section: "Generating and Analysing Complex Meshes",
          title: "[BONUS] Cantilever model and analysis walkthrough",
          vimeoURL: "https://player.vimeo.com/video/600123824",
          duration: "22:28",
          
          preview: true,
          topicId: 452,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/72-cantilever-model-and-analysis-walkthrough/452"
            }
          ],
          files:[{
            linkText: "Lecture 72 Jupyter Notebook",
            filename: "Lecture 72a.zip"            
          },
          {
            linkText: "Lecture 72 Blender File",
            filename: "Lecture 72b.zip"            
          }]
        }
      ]
    },
    {
      courseId: 14,      
      title: "Finite Element Analysis of 3D Structures using Python",
      tagline: "Build your own complete 3D structural analysis software in Python using the Direct Stiffness Method",
      duration: 14.5,
      nlectures: 80,
      path: "course-14/lecture-1",
      infoURL: "https://www.degreetutors.com/finite-element-analysis-of-3d-structures-using-python/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/600132472?h=ca60adb5df",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Welcome and preliminaries",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/600133433",
          duration: "03:41",
          
          preview: true,          
          topicId: 307,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-3d-braced-frames/307"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and preliminaries",
          title: "What you should cover before this course",          
          vimeoURL: "https://player.vimeo.com/video/600134179",
          duration: "03:14",
          preview: true,
          topicId: 308,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-what-you-should-cover-before-this-course-3d-braced-frames/308"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "The 3D Beam Stiffness Matrix",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/600134895",
          duration: "01:13",
          preview: true,
          topicId: 309,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-section-overview/309"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "The 3D Beam Stiffness Matrix",
          title: "Review of the 2D stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/600135817",
          duration: "16:20",
          preview: false,
          topicId: 310,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-review-of-the-2d-stiffness-matrix/310"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "The 3D Beam Stiffness Matrix",
          title: "3D beam elements",          
          vimeoURL: "https://player.vimeo.com/video/605447487?h=db45731809",
          duration: "07:43",
          preview: true,
          topicId: 311,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-3d-beam-elements/311"
            }
          ],
          files:[{
            linkText: "Lecture 5 PDF slides",
            filename: "Lecture 5.zip"            
          }]
        },
        {
          lectureId: 6,
          section: "The 3D Beam Stiffness Matrix",
          title: "Minor axis bending and transverse shear",          
          vimeoURL: "https://player.vimeo.com/video/605454397?h=4cfb1fcdf2",
          duration: "11:20",
          preview: false,
          topicId: 312,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-minor-axis-bending-and-transverse-shear/312"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "The 3D Beam Stiffness Matrix",
          title: "Torsion & completing the stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/605458454?h=219a8b84f2",
          duration: "05:01",
          preview: false,
          topicId: 313,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-torsion-completing-the-stiffness-matrix/313"
            }
          ],
          files:[{
            linkText: "Lecture 7 PDF slides",
            filename: "Lecture 7.zip"            
          }]
        },
        {
          lectureId: 8,
          section: "Location and Rotation in 3D Space",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/605460127?h=be3d55b8f9",
          duration: "01:12",
          preview: true,
          topicId: 314,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-section-overview-3d-braced-frames/314"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Location and Rotation in 3D Space",
          title: "An introduction to 3D reference frames",          
          vimeoURL: "https://player.vimeo.com/video/605461016?h=f59f86f113",
          duration: "12:11",
          preview: false,
          topicId: 315,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-an-introduction-to-3d-reference-frames/315"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Location and Rotation in 3D Space",
          title: "Recap of direction cosines & the 3D bar transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/605465939?h=cd43cf65ae",
          duration: "06:51",
          preview: false,
          topicId: 316,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-recap-of-direction-cosines-the-3d-bar-transformation-matrix/316"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Location and Rotation in 3D Space",
          title: "Building the 3D beam transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/605468981?h=c44bfb3f94",
          duration: "16:53",
          preview: false,
          topicId: 317,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-building-the-3d-beam-transformation-matrix/317"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Generating frame data in Blender",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/605479356?h=1df551caf9",
          duration: "02:43",
          preview: true,
          topicId: 318,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-section-overview-3d-braced-frames/318"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Generating frame data in Blender",
          title: "Building and exporting a basic frame",          
          vimeoURL: "https://player.vimeo.com/video/605481761?h=97db7ec1b3",
          duration: "10:53",
          preview: false,
          topicId: 319,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-building-and-exporting-a-basic-frame/319"
            }
          ],
          files:[
            {
              linkText: "Lectures 13-16 Blender File",
              filename: "Lectures 13-16.zip"            
            }
          ]
        },
        {
          lectureId: 14,
          section: "Generating frame data in Blender",
          title: "Exporting frame data from Blender",          
          vimeoURL: "https://player.vimeo.com/video/605485025?h=d9dc35573f",
          duration: "19:39",
          preview: false,
          topicId: 320,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-exporting-frame-data-from-blender/320"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Generating frame data in Blender",
          title: "Exporting structural supports",          
          vimeoURL: "https://player.vimeo.com/video/605487773?h=45eaed5b7c",
          duration: "17:19",
          preview: false,
          topicId: 321,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-exporting-structural-supports/321"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Generating frame data in Blender",
          title: "Exporting point load data",          
          vimeoURL: "https://player.vimeo.com/video/605493112?h=d5a69b20bf",
          duration: "07:31",
          preview: false,
          topicId: 322,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-exporting-point-load-data/322"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Generating frame data in Blender",
          title: "Importing our frame data",          
          vimeoURL: "https://player.vimeo.com/video/605494523?h=40a72dc9df",
          duration: "26:37",
          preview: false,
          topicId: 323,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-importing-our-frame-data/323"
            }
          ],
          files:[{
            linkText: "Lectures 17-19 Jupyter Notebook",
            filename: "Lectures 17-19.zip"            
          }]
        },
        {
          lectureId: 18,
          section: "Generating frame data in Blender",
          title: "Building the force vector",          
          vimeoURL: "https://player.vimeo.com/video/605497882?h=31f1208b21",
          duration: "08:41",
          preview: false,
          topicId: 324,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-building-the-force-vector/324"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Generating frame data in Blender",
          title: "Visualising our structure with matplotlib",          
          vimeoURL: "https://player.vimeo.com/video/605499113?h=d28d438494",
          duration: "17:47",
          preview: false,
          topicId: 325,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-visualising-our-structure-with-matplotlib/325"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Building the Transformation Matrix",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/605502838?h=20509f8d96",
          duration: "01:14",
          preview: true,
          topicId: 326,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-section-overview-3d-braced-frames/326"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Building the Transformation Matrix",
          title: "Defining a default member orientation",          
          vimeoURL: "https://player.vimeo.com/video/605503451?h=cac38ef635",
          duration: "14:49",
          preview: false,
          topicId: 327,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-defining-a-default-member-orientation/327"
            }
          ],
          files:[{
            linkText: "Lectures 21-24 Jupyter Notebook",
            filename: "Lectures 21-24.zip"            
          }]
        },
        {
          lectureId: 22,
          section: "Building the Transformation Matrix",
          title: "How to define a local member reference frame",          
          vimeoURL: "https://player.vimeo.com/video/605509030?h=3211866a2a",
          duration: "17:03",
          preview: false,
          topicId: 328,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-how-to-define-a-local-member-reference-frame/328"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Building the Transformation Matrix",
          title: "Building the local member reference frame",          
          vimeoURL: "https://player.vimeo.com/video/605512294?h=3a893fe739",
          duration: "18:57",
          preview: false,
          topicId: 329,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-building-the-local-member-reference-frame/329"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Building the Transformation Matrix",
          title: "Calculating member reference frames and lengths ",          
          vimeoURL: "https://player.vimeo.com/video/605515806?h=69056cb0ed",
          duration: "09:56",
          preview: false,
          topicId: 330,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-calculating-member-reference-frames-and-lengths/330"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Building and Solving the Stiffness Matrix",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/605518216?h=33f283e245",
          duration: "01:06",
          preview: true,
          topicId: 331,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-section-overview/331"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Building and Solving the Stiffness Matrix",
          title: "Building the global element stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/605527346?h=990969744f",
          duration: "13:09",
          preview: false,
          topicId: 332,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-building-the-global-element-stiffness-matrix/332"
            }
          ],
          files:[{
            linkText: "Lectures 26-30 Jupyter Notebook",
            filename: "Lectures 26-30.zip"            
          }]
        },
        {
          lectureId: 27,
          section: "Building and Solving the Stiffness Matrix",
          title: "Building the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/605529017?h=976325db47",
          duration: "09:05",
          preview: false,
          topicId: 333,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-building-the-primary-stiffness-matrix/333"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Building and Solving the Stiffness Matrix",
          title: "Extracting the structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/605530424?h=d84d57cd08",
          duration: "04:20",
          preview: false,
          topicId: 334,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-extracting-the-structure-stiffness-matrix/334"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Building and Solving the Stiffness Matrix",
          title: "Solving for displacements and reactions",          
          vimeoURL: "https://player.vimeo.com/video/605531054?h=60d36ff6bd",
          duration: "08:24",
          preview: false,
          topicId: 335,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-solving-for-displacements-and-reactions/335"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Building and Solving the Stiffness Matrix",
          title: "Solving for member actions",          
          vimeoURL: "https://player.vimeo.com/video/605533011?h=0c21bf65ce",
          duration: "17:28",
          preview: false,
          topicId: 336,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-solving-for-member-actions/336"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Visualising Results in 3D",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/605568465?h=41151031a4",
          duration: "02:34",
          preview: true,
          topicId: 337,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-section-overview/337"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Visualising Results in 3D",
          title: "Plotting the deflected shape",          
          vimeoURL: "https://player.vimeo.com/video/611543334?h=47c52a463c",
          duration: "12:53",
          preview: false,
          topicId: 338,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-plotting-the-deflected-shape/338"
            }
          ],
          files:[{
            linkText: "Lectures 32-34 Jupyter Notebook",
            filename: "Lectures 32-34.zip"            
          }]
        },
        {
          lectureId: 33,
          section: "Visualising Results in 3D",
          title: "Plotting the axial force heat map",          
          vimeoURL: "https://player.vimeo.com/video/611545339?h=b2a1636cc7",
          duration: "25:57",
          preview: false,
          topicId: 339,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-plotting-the-axial-force-heat-map/339"
            }
          ],
          files:[]
        },
        {
          lectureId: 34,
          section: "Visualising Results in 3D",
          title: "Build the local BMD",          
          vimeoURL: "https://player.vimeo.com/video/611549102?h=1d611786e6",
          duration: "13:19",
          preview: false,
          topicId: 340,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-build-the-local-bmd/340"
            }
          ],
          files:[]
        },
        {
          lectureId: 35,
          section: "Visualising Results in 3D",
          title: "Plotting the 3D bending moment diagram",          
          vimeoURL: "https://player.vimeo.com/video/616193421?h=0ad7c8d9c8",
          duration: "26:34",
          preview: false,
          topicId: 341,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-plotting-the-3d-bending-moment-diagram/341"
            }
          ],
          files:[{
            linkText: "Lectures 35-39 Jupyter Notebook",
            filename: "Lectures 35-39.zip"            
          },
          {
            linkText: "Lecture 35 Blender File",
            filename: "Lecture 35.zip"            
          }]
        },
        {
          lectureId: 36,
          section: "Visualising Results in 3D",
          title: "Build the local SFD",          
          vimeoURL: "https://player.vimeo.com/video/616205856?h=13c7e7cb82",
          duration: "04:59",
          preview: false,
          topicId: 342,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-build-the-local-sfd/342"
            }
          ],
          files:[]
        },
        {
          lectureId: 37,
          section: "Visualising Results in 3D",
          title: "Plotting the 3D shear force diagram",          
          vimeoURL: "https://player.vimeo.com/video/616207350?h=ec93c0eb0a",
          duration: "14:14",
          preview: false,
          topicId: 343,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-plotting-the-3d-shear-force-diagram/343"
            }
          ],
          files:[]
        },
        {
          lectureId: 38,
          section: "Visualising Results in 3D",
          title: "Generating detailed text output",          
          vimeoURL: "https://player.vimeo.com/video/616214539?h=69fe81d45f",
          duration: "20:37",
          preview: false,
          topicId: 344,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-generating-detailed-text-output/344"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Visualising Results in 3D",
          title: "Exporting deflections to Blender",          
          vimeoURL: "https://player.vimeo.com/video/616220755?h=0431d27868",
          duration: "15:47",
          preview: false,
          topicId: 345,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-exporting-deflections-to-blender/345"
            }
          ],
          files:[
            {
              linkText: "Lecture 39 Blender File",
              filename: "Lecture 39.zip"            
            }
          ]
        },
        {
          lectureId: 40,
          section: "Implementing Bar Bracing Elements",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616224735?h=9a4f4337fe",
          duration: "01:55",
          preview: true,
          topicId: 346,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-section-overview-3d-braced-frames/346"
            }
          ],
          files:[
            {
              linkText: "Lectures 40-47 Jupyter Notebook",
              filename: "Lectures 40-47.zip"            
            }
          ]
        },
        {
          lectureId: 41,
          section: "Implementing Bar Bracing Elements",
          title: "Identifying and exporting bar elements from Blender",          
          vimeoURL: "https://player.vimeo.com/video/616226506?h=8875f6a532",
          duration: "13:04",
          preview: false,
          topicId: 347,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-identifying-and-exporting-bar-elements-from-blender/347"
            }
          ],
          files:[
            {
              linkText: "Lecture 41 Blender File",
              filename: "Lecture 41.zip"            
            }
          ]
        },
        {
          lectureId: 42,
          section: "Implementing Bar Bracing Elements",
          title: "Identifying rotational releases in our code",          
          vimeoURL: "https://player.vimeo.com/video/616231690?h=5d804a45ef",
          duration: "21:00",
          preview: false,
          topicId: 348,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/42-identifying-rotational-releases-in-our-code/348"
            }
          ],
          files:[]
        },
        {
          lectureId: 43,
          section: "Implementing Bar Bracing Elements",
          title: "Assigning different areas to bar elements",          
          vimeoURL: "https://player.vimeo.com/video/616237848?h=2a9254f625",
          duration: "04:00",
          preview: false,
          topicId: 349,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/43-assigning-different-areas-to-bar-elements/349"
            }
          ],
          files:[]
        },
        {
          lectureId: 44,
          section: "Implementing Bar Bracing Elements",
          title: "Building the bar element stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616239235?h=191d8cae0e",
          duration: "05:58",
          preview: false,
          topicId: 350,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/44-building-the-bar-element-stiffness-matrix/350"
            }
          ],
          files:[]
        },
        {
          lectureId: 45,
          section: "Implementing Bar Bracing Elements",
          title: "Adding bar elements to the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616241249?h=6097340369",
          duration: "07:25",
          preview: false,
          topicId: 351,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/45-adding-bar-elements-to-the-primary-stiffness-matrix/351"
            }
          ],
          files:[]
        },
        {
          lectureId: 46,
          section: "Implementing Bar Bracing Elements",
          title: "Extracting the structure stuffiness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616250914?h=91a722ec15",
          duration: "15:37",
          preview: false,
          topicId: 352,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/46-extracting-the-structure-stuffiness-matrix/352"
            }
          ],
          files:[]
        },
        {
          lectureId: 47,
          section: "Implementing Bar Bracing Elements",
          title: "Solving for axial forces in bar elements",          
          vimeoURL: "https://player.vimeo.com/video/616256835?h=e56e1f9903",
          duration: "12:40",
          preview: false,
          topicId: 353,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/47-solving-for-axial-forces-in-bar-elements/353"
            }
          ],
          files:[]
        },
        {
          lectureId: 48,
          section: "Implementing Pinned Members",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616263803?h=c1964b9ed4",
          duration: "02:43",
          preview: true,
          topicId: 354,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/48-section-overview-3d-braced-frames/354"
            }
          ],
          files:[]
        },
        {
          lectureId: 49,
          section: "Implementing Pinned Members",
          title: "Constructing a fixed-pinned stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616267221?h=f59d2cc164",
          duration: "09:32",
          preview: false,
          topicId: 355,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/49-constructing-a-fixed-pinned-stiffness-matrix/355"
            }
          ],
          files:[]
        },
        {
          lectureId: 50,
          section: "Implementing Pinned Members",
          title: "Identifying and exporting pinned elements from Blender",          
          vimeoURL: "https://player.vimeo.com/video/616277906?h=a82c86343c",
          duration: "06:36",
          preview: false,
          topicId: 356,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/50-identifying-and-exporting-pinned-elements-from-blender/356"
            }
          ],
          files:[
            {
              linkText: "Lecture 50 Blender File",
              filename: "Lecture 50.zip"            
            }
          ]
        },
        {
          lectureId: 51,
          section: "Implementing Pinned Members",
          title: "Identifying pinned members in our code",          
          vimeoURL: "https://player.vimeo.com/video/616280794?h=a256bb257a",
          duration: "14:32",
          preview: false,
          topicId: 357,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/51-identifying-pinned-members-in-our-code/357"
            }
          ],
          files:[
            {
              linkText: "Lectures 51-55 Jupyter Notebook",
              filename: "Lectures 51-55.zip"            
            }
          ]
        },
        {
          lectureId: 52,
          section: "Implementing Pinned Members",
          title: "Element stiffness matrix with pin at node i",          
          vimeoURL: "https://player.vimeo.com/video/616288374?h=422b602d30",
          duration: "10:26",
          preview: false,
          topicId: 358,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/52-element-stiffness-matrix-with-pin-at-node-i/358"
            }
          ],
          files:[]
        },
        {
          lectureId: 53,
          section: "Implementing Pinned Members",
          title: "Element stiffness matrix with pin at node j",          
          vimeoURL: "https://player.vimeo.com/video/616291442?h=4f3aa36410",
          duration: "05:03",
          preview: false,
          topicId: 359,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/53-element-stiffness-matrix-with-pin-at-node-j/359"
            }
          ],
          files:[]
        },
        {
          lectureId: 54,
          section: "Implementing Pinned Members",
          title: "Adding pinned elements to the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616294322?h=78754b00d4",
          duration: "06:51",
          preview: false,
          topicId: 360,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/54-adding-pinned-elements-to-the-primary-stiffness-matrix/360"
            }
          ],
          files:[]
        },
        {
          lectureId: 55,
          section: "Implementing Pinned Members",
          title: "Solving for member actions in pinned elements",          
          vimeoURL: "https://player.vimeo.com/video/616298013?h=99e17a7e9a",
          duration: "17:07",
          preview: false,
          topicId: 361,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/55-solving-for-member-actions-in-pinned-elements/361"
            }
          ],
          files:[]
        },
        {
          lectureId: 56,
          section: "Implementing Distributed Loading",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616305809?h=a4f1515b45",
          duration: "02:46",
          preview: true,
          topicId: 362,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/56-section-overview-3d-braced-frames/362"
            }
          ],
          files:[]
        },
        {
          lectureId: 57,
          section: "Implementing Distributed Loading",
          title: "Identifying distributed loads in Blender",          
          vimeoURL: "https://player.vimeo.com/video/616308137?h=274b38bbff",
          duration: "04:37",
          preview: false,
          topicId: 363,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/57-identifying-distributed-loads-in-blender/363"
            }
          ],
          files:[{
            linkText: "Lecture 57 Blender File",
            filename: "Lecture 57.zip"            
          }]
        },
        {
          lectureId: 58,
          section: "Implementing Distributed Loading",
          title: "Importing distributed loading information",          
          vimeoURL: "https://player.vimeo.com/video/616309826?h=f751365b11",
          duration: "03:54",
          preview: false,
          topicId: 364,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/58-importing-distributed-loading-information/364"
            }
          ],
          files:[
            {
              linkText: "Lectures 58-63 Jupyter Notebook",
              filename: "Lectures 58-63.zip"            
            }
          ]
        },
        {
          lectureId: 59,
          section: "Implementing Distributed Loading",
          title: "Calculating equivalent nodal actions - Part 1",          
          vimeoURL: "https://player.vimeo.com/video/616311376?h=2e9c5cb506",
          duration: "20:25",
          preview: false,
          topicId: 365,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/59-calculating-equivalent-nodal-actions-part-1/365"
            }
          ],
          files:[]
        },
        {
          lectureId: 60,
          section: "Implementing Distributed Loading",
          title: "Calculating equivalent nodal actions - Part 2",          
          vimeoURL: "https://player.vimeo.com/video/616317595?h=dcc526f7e8",
          duration: "11:19",
          preview: false,
          topicId: 366,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/60-calculating-equivalent-nodal-actions-part-2/366"
            }
          ],
          files:[]
        },
        {
          lectureId: 61,
          section: "Implementing Distributed Loading",
          title: "Updating the global force vector",          
          vimeoURL: "https://player.vimeo.com/video/616322464?h=23c55b0861",
          duration: "07:47",
          preview: false,
          topicId: 367,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/61-updating-the-global-force-vector/367"
            }
          ],
          files:[]
        },
        {
          lectureId: 62,
          section: "Implementing Distributed Loading",
          title: "Removing ENA from reactions",          
          vimeoURL: "https://player.vimeo.com/video/616325624?h=255fb7e88d",
          duration: "03:12",
          preview: false,
          topicId: 368,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/62-removing-ena-from-reactions/368"
            }
          ],
          files:[]
        },
        {
          lectureId: 63,
          section: "Implementing Distributed Loading",
          title: "Removing ENA from member actions",          
          vimeoURL: "https://player.vimeo.com/video/616326642?h=2a1249eaa7",
          duration: "15:47",
          preview: false,
          topicId: 369,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/63-removing-ena-from-member-actions/369"
            }
          ],
          files:[]
        },
        {
          lectureId: 64,
          section: "Bridge Model Case Study",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616333103?h=0593db9b7d",
          duration: "03:43",
          preview: true,
          topicId: 370,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/64-section-overview/370"
            }
          ],
          files:[]
        },
        {
          lectureId: 65,
          section: "Bridge Model Case Study",
          title: "Reviewing the bridge structure",          
          vimeoURL: "https://player.vimeo.com/video/616337160?h=760a50e517",
          duration: "14:01",
          preview: false,
          topicId: 371,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/65-reviewing-the-bridge-structure/371"
            }
          ],
          files:[]
        },
        {
          lectureId: 66,
          section: "Bridge Model Case Study",
          title: "Building the structural bridge model",          
          vimeoURL: "https://player.vimeo.com/video/616342885?h=9388f31d3e",
          duration: "27:26",
          preview: false,
          topicId: 372,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/66-building-the-structural-bridge-model/372"
            }
          ],
          files:[
            {
              linkText: "Lecture 66 Blender File",
              filename: "Lecture 66.zip"            
            }
          ]
        },
        {
          lectureId: 67,
          section: "Bridge Model Case Study",
          title: "Data export/import",          
          vimeoURL: "https://player.vimeo.com/video/616354007?h=626c087098",
          duration: "10:20",
          preview: false,
          topicId: 373,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/67-data-export-import/373"
            }
          ],
          files:[
            {
              linkText: "Lectures 67-68 Jupyter Notebook",
              filename: "Lectures 67-68.zip"            
            }
          ]
        },
        {
          lectureId: 68,
          section: "Bridge Model Case Study",
          title: "Review of results",          
          vimeoURL: "https://player.vimeo.com/video/616357271?h=cfd43f454b",
          duration: "07:59",
          preview: false,
          topicId: 374,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/68-review-of-results/374"
            }
          ],
          files:[]
        },
        {
          lectureId: 69,
          section: "Bridge Model Case Study",
          title: "Course wrap up and debrief",          
          vimeoURL: "https://player.vimeo.com/video/616360170?h=11c5b595a9",
          duration: "03:10",
          preview: false,
          topicId: 375,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/69-course-wrap-up-and-debrief/375"
            }
          ],
          files:[]
        },
        {
          lectureId: 70,
          section: "Course Updates",
          title: "Self-weight part 1 - bar elements",          
          vimeoURL: "https://player.vimeo.com/video/616364973?h=2e02f5de88",
          duration: "14:27",
          preview: false,
          topicId: 376,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/70-self-weight-part-1-bar-elements/376"
            }
          ],
          files:[
            {
              linkText: "Lectures 70-71 Jupyter Notebook",
              filename: "Lectures 70-71.zip"            
            }
          ]
        },
        {
          lectureId: 71,
          section: "Course Updates",
          title: "Self-weight part 1 - beam elements",          
          vimeoURL: "https://player.vimeo.com/video/616368402?h=efceed5af2",
          duration: "11:19",
          preview: false,
          topicId: 377,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/71-self-weight-part-1-beam-elements/377"
            }
          ],
          files:[]
        },
        {
          lectureId: 72,
          section: "Course Updates",
          title: "Member rotations part 1 - Identifying members to rotate",          
          vimeoURL: "https://player.vimeo.com/video/616373302?h=396953654c",
          duration: "08:51",
          preview: false,
          topicId: 378,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/72-member-rotations-part-1-identifying-members-to-rotate/378"
            }
          ],
          files:[
            {
              linkText: "Lectures 72-73 Jupyter Notebook",
              filename: "Lectures 72-73.zip"            
            },
            {
              linkText: "Lecture 72 Blender File",
              filename: "Lecture 72.zip"            
            }
          ]
        },
        {
          lectureId: 73,
          section: "Course Updates",
          title: "Member rotations part 2 - Implementing rotation overrides",          
          vimeoURL: "https://player.vimeo.com/video/616375950?h=7ba95dc065",
          duration: "23:14",
          preview: false,
          topicId: 379,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/73-member-rotations-part-2-implementing-rotation-overrides/379"
            }
          ],
          files:[]
        }
      ]
    },
    {
      courseId: 13,
      title: "3D Space Frame Analysis using Python and Blender",
      tagline: "Develop tools to model and analyse complex 3D space frame structures using Python", 
      duration: 6,
      nlectures: 38,
      path: "course-13/lecture-1",
      infoURL:"https://www.degreetutors.com/3d-truss-analysis-using-the-direct-stiffness-method/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/616752387?h=a16eb6aff3",    
      discourse: true,  
      lectures: [
        {
          lectureId: 1,
          section: "Welcome and preliminaries",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/616755255?h=8210cef881",
          duration: "03:18",
          preview: true,
          topicId: 262,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-3d-space-frames/262"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and preliminaries",
          title: "What you should cover before this course",          
          vimeoURL: "https://player.vimeo.com/video/616756653?h=68ab2d540f",
          duration: "01:14",
          preview: true,
          topicId: 263,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/2-what-you-should-cover-before-this-course/263"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Welcome and preliminaries",
          title: "Installing Nbextensions",          
          vimeoURL: "https://player.vimeo.com/video/616756922?h=7276ea7321",
          duration: "05:06",
          preview: false,
          topicId: 264,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/3-installing-nbextensions/264"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Expanding from 2 to 3 dimensions",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616757802?h=1aa663c534",
          duration: "00:42",
          preview: true,
          topicId: 265,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/4-section-overview-3d-space-frames/265"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Expanding from 2 to 3 dimensions",
          title: "A quick review of transformations in 2D",          
          vimeoURL: "https://player.vimeo.com/video/616758239?h=ed82872859",
          duration: "17:30",
          preview: false,
          topicId: 266,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/5-a-quick-review-of-transformations-in-2d/266"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Expanding from 2 to 3 dimensions",
          title: "Expanding to a 3D transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/616761126?h=2b3c70a174",
          duration: "12:51",
          preview: false,
          topicId: 267,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/6-expanding-to-a-3d-transformation-matrix/267"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Expanding from 2 to 3 dimensions",
          title: "The 6x6 element stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616763109?h=ca0df5395c",
          duration: "04:54",
          preview: false,
          topicId: 268,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/7-the-6x6-element-stiffness-matrix/268"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Coding the third dimension",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616763935?h=06074b91c7",
          duration: "00:49",
          preview: true,
          topicId: 269,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/8-section-overview-3d-space-frames/269"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Coding the third dimension",
          title: "Visualising our 3D structure",          
          vimeoURL: "https://player.vimeo.com/video/616764450?h=396ffc2208",
          duration: "16:22",
          preview: false,
          topicId: 270,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/9-visualising-our-3d-structure/270"
            }
          ],
          files:[{
              linkText: "Lectures 9-15 Jupyter Notebook",
              filename: "Lecture 9.zip"                        
          }]
        },
        {
          lectureId: 10,
          section: "Coding the third dimension",
          title: "Adding node number annotations",          
          vimeoURL: "https://player.vimeo.com/video/616766618?h=df1a9fe409",
          duration: "04:44",
          preview: false,
          topicId: 271,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/10-adding-node-number-annotations/271"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Coding the third dimension",
          title: "Calculating 3D orientations and lengths",          
          vimeoURL: "https://player.vimeo.com/video/616767422?h=981c119d7a",
          duration: "08:39",
          preview: false,
          topicId: 272,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/11-calculating-3d-orientations-and-lengths/272"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Coding the third dimension",
          title: "Building the 3D structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616768491?h=fdfdc88c42",
          duration: "09:34",
          preview: false,
          topicId: 273,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/12-building-the-3d-structure-stiffness-matrix/273"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Coding the third dimension",
          title: "Solving for displacements, reactions and member forces",          
          vimeoURL: "https://player.vimeo.com/video/616769377?h=4bd8d0d586",
          duration: "07:59",
          preview: false,
          topicId: 274,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/13-solving-for-displacements-reactions-and-member-forces/274"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Coding the third dimension",
          title: "Visualising axial forces and the deflected shape",          
          vimeoURL: "https://player.vimeo.com/video/616770378?h=23f8fc98e9",
          duration: "10:50",
          preview: false,
          topicId: 275,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/14-visualising-axial-forces-and-the-deflected-shape/275"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Coding the third dimension",
          title: "Producing text output",          
          vimeoURL: "https://player.vimeo.com/video/616772551?h=9d12cb7751",
          duration: "03:45",
          preview: false,
          topicId: 276,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/15-producing-text-output/276"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Improving the user experience",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616772906?h=f7e0f4b9e1",
          duration: "00:40",
          preview: true,
          topicId: 277,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/16-section-overview-3d-space-frames/277"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Improving the user experience",
          title: "Adding a colour map for axial forces",          
          vimeoURL: "https://player.vimeo.com/video/616773367?h=fdde41b6aa",
          duration: "08:07",
          preview: false,
          topicId: 278,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/17-adding-a-colour-map-for-axial-forces/278"
            }
          ],
          files:[{
            linkText: "Lectures 17-18 Jupyter Notebook",
            filename: "Lecture 17.zip"                        
        }]
        },
        {
          lectureId: 18,
          section: "Improving the user experience",
          title: "Adding UI widgets for plot adjustments",          
          vimeoURL: "https://player.vimeo.com/video/616774425?h=bdf08d5185",
          duration: "14:11",
          preview: false,
          topicId: 279,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/18-adding-ui-widgets-for-plot-adjustments/279"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Building 3D structures in Blender",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616776624?h=ac7add7e36",
          duration: "01:21",
          preview: true,
          topicId: 280,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/19-section-overview-3d-space-frames/280"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Building 3D structures in Blender",
          title: "How can Blender help us?",          
          vimeoURL: "https://player.vimeo.com/video/616777065?h=bfab4fcffc",
          duration: "06:18",
          preview: false,
          topicId: 281,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/20-how-can-blender-help-us/281"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Building 3D structures in Blender",
          title: "Downloading and installing Blender",          
          vimeoURL: "https://player.vimeo.com/video/616779499?h=949a580c75",
          duration: "03:22",
          preview: false,
          topicId: 282,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/21-downloading-and-installing-blender/282"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Building 3D structures in Blender",
          title: "Blender overview and interface basics",          
          vimeoURL: "https://player.vimeo.com/video/616779855?h=ac4404ebc6",
          duration: "15:36",
          preview: false,
          topicId: 283,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/22-blender-overview-and-interface-basics/283"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Building 3D structures in Blender",
          title: "Object versus edit mode",          
          vimeoURL: "https://player.vimeo.com/video/616782819?h=8b8ca42301",
          duration: "10:06",
          preview: false,
          topicId: 284,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/23-object-versus-edit-mode/284"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Building 3D structures in Blender",
          title: "Basic modelling - rectilinear structures",          
          vimeoURL: "https://player.vimeo.com/video/616784766?h=3ba9abf76e",
          duration: "10:50",
          preview: false,
          topicId: 285,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/24-basic-modelling-rectilinear-structures/285"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Building 3D structures in Blender",
          title: "Basic modelling - organic/curved structures",          
          vimeoURL: "https://player.vimeo.com/video/616786886?h=d1e5ccd7a1",
          duration: "18:26",
          preview: true,
          topicId: 286,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/25-basic-modelling-organic-curved-structures/286"
            }
          ],
          files:[{
            linkText: "Lecture 25 Blender File",
            filename: "Lecture 25.zip"                        
        }]
        },
        {
          lectureId: 26,
          section: "Jupyter/Blender data transfer",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616790777?h=5b0dddb826",
          duration: "01:05",
          preview: true,
          topicId: 287,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/26-section-overview-3d-space-frames/287"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Jupyter/Blender data transfer",
          title: "Exporting our model data from Blender",          
          vimeoURL: "https://player.vimeo.com/video/616791170?h=f94db4cc03",
          duration: "13:01",
          preview: false,
          topicId: 288,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/27-exporting-our-model-data-from-blender/288"
            }
          ],
          files:[{
            linkText: "Lectures 27-30 Blender File",
            filename: "Lecture 27.zip"                        
        }]
        },
        {
          lectureId: 28,
          section: "Jupyter/Blender data transfer",
          title: "Importing model data into our Jupyter Notebook",          
          vimeoURL: "https://player.vimeo.com/video/616793096?h=8f256a2109",
          duration: "13:20",
          preview: false,
          topicId: 289,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/28-importing-model-data-into-our-jupyter-notebook/289"
            }
          ],
          files:[{
            linkText: "Lectures 28-29 Jupyter Notebook",
            filename: "Lecture 28.zip"                        
          }]
        },
        {
          lectureId: 29,
          section: "Jupyter/Blender data transfer",
          title: "Exporting structural deflections ",          
          vimeoURL: "https://player.vimeo.com/video/616795149?h=2b4e86a739",
          duration: "08:48",
          preview: false,
          topicId: 290,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/29-exporting-structural-deflections/290"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Jupyter/Blender data transfer",
          title: "Importing and visualising deflections in Blender",          
          vimeoURL: "https://player.vimeo.com/video/616796313?h=2a35a8051f",
          duration: "06:36",
          preview: false,
          topicId: 291,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/30-importing-and-visualising-deflections-in-blender/291"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Case study structures",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616797289?h=65846ca864",
          duration: "00:36",
          preview: true,
          topicId: 292,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/31-section-overview-3d-space-frames/292"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Case study structures",
          title: "Case study 1 - Transmission Tower: Modelling",          
          vimeoURL: "https://player.vimeo.com/video/616797497?h=a3b2873e67",
          duration: "24:00",
          preview: false,
          topicId: 293,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/32-case-study-1-transmission-tower-modelling/293"
            }
          ],
          files:[{
            linkText: "Lecture 32 Blender File",
            filename: "Lecture 32.zip"                        
          }]
        },
        {
          lectureId: 33,
          section: "Case study structures",
          title: "Case study 1 - Transmission Tower: Analysis",          
          vimeoURL: "https://player.vimeo.com/video/616802756?h=12c18192df",
          duration: "13:34",
          preview: false,
          topicId: 294,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/33-case-study-1-transmission-tower-analysis/294"
            }
          ],
          files:[{
            linkText: "Lecture 33 Jupyter Notebook",
            filename: "Lecture 33.zip"                        
          }]
        },
        {
          lectureId: 34,
          section: "Case study structures",
          title: "Case study 2 - Geodesic Dome: Modelling & Data Export",          
          vimeoURL: "https://player.vimeo.com/video/616805213?h=d26437dd7d",
          duration: "15:21",
          preview: false,
          topicId: 295,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/34-case-study-2-geodesic-dome-modelling-data-export/295"
            }
          ],
          files:[{
            linkText: "Lecture 34 Blender File",
            filename: "Lecture 34.zip"                        
          }]
        },
        {
          lectureId: 35,
          section: "Case study structures",
          title: "Case study 2 - Geodesic Dome: Analysis & Data Import",          
          vimeoURL: "https://player.vimeo.com/video/616806999?h=5c2bb9099c",
          duration: "09:31",
          preview: false,
          topicId: 296,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/35-case-study-2-geodesic-dome-analysis-data-import/296"
            }
          ],
          files:[{
            linkText: "Lecture 35 Jupyter Notebook",
            filename: "Lecture 35.zip"                        
          }]
        },
        {
          lectureId: 36,
          section: "Case study structures",
          title: "Case study 3 - Gherkin-esk Tower",          
          vimeoURL: "https://player.vimeo.com/video/616808498?h=f5a0774784",
          duration: "28:59",
          preview: false,
          topicId: 297,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/36-case-study-3-gherkin-esk-tower/297"
            }
          ],
          files:[
            {
              linkText: "Lecture 36 Jupyter Notebook",
              filename: "Lecture 36a.zip"            
            },
            {
              linkText: "Lecture 36 Blender File",
              filename: "Lecture 36b.zip"            
            }
          ]
        },
        {
          lectureId: 37,
          section: "Case study structures",
          title: "Case study 4 - Space frame roof ",          
          vimeoURL: "https://player.vimeo.com/video/616820151?h=37bc59783f",
          duration: "17:12",
          preview: false,
          topicId: 298,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/37-case-study-4-space-frame-roof/298"
            }
          ],
          files:[
            {
              linkText: "Lecture 37 Jupyter Notebook",
              filename: "Lecture 37a.zip"            
            },
            {
              linkText: "Lecture 37 Blender File",
              filename: "Lecture 37b.zip"            
            }
          ]
        },
        {
          lectureId: 38,
          section: "Case study structures",
          title: "Course wrap up",          
          vimeoURL: "https://player.vimeo.com/video/616827356?h=c427c807ee",
          duration: "01:48",
          preview: false,
          topicId: 299,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/38-course-wrap-up/299"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Course updates",
          title: "Including self-weight in the analysis",          
          vimeoURL: "https://player.vimeo.com/video/616830230?h=4d3aa69137",
          duration: "25:35",
          preview: false,
          topicId: 300,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/39-including-self-weight-in-the-analysis/300"
            }
          ],
          files:[{
            linkText: "Lecture 39 Jupyter Notebook",
            filename: "Lecture 39a.zip"            
          }]
        },
        {
          lectureId: 40,
          section: "Course updates",
          title: "Blender data-viz - Structure setup & force data",          
          vimeoURL: "https://player.vimeo.com/video/706988385?h=98a121593e",
          duration: "19:02",
          preview: false,
          topicId: 301,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/40-blender-data-viz-structure-setup-force-data/301"
            }
          ],
          files:[{
            linkText: "Lecture 40 Jupyter Notebook",
            filename: "Lecture 40.zip"            
          }]
        },
        {
          lectureId: 41,
          section: "Course updates",
          title: "Blender data-viz - Blocking out the code",          
          vimeoURL: "https://player.vimeo.com/video/706989515?h=7d6699cad6",
          duration: "22:56",
          preview: false,
          topicId: 302,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/41-blender-data-viz-blocking-out-the-code/302"
            }
          ],
          files:[{
            linkText: "Lectures 41-43 Blender File",
            filename: "Lecture 41.zip"                        
        }]
        },
        {
          lectureId: 42,
          section: "Course updates",
          title: "Blender data-viz - The transformation matrix",          
          vimeoURL: "https://player.vimeo.com/video/706990611?h=8485033203",
          duration: "22:00",
          preview: false,
          topicId: 303,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/42-blender-data-viz-the-transformation-matrix/303"
            }
          ],
          files:[]
        },
        {
          lectureId: 43,
          section: "Course updates",
          title: "Blender data-viz - Generating materials from force data",          
          vimeoURL: "https://player.vimeo.com/video/706991530?h=d75eb0ea51",
          duration: "21:36",
          preview: false,
          topicId: 304,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://community.degreetutors.com/t/43-blender-data-viz-generating-materials-from-force-data/304"
            }
          ],
          files:[]
        }        
      ]
    },
    {
      courseId: 12,
      title: "Beam & Frame Analysis using the Direct Stiffness Method in Python",
      tagline: "Build a sophisticated structural analysis software tool that models beams and frames using Python",
      duration: 9,
      nlectures: 55,
      path: "course-12/lecture-1",      
      infoURL:"https://www.degreetutors.com/beam-and-frame-analysis-using-the-direct-stiffness-method-in-python/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/616837140?h=55d8016acc",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Welcome and setup",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/616840149?h=3d37a61501",          
          duration: "05:53",
          preview: true,
          topicId: 199,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-2d-beam-frame/199"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and setup",
          title: "Our approach to Python",          
          vimeoURL: "https://player.vimeo.com/video/622225735?h=e30fc9d088",          
          duration: "02:02",
          preview: true,
          topicId: 200,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-our-approach-to-python-2d-beam-frame/200"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Welcome and setup",
          title: "Getting started with Jupyter Notebooks",          
          vimeoURL: "https://player.vimeo.com/video/622226178?h=d97e4e5126",          
          duration: "12:04",
          preview: true,
          topicId: 201,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-getting-started-with-jupyter-notebooks-2d-beam-frame/201"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Beam Element Stiffness Matrices",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616841384?h=0fe2c3d4cf",          
          duration: "01:12",
          preview: true,
          topicId: 202,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-section-overview-2d-beam-frame/202"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Beam Element Stiffness Matrices",
          title: "Finite element equations - quick review",          
          vimeoURL: "https://player.vimeo.com/video/616841572?h=9d93e6dd63",          
          duration: "09:59",
          preview: true,
          topicId: 203,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-finite-element-equations-quick-review/203"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Beam Element Stiffness Matrices",
          title: "Stiffness matrix for a beam element - part 1",          
          vimeoURL: "https://player.vimeo.com/video/616844529?h=6edc674e1a",          
          duration: "20:27",
          preview: false,
          topicId: 204,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-stiffness-matrix-for-a-beam-element-part-1/204"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Beam Element Stiffness Matrices",
          title: "Stiffness matrix for a beam element - part 2",          
          vimeoURL: "https://player.vimeo.com/video/616846418?h=9f789ea6ed",          
          duration: "30:56",
          preview: false,
          topicId: 205,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-stiffness-matrix-for-a-beam-element-part-2/205"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Beam Element Stiffness Matrices",
          title: "Transforming from local to global coordinates",          
          vimeoURL: "https://player.vimeo.com/video/616851672?h=574e040461",          
          duration: "11:13",
          preview: false,
          topicId: 206,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-transforming-from-local-to-global-coordinates/206"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616854116?h=f766370cee",          
          duration: "00:47",
          preview: true,
          topicId: 207,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-section-overview-2d-beam-frame/207"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Analysis procedure overview",          
          vimeoURL: "https://player.vimeo.com/video/616854616?h=4664eb1032",          
          duration: "05:51",
          preview: true,
          topicId: 208,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-analysis-procedure-overview/208"
            }
          ],
          files:[{
            linkText: "Lecture 10 Direct stiffness method step by step - Slides",
            filename: "Lecture 10.zip"                        
          }]
        },
        {
          lectureId: 11,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Calculating element stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/616855750?h=004023c462",          
          duration: "03:32",
          preview: false,
          topicId: 209,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-calculating-element-stiffness-matrices/209"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Building the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616856364?h=27e784eadc",          
          duration: "03:36",
          preview: false,
          topicId: 210,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-building-the-primary-stiffness-matrix/210"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Reducing to the structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616857080?h=acec040d78",          
          duration: "02:30",
          preview: false,
          topicId: 211,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-reducing-to-the-structure-stiffness-matrix/211"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Solving for unknown displacements",          
          vimeoURL: "https://player.vimeo.com/video/616857831?h=029ff9f910",          
          duration: "01:46",
          preview: false,
          topicId: 212,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-solving-for-unknown-displacements/212"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Solving for reactions",          
          vimeoURL: "https://player.vimeo.com/video/616858147?h=2cfa7b916b",          
          duration: "01:55",
          preview: false,
          topicId: 213,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-solving-for-reactions/213"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Beam/Frame Analysis Step-by-Step",
          title: "Finding axial forces, shears and moments",          
          vimeoURL: "https://player.vimeo.com/video/616858678?h=a46dbade1e",          
          duration: "05:17",
          preview: false,
          topicId: 214,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-finding-axial-forces-shears-and-moments/214"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Implementing Beam/Frame Analysis in Python",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616859166?h=2a9509904c",          
          duration: "01:01",
          preview: true,
          topicId: 215,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-section-overview-2d-beam-frame/215"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Implementing Beam/Frame Analysis in Python",
          title: "Element stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/616859704?h=bdde5ff9b5",          
          duration: "18:08",
          preview: true,
          topicId: 216,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-element-stiffness-matrices/216"
            }
          ],
          files:[{
            linkText: "Lecture 18 Jupyter Notebook",
            filename: "Lecture 18.zip"                        
          }]
        },
        {
          lectureId: 19,
          section: "Implementing Beam/Frame Analysis in Python",
          title: "Whole structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616861902?h=43114903d3",          
          duration: "13:34",
          preview: false,
          topicId: 217,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-whole-structure-stiffness-matrix/217"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Implementing Beam/Frame Analysis in Python",
          title: "Solving for displacements and reactions",          
          vimeoURL: "https://player.vimeo.com/video/616863449?h=7d10753828",          
          duration: "10:31",
          preview: false,
          topicId: 218,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-solving-for-displacements-and-reactions/218"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Implementing Beam/Frame Analysis in Python",
          title: "Evaluating bending moments and shear forces",          
          vimeoURL: "https://player.vimeo.com/video/616878474?h=5c29fbd15b",          
          duration: "12:50",
          preview: false,
          topicId: 219,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-evaluating-bending-moments-and-shear-forces/219"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Implementing Beam/Frame Analysis in Python",
          title: "Visualising displacements",          
          vimeoURL: "https://player.vimeo.com/video/616879248?h=250b3ade2f",          
          duration: "09:53",
          preview: false,
          topicId: 220,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-visualising-displacements/220"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Generalising our Code",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616880047?h=ef9d06e389",          
          duration: "01:07",
          preview: true,
          topicId: 221,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-section-overview/221"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Generalising our Code",
          title: "Element stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/616880194?h=86c413d029",          
          duration: "09:06",
          preview: false,
          topicId: 222,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-element-stiffness-matrices/222"
            }
          ],
          files:[{
            linkText: "Lecture 24 Jupyter Notebook",
            filename: "Lecture 24.zip"                        
          }]
        },
        {
          lectureId: 25,
          section: "Generalising our Code",
          title: "The structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616881886?h=f375ba92de",          
          duration: "05:42",
          preview: false,
          topicId: 223,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-the-structure-stiffness-matrix/223"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Generalising our Code",
          title: "Solving for displacements and reactions",          
          vimeoURL: "https://player.vimeo.com/video/616882565?h=ab8107b3af",          
          duration: "09:13",
          preview: false,
          topicId: 224,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-solving-for-displacements-and-reactions/224"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Generalising our Code",
          title: "Solving for axial forces, moments and shears",          
          vimeoURL: "https://player.vimeo.com/video/616883254?h=d31583bba1",          
          duration: "06:45",
          preview: false,
          topicId: 225,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-solving-for-axial-forces-moments-and-shears/225"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Generalising our Code",
          title: "Graphical and text output",          
          vimeoURL: "https://player.vimeo.com/video/616884160?h=d85f5796ec",          
          duration: "21:06",
          preview: false,
          topicId: 226,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-graphical-and-text-output/226"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Building Better Visualisations",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616886299?h=6a59f5fb70",          
          duration: "01:02",
          preview: true,
          topicId: 227,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-section-overview-2d-beam-frame/227"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Building Better Visualisations",
          title: "Adding nodal annotations to deflection",          
          vimeoURL: "https://player.vimeo.com/video/616886437?h=0765eca947",          
          duration: "14:16",
          preview: false,
          topicId: 228,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-adding-nodal-annotations-to-deflection/228"
            }
          ],
          files:[{
            linkText: "Lecture 30 Jupyter Notebook",
            filename: "Lecture 30.zip"                        
          }]
        },
        {
          lectureId: 31,
          section: "Building Better Visualisations",
          title: "Plotting an axial force diagram",          
          vimeoURL: "https://player.vimeo.com/video/616891773?h=5cb40befe1",          
          duration: "08:00",
          preview: false,
          topicId: 229,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-plotting-an-axial-force-diagram/229"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Building Better Visualisations",
          title: "Planning the bending moment diagram",          
          vimeoURL: "https://player.vimeo.com/video/616892441?h=c4aee2345b",          
          duration: "12:45",
          preview: false,
          topicId: 230,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-planning-the-bending-moment-diagram/230"
            }
          ],
          files:[]
        },
        {
          lectureId: 33,
          section: "Building Better Visualisations",
          title: "Building the bending moment diagram",          
          vimeoURL: "https://player.vimeo.com/video/616894803?h=58e6a66b27",          
          duration: "25:46",
          preview: false,
          topicId: 231,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-building-the-bending-moment-diagram/231"
            }
          ],
          files:[]
        },
        {
          lectureId: 34,
          section: "Building Better Visualisations",
          title: "Building the shear force diagram",          
          vimeoURL: "https://player.vimeo.com/video/616897243?h=e9a954ec81",          
          duration: "08:35",
          preview: false,
          topicId: 232,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-building-the-shear-force-diagram/232"
            }
          ],
          files:[]
        },
        {
          lectureId: 35,
          section: "Building Better Visualisations",
          title: "Adding UI widgets for plot adjustments",          
          vimeoURL: "https://player.vimeo.com/video/616898461?h=faf5f66aea",          
          duration: "13:30",
          preview: false,
          topicId: 233,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-adding-ui-widgets-for-plot-adjustments/233"
            }
          ],
          files:[{
            linkText: "Lecture 35 Jupyter Notebook",
            filename: "Lecture 35.zip"                        
          }]
        },
        {
          lectureId: 36,
          section: "Building Better Visualisations",
          title: "Label and widget refactor",          
          vimeoURL: "https://player.vimeo.com/video/616900707?h=2a96de66f8",          
          duration: "22:28",
          preview: false,
          topicId: 234,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-label-and-widget-refactor/234"
            }
          ],
          files:[{
            linkText: "Lecture 36 Jupyter Notebook",
            filename: "Lecture 36.zip"                        
          }]
        },
        {
          lectureId: 37,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616903270?h=ba023832f5",          
          duration: "01:50",
          preview: true,
          topicId: 235,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-section-overview-2d-beam-frame/235"
            }
          ],
          files:[]
        },
        {
          lectureId: 38,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Beam elements pinned at one end",          
          vimeoURL: "https://player.vimeo.com/video/616903692?h=e90a6c9d80",          
          duration: "08:07",
          preview: false,
          topicId: 236,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-beam-elements-pinned-at-one-end/236"
            }
          ],
          files:[{
            linkText: "Lecture 38 Beam elements pinned at one end - Slides",
            filename: "Lecture 38.zip"                        
          }]
        },
        {
          lectureId: 39,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Capturing pin locations",          
          vimeoURL: "https://player.vimeo.com/video/616905399?h=8e3caddd9c",          
          duration: "12:11",
          preview: false,
          topicId: 237,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-capturing-pin-locations/237"
            }
          ],
          files:[{
            linkText: "Lecture 39 Jupyter Notebook",
            filename: "Lecture 39.zip"                        
          }]
        },
        {
          lectureId: 40,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Pinned stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/616906674?h=98c9b6b4eb",          
          duration: "18:54",
          preview: false,
          topicId: 238,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-pinned-stiffness-matrices/238"
            }
          ],
          files:[]
        },
        {
          lectureId: 41,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Pinned structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/616909098?h=22443c57b2",          
          duration: "12:34",
          preview: false,
          topicId: 239,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-pinned-structure-stiffness-matrix/239"
            }
          ],
          files:[]
        },
        {
          lectureId: 42,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Solving for pinned member actions",          
          vimeoURL: "https://player.vimeo.com/video/616910650?h=bf8a882758",          
          duration: "17:33",
          preview: false,
          topicId: 240,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/42-solving-for-pinned-member-actions/240"
            }
          ],
          files:[]
        },
        {
          lectureId: 43,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Dealing with inter-nodal loading",          
          vimeoURL: "https://player.vimeo.com/video/616913474?h=11d87ab4bb",          
          duration: "17:16",
          preview: false,
          topicId: 241,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/43-dealing-with-inter-nodal-loading/241"
            }
          ],
          files:[]
        },
        {
          lectureId: 44,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Recording inter-nodal loading",          
          vimeoURL: "https://player.vimeo.com/video/616917005?h=87e1bc4cb3",          
          duration: "19:46",
          preview: false,
          topicId: 242,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/44-recording-inter-nodal-loading/242"
            }
          ],
          files:[{
            linkText: "Lecture 44 Jupyter Notebook",
            filename: "Lecture 44.zip"                        
          }]
        },
        {
          lectureId: 45,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Removing Equivalent Nodal Actions",          
          vimeoURL: "https://player.vimeo.com/video/616919374?h=15155bbe68",          
          duration: "13:37",
          preview: false,
          topicId: 243,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/45-removing-equivalent-nodal-actions/243"
            }
          ],
          files:[]
        },
        {
          lectureId: 46,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Inter-nodal loading with pins",          
          vimeoURL: "https://player.vimeo.com/video/616920961?h=ae94ac5f3a",          
          duration: "16:45",
          preview: false,
          topicId: 244,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/46-inter-nodal-loading-with-pins/244"
            }
          ],
          files:[]
        },
        {
          lectureId: 47,
          section: "Pins, Inter-Nodal Loading and Flexible Supports",
          title: "Flexible supports",          
          vimeoURL: "https://player.vimeo.com/video/616922212?h=3e29c599d5",          
          duration: "11:52",
          preview: false,
          topicId: 245,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/47-flexible-supports/245"
            }
          ],
          files:[{
            linkText: "Lecture 47 Jupyter Notebook",
            filename: "Lecture 47.zip"                        
          }]
        },
        {
          lectureId: 48,
          section: "Test Questions",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/616924594?h=92a1fcc042",          
          duration: "00:34",
          preview: true,
          topicId: 246,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/48-section-overview-2d-beam-frame/246"
            }
          ],
          files:[]
        },
        {
          lectureId: 49,
          section: "Test Questions",
          title: "Installing Nbextensions (optional)",          
          vimeoURL: "https://player.vimeo.com/video/616924742?h=166b057820",          
          duration: "05:16",
          preview: false,
          topicId: 247,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/49-installing-nbextensions-optional/247"
            }
          ],
          files:[]
        },
        {
          lectureId: 50,
          section: "Test Questions",
          title: "Test Question #1",          
          vimeoURL: "https://player.vimeo.com/video/616925520?h=db8e884ea9",          
          duration: "13:19",
          preview: false,
          topicId: 248,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/50-test-question-1/248"
            }
          ],
          files:[{
            linkText: "Lecture 50 Jupyter Notebook",
            filename: "Lecture 50.zip"                        
          }]
        },
        {
          lectureId: 51,
          section: "Test Questions",
          title: "Test Question #2",          
          vimeoURL: "https://player.vimeo.com/video/616931983?h=4c791766f5",          
          duration: "11:31",
          preview: false,
          topicId: 249,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/51-test-question-2/249"
            }
          ],
          files:[{
            linkText: "Lecture 51 Jupyter Notebook",
            filename: "Lecture 51.zip"                        
          }]
        },
        {
          lectureId: 52,
          section: "Test Questions",
          title: "Test Question #3",          
          vimeoURL: "https://player.vimeo.com/video/616933180?h=5c46bdd584",          
          duration: "02:17",
          preview: false,
          topicId: 250,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/52-test-question-3/250"
            }
          ],
          files:[{
            linkText: "Lecture 52 Jupyter Notebook",
            filename: "Lecture 52.zip"                        
          }]
        },
        {
          lectureId: 53,
          section: "Test Questions",
          title: "Test Question #4",          
          vimeoURL: "https://player.vimeo.com/video/616933486?h=37e7a2cacc",          
          duration: "06:43",
          preview: false,
          topicId: 251,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/53-test-question-4/251"
            }
          ],
          files:[{
            linkText: "Lecture 53 Jupyter Notebook",
            filename: "Lecture 53.zip"                        
          }]
        },
        {
          lectureId: 54,
          section: "Test Questions",
          title: "Test Question #5",          
          vimeoURL: "https://player.vimeo.com/video/622209179?h=954732dee9",          
          duration: "09:21",
          preview: true,
          topicId: 252,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/54-test-question-5/252"
            }
          ],
          files:[{
            linkText: "Lecture 54Jupyter Notebook",
            filename: "Lecture 54.zip"                        
          }]
        },
        {
          lectureId: 55,
          section: "Test Questions",
          title: "Course wrap up and debrief",          
          vimeoURL: "https://player.vimeo.com/video/622210255?h=31e53009a0",          
          duration: "01:16",
          preview: false,
          topicId: 253,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/55-course-wrap-up-and-debrief-2d-beam-frame/253"
            }
          ],
          files:[]
        },
        {
          lectureId: 56,
          section: "Course Updates",
          title: "Combining beam and axially loaded bar elements",          
          vimeoURL: "https://player.vimeo.com/video/622210402?h=4af0943138",          
          duration: "23:50",
          preview: false,
          topicId: 254,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/56-combining-beam-and-axially-loaded-bar-elements/254"
            }
          ],
          files:[{
            linkText: "Lecture 56 Jupyter Notebook",
            filename: "Lecture 56.zip"                        
          }]
        },
        {
          lectureId: 57,
          section: "Course Updates",
          title: "Varying element cross-sectional areas",          
          vimeoURL: "https://player.vimeo.com/video/622214002?h=37ba79ddc4",          
          duration: "06:46",
          preview: false,
          topicId: 255,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/57-varying-element-cross-sectional-areas/255"
            }
          ],
          files:[{
            linkText: "Lecture 57 Jupyter Notebook",
            filename: "Lecture 57.zip"                        
          }]
        },
      ]
    },
    {
      courseId: 11,
      title: "The Direct Stiffness Method for Truss Analysis with Python",
      tagline: "Build your own finite element truss analysis software using Python and tackle large scale structures",
      duration: 7.5,
      nlectures: 56,
      path: "course-11/lecture-1",
      infoURL:"https://www.degreetutors.com/the-direct-stiffness-method-for-truss-analysis-with-python/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/622221766?h=fc5c7778b2",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Welcome and setup",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/622224189?h=9d335984b6",          
          duration: "06:40",
          preview: true,
          topicId: 106,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview/106"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and setup",
          title: "Our approach to Python",          
          vimeoURL: "https://player.vimeo.com/video/622225735?h=e30fc9d088",          
          duration: "02:02",
          preview: true,
          topicId: 107,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-our-approach-to-python/107"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Welcome and setup",
          title: "Getting started with Jupyter Notebooks",          
          vimeoURL: "https://player.vimeo.com/video/622226178?h=d97e4e5126",          
          duration: "12:04",
          preview: true,
          topicId: 108,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-getting-started-with-jupyter-notebooks/108"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Modelling Elastic Behaviour",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622227735?h=542398574a",          
          duration: "01:08",
          preview: true,
          topicId: 109,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-section-overview-2d-trusses/109"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Modelling Elastic Behaviour",
          title: "Stress and strain in 2D",          
          vimeoURL: "https://player.vimeo.com/video/622228082?h=de2a9e5883",          
          duration: "14:48",
          preview: true,
          topicId: 110,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-stress-and-strain-in-2d/110"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Modelling Elastic Behaviour",
          title: "Strain & displacement in 2D",          
          vimeoURL: "https://player.vimeo.com/video/622231796?h=5d18422117",          
          duration: "12:25",
          preview: false,
          topicId: 111,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-strain-displacement-in-2d/111"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Modelling Elastic Behaviour",
          title: "3 dimensions and matrix notation",          
          vimeoURL: "https://player.vimeo.com/video/622234541?h=59bd150d7c",          
          duration: "19:08",
          preview: false,
          topicId: 112,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-3-dimensions-and-matrix-notation/112"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Modelling Elastic Behaviour",
          title: "Plane Stress condition",          
          vimeoURL: "https://player.vimeo.com/video/622239826?h=a819b189e5",          
          duration: "16:41",
          preview: false,
          topicId: 113,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-plane-stress-condition/113"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Modelling Elastic Behaviour",
          title: "Plane Strain condition",          
          vimeoURL: "https://player.vimeo.com/video/622243995?h=ed5b502aa2",          
          duration: "06:52",
          preview: false,
          topicId: 114,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-plane-strain-condition/114"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Modelling Elastic Behaviour",
          title: "Material matrix summary",          
          vimeoURL: "https://player.vimeo.com/video/622246005?h=d2111007cd",          
          duration: "08:40",
          preview: false,
          topicId: 115,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-material-matrix-summary/115"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622248278?h=32fca89032",          
          duration: "01:39",
          preview: true,
          topicId: 116,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-section-overview-2d-trusses/116"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "Finite Element equations and the Direct Stiffness method",          
          vimeoURL: "https://player.vimeo.com/video/622249016?h=edb9ad0664",          
          duration: "21:25",
          preview: true,
          topicId: 117,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-finite-element-equations-and-the-direct-stiffness-method/117"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "The Principle of Minimum Potential Energy",          
          vimeoURL: "https://player.vimeo.com/video/622253276?h=65241f5ecc",          
          duration: "15:18",
          preview: false,
          topicId: 118,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-the-principle-of-minimum-potential-energy/118"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "The Finite Element equations",          
          vimeoURL: "https://player.vimeo.com/video/622257271?h=be4c6ad8be",          
          duration: "13:48",
          preview: false,
          topicId: 119,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-the-finite-element-equations/119"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "Stiffness matrix for bar element",          
          vimeoURL: "https://player.vimeo.com/video/622261559?h=f03fe68ec4",          
          duration: "13:13",
          preview: false,
          topicId: 120,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-stiffness-matrix-for-bar-element/120"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "Finite element equation review",          
          vimeoURL: "https://player.vimeo.com/video/622264333?h=8ff747e34d",          
          duration: "14:16",
          preview: false,
          topicId: 121,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-finite-element-equation-review/121"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Finite Element Equations & Stiffness Matrices",
          title: "Transformation from local to global coordinates",          
          vimeoURL: "https://player.vimeo.com/video/622267431?h=21b5f43d1c",          
          duration: "16:31",
          preview: false,
          topicId: 122,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-transformation-from-local-to-global-coordinates/122"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622270993?h=2b39a9d7f5",          
          duration: "01:38",
          preview: true,
          topicId: 123,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-section-overview-2d-trusses/123"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Analysis procedure overview",          
          vimeoURL: "https://player.vimeo.com/video/622271396?h=05558351f1",          
          duration: "06:07",
          preview: true,
          topicId: 124,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-analysis-procedure-overview/124"
            }
          ],
          files:[
            {
              linkText: "Direct Stiffness Method step by step - slides",
              filename: "Lecture 19.zip"            
            }
          ]
        },
        {
          lectureId: 20,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Calculating element stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/622272360?h=9793c19f91",          
          duration: "04:37",
          preview: false,
          topicId: 125,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-calculating-element-stiffness-matrices/125"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Building the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622273343?h=e6052826b2",          
          duration: "06:24",
          preview: false,
          topicId: 126,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-building-the-primary-stiffness-matrix/126"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Reducing to structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622274173?h=51d59600e1",          
          duration: "04:48",
          preview: false,
          topicId: 127,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-reducing-to-structure-stiffness-matrix/127"
            }
          ],
          files:[]
        },        
        {
          lectureId: 23,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Solve for unknown displacements",          
          vimeoURL: "https://player.vimeo.com/video/622275126?h=3c18ab74e8",          
          duration: "01:39",
          preview: false,
          topicId: 128,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-solve-for-unknown-displacements/128"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Solve for unknown reactions",          
          vimeoURL: "https://player.vimeo.com/video/622275405?h=afd63b092f",          
          duration: "02:43",
          preview: false,
          topicId: 129,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-solve-for-unknown-reactions/129"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Direct Stiffness Method: Step-by-Step",
          title: "Finding element forces",          
          vimeoURL: "https://player.vimeo.com/video/622275943?h=4e272b614b",          
          duration: "05:31",
          preview: false,
          topicId: 130,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-finding-element-forces/130"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Direct Stiffness Method in Python",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622276929?h=9b995c2788",          
          duration: "01:24",
          preview: true,
          topicId: 131,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-section-overview-2d-trusses/131"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Direct Stiffness Method in Python",
          title: "Element stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/622277743?h=ada795a927",          
          duration: "14:48",
          preview: true,
          topicId: 132,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-element-stiffness-matrices/132"
            }
          ],
          files:[{
            linkText: "Lecture 27 Jupyter Notebook",
            filename: "Lecture 27.zip"            
          }]
        },
        {
          lectureId: 28,
          section: "Direct Stiffness Method in Python",
          title: "Whole structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622278797?h=6dcb636ed1",          
          duration: "11:52",
          preview: false,
          topicId: 133,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-whole-structure-stiffness-matrix/133"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Direct Stiffness Method in Python",
          title: "Reactions, element forces and nodal displacements",          
          vimeoURL: "https://player.vimeo.com/video/622280119?h=a748d2e583",          
          duration: "12:58",
          preview: false,
          topicId: 134,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-reactions-element-forces-and-nodal-displacements/134"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Direct Stiffness Method in Python",
          title: "Visualising our output",          
          vimeoURL: "https://player.vimeo.com/video/622281670?h=b634306edb",          
          duration: "13:00",
          preview: false,
          topicId: 135,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-visualising-our-output/135"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "Direct Stiffness Method in Python",
          title: "Refactor - Tidying up with functions",          
          vimeoURL: "https://player.vimeo.com/video/622283061?h=222c5c963b",          
          duration: "17:33",
          preview: false,
          topicId: 136,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-refactor-tidying-up-with-functions/136"
            }
          ],
          files:[
            {
              linkText: "Lecture 31 Jupyter Notebook",
              filename: "Lecture 31.zip"            
            }
          ]
        },
        {
          lectureId: 32,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622286435?h=fec18ed047",          
          duration: "00:57",
          preview: true,
          topicId: 137,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-section-overview-2d-trusses/137"
            }
          ],
          files:[]
        },
        {
          lectureId: 33,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Calculating element stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/622286697?h=a5f9019802",          
          duration: "04:28",
          preview: false,
          topicId: 138,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-calculating-element-stiffness-matrices/138"
            }
          ],
          files:[
            {
              linkText: "Direct Stiffness Method on larger structures - slides",
              filename: "Lecture 33a.zip"            
            },
            {
              linkText: "Lecture 33 Jupyter Notebook",
              filename: "Lecture 33b.zip"            
            }
          ]
        },
        {
          lectureId: 34,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Building the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622287878?h=701055ac6b",          
          duration: "03:47",
          preview: false,
          topicId: 139,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-building-the-primary-stiffness-matrix/139"
            }
          ],
          files:[]
        },
        {
          lectureId: 35,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Reducing to structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622288770?h=f4d3226937",          
          duration: "01:44",
          preview: false,
          topicId: 140,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-reducing-to-structure-stiffness-matrix/140"
            }
          ],
          files:[]
        },
        {
          lectureId: 36,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Solve for unknown displacements",          
          vimeoURL: "https://player.vimeo.com/video/622289325?h=4c94dcb7af",          
          duration: "01:05",
          preview: false,
          topicId: 141,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/36-solve-for-unknown-displacements/141"
            }
          ],
          files:[]
        },
        {
          lectureId: 37,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Solve for unknown reactions",          
          vimeoURL: "https://player.vimeo.com/video/622289777?h=e6335694c7",          
          duration: "01:19",
          preview: false,
          topicId: 142,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/37-solve-for-unknown-reactions/142"
            }
          ],
          files:[]
        },
        {
          lectureId: 38,
          section: "Direct Stiffness Method on Larger Structures",
          title: "Finding element forces",          
          vimeoURL: "https://player.vimeo.com/video/622289955?h=933b372029",          
          duration: "03:35",
          preview: false,
          topicId: 143,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/38-finding-element-forces/143"
            }
          ],
          files:[]
        },
        {
          lectureId: 39,
          section: "Optimising for Larger Structures in Python",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622290215?h=6a53c4c20a",          
          duration: "01:06",
          preview: true,
          topicId: 144,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/39-section-overview-2d-trusses/144"
            }
          ],
          files:[]
        },
        {
          lectureId: 40,
          section: "Optimising for Larger Structures in Python",
          title: "Building the primary stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622291017?h=a165cee6d5",          
          duration: "19:09",
          preview: false,
          topicId: 145,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/40-building-the-primary-stiffness-matrix/145"
            }
          ],
          files:[
            {
              linkText: "Lecture 40 Jupyter Notebook",
              filename: "Lecture 40.zip"            
            }
          ]
        },
        {
          lectureId: 41,
          section: "Optimising for Larger Structures in Python",
          title: "Extracting the structure stiffness matrix",          
          vimeoURL: "https://player.vimeo.com/video/622294440?h=5e80f2341f",          
          duration: "03:00",
          preview: false,
          topicId: 146,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/41-extracting-the-structure-stiffness-matrix/146"
            }
          ],
          files:[]
        },
        {
          lectureId: 42,
          section: "Optimising for Larger Structures in Python",
          title: "Displacements and reactions",          
          vimeoURL: "https://player.vimeo.com/video/622294647?h=b4273d6a8e",          
          duration: "09:29",
          preview: false,
          topicId: 147,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/42-displacements-and-reactions/147"
            }
          ],
          files:[]
        },
        {
          lectureId: 43,
          section: "Optimising for Larger Structures in Python",
          title: "Calculating member forces",          
          vimeoURL: "https://player.vimeo.com/video/622295992?h=acc4e484ff",          
          duration: "04:58",
          preview: false,
          topicId: 148,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/43-calculating-member-forces/148"
            }
          ],
          files:[]
        },
        {
          lectureId: 44,
          section: "Optimising for Larger Structures in Python",
          title: "Visualising our output",          
          vimeoURL: "https://player.vimeo.com/video/622296780?h=fa044104da",          
          duration: "10:16",
          preview: false,
          topicId: 149,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/44-visualising-our-output/149"
            }
          ],
          files:[]
        },
        {
          lectureId: 45,
          section: "Building a Generalised Truss Solver in Python",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622298507?h=9fa70d86a1",          
          duration: "01:06",
          preview: false,
          topicId: 150,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/45-section-overview-2d-trusses/150"
            }
          ],
          files:[]
        },
        {
          lectureId: 46,
          section: "Building a Generalised Truss Solver in Python",
          title: "Establishing input data",          
          vimeoURL: "https://player.vimeo.com/video/622298889?h=5e1cc0f7b9",          
          duration: "07:16",
          preview: false,
          topicId: 151,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/46-establishing-input-data/151"
            }
          ],
          files:[
            {
              linkText: "Lecture 46 Jupyter Notebook",
              filename: "Lecture 46.zip"            
            }
          ]
        },
        {
          lectureId: 47,
          section: "Building a Generalised Truss Solver in Python",
          title: "Calculating member orientation and length",          
          vimeoURL: "https://player.vimeo.com/video/622299660?h=9c46ad2eb7",          
          duration: "27:43",
          preview: false,
          topicId: 152,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/47-calculating-member-orientation-and-length/152"
            }
          ],
          files:[]
        },
        {
          lectureId: 48,
          section: "Building a Generalised Truss Solver in Python",
          title: "Primary and Structure Stiffness matrices",          
          vimeoURL: "https://player.vimeo.com/video/622303233?h=c345a01910",          
          duration: "10:07",
          preview: false,
          topicId: 153,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/48-primary-and-structure-stiffness-matrices/153"
            }
          ],
          files:[]
        },
        {
          lectureId: 49,
          section: "Building a Generalised Truss Solver in Python",
          title: "Displacements, reactions and member forces",          
          vimeoURL: "https://player.vimeo.com/video/622304412?h=4b11b7694e",          
          duration: "16:30",
          preview: false,
          topicId: 154,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/49-displacements-reactions-and-member-forces/154"
            }
          ],
          files:[]
        },
        {
          lectureId: 50,
          section: "Building a Generalised Truss Solver in Python",
          title: "Automating the output visualisation",          
          vimeoURL: "https://player.vimeo.com/video/622306003?h=8050152a46",          
          duration: "10:04",
          preview: false,
          topicId: 155,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/50-automating-the-output-visualisation/155"
            }
          ],
          files:[]
        },
        {
          lectureId: 51,
          section: "Building a Generalised Truss Solver in Python",
          title: "Automating the text summary",          
          vimeoURL: "https://player.vimeo.com/video/622307250?h=69345c1f81",          
          duration: "10:35",
          preview: false,
          topicId: 156,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/51-automating-the-text-summary/156"
            }
          ],
          files:[]
        },
        {
          lectureId: 52,
          section: "Taking your Solver for a Test Drive",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622308754?h=401dc464d6",          
          duration: "00:45",
          preview: true,
          topicId: 157,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/52-section-overview-2d-trusses/157"
            }
          ],
          files:[]
        },
        {
          lectureId: 53,
          section: "Taking your Solver for a Test Drive",
          title: "Test Question #1",          
          vimeoURL: "https://player.vimeo.com/video/622309201?h=3df1652b4a",          
          duration: "09:44",
          preview: false,
          topicId: 158,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/53-test-question-1/158"
            }
          ],
          files:[
            {
              linkText: "Lecture 53 Jupyter Notebook",
              filename: "Lecture 53.zip"            
            }
          ]
        },
        {
          lectureId: 54,
          section: "Taking your Solver for a Test Drive",
          title: "Test Question #2",          
          vimeoURL: "https://player.vimeo.com/video/622310624?h=715662b05a",          
          duration: "05:58",
          preview: false,
          topicId: 159,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/54-test-question-2/159"
            }
          ],
          files:[
            {
              linkText: "Lecture 54 Jupyter Notebook",
              filename: "Lecture 54.zip"            
            }
          ]
        },
        {
          lectureId: 55,
          section: "Taking your Solver for a Test Drive",
          title: "Test Question #3",          
          vimeoURL: "https://player.vimeo.com/video/622311857?h=2a385da20f",          
          duration: "04:07",
          preview: false,
          topicId: 160,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/55-test-question-3/160"
            }
          ],
          files:[
            {
              linkText: "Lecture 55 Jupyter Notebook",
              filename: "Lecture 55.zip"            
            }
          ]
        },
        {
          lectureId: 56,
          section: "Taking your Solver for a Test Drive",
          title: "Course wrap up and debrief",          
          vimeoURL: "https://player.vimeo.com/video/622312594?h=67c577bf48",          
          duration: "01:38",
          preview: false,
          topicId: 161,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/56-course-wrap-up-and-debrief/161"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 10,
      title: "Fundamentals of Engineering Structural Dynamics with Python",
      tagline: "Leverage fundamental structural dynamics to build your own flexible numerical solutions in Python",      
      nlectures: 35,
      path: "course-10/lecture-1",
      infoURL: "https://www.degreetutors.com/fundamentals-of-structural-dynamics-with-python/",
      video: true,
      text: true,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",      
      vimeoURL: "https://player.vimeo.com/video/594081250?h=47b57cbbb3",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Statics versus Dynamics",
          title: "Introduction and course overview",          
          vimeoURL: "https://player.vimeo.com/video/622327589?h=2a07362287",          
          duration: "05:11",
          preview: true,
          topicId: 163,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/1-introduction-and-course-overview-fundamental-dynamics/163"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Statics versus Dynamics",
          title: "Our approach to Python",          
          vimeoURL: "https://player.vimeo.com/video/622329869?h=9a828501e1",          
          duration: "02:05",
          preview: true,
          topicId: 164,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/2-our-approach-to-python-fundamental-dynamics/164"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Statics versus Dynamics",
          title: "Getting started with Jupyter Notebooks",          
          vimeoURL: "https://player.vimeo.com/video/622330629?h=0468ea6570",          
          duration: "12:10",
          preview: false,
          topicId: 165,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/3-getting-started-with-jupyter-notebooks-fundamental-dynamics/165"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Statics versus Dynamics",
          title: "Static versus dynamic analysis",          
          vimeoURL: "https://player.vimeo.com/video/622332963?h=eecd058b1d",          
          duration: "08:26",
          preview: true,
          topicId: 166,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/4-static-versus-dynamic-analysis/166"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Statics versus Dynamics",
          title: "Introducing inertia",          
          vimeoURL: "https://player.vimeo.com/video/622335970?h=a3eaa08ca2",          
          duration: "12:38",
          preview: true,
          topicId: 167,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/5-introducing-inertia/167"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Statics versus Dynamics",
          title: "Dynamic response and inertia ",          
          vimeoURL: "https://player.vimeo.com/video/622341039?h=8571e95fcb",          
          duration: "13:25",
          preview: false,
          topicId: 168,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/6-dynamic-response-and-inertia/168"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622346170?h=d0304a56bb",          
          duration: "00:54",
          preview: false,
          topicId: 169,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/7-section-overview-fundamental-dynamics/169"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Lumped mass analysis",          
          vimeoURL: "https://player.vimeo.com/video/622346446?h=f6d28623bf",          
          duration: "14:44",
          preview: false,
          topicId: 170,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/8-lumped-mass-analysis/170"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "The spring-mass-damper model",          
          vimeoURL: "https://player.vimeo.com/video/622351628?h=2762ea8425",          
          duration: "09:15",
          preview: false,
          topicId: 171,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/9-the-spring-mass-damper-model/171"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "The equation of motion",          
          vimeoURL: "https://player.vimeo.com/video/622355859?h=b1e928d616",          
          duration: "10:29",
          preview: false,
          topicId: 172,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/10-the-equation-of-motion/172"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Undamped free vibration",          
          vimeoURL: "https://player.vimeo.com/video/622360138?h=34b1c5cec4",          
          duration: "08:53",
          preview: false,
          topicId: 173,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/11-undamped-free-vibration/173"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Natural frequency",          
          vimeoURL: "https://player.vimeo.com/video/622363671?h=e53d6658f0",          
          duration: "04:39",
          preview: false,
          topicId: 174,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/12-natural-frequency/174"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Damped free vibration",          
          vimeoURL: "https://player.vimeo.com/video/622364598?h=16b1f0f267",          
          duration: "05:50",
          preview: false,
          topicId: 175,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/13-damped-free-vibration/175"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Critically-damped and over-damped systems",          
          vimeoURL: "https://player.vimeo.com/video/622367062?h=dab8d7dc17",          
          duration: "06:38",
          preview: false,
          topicId: 176,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/14-critically-damped-and-over-damped-systems/176"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Under-damped systems",          
          vimeoURL: "https://player.vimeo.com/video/622427820?h=b4fe257447",          
          duration: "07:16",
          preview: false,
          topicId: 177,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/15-under-damped-systems/177"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Damped natural frequency",          
          vimeoURL: "https://player.vimeo.com/video/622369699?h=2072d05dca",          
          duration: "05:36",
          preview: false,
          topicId: 178,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/16-damped-natural-frequency/178"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Logarithmic decrement",          
          vimeoURL: "https://player.vimeo.com/video/622371377?h=823990e5c2",          
          duration: "11:30",
          preview: false,
          topicId: 179,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/17-logarithmic-decrement/179"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Worked Example #1",          
          vimeoURL: "https://player.vimeo.com/video/622373955?h=4da0898d1c",          
          duration: "24:02",
          preview: false,
          topicId: 180,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/18-worked-example-1/180"
            }
          ],
          files:[
            {
              linkText: "Lecture 18 Jupyter Notebook",
              filename: "Lecture 18.zip"            
            }
          ]
        },
        {
          lectureId: 19,
          section: "Free Vibration of Single-Degree-of-Freedom Systems",
          title: "Worked Example #2",          
          vimeoURL: "https://player.vimeo.com/video/622376325?h=8e662aa4e9",          
          duration: "42:07",
          preview: false,
          topicId: 181,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/19-worked-example-2/181"
            }
          ],
          files:[
            {
              linkText: "Lecture 19 Jupyter Notebook",
              filename: "Lecture 19.zip"            
            }
          ]
        },
        {
          lectureId: 20,
          section: "Harmonic Excitation",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622383021?h=8f5ce400ea",          
          duration: "00:55",
          preview: false,
          topicId: 182,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/20-section-overview-fundamental-dynamics/182"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Harmonic Excitation",
          title: "Harmonic forcing",          
          vimeoURL: "https://player.vimeo.com/video/622383664?h=6c9d76b524",          
          duration: "09:02",
          preview: false,
          topicId: 183,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/21-harmonic-forcing/183"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Harmonic Excitation",
          title: "Solving the equation of motion",          
          vimeoURL: "https://player.vimeo.com/video/622385440?h=9549f0286f",          
          duration: "14:14",
          preview: false,
          topicId: 184,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/22-solving-the-equation-of-motion/184"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Harmonic Excitation",
          title: "Characterising the steady-state response",          
          vimeoURL: "https://player.vimeo.com/video/622388879?h=9c17c029fa",          
          duration: "13:49",
          preview: false,
          topicId: 185,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/23-characterising-the-steady-state-response/185"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Harmonic Excitation",
          title: "Dynamic magnification factor and resonance",          
          vimeoURL: "https://player.vimeo.com/video/622391882?h=34e6ef42da",          
          duration: "27:58",
          preview: false,
          topicId: 186,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/24-dynamic-magnification-factor-and-resonance/186"
            }
          ],
          files:[
            {
              linkText: "Lecture 24 Jupyter Notebook",
              filename: "Lecture 24.zip"            
            }
          ]
        },
        {
          lectureId: 25,
          section: "Harmonic Excitation",
          title: "Worked Example #3 - Part A",          
          vimeoURL: "https://player.vimeo.com/video/622396208?h=06f7601e19",          
          duration: "19:57",
          preview: false,
          topicId: 187,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/25-worked-example-3-part-a/187"
            }
          ],
          files:[
            {
              linkText: "Lecture 25 Jupyter Notebook",
              filename: "Lecture 25.zip"            
            }
          ]
        },
        {
          lectureId: 26,
          section: "Harmonic Excitation",
          title: "Worked Example #3 - Part B",          
          vimeoURL: "https://player.vimeo.com/video/622398375?h=eb52601cd9",          
          duration: "18:29",
          preview: false,
          topicId: 188,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/26-worked-example-3-part-b/188"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Harmonic Excitation",
          title: "Worked Example #3 - Part C",          
          vimeoURL: "https://player.vimeo.com/video/622402178?h=c5c6987d16",          
          duration: "20:14",
          preview: false,
          topicId: 189,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/27-worked-example-3-part-c/189"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "General Dynamic Excitation",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/622406511?h=b76b469dc4",          
          duration: "01:03",
          preview: false,
          topicId: 190,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/28-section-overview-fundamental-dynamics/190"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "General Dynamic Excitation",
          title: "General dynamic loading and feedback",          
          vimeoURL: "https://player.vimeo.com/video/622406836?h=f31ddd5276",          
          duration: "11:48",
          preview: false,
          topicId: 191,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/29-general-dynamic-loading-and-feedback/191"
            },
            {
              linkText: "Pedestrian-induced vibrations of the Clifton Suspension Bridge, UK",
              url: "https://www.icevirtuallibrary.com/doi/10.1680/bren.2008.161.2.69"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "General Dynamic Excitation",
          title: "The Piecewise-Exact Method - concept",          
          vimeoURL: "https://player.vimeo.com/video/622410185?h=f6d9981e46",          
          duration: "07:56",
          preview: false,
          topicId: 192,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/30-the-piecewise-exact-method-concept/192"
            }
          ],
          files:[]
        },
        {
          lectureId: 31,
          section: "General Dynamic Excitation",
          title: "The Piecewise-Exact Method - development",          
          vimeoURL: "https://player.vimeo.com/video/622412577?h=a2b8c4ff94",          
          duration: "12:48",
          preview: false,
          topicId: 193,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/31-the-piecewise-exact-method-development/193"
            },
            {
              linkText: "Dynamics of Structures by J.L. Humar",
              url: "https://www.amazon.co.uk/Dynamics-Structures-J-Humar-ebook/dp/B007IVDSFS/ref=sr_1_3?keywords=dynamics+of+structures+humar&qid=1639752853&sr=8-3"
            }
          ],
          files:[{
            linkText: "Lecture 31 Jupyter Notebook",
            filename: "Lecture 31.zip"            
          }]
        },
        {
          lectureId: 32,
          section: "General Dynamic Excitation",
          title: "Implementing the Piecewise-Exact Method",          
          vimeoURL: "https://player.vimeo.com/video/622414633?h=5b0b179322",          
          duration: "29:13",
          preview: false,
          topicId: 194,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/32-implementing-the-piecewise-exact-method/194"
            }
          ],
          files:[
            {
              linkText: "Lecture 32 Jupyter Notebook",
              filename: "Lecture 32.zip"            
            }
          ]
        },
        {
          lectureId: 33,
          section: "General Dynamic Excitation",
          title: "Force segmentation",          
          vimeoURL: "https://player.vimeo.com/video/622419533?h=f99ce93ed2",          
          duration: "25:47",
          preview: false,
          topicId: 195,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/33-force-segmentation/195"
            }
          ],
          files:[
            {
              linkText: "Lecture 33 Jupyter Notebook",
              filename: "Lecture 33.zip"            
            }
          ]
        },
        {
          lectureId: 34,
          section: "General Dynamic Excitation",
          title: "Time discretisation",          
          vimeoURL: "https://player.vimeo.com/video/622425097?h=fe2a08f4d7",          
          duration: "14:12",
          preview: false,
          topicId: 196,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/34-time-discretisation/196"
            }
          ],
          files:[
            {
              linkText: "Lecture 34 Jupyter Notebook",
              filename: "Lecture 34.zip"            
            }
          ]
        },
        {
          lectureId: 35,
          section: "General Dynamic Excitation",
          title: "Course wrap up",          
          vimeoURL: "https://player.vimeo.com/video/622427593?h=d79c5101f3",          
          duration: "00:50",
          preview: false,
          topicId: 197,
          links:[
            {
              linkText: "Support and lecture Q&A",
              url: "https://community.degreetutors.com/t/35-course-wrap-up-fundamental-dynamics/197"
            }
          ],
          files:[]
        }
      ]
    },
    {
      courseId: 9,
      title: "Analysing Hybrid Indeterminate Civil Engineering Structures",
      tagline: "Powerful techniques for analysing realistic civil engineering structures",
      duration: 4,
      nlectures: 20,
      path: "course-9/lecture-1",
      infoURL:"https://www.degreetutors.com/analysing-complex-indeterminate-structures-using-virtual-work/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/635395565?h=8b728f3c35",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction",
          title: "Welcome to the course",          
          vimeoURL: "https://player.vimeo.com/video/635397263?h=b24cdfbdde",          
          duration: "01:59",
          preview: true,
          topicId: 846,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241547-introduction/698565-1-welcome-to-the-course"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Introduction",
          title: "Course overview and tips for success",          
          vimeoURL: "https://player.vimeo.com/video/635398950?h=8687671156",          
          duration: "02:32",
          preview: true,
          topicId: 847,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241547-introduction/698566-2-course-overview-and-tips-for-success"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Complex Structure Analysis Strategy",
          title: "What are complex structures?",          
          vimeoURL: "https://player.vimeo.com/video/635400926?h=6aacf915e2",          
          duration: "09:50",
          preview: true,
          topicId: 848,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698577-3-what-are-complex-structures"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Complex Structure Analysis Strategy",
          title: "How does superposition help us?",          
          vimeoURL: "https://player.vimeo.com/video/635403639?h=a3e7370cea",          
          duration: "11:21",
          preview: true,
          topicId: 849,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698578-4-how-does-superposition-help-us"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Complex Structure Analysis Strategy",
          title: "Dealing with axial forces",          
          vimeoURL: "https://player.vimeo.com/video/635406018?h=c2abd0be90",          
          duration: "13:07",
          preview: false,
          topicId: 850,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698576-5-dealing-with-axial-forces"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Complex Structure Analysis Strategy",
          title: "Dealing with bending moments",          
          vimeoURL: "https://player.vimeo.com/video/635407746?h=7e89c9e5d1",          
          duration: "08:38",
          preview: false,
          topicId: 851,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698583-6-dealing-with-bending-moments"
            }
          ],
          files:[{
            linkText: "Integral tables",
            filename: "Lecture 6.zip"            
          }]
        },
        {
          lectureId: 7,
          section: "Complex Structure Analysis Strategy",
          title: "Finding the scale factor, alpha",          
          vimeoURL: "https://player.vimeo.com/video/635420623?h=a08c61327e",          
          duration: "15:18",
          preview: false,
          topicId: 852,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698594-7-finding-the-scale-factor-alpha"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Complex Structure Analysis Strategy",
          title: "Analysis Walkthrough – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638555757?h=c6ff30e43d",          
          duration: "15:25",
          preview: false,
          topicId: 853,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698604-8-analysis-walkthrough-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Complex Structure Analysis Strategy",
          title: "Analysis Walkthrough – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638557249?h=430e093ca3",          
          duration: "13:30",
          preview: false,
          topicId: 854,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241548-complex-structure-analysis-strategy/698618-9-analysis-walkthrough-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Challenge Questions",
          title: "Challenge Question 1 – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638558856?h=755839c875",          
          duration: "16:21",
          preview: false,
          topicId: 855,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698755-10-challenge-question-1-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Challenge Questions",
          title: "Challenge Question 1 – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638560537?h=53c92cf6b7",          
          duration: "08:30",
          preview: false,
          topicId: 856,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698749-11-challenge-question-1-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Challenge Questions",
          title: "Challenge Question 2 – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638561452?h=a12409a6ba",          
          duration: "31:21",
          preview: false,
          topicId: 857,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698780-12-challenge-question-2-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Challenge Questions",
          title: "Challenge Question 2 – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638564526?h=737c96016c",          
          duration: "08:07",
          preview: false,
          topicId: 858,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698768-13-challenge-question-2-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Challenge Questions",
          title: "Challenge Question 3 – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638565456?h=a724c23e14",          
          duration: "15:11",
          preview: false,
          topicId: 859,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698790-14-challenge-question-3-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Challenge Questions",
          title: "Challenge Question 3 – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638567056?h=d1e13cfe7e",          
          duration: "07:20",
          preview: false,
          topicId: 860,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698781-15-challenge-question-3-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Challenge Questions",
          title: "Challenge Question 4 – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638567835?h=add319866c",          
          duration: "13:17",
          preview: false,
          topicId: 861,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698794-16-challenge-question-4-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Challenge Questions",
          title: "Challenge Question 4 – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638569295?h=5fe2ae8f12",          
          duration: "13:45",
          preview: false,
          topicId: 862,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698806-17-challenge-question-4-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Challenge Questions",
          title: "Challenge Question 5 – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638570738?h=17e2542409",          
          duration: "15:27",
          preview: false,
          topicId: 863,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698813-18-challenge-question-5-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Challenge Questions",
          title: "Challenge Question 5 – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638572308?h=a630226129",          
          duration: "06:01",
          preview: false,
          topicId: 864,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241549-challenge-questions/698808-19-challenge-question-5-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Course wrap up",
          title: "Course wrap up and debrief",          
          vimeoURL: "https://player.vimeo.com/video/638572956?h=de1a065426",          
          duration: "00:49",
          preview: false,
          topicId: 865,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/analysing-hybrid-indeterminate-civil-engineering-structures/241550-course-wrap-up/698814-20-course-wrap-up-and-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 8,
      title: "Mastering Virtual Work for Civil Engineering",
      tagline: "Analyse complex beam and frame civil engineering structures using Virtual Work",
      duration: 4.5,
      nlectures: 34,
      path: "course-8/lecture-1",
      infoURL:"https://www.degreetutors.com/mastering-virtual-work-for-engineers/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/638627969?h=ef098ae763",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction",
          title: "Course Introduction",          
          vimeoURL: "https://player.vimeo.com/video/638628706?h=6bfaf50381",          
          duration: "01:28",
          preview: true,
          topicId: 811,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241401-introduction/697882-1-introduction"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Introduction",
          title: "Syllabus walkthrough",          
          vimeoURL: "https://player.vimeo.com/video/638629323?h=210b744da0",          
          duration: "01:41",
          preview: true,
          topicId: 812,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241401-introduction/697879-2-syllabus-walkthrough"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Introduction",
          title: "Getting the most from your course",          
          vimeoURL: "https://player.vimeo.com/video/638629431?h=80185bf8fa",          
          duration: "02:06",
          preview: false,
          topicId: 813,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241401-introduction/697878-3-getting-the-most-from-your-course"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Quick Review of Bending Moments",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638629518?h=27b733b7f2",          
          duration: "00:30",
          preview: true,
          topicId: 814,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697892-4-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Quick Review of Bending Moments",
          title: "Relating bending moment to bending stress",          
          vimeoURL: "https://player.vimeo.com/video/638629726?h=a6c356226f",          
          duration: "21:18",
          preview: false,
          topicId: 815,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697899-5-relating-bending-moment-to-bending-stress"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Quick Review of Bending Moments",
          title: "Internal bending moment and equilibrium",          
          vimeoURL: "https://player.vimeo.com/video/638631026?h=c02ececbd6",          
          duration: "20:35",
          preview: false,
          topicId: 816,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697905-6-internal-bending-moment-and-equilibrium"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Quick Review of Bending Moments",
          title: "Using cuts to reveal the internal bending moment",          
          vimeoURL: "https://player.vimeo.com/video/638633205?h=fa0bf7e769",          
          duration: "21:04",
          preview: false,
          topicId: 817,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697900-7-using-cuts-to-reveal-the-internal-bending-moment"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Quick Review of Bending Moments",
          title: "Worked Example 1",          
          vimeoURL: "https://player.vimeo.com/video/638635416?h=0763b1b9e5",          
          duration: "09:39",
          preview: false,
          topicId: 818,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697907-8-worked-example-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Quick Review of Bending Moments",
          title: "Worked Example 2",          
          vimeoURL: "https://player.vimeo.com/video/638637508?h=0acb9d5910",          
          duration: "14:48",
          preview: false,
          topicId: 819,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697910-9-worked-example-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Quick Review of Bending Moments",
          title: "Worked Example 3",          
          vimeoURL: "https://player.vimeo.com/video/638640675?h=9972633102",          
          duration: "16:03",
          preview: false,
          topicId: 820,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241402-quick-review-of-bending-moments-optional/697911-10-worked-example-3"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Virtual Work",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638643443?h=c8417edee4",          
          duration: "01:17",
          preview: true,
          topicId: 821,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241403-virtual-work/697917-11-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Virtual Work",
          title: "The basic idea",          
          vimeoURL: "https://player.vimeo.com/video/638644392?h=c7074cfd48",          
          duration: "13:23",
          preview: true,
          topicId: 822,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241403-virtual-work/697925-12-the-basic-idea"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Virtual Work",
          title: "Equilibrium force system",          
          vimeoURL: "https://player.vimeo.com/video/638647405?h=2d220168fe",          
          duration: "03:53",
          preview: false,
          topicId: 823,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241403-virtual-work/697916-13-equilibrium-force-system"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Virtual Work",
          title: "Compatible displacement system",          
          vimeoURL: "https://player.vimeo.com/video/638648323?h=3621ea8070",          
          duration: "12:18",
          preview: false,
          topicId: 824,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241403-virtual-work/697926-14-compatible-displacement-system"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Virtual Work",
          title: "The Principle of Virtual Work",          
          vimeoURL: "https://player.vimeo.com/video/638651172?h=39f4e9a2a7",          
          duration: "05:38",
          preview: false,
          topicId: 825,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241403-virtual-work/697924-15-the-principle-of-virtual-work"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Virtual Work and Bending",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638653173?h=3329af8632",          
          duration: "00:40",
          preview: true,
          topicId: 826,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241404-virtual-work-and-bending/697931-16-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Virtual Work and Bending",
          title: "A strategy for bending",          
          vimeoURL: "https://player.vimeo.com/video/638653716?h=1ab5ad822e",          
          duration: "08:34",
          preview: true,
          topicId: 827,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241404-virtual-work-and-bending/697946-17-a-strategy-for-bending"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Virtual Work and Bending",
          title: "Virtual work equation for bending",          
          vimeoURL: "https://player.vimeo.com/video/638655691?h=bf18e0b050",          
          duration: "13:42",
          preview: false,
          topicId: 828,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241404-virtual-work-and-bending/697956-18-virtual-work-equation-for-bending"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Virtual Work and Bending",
          title: "Walkthrough – Part 1",          
          vimeoURL: "https://player.vimeo.com/video/638657912?h=737a8e2353",          
          duration: "07:23",
          preview: false,
          topicId: 829,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241404-virtual-work-and-bending/697952-19-walkthrough-part-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Virtual Work and Bending",
          title: "Walkthrough – Part 2",          
          vimeoURL: "https://player.vimeo.com/video/638659675?h=30ef342c78",          
          duration: "08:07",
          preview: false,
          topicId: 830,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241404-virtual-work-and-bending/697960-20-walkthrough-part-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Virtual Work and Bending",
          title: "Integral tables",          
          vimeoURL: "https://player.vimeo.com/video/638661415?h=2286f30ee2",          
          duration: "09:27",
          preview: false,
          topicId: 831,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241404-virtual-work-and-bending/697964-21-integral-tables"
            }
          ],
          files:[{
            linkText: "Integral tables",
            filename: "Lecture 21.zip"            
          }]
        },
        {
          lectureId: 22,
          section: "Practice Questions",
          title: "Section overview ",          
          vimeoURL: "https://player.vimeo.com/video/638662507?h=1ee9a24988",          
          duration: "01:00",
          preview: true,
          topicId: 832,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697983-22-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Practice Questions",
          title: "Practice Question 1 – Part A",          
          vimeoURL: "https://player.vimeo.com/video/638662767?h=89e7fca60b",          
          duration: "05:22",
          preview: false,
          topicId: 833,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697984-23-practice-question-1-part-a"
            }
          ],
          files:[{
            linkText: "Question/Solution 1",
            filename: "Lecture 23.zip"            
          }]
        },
        {
          lectureId: 24,
          section: "Practice Questions",
          title: "Practice Question 1 – Part B",          
          vimeoURL: "https://player.vimeo.com/video/638663207?h=a7635675cb",          
          duration: "06:57",
          preview: false,
          topicId: 834,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697993-24-practice-question-1-part-b"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Practice Questions",
          title: "Practice Question 2 – Part A",          
          vimeoURL: "https://player.vimeo.com/video/638663881?h=457733ce97",          
          duration: "03:09",
          preview: false,
          topicId: 835,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697986-25-practice-question-2-part-a"
            }
          ],
          files:[{
            linkText: "Question/Solution 2",
            filename: "Lecture 25.zip"            
          }]
        },
        {
          lectureId: 26,
          section: "Practice Questions",
          title: "Practice Question 2 – Part B",          
          vimeoURL: "https://player.vimeo.com/video/638664242?h=8717ddf5e1",          
          duration: "05:24",
          preview: false,
          topicId: 836,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697994-26-practice-question-2-part-b"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Practice Questions",
          title: "Practice Question 2 – Part C",          
          vimeoURL: "https://player.vimeo.com/video/638664734?h=07a4453082",          
          duration: "06:07",
          preview: false,
          topicId: 837,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697998-27-practice-question-2-part-c"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Practice Questions",
          title: "Practice Question 3 – Part A",          
          vimeoURL: "https://player.vimeo.com/video/638665274?h=0bc2836b02",          
          duration: "09:39",
          preview: false,
          topicId: 838,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/698004-28-practice-question-3-part-a"
            }
          ],
          files:[{
            linkText: "Question/Solution 3",
            filename: "Lecture 28.zip"            
          }]
        },
        {
          lectureId: 29,
          section: "Practice Questions",
          title: "Practice Question 3 – Part B",          
          vimeoURL: "https://player.vimeo.com/video/638666122?h=d9ab81c825",          
          duration: "04:14",
          preview: false,
          topicId: 839,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/697997-29-practice-question-3-part-b"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Practice Questions",
          title: "Practice Question 4 – Part A",          
          vimeoURL: "https://player.vimeo.com/video/638666517?h=9f824964f0",          
          duration: "07:56",
          preview: false,
          topicId: 840,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/698009-30-practice-question-4-part-a"
            }
          ],
          files:[{
            linkText: "Question/Solution 4",
            filename: "Lecture 30.zip"            
          }]
        },
        {
          lectureId: 31,
          section: "Practice Questions",
          title: "Practice Question 4 – Part B",          
          vimeoURL: "https://player.vimeo.com/video/638667154?h=d30bbacc94",          
          duration: "05:39",
          preview: false,
          topicId: 841,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/698006-31-practice-question-4-part-b"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Practice Questions",
          title: "Practice Question 5 – Part A",          
          vimeoURL: "https://player.vimeo.com/video/638667861?h=2f086ed0cc",          
          duration: "04:55",
          preview: false,
          topicId: 842,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/698008-32-practice-question-5-part-a"
            }
          ],
          files:[{
            linkText: "Question/Solution 5",
            filename: "Lecture 32.zip"            
          }]
        },
        {
          lectureId: 33,
          section: "Practice Questions",
          title: "Practice Question 5 – Part B",          
          vimeoURL: "https://player.vimeo.com/video/638669198?h=ba072c0564",          
          duration: "06:44",
          preview: false,
          topicId: 843,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241405-practice-questions/698015-33-practice-question-5-part-b"
            }
          ],
          files:[]
        },
        {
          lectureId: 34,
          section: "Course wrap up and debrief",
          title: "Closing comments and advice",          
          vimeoURL: "https://player.vimeo.com/video/638671178?h=bc1dad0427",          
          duration: "01:33",
          preview: false,
          topicId: 844,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-virtual-work-for-engineers/241406-course-wrap-up/698021-34-closing-comments-and-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 7,
      title: "Fundamentals of Virtual Work for Civil Engineers",
      tagline: "Level up your structural analysis skillset with these powerful Civil Engineering analysis techniques",
      duration: 3.5,
      nlectures: 30,
      path: "course-7/lecture-1",
      infoURL:"https://www.degreetutors.com/fundamentals-of-virtual-work-for-civil-engineers/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/638587222?h=1e8dc10b5c",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Welcome and Introduction",
          title: "Welcome to the course",          
          vimeoURL: "https://player.vimeo.com/video/638588355?h=ffcc4ba0fd",          
          duration: "03:26",
          preview: true,          
          topicId: 780,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240684-welcome-and-introduction/696738-1-welcome-to-the-course"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Welcome and Introduction",
          title: "Syllabus walkthrough",          
          vimeoURL: "https://player.vimeo.com/video/638589427?h=92821c4357",          
          duration: "01:47",
          preview: true,
          topicId: 781,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240684-welcome-and-introduction/696745-2-syllabus-walkthrough"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Welcome and Introduction",
          title: "Maximise course value",          
          vimeoURL: "https://player.vimeo.com/video/638589532?h=a2e378af68",          
          duration: "02:06",
          preview: false,
          topicId: 782,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240684-welcome-and-introduction/696743-3-maximise-course-value"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Quick Review of Trusses",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638589628?h=e71022e9c4",          
          duration: "01:01",
          preview: false,
          topicId: 783,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240685-quick-review-of-trusses-optional/696748-4-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Quick Review of Trusses",
          title: "Key features of a truss",          
          vimeoURL: "https://player.vimeo.com/video/638590005?h=c396bc0c4e",          
          duration: "04:42",
          preview: false,
          topicId: 784,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240685-quick-review-of-trusses-optional/696762-5-key-features-of-a-truss"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Quick Review of Trusses",
          title: "The joint resolution method",          
          vimeoURL: "https://player.vimeo.com/video/638591399?h=6241a9973d",          
          duration: "10:56",
          preview: false,
          topicId: 785,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240685-quick-review-of-trusses-optional/696772-6-the-joint-resolution-method"
            }
          ],
          files:[{
            linkText: "Exercise solution",
            filename: "Lecture 6.zip"            
          }]
        },
        {
          lectureId: 7,
          section: "Quick Review of Trusses",
          title: "The method of sections",          
          vimeoURL: "https://player.vimeo.com/video/638594124?h=a1100b3b18",          
          duration: "08:19",
          preview: false,
          topicId: 786,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240685-quick-review-of-trusses-optional/696765-7-the-method-of-sections"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Quick Review of Trusses",
          title: "Worked example",          
          vimeoURL: "https://player.vimeo.com/video/638596201?h=c89c1226d0",          
          duration: "09:47",
          preview: false,
          topicId: 787,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240685-quick-review-of-trusses-optional/696770-8-worked-example"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Strain Energy",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638597572?h=ad5d554b88",          
          duration: "01:13",
          preview: true,
          topicId: 788,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240686-strain-energy/696780-9-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Strain Energy",
          title: "What is strain energy?",          
          vimeoURL: "https://player.vimeo.com/video/638598047?h=731e411dea",          
          duration: "16:45",
          preview: true,
          topicId: 789,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240686-strain-energy/696812-10-what-is-strain-energy"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Strain Energy",
          title: "Energy stored in a structure",          
          vimeoURL: "https://player.vimeo.com/video/638599938?h=78093835a1",          
          duration: "07:52",
          preview: false,
          topicId: 790,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240686-strain-energy/696781-11-energy-stored-in-a-structure"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Strain Energy",
          title: "Energy storage and trusses",          
          vimeoURL: "https://player.vimeo.com/video/638600819?h=64c684e8b2",          
          duration: "10:52",
          preview: false,
          topicId: 791,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240686-strain-energy/696799-12-energy-storage-and-trusses"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Strain Energy",
          title: "Let’s try that again",          
          vimeoURL: "https://player.vimeo.com/video/638601905?h=3643a80a14",          
          duration: "11:00",
          preview: false,
          topicId: 792,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240686-strain-energy/696800-13-let-s-try-that-again"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Strain Energy",
          title: "What are the limitations of strain energy?",          
          vimeoURL: "https://player.vimeo.com/video/638603212?h=b5ace1c2c9",          
          duration: "06:47",
          preview: false,
          topicId: 793,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240686-strain-energy/696810-14-what-are-the-limitations-of-strain-energy"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Virtual Work",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638603980?h=519bb568f1",          
          duration: "01:12",
          preview: true,
          topicId: 794,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240687-virtual-work/696819-15-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Virtual Work",
          title: "The basic idea",          
          vimeoURL: "https://player.vimeo.com/video/638604521?h=baf0b8dbf7",          
          duration: "13:23",
          preview: true,
          topicId: 795,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240687-virtual-work/696829-16-the-basic-idea"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Virtual Work",
          title: "Equilibrium force system",          
          vimeoURL: "https://player.vimeo.com/video/638605936?h=cf40a3dc1d",          
          duration: "03:53",
          preview: false,
          topicId: 796,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240687-virtual-work/696818-17-equilibrium-force-system"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Virtual Work",
          title: "Compatible displacement system",          
          vimeoURL: "https://player.vimeo.com/video/638606253?h=f03606b69b",          
          duration: "12:18",
          preview: false,
          topicId: 797,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240687-virtual-work/696830-18-compatible-displacement-system"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Virtual Work",
          title: "The Principle of Virtual Work",          
          vimeoURL: "https://player.vimeo.com/video/638607599?h=fcf66698d4",          
          duration: "05:38",
          preview: false,
          topicId: 798,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240687-virtual-work/696827-19-the-principle-of-virtual-work"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Pin-Jointed Structures / Trusses",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638608534?h=2c7019202b",          
          duration: "01:01",
          preview: true,
          topicId: 799,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240688-pin-jointed-structures-trusses/696839-20-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Pin-Jointed Structures / Trusses",
          title: "A strategy for analysing trusses",          
          vimeoURL: "https://player.vimeo.com/video/638608997?h=4d434322be",          
          duration: "10:36",
          preview: false,
          topicId: 800,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240688-pin-jointed-structures-trusses/696844-21-a-strategy-for-analysing-trusses"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Pin-Jointed Structures / Trusses",
          title: "Finding unknown displacements",          
          vimeoURL: "https://player.vimeo.com/video/638610249?h=49e8948e95",          
          duration: "10:20",
          preview: false,
          topicId: 801,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240688-pin-jointed-structures-trusses/696842-22-finding-unknown-displacements"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Pin-Jointed Structures / Trusses",
          title: "Finding unknown forces",          
          vimeoURL: "https://player.vimeo.com/video/638611568?h=606255ef1f",          
          duration: "09:38",
          preview: false,
          topicId: 802,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240688-pin-jointed-structures-trusses/696846-23-finding-unknown-forces"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Practice Questions",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/638612810?h=cd6b54af5e",          
          duration: "00:58",
          preview: true,
          topicId: 803,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240689-practice-questions/696960-24-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Practice Questions",
          title: "Practice Question 1",          
          vimeoURL: "https://player.vimeo.com/video/638613152?h=1dab096b48",          
          duration: "06:07",
          preview: false,
          topicId: 804,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240689-practice-questions/696970-25-practice-question-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Practice Questions",
          title: "Practice Question 2",          
          vimeoURL: "https://player.vimeo.com/video/638613912?h=94ebb794cb",          
          duration: "04:12",
          preview: false,
          topicId: 805,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240689-practice-questions/696962-26-practice-question-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Practice Questions",
          title: "Practice Question 3",          
          vimeoURL: "https://player.vimeo.com/video/638614548?h=51b405b5ba",          
          duration: "06:56",
          preview: false,
          topicId: 806,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240689-practice-questions/696976-27-practice-question-3"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Practice Questions",
          title: "Practice Question 4",          
          vimeoURL: "https://player.vimeo.com/video/638615538?h=cec79142e0",          
          duration: "03:14",
          preview: false,
          topicId: 807,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240689-practice-questions/696975-28-practice-question-4"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Practice Questions",
          title: "Practice Question 5",          
          vimeoURL: "https://player.vimeo.com/video/638616036?h=53b8dbfe70",          
          duration: "10:47",
          preview: false,
          topicId: 808,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240689-practice-questions/696983-29-practice-question-5"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Course wrap up and debrief",
          title: "Closing comments and advice",          
          vimeoURL: "https://player.vimeo.com/video/638616956?h=9dca5e2ccd",          
          duration: "02:01",
          preview: false,
          topicId: 809,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-virtual-work-for-civil-engineers/240756-wrap-up/696877-30-closing-comments-and-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 6,
      title: "Indeterminate Structures & The Moment Distribution Method",
      tagline: "Unlock indeterminate structures using the moment distribution structural analysis method",
      duration: 8,
      nlectures: 36,
      path: "course-6/lecture-1",
      infoURL:"https://www.degreetutors.com/indeterminate-structures-and-moment-distribution/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/635134554?h=b854379e06",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction",
          title: "Welcome to the course",          
          vimeoURL: "https://player.vimeo.com/video/635136846?h=89bd5af36c",          
          duration: "04:42",
          preview: true,
          topicId: 723,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240311-introduction/695875-1-welcome-to-the-course"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Beam Analysis",
          title: "The moment distribution process",          
          vimeoURL: "https://player.vimeo.com/video/635142409?h=00b6120022",          
          duration: "25:48",
          preview: true,
          topicId: 724,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695877-2-the-moment-distribution-process"
            }
          ],
          files:[{
            linkText: "Fixed-end moment table",
            filename: "Lecture 2.zip"            
          }]
        },
        {
          lectureId: 3,
          section: "Beam Analysis",
          title: "Member stiffness and distribution factors",          
          vimeoURL: "https://player.vimeo.com/video/635151280?h=360ab22d8f",          
          duration: "30:47",
          preview: false,
          topicId: 725,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695880-3-member-stiffness-and-distribution-factors"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Beam Analysis",
          title: "Developing reactions, shear forces and span moments",          
          vimeoURL: "https://player.vimeo.com/video/635161056?h=0c8ee2e048",          
          duration: "15:50",
          preview: false,
          topicId: 726,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695879-4-developing-reactions-shear-forces-and-span-moments"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Beam Analysis",
          title: "Dealing with pinned ends",          
          vimeoURL: "https://player.vimeo.com/video/635166798?h=f07617bea8",          
          duration: "13:33",
          preview: false,
          topicId: 727,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695888-5-dealing-with-pinned-ends"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Beam Analysis",
          title: "Dealing with cantilever ends",          
          vimeoURL: "https://player.vimeo.com/video/635170795?h=a2091d1e4f",          
          duration: "19:05",
          preview: false,
          topicId: 728,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695890-6-dealing-with-cantilever-ends"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Beam Analysis",
          title: "Multi-iteration distributions",          
          vimeoURL: "https://player.vimeo.com/video/635176293?h=e5749ca3a4",          
          duration: "27:25",
          preview: false,
          topicId: 729,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695894-7-multi-iteration-distributions"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Beam Analysis",
          title: "Test Yourself – Worked Solution #1",          
          vimeoURL: "https://player.vimeo.com/video/635182107?h=d0357d3f76",          
          duration: "24:07",
          preview: false,
          topicId: 730,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695925-8-worked-solution-1"
            }
          ],
          files:[{
            linkText: "Question/Solution 1",
            filename: "Lecture 8.zip"            
          }]
        },
        {
          lectureId: 9,
          section: "Beam Analysis",
          title: "Test Yourself – Worked Solution #2",          
          vimeoURL: "https://player.vimeo.com/video/635188198?h=d8e436ca73",          
          duration: "23:56",
          preview: false,
          topicId: 731,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695927-9-worked-solution-2"
            }
          ],
          files:[{
            linkText: "Question/Solution 2",
            filename: "Lecture 9.zip"            
          }]
        },
        {
          lectureId: 10,
          section: "Beam Analysis",
          title: "Test Yourself – Worked Solution #3",          
          vimeoURL: "https://player.vimeo.com/video/635194514?h=7df4542237",          
          duration: "16:35",
          preview: false,
          topicId: 732,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240310-beam-analysis/695922-10-worked-solution-3"
            }
          ],
          files:[{
            linkText: "Question/Solution 3",
            filename: "Lecture 10.zip"            
          }]
        },
        {
          lectureId: 11,
          section: "Lateral Stability",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/635199259?h=98a7e0bc13",          
          duration: "01:01",
          preview: true,
          topicId: 733,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240312-lateral-stability/695930-11-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Lateral Stability",
          title: "Lateral stability systems",          
          vimeoURL: "https://player.vimeo.com/video/635199993?h=186547e8d1",          
          duration: "18:38",
          preview: false,
          topicId: 734,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240312-lateral-stability/695939-12-lateral-stability-systems"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Lateral Stability",
          title: "Sway versus non-sway frames",          
          vimeoURL: "https://player.vimeo.com/video/635204445?h=f9f31f70fe",          
          duration: "19:18",
          preview: false,
          topicId: 735,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240312-lateral-stability/695937-13-sway-versus-non-sway-frames"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Non-sway Frames",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/635208955?h=f4639320f0",          
          duration: "02:46",
          preview: true,
          topicId: 736,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695949-14-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Non-sway Frames",
          title: "Worked Solution #4: The distribution",          
          vimeoURL: "https://player.vimeo.com/video/635210437?h=1c5f8b34ef",          
          duration: "13:11",
          preview: false,
          topicId: 737,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695951-15-worked-solution-4-the-distribution"
            }
          ],
          files:[{
            linkText: "Question/Solution 4",
            filename: "Lecture 15.zip"            
          }]
        },
        {
          lectureId: 16,
          section: "Non-sway Frames",
          title: "Worked Solution #4: Free-body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635214980?h=ddb1d6d3bd",          
          duration: "29:44",
          preview: false,
          topicId: 738,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695976-16-worked-solution-4-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Non-sway Frames",
          title: "Worked Solution #4: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635221342?h=8d4c2b8b26",          
          duration: "12:42",
          preview: false,
          topicId: 739,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695961-17-worked-solution-4-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Non-sway Frames",
          title: "Worked Solution #5: The distribution",          
          vimeoURL: "https://player.vimeo.com/video/635223926?h=47bf30a697",          
          duration: "08:31",
          preview: false,
          topicId: 740,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695966-18-worked-solution-5-the-distribution"
            }
          ],
          files:[{
            linkText: "Question/Solution 5",
            filename: "Lecture 18.zip"            
          }]
        },
        {
          lectureId: 19,
          section: "Non-sway Frames",
          title: "Worked Solution #5: Free-body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635226273?h=a7fa676c28",          
          duration: "05:54",
          preview: false,
          topicId: 741,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695972-19-worked-solution-5-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 20,
          section: "Non-sway Frames",
          title: "Worked Solution #5: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635227457?h=b8a63b5c13",          
          duration: "07:53",
          preview: false,
          topicId: 742,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695982-20-worked-solution-5-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Non-sway Frames",
          title: "Worked Solution #6: The distribution",          
          vimeoURL: "https://player.vimeo.com/video/635229492?h=c9e53b31e1",          
          duration: "08:21",
          preview: false,
          topicId: 743,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/695984-21-worked-solution-6-the-distribution"
            }
          ],
          files:[{
            linkText: "Question/Solution 6",
            filename: "Lecture 21.zip"            
          }]
        },
        {
          lectureId: 22,
          section: "Non-sway Frames",
          title: "Worked Solution #6: Free-body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635231681?h=bb07fe87e9",          
          duration: "12:59",
          preview: false,
          topicId: 744,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/696002-22-worked-solution-6-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "Non-sway Frames",
          title: "Worked Solution #6: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635235029?h=c06840b9ef",          
          duration: "10:17",
          preview: false,
          topicId: 745,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240313-non-sway-frame-analysis/696004-23-worked-solution-6-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Sway Frame Analysis",
          title: "Section overview",          
          vimeoURL: "https://player.vimeo.com/video/635236901?h=6f58c2d28a",          
          duration: "01:22",
          preview: true,
          topicId: 746,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696014-24-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Sway Frame Analysis",
          title: "The two-stage analysis",          
          vimeoURL: "https://player.vimeo.com/video/635238099?h=26ad9e374c",          
          duration: "15:22",
          preview: false,
          topicId: 747,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696032-25-the-two-stage-analysis"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Sway Frame Analysis",
          title: "Step-by-step demonstration 1 – Stage 1",          
          vimeoURL: "https://player.vimeo.com/video/635242255?h=25185ceee4",          
          duration: "08:02",
          preview: false,
          topicId: 748,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696021-26-step-by-step-demonstration-1-stage-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 27,
          section: "Sway Frame Analysis",
          title: "Step-by-step demonstration 1 – Stage 2",          
          vimeoURL: "https://player.vimeo.com/video/635244434?h=b4f6f3c6be",          
          duration: "12:57",
          preview: false,
          topicId: 749,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696027-27-step-by-step-demonstration-1-stage-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 28,
          section: "Sway Frame Analysis",
          title: "Step-by-step demonstration 2 – Stage 1",          
          vimeoURL: "https://player.vimeo.com/video/635248325?h=54d52d0df7",          
          duration: "07:32",
          preview: false,
          topicId: 750,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696044-28-step-by-step-demonstration-2-stage-1"
            }
          ],
          files:[]
        },
        {
          lectureId: 29,
          section: "Sway Frame Analysis",
          title: "Step-by-step demonstration 2 – Stage 2",          
          vimeoURL: "https://player.vimeo.com/video/635250208?h=0fa0a6bb30",          
          duration: "18:29",
          preview: false,
          topicId: 751,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696098-29-step-by-step-demonstration-2-stage-2"
            }
          ],
          files:[]
        },
        {
          lectureId: 30,
          section: "Sway Frame Analysis",
          title: "Worked Solution #7: Stage 2 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635254276?h=2c97cfa24c",          
          duration: "09:02",
          preview: false,
          topicId: 752,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696043-30-worked-solution-7-stage-2-analysis"
            }
          ],
          files:[{
            linkText: "Question/Solution 7",
            filename: "Lecture 30.zip"            
          }]
        },
        {
          lectureId: 31,
          section: "Sway Frame Analysis",
          title: "Worked Solution #7: Post-distribution",          
          vimeoURL: "https://player.vimeo.com/video/635255369?h=15a53beb45",          
          duration: "11:02",
          preview: false,
          topicId: 753,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696065-31-worked-solution-7-post-distribution"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Sway Frame Analysis",
          title: "Worked Solution #8: Stage 2 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635257449?h=178691ce52",          
          duration: "08:09",
          preview: false,
          topicId: 754,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696068-32-worked-solution-8-stage-2-analysis"
            }
          ],
          files:[{
            linkText: "Question/Solution 8",
            filename: "Lecture 32.zip"            
          }]
        },
        {
          lectureId: 33,
          section: "Sway Frame Analysis",
          title: "Worked Solution #8: Post-distribution",          
          vimeoURL: "https://player.vimeo.com/video/635259566?h=5f02b7e601",          
          duration: "06:15",
          preview: false,
          topicId: 755,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696073-33-worked-solution-8-post-distribution"
            }
          ],
          files:[]
        },
        {
          lectureId: 34,
          section: "Sway Frame Analysis",
          title: "Worked Solution #9: Stage 2 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635261606?h=42aded0de2",          
          duration: "07:38",
          preview: false,
          topicId: 756,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696074-34-worked-solution-9-stage-2-analysis"
            }
          ],
          files:[{
            linkText: "Question/Solution 34",
            filename: "Lecture 9.zip"            
          }]
        },
        {
          lectureId: 35,
          section: "Sway Frame Analysis",
          title: "Worked Solution #9: Post-distribution",          
          vimeoURL: "https://player.vimeo.com/video/635263925?h=870bd755f9",          
          duration: "08:20",
          preview: false,
          topicId: 757,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696082-35-worked-solution-9-post-distribution"
            }
          ],
          files:[]
        },
        {
          lectureId: 36,
          section: "Sway Frame Analysis",
          title: "Course wrap-up",          
          vimeoURL: "https://player.vimeo.com/video/635266248?h=72d2c99b01",          
          duration: "01:03",
          preview: false,
          topicId: 758,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/indeterminate-structures-the-moment-distribution-method/240314-sway-frame-analysis/696077-36-course-wrap-up-and-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 5,
      title: "Moment Distribution Method: Analysis Bootcamp",
      tagline: "Step up your structural analysis skills by working your way through these indeterminate structures",
      duration: 3,
      nlectures: 19,
      path: "course-5/lecture-1",
      infoURL:"https://www.degreetutors.com/moment-distribution-method-analysis-bootcamp/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/635287610?h=8973eb8701",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction",
          title: "Quick Introduction and some advice",          
          vimeoURL: "https://player.vimeo.com/video/635288556?h=ff497c4fe0",          
          duration: "02:48",
          preview: true,
          topicId: 760,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240487-introduction/696289-1-quick-introduction-and-some-advice"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Non-Sway Frames",
          title: "Question 1: The distribution",          
          vimeoURL: "https://player.vimeo.com/video/635291113?h=838180c585",          
          duration: "11:30",
          preview: true,
          topicId: 761,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696303-2-question-1-the-distribution"
            }
          ],
          files:[{
            linkText: "Question/Solution 1",
            filename: "Lecture 2.zip"            
          }]
        },
        {
          lectureId: 3,
          section: "Non-Sway Frames",
          title: "Question 1: Free body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635293968?h=075744627c",          
          duration: "12:44",
          preview: true,
          topicId: 762,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696311-3-question-1-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Non-Sway Frames",
          title: "Question 1: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635296245?h=f883183a06",          
          duration: "08:44",
          preview: false,
          topicId: 763,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696297-4-question-1-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Non-Sway Frames",
          title: "Question 2: The distribution",          
          vimeoURL: "https://player.vimeo.com/video/635298350?h=c49063d2b4",          
          duration: "07:44",
          preview: false,
          topicId: 764,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696314-5-question-2-the-distribution"
            }
          ],
          files:[{
            linkText: "Question/Solution 2",
            filename: "Lecture 5.zip"            
          }]
        },
        {
          lectureId: 6,
          section: "Non-Sway Frames",
          title: "Question 2: Free body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635300967?h=146cee18c0",          
          duration: "04:18",
          preview: false,
          topicId: 765,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696309-6-question-2-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Non-Sway Frames",
          title: "Question 2: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635302571?h=9714e8a254",          
          duration: "13:01",
          preview: false,
          topicId: 766,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696324-7-question-2-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Non-Sway Frames",
          title: "Question 3: The distribution",          
          vimeoURL: "https://player.vimeo.com/video/635306259?h=7e5e635efa",          
          duration: "13:03",
          preview: false,
          topicId: 767,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696337-8-question-3-the-distribution"
            }
          ],
          files:[{
            linkText: "Question/Solution 3",
            filename: "Lecture 8.zip"            
          }]
        },
        {
          lectureId: 9,
          section: "Non-Sway Frames",
          title: "Question 3: Free body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635310416?h=666661ff2c",          
          duration: "15:49",
          preview: false,
          topicId: 768,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696344-9-question-3-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Non-Sway Frames",
          title: "Question 3: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635315795?h=88522dabcc",          
          duration: "15:59",
          preview: false,
          topicId: 769,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240488-non-sway-frames/696350-10-question-3-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Sway Frames",
          title: "Question 4: Stage 1 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635320851?h=a603ddfaad",          
          duration: "12:03",
          preview: false,
          topicId: 770,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696393-11-question-4-stage-1-analysis"
            }
          ],
          files:[{
            linkText: "Question/Solution 4",
            filename: "Lecture 11.zip"            
          }]
        },
        {
          lectureId: 12,
          section: "Sway Frames",
          title: "Question 4: Stage 2 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635324565?h=a3598cee7e",          
          duration: "07:52",
          preview: false,
          topicId: 771,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696380-12-question-4-stage-2-analysis"
            }
          ],
          files:[]
        },
        {
          lectureId: 13,
          section: "Sway Frames",
          title: "Question 4: Free body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635327144?h=08b0654fbb",          
          duration: "06:24",
          preview: false,
          topicId: 772,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696379-13-question-4-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 14,
          section: "Sway Frames",
          title: "Question 4: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635329329?h=e7cb8a388a",          
          duration: "06:45",
          preview: false,
          topicId: 773,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696394-14-question-4-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 15,
          section: "Sway Frames",
          title: "Question 5: Stage 1 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635331323?h=755d4ef0f1",          
          duration: "11:58",
          preview: false,
          topicId: 774,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696417-15-question-5-stage-1-analysis"
            }
          ],
          files:[{
            linkText: "Question/Solution 5",
            filename: "Lecture 15.zip"            
          }]
        },
        {
          lectureId: 16,
          section: "Sway Frames",
          title: "Question 5: Stage 2 analysis",          
          vimeoURL: "https://player.vimeo.com/video/635334721?h=dc7ea5345f",          
          duration: "08:53",
          preview: false,
          topicId: 775,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696414-16-question-5-stage-2-analysis"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Sway Frames",
          title: "Question 5: Free body diagrams",          
          vimeoURL: "https://player.vimeo.com/video/635337607?h=109bb39466",          
          duration: "04:24",
          preview: false,
          topicId: 776,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696412-17-question-5-free-body-diagrams"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Sway Frames",
          title: "Question 5: Drawing the solution",          
          vimeoURL: "https://player.vimeo.com/video/635338816?h=dea9dd1061",          
          duration: "09:23",
          preview: false,
          topicId: 777,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240489-sway-frames/696418-18-question-5-drawing-the-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Wrap Up",
          title: "Course wrap up and debrief",          
          vimeoURL: "https://player.vimeo.com/video/635343804?h=5362050173",          
          duration: "01:22",
          preview: false,
          topicId: 778,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/moment-distribution-method-analysis-bootcamp/240491-wrap-up/696423-19-course-debrief-and-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 4,
      title: "Shear Forces & Bending Moments: Analysis Bootcamp",
      tagline: "Sharpen your civil engineering analysis skills with  questions designed to test your understanding",
      duration: 3,
      nlectures: 12,
      path: "course-4/lecture-1",
      infoURL:"https://www.degreetutors.com/shear-forces-and-bending-moments-analysis-bootcamp/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/629231051?h=135d1ed45d",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction",
          title: "A quick word before we get started",          
          vimeoURL: "https://player.vimeo.com/video/629231787?h=6dff40b9c5",          
          duration: "",
          preview: true,
          topicId: 710,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240432-introduction/696173-1-welcome-and-course-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Challenge Questions",
          title: "Challenge Question 1",          
          vimeoURL: "https://player.vimeo.com/video/629232838?h=55ad9a40c0",          
          duration: "",
          preview: true,
          topicId: 711,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696189-2-worked-solution-1"
            }
          ],
          files:[{
            linkText: "Question/Solution 1",
            filename: "Lecture 2.zip"            
          }]
        },
        {
          lectureId: 3,
          section: "Challenge Questions",
          title: "Challenge Question 2",          
          vimeoURL: "https://player.vimeo.com/video/629236445?h=2be54d2881",          
          duration: "",
          preview: false,
          topicId: 712,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696184-3-worked-solution-2"
            }
          ],
          files:[{
            linkText: "Question/Solution 2",
            filename: "Lecture 3.zip"            
          }]
        },
        {
          lectureId: 4,
          section: "Challenge Questions",
          title: "Challenge Question 3",          
          vimeoURL: "https://player.vimeo.com/video/629239096?h=5c0cc7ea9b",          
          duration: "",
          preview: false,
          topicId: 713,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696182-4-worked-solution-3"
            }
          ],
          files:[{
            linkText: "Question/Solution 3",
            filename: "Lecture 4.zip"            
          }]
        },
        {
          lectureId: 5,
          section: "Challenge Questions",
          title: "Challenge Question 4",          
          vimeoURL: "https://player.vimeo.com/video/629241437?h=27e39f7b94",          
          duration: "",
          preview: false,
          topicId: 714,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696205-5-worked-solution-4"
            }
          ],
          files:[{
            linkText: "Question/Solution 4",
            filename: "Lecture 5.zip"            
          }]
        },
        {
          lectureId: 6,
          section: "Challenge Questions",
          title: "Challenge Question 5",          
          vimeoURL: "https://player.vimeo.com/video/629246323?h=9489b1ee42",          
          duration: "",
          preview: false,
          topicId: 715,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696193-6-worked-solution-5"
            }
          ],
          files:[{
            linkText: "Question/Solution 5",
            filename: "Lecture 6.zip"            
          }]
        },
        {
          lectureId: 7,
          section: "Challenge Questions",
          title: "Challenge Question 6",          
          vimeoURL: "https://player.vimeo.com/video/629247753?h=1a3cacd93a",          
          duration: "",
          preview: false,
          topicId: 716,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696200-7-worked-solution-6"
            }
          ],
          files:[{
            linkText: "Question/Solution 6",
            filename: "Lecture 7.zip"            
          }]
        },
        {
          lectureId: 8,
          section: "Challenge Questions",
          title: "Challenge Question 7",          
          vimeoURL: "https://player.vimeo.com/video/629249392?h=f616250745",          
          duration: "",
          preview: false,
          topicId: 717,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696206-8-worked-solution-7"
            }
          ],
          files:[{
            linkText: "Question/Solution 7",
            filename: "Lecture 8.zip"            
          }]
        },
        {
          lectureId: 9,
          section: "Challenge Questions",
          title: "Challenge Question 8",          
          vimeoURL: "https://player.vimeo.com/video/629252059?h=4393bddce0",          
          duration: "",
          preview: false,
          topicId: 718,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696208-9-worked-solution-8"
            }
          ],
          files:[{
            linkText: "Question/Solution 8",
            filename: "Lecture 9.zip"            
          }]
        },
        {
          lectureId: 10,
          section: "Challenge Questions",
          title: "Challenge Question 9",          
          vimeoURL: "https://player.vimeo.com/video/635083407?h=0a95d5ec3c",          
          duration: "",
          preview: false,
          topicId: 719,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696214-10-worked-solution-9"
            }
          ],
          files:[{
            linkText: "Question/Solution 9",
            filename: "Lecture 10.zip"            
          }]
        },
        {
          lectureId: 11,
          section: "Challenge Questions",
          title: "Challenge Question 10",          
          vimeoURL: "https://player.vimeo.com/video/629273522?h=c85a63f370",          
          duration: "",
          preview: false,
          topicId: 720,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240433-challenge-questions/696213-11-worked-solution-10"
            }
          ],
          files:[{
            linkText: "Question/Solution 10",
            filename: "Lecture 11.zip"            
          }]
        },
        {
          lectureId: 12,
          section: "Course wrap up",
          title: "Wrap up and debrief",          
          vimeoURL: "https://player.vimeo.com/video/635092006?h=8ee0de862a",          
          duration: "01:38",
          preview: false,
          topicId: 721,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/shear-forces-and-bending-moments-bootcamp/240435-wrap-up/696218-12-course-debrief-and-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 3,
      title: "Mastering Shear Force and Bending Moment Diagrams",
      tagline: "Your complete roadmap to mastering these essential structural analysis skills",
      duration: 8.5,
      nlectures: 35,
      path: "course-3/lecture-1",
      infoURL:"https://www.degreetutors.com/mastering-shear-force-and-bending-moment-diagrams/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/628991331?h=3a0a6a31ec",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Quick Review of the Basics",
          title: "Welcome and Section Overview",          
          vimeoURL: "https://player.vimeo.com/video/628992612?h=4cfb47d220",          
          duration: "07:56",
          preview: true,
          topicId: 674,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239284-quick-review-of-the-basics/693804-1-welcome-and-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Quick Review of the Basics",
          title: "Some Basic Assumptions",          
          vimeoURL: "https://player.vimeo.com/video/628995682?h=183db9a484",          
          duration: "25:36",
          preview: true,
          topicId: 675,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239284-quick-review-of-the-basics/693816-2-some-basic-assumptions"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Quick Review of the Basics",
          title: "Loads, Reactions and Load Paths",          
          vimeoURL: "https://player.vimeo.com/video/628998947?h=d45f336f3b",          
          duration: "04:37",
          preview: true,
          topicId: 676,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239284-quick-review-of-the-basics/693800-3-loads-reactions-and-load-paths"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Quick Review of the Basics",
          title: "Test Yourself – Worked Example #1",          
          vimeoURL: "https://player.vimeo.com/video/629000592?h=7e6d932084",          
          duration: "08:01",
          preview: false,
          topicId: 677,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239284-quick-review-of-the-basics/693806-4-worked-solution-1"
            }
          ],
          files:[{
            linkText: "Question/Solution 1",
            filename: "Lecture 4.zip"            
          }]
        },
        {
          lectureId: 5,
          section: "Quick Review of the Basics",
          title: "Test Yourself – Worked Example #2",          
          vimeoURL: "https://player.vimeo.com/video/629001323?h=eefc5a8e9a",          
          duration: "06:43",
          preview: true,
          topicId: 678,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239284-quick-review-of-the-basics/693817-5-worked-solution-2"
            }
          ],
          files:[{
            linkText: "Question/Solution 2",
            filename: "Lecture 5.zip"            
          }]
        },
        {
          lectureId: 6,
          section: "Quick Review of the Basics",
          title: "Test Yourself – Worked Example #3",          
          vimeoURL: "https://player.vimeo.com/video/629002097?h=7619acf9b0",          
          duration: "08:34",
          preview: false,
          topicId: 679,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239284-quick-review-of-the-basics/693818-6-worked-solution-3"
            }
          ],
          files:[{
            linkText: "Question/Solution 3",
            filename: "Lecture 6.zip"            
          }]
        },
        {
          lectureId: 7,
          section: "Internal Bending Moments - The Basics",
          title: "Section overview ",          
          vimeoURL: "https://player.vimeo.com/video/629002985?h=9de9334f88",          
          duration: "02:09",
          preview: true,
          topicId: 680,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693861-7-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Internal Bending Moments - The Basics",
          title: "Relating bending moment to bending stress",          
          vimeoURL: "https://player.vimeo.com/video/629003757?h=cb3edebd75",          
          duration: "19:06",
          preview: false,
          topicId: 681,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693876-8-relating-bending-moment-to-bending-stress"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Internal Bending Moments - The Basics",
          title: "Internal bending moment and equilibrium",          
          vimeoURL: "https://player.vimeo.com/video/629004822?h=9f6f575f52",          
          duration: "20:35",
          preview: false,
          topicId: 682,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693875-9-internal-bending-moment-and-equilibrium"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Internal Bending Moments - The Basics",
          title: "Using cuts to reveal the internal bending moment",          
          vimeoURL: "https://player.vimeo.com/video/629006630?h=7823020fb4",          
          duration: "21:04",
          preview: false,
          topicId: 683,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693883-10-using-cuts-to-reveal-the-internal-bending-moment"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Internal Bending Moments - The Basics",
          title: "Bending moment sign convention",          
          vimeoURL: "https://player.vimeo.com/video/629009235?h=d034ba1049",          
          duration: "15:34",
          preview: false,
          topicId: 684,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693897-11-bending-moment-sign-convention"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Internal Bending Moments - The Basics",
          title: "Test Yourself – Worked Example #4",          
          vimeoURL: "https://player.vimeo.com/video/629010788?h=75aa0666d0",          
          duration: "09:39",
          preview: true,
          topicId: 685,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693892-12-worked-solution-4"
            }
          ],
          files:[{
            linkText: "Question/Solution 4",
            filename: "Lecture 12.zip"            
          }]
        },
        {
          lectureId: 13,
          section: "Internal Bending Moments - The Basics",
          title: "Test Yourself – Worked Example #5",          
          vimeoURL: "https://player.vimeo.com/video/629011576?h=e023580979",          
          duration: "14:48",
          preview: false,
          topicId: 686,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693908-13-worked-solution-5"
            }
          ],
          files:[{
            linkText: "Question/Solution 5",
            filename: "Lecture 13.zip"            
          }]
        },
        {
          lectureId: 14,
          section: "Internal Bending Moments - The Basics",
          title: "Test Yourself – Worked Example #6",          
          vimeoURL: "https://player.vimeo.com/video/629013248?h=55b6ff19d9",          
          duration: "16:03",
          preview: false,
          topicId: 687,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239286-internal-bending-moments-the-basics/693924-14-worked-solution-6"
            }
          ],
          files:[{
            linkText: "Question/Solution 6",
            filename: "Lecture 14.zip"            
          }]
        },
        {
          lectureId: 15,
          section: "Internal Shear Forces - The Basics",
          title: "Section overview ",          
          vimeoURL: "https://player.vimeo.com/video/629015627?h=5c29ceda3e",          
          duration: "01:30",
          preview: true,
          topicId: 688,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239288-internal-shear-forces-the-basics/693971-15-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 16,
          section: "Internal Shear Forces - The Basics",
          title: "Relating shear force to shear stress",          
          vimeoURL: "https://player.vimeo.com/video/629015985?h=c75d4f803d",          
          duration: "14:44",
          preview: false,
          topicId: 689,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239288-internal-shear-forces-the-basics/693986-16-relating-shear-force-to-shear-stress"
            }
          ],
          files:[]
        },
        {
          lectureId: 17,
          section: "Internal Shear Forces - The Basics",
          title: "Using cuts to reveal the internal shear force",          
          vimeoURL: "https://player.vimeo.com/video/629017600?h=e589fd1d4f",          
          duration: "14:20",
          preview: false,
          topicId: 690,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239288-internal-shear-forces-the-basics/693984-17-using-cuts-to-reveal-the-internal-shear-force"
            }
          ],
          files:[]
        },
        {
          lectureId: 18,
          section: "Internal Shear Forces - The Basics",
          title: "Shear force sign convention",          
          vimeoURL: "https://player.vimeo.com/video/629019218?h=75b3630029",          
          duration: "07:17",
          preview: false,
          topicId: 691,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239288-internal-shear-forces-the-basics/693980-18-shear-force-sign-convention"
            }
          ],
          files:[]
        },
        {
          lectureId: 19,
          section: "Internal Shear Forces - The Basics",
          title: "Worked Example #7",          
          vimeoURL: "https://player.vimeo.com/video/629019994?h=084732fac4",          
          duration: "18:42",
          preview: false,
          topicId: 692,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239288-internal-shear-forces-the-basics/694006-19-worked-solution-7"
            }
          ],
          files:[{
            linkText: "Question/Solution 7",
            filename: "Lecture 19.zip"            
          }]
        },
        {
          lectureId: 20,
          section: "Internal Shear Forces - The Basics",
          title: "Worked Example #8",          
          vimeoURL: "https://player.vimeo.com/video/629022867?h=26758738a2",          
          duration: "21:51",
          preview: false,
          topicId: 693,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239288-internal-shear-forces-the-basics/694007-20-worked-solution-8"
            }
          ],
          files:[{
            linkText: "Question/Solution 8",
            filename: "Lecture 20.zip"            
          }]
        },
        {
          lectureId: 21,
          section: "The Relationship Between Loading, Shear Force and Bending Moment",
          title: "Section overview ",          
          vimeoURL: "https://player.vimeo.com/video/629025215?h=6a9010cc35",          
          duration: "02:30",
          preview: true,
          topicId: 694,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239289-the-relationship-between-loading-shear-force-and-bending-moment/694017-21-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "The Relationship Between Loading, Shear Force and Bending Moment",
          title: "Case I: distributed loading",          
          vimeoURL: "https://player.vimeo.com/video/629026357?h=b785b82343",          
          duration: "19:31",
          preview: false,
          topicId: 695,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239289-the-relationship-between-loading-shear-force-and-bending-moment/694019-22-case-i-distributed-loading"
            }
          ],
          files:[]
        },
        {
          lectureId: 23,
          section: "The Relationship Between Loading, Shear Force and Bending Moment",
          title: "Case II: point loading",          
          vimeoURL: "https://player.vimeo.com/video/629028208?h=7c13b272bf",          
          duration: "14:05",
          preview: false,
          topicId: 696,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239289-the-relationship-between-loading-shear-force-and-bending-moment/694014-23-case-ii-point-loading"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "The Relationship Between Loading, Shear Force and Bending Moment",
          title: "Case III: couple or applied moment",          
          vimeoURL: "https://player.vimeo.com/video/629029818?h=26ae2b5440",          
          duration: "10:46",
          preview: false,
          topicId: 697,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239289-the-relationship-between-loading-shear-force-and-bending-moment/694021-24-case-iii-couple-or-applied-moment"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Bringing It All Together",
          title: "Section overview ",          
          vimeoURL: "https://player.vimeo.com/video/629030773?h=2fdb2fc1dd",          
          duration: "02:00",
          preview: true,
          topicId: 698,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239291-bringing-it-all-together/694030-25-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 26,
          section: "Bringing It All Together",
          title: "Test Yourself – Worked Example #9",          
          vimeoURL: "https://player.vimeo.com/video/629031748?h=9a74a829a7",          
          duration: "30:40",
          preview: false,
          topicId: 699,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239291-bringing-it-all-together/694114-26-worked-solution-9"
            }
          ],
          files:[{
            linkText: "Question/Solution 9",
            filename: "Lecture 26.zip"            
          }]
        },
        {
          lectureId: 27,
          section: "Bringing It All Together",
          title: "Test Yourself – Worked Example #10",          
          vimeoURL: "https://player.vimeo.com/video/629035288?h=ffabaa9068",          
          duration: "35:05",
          preview: false,
          topicId: 700,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239291-bringing-it-all-together/694103-27-worked-solution-10"
            }
          ],
          files:[{
            linkText: "Question/Solution 10",
            filename: "Lecture 27.zip"            
          }]
        },
        {
          lectureId: 28,
          section: "Bringing It All Together",
          title: "Test Yourself – Worked Example #11",          
          vimeoURL: "https://player.vimeo.com/video/629040653?h=2ca1f5ce63",          
          duration: "15:55",
          preview: false,
          topicId: 701,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239291-bringing-it-all-together/694046-28-worked-solution-11"
            }
          ],
          files:[{
            linkText: "Question/Solution 11",
            filename: "Lecture 28.zip"            
          }]
        },
        {
          lectureId: 29,
          section: "Bringing It All Together",
          title: "Test Yourself – Worked Example #12",          
          vimeoURL: "https://player.vimeo.com/video/629041975?h=dbbcab777d",          
          duration: "28:24",
          preview: false,
          topicId: 702,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239291-bringing-it-all-together/694109-29-worked-solution-12"
            }
          ],
          files:[{
            linkText: "Question/Solution 12",
            filename: "Lecture 29.zip"            
          }]
        },
        {
          lectureId: 30,
          section: "Bringing It All Together",
          title: "Test Yourself – Worked Example #13",          
          vimeoURL: "https://player.vimeo.com/video/629046223?h=88b1194d2d",          
          duration: "16:44",
          preview: false,
          topicId: 703,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239291-bringing-it-all-together/694110-30-worked-solution-13"
            }
          ],
          files:[{
            linkText: "Question/Solution 13",
            filename: "Lecture 30.zip"            
          }]
        },
        {
          lectureId: 31,
          section: "Beyond Beams - Statically Determinate Frames",
          title: "Section overview ",          
          vimeoURL: "https://player.vimeo.com/video/629048372?h=473e03c190",          
          duration: "01:15",
          preview: true,
          topicId: 704,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239292-beyond-beams-statically-determinate-frames/694497-31-section-overview"
            }
          ],
          files:[]
        },
        {
          lectureId: 32,
          section: "Beyond Beams - Statically Determinate Frames",
          title: "Test Yourself – Worked Example #14",          
          vimeoURL: "https://player.vimeo.com/video/629049315?h=03c5e55a1b",          
          duration: "29:35",
          preview: false,
          topicId: 705,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239292-beyond-beams-statically-determinate-frames/694514-32-worked-solution-14"
            }
          ],
          files:[{
            linkText: "Question/Solution 14",
            filename: "Lecture 32.zip"            
          }]
        },
        {
          lectureId: 33,
          section: "Beyond Beams - Statically Determinate Frames",
          title: "Test Yourself – Worked Example #15",          
          vimeoURL: "https://player.vimeo.com/video/629053451?h=c393e0a702",          
          duration: "13:43",
          preview: false,
          topicId: 706,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239292-beyond-beams-statically-determinate-frames/694505-33-worked-solution-15"
            }
          ],
          files:[{
            linkText: "Question/Solution 15",
            filename: "Lecture 33.zip"            
          }]
        },
        {
          lectureId: 34,
          section: "Beyond Beams - Statically Determinate Frames",
          title: "Test Yourself – Worked Example #16",          
          vimeoURL: "https://player.vimeo.com/video/629054051?h=d218989f42",          
          duration: "19:14",
          preview: false,
          topicId: 707,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239292-beyond-beams-statically-determinate-frames/694512-34-worked-solution-16"
            }
          ],
          files:[{
            linkText: "Question/Solution 16",
            filename: "Lecture 34.zip"            
          }]
        },
        {
          lectureId: 35,
          section: "Beyond Beams - Statically Determinate Frames",
          title: "Well done! Course wrap-up",          
          vimeoURL: "https://player.vimeo.com/video/629057018?h=5539e868ec",          
          duration: "02:22",
          preview: false,
          topicId: 708,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/mastering-shear-force-and-bending-moment-diagrams/239292-beyond-beams-statically-determinate-frames/694510-35-well-done-course-wrap-up-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    },
    {
      courseId: 2,
      title: "Fundamentals of 2D Stress Analysis and Mohr’s Circle",
      tagline: "Perform 2D stress analysis and use Mohr’s Circle to visualise the complete stress field",
      duration: 3.5,
      nlectures: 9,
      path: "course-2/lecture-1",
      infoURL:"",
      video: false,
      text: false,
      visible: false,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "",
      lectures: []
    },
    {
      courseId: 1,
      title: "Fundamentals of Structural Analysis",
      tagline: "Get to grips with civil engineering structural analysis once and for all",
      duration: 3,
      nlectures: 30,
      path: "course-1/lecture-1",
      infoURL:"https://www.degreetutors.com/fundamentals-of-structural-analysis/",
      video: true,
      text: false,
      visible: true,
      access: 'private',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "/login",
      vimeoURL: "https://player.vimeo.com/video/622543082?h=e62d607fb1",
      discourse: true,
      lectures: [
        {
          lectureId: 1,
          section: "Introduction and course overview",
          title: "Quick introduction to me and the course",          
          vimeoURL: "https://player.vimeo.com/video/622543082?h=e62d607fb1",          
          duration: "01:34",
          preview: true,
          topicId: 643,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226164-quick-introduction/658574-1-quick-introduction-to-me-and-the-course"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "2D Force Systems",
          title: "2D force systems",          
          vimeoURL: "https://player.vimeo.com/video/622451958?h=8a59f106ee",          
          duration: "07:37",
          preview: false,
          topicId: 644,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658627-2-2d-force-systems"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "2D Force Systems",
          title: "Force resultants and components",          
          vimeoURL: "https://player.vimeo.com/video/622457173?h=bf9b0720e5",          
          duration: "07:55",
          preview: false,
          topicId: 645,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658655-3-force-resultants-and-components"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "2D Force Systems",
          title: "The moment of a force",          
          vimeoURL: "https://player.vimeo.com/video/622461289?h=8875ee63b9",          
          duration: "09:47",
          preview: false,
          topicId: 646,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658676-4-the-moment-of-a-force"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "2D Force Systems",
          title: "Couples and equivalent systems",          
          vimeoURL: "https://player.vimeo.com/video/622470138?h=5b57a07b49",          
          duration: "09:44",
          preview: false,
          topicId: 647,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658711-5-couples-and-equivalent-systems"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "2D Force Systems",
          title: "Test Yourself – Worked Example #1",          
          vimeoURL: "https://player.vimeo.com/video/628965573?h=364b339ba6",          
          duration: "06:14",
          preview: false,
          topicId: 648,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658742-6-worked-example-1-video-solution"
            }
          ],
          files:[{
            linkText: "Example 6 Answer",
            filename: "Lecture 6.zip"            
          }]
        },
        {
          lectureId: 7,
          section: "2D Force Systems",
          title: "Test Yourself – Worked Example #2",          
          vimeoURL: "https://player.vimeo.com/video/628966444?h=d65b6c3cd5",          
          duration: "10:54",
          preview: false,
          topicId: 649,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658743-7-worked-example-2-video-solution"
            }
          ],
          files:[{
            linkText: "Example 7 Answer",
            filename: "Lecture 7.zip"            
          }]
        },
        {
          lectureId: 8,
          section: "2D Force Systems",
          title: "Test Yourself – Worked Example #3",          
          vimeoURL: "https://player.vimeo.com/video/628968373?h=10b18fa7f6",          
          duration: "07:27",
          preview: false,
          topicId: 650,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226165-2d-force-systems/658739-8-worked-example-3-video-solution"
            }
          ],
          files:[{
            linkText: "Example 8 Answer",
            filename: "Lecture 8.zip"            
          }]
        },
        {
          lectureId: 9,
          section: "Static Equilibrium and Reaction Forces",
          title: "Equilibrium and reaction forces",          
          vimeoURL: "https://player.vimeo.com/video/622478307?h=58ed7f3cbe",          
          duration: "11:17",
          preview: false,
          topicId: 651,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658788-9-equilibrium-and-reaction-forces"
            }
          ],
          files:[]
        },
        {
          lectureId: 10,
          section: "Static Equilibrium and Reaction Forces",
          title: "Calculating reaction forces",          
          vimeoURL: "https://player.vimeo.com/video/622487204?h=0d1a84d4bc",          
          duration: "08:54",
          preview: false,
          topicId: 652,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658784-10-calculating-reaction-forces"
            }
          ],
          files:[]
        },
        {
          lectureId: 11,
          section: "Static Equilibrium and Reaction Forces",
          title: "Limitations of the equations of statics",          
          vimeoURL: "https://player.vimeo.com/video/622491126?h=38bb1c8ee5",          
          duration: "02:32",
          preview: false,
          topicId: 653,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658766-11-limitations-of-the-equations-of-statics"
            }
          ],
          files:[]
        },
        {
          lectureId: 12,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #4",          
          vimeoURL: "https://player.vimeo.com/video/628969218?h=b99adda0d7",          
          duration: "03:05",
          preview: false,
          topicId: 654,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658810-12-worked-example-4-video-solution"
            }
          ],
          files:[{
            linkText: "Example 12 Answer",
            filename: "Lecture 12.zip"            
          }]
        },
        {
          lectureId: 13,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #5",          
          vimeoURL: "https://player.vimeo.com/video/628969928?h=4b35795bb6",          
          duration: "03:39",
          preview: false,
          topicId: 655,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658811-13-worked-example-5-video-solution"
            }
          ],
          files:[{
            linkText: "Example 13 Answer",
            filename: "Lecture 13.zip"            
          }]
        },
        {
          lectureId: 14,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #6",          
          vimeoURL: "https://player.vimeo.com/video/628970344?h=6b92663637",          
          duration: "03:56",
          preview: false,
          topicId: 656,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658825-14-worked-example-6-video-solution"
            }
          ],
          files:[{
            linkText: "Example 14 Answer",
            filename: "Lecture 14.zip"            
          }]
        },
        {
          lectureId: 15,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #7",          
          vimeoURL: "https://player.vimeo.com/video/628971038?h=d2682702d9",          
          duration: "03:56",
          preview: false,
          topicId: 657,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658814-15-worked-example-7-video-solution"
            }
          ],
          files:[{
            linkText: "Example 15 Answer",
            filename: "Lecture 15.zip"            
          }]
        },
        {
          lectureId: 16,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #8",          
          vimeoURL: "https://player.vimeo.com/video/628971534?h=54641a7a18",          
          duration: "02:09",
          preview: false,
          topicId: 658,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658815-16-worked-example-8-video-solution"
            }
          ],
          files:[{
            linkText: "Example 16 Answer",
            filename: "Lecture 16.zip"            
          }]
        },
        {
          lectureId: 17,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #9",          
          vimeoURL: "https://player.vimeo.com/video/628971853?h=574acfb65a",          
          duration: "02:47",
          preview: false,
          topicId: 659,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658816-17-worked-example-9-video-solution"
            }
          ],
          files:[{
            linkText: "Example 17 Answer",
            filename: "Lecture 17.zip"            
          }]
        },
        {
          lectureId: 18,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #10",          
          vimeoURL: "https://player.vimeo.com/video/628972038?h=d555d6ef17",          
          duration: "01:51",
          preview: false,
          topicId: 660,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658817-18-worked-example-10-video-solution"
            }
          ],
          files:[{
            linkText: "Example 18 Answer",
            filename: "Lecture 18.zip"            
          }]
        },
        {
          lectureId: 19,
          section: "Static Equilibrium and Reaction Forces",
          title: "Test Yourself – Worked Example #11",          
          vimeoURL: "https://player.vimeo.com/video/628972212?h=512ef4061e",          
          duration: "02:31",
          preview: false,
          topicId: 661,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226170-static-equilibrium-and-reaction-forces/658820-19-worked-example-11-video-solution"
            }
          ],
          files:[{
            linkText: "Example 19 Answer",
            filename: "Lecture 19.zip"            
          }]
        },
        {
          lectureId: 20,
          section: "Analysis of Trusses",
          title: "What is a truss structure in theory",          
          vimeoURL: "https://player.vimeo.com/video/622493824?h=052127a5c7",          
          duration: "04:42",
          preview: true,
          topicId: 662,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658897-20-what-is-a-pin-jointed-structure-in-theory"
            }
          ],
          files:[]
        },
        {
          lectureId: 21,
          section: "Analysis of Trusses",
          title: "What is a truss structure in reality",          
          vimeoURL: "https://player.vimeo.com/video/622498324?h=7a39632c3e",          
          duration: "05:09",
          preview: true,
          topicId: 663,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658908-21-what-is-a-pin-jointed-structure-in-reality"
            }
          ],
          files:[]
        },
        {
          lectureId: 22,
          section: "Analysis of Trusses",
          title: "Force calculation using ‘Joint Resolution’",          
          vimeoURL: "https://player.vimeo.com/video/622519660?h=3a41ff88a6",          
          duration: "10:56",
          preview: true,
          topicId: 664,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658910-22-force-calculation-using-joint-resolution"
            }
          ],
          files:[{
            linkText: "Example 22 Solution",
            filename: "Lecture 22.zip"            
          }]
        },
        {
          lectureId: 23,
          section: "Analysis of Trusses",
          title: "Force calculation using the ‘Method of Sections’",          
          vimeoURL: "https://player.vimeo.com/video/622529063?h=8d34f6ad83",          
          duration: "08:19",
          preview: false,
          topicId: 665,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658929-23-force-calculation-using-the-method-of-sections"
            }
          ],
          files:[]
        },
        {
          lectureId: 24,
          section: "Analysis of Trusses",
          title: "Statical determinacy",          
          vimeoURL: "https://player.vimeo.com/video/622535380?h=bf5c240c1c",          
          duration: "04:50",
          preview: false,
          topicId: 666,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658919-24-statical-determinacy"
            }
          ],
          files:[]
        },
        {
          lectureId: 25,
          section: "Analysis of Trusses",
          title: "Test Yourself – Worked Example #12",          
          vimeoURL: "https://player.vimeo.com/video/628972557?h=b9bbeb81e7",          
          duration: "12:22",
          preview: false,
          topicId: 667,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658922-25-worked-example-12-video-solution"
            }
          ],
          files:[{
            linkText: "Example 25 Answer",
            filename: "Lecture 25.zip"            
          }]
        },
        {
          lectureId: 26,
          section: "Analysis of Trusses",
          title: "Test Yourself – Worked Example #13",          
          vimeoURL: "https://player.vimeo.com/video/628974644?h=203e454561",          
          duration: "09:47",
          preview: false,
          topicId: 668,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658935-26-worked-example-13-video-solution"
            }
          ],
          files:[{
            linkText: "Example 26 Answer",
            filename: "Lecture 26.zip"            
          }]
        },
        {
          lectureId: 27,
          section: "Analysis of Trusses",
          title: "Test Yourself – Worked Example #14",          
          vimeoURL: "https://player.vimeo.com/video/628975855?h=b52e4ace3a",          
          duration: "11:41",
          preview: false,
          topicId: 669,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658944-27-worked-example-14-video-solution"
            }
          ],
          files:[{
            linkText: "Example 27 Answer",
            filename: "Lecture 27.zip"            
          }]
        },
        {
          lectureId: 28,
          section: "Analysis of Trusses",
          title: "Test Yourself – Worked Example #15",          
          vimeoURL: "https://player.vimeo.com/video/628977871?h=f2a47fda64",          
          duration: "03:34",
          preview: false,
          topicId: 670,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658936-28-worked-example-15-video-solution"
            }
          ],
          files:[{
            linkText: "Example 28 Answer",
            filename: "Lecture 28.zip"            
          }]
        },
        {
          lectureId: 29,
          section: "Analysis of Trusses",
          title: "Test Yourself – Worked Example #16",          
          vimeoURL: "https://player.vimeo.com/video/628978190?h=f99034b77a",          
          duration: "02:31",
          preview: false,
          topicId: 671,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658948-29-worked-example-16-video-solution"
            }
          ],
          files:[{
            linkText: "Example 29 Answer",
            filename: "Lecture 29.zip"            
          }]
        },
        {
          lectureId: 30,
          section: "Analysis of Trusses",
          title: "Wrapping up",          
          vimeoURL: "https://player.vimeo.com/video/622539927?h=27f6cca55d",          
          duration: "01:56",
          preview: false,
          topicId: 672,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/fundamentals-of-structural-analysis/226176-analysis-of-trusses/658949-30-wrap-up-certificate-of-completion"
            }
          ],
          files:[]
        },
      ]
    }
  ]
}
