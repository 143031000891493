import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1=
`#Constants
E = 70*10**9 #(N/m^2) Young's modulus 
A = 0.0025 #(m^2) Cross-sectional area 

gamma = 0 #(kg/m) Cable mass per unit length
swt = True #Take self-weight into account 🚨
Areas = A*np.ones([len(members)]) ##Array to hold individual member area
P0 = 0*np.ones([len(members)]) #Array to hold individual member pre-tension
P = -10000 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

convThreshold = 30 #(N) Threshold on maximum value of F_inequilibrium
nForceIncrements = 1000 #The number of force increments`

const codeString2 = 
`if(swt):
    SW_at_supports = np.empty((0,2))
    for n, mbr in enumerate(members):  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member
        length = lengths[n]     
        sw = length*gamma*9.81 #(N) Self-weight of the member
        F_node = sw/2 #(N) Self-weight distributed into each node  
        iy = 2*node_i-1 #index of y-DoF for node i
        jy = 2*node_j-1 #index of y-DoF for node j         
        forceVector[iy] = forceVector[iy]-F_node
        forceVector[jy] = forceVector[jy]-F_node  
        
        #Check if SW needs to be directly added to supports (if bars connect to supports)
        if(iy+1 in restrainedDoF):
            supportSW = np.array([iy, F_node])
            SW_at_supports = np.append(SW_at_supports, [supportSW], axis=0) #Store y-DoF
        if(jy+1 in restrainedDoF):
            supportSW = np.array([jy, F_node])
            SW_at_supports = np.append(SW_at_supports, [supportSW], axis=0) #Store y-DoF`

const codeString3 = 
`reactionsFlag = False #Initialise flag so we can plot a message re. reactions
if(swt):
    if SW_at_supports.size>0:   
        reactionsFlag = True
        for SW in SW_at_supports:        
            index = int(SW[0]) #Index of the global force vector 'FG' to update        
            FI_FINAL[index,:] = FI_FINAL[index,:] + SW[1] #Add nodal SW force to FG `


class Lecture_17_31 extends React.Component {
  state={
    course: 17,
    lecture: 31, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll implement the ability to take the self-weight of the cable into account in our analysis. We start by adding a flag, <code className={classes.code}>swt</code>, to the manual data entry section. Since we’d like to retain the option to ignore self-weight, this flag will allow us to consider or ignore self-weight by changing its value between <code className={classes.code}>True</code> and <code className={classes.code}>False</code>. When set to <code className={classes.code}>True</code>, we consider self-weight in the analysis.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Calculate and add self-weight to the external force vector </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To take the self-weight of each element into account in our analysis, we simply need to cycle through each element in our model, calculate its self-weight and add half of the force to each end of the element in the negative vertical direction. This is achieved in lines 4 to 12 below.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    One extra step we need to take is to record instances where self-weight is added to a vertically restrained node. This occurs wherever an element is connected to a vertically restrained support - so, pinned nodes 1 and 4 in our case. This is necessary because these self-weight forces will be lost from our analysis in the process of reducing from the primary down to the structure stiffness matrix. As such, they will not subsequently appear in our reactions and must be manually added back in. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    While cycling through each element, we check if the vertical DoF at either node is in the list of restrained DoFs. If either is, we store the relevant DoF along with the self-weight force applied along that DoF. These are stored in a variable called <code className={classes.code}>SW_at_supports</code> (lines 15-20). This allows the reactions to be updated with the missing portion of self-weight. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The missing portion of self-weight is not generally that significant since it only relates to half the self-weight of any member connected to the support. But, it's no harm to consider this and manually add the force back into the reactions. This code block can be positioned at some point after <code className={classes.code}>forceVector</code> is first constructed and before the <code className={classes.code}>forceVector</code> is converted into smaller load increments.
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Additional force at supports due to elements connected to supports </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    When our simulation is finished, if our self-weight flag, <code className={classes.code}>swt</code> was set to true, we need to add half of the self-weight of those members connected to supports, back onto the reactions. We can do this by adding it directly into the last column of <code className={classes.code}>FI_FINAL</code>. This is achieved with the code block below. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that we're defining a new flag, <code className={classes.code}>reactionsFlag</code> and initialising it to <code className={classes.code}>False</code> (line 1) and only switching it to <code className={classes.code}>True</code> if self-weight forces have been manually added (line 4). The value of this flag will be used in the next section when deciding what title to add to our results plot - there's no need to consider this any further now. 
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock> 

                  <Typography paragraph className={classes.bodytext}>
                    One final point to note is that because we have manually added forces to <code className={classes.code}>FI_FINAL</code> in order to determine accurate reaction forces, the reactions will not agree with the tension force in adjoining members - i.e. there will not be force equilibrium at the joint. This is fine - once we understand why. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So, that wraps up this lecture and this section. Now that we have a functioning solver, we can focus in the next section on visualising the results in a more helpful way. Remember that you can download the complete notebook for this section from the resources panel at the top of this lecture. 
                  </Typography>
                                                                                 
                </Grid>        
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_31));
