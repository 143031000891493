import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import history from "../../../history";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import TableWrapper from "../../../components/utils/TableWrapper"
import CourseData from '../../../Data/courses.js';
import ProjectData from '../../../Data/projects.js';
import Avatar from "@material-ui/core/Avatar";
import {returnImage} from '../../../Data/ReturnImage';

//Table configuration
const columns = [  
	{
		name: "title",
		label: "Lecture title",
		options: {
			filter: true,
			sort: true
		}
	},
  {
		name: "course",
		label: "Course",
		options: {
			filter: true,
			sort: true,
      searchable: false
		}
  },
  {
		name: "img",
		label: "Course Image",
		options: {
			filter: false,
			sort: true,      
		}
	},
];


//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5)    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {
		// marginTop: theme.spacing(5),
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	},
  courseAvatar:{
    width: 114,
		height: 64,
    borderRadius:"5px"
  }
});

class LectureList extends React.Component {
  componentDidMount() {  
    window.scrollTo(0, 0);
  }

  returnLectures = () => {

    const { classes } = this.props;	

    let lectures=[];

    //Compile course lectures
    for (let i = 0; i < CourseData.courseList.length; i++) {
      if(CourseData.courseList[i].lectures.length>0){
        //Add course title to each lecture record
        let result = CourseData.courseList[i].lectures.map((el)=>{
          var o = Object.assign({
            course:CourseData.courseList[i].title,            
            img:(                                          
              <Avatar src={returnImage(CourseData.courseList[i].courseId,'course')} variant="square" className={classes.courseAvatar}/>
            )
          }, el);
          o.isActive = true;
          return o;
        })        
        //Add lectures from this course to master list
        lectures.push(...result)              
      }
    }  

    //Compile project lectures
    for (let i = 0; i < ProjectData.projectList.length; i++) {
      if(CourseData.courseList[i].lectures.length>0){
        //Add project title to each lecture record
        let result = ProjectData.projectList[i].lectures.map((el)=>{
          var o = Object.assign({
            course:ProjectData.projectList[i].title,            
            img:(                                          
              <Avatar src={returnImage(ProjectData.projectList[i].projectId,'project')} variant="square" className={classes.courseAvatar}/>
            )
          }, el);
          o.isActive = true;
          return o;
        })        
        //Add lectures from this course to master list
        lectures.push(...result) 
      }
    }
        
    return lectures
	};

  //Function to handle row click
	handleRowClick = (data, meta, index) => {        
    let courseTitle = data[1]
    let lectureTitle = data[0]

    let selectedCourse = CourseData.courseList.filter((c)=>{
      return c.title==courseTitle
    })
    let selectedLecture = selectedCourse[0].lectures.filter((l)=>{
      return l.title==lectureTitle
    })
    let courseId = selectedCourse[0].courseId
    let lectureId = selectedLecture[0].lectureId

    history.push({
			pathname: `/course-${courseId}/lecture-${lectureId}`
		});
	};

   

	render() {	
    //Table options
		const options = {			
      selectableRows:false,
      download:false,			
			print: false,			
			onRowClick: (data, meta, index) => this.handleRowClick(data, meta, index) //Event hander for row click
		};
    
    
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={"LECTURE INDEX"}
				>
          <Grid container justify="center" spacing={4} style={{paddingLeft:"5%", paddingRight:"5%", marginBottom:"325px"}}>       
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
              <TableWrapper
                data={this.returnLectures()}
                columns={columns}
                options={options}
              />   
            </Grid>          
          </Grid>
        </Layout>
		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(LectureList));
