// Colorbar for tension and compression forces

import * as d3 from "d3";

const tickSize = 6
const height = 75 + tickSize//50
const marginTop = 18
const marginRight = 0
const marginBottom = 16 + tickSize
const marginLeft = 0
const title = "Axial Force (N)"
const tickFormat = ".0f"

export default class Colorbar{
  constructor(element, data){
    let vis = this;
		vis.WIDTH = element.offsetWidth - marginRight - marginLeft;
    vis.TICKS = vis.WIDTH / 64   
    vis.ELEMENT = element

    vis.svg = d3
			.select(element)
			.append("svg")
      .attr("viewBox", [0, 0, vis.WIDTH, height])
      .style("overflow", "visible")
      .style("display", "block")
      .attr("viewBox", `0 0 100 ${height}`)

      vis.update(data);
  }

  update(data){    
    let vis = this;
		vis.data = data; 

    let element = vis.ELEMENT
    vis.WIDTH = element.offsetWidth - marginRight - marginLeft;
    vis.TICKS = vis.WIDTH / 64   
    vis.svg.attr("viewBox", `0 0 ${vis.WIDTH} ${height}`)
    
    const tickAdjust = g => g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height);
    const tickAdjust2 = g => g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height);

    let color 
    if((Math.min(...vis.data)<0) && (Math.max(...vis.data)>0)){
      // color = d3.scaleDiverging([Math.min(...vis.data), 0, Math.max(...vis.data)], ["red", "white", "blue"])
      color = d3.scaleDiverging([Math.min(...vis.data), 0, Math.max(...vis.data)], ["red", "#8bfb6f", "blue"])
    } else if (Math.min(...vis.data)>=0){
      // color = d3.scaleSequential([0, Math.max(...vis.data)], ["white", "blue"])
      color = d3.scaleSequential([0, Math.max(...vis.data)], ["#8bfb6f", "blue"])
    } else if(Math.max(...vis.data)<=0){
      // color = d3.scaleSequential([ Math.min(...vis.data), 0], ["red", "white"])
      color = d3.scaleSequential([ Math.min(...vis.data), 0], ["red", "#8bfb6f"])
    }

    const n = Math.min(color.domain().length, color.range().length);
    const x = color.copy().rangeRound(d3.quantize(d3.interpolate(marginLeft, vis.WIDTH - marginRight), n));

    vis.svg.append("image")
      .attr("x", marginLeft)
      .attr("y", marginTop)
      .attr("width", vis.WIDTH - marginLeft - marginRight)      
      .attr("height", height - marginTop - marginBottom)
      .attr("preserveAspectRatio", "none")      
      .attr("xlink:href", ramp(color.interpolator()).toDataURL());

    vis.svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x)
        .ticks(vis.TICKS, typeof tickFormat === "string" ? tickFormat : undefined)        
        .tickFormat(d3.format(tickFormat))
        .tickFormat(d3.format(','))
        .tickSize(tickSize))
        .call(tickAdjust)
        .call(g => g.select(".domain").remove())
        .call(g => g.append("text")
          .attr("x", marginLeft)
          .attr("y", marginTop + marginBottom - height - 6)
          .attr("fill", "#33cc99")
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
          .attr("class", "title")
          .text(title));

    const axis2 = vis.svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x)      
      .ticks(vis.data)            
      .tickFormat("")
      .tickSize(0)
      .tickValues(vis.data))  
    .call(tickAdjust2) 
      
    axis2.selectAll(".tick line")
      .attr("stroke","#33cc99");
      
    
  }
}

//Ref: https://observablehq.com/@d3/color-legend
function ramp(color, n = 256) {
  const canvas = document.createElement("canvas");
  canvas.width = n;
  canvas.height = 1;
  const context = canvas.getContext("2d");
  for (let i = 0; i < n; ++i) {
    context.fillStyle = color(i / (n - 1));
    context.fillRect(i, 0, 1, 1);
  }
  return canvas;
}

