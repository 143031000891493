import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const codeString1 = 
`def calculateMbrForces(UG):
    """
    Calculates the member forces based on change in length of each member
    Takes in the cumulative global displacement vector as UG
    """
    
    mbrForces = np.zeros(len(members)) #Initialise a container to hold axial forces
    
    for n, mbr in enumerate(members):  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member   

        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member

        #New positions = initial pos + cum deflection
        ix = nodes[node_i-1,0] + UG[ia,0]
        iy = nodes[node_i-1,1] + UG[ib,0] 
        jx = nodes[node_j-1,0] + UG[ja,0]
        jy = nodes[node_j-1,1] + UG[jb,0]

        dx = jx-ix #x-component of vector along member
        dy = jy-iy #y-component of vector along member
        newLength = math.sqrt(dx**2 + dy**2) #Magnitude of vector

        deltaL = newLength - lengths[n] #Change in length            
        force = P0[n] + deltaL*E*Areas[n]/lengths[n] #Axial force 
        mbrForces[n] = force #Store member force  
         
    return mbrForces`


class Lecture_17_28 extends React.Component {
  state={
    course: 17,
    lecture: 28, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll write a simple function to calculate the axial force in each member based on the current set of displacements for the structure. As I said at the end of the previous lecture, we haven't called this function yet but we will make use of it soon.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Function to calculate axial forces based on member change in length </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If you've followed what we've done up to this point, this will be quite an easy function for your to decipher. We take in the current set of displacements as an argument. Then initialise a container to store all member forces, <code className={classes.code}>mbrForces</code>, then cycle through each member to calculate the member force.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The axial force is determined from the change in length of the member, which is easily determined from the vector of displacements <code className={classes.code}>UG</code>. Once the axial force for each member is determined, it's stored in our container of <code className={classes.code}>mbrForces</code> (line 31). Once we've cycled through all members, we return the complete collection at the end of the function.  
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    We can think of this function as a handy little utility that we can call at any point to get a snapshot of the axial force in each member. In the next lecture, we'll implement a more substantial feature of our toolbox, the ability to break the applied force into multiple increments.
                  </Typography>
                                                                 
                </Grid>

                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_28));
