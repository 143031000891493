import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-54/img1.png";

const codeString1=
`i=0 #Initialise an iteration counter (zeros out for each load increment)
inc=0 #Initialise load increment counter
notConverged = True #Initialise convergence flag

while notConverged and i<10000: 
    
    #Calculate the cumulative internal forces Fi_total = Fa + Fb + Fc + ...    
    Fi_total = np.matrix(np.sum(F_inc,axis=1)).T #Sum across columns of F_inc     
        
    #Calculate the cumulative incremental displacements UG_total = Xa + Xb + Xc + ...
    UG_total = np.matrix(np.sum(UG_inc,axis=1)).T #Sum across columns of UG_inc  
   
    #Inequilibrium force vector used in this iteration F_EXT - Fi_total 
    F_inequilibrium = forceVector - Fi_total  
            
    #Build structure stiffness matrix based on current position
    Ks = buildStructureStiffnessMatrix(UG_total)     

    #Solve for global (incremental) displacement vector [Xn] for this iteration
    UG = solveDisplacements(Ks, F_inequilibrium)
    
    #Calculate a new transformation matrix for each member
    TMs = calculateTransMatrices(UG_total)
    
    #Calculate the internal force system based on new incremental displacements, [Fn]
    F_int = updateInternalForceSystem(UG)       
        
    #Save incremental displacements and internal forces for this iteration
    UG_inc = np.append(UG_inc, UG, axis=1)
    F_inc = np.append(F_inc, F_int, axis=1)       
        
    #Test for convergence
    notConverged = testForConvergence(i, convThreshold, F_inequilibrium)   
    
    i+=1
        
    '''
    🚨 If system has converged: 
        - test for slack elements, 
        - save converged displacements, forces and increment external loading
    '''
    if not notConverged: 
        
        hasSlackElements = False #Initialise a flag 
        mbrForces = calculateMbrForces(UG_total) #Calculate member forces
        
        #Test for compression in cable elements 
        if inc > checkSlackAfter:
            for m, mbr in enumerate(members):            
                if memberType[m] == 'c' and mbrForces[m]<0:
                    print(f'Compression in cable element from nodes {mbr[0]} to {mbr[1]}')
                    hasSlackElements = True #Switch slack elements flag
                    Areas[m] = 0 #Eliminate member stiffness
                    P0[m] = 0 #Eliminate pretension
         
        if not hasSlackElements: 
            print(f'System has converged for load increment {inc} after {i-1} iterations')

            #Save displacement data for this increment and prep for next one
            UG_FINAL = np.append(UG_FINAL, UG_total, axis=1) #
            UG_inc = np.empty([nDoF,0]) 
            UG_inc = np.array(np.append(UG_inc, UG_total, axis=1)) 

            #Save force data for this increment and prep for next one
            FI_FINAL = np.append(FI_FINAL, Fi_total, axis=1) 
            F_inc = np.empty([nDoF,0]) 
            F_inc = np.array(np.append(F_inc, Fi_total, axis=1))

            #Save snapshot of member axial forces for this increment
            mbrForces = calculateMbrForces(UG_FINAL[:,-1]) 
            MBRFORCES = np.append(MBRFORCES, np.matrix(mbrForces).T, axis=1) 

            #Save snapshot of externally applied forces for this increment
            EXTFORCES = np.append(EXTFORCES, forceVector, axis=1) 
          
            #Test if all external loading has been applied
            if abs(sum(forceVector).item()) < abs(sum(maxForce).item()):
                i=0 #Reset counter for next load increment
                inc +=1
                forceVector = forceVector + forceIncrement #Increment the applied load 
                notConverged = True #Reset notConverged flag for next load increment
        else:
            print('Rerun load increment with slack element stiffness set to zero')
            F_inc = np.empty([nDoF,0]) #Zero out the record of incremental forces
            F_inc = np.array(np.append(F_inc, FI_FINAL[:,-1], axis=1)) #Reset F_inc 

            UG_inc = np.empty([nDoF,0]) #Zero out the record of incremental displacements
            UG_inc = np.array(np.append(UG_inc, UG_FINAL[:,-1], axis=1)) #Reset UG_inc

            #Reset the external forceVector to last converged value
            forceVector = np.array([EXTFORCES[:,-1]]).T 

            i=0 #Reset iteration counter
            notConverged = True #Reset notConverged flag for next load increment `


class Lecture_17_54 extends React.Component {
  state={
    course: 17,
    lecture: 54, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We saw in the previous lecture that our structure developed compression in two cable elements. These elements can be termed, <i>slack</i> elements. In this lecture, we'll modify our main execution loop to check for compression in cable elements every time we've converged for a given load increment. If we identify compression, we'll eliminate the member from the structure by setting its cross-sectional area to zero (removing its influence from the stiffness matrix), we eliminate the pre-tension for the member and rerun the analysis for that load increment. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    One thing to be aware of is that by effectively removing elements from the structure mid-way through our analysis, we introduce another significant source of non-linearity into our analysis. If too many cable elements go into compression, our solution may become unstable and may not converge. So, to increase the chances of convergence, we ought to seek initial structural configurations that will not lead to large numbers of elements going onto compression. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we observe modest compression forces developing in a limited number of cable elements, the code we're about to implement has a reasonable chance of dealing with it successfully.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Eliminating compression elements </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first thing we need to do is add another parameter to the data entry block. We will only check for slack cable elements after an initial number of load increments has been passed. This, in effect, means that we're only checking for slack elements after the convergence behaviour has somewhat stabilised. We'll define the parameter, <code className={classes.code}>checkSlackAfter=1000</code>.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can move down to the main execution loop. The full <code className={classes.code}>while</code> loop code block is included below, but we only need to focus on the code after we enter the <code className={classes.code}>if not notConverged</code> block, i.e. after convergence has been achieved for a given load increment. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Once we've entered this block after one of the load increments has converged, we define a flag, <code className={classes.code}>hasSlackElements</code>, to indicate whether there is compression in any cable element (line 44). Then we calculate the axial force in each element. If the current load increment is after <code className={classes.code}>checkSlackAfter</code> that we defined above, we cycle through each element and check if any of them are cables and have a compression force (lines 48-50). If we identify a cable in compression, we set its area and pre-tension force to zero and switch the flag, <code className={classes.code}>hasSlackElements</code> to true. The value of this flag will govern what happens next.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If there are no slack elements found, then we enter the first <code className={classes.code}>if not</code> block and proceed to save the snapshot of data and prepare for the next load increment as before. However, if there were slack elements, we enter the second,<code className={classes.code}>else</code> block. Inside this block, we reset the incremental force and displacement vectors, <code className={classes.code}>F_inc</code> and <code className={classes.code}>UG_inc</code> and the external force vector, <code className={classes.code}>forceVector</code>. We reset the iteration counter and return the <code className={classes.code}>notCOnverged</code> flag to <code className={classes.code}>True</code>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The <code className={classes.code}>while</code> loop will try to converge for the previous load increment again on the next iteration of the loop, except this time the influence of the slack element has been removed.   
                  </Typography>  

                  <CodeBlock>{codeString1}</CodeBlock>   

                  <Typography paragraph className={classes.bodytext}>
                    After running the code we can see that the members that previously developed compression are now printed in grey, indicating that they're now zero force members, Fig 1. So, our code has worked; instead of these elements developing compression forces, they now develop no internal forces and the force distribution in the rest of the structure has adjusted accordingly. 
                  </Typography>
                 
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Results plot indicating zero force members between nodes 6 and 16 and nodes 24 and 36.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    If we inspect the output statements generated by the main execution loop, we can see that on iteration <MathJax.Node inline formula={"5572"} />, compression was detected in element <MathJax.Node inline formula={"6-16"} />. Similarly, compression was detected in element <MathJax.Node inline formula={"24-36"} /> on iteration <MathJax.Node inline formula={"5575"} />. Once these compression forces were eliminated and the increments re-run, no further compression was detected. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > How robust is this solution? </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Although our solution has fixed the issue for this model, it's worth emphasising again that this solution will work in some but definitely not all situations. Despite the solution and the underlying logic being pretty straightforward, if many cable elements go into compression, we're potentially setting large chunks of the stiffness matrix to zero. It's quite possible, likely even, that this will cause problems with the stiffness matrix, making it uninvertable. It may also lead to a sudden increase in deflection magnitude that our solver will not be able to converge for. So, as has been mentioned above, our best defence against this dead-end is to ensure we have an initial configuration that maintains tension in all cable elements throughout the loading history. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This marks the end of development - our 2D solver is complete. In the next lecture, we'll apply it to one final interesting structure before wrapping up the course.
                  </Typography>
                
                </Grid> 
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_54));
