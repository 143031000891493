export const FETCH_USER = "fetch_user";
export const FETCH_FLASH = "fetch_flash";
export const FETCH_ERRORS = "fetch_errors";

export const FETCH_CUSTOMER_DATA = "fetch_customer_data";
export const FETCH_ACTIVE_USERS = "fetch_users";
export const FETCH_SOLVER_USAGE = "fetch_solver_usage";

export const FETCH_2DTRUSS = "fetch_2dtruss";
export const DELETE_2DTRUSS = "delete_2dtruss";

export const FETCH_3DTRUSS = "fetch_3dtruss";
export const DELETE_3DTRUSS = "delete_3dtruss";

export const FETCH_COMPLETEDLECTURES = "fetch_completedLectures"
