import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const Eqn1 = `\\begin{align}
K &= \\frac{M_{Ed}}{bd^2\\:f_{ck}}\\\\\\\\
K&= \\frac{350 \\times 10^6}{300\\times 450^2 \\times 30}\\\\\\\\
K &= 0.192
\\end{align}`

const Eqn2 = `\\begin{equation}
\\frac{d'}{d} = \\frac{50}{450}=0.111<0.171
\\end{equation}`

const Eqn3 = `\\begin{align}
A_s' &= \\frac{(350-304.4)\\times 10^6}{0.87\\times 500 \\times (450-50)}\\\\\\\\
&= 262 \\:mm^2
\\end{align}`

const Eqn4 = `\\begin{align}
A_s &= \\frac{304.4\\times 10^6}{0.87\\times 500 \\times (0.82\\times 450)}+262\\\\\\\\
&= 2158 \\:mm^2
\\end{align}`

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_20 extends React.Component {
  state={
    course: 19,
    lecture: 20, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >      

                  <Typography component="h2" className={classes.H2} > 1.0 Question  </Typography>            
                  
                  <Typography paragraph className={classes.bodytext}>
                    Continuing our hypothetical design scenario from the last lecture, consider the beam from <Link to="/course-19/lecture-15" className={classes.link} target="_blank">example #3</Link> again. It now must resist a design bending moment of <MathJax.Node inline formula={"M_{Ed} = 350 \\:kNm"} />. Determine a suitable arrangement of longitudinal reinforcement.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The solution to this question requires a standard doubly reinforced design. We start by evaluating <MathJax.Node inline formula={"K"} />, to confirm that compression reinforcement is required.
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since <MathJax.Node inline formula={"K >0.167"} />, a singly reinforced beam will be insufficient and compression reinforcement is required.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we assume <MathJax.Node inline formula={"30 \\:mm"} /> outer cover to the first steel, a <MathJax.Node inline formula={"10\\: mm"} /> diameter shear link and <MathJax.Node inline formula={"20\\: mm"} /> diameter compression reinforcement bars, then the effective depth to the centroid of the compression reinforcement is <MathJax.Node inline formula={"d'=30+10+(20/2)=50\\:mm"} />. Now we confirm that the compression reinforcement will have yielded at the ultimate limit state,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore the compression reinforcement will have yielded, and our assumption is valid. Now all that remains is to determine the required areas of compression and tension reinforcement. We start with the compression reinforcement, 
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now the tension reinforcement can be calculated,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, we can propose a rebar arrangement. For tension reinforcement, we might spec 5 H25 bars(<MathJax.Node inline formula={"2450\\:mm^2"} />), although with a beam width of <MathJax.Node inline formula={"300\\:mm"} />, this leaves only about <MathJax.Node inline formula={"23\\:mm"} /> clear between the bars, which is on the limit of acceptability. So, a better option might be 3 H32 which provides approximately the same steel area (<MathJax.Node inline formula={"2410\\:mm^2"} />) but leaves a clear distance of <MathJax.Node inline formula={"62\\:mm"} /> clear between bars. For compression reinforcement, 3 H12 bars (<MathJax.Node inline formula={"339\\:mm^2"} />) will work well. 
                  </Typography>                  
                                
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_20));
