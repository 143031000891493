import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-43/img1.jpg";
import img2 from "../../../images/Lectures/C17-43/img2.jpg";
import img3 from "../../../images/Lectures/C17-43/img3.jpg";

const codeString1 = 
`#Constants
E = 70*10**9 #(N/m^2)
A = 0.0002 #(m^2)

gamma = 100 #(kg/m) Cable mass per unit length
swt = True #Take self-weight into account 
Areas = A*np.ones([len(members)]) #An array to hold individual area for each member 
P0 = 1*np.ones([len(members)]) #An array to hold individual member pre-tension values

P = 0 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

nForceIncrements = 1000
convThreshold = 10 #(N) Threshold on maximum value of F_inequilibrium`


class Lecture_17_43 extends React.Component {
  state={
    course: 17,
    lecture: 43, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll start to reduce the axial stiffness of the cable. In doing so, we'll gradually increase the elongation of the cable under its own self-weight, and as a result, the behaviour will become non-linear. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll reduce the stiffness by progressively reducing the cross-sectional area. We'll start by reducing it by a factor of <MathJax.Node inline formula={"10"} />, down to <MathJax.Node inline formula={"0.0002 \\:m^2"} />. All of the other simulation parameters are unchanged.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    The results plot, Fig. 1 shows very little change from our previous linear analysis. In particular, we note that the deflection is still barely perceptible.
                  </Typography>
                  
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. The results plot with a cable cross-sectional area of <MathJax.Node inline formula={"0.0002 \\:m^2"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we can reduce the cross-sectional area by a further factor of <MathJax.Node inline formula={"10"} />, down to <MathJax.Node inline formula={"0.00002 \\:m^2"} />. From the results plot, Fig 2, we can see that the change in cable length is now starting to increase appreciably, and as a result, we're starting to see a change in the horizontal component of the reaction force, reducing from <MathJax.Node inline formula={"6.43 \\:kN"} /> to <MathJax.Node inline formula={"6.34 \\:kN"} /> as the cable becomes more vertical where it connects to the supports. 
                  </Typography>

                                                 
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. The results plot with cable cross-sectional area of <MathJax.Node inline formula={"0.00002 \\:m^2"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Let's continue and reduce the area by another factor or <MathJax.Node inline formula={"10"} />, down to <MathJax.Node inline formula={"0.000002 \\:m^2"} />. As expected, we're now seeing very significant self-weight deflection and as a result, geometric non-linearity, Fig 3. The horizontal component of the reactions has reduced further to <MathJax.Node inline formula={"5.66 \\:kN"} />. 
                  </Typography>                                  
                </Grid>  

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. The results plot with cable cross-sectional area of <MathJax.Node inline formula={"0.000002 \\:m^2"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The maximum tension in the cable has also reduced from <MathJax.Node inline formula={"8 \\:kN"} /> in the previous analysis, down to <MathJax.Node inline formula={"7.48 \\:kN"} /> in this analysis. We could keep reducing the axial stiffness more and more but we've sufficiently demonstrated the emergence of non-linear behaviour and our code's ability to handle it. When compared to our analysis back in lecture 7, you should recognise the great utility and flexibility of our numerical solution.  
                  </Typography>                                  
                </Grid>  
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_43));
