import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_3 extends React.Component {
  state={
    course: 17,
    lecture: 3, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    In this section, we’ll start our exploration of cables by examing the standard linear model of cable behaviour. We’ll consider a simple cable hanging under the influence of its own weight. By ignoring any change in cable length and therefore assuming no change in the geometry of the cable under loading, we’ll derive a closed-form solution for the maximum tension in the cable. As well as being a good entry point into cable analysis, this will provide a baseline case to test our non-linear code against later.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the first three lectures, we’ll work through the classic process of deriving the cable or catenary equation. This will result in an equation that, depending on the quantity we’re solving for, will be <i>transcendental</i>. In our case, we’ll be solving for the maximum tension in the cable, so we must solve the equation numerically.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In the final lecture, we’ll move over to a Jupyter Notebook and write some Python code that will solve the catenary equation giving us the maximum tension for a given cable span, self-weight and mid-span sag.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    One key takeaway from this section is that we’re ignoring any cable extension. If the geometry of the cable significantly changes under the influence of the applied load, this will invalidate what we’ve done in this section. So, it will be quite clear how valuable a non-linear solver will be to us, once we finish this section.
                  </Typography>                   

                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_3));
