import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-49/img1.jpg";

const codeString1 = 
`import bpy
import csv
import numpy as np
import bmesh
obdata = bpy.context.object.data
bm = bmesh.from_edit_mesh(obdata)`

const codeString2 = 
`mask = [1,1]`

const codeString3 = 
`#Get selected vertices
vertices = np.empty((0,2),int)
for v in bm.verts:
    if v.select:
        indexNo = v.index+1
        xDoF = 2*indexNo-1
        yDoF = 2*indexNo
        
        vertex = np.array([xDoF, yDoF])
        vertex = vertex*mask
        vertices = np.append(vertices, [vertex], axis=0) `

const codeString4 = 
`#Export vertex coordinates to CSV file 
filename = "/Users/Sean/Desktop/Restraint-Data.csv"
# writing to csv file  
with open(filename, 'w') as csvfile:  
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(vertices) # writing the data rows  `

const codeString5 = 
`mask = [1,0]`

class Lecture_17_49 extends React.Component {
  state={
    course: 17,
    lecture: 49, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The next thing we need to export from the Blender file is the restrained degrees of freedom. Our approach is very similar to when we exported cable elements in the previous lecture. While in <i>Edit</i> mode, we'll select the vertices we want to be restrained. Then we'll loop through all vertices in our code and only operate on the ones that are currently selected.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To indicate which DoFs are to be restrained, x or y or x <i>and</i> y, we'll use a <i>mask</i> containing <MathJax.Node inline formula={"0"} /> or <MathJax.Node inline formula={"1"} /> for each DoF. <MathJax.Node inline formula={"0"} /> will indicate no fixity, and <MathJax.Node inline formula={"1"} /> will indicate full fixity. So, for example, the mask for a pinned node, restrained in the x and y direction would be <code className={classes.code}>mask=[1,1]</code>. For a node restrained in the x direction only, we would have <code className={classes.code}>mask=[1,0]</code> and for y restraint, <code className={classes.code}>mask=[0,1]</code>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    By multiplying (elementwise) the relevant mask by the vector or DoFs for a node, we will convert the unrestrained DoF to zero and leave the restained DoF number unchanged. After operating on all selected nodes, we're left with a data structure containing only restrained DoF numbers. This is what we'll export.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If our model contains two different types of support, as will be the case here, we need to run the code multiple times with different masks and combine the data from the output csv files into a single file. We'll see this further below. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We start with the same imports and definitions as the last lecture.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we need to define the correct mask for the restained nodes in our model. The ends of the main suspension cable will be restrained in the x and y direction. The left end of the truss deck will also be restrained in the x-direction only, Fig 1. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}></Typography>
                  <Typography paragraph className={classes.bodytext}></Typography>                  
                                                    
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                    <figcaption className={classes.caption}>Fig 1. Model restraints.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    So, we'll need two masks and two executions of our code. Then we'll manually combine the exported DoFs into a single file. We'll generate the data for the pinned nodes first and define our mask as follows. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Now we cycle through each vertex in the usual way and check to ensure the current vertex is selected before operating on it. We can compute the x and y DoF numbers from the index of the vertex, remembering to add <code className={classes.code}>+1</code> to the vertex number generated by Blender (lines 5-7). Then we can construct an array of DoF numbers (line 9) and multiply it by our mask (line 10), eliminating the free DoF. Note that in this particular case, the mask for a pin doesn't actually eliminate any DoFs since both are restrained. The remaining array of index numbers is then appended to a larger collection.
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Once we've cycled through all verticies, we can export the <code className={classes.code}>vertices</code> to <i>Restraint-Data.csv</i> in the usual way.
                  </Typography>

                  <CodeBlock>{codeString4}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Remember, we still need to export the restrained DoF for the vertical roller that restrains the left edge of the deck. So, we need to run the code again, but this time we change the mask to indicate that the horizontal DoF only is restrained.
                  </Typography>

                  <CodeBlock>{codeString5}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Before running the code, make sure to move or rename the csv file that we just generated, as running the script a second time will overwrite the last file, and we'll lose the data inside. Remember, we need to combine the data from both files before we import into our Jupyter Notebook. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    After executing the script a second time, we have two csv files, we can combine them into a single file with three rows. Make sure this file is named <i>Restraint-Data.csv</i> and copied into the main data folder. We'll complete the final data export, for force location data, in the next lecture.
                  </Typography>
                             
                </Grid> 
                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_49));
