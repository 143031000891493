import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-23/img1.png";

const Eqn1 = `\\begin{align}
M_f &=  F_{cf}\\:z_1\\\\\\\\
M_f &= 1063.1\\times 0.538\\\\\\\\
M_f &= 572\\:kNm
\\end{align}`

const Eqn2 = `\\begin{equation}
M_{Ed} = M_f + F_{cw}\\:z_2
\\end{equation}`

const Eqn3 = `\\begin{align}
700\\times 10^6 &= 572\\times 10^6 + (0.567\\:f_{ck}\\:b_w\\:s_w)\\left(d-h_f-\\frac{s_w}{2}\\right)\\\\\\\\
700\\times 10^6 &= 572\\times 10^6 + (0.567\\times 30\\times 400\\times s_w)(600-125-0.5s_w)
\\end{align}`

const Eqn4 = `\\begin{equation}
3402s_w^2-3231900s_w+(128.1\\times 10^6)=0
\\end{equation}`

const Eqn5 = `\\begin{align}
x_{lim} &= 0.45\\:d\\\\\\\\
x_{lim} &=0.45\\times 600 = 270\\:mm\\:>208\\:mm
\\end{align}`

const Eqn6 = `\\begin{align}
F_{st} &= F_{cf} + F_{cw}\\\\\\\\
0.87\\:f_{yk}\\:A_s &= 1063.1\\times 10^3 + 0.567\\:f_{ck}\\:b_w\\:s_w\\\\\\\\
0.87\\times 500\\times A_s &=1063.1\\times 10^3 + 0.567\\times 30\\times 400 \\times 41
\\end{align}`



const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_24 extends React.Component {
  state={
    course: 19,
    lecture: 24, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >          
                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>       

                  <Typography paragraph className={classes.bodytext}>
                    For our next example, we'll consider the same cross-section shape as the previous example, but this time, we'll specify a design moment, <MathJax.Node inline formula={"M_{Ed} = 700\\:kNm"} /> and determine the required area of steel. All material properties are unchanged.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Flanged cross-section with unknown tension reinforcement.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>             

                  <Typography paragraph className={classes.bodytext}>
                    A sensible starting point is to first consider how much moment resistance can be generated by the flange alone going into compression. We've already worked out the compression force that can be generated by the flange and its lever-arm relative to the tension force in the steel,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    As we might have suspected, to resist this design moment, the compression block must extend into the web. So, the next question is how far into the web?
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can determine this by considering moment equilibrium between the design moment <MathJax.Node inline formula={"M_{Ed}"} /> and the internal resistance moment, 
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now, if we let <MathJax.Node inline formula={"s_w"} /> be the height of the web in compression, we can expand the last expression as,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    After some simplifying, this reduces to a quadratic in <MathJax.Node inline formula={"s_w"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Of the two possible roots <MathJax.Node inline formula={"(909,\\:41)"} />, only one is valid in the context of this problem, therefore <MathJax.Node inline formula={"s_w = 41\\:mm"} />.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we should check to make sure that the neutral axis depth won't exceed the limit for a ductile failure. The total depth of the compression block is <MathJax.Node inline formula={"125+41 = 166\\:mm"} />. Therefore the neutral axis depth is <MathJax.Node inline formula={"x=166/0.8 = 208\\:mm"} />. The limiting neutral axis depth is,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, we won't exceed the limit on neutral axis depth while resisting this design moment.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Our final task is to determine the required area of steel by constructing the force equilibrium equation,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore, <MathJax.Node inline formula={"A_s = 3085\\:mm^2"} />. We note that this happens to be slightly less than the area of steel specified in the previous example. So, 4 H32 bars would be sufficient.  
                  </Typography>                      
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_24));
