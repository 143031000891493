import { FETCH_SOLVER_USAGE } from "../actions/types";

export default function(state = {}, action) {
	switch (action.type) {
		case FETCH_SOLVER_USAGE:
			return action.payload; //returns user object or false    
		default:
			return state;
	}
}
