import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_40 extends React.Component {
  state={
    course: 17,
    lecture: 40, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    At this stage, we have a minimum viable code - our solver performs an iterative analysis that tries to converge on the final state of the structure, and we’ve implemented an extensive structure visualisation. This section acts as a validation of sorts for our solver so far.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In this section, we’ll close the circle, so to speak, and compare our code’s results with the linear cable solution we developed at the start of the course. We’ll be able to closely approximate the linear solution we derived previously but also observe the emergence of non-linear behaviour as the axial stiffness of our cable is progressively reduced, leading to larger and larger self-weight deflections.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We’ll also compare the behaviour of our new non-linear solver with the linear truss analysis solver we developed in the pre-requisite course. Again highlighting the advantage offered by a non-linear solver when deflections start to become large by comparison to the scale of the structure.  
                  </Typography>          
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_40));
