import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { useMediaQuery } from "react-responsive";
import LayoutPublic from "../../../components/common/LayoutPublic";
import Feedback from "../../../components/utils/Feedback";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { reduxForm, Field, formValueSelector } from "redux-form";
import Particles from "react-particles-js";

//Config for particles.js
const particleConfig = {
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
				value_area: 800
			}
		},
		color: {
			value: "#ffffff"
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000"
			},
			polygon: {
				nb_sides: 5
			},
			image: {
				src: "img/github.svg",
				width: 100,
				height: 100
			}
		},
		opacity: {
			value: 0.06313181133058181,
			random: false,
			anim: {
				enable: false,
				speed: 0.5,
				opacity_min: 0.1,
				sync: false
			}
		},
		size: {
			value: 3,
			random: true,
			anim: {
				enable: false,
				speed: 40,
				size_min: 0.1,
				sync: false
			}
		},
		line_linked: {
			enable: true,
			distance: 150,
			color: "#ffffff",
			opacity: 0.11048066982851817,
			width: 1
		},
		move: {
			enable: true,
			speed: 2,
			direction: "none",
			random: false,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200
			}
		}
	},
	interactivity: {
		detect_on: "canvas",
		events: {
			onhover: {
				enable: false,
				mode: "repulse"
			},
			onclick: {
				enable: false,
				mode: "push"
			},
			resize: true
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1
				}
			},
			bubble: {
				distance: 400,
				size: 40,
				duration: 2,
				opacity: 8,
				speed: 3
			},
			repulse: {
				distance: 200,
				duration: 0.4
			},
			push: {
				particles_nb: 4
			},
			remove: {
				particles_nb: 2
			}
		}
	},
	retina_detect: true
};

//Media Queries
const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1281 });
	return isDesktop ? children : null;
};
const Laptop = ({ children }) => {
	const isLaptop = useMediaQuery({ minWidth: 992, maxWidth: 1280 });
	return isLaptop ? children : null;
};
const Tablet = ({ children }) => {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	return isTablet ? children : null;
};
const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile ? children : null;
};

//Custom styling
const styles = theme => ({
	particleBackground: {		
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "fixed !important",
		opacity: "100%",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -2
	},
	overlay: {
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		position: "fixed !important",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -1
	},
	page1: {
		marginTop: "50px",
		marginBottom: "125px"
	},
	page1Mobile: {
		marginTop: "15px",
		marginBottom: "50px"
	},
	headline: {
		paddingRight: "50px",
    // color: theme.palette.secondary.main,
    color:"white",
		fontSize: "2.5rem",
		fontWeight: "bold"
	},
	tagline: {
		color: "white",
		fontSize: "1.4rem",
		marginTop: "40px",
		margin: "auto",
		width: "70%"
	},
	joinTagline: {
		color: "white",
		fontSize: "1.8rem",
		marginTop: "20px",
		marginBottom: "20px"
	},
	headlineMobile: {
		paddingTop: "20px",
    // color: theme.palette.secondary.main,
    color:"white",
		fontSize: "1.8rem",
		fontWeight: "bold",
		margin: "auto"
	},
	taglineMobile: {
		color: "white",
		fontSize: "1.3rem",
		marginTop: "25px",
		margin: "auto"
	},
	textEmphasis: {
		fontWeight: "bold"
	},
	textInput: {
		color: "black",
		borderColor: theme.palette.primary.main,
		fontSize: "1.5rem"
	},
	textLabel: {
		color: theme.palette.primary.main,
		fontSize: "1.5rem",
		marginLeft: "15px"
	},
	demoButton: {
		borderColor: theme.palette.coral.main,
		color: theme.palette.coral.main
	},
	trialButton: {
		width: "100%",
		marginRight: "25px",
		color: "white",
		"&:hover": {
			color: theme.palette.coral.main
		},
		backgroundColor: theme.palette.coral.main
	}
});

class Landing extends React.Component {
	// Display red outline around input if validation error returned
	returnClasses(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return "form-control form-control-lg is-invalid";
				}
			}
		}
		return "form-control form-control-lg ";
	}

	formSubmit = values => {
		this.props.betaRequest(values);
		this.props.change("betaEmail", null); //Set the attribute title field to blank
	};

	// Return error message if calidation error returned
	returnErrorText(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return <div className="text-danger">{errors[i].msg}</div>;
				}
			}
		}
		return;
	}

	//Required when combining Matrial UI <TextField /> with redux-form
	renderTextField = ({ id, name, input, label, ...custom }) => {
		const { classes } = this.props; //Destructure data off the components props

		return (
			<TextField
				id={id}
				name={name}
				label={label}
				InputProps={{
					className: classes.textInput
				}}
				InputLabelProps={{ className: classes.textLabel }}
				{...input}
				{...custom}
			/>
		);
	};

	DesktopLayout = () => {
		const { classes } = this.props; //Destructure data off the components props
		return (
			<React.Fragment>
				<LayoutPublic />
				{/* <div className={classes.particleBackground}></div>
				<div className={classes.overlay}></div> */}
        <Particles className={classes.particleBackground} params={particleConfig} />

				<Grid container justify="center" className={classes.page1}>
					<Grid item xs={10} sm={10} md={6}>
						<Typography variant="h1" align="center" className={classes.headline}>
							<Box lineHeight={1.3}>
								DegreeTutors User Experience Sandbox
							</Box>
						</Typography>

						{/* <Typography variant="h2" align="center" className={classes.tagline}>
							<Box lineHeight={1.3}>
								<span className={classes.textEmphasis}>Empower your students</span> by
								giving them a voice and iterate your way to a better learning
								experience.
							</Box>
						</Typography> */}

						<Grid
							item
							xs={12}
							sm={10}
							style={{ marginTop: "75px", marginBottom: "300px" }}
						>
							<Grid
								container
								direction="row"
								justify="flex-start"
								alignItems="center"
							></Grid>
						</Grid>
					</Grid>
					{/* <Grid item xs={12} sm={5} md={10}></Grid>
					<Grid item xs={12} sm={5} md={4}>
						<Typography variant="h2" align="center" className={classes.joinTagline}>
							<Box lineHeight={1.3}>Get updated when we launch</Box>
						</Typography>
						<form
							noValidate
							onSubmit={this.props.handleSubmit(values => {
								this.formSubmit(values);
							})}
						>
							<div className="form-group">
								<Field
									type="email"
									className={this.returnClasses("betaEmail")}
									placeholder="Email"
									name="betaEmail"
									component="input"
									required
								/>
								{this.returnErrorText("betaEmail")}
							</div>
							<Button
								variant="contained"
								className={classes.trialButton}
								type="submit"
							>
								Join the list
							</Button>
							<Feedback />
						</form>
					</Grid>
				 */}
        </Grid>
			</React.Fragment>
		);
	};

	MobileLayout = () => {
		const { classes } = this.props; //Destructure data off the components props
		return (
			<React.Fragment>
				<LayoutPublic />
				<div className={classes.particleBackground}></div>
				<div className={classes.overlay}></div>
				<Grid container justify="center" className={classes.page1Mobile}>
					<Grid item xs={10} sm={10} md={10}>
						<Typography
							variant="h1"
							align="center"
							className={classes.headlineMobile}
						>
							<Box lineHeight={1.3}>
                DegreeTutors User Experience Sandbox
							</Box>
						</Typography>
						{/* <Typography variant="h2" align="center" className={classes.taglineMobile}>
							<Box lineHeight={1.3}>
								<span className={classes.textEmphasis}>Empower your students</span> by
								giving them a voice and iterate your way to a better learning
								experience.
							</Box>
						</Typography> */}
						{/* <Grid
							item
							xs={12}
							sm={10}
							md={10}
							style={{ margin: "auto", marginTop: "50px" }}
						>
							<Grid container direction="row" justify="center" alignItems="center">
								<Grid item xs={12} sm={5} md={4}>
									<Typography
										variant="h2"
										align="center"
										className={classes.joinTagline}
									>
										<Box lineHeight={1.3}>Get updated when we launch</Box>
									</Typography>
									<form
										noValidate
										onSubmit={this.props.handleSubmit(values => {
											this.formSubmit(values);
										})}
									>
										<div className="form-group">
											<Field
												type="email"
												className={this.returnClasses("betaEmail")}
												placeholder="Email"
												name="betaEmail"
												component="input"
												required
											/>
											{this.returnErrorText("betaEmail")}
										</div>
										<Button
											variant="contained"
											className={classes.trialButton}
											type="submit"
										>
											Join the list
										</Button>
										<Feedback />
									</form>
								</Grid>
							</Grid>
						</Grid> */}
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	render() {
		return (
			<React.Fragment>
				<Desktop>
					<this.DesktopLayout />
				</Desktop>
				<Laptop>
					<this.DesktopLayout />
				</Laptop>
				<Tablet>
					<this.MobileLayout />
				</Tablet>
				<Mobile>
					<this.MobileLayout />
				</Mobile>
			</React.Fragment>
		);
	}
}

//Create a formWrapped version of Login component
const formWrapped = reduxForm({
	form: "landingForm"
})(Landing);

const selector = formValueSelector("landingForm"); //Create a selector to extract form values

function mapStateToProps(state) {
	return {
		errors: state.errors,
		betaEmail: selector(state, "betaEmail")
	};
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(formWrapped));
