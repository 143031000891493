import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1=
`def updateInternalForceSystem(UG):
    """
    Calculate vector of internal forces associated with incremental displacements UG
    [Fn] = [T'][AA'][P]
    """
    
    F_int = np.zeros([nDoF,1]) #Initialise a container for all internal forces
    
    #Cycle through each member and calculate nodal forces in global coordinates
    for n, mbr in enumerate(members): 
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member                
        
        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member 

        #Displacements        
        d_ix = UG[ia,0]
        d_iy = UG[ib,0]
        d_jx = UG[ja,0]
        d_jy = UG[jb,0]         

        #Extract current version of transformation matrix [T] 
        TM = TMs[n,:,:]                

        #Calculate local displacements [u, v] using global cumulative displacements UG
        localDisp = np.matmul(TM,np.array([[d_ix, d_iy, d_jx, d_jy]]).T)
        u = localDisp[0].item()
        v = localDisp[1].item()                

        #Calculate extension, e
        Lo = lengths[n]
        e = math.sqrt((Lo+u)**2 + v**2)-Lo                

        #Calculate matrix [AA]
        a1 = (Lo+u)/(Lo+e)
        a2 = v/(Lo+e)
        AA = np.array([[a1,a2]])

        #Calculate axial load, P, due to incremental deflections   
        P = (E*Areas[n]/Lo)*e                
                
        #Determine axial load in global coords        
        F_global = np.matmul(TM.T,AA.T)*P     
                             
        #Add member pre-tension to overall record
        F_int[ia,0] = F_int[ia,0] + F_global[0][0]
        F_int[ib,0] = F_int[ib,0] + F_global[1][0]
        F_int[ja,0] = F_int[ja,0] + F_global[2][0]
        F_int[jb,0] = F_int[jb,0] + F_global[3][0]                

    return F_int`


class Lecture_17_26 extends React.Component {
  state={
    course: 17,
    lecture: 26, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll take the global displacement vector, calculated using the function we discussed in the last lecture, and determine the corresponding system of internal forces, <code className={classes.code}>F_int</code>. This will all happen inside a function called <code className={classes.code}>updateInternalForceSystem()</code>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The code in this function will be very similar to the code we wrote to calculate the pre-tension force system in lecture 23. This makes sense because, just like our pre-tension calculation, we're going to turn an axial force for each member into a system of global forces. The difference this time is that the axial force is determined based on the elongation of each member rather than some specified pre-tension. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll also reuse much of the code we used to calculate the element stiffness matrix in lecture 24. We start by establishing a template container filled with zeros, <code className={classes.code}>F_int</code>. Then, we cycle through each member repeating the steps we implemented in the function <code className={classes.code}>buildElementStiffnessMatrix()</code>, in lecture 24, all the way down to line 41 (below), where we calculate the vector <code className={classes.code}>AA</code>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we calculate the axial force associated with the member elongation (line 44). Then we can use the transformation matrix and the vector <code className={classes.code}>AA</code> to convert this axial force into a vector of global forces for the element, <code className={classes.code}>F_global</code>. This is another implementation of Eqn. 4 from lecture 23. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Then, we can use the indices of the element's DoFs to store the global forces in the correct location within <code className={classes.code}>F_int</code>. Finally, after cycling through all elements and building <code className={classes.code}>F_int</code> for the entire structure, we can return it from the function.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Function to calculate the internal force system vector </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    In the next lecture, we'll build the function to test for convergence.
                  </Typography>
                                                  
                </Grid>                                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_26));
