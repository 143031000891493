import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-19/img1.jpg";

const eqn1 = `
\\begin{equation}
\\tag{1}
\\text{forceVector} = \\begin{bmatrix}
0\\\\
0\\\\
0\\\\
-10000\\\\
0\\\\
-10000\\\\
0\\\\
0
\\end{bmatrix}
\\end{equation}
`
const codeString1 = `# DEPENDENCIES
import copy #Allows us to create copies of objects in memory
import math #Basic math functionality
import numpy as np #Numpy for working with arrays
import matplotlib.pyplot as plt #Plotting functionality 
import matplotlib.colors #For colormap functionality
import ipywidgets as widgets #For our interactive widgets
from glob import glob #Allow us to check that a file exists before import
from numpy import genfromtxt #For importing data from csv`

const codeString2 = 
`if glob('data/Vertices.csv'): 
    nodes = genfromtxt('data/Vertices.csv', delimiter=',') 
    print('1. 🟢 Vertices.csv imported')
else: 
    print('1. 🛑 STOP: Vertices.csv not found')`

const codeString3 = `
if glob('data/Edges.csv'): 
    members = genfromtxt('data/Edges.csv', delimiter=',') 
    members = np.int_(members) 
    nDoF = np.amax(members)*2 #Total number of degrees of freedom
    print('2. 🟢 Edges.csv imported')
else: 
    print('2. 🛑 STOP: Edges.csv not found')`

const codeString4 = 
`if glob('data/Restraint-Data.csv'): 
    restraintData = genfromtxt('data/Restraint-Data.csv', delimiter=',') 
    restraintData = np.int_(restraintData) #Convert from float to int
    flatData = restraintData.flatten() #Flatten DoF data
    restrainedDoF = flatData[np.nonzero(flatData)[0]].tolist() #Remove zeros 
    restrainedIndex = [x - 1 for x in restrainedDoF] #Generate index values
    freeDoF = np.delete( np.arange(0,nDoF),restrainedIndex) #Unrestrained DoF array
    print('3. 🟢 Restraint-Data.csv imported')
else:
    print('3. 🛑 STOP: Restraint-Data.csv not found')`

const codeString5 = `if glob('data/Force-Data.csv'): 
    forceLocationData = genfromtxt('data/Force-Data.csv', delimiter=',')
    forceLocationData = np.int_(forceLocationData) 
    nForces = len(np.array(forceLocationData.shape))
    if nForces<2:
        forceLocationData = np.array([forceLocationData]) 
    print('4. 🟢 Force-Data.csv imported')              
else:
    forceLocationData = []
    print('4. ⚠️ Force-Data.csv not found')`

const codeString6 = `#Constants
E = 70*10**9 #(N/m^2) Young's modulus 
A = 0.0025 #(m^2) Cross-sectional area 

gamma = 0 #(kg/m) Cable mass per unit length
Areas = A*np.ones([len(members)]) #Array to hold individual member area
P0 = 0*np.ones([len(members)]) #Array to hold individual member pre-tension
P = -10000 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied`

const codeString7 = `forceVector = np.array([np.zeros(len(nodes)*2)]).T #Initialise a vector of zeros
if(len(forceLocationData)>0):     
    #Split or unpack the force location data (index starting at 0)
    forcedNodes = forceLocationData[:,0]
    xForceIndices = forceLocationData[:,1]
    yForceIndices = forceLocationData[:,2]

    #Assign forces to degrees of freedom
    if(pointLoadAxis=='x'):
        forceVector[xForceIndices] = P 
    elif(pointLoadAxis=='y'):
        forceVector[yForceIndices] = P`

const codeString8 = `lengths = np.zeros(len(members)) #Initialise an array of zeros to hold lengths

for n, mbr in enumerate(members):
    
    #Calculate undeformed length of member
    node_i = mbr[0] #Node number for node i of this member
    node_j = mbr[1] #Node number for node j of this member
    ix = nodes[node_i-1][0] #x-coord for node i 
    iy = nodes[node_i-1][1] #y-coord for node i
    jx = nodes[node_j-1][0] #x-coord for node j
    jy = nodes[node_j-1][1] #y-coord for node j

    dx = jx-ix #x-component of vector along member
    dy = jy-iy #y-component of vector along member
    length = math.sqrt(dx**2 + dy**2) #Magnitude of vector (length of member)
    lengths[n] = length #Store the length in the lengths array`


class Lecture_17_19 extends React.Component {
  state={
    course: 17,
    lecture: 19, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We're finally ready to start building our solver. As always, I would encourage you to write your own version of the code alongside me. For reference, you can download a complete version of the notebook in lecture 31, at the end of this section, along with the 4 csv data files referenced below. As I explain each code-based lecture moving forward - you will need to rely partly on the accompanying commentary text and partly on reading the code and code comments. Doing this, in combination with running the code in your own notebook, will help you keep up with what's happing. If you simply skim-read through each lecture - you're likely to get lost pretty quickly. And of course, don't forget you always have the video version at the top of each lecture. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Import dependencies </Typography>

                  <Typography paragraph className={classes.bodytext}>
                  We start, as usual, by importing the dependencies we'll need for the project. The code comments below are all pretty self-explanatory. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>  

                  <Typography component="h2" className={classes.H2} > The structure and its data </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Before we do any real coding, we need to talk about the structure we'll base our initial code on. We will build our code around the simple 3-bar catenary initially, which we saw in an earlier lecture, Fig. 1.
                  </Typography>               
                                                                   
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Three-bar catenary structure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    This is a very simple structure - by design! It will be much easier to get our code working for something simple like this rather than trying to troubleshoot bugs on a much larger model. The structure and the location of any applied loads will be defined in 4 csv files:
                  </Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}>Vertices.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Edges.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Restraint-Data.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Force-Data.csv</Typography></li>
                  </ol>

                  <Typography paragraph className={classes.bodytext}>
                    Vertices.csv simply contains the x and y coordinate of each node, one coordinate pair on each row. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Edges.csv contains the definition of each cable element. An element is defined by the node numbers at each end of the element. We're assuming here that node numbers start at 1 and increase sequentially as we move from left to right along the catenary. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Restraint-data.csv contains the number of the restained degrees of freedom (DoF) at each node. For example, in this structure, node 1 is restrained in the x and y directed (pinned). So, the first row of the csv file contains numbers 1 and 2, the numbers for the restrained DoF at that node. Similarly, node 4 is also pinned, so the next row in the csv contains numbers 7 and 8 for the DoF at this node. Later, we'll streamline the process of generating all of these files directly from our structural models. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, Force-Data.csv contains the node number, x-DoF index and y-DoF index for each node that will have a load applied to it. In this case, that's nodes 2 and 3. Note that the DoF numbers for these nodes are 3 and 4 for node 2 and 5 and 6 for node 3. But, the index of these DoFs is one less than the DoF numbers - since indexing in Python starts at 0 and not 1. You can think of the index as the position or address these DoFs would have in an array of DoFs, if they were sequentially arranged in the array. We'll specify the axis along which the loads are applied, as well as their magnitude and direction in our code below. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    These files are small enough that you can generate them yourself, or you can download them in lecture 31. If you generate them yourself, make sure to use the file names above. Also, note that the data import code we write will assume these files are located in a folder called <b>data</b>, located in the same folder as your Jupyter Notebook. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Automatic structure & loading data import </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now that we understand how the structure is defined, we need to get this data into our Jupyter Notebook. The data import blocks below essentially all do more or less the same thing. The import for Vertices.csv is probably the simplest case. We start by using <code className={classes.code}>glob</code> to test if the file is present in the data folder. We only try to import the file if it's there - otherwise we print out an error message. It's important to provide an error message because, without this critical data, the code will fail. The actual data import is handled with a single line and uses <code className={classes.code}>genfromtxt</code> from NumPy. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>  
                  
                  <Typography paragraph className={classes.bodytext}>
                    Next, we import Edges.csv. An extra step here is that we're manually re-casting the node numbers as integers - by default, they'll be imported as floats. We're also calculating the number of degrees of freedom for the structure.
                  </Typography>  

                  <CodeBlock>{codeString3}</CodeBlock>  

                  <Typography paragraph className={classes.bodytext}>
                    When importing Restraint-Data.csv, we have a couple of extra steps. In line 4, we're <i>flattening</i> the data structure to be a 1-D array rather than 2D. In line 5, we remove any zeros (our automated process for building this file later may add zeros - this will make more sense later) and convert the NumPy array to a list. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Our DoF numbers start at 1. However, because indexing in Python starts at 0 rather than 1, it will be convenient for us to have the corresponding index for each DoF number - simply the DoF number minus 1. In line 6, we're using a list comprehension (a Python code structure) to generate the list of restrained indices. Finally, we're generating a list of unrestrained DoFs on line 7, again, for convenience later. 
                  </Typography>

                  <CodeBlock>{codeString4}</CodeBlock>  

                  <Typography paragraph className={classes.bodytext}>
                    Our last data import is Force-Data.csv. This is a simpler import than the previous one, but we do need to catch one potential edge case. The code we write later will assume that the data structure that holds our force location data is a 2D array, one row for each force location with two numbers per row. If we apply forces to more than one node, then everything works fine, and the variable below, <code className={classes.code}>forceLocationData</code>, will naturally be a 2D array. However, if we only apply load to a single node, then <code className={classes.code}>forceLocationData</code> will only be a 1D array containing two numbers. So, we need to manually nest this within an array to generate the 2D data structure our later code expects. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we don't do this now, our code will break later on. If this is slightly confusing, delete one of the rows from your csv file and import the force data, then print <code className={classes.code}>forceLocationData</code> and observe the data structure. Repeat this, but comment out lines 5 and 6 and re-examine the data structure. By spotting the difference, you should understand better what we're doing. Just remember to reinstate the deleted row in your csv file when you're finished experimenting. 
                  </Typography>

                  <CodeBlock>{codeString5}</CodeBlock>  

                  <Typography component="h2" className={classes.H2} > Manual data entry</Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can manually define some simulation parameters. Note that we're defining the load axis as <code className={classes.code}>y</code>, meaning the point loads will be vertical. The negative force magnitude, <code className={classes.code}>P</code>, indicates that the forces point down rather than up. For now, we're setting the pre-tension, <code className={classes.code}>P_0</code>, to be zero. Everything else is pretty self-explanatory.
                  </Typography>

                  <CodeBlock>{codeString6}</CodeBlock>  

                  <Typography component="h2" className={classes.H2} > Add point loads to global force vector</Typography>

                  <Typography paragraph className={classes.bodytext}>
                  To apply loads to our structure, we need to build a list of all forces applied to it, we'll call this variable <code className={classes.code}>forceVector</code>. It contains one number for each DoF in the structure. Most of the numbers will be zero, except where a force is applied; so, in our case, a force is applied in the direction of the vertical DoF of nodes 2 and 3. The corresponding DoF numbers are 4 and 6. However, these DoFs have indices 3 and 5. We imported the index numbers of the forced vertices above and stored the data on <code className={classes.code}>forceLocationData</code>. We're unpacking this data in lines 5 and 6 below and using these indices to overwrite the default force value within <code className={classes.code}>forceVector</code>, at the appropriate locations. The end result should be a <code className={classes.code}>forceVector</code> that looks like this,
                  </Typography>  

                  <MathJax.Node formula={eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    You can run the code and print <code className={classes.code}>forceVector</code> to confirm this.
                  </Typography>

                  <CodeBlock>{codeString7}</CodeBlock>  

                  <Typography component="h2" className={classes.H2} > Calculate initial length for each member based on initial position </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The final task in this lecture is to calculate and store the length of each member. This is done by looping through each member in a <code className={classes.code}>for</code> loop, extracting the coordinates of the nodes that define the member and calculating the distance between the nodes. Finally, we store each new length for use later on. 
                  </Typography>

                  <CodeBlock>{codeString8}</CodeBlock>  

                  <Typography paragraph className={classes.bodytext}>
                    That wraps up this lecture. In the next lecture, we'll move on to plotting the structure.
                  </Typography>                      
                                                  
                </Grid>
                
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_19));
