import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-13/img1.png";
import img2 from "../../../images/Lectures/C17-13/img2.png";
import img3 from "../../../images/Lectures/C17-13/img3.png";
import img4 from "../../../images/Lectures/C17-13/img4.png";
import img5 from "../../../images/Lectures/C17-13/img5.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
l_p = \\frac{X_B-X_A}{L_o}
\\end{equation}`

const eqn2 = `
\\begin{equation}
\\tag{2}
m_p = \\frac{Y_B-Y_A}{L_o}
\\end{equation}`

const eqn3 = `
\\begin{equation}
\\tag{3}
L_o = \\sqrt{(X_B-X_A)^2 + (Y_B-Y_A)^2}
\\end{equation}`

const eqn4 = `
\\begin{equation}
\\tag{4}
\\cos A = -\\cos(180-A)
\\end{equation}`

const eqn5 = `
\\begin{equation}
\\tag{5}
\\cos l_q = -\\cos(180-l_q)
\\end{equation}`

const eqn6 = `
\\begin{equation}
\\tag{6}
\\cos l_q = -\\cos(m_p)
\\end{equation}`

const eqn7 = `
\\begin{equation}
\\tag{7}
l_q = -m_p
\\end{equation}`

const eqn8 = `
\\begin{equation}
\\tag{8}
\\boxed{
[T] = \\begin{bmatrix}
-l_p & -m_p & l_p & m_p\\\\
-l_q & -m_q & l_q & m_q
\\end{bmatrix}
}
\\end{equation}`

const eqn9 = `
\\begin{equation}
\\tag{9}
[L] = \\begin{bmatrix}
F_{x,a}\\\\
F_{y,a}\\\\
F_{x,b}\\\\
F_{y,b}
\\end{bmatrix}
\\end{equation}`

const eqn10 = `
\\begin{equation}
\\tag{10}
[X] = \\begin{bmatrix}
x_{a}\\\\
y_{a}\\\\
x_{b}\\\\
y_{b}
\\end{bmatrix}
\\end{equation}`

const eqn11 = `
\\begin{equation}
\\tag{11}
[R] = \\begin{bmatrix}
R\\\\
S\\\\
\\end{bmatrix}
\\end{equation}`

const eqn12 = `
\\begin{equation}
\\tag{12}
[U] = \\begin{bmatrix}
u\\\\
v\\\\
\\end{bmatrix}
\\end{equation}`

const eqn13 = `
\\begin{align}
\\tag{13}
[U] &= [T][X]\\\\
\\begin{bmatrix}
u\\\\
v
\\end{bmatrix} &=
\\begin{bmatrix}
-l_p & -m_p & l_p & m_p\\\\
-l_q & -m_q & l_q & m_q
\\end{bmatrix}
\\begin{bmatrix}
x_{a}\\\\
y_{a}\\\\
x_{b}\\\\
y_{b}
\\end{bmatrix}
\\end{align}`

const eqn14 = `
\\begin{align}
\\tag{14}
[L] &=[T]^T[R] \\\\\\\\
\\begin{bmatrix}
F_{x,a}\\\\
F_{y,a}\\\\
F_{x,b}\\\\
F_{y,b}
\\end{bmatrix} &=
\\begin{bmatrix}
-l_p & -l_q\\\\
-m_p & -m_q\\\\
l_p & l_q\\\\
m_p & m_q
\\end{bmatrix}
\\begin{bmatrix}
R\\\\
S
\\end{bmatrix}
\\end{align}`




class Lecture_17_13 extends React.Component {
  state={
    course: 17,
    lecture: 13, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    Our objective in this lecture is to build the transformation matrix. We’ll use this as a bridge between forces and displacements in a local and global reference frame. Consider the planar element <MathJax.Node inline formula={"AB"} /> in a 2D global reference frame, with local axis vectors <MathJax.Node inline formula={"P"} /> and <MathJax.Node inline formula={"Q"} />. Fig 1.
                  </Typography>                   
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Planar element <MathJax.Node inline formula={"AB"} /> in a global reference frame.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    The transformation matrix can be constructed by combining the direction cosines for the local axes. The direction cosines for the <MathJax.Node inline formula={"P"} /> axis are <MathJax.Node inline formula={"l_p"} /> and <MathJax.Node inline formula={"m_p"} />, Fig. 2, and are defined as, 
                  </Typography>

                  <MathJax.Node formula={eqn1} className={classes.formula}/>

                  <MathJax.Node formula={eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"L_o"} /> is the length of the element <MathJax.Node inline formula={"AB"} />,
                  </Typography>

                  <MathJax.Node formula={eqn3} className={classes.formula}/>                                                       
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Direction cosines for the local <MathJax.Node inline formula={"P"} /> axis.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can define the direction cosines for the <MathJax.Node inline formula={"Q"} /> axis, <MathJax.Node inline formula={"l_q"} /> and <MathJax.Node inline formula={"m_q"} />, Fig 3. 
                  </Typography>                                    
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Direction cosines for the local <MathJax.Node inline formula={"Q"} /> axis.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    We can see by inspection that <MathJax.Node inline formula={"m_q = l_p"} />. We can also determine, albeit with a little more effort, that <MathJax.Node inline formula={"l_q=-m_p"} />. To demonstrate this, start with the trigonometric identity,
                  </Typography>

                  <MathJax.Node formula={eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Replacing <MathJax.Node inline formula={"A"} /> with <MathJax.Node inline formula={"l_q"} />,
                  </Typography>

                  <MathJax.Node formula={eqn5} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Since <MathJax.Node inline formula={"180-l_q = m_p"} />, we have,
                  </Typography>

                  <MathJax.Node formula={eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Therefore, 
                  </Typography>

                  <MathJax.Node formula={eqn7} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The direction cosines are now combined to form the transformation matrix, <MathJax.Node inline formula={"[T]"} />,
                  </Typography>

                  <MathJax.Node formula={eqn8} className={classes.formula}/>

                  <Typography component="h2" className={classes.H2} > Forces and displacements in a global reference frame </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                  Now that we have a transformation matrix, we'll briefly review its role in transforming forces and displacements between local and global reference frames. Fig. 4 shows the nodal forces and displacements represented in a global reference frame. 
                  </Typography>                                          
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Nodal forces (left) and displacements (right) represented in a global reference frame.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    The vector of <b>global forces</b>, <MathJax.Node inline formula={"[L]"} />, is given by,
                  </Typography>

                  <MathJax.Node formula={eqn9} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The vector of <b>global displacements</b>, <MathJax.Node inline formula={"[X]"} />, is given by,
                  </Typography>

                  <MathJax.Node formula={eqn10} className={classes.formula}/>

                  <Typography component="h2" className={classes.H2} > Forces and displacements in a local reference frame </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The nodal forces and displacements in a local reference frame are shown in Fig. 5.
                  </Typography>                                         
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img5} />                  
                  <figcaption className={classes.caption}>Fig 5. Nodal forces (left) and displacements (right) represented in a local reference frame.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    The vector of <b>local forces</b>, <MathJax.Node inline formula={"[R]"} />, is given by,
                  </Typography>

                  <MathJax.Node formula={eqn11} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The local displacement vector, <MathJax.Node inline formula={"[U]"} />, is given by,
                  </Typography>
                  
                  <MathJax.Node formula={eqn12} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We can use the transformation matrix to convert the vector of global displacements into local displacements as follows,
                  </Typography>

                  <MathJax.Node formula={eqn13} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In the case of force data, a conversion can also be made between local and global reference frames,
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    <MathJax.Node formula={eqn14} className={classes.formula}/>
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now that we've extablished the transformation matrix, we can leave it to one side and focus our attention on the local  element stiffness matrix in the next lecture. 
                  </Typography>                                     
                              
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_13));
