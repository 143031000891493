import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-46/img1.jpg";
import img2 from "../../../images/Lectures/C17-46/img2.jpg";
import img3 from "../../../images/Lectures/C17-46/img3.jpg";
import img4 from "../../../images/Lectures/C17-46/img4.jpg";
import img5 from "../../../images/Lectures/C17-46/img5.jpg";
import img6 from "../../../images/Lectures/C17-46/img6.jpg";
import img7 from "../../../images/Lectures/C17-46/img7.jpg";
import img8 from "../../../images/Lectures/C17-46/img8.jpg";
import img9 from "../../../images/Lectures/C17-46/img9.jpg";
import img10 from "../../../images/Lectures/C17-46/img10.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_46 extends React.Component {
  state={
    course: 17,
    lecture: 46, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll use Blender's physics simulation tools to simulate some initial catenary geometry. This can then be used as a starting point for our structural simulation. We’ll focus in this lecture on the details of the physics simulation rather than the basic modelling operations in Blender. If you need a primer on operating inside Blender, I've included an <i>Introduction to Blender</i> appendix at the end of the course.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Since much of this lecture involves moving and interacting with the Blender interface, you’ll get a much better understanding of each step by watching the video lecture. This text lecture will just waymark the key points in the process.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    After opening a fresh Blender scene, we'll start by deleting everything and orientating our viewport so that we have an orthographic view of the x-y plane. This is the plane that we’ll model our 2D structure on. We'll add a mesh plane and merge all of the vertices. With this vertex selected, we'll extrude it a distance of <MathJax.Node inline formula={"24\\:m"} /> along the x-axis. We now have a mesh that consists of 2 vertices and 1 edge, Fig 1.
                  </Typography>                  
                                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Initial mesh structure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Next, we'll subdivide this single edge by making <MathJax.Node inline formula={"23"} /> cuts using the subdivide menu option, Fig 2. These <MathJax.Node inline formula={"24"} /> segments will be the basis of our catenary. 
                  </Typography>                          
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Multi-segment mesh that will be used as the basis of a catenary.</figcaption>
                </figure>                

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography component="h2" className={classes.H2} > Basic cloth physics simulation </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    To deform the base mesh into a catenary shape, we use Blender's cloth simulation toolbox. This is a physics engine that can simulate the response of a mesh to gravity. As the name suggests, it's more typically used to simulate cloth or fabric in Blender. But we can also use it to simulate the draped or hung shape of our cables. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the case of this simple cable, we don't necessarily need to use a physics engine to determine the catenary shape. We could simply use the equation we derived back in section 2. However, this simple example will help to demonstrate key features of the simulation approach. The ability to quickly simulate the catenary geometry of a cablenet will also be very helpful as we model more and more complex structures. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To simulate a hanging cable, we start by specifying the locations of the supports or pins. We do this by creating a vertex group and adding the selected vertices to it, Fig 3. In our case, the first and last nodes in the chain will be the pin locations.
                  </Typography>
                            
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. First and last verticies added to a Vertex Group (panel on the right) called <i>Pins</i>.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                  With the pin locations defined, we can now add a <i>Cloth modifier</i> in the modifiers stack, Fig 4. 
                  </Typography>                                 
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Cloth Modifier added to the modifier stack.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The settings for the physics simulation are controlled from the physics tab on the right-hand panel, Fig 5. Within this control panel we need to navigate to the <i>Shape</i> section and select our previously defined <i>Pins</i> vertex group. This tells the physics engine which vertices to treat as fixed during the simulation. 
                  </Typography>                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img5} />                  
                  <figcaption className={classes.caption}>Fig 5. Physics simulation control panel (inset).</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Before we start the physics simulation, we need to change the default direction for gravity from z to y. We can do this in the scene properties section of the control panel on the right, Fig 6.
                  </Typography>                                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img6} />                  
                  <figcaption className={classes.caption}>Fig 6. Gravity settings in the scene properties panel.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Pressing the play button on the timeline will now start the physics simulation. You will see the cable fall under gravity, and after several seconds it will come to rest, having taken up the familiar catenary shape, Fig 7. 
                  </Typography>                                 
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img7} />                  
                  <figcaption className={classes.caption}>Fig 7. Simulated catenary shape.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    As you can see, a cloth physics simulation in Blender is extremely easy to set up and complete. This opens up a whole range of modelling possibilities, as we'll see in the 3D cablenet analysis course that follows this one. As you can see from the control panel, there is a large number of simulation parameters we can alter. The one that has by far the biggest impact on the final shape of the catenary is the Vertex Mass in the Physical Properties section. Increasing this will obviously cause the catenary to sag more. If we increase this to <MathJax.Node inline formula={"2.5 \\:kg"} />, we see that the maximum sag of the cable increases to approximately <MathJax.Node inline formula={"3\\:m"} />, Fig 8.
                  </Typography> 
                       
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img8} />                  
                  <figcaption className={classes.caption}>Fig 8. Simulated catenary shape with increased Vertex Mass.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     

                  <Typography component="h2" className={classes.H2} > Modelling a complete structure </Typography>             

                  <Typography paragraph className={classes.bodytext}>
                    This simulated cable shape will now be used as part of a larger model that we'll continue analysing in the next section of the course. To use the simulated geometry, we must first apply the modifier, which <i>bakes in</i> the geometry and removes the ability to perform any further simulation. The modifier can be applied from the modifiers stack. Since this step cannot be reversed, it makes sense to duplicate the cable before applying the physics modifier. This way, we can always return to this stage in the modelling process. 
                  </Typography>                                      
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img9} />                  
                  <figcaption className={classes.caption}>Fig 9. Applying the Cloth Modifier to the cable.</figcaption>
                </figure>


                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    After applying the modifier to the copied version of the cable, it now takes up the deformed catenary shape, even in edit mode. From here, we can implement basic Blender modelling operations; extrude, duplicate, scale etc., to generate a larger model. We'll model a simple suspension structure consisting of a trussed deck suspended by hander cables from the cable we've just simulated. To follow the detailed modelling steps, you should watch the video lecture above. We'll just present an image of the final result here, Fig 10. The specific geometry of the final structure is not critical - the important takeaway is how we were able to use simulation to generate the catenary shape of the main suspension cable.
                  </Typography>                  
                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img10} />                  
                  <figcaption className={classes.caption}>Fig 10. Suspended truss deck model.</figcaption>
                </figure>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In the following lectures, we'll write some simple utility scripts that allow us to export the data defining this structure. These scripts will generate the csv files we can import into our Jupyter Notebook. If you've only read through this summary lecture, I encourage you to watch the video before moving on. Blender may initially seem like a distraction from the main focus of the course, but having a way to quickly simulate and model structural geometry, really will be a huge asset to you when generating more complex structural models. 
                  </Typography>                  
                                 
                </Grid> 
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_46));
