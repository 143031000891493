import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-17/img1.png";
import img2 from "../../../images/Lectures/C19-17/img2.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString =``

const codeString1 = `import math #...........................Math functionality
import numpy as np #....................Numpy for working with arrays
import matplotlib.pyplot as plt #Plotting functionality 

#Constants
fck = 30 #(N/mm^2) Characteristic compressive strength of concrete
fyk = 500 #(N/mm^2) Characteristic yield strength of steel reinforcement
As = 6430 #(mm^2) Area of steel
b = 400 #(N/mm^2) Width of the beam
d = 650 #(mm) Effective depth
ep_cu2 = 0.0035 #Maximum strain for concrete
E = 200000 #(N/mm^2) Young's modulus for steel reinforcement`

const codeString2 =`#Build an array of possible neutral axis depth values
fac = np.arange(0.617,0.79,0.00001)
X = d*fac`

const codeString3 =`#Determine the difference in concrete and steel forces for all values of neutral axis
deltaF = np.array([]) #Empty array to hold all force difference values
for i, x in enumerate(X):
    F_C = 0.567*fck*b*0.8*x #(N) Force in the concrete
    ep_st = ep_cu2*((d-x)/x) # Strain in the steel
    sig_st = E*ep_st #(N/mm^2) Stress in the steel
    F_S = 0.87*sig_st*As #(N) Force in the steel
    deltaF = np.append(deltaF,F_C-F_S) #Save the difference in concrete and steel forces`

const codeString4 =`#Identify the value of neutral axis at which the force difference is a minimum
for i in np.arange(0,len(deltaF)-1):            
    if deltaF[i]<0 and deltaF[i+1]>0:
        print(f'The required value of NA depth is : {round(fac[i]*d,2)} mm')
        Fac = fac[i]
    elif deltaF[i]>0 and deltaF[i+1]<0:
        print(f'The required value of NA depth is : {round(fac[i]*d,2)} mm')        
        Fac = fac[i]`

const codeString5 =`# Plot the results
fig = plt.figure() 
axes = fig.add_axes([0.1,0.1,2,1]) 
axes.plot(fac*d,deltaF,'-')
axes.plot([Fac*d, Fac*d],[deltaF.min(), deltaF.max()],'--r', lw=0.75)
axes.plot([fac.min()*d, fac.max()*d],[0, 0],'--r', lw=0.75)
axes.set_xlim([fac[0]*d, fac[-1]*d])
axes.set_xlabel('x (mm)')
axes.set_ylabel('Force difference (N)')
axes.set_title('Graphical solution')
axes.grid()`



const Eqn1 = `\\begin{align}
F_S &= 0.87\\:f_{yk}\\: A_s\\\\\\\\
F_S &= 0.87\\times 500 \\times 6430\\\\\\\\
F_S &= 2797.05\\:kN
\\end{align}`

const Eqn2 = `\\begin{equation}
F_C = 0.567\\:f_{ck}\\:0.8x\\:b = 2,797,050\\\\\\\\
\\end{equation}`

const Eqn3 = `\\begin{align}
F_C&= 0.567\\: f_{ck}\\: b\\: 0.8x\\\\\\\\
\\overbrace{F_C}^{\\text{?}} &= 0.567\\times 30 \\times 400\\times 0.8\\times \\overbrace{x}^{\\text{?}}
\\end{align}`

const Eqn4 = `\\begin{align}
x=&0.62\\:d\\\\\\\\
&= 0.62\\times 650\\\\\\\\
&=403\\:mm
\\end{align}`

const Eqn5 = `\\begin{align}
F_C&= 0.567\\: f_{ck}\\: b\\: 0.8x\\\\\\\\
 &= 0.567\\times 30 \\times 400\\times 0.8\\times 403\\\\\\\\
 &= 2193.61\\:kN
\\end{align}`

const Eqn6 = `\\begin{align}
\\frac{\\epsilon_{st}}{d-x} &= \\frac{0.0035}{x}\\\\\\\\
\\epsilon_{st} &= 0.0035\\left(\\frac{650-403}{403}\\right)\\\\\\\\
\\epsilon_{st} &=0.002145
\\end{align}`

const Eqn7 = `\\begin{align}
f_{st} &= E\\times \\epsilon_{st}\\\\\\\\
f_{st} &= (200\\times 10^3)(0.002145)\\\\\\\\
f_{st} &= 429\\:N/mm^2
\\end{align}`

const Eqn8 = `\\begin{align}
F_S = 0.87\\:f_{st}\\:A_s\\\\\\\\
F_S = 0.87\\times 429 \\times 6430\\\\\\\\
F_S = 2399.9\\:kN
\\end{align}`

const Eqn9 = `\\begin{align}
x &= 0.635\\:d\\\\\\\\
x &= 412.8\\:mm
\\end{align}`

const Eqn10 = `\\begin{align}
F_C&= 0.567\\: f_{ck}\\: b\\: 0.8x\\\\\\\\
 &= 0.567\\times 30 \\times 400\\times 0.8\\times 412.8\\\\\\\\
 &= 2246.95\\:kN
\\end{align}`

const Eqn11 = `\\begin{align}
\\epsilon_{st} &= 0.0035\\left(\\frac{650-412.8}{412.8}\\right)\\\\\\\\
\\epsilon_{st} &= 0.002011
\\end{align}`

const Eqn12 = `\\begin{align}
F_S = 0.87\\times f_{st}\\times A_s\\\\\\\\
F_S = 2249.95\\:kN
\\end{align}`

const Eqn13 = `\\begin{align}
z &= d-0.4x\\\\\\\\
z &=650-0.4\\times 412.8\\\\\\\\
x &= 484.9\\:mm
\\end{align}`

const Eqn14 = `\\begin{align}
M_{Rd} &= 2248.45\\times 0.4849\\\\\\\\
&= 1090.3\\:kNm
\\end{align}`

const Eqn15 = `\\begin{align}
M_{Rd,max} &= 0.167\\: f_{ck}\\:bd^2\\\\\\\\
&= 0.167\\times 30 \\times 400\\times 650^2\\\\\\\\
&= 846.7\\:kNm
\\end{align}`

const Eqn16 = ``

const Eqn17 = ``

const Eqn18 = ``

const Eqn19 = ``

const Eqn20 = ``


class Lecture_19_17 extends React.Component {
  state={
    course: 19,
    lecture: 17, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >             
                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>     
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this example, let's imagine the task is to determine the design moment of resistance for a pre-existing beam. I say pre-existing because we would never set out to design an over-reinforced beam. Nevertheless, it's an interesting exercise to determine the moment of resistance. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can assume the same beam dimensions and material properties as the last example, except this time, the beam is reinforced with <MathJax.Node inline formula={"8"} /> H32 bars, giving an area of steel, <MathJax.Node inline formula={"A_s = 6430\\:mm^2"} />. We'll also assume at the outset that we don't actually know the beam is over-reinforced - but this will soon become apparent through our analysis. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    <b>Before working through the solution below</b>, pause here. Minimise the broswer tab and start trying to work out the solution yourself on a blank sheet of paper. Based on what we've already covered, you should have a good chance of finding the solution or at least highlighting the main challenge we're about to run into. You'll get a lot more from this exercise if you've tried to solve this yourself first!
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>

                  <Typography component="h3" className={classes.H3} > 2.1 Is the beam over-reinforced? </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the previous example, we assumed that the beam was under-reinforced and that the steel failed first. So, this seems like a logical place to start, since we only <i>suspect</i> at this stage that the beam might be over-reinforced. We can determine the force in the steel as follows.
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Equating this to the force in the concrete,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    and solving for the depth of the neutral axis, <MathJax.Node inline formula={"x"} />, we get, <MathJax.Node inline formula={"x=514\\:mm"} />, or <MathJax.Node inline formula={"0.79\\:d"} />. So, in other words, we need a neutral axis depth of <MathJax.Node inline formula={"0.79\\:d"} />, if the force in the concrete is to balance the force in the steel at the instant the steel fails. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    But we know that EC2 tells us we must limit the depth of the neutral axis to <MathJax.Node inline formula={"0.45\\:d"} /> to ensure sufficient rotation of the section can take place to allow moment redistribution in the structure at failure. So straight away we know this beam is contravening the code. Let's put this code-based limit to one side and focus on the underlying material and mechanical behaviour. We know from our earlier discussion of balanced section design, that for a simultaneous failure of the steel and concrete, the neutral axis would be at a depth of <MathJax.Node inline formula={"0.617\\:d"} />.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Since basic geometry tells us that our neutral axis is below this when the steel fails, Fig 1, we can see that the strain in the concrete is beyond that which would cause crushing. So, we conclude that when the steel theoretically fails, the concrete compression block would have already crushed, leading to a sudden brittle failure of the cross-section. There is too much steel in the section and it's over-reinforced.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Strain distribution at failure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     

                  <Typography component="h3" className={classes.H3} > 2.2 What is the over-reinforced moment capacity? </Typography>            

                  <Typography paragraph className={classes.bodytext}>
                    Once we know that the concrete compression block fails first, the next question is what is the applied moment that causes this failure - the over-reinforced moment capacity? This is where we run into a problem. Consider the unknown force in the concrete, 
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The problem quickly becomes apparent; we don’t know the force in the concrete compression block at failure and we don't know the corresponding depth of the neutral axis. This also means that we can't compute the lever-arm since we need to know the depth of the compression block to calculate this. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We avoided this problem with the previous under-reinforced beam because we were able to assume and confirm that the steel had yielded first and so we could compute the force in the steel, which was equal to the force in the concrete, allowing us to solve for <MathJax.Node inline formula={"x"} />. Now, all we know is that the force in the concrete (and steel) is some value less than <MathJax.Node inline formula={"2797.05\\:kN"} />. So, are we stuck?
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    No, not quite. We can use brute force to determine the force in the concrete at failure. Let's recap what we know:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>For equilibrium to be maintained, the forces acting on the cross-section must be equal, so <MathJax.Node inline formula={"F_C=F_S"} />.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Both forces must be less than <MathJax.Node inline formula={"2797.05\\:kN"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>We know the range in which the neutral axis must fall, <MathJax.Node inline formula={"0.617\\:d<x<0.79\\:d"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>We know the concrete fails before the steel, so we know the strain on the upper surface of the cross-section.</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    So, we can use trial and error to identify the correct value of <MathJax.Node inline formula={"x"} />, within the specified range, that yields force equilibrium between the force in the steel and the force in the concrete at the instant the concrete compression block fails. To demonstrate this, let's naively assume <MathJax.Node inline formula={"x=0.62\\:d"} />, therefore,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can calculate the force on the concrete at failure, <MathJax.Node inline formula={"F_C"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    With our assumption for <MathJax.Node inline formula={"x"} /> and that the concrete is at the point of failure, we can use geometry to determine the corresponding strain in the steel, <MathJax.Node inline formula={"\\epsilon_{st}"} /> (see Fig 2),
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Note that, as expected, this is less than the yield strain for the steel. Now we can convert this to a steel stress, <MathJax.Node inline formula={"f_{st}"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can compute the force in the steel and compare it to the force in the concrete, 
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since the force in the steel is clearly not equal to the force in the concrete, we know that it is not a valid configuration for the cross-section and we've selected the wrong value for neutral axis depth, <MathJax.Node inline formula={"x"} />. 
                  </Typography>  

                  <Typography component="h3" className={classes.H3} > 2.3 Trial and error </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    At this point, we have a few options. We could continue trialling values by hand and eventually, zero in on the correct one or we could let the computer do the number crunching for us. Naturally, we'll go for the second option! There are a number of different approaches to solving this with a computer. For a simple problem like this, the easiest option is to use a function like Goal Seek in Excel. This will be demonstrated in the next lecture. We could also write a simple Python script to identify the correct value of <MathJax.Node inline formula={"x"} />. I'll write and explain such a script in the next lecture. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    For now, let's use the correct value of <MathJax.Node inline formula={"x"} /> obtained from Excel's Goal Seek function. It turns out that the value that yields force equilibrium in the cross-section is,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, the force in the concrete is,
                  </Typography>   

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The corresponding strain in the steel is, 
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The stress in the steel is <MathJax.Node inline formula={"f_{st}=200\\times 10^3\\times 0.002011 = 402.2\\:N/mm^2"} />. We can now confirm the force in the steel as,
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We can see that the force in the steel is approximately equal to the force in the concrete (ignoring rounding errors). For the purposes of calculating a moment of resistance, we can take the average of the two force values, <MathJax.Node inline formula={"0.5\\times(2246.95+2249.95) = 2248.45\\:kN"} />. 
                  </Typography>   

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can calculate the lever arm, <MathJax.Node inline formula={"z"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn13} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, the moment of resistance can be calculated as,
                  </Typography>

                  <MathJax.Node formula={Eqn14} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Recall that the maximum moment of resistance that could be developed for this cross-section size, while observing the <MathJax.Node inline formula={"0.45\\:d"} /> limit on neutral axis depth was, 
                  </Typography>  

                  <MathJax.Node formula={Eqn15} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, by packing in more steel, the beam achieves a higher moment of resistance but as we've already mentioned, this comes at the cost of ductility. If the beam does now fail, this failure will give no warning signs (large deflections and visible cracking) and as a result, could be dangerous. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As we've seen, this stems from the fact that the concrete compression block simply can't match the force generated in the tension steel. This might lead you to ask yourself - can we somehow strengthen the compression block so that more force can be developed, safely unlocking the extra moment capacity? Well, the answer is yes - we could add compression reinforcement to the compression block. Such sections are referred to as <b>doubly reinforced</b> and we'll discuss them soon. 
                  </Typography>   

                  
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_17));
