import { DELETE_3DTRUSS, FETCH_3DTRUSS } from "../actions/types";

export default function(state = {}, action) {
	switch (action.type) {
		case FETCH_3DTRUSS:
			return action.payload; //returns user object or false    
    case DELETE_3DTRUSS:
      return {}
		default:
			return state;
	}
}