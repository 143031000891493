import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_2 extends React.Component {
  state={
    course: 19,
    lecture: 2, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    As the course title suggests, this course will very much focus on the fundamental behaviour of reinforced concrete. As such, it’s written as a first introduction to the design of reinforced concrete. It will be most suited to anyone who has not yet been introduced to the topic or anyone looking for a refresher in the fundamental mechanics and design practices that apply to reinforced concrete. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Undergraduate civil and structural engineering students or professional engineers from other disciplines are likely to derive the most value from this course. Engineers routinely designing reinforced concrete structures professionally are always welcome but are not likely to cover new ground here. Subsequent courses will develop on what we cover here and explore more complex topics in reinforced concrete design.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    It is also worth mentioning that this course is written from the perspective of someone designing to Eurocodes and specifically Eurocode 2. This naturally reduces the relevance of this course to you if you do not use or intend to use Eurocodes. If you design to another set of standards, I believe you will still find this course interesting and helpful, but undoubtedly less so than a Eurocode user.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, I’ll briefly comment here that this course also uses Python to build scripts to automate much of the manual calculation work involved in routine design. If you are already a proficient reinforced concrete designer or design to alternative codes of practice, then the Python elements of the course may yet justify you completing it. The role of Python will be briefly discussed in the following lecture.
                  </Typography>                                
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_2));
