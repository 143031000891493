import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1=
`#🚨 MANDATORY IMPORT: cable definitions
if glob('data/Cables.csv'): 
    cables = genfromtxt('data/Cables.csv', delimiter=',') 
    print('3. 🟢 Cables.csv imported')
else: 
    cables = []
    print('3. 🛑 STOP: Cables.csv not found')`

const codeString2=
`#Constants
E = 70*10**9 #(N/m^2)
swt = True #Take self-weight into account 

areaCable = 0.000002 #🚨 (m^2) 
areaBar = 0.002 #🚨 (m^2)
gammaCable = 100 #🚨 (kg/m) Cable mass per unit length
gammaBar = 50 #🚨 (kg/m) Bar mass per unit length

preTension = 1 #Pre-tension applied to all cable elements
P = 0 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

nForceIncrements = 1000 #Initial number of force increments for applied loading
convThreshold = 10 #(N) Threshold on maximum value of F_inequilibrium
itLimit = 10000 #Maximum no. iterations allowed to achieve convergence`

const codeString3=
`memberType = [] #Initialise a list to hold the member types (cable or bar)
P0 = np.zeros([len(members)]) #Initialise an array to hold member pre-tension
Areas = np.zeros([len(members)]) #An array to hold area for each member
gamma = np.zeros([len(members)]) #An array to hold mass per unit length for each member

for n,m in enumerate(members):   
    #Initially assume all members are bars 
    memberType.append('b')
    Areas[n] = areaBar
    gamma[n] = gammaBar
        
    #Check if member is a cable
    for c in cables:         
        if(m[0] in c and m[1] in c):
            memberType[n] = 'c' 
            P0[n] = preTension
            Areas[n] = areaCable
            gamma[n] = gammaCable`

const codeString4=
`if(swt):
    SW_at_supports = np.empty((0,2))
    for n, mbr in enumerate(members):  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member
        length = lengths[n]     
        sw = length*gamma[n]*9.81 #🚨(N) Self-weight of the member
        F_node = sw/2 #(N) Self-weight distributed into each node  
        iy = 2*node_i-1 #index of y-DoF for node i
        jy = 2*node_j-1 #index of y-DoF for node j    
        forceVector[iy] = forceVector[iy]-F_node
        forceVector[jy] = forceVector[jy]-F_node  
        
        #Check if SW needs to be directly added to supports 
        if(iy+1 in restrainedDoF):
            supportSW = np.array([iy, F_node])
            SW_at_supports = np.append(SW_at_supports, [supportSW], axis=0)
        if(jy+1 in restrainedDoF):
            supportSW = np.array([jy, F_node])
            SW_at_supports = np.append(SW_at_supports, [supportSW], axis=0) added`

const codeString5=
`#Plot members
for n, mbr in enumerate(members):   
    node_i = mbr[0] #Node number for node i of this member
    node_j = mbr[1] #Node number for node j of this member                

    ix = nodes[node_i-1,0] #x-coord of node i of this member
    iy = nodes[node_i-1,1] #y-coord of node i of this member
    jx = nodes[node_j-1,0] #x-coord of node j of this member
    jy = nodes[node_j-1,1] #y-coord of node j of this member        

    #Index of DoF for this member
    ia = 2*node_i-2 #horizontal DoF at node i of this member 
    ib = 2*node_i-1 #vertical DoF at node i of this member
    ja = 2*node_j-2 #horizontal DoF at node j of this member
    jb = 2*node_j-1 #vertical DoF at node j of this member

    #🚨 Use different colors for cable and bar elements
    if memberType[n]=='c':
        axes.plot([ix,jx],[iy,jy],color='#33cc99',linestyle='-',lw=2) #Member
    else:
        axes.plot([ix,jx],[iy,jy],color='b',linestyle='-',lw=2) #Member`


class Lecture_17_52 extends React.Component {
  state={
    course: 17,
    lecture: 52, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Our first task in this section is to modify our solver code to accommodate two different types of elements, bars and cables, as discussed in the previous section. We'll start with the notebook and structure data we finished section 7 with, the 12-bar catenary. Remember that you can download the Jupyter Notebook for this lecture in the resource panel. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Importing cable definitions </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the last section we generated a new csv file containing the definitions of all cables, <i>Cables.csv</i>. Although we won't actually be importing a Cables.csv file into the current notebook (that will happen in the next lecture), we'll amend the code as if we were. The import is handled in our main data import block, in the exact same way we imported the <i>Edges.csv</i>.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography component="h2" className={classes.H2} > Updating the simulation parameters </Typography>

                  

                  <Typography paragraph className={classes.bodytext}>
                    We now need to differentiate between the area and self-weight of cable and bar elements. So, we'll introduce separate variables for each in the manual data entry block. The updates are highlighted with 🚨 as usual. Note also that we've added a new variable, <code className={classes.code}>itLimit=10000</code>. This will control the maximum number of iterations our main <code className={classes.code}>while</code> loop will do. We hardcoded this value previously, but it makes more sense to set it in the data entry block. Remember to actually use this variable in the main <code className={classes.code}>while</code> loop rather than the hardcoded value we've used up to this point. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>
                  

                  <Typography component="h2" className={classes.H2} > Build the member definition array and member pre-tension array </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we need to build an array that records whether each member is a cable element or a bar element. Also, up to this point, we've assigned the same self-weight to each element and the same pre-tension. Now, we want to assign these properties based on the element type. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To do this, we iterate through each member and automatically assign the bar properties. Then within the same loop iteration, we can check if the element is a cable by checking if the nodes that define it exist in the list of cable definitions. If the member happens to be a cable, we'll overwrite the properties with the cable properties defined in the data entry section above. This block of code can be placed immediately below the manual data entry section.
                  </Typography>
                  
                  <CodeBlock>{codeString3}</CodeBlock>
                  

                  <Typography component="h2" className={classes.H2} > Updating self-weight force calculation </Typography>    

                  <Typography paragraph className={classes.bodytext}>
                    This is an easy update; we just need to change the self-weight force calculation to use the correct self-weight for each member, since now there are two different member types. This only required a single update on line 7 below.
                  </Typography>

                  <CodeBlock>{codeString4}</CodeBlock>
                  

                  <Typography component="h2" className={classes.H2} > Updating the pre-analysis plot </Typography> 

                  <Typography paragraph className={classes.bodytext}>
                    Another minor update next; we want to plot cable and bar elements using different colours in the pre-analysis plot. This way we can visually confirm that the members are defined correctly before proceeding with the analysis. We can do this by adding an <code className={classes.code}>if</code> condition to the member plotting section within the main <code className={classes.code}>plotStructure()</code> function. We just need to check whether the current member being plotted is a bar or cable. Note that in the code block below, I've only extracted the relevant code from within the parent function rather than repeating the whole plotting function.
                  </Typography>

                  <CodeBlock>{codeString5}</CodeBlock>
                  

                  <Typography paragraph className={classes.bodytext}>
                    This takes care of all of the minor edits we need to make. We'll test the code out on a structure containing both member types, in the next lecture.
                  </Typography>                  
                                                     
                </Grid> 
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_52));
