import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-7/img1.png";
import img2 from "../../../images/Lectures/C19-7/img2.png";
import img3 from "../../../images/Lectures/C19-7/img3.png";
import img4 from "../../../images/Lectures/C19-7/img4.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const Eqn1 = `\\begin{equation}
F_d = \\gamma_G\\:G_k + \\gamma_Q\\:Q_k
\\end{equation}`

const Eqn2 = `\\begin{equation}
E_d = E\\left[\\: \\overbrace{\\sum_{j\\geq 1} \\gamma_{G,j}\\:G_{k,j}}^{\\text{Permanent actions}}\\:\\:+\\:\\: \\overbrace{\\gamma_{Q,1}\\:Q_{k,1}}^{\\text{Leading var action}}\\:\\: +\\:\\: \\overbrace{\\sum_{i \\geq 1} \\gamma_{Q,i}\\: \\psi_{0,i} \\: Q_{k,i}}^{\\text{Accompanying var action}}\\right]
\\end{equation} `

const Eqn3 = `\\begin{align*}
E_d =& (1.35 \\times 35\\: kN/m) + (1.5 \\times 20\\: kN/m) + (0.7 \\times 1.5 \\times 12 \\: kN/m)\\\\\\\\
E_d =& 89.85\\: kN/m
\\end{align*}`

const Eqn4 = `\\begin{align*}
E_{d,A-B} = & (0.9\\: \\times 25\\: kN/m) + (0\\: \\times 10 \\: kN/m)\\\\\\\\
E_{d,A-B} = & 22.5\\:kN/m
\\end{align*}`

const Eqn5 = `\\begin{align*}
E_{d,B-C} = & (1.1\\: \\times 25\\: kN/m) + (1.5\\: \\times 10 \\: kN/m)\\\\\\\\
E_{d,B-C} = & 42.5\\:kN/m
\\end{align*}`

const Eqn6 = `\\begin{align*}
E_{d,max} =& (1.35 \\times \\: 25\\: kN/m) + (1.5\\times 10\\:kN/m) \\\\
E_{d,max} =&48.75\\:kN/m\\\\\\\\
E_{d,min} =& (1.35 \\times \\: 25\\: kN/m) + (0\\times 10\\:kN/m) \\\\
E_{d,min} =& 33.75\\:kN/m
\\end{align*}`

const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_7 extends React.Component {
  state={
    course: 19,
    lecture: 7, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >       
                  <Typography component="h2" className={classes.H2} > 1.0 Partial Factors, Design Actions and Limit States </Typography>           

                  <Typography paragraph className={classes.bodytext}>
                    As we mentioned at the end of the last lecture, in order to turn characteristic values of actions, <MathJax.Node inline formula={"G_k/Q_k"} />, into design values, <MathJax.Node inline formula={"G_d/Q_d"} />, they must be multiplied by partial safety factors, also referred to as load factors. Load factors for permanent and variable actions are denoted by <MathJax.Node inline formula={"\\gamma_G"} /> and <MathJax.Node inline formula={"\\gamma_Q"} />, respectively. Thus, in a straightforward case, the design action, <MathJax.Node inline formula={"F_d"} /> is obtained as:
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The specific values of <MathJax.Node inline formula={"\\gamma_G"} /> and <MathJax.Node inline formula={"\\gamma_Q"} /> used to determine <MathJax.Node inline formula={"F_d"} /> will vary depending on the limit state being evaluated. A limit state is a state beyond which the structure no longer fulfils the relevant design criteria. The distinction is made between ultimate limit states (ULS) and serviceability limit states (SLS). Ultimate limit states are those associated with collapse or other similar forms of structural failure. Ultimate limit states are concerned with the safety of people and/or the structure. Serviceability limit states correspond to conditions of the structure beyond which specified service requirements are no longer met, e.g. deflection or vibration. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Ultimate Limit State Design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The designer's first task is to ensure that their design satisfies ULS requirements. This is then followed by the relevant checks to ensure the structure does not approach any serviceability limit states; in other words, making sure a beam is strong enough to carry the loads without failing requires a ULS design exercise while ensuring that it doesn't deflect beyond a specified limit requires and SLS design. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Remember, a limit state is a condition of the structure that we are trying to avoid; as engineers, we are trying to ensure our structures do not approach these limit states. There are three ultimate limit states to consider:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}><b>EQU:</b> Loss of static equilibrium of the structure (e.g. overturning, sliding, uplift) or any part of it considered as a rigid body. If a relatively minor change to the spatial distribution or magnitude of loads could lead to a loss of equilibrium, the EQU limit state should be checked. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><b>STR:</b> Internal failure or excessive deformation of the structure or structural members. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><b>GEO:</b> Failure or excessive deformation of the ground where the strength of soil or rock is significant in providing resistance. </Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    We <i>check</i> that the structure satisfies a particular limit state by applying that limit state’s partial safety factors to the characteristic loads and assessing the acceptability of the structures' behaviour under the resulting design action. Figure 1 demonstrates which limit states would apply for different design checks for a hypothetical structure.
                  </Typography>                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Examples that illustrate the application of ultimate limit states EQR, STR and GEO. Reproduced from <i>Manual for the design of building structures to Eurocode 1 and Basis of Structural Design</i>, The Institution of Structural Engineers.</figcaption>
                </figure>           

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Having introduced the concept of ultimate and service limit states and also having just identified three ultimate limit states, namely EQU, STR and GEO, we can now directly link these with the partial safety factors discussed above. These are summaries in the table in  Fig 2. Note that different partial factors are used depending on whether the loading is considered favourable or unfavourable. Also, note the use of the combination factor <MathJax.Node inline formula={"\\psi_0"} /> applied to accompanying variable actions.
                  </Typography>                   
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Partial factors and combinations for ultimate limit states EQU, STR and GEO.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The partial factors in Fig. 2 can be utilised in the following expression to combine characteristic actions into a design action or <b>design effect</b>:
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where, <MathJax.Node inline formula={"E_d"} /> stands for design effect, <MathJax.Node inline formula={"E"} /> stands for <i>effect of</i> and <MathJax.Node inline formula={"+"} /> is shorthand for <i>combined with</i>.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > Example 1 </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Let's put all of this into context with a couple of simple examples. We'll start by considering the simply supported beam shown in Fig 3 below. This beam is subject to a uniformly distributed permanent action and two uniformly distributed variable actions. The variable actions are derived from a residential occupancy. The task is to determine the design effect used to test the STR limit state.
                  </Typography>                   
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Simply supported beam subject to permanent and variable actions.</figcaption>
                </figure>


                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We can obtain the appropriate partial factors from the second row of the table in Fig. 2. The worst case (maximum bending moment and shear force) is obtained when the loading on the beam is a maximum. Thus the permanent action can be considered unfavourable, and <MathJax.Node inline formula={"\\gamma_G = 1.35"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    There are two variable actions, and it’s clear that the primary (larger) action is <MathJax.Node inline formula={"q_{k,1}"} /> and the accompanying (smaller) action is <MathJax.Node inline formula={"q_{k,2}"} />. As the loading is derived from residential usage, we can confirm from the table in the previous lecture that the appropriate combination factor is <MathJax.Node inline formula={"\\psi_{0,1} = 0.7"} />. The design effect is, therefore:
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, provided the beam is designed to withstand the internal stresses induced by a uniformly distributed load of <MathJax.Node inline formula={"89.85\\: kN/m"} />, it will have satisfied the STR limit state. Note also that due to the configuration of the beam, no change in the magnitude or position of the loading will lead to rigid body instability; therefore, there is no need to consider the EQU limit state. This is not the case with the following example.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > Example 2 </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now consider the simply supported beam in Fig. 4. This time, the beam has a cantilever overhang which complicates matters slightly. Since uplift could occur at A if the applied force between B and C is sufficiently high, we need to consider both the STR and EQU limit states. 
                  </Typography>                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Simply supported beam with overhang supporting permanent and variable actions.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    By inspection of the structure, we can see that if the loading between B and C is sufficiently large compared to the loading between A and B, and if no provision was made for a <i>holding down</i> reaction at A, a rigid body rotation, clockwise about point B could occur (ignoring any flexure of the beam itself). Thus the most onerous EQU load arrangement will be obtained when the loading between B and C is a maximum and between A and B is a minimum. The actions between A and B are said to be favourable as the larger they are, the less likely rotational instability becomes. Conversely, the actions between B and C are said to be unfavourable.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Referring to the first row in the table (Fig. 2), the design effect between A and B is:
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The design effect between B and C is:
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Provided that rotational instability does not occur under the simultaneous influence of these design effects, the EQU limit state will have been satisfied. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To consider the STR limit state, we must consider the different possible loading arrangements. By inspection, we can identify three cases:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Max load on all spans.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Max load on span A-B, min load on span B-C.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Min load on span A-B, max load on span B-C.</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    Following the same approach as above but referring to row 2 in the table (Fig. 2) this time, the max and min design effects are:
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Each of the three load arrangements above should be evaluated with the maximum shear force and bending moments extracted for design purposes. Notice that the minimum design effect is obtained with a partial factor <MathJax.Node inline formula={"\\gamma_{G} = 1.35"} /> rather than <MathJax.Node inline formula={"\\gamma_{G} = 1.0"} /> as one might expect from the table. This is due to the <b>single source principle</b> within the Eurocode. This principle states that all permanent actions from the same source are assigned the same value of partial factor in any one load combination. Note that the single source principle only applies to STR and GEO limit states and <b>does not</b> apply to EQU limit state.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the case of the example above, because the maximum loading requires <MathJax.Node inline formula={"\\gamma_g = 1.35"} />, the minimum loading must also be obtained using <MathJax.Node inline formula={"\\gamma_g = 1.35"} /> despite the fact that for load arrangement 2, a larger span moment would be obtained if <MathJax.Node inline formula={"\\gamma_g = 1.0"} /> when calculating <MathJax.Node inline formula={"E_{d,min}"} /> applied to span B-C.
                  </Typography>                  
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_7));
