import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-28/img1.png";
import img2 from "../../../images/Lectures/C19-28/img2.png";

const Eqn1 = `\\begin{equation}
l_{eff} = l_n + a_1 + a_2
\\end{equation}`

const Eqn2 = `\\begin{align}
V_{Ef} &= 1169 - 0.2\\:m\\times 90\\:kN/m\\\\\\\\
V_{Ef} &= 1151\\:kN
\\end{align}`

const Eqn3 = `\\begin{align}
v_{Ef} &= \\frac{1151\\times 10^3}{400\\times 0.9\\times 750}\\\\\\\\
v_{Ef} &= 4.26\\:N/mm^2
\\end{align}`

const Eqn4 = `\\begin{align}
V_{Rd,max} &= 0.18\\:b_wd\\left(1-\\frac{f_{ck}}{250}\\right)f_{ck}\\:\\sin 2\\theta\\\\\\\\
V_{Rd,max} &= 0.18\\times 400\\times 750\\left(1-\\frac{30}{250}\\right)\\times 30\\times \\sin 90\\\\\\\\
V_{Rd,max} &= 1425.6\\:kN
\\end{align}`

const Eqn5 = `\\begin{align}
v_{Rd,max} &= \\frac{V_{Rd,max}}{b\\times 0.9d}\\\\\\\\
v_{Rd,max} &= \\frac{1425.6\\times 10^3}{400\\times 0.9\\times 750}\\\\\\\\
v_{Rd,max} &=5.28\\:N/mm^2
\\end{align}`

const Eqn6 = `\\begin{align}
\\theta &= 0.5\\:\\sin^{-1}\\left[\\frac{V_{Ed}}{0.18\\:b_wd\\left(1-\\frac{f_{ck}}{250}\\right)f_{ck}}\\right]\\\\\\\\
\\theta &= 0.5\\sin^{-1}\\left[\\frac{1151\\times 10^3}{0.18\\times 400\\times 750\\times \\left(1-\\frac{30}{250} \\right)30}\\right]\\\\\\\\
\\theta &= 27^{\\circ}
\\end{align} `

const Eqn7 = `\\begin{align}
V_{Ed} &= 1169 - (90\\:kN/m\\times 0.95\\:m)\\\\\\\\
V_{Ed} &= 1083.5\\:kN
\\end{align}`

const Eqn8 = `\\begin{align}
\\frac{A_{sw}}{s} &= \\frac{V_{Ed}}{0.87 f_{yk}\\:z\\:\\cot\\:\\theta}\\\\\\\\
\\frac{A_{sw}}{s} &= \\frac{1083.5\\times10^3}{0.87\\times 500\\times 0.9\\times 750 \\times \\cot 27^{\\circ}}\\\\\\\\
\\frac{A_{sw}}{s} &= 1.88
\\end{align}`

const Eqn9 = `\\begin{align}
s_{req} &= \\frac{314\\:mm}{1.88}\\\\\\\\
s_{req} &= 167\\:mm
\\end{align}`


class Lecture_19_29 extends React.Component {
  state={
    course: 19,
    lecture: 29, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll consider the design of another simply supported beam. This time we'll focus only on the shear design calculations. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider the simply supported beam shown in Fig 1 below. In addition to a uniformly distributed load, the beam is subject to a point load which induces large magnitude shear forces in the beam. The applied forces shown in Fig 1 can be considered the ultimate loads obtained from the STR limit state. Assume the following parameters:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>width, <MathJax.Node inline formula={"b=400\\:mm"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>height, <MathJax.Node inline formula={"h=800\\:mm"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>effective depth, <MathJax.Node inline formula={"d=750\\:mm"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><MathJax.Node inline formula={"f_{ck} = 30\\:N/mm^2"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><MathJax.Node inline formula={"f_{yk} = 500\\:N/mm^2"} /></Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    Our task is to determine a suitable arrangement of shear links to withstand the maximum shear developed in the beam.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Simply supported beam subject to a point load and a uniformly distributed load.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>             

                  <Typography paragraph className={classes.bodytext}>
                    Just like the previous example, we can start by determining the effective span of the beam,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"a_1"} /> and <MathJax.Node inline formula={"a_2"} /> are the lesser of,
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>half of the width of the support, <MathJax.Node inline formula={"200\\:mm"} /></Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>half of the height of the section, <MathJax.Node inline formula={"400\\:mm"} /></Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    So, <MathJax.Node inline formula={"l_n = 6 + 0.2 + 0.2 = 6.4\\:m"} />. This allows us to establish a simple model of the structure, along with a shear force diagram, Fig 2. 
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Structural model and shear force diagram.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We start our shear design by checking for crushing of the compression strut at the face of the support. Following the same approach as the previous example, the shear at the face of the support is,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The corresponding shear stress is,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If the compression strut developed at its max allowable angle of <MathJax.Node inline formula={"45^{\\circ}"} />, the maximum allowable shear stress would be <MathJax.Node inline formula={"v_{Rd, max}=5.28\\:N/mm^2"} />. Previously, we determined this by referencing the table presented in <Link to="/course-19/lecture-26" className={classes.link} target="_blank">lecture 26</Link>. For demonstration purposes only, we'll calculate this value here, 
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore we can calculate the stress as,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This tells us that the compression strut will not crush under the applied loads since <MathJax.Node inline formula={"4.26\\:N/mm^2<5.28\\:N/mm^2"} />. We can also deduce from this that the angle of the compression strut will be more than the minimum value of <MathJax.Node inline formula={"22^{\\circ}"} /> that we assumed in the previous example. Now, we can determine the strut angle, <MathJax.Node inline formula={"\\theta"} /> using Eqn 7 from <Link to="/course-19/lecture-26" className={classes.link} target="_blank">lecture 26</Link>,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can determine a suitable combination of link spacing <MathJax.Node inline formula={"s"} /> and shear link area, <MathJax.Node inline formula={"A_{sw}"} />. Remember, we can determine the area of shear reinforcement based on the shear force at a distance <MathJax.Node inline formula={"d"} /> from the face of the support, therefore,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, using the previously derived design equation,
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We can check to see what steel area would be required if we place the planes of shear links at their maximum spacing, <MathJax.Node inline formula={"s_{max}=0.75d=563\\:mm"} />; setting <MathJax.Node inline formula={"s"} /> to say <MathJax.Node inline formula={"550\\:mm"} />, would require <MathJax.Node inline formula={"A_{sw}=1034\\:mm^2"} />. This corresponds to about <MathJax.Node inline formula={"14"} /> H10 link legs which is clearly not practical. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So, let's tackle this the other way around; considering the beam is only <MathJax.Node inline formula={"400\\:mm"} /> wide, let's say we only want to provide <MathJax.Node inline formula={"4"} /> H10 link legs <MathJax.Node inline formula={"(314\\:mm^2)"} /> in each plane. Now we can solve for the required spacing,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, we can select a practical spacing of <MathJax.Node inline formula={"150\\:mm"} />. Therefore the shear design result is summarised as <b><MathJax.Node inline formula={"4 \\:\\times"} /> H10 link legs at <MathJax.Node inline formula={"150\\:mm"} /> spacing.</b>. The specific arrangement of these link legs within a plane of reinforcement would be determined following the specification of the longitudinal reinforcement.
                  </Typography>                  

                  <MathJax.Node inline formula={""} />
                  <MathJax.Node inline formula={""} />
                  <MathJax.Node inline formula={""} />
                  <MathJax.Node inline formula={""} />
                  <MathJax.Node inline formula={""} />
                                                                    
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_29));
