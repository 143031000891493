import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { HtmlTooltip } from "../../../components/common/ToolTip";
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import VideocamOffRoundedIcon from '@material-ui/icons/VideocamOffRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import LockIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import {returnImage} from '../../../Data/ReturnImage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { mdiClipboardCheck, mdiBookOpenPageVariant, mdiGraph } from "@mdi/js";
import Icon from "@mdi/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#2a2e35",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#33cc99',
  },
}))(LinearProgress);

//Custom styling
const styles = theme => ({  
	card: {    
    border: '1px solid #33cc9950',
    borderRadius:'5px',
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",		
		padding: 0,    
    background: "linear-gradient(45deg, rgba(06,06,06,1) 0%, rgba(18,24,27,1) 70%, rgba(18,24,27,1) 100%)",    
    height:'100%', 
    width:'100%',  
    boxShadow: '0 8px 32px 0 rgba( 0, 0, 0, 0.99 )',      
    "&:hover": {
      border: '1px solid #f13f6450',
      transform: 'scale(101%)',     
      transition: 'transform 0.1s linear',  
      boxShadow: "4px 4px 11px -1px #f13f6420"       
		}
	},
	titleContainer: {
		display: "flex",
		justifyContent: "left"
  },
  subtitleContainer: {
		display: "flex",
    justifyContent: "left",
    paddingTop:'0px'    
	},
  statusContainer:{
    display: "flex",
    justifyContent: "left",
    paddingTop:'0px',
    paddingBottom:'0px', 
  },
	iconContainer: {       
    display: "flex",         
    justifyContent: "flex-start",    
    paddingTop:"0px",
    paddingBottom:"10x"
  },
  courseButton:{        
    marginLeft:'auto',
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    fontSize:"0.75rem",
    background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(51,204,153,1) 0%, rgba(0,156,103,1) 100%)",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    color:"#1a1a1a",   
    "&:hover": {
      background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(69,226,173,1) 0%, rgba(106,230,208,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(51,204,153,0.2)"   
    }    
  },
  title: {
		color: "#33cc99",		
		paddingBottom: 0,
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    fontSize: "1.1rem",
	},
	subTitle: {
    textAlign: "left",    
		color: "white",
		fontSize: '0.9rem',    
    fontFamily:"'sofia-pro',sans-serif",
		paddingBottom: 0,
    paddingTop:0
	},
  statusMessage:{
    textAlign: "left",    
		color: "#f13f64",
		fontSize: '0.8rem',    
    fontFamily:"'sofia-pro',sans-serif",
		paddingBottom: 0,
    paddingTop:0
  },
	cardAction: {
		display: "block",
		textAlign: "initial"
	},
	spinner: {
		marginTop: "15px",
		marginBottom: "15px",
		color: "white"
	},
  progressMessage:{
    color:"#33cc99",
    fontWeight:'bold',
    fontSize: "1.1rem",
  }
});

class CourseCard extends React.Component {

  //Return the appropriate icons
  returnIconSet(course){

    const { classes } = this.props; //Destructure data off the components props

    let hasVideo = course.video
    let hasText = course.text
    let access = course.access

    if((hasVideo) && (hasText)){
      return (
        <React.Fragment>
          <HtmlTooltip title={this.returnTooltipText("Have video")}>
            <VideocamRoundedIcon style={{ fontSize: 20, color: "#33cc99" }} />
          </HtmlTooltip>
          <HtmlTooltip title={this.returnTooltipText("Have text")}>
            <MenuBookRoundedIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} />         
          </HtmlTooltip>  
          <HtmlTooltip title={this.returnTooltipText(course.access)}>
            <LockIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} />         
          </HtmlTooltip> 
          <HtmlTooltip title={this.returnTooltipText("info")}>
            <a href={course.infoURL} target={"_blank"}>
              <InfoIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} />         
            </a>            
          </HtmlTooltip>                             
        </React.Fragment>
        
      )
    }
    else if(hasVideo){
      return (
        <React.Fragment>
          <HtmlTooltip title={this.returnTooltipText("Have video")}>
            <VideocamRoundedIcon style={{ fontSize: 20, color: "#33cc99" }} />
          </HtmlTooltip>
          <HtmlTooltip title={this.returnTooltipText("No text")}>
            <ImportContactsRoundedIcon style={{ fontSize: 20, color: "#f13f64", marginLeft:"20px" }} />
          </HtmlTooltip>
          <HtmlTooltip title={this.returnTooltipText(course.access)}>
            <LockIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} />         
          </HtmlTooltip>   
          <HtmlTooltip title={this.returnTooltipText("info")}>            
            <a href={course.infoURL} target={"_blank"}>
              <InfoIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} /> 
            </a>                    
          </HtmlTooltip>                
        </React.Fragment>
      )
    }
    else{
      return (
        <React.Fragment>
          <HtmlTooltip title={this.returnTooltipText("No video")}>
            <VideocamOffRoundedIcon style={{ fontSize: 20, color: "#f13f64" }} />
          </HtmlTooltip>
          <HtmlTooltip title={this.returnTooltipText("No text")}>
            <ImportContactsRoundedIcon style={{ fontSize: 20, color: "#f13f64", marginLeft:"20px"  }} />
          </HtmlTooltip>
          <HtmlTooltip title={this.returnTooltipText(course.access)}>
            <LockIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} />         
          </HtmlTooltip>  
          <HtmlTooltip title={this.returnTooltipText("info")}>            
            <a href={course.infoURL} target={"_blank"}>
              <InfoIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} />         
            </a>            
          </HtmlTooltip>              
        </React.Fragment>
      );
    }
  }

  //Return the appropriate icons
  returnCourseButton(course){

    const { classes } = this.props; //Destructure data off the components props

    let hasVideo = course.video
    let hasText = course.text
    let access = course.access

    if((hasVideo) && (hasText)){
      return (
        <React.Fragment>                
          <Button 
            className={classes.courseButton} 
            size="small"
            onClick={() => this.props.onClick(course)} > 
              {this.props.user?'Go to course':'Preview'}
          </Button>          
        </React.Fragment>
        
      )
    }
    else if(hasVideo){
      return (
        <React.Fragment>             
          <Button 
            className={classes.courseButton} 
            size="small" 
            onClick={() => this.props.onClick(course)} >
              {this.props.user?'Go to course':'Preview'}
          </Button>
        </React.Fragment>
      )
    }
    else{
      return (
        <React.Fragment>          
          <Button 
            // disabled 
            className={classes.courseButton} 
            size="small" 
          >
              Coming soon
          </Button>     
        </React.Fragment>
      );
    }
  }

  //Return the appropriate text for the tooltip
	returnTooltipText(tip) {
		if (tip === "Have video") {
			return (
				<React.Fragment>					
					{
						"👍 Course video available."
					}
				</React.Fragment>
			);
		} else if(tip==="No video"){
      return (
        <React.Fragment>					
            {
              "🚧 Course video not available yet. Please watch the course over on our Podia site."
            }
          </React.Fragment>
      );
    } else if (tip ==="Have text"){
      return (
        <React.Fragment>					
            {
              "👍 Full text notes available."
            }
          </React.Fragment>
      );
    } else if(tip==="No text"){
      return (
        <React.Fragment>					
            {
              "🚧 Course notes not available yet. But we're working on it!"
            }
          </React.Fragment>
      );
    } else if(tip==="private"){
      return (
        <React.Fragment>					
          {
            "✋ Member only content"
          }
        </React.Fragment>
      );
    } else if(tip==="info"){
      return (
        <React.Fragment>					
          {
            "📋 Course info page"
          }
        </React.Fragment>
      );
    }

		return;
	}

  //Return progress bar for logged-in users
  returnProgressBar = () =>{          
      const { classes, course, ids, user } = this.props; //Destructure data off the components props
      if(this.props.user){
        let progress = 100*ids.length / course.lectures.length
        return(
          <CardContent style={{padding:"10px"}}>
            <BorderLinearProgress variant="determinate" value={progress} />            
          </CardContent> 
        )
      }
      return null  
  }  

  //Return progress message
  returnProgressMessage = () =>{
    const { classes, course, ids, user } = this.props; //Destructure data off the components props
    if(this.props.user){
      let progress = Math.round(100*ids.length / course.lectures.length)
      if((ids.length>0) && (ids.length<course.lectures.length)){
        return(
          <Typography className={classes.progressMessage}>{ids.length}/{course.lectures.length} complete</Typography>
        )
      } else if(ids.length == course.lectures.length){
        return(
          <React.Fragment>
            <CheckCircleIcon style={{marginRight:"10px", color:"#33cc99"}}/>
            <Typography className={classes.progressMessage}>Course Complete</Typography>
          </React.Fragment>
        )
      }
      return null      
    }
    return null    
  }
  
  render() {    
		const { classes, course, ids } = this.props; //Destructure data off the components props    
		return (      
      <Card raised={true} className={classes.card}>       
          <CardMedia
            className={classes.media}            
            image={returnImage(course.courseId,'course')}
            style={{height: 0, paddingTop: '56.25%'}}            
            title={course.title}
          />                  
          <CardContent className={classes.titleContainer}>
            <Typography className={classes.title}>{course.title}</Typography>            
          </CardContent>
          <CardContent className={classes.iconContainer}> 
            {this.returnIconSet(course)}                                  
          </CardContent>  
          <CardContent className={classes.subtitleContainer}>
            <Typography className={classes.subTitle}><em>{course.tagline}</em></Typography>            
          </CardContent>
          <CardContent className={classes.statusContainer}>            
            <Typography className={classes.statusMessage}><em>{course.statusMessage}</em></Typography>
          </CardContent>
          
          <CardContent className={classes.iconContainer}> 
            {this.returnProgressMessage()}
            {this.returnCourseButton(course)}                                  
          </CardContent>   

          {this.returnProgressBar()}


      </Card>      
		);
	}


}
export default withStyles(styles)(CourseCard);