import React, {useState, useRef, Suspense} from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import { a, useSpring } from '@react-spring/three';
// import DatGui, { DatBoolean, DatColor, DatNumber, DatString } from 'react-dat-gui';
// import 'react-dat-gui/dist/index.css';
import {TextureLoader} from "three";
import imageUrl from "../../images/Landing/avatar_spcarroll.png"

function Cube(props){
  const [isHovered, setIsHovered] = useState(false)
  
  const ref = useRef() //Gives us a reference to a DOM element
  useFrame(()=>{    
    ref.current.rotation.y +=0.005 //Update the referenced DOM element
  })

  //Use react spring to animate scale transition
  const { size } = useSpring({
    size: isHovered ? [0.21,0.21,0.21] : [0.2,0.2,0.2],    
  })
  
  const texture = useLoader(TextureLoader, imageUrl)
  const gltf = useLoader(GLTFLoader, '/Logo.glb')
  
  // return(
  //   <a.mesh 
  //     {...props} 
  //     ref={ref}
  //     scale={size}           
  //     onPointerOut={()=>{setIsHovered(false)}}
  //     onPointerOver={()=>{setIsHovered(true)}}
  //   >
  //     <boxBufferGeometry attach="geometry" args={[1,1,1]}/>
  //     <meshPhongMaterial   
  //       map={texture}        
  //       roughness={1}
  //       metalness = {0.5}
  //       shininess = {100}
  //       attach="material"         
  //     />
  //   </a.mesh>
  // );
  return(
    <a.primitive 
      {...props} 
      ref={ref}
      scale={size}   
      object={gltf.scene}                 
      onPointerOut={()=>{setIsHovered(false)}}
      onPointerOver={()=>{setIsHovered(true)}}
    />
    
  );
}

//Contains all of the elements in our scene - gets passed to the canvas
function Scene(){    
  return(
    <>
      {/* <ambientLight intensity={10}/> */}
      <pointLight intensity={1} position={[1,1,4]}/>
      <pointLight intensity={1} position={[-1,1,4]}/>
      <Suspense fallback={null}>
        <Cube rotation={[0,0,0]} position={[0,0,0]} />
      </Suspense>      
      {/* <OrbitControls/>   */}
    </>
  );
}

//Exported component
function SpinningLogo(){ 
  return(    
    <Canvas
    camera={{
      position:[0,0.5,6],
    }}
    >
      <Scene/>
    </Canvas>    
  );
}

export default SpinningLogo;