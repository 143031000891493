import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-21/img1.png";
import img2 from "../../../images/Lectures/C19-21/img2.png";
import img3 from "../../../images/Lectures/C19-21/img3.png";
import img4 from "../../../images/Lectures/C19-21/img4.png";

const Eqn1 = `\\begin{equation}
\\tag{1}
b_{\\text{eff}} = b_{\\text{w}} + b_{\\text{eff},1} + b_{\\text{eff},2}
\\end{equation}`

const Eqn2 = `\\begin{equation}
\\tag{2}
b_{\\text{eff,i}} = 0.2b_{\\text{i}} + 0.1l_0\\:\\:\\text{but}\\:\\leq0.2l_0\\:\\text{and}\\:\\leq b_{\\text{i}}
\\end{equation}`

const Eqn3 = `\\begin{align}
l_0&=0.7l\\\\
l_0&=0.7\\times 8\\\\
l_0&=5.6\\:m
\\end{align}`

const Eqn4 = `\\begin{align}
b_{\\text{eff},1} &= 0.2\\times1.35 + 0.1\\times 5.6\\\\
b_{\\text{eff},1} &=0.83\\:m
\\end{align}`

const Eqn5 = `\\begin{align}
b_{\\text{eff}} &= 0.3+2\\times 0.83\\\\
b_{\\text{eff}} &=1.96\\:m
\\end{align}`

const Eqn6 = ``

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_22 extends React.Component {
  state={
    course: 19,
    lecture: 22, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >         

                  <Typography component="h2" className={classes.H2} > 1.0 Introduction </Typography>         
                  
                  <Typography paragraph className={classes.bodytext}>
                    Concrete beams are very often constructed or cast monolithically with the concrete slabs that they support. In Fig 1(a), you can see a section through a slab supported by three concrete beams. You may initially think that the structure should be partitioned, as shown in Fig 1(b), with the three beams residing wholly beneath the slab. However, a more accurate and structurally efficient modelling of the structure is shown in Fig 1(c). In this case, the beams benefit from a wider <i>flange</i> that extends into the slab. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the case of sagging moments, inducing tension on the underside of the beam, this allows the beam to develop a compression block over a much larger area than just the beam width. A beam designed to take advantage of this wider flange is known as a <b>flanged beam</b>. Remember, concrete structures benefit from being monolithically cast, and when fully detailed with steel reinforcement, they have a huge degree of interconnectivity between elements. This is one of the things that makes concrete structures so robust. We need to keep this in mind when tempted to artificially partition the structure for the purposes of analysis and design.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that in the case of a hogging moment, inducing tension on the top surface of the beam, the compression block will be located at the bottom of the beam section and will, therefore, not benefit from the wider flange. We know from our previous study of section analysis that in terms of bending resistance, the concrete in the tension zone (top of the beam in the case of a hogging moment) makes no contribution. So, flanged beams offer an advantage for sagging moments only.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. (a) Beam and slab section, (b) beam and slab model in which the slab makes no contribution to the beam stiffness, (c) beam and slab model in which the slab acts as a compression flange to the beam in the case of sagging moments.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >  

                  <Typography component="h2" className={classes.H2} > 2.0 Flange width </Typography>                

                  <Typography paragraph className={classes.bodytext}>
                    The first question we need to answer when designing a flanged beam is, <i>how wide should the flange be?</i> Fortunately we have guidance within EC2 to follow. The <b>effective flange width, <MathJax.Node inline formula={"b_{\\text{eff}}"} /></b> depends on the cross-section dimensions and the the distance between points of zero moment, <MathJax.Node inline formula={"l_0"} />, along the beam. Consider the cross-section shown in Fig 2.
                  </Typography> 
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Parameters used to determine the effective flange width, <MathJax.Node inline formula={"b_{eff}"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    According to EC2, the effective flange width should be determined from,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    and <MathJax.Node inline formula={"l_0"} /> is the distance between points of zero moment, as defined in Fig 3 below.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Elevation of a continuous beam identifying the distance between points of zero moment, <MathJax.Node inline formula={"l_0"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography component="h3" className={classes.H3} > 2.1 Example </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll work through an example to demonstrate the use of this guidance. Consider the section and elevation shown in Fig 4 below. We must determine the effective width of the flange for the green beam. Below the section, we can see that the beam under consideration is actually an interior span of a continuous beam. The information provided in both diagrams is sufficient to determine the effective flange width based on the guidance in EC2.
                  </Typography>
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Section through slab and beams (top), elevation of interior span (bottom).</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    As we're dealing with an internal span of a continuous beam, based on Fig 3, <MathJax.Node inline formula={"l_0"} /> is obtained as,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The beam centre-to-centre distance is <MathJax.Node inline formula={"3\\:m"} />, therefore <MathJax.Node inline formula={"b_1=(3-0.3)/2=1.35\\:m"} />. Therefore, <MathJax.Node inline formula={"b_{\\text{eff},1}"} /> is,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We note that this is less than <MathJax.Node inline formula={"0.2l_0"} /> and <MathJax.Node inline formula={"b_1"} /> and is satisfactory. As we have a symmetrical arrangement, <MathJax.Node inline formula={"b_{\\text{eff},2} = b_{\\text{eff},1}"} /> and we can compute the effective flange width using Eqn 1,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    With the effective flange width determined for this interior span, we could now proceed with the section analysis and bending design. We'll discuss this next.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 3.0 Design for bending </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The bending design of a flanged section relies on all of the same fundamental theory we've already discussed; we must maintain force equilibrium across the section, and we must always ensure a ductile failure at the ultimate limit state. As we've said above, the wider flange only applies to sagging moments (inducing compression at the top of the beam). If we now focus on designing for sagging moments, there are two further scenarios that could develop. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first is when the compression zone lies completely in the flange. In this case, we simply design the section as normal with the beam width <MathJax.Node inline formula={"b = b_{\\text{eff}}"} />. Note that despite the larger width of the compression zone, the tension reinforcement will still be confined to a narrower section of the beam with width <MathJax.Node inline formula={"b"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The second sagging moment scenario is when the compression zone extends down into the web, i.e. the complete flange goes into compression, and to balance the force developed in the steel, part of the web also goes into compression. We'll consider this case further with an illustrative example in the next lecture.
                  </Typography>
                  
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_22));
