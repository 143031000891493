import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-6/img1.png";

class Lecture_19_6 extends React.Component {
  state={
    course: 19,
    lecture: 6, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography component="h2" className={classes.H2} > 1.0 Introduction </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The loading that a structure is subject to during its life is a huge source of uncertainty. So, when designing a structure, one of the first steps is determining what loads or combinations of loads should be assumed in the design. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Helpfully, codes of practice such as the Eurocodes provide a framework that allows designers to systematically determine design loadings that balance efficiency and risk of exceedance. As we saw in the previous lecture, the relevant Eurocodes for determining design loading are:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>BS EN 1990, Eurocode: Basis of structural design (EC0)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>BE EN 1991, Eurocode 1: Actions on structures (EC1)</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    In order to get an overview of the following discussion, consider a basic design scenario; you are tasked with designing a beam that supports a floor. The floor forms part of an office building. The building is a concrete frame structure with solid concrete floor slabs.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Based on the type of structure (reinforced concrete), we can estimate the <b><i>characteristc permanent load</i></b> applied to the beam due to the self-weight of the structure. Based on the building usage (office building), we can determine from published tables the value of the <b><i>characteristic variable load</i></b> that can be applied to the floor (and indirectly to the beam). 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Because the consequences of collapse are potentially catastrophic and we can't be any more specific about what activities will take place on the floor, the characteristic permanent and variable loads are multiplied by <b><i>partial factors of safety</i></b> to obtain the <b><i>design permanent and variable loads</i></b>. The final step is to combine the permanent and variable design loads together to give a final value of the load that can be applied in the beam design calculation. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The values chosen for partial factors, broadly speaking, depend on what <b><i>limit state</i></b> we are designing for; i.e. is our design checking to ensure that the structure will not collapse (an <b><i>Ultimate Limit State (ULS)</i></b>) or are we checking to ensure that the structure will not deflect too much or vibrate too much (examples of <b><i>Serviceability Limit States (SLS)</i></b>)? Each of the concepts identified in the above scenario will be discussed in the following lectures. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Actions (Loads) </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The Eurocodes use the term <b><i>Action</i></b> to refer to anything that induces a change in the stress state of a structure (we'll use the term actions and loads interchangeably). Actions can be categorised as direct or indirect. Direct actions are typically loads that are applied directly to the structure, while examples of indirect actions include imposed deformations such as uneven settlement or thermal movement. Indirect actions, therefore, induce a change in the stress state of a structure without the direct application of a force. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Of more importance is the distinction between permanent and variable actions, which are defined as follows:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}><b>Permanent Action:</b> An action that is likely to act throughout a given reference period (design life) and for which the variation in magnitude with time is negligible. Historically referred to as <i>Dead Loads</i>, examples include the self-weight of the structure and any fixed equipment or fittings. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><b>Variable Action:</b> An action for which the variation in magnitude with time is not negligible. Historically referred to as <i>Imposed</i> or <i>Live</i> loads, examples include loading due to occupancy.</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    The magnitude stated for a permanent or variable action will unless stated otherwise, usually be the <i>characteristic</i> value. This is the baseline value before partial safety factors are applied. For example, if we consider a concrete beam that is <MathJax.Node inline formula={"400 \\:mm"} /> wide and <MathJax.Node inline formula={"800 \\:mm"} /> deep, the characteristic permanent loading due to self-weight would be <MathJax.Node inline formula={"0.4 \\:m \\times 0.8\\:m \\times 25\\: kN/m^3 = 8 \\:kN/m"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In order to determine the <i>design value</i> of the permanent load, we multiply the characteristic load by a partial factor of, say, <MathJax.Node inline formula={"1.35"} />, leading to a design permanent load of <MathJax.Node inline formula={"1.35 \\times 8\\: kN/m = 10.8\\: kN/m"} />. This can now be added to the design variable load to obtain a single loading figure that can be used in design. The actual value of the partial factor (<MathJax.Node inline formula={"1.35"} /> above) will depend on the limit state under consideration (discussed later).
                  </Typography>  

                  <Typography component="h3" className={classes.H3} > 2.1 Characteristic Permanent Actions </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As already mentioned, characteristic permanent actions, denoted as <MathJax.Node inline formula={"G_k"} />, have minimal variation in magnitude during the structure’s lifetime, a simple example is the loading due to self-weight. If we assume the range of <MathJax.Node inline formula={"G_k"} /> values follows a Gaussian distribution, the variation can be considered small if the standard deviation is less than <MathJax.Node inline formula={"10\\:\\%"} /> of the mean value.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the variation cannot be considered small, two different values should be determined; an upper value, <MathJax.Node inline formula={"G_{k,sup}"} /> and a lower value, <MathJax.Node inline formula={"G_{k,inf}"} />. <MathJax.Node inline formula={"G_{k,sup}"} /> is that value of permanent loading that has only a <MathJax.Node inline formula={"5\\:\\%"} /> chance of exceedance during the lifetime of the structure, assuming <MathJax.Node inline formula={"G_k"} /> follows a Gaussian distribution. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                  <MathJax.Node inline formula={"G_{k,inf}"} /> is that value of permanent loading with a <MathJax.Node inline formula={"95\\:\\%"} /> chance of exceedance. For most cases and certainly any designs we undertake in this course, a single value of permanent action will apply.  
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    <i>Side note: when a load is in units of <MathJax.Node inline formula={"kN"} />, an upper case letter is used, e.g. <MathJax.Node inline formula={"G_k = 5\\:kN"} />. If the load is a uniformly distributed/line load, a lower case symbol is used, e.g. <MathJax.Node inline formula={"g_k = 5\\: kN/m^2"} />.</i>
                  </Typography> 

                  <Typography paragraph className={classes.bodytext}>
                    In addition to permanent and variable actions, you should also be aware of <i>accidental actions</i>. An accidental action is usually of short duration but significant magnitude. They are unlikely to occur on a structure during the design working life. Nevertheless, some allowance must be made for the potential occurrence of fire, explosions, impacts and localised failures. Determining the magnitude of design accidental actions is beyond the scope of this course, but you should be aware of their existence. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Characteristic Variable Actions </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The characteristic variable action, denoted by <MathJax.Node inline formula={"Q_k"} /> or <MathJax.Node inline formula={"q_k"} /> can usually be determined by considering the proposed usage of the structure. For buildings, four categories are defined:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Category A: Areas of domestic and residential activities. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Category B: Office areas</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Category C: Areas where people may congregate not covered by categories A, B and D.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Category D: Shopping areas</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    Within each category, there are sub-categories; for the complete table, you should refer to the source document or IStructE manual referred to in the previous lecture. Examples of category/sub-category loadings are shown below as an example:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Category A / sub-category A1: <MathJax.Node inline formula={"(q_k=1.5\\:kN/m^2, \\:Q_K=2.0\\:kN)"} />, <i>All usages within self-contained dwelling units. Commercial areas (including kitchens) in blocks of flats with limited use.</i></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Category B / sub-category B2: <MathJax.Node inline formula={"(q_k=3.0\\:kN/m^2, \\:Q_K=2.7\\:kN)"} /> <i>Office areas / at or below ground level.</i></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Category C / sub-category C1 / sub-sub-category C13: <MathJax.Node inline formula={"(q_k=3.0\\:kN/m^2, \\:Q_K=3.0\\:kN)"} /> <i>Areas where people may congregate / with tables / classrooms.</i></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Category D / subcategory D1: <MathJax.Node inline formula={"(q_k=4.0\\:kN/m^2, \\:Q_K=3.6\\:kN)"} /> <i>Shopping areas / in general retail shops.</i></Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    These are merely a selection of the extensive list of characteristic variable actions specified. If an appropriate sub-category cannot be identified, or where the designer has knowledge that the prescribed loading in the relevant sub-category is not appropriate, sound engineering judgement should be used to determine a suitable (safe and efficient) variable action.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In certain design situations, the characteristic variable action can be multiplied by a number of different factors to facilitate specific design checks, thus, in addition to the characteristic value, <MathJax.Node inline formula={"Q_k"} />, there are three other values that may be used in specific circumstances:  
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>
                      The combination value, <MathJax.Node inline formula={"\\psi_0\\:Q_k"} />: When we move on to consider different design limit states in the following lectures, it will become necessary to combine permanent and variable actions. When a number of variable actions act simultaneously, the <i>leading</i> action is chosen as <MathJax.Node inline formula={"Q_{k,1}"} /> and the other <i>accompanying</i> actions are chosen as <MathJax.Node inline formula={"Q_{k,i}"} /> and are reduced by an appropriate combination factor, <MathJax.Node inline formula={"\\psi_0"} />.   </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    The frequent value, <MathJax.Node inline formula={"\\psi_1\\:Q_k"} />: This is used to reduce the magnitude of the leading variable action when considering accidental design situations. It is also used when considering serviceability limit states, discussed below. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    The quasi-permanent value, <MathJax.Node inline formula={"\\psi_2\\:Q_k"} />: This is also used when considering accidental design situations at the ultimate limit state and when considering serviceability limit states. </Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    The frequent and quasi-permanent values for variable actions have only been touched on here. These will make more sense after we discuss the concept of limit state design. The values of <MathJax.Node inline formula={"\\psi_0"} />, <MathJax.Node inline formula={"\\psi_1"} /> and <MathJax.Node inline formula={"\\psi_2"} /> can be determined based on the usage category of the structure, see Fig. 1 below. 
                  </Typography>                                                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Values of <MathJax.Node inline formula={"\\psi"} /> factors for buildings.</figcaption>
                </figure> 

                <Grid item xs={12} sm={12} md={10} >   
                  <Typography component="h3" className={classes.H3} > 2.3 Partition Loading </Typography>               

                  <Typography paragraph className={classes.bodytext}>
                    The distribution of internal dividing walls or partitions within a structure is often not known when the calculations for the frame and vertical load-bearing system are being completed. Offices, for example, often utilise moveable partitions to subdivide large open floorplans. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To allow for flexibility in the positioning of non-load-bearing partitions, an allowance can be made for them by applying a uniformly distributed loading across the entire floor. This loading is categorised as a variable load and is added to the loading due to occupancy already determined. The value of the partition loading can be determined as follows:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>For moveable partitions with a self-weight <MathJax.Node inline formula={"\\leq 1.0 \\:kN/m"}/> wall length: <MathJax.Node inline formula={"q_k = 0.5\\: kN/m^2"} />.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>For moveable partitions with a self-weight <MathJax.Node inline formula={"\\leq 2.0 \\:kN/m"}/> wall length: <MathJax.Node inline formula={"q_k = 0.8\\: kN/m^2"} />.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>For moveable partitions with a self-weight <MathJax.Node inline formula={"\\leq 3.0 \\:kN/m"}/> wall length: <MathJax.Node inline formula={"q_k = 1.2\\: kN/m^2"} />.</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    After determining the characteristic values of permanent and variable actions for a given structure and usage category, our next task is to combine these together using partial safety factors to determine the final design actions. The selection of partial safety factors and the combination of permanent and variable loads is determined by the specific <i>limit state</i> being considered. We'll continue in the next lecture by introducing the Ultimate Limit State.
                  </Typography>                  
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_6));
