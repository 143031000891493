import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-15/img1.png";
import img2 from "../../../images/Lectures/C19-15/img2.png";

const Eqn1 = `\\begin{align*}
M_{Rd,max} &= 0.167\\:f_{ck}bd^2\\\\\\\\
&=304.4\\:kNm
\\end{align*} `

const Eqn2 = `\\begin{align*}
K&=\\frac{153.1\\times 10^6}{300\\times 450^2 \\times 30}\\\\\\\\
&=0.084
\\end{align*}`

const Eqn3 = `\\begin{align*}
z &=450\\Bigg[ 0.5+\\sqrt{0.25-\\frac{0.084}{1.134}}\\Bigg]\\\\\\\\
&=0.919\\:d\\:\\:(<0.95\\:d)\\\\\\\\
&=414\\:mm
\\end{align*}`

const Eqn4 = `\\begin{align*}
A_{s,req} &= \\frac{153.1\\times 10^6}{0.87\\times 500\\times 414}\\\\\\\\
&=850\\:mm^2
\\end{align*} `

const Eqn5 = `\\begin{equation}
\\tag{1}
A_{s,min} = \\frac{0.26\\:f_{ctm}}{f_{yk}}\\:bh
\\end{equation}`

const Eqn6 = `\\begin{align*}
A_{s,min} &= 0.15\\%\\\\
&=0.0015\\times 300 \\times 450\\\\
&=203\\:mm^2
\\end{align*}`

const Eqn7 = `\\begin{align*}
\\delta_{clear} &= \\frac{b-2(\\text{cover}+\\text{link dia.})-(\\text{No. bars}\\times \\text{bar dia.})}{\\text{No. bars}-1}\\\\\\\\
&=80\\:mm>25\\:mm\\:\\therefore \\text{OK}
\\end{align*}`


class Lecture_19_15 extends React.Component {
  state={
    course: 19,
    lecture: 15, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >          
                  <Typography component="h2" className={classes.H2} > 1.0 Question </ Typography>        
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll put the design equations from the previous lecture into practice. Consider a simply supported beam with a design span of <MathJax.Node inline formula={"L = 7 \\:m"} />, subject to a UDL, <MathJax.Node inline formula={"w = 25 \\:kN/m"} />. The beam has a cross-section height of <MathJax.Node inline formula={"h = 500\\: mm"} />, width <MathJax.Node inline formula={"b = 300\\: mm"} />, effective depth <MathJax.Node inline formula={"d = 450\\: mm"} /> and a characteristic concrete compressive strength <MathJax.Node inline formula={"f_{ck} = 30\\: N/mm^2"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Our task is to determine the area and arrangement of tensile reinforcement required in the beam. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>

                  <Typography component="h3" className={classes.H3} > 2.1 Confirm suitability of section dimensions </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The externally applied bending moment is obtained as <MathJax.Node inline formula={"M_{Ed} = wL^2/8 = 153.1\\: kNm"} />. The maximum moment a singly reinforced beam with these dimensions could safely withstand is:
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This tells us immediately that the overall dimensions of our cross-section should be sufficient for a reasonable beam design. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Determine the required area of steel, <MathJax.Node inline formula={"A_{s,req}"} /> </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now, although the maximum capacity is <MathJax.Node inline formula={"304.4\\:kNm"} />, we only wish to cater for a design moment of <MathJax.Node inline formula={"153.1 \\:kNm"} />, we therefore determine <MathJax.Node inline formula={"K"} /> as:
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The lever arm can now be obtained as:
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Note that the maximum allowable lever arm value is <MathJax.Node inline formula={"0.95\\:d"} />. Larger values for <MathJax.Node inline formula={"z"} /> should always be limited to <MathJax.Node inline formula={"0.95\\:d"} /> to avoid an unreasonably small compression block. The required area of steel can finally be determined as:
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography component="h3" className={classes.H3} > 2.3 Check the minimum area of steel, <MathJax.Node inline formula={"A_{s,min}"} /> </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Before working out the arrangement of reinforcing bars to provide, we first check to ensure the area of reinforcing steel derived is larger than the minimum percentage prescribed by Eurocode 2. The minimum longitudinal reinforcement area can be obtained using the following formula,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"f_{ctm}"} /> is the mean tensile strength, which can be estimated as a function of the characteristic compressive strength, <MathJax.Node inline formula={"f_{ck}"} />, and <MathJax.Node inline formula={"f_{yk}"} /> is the characteristic yield strength of the steel reinforcement. If we assume <MathJax.Node inline formula={"f_{yk}=500\\:N/mm^2"} />, then <MathJax.Node inline formula={"A_{s,min}"} /> can be tabulated as a percentage of the concrete cross-sectional area for easy reference, Fig 1.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Minimum percentage of reinforcement in the cross-section. Assumes <MathJax.Node inline formula={"f_{yk}=500\\:N/mm^2"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    So, for a concrete with <MathJax.Node inline formula={"f_{ck} = 30 \\:N/mm^2"} />:
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore the minimum requirement will be satisfied by providing <MathJax.Node inline formula={"A_{s,req}"} />. There is also a maximum allowable area of longitudinal reinforcement that must be observed to avoid overly congested cross-sections. This is <MathJax.Node inline formula={"4\\:\\%"} />, or <MathJax.Node inline formula={"8\\:\\%"} /> at sections where reinforcement is lapped. Clearly, we're not in danger of exceeding this limit in this case.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.4 Check bar arrangement </Typography>


                  <Typography paragraph className={classes.bodytext}>
                    The final task is to determine a suitable arrangement of reinforcing bars. We must ensure that bars are placed with enough clear space to allow concrete to flow completely around them. So, we must ensure that the clear distance between bars is at least the maximum aggregate size (used in the concrete mix) + <MathJax.Node inline formula={"5 \\:mm"} />. Practically this usually means a minimum clear distance of <MathJax.Node inline formula={"25 \\:mm"} /> should be provided. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The final task is to determine a suitable arrangement of reinforcing bars. We must ensure that bars are placed with enough clear space to allow concrete to flow completely around them. Thus we must ensure that the clear distance between bars is at least the maximum aggregate size (used in the concrete mix) + 5 mm. Practically this usually means a minimum clear distance of <MathJax.Node inline formula={"25\\: mm"} /> should be provided. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Referring to the table of reinforcement areas shown in Fig 2, we can see that <MathJax.Node inline formula={"3"} /> H20 (<MathJax.Node inline formula={"20\\:mm"} /> diameter, high yield strength reinforcement) bars provide an area of <MathJax.Node inline formula={"942\\:mm^2"} />, which is sufficient. If we assume <MathJax.Node inline formula={"30\\:mm"} /> outer cover (distance from the surface of the concrete to the first steel) and a <MathJax.Node inline formula={"10 \\:mm"} /> diameter shear link (discussed later), the clear distance between <MathJax.Node inline formula={"3"} /> H20 bars can be calculated as:
                  </Typography> 

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Areas of groups of bars.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    It is worth mentioning here that we should also check the maximum spacing between longitudinal bars; no longitudinal bar should be more than <MathJax.Node inline formula={"150\\:mm"} /> from a vertical shear link. We'll return to this a little later when we discuss shear reinforcement.
                  </Typography>                   
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_15));
