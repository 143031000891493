import React from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppsIcon from '@material-ui/icons/Apps';
import CodeIcon from '@material-ui/icons/Code';
import BuildIcon from '@material-ui/icons/Build';
import ListIcon from '@material-ui/icons/List';
import Typography from "@material-ui/core/Typography";
import { mdiLogout, mdiLockOpen, mdiMap, mdiLogin } from "@mdi/js";
import Icon from "@mdi/react";
import ForumIcon from '@material-ui/icons/Forum';

const iconStyle = {
	color: "#33CC99"
};

const topItems = [
	// {
	// 	linkText: "courses",
	// 	displayText: "COURSES",
	// 	icon: <AppsIcon />
	// },
  // {
	// 	linkText: "projects",
	// 	displayText: "PYTHON PROJECTS",
	// 	icon: <CodeIcon />
	// },
  // {
	// 	linkText: "lectures",
	// 	displayText: "LECTURE INDEX",
	// 	icon: <ListIcon />
	// },
  // {
	// 	linkText: "structureworks",
	// 	displayText: "STRUCTURE WORKS",
	// 	icon: <BuildIcon />
	// },
  {
		linkText: "home",
		displayText: "HOME",
		icon: <AppsIcon />
	},
  {
		linkText: "structureworks/truss2d",
		displayText: "2D TRUSS SOLVER",
		icon: <BuildIcon />
	},
  {
		linkText: "structureworks/truss3d",
		displayText: "3D TRUSS SOLVER",
		icon: <BuildIcon />
	},
  // {
	// 	linkText: "community",
	// 	displayText: "COMMUNITY",
	// 	icon: <ForumIcon />
	// }
];

export const topList = classes => {
	return (
		<List className={classes.grow}>
			{topItems.map(item => (
				<ListItem
					button
					className={classes.menuItem}
					key={item.linkText}
					component={Link}
					to={"/" + item.linkText}
          target={item.linkText=='community'? '_blank' : null}
				>
					<ListItemIcon style={iconStyle}>{item.icon}</ListItemIcon>
					{/* <ListItemText primary={item.displayText} /> */}
          <ListItemText primary={<Typography className={classes.mainMenuItemText}>{`${item.displayText}`}</Typography>} />
          
				</ListItem>
			))}
		</List>
	);
};

const btmItems = [
	// {
	// 	linkText: "roadmap",
	// 	displayText: "ROADMAP",
	// 	icon: <Icon path={mdiMap} size={1} color={iconStyle.color} />
	// }
];

export const btmList = props => {
	const { classes } = props;
  
	return (
		<List>
			{btmItems.map(item => (
				<ListItem
					button
					className={classes.menuItem}
					key={item.linkText}
					component={Link}
					to={"/" + item.linkText}
					target={item.linkText=="roadmap"?"_blank":null}
				>
					<ListItemIcon style={iconStyle}>{item.icon}</ListItemIcon>
					<ListItemText primary={<Typography className={classes.mainMenuItemText}>{`${item.displayText}`}</Typography>} />
				</ListItem>
			))}
			<ListItem
				button
				key={"Sign Out"}
				onClick={props.onLogout}
				className={classes.menuItem}
			>
				<ListItemIcon style={iconStyle}>
					<Icon path={mdiLogout} size={1} color={iconStyle.color} />
				</ListItemIcon>
				<ListItemText primary={<Typography className={classes.mainMenuItemText}>SIGN OUT</Typography>} />
			</ListItem>
		</List>
	);
};

export const btmListPublic = props => {
  const { classes } = props;
  return (
		<List>
			{btmItems.map(item => (
				<ListItem
					button
					className={classes.menuItem}
					key={item.linkText}
					component={Link}
					to={"/" + item.linkText}	
          target={item.linkText=="roadmap"?"_blank":null}				
				>
					<ListItemIcon style={iconStyle}>{item.icon}</ListItemIcon>
					<ListItemText primary={<Typography className={classes.mainMenuItemText}>{item.displayText}</Typography>} />
				</ListItem>
			))}
			<ListItem
				button
				key={"Sign in"}
				component={Link}
        to={"/login"}
				className={classes.menuItem}
			>
				<ListItemIcon style={iconStyle}>
					<Icon path={mdiLogin} size={1} color={"#f13f64"} />
				</ListItemIcon>
				<ListItemText style={{color:"#f13f64", fontWeight:"bold"}} primary={<Typography className={classes.mainMenuItemText}>MEMBER SIGN IN</Typography>} />
			</ListItem>
		</List>
	);

};


const mobileWidth = 767
export const drawerWidthRight = window.innerWidth<mobileWidth?275:320
export const drawerWidth = 250;

export const styles = theme => ({
	root: {
		display: "flex"   
  }, 
	appBar: {
    // background:'#1a1a1a',   
    background: "#2a2e35",
    zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {    
		marginLeft: drawerWidth,

		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,    
	},
	menuButtonRight: {
		marginLeft: 12,
		marginRight: 0
	},
	hide: {
		display: "none"
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap"
	},
	drawerRight: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerOpen: {
    // backgroundImage: `url(${backgroundImage})`,
    // background: '#1a1a1a',    
    background: 'rgb(18,24,27)',
    // background: 'linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(26,26,26,1) 13%, rgba(25,102,76,1) 100%)',
    background: 'linear-gradient(90deg, rgba(18,24,27,1) 0%, rgba(18,24,27,1) 13%, rgba(17,73,54,1) 100%)',
    borderRight: '1px solid #33cc99',
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
    // height: 'calc(100% - 200px)',
    // bottom: 200
	},
	drawerClose: {
    // backgroundImage: `url(${backgroundImage})`,    
    // background: '#1a1a1a',
    background: 'rgb(18,24,27)',
    background: 'linear-gradient(90deg, rgba(18,24,27,1) 0%, rgba(18,24,27,1) 90%, rgba(39,126,97,1) 100%)',
    borderRight: '1px solid #33cc99',
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1
		}
	},
	drawerOpenRight: {
		width: drawerWidthRight,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerCloseRight: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: 0
		// width: theme.spacing(7) + 1,
		// [theme.breakpoints.up("sm")]: {
		// 	width: theme.spacing(9) + 1
		// }
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
    paddingBottom:"0px",
    paddingLeft:"0px",
    paddingRight:"0px"

	},
	grow: {
		flexGrow: 1
	},
	modal: {
		position: "absolute",
		width: 800,
		backgroundColor: theme.palette.background.default,
		border: "2px solid #000",
		borderColor: theme.palette.secondary.main,
    borderRadius:"25px",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(5)
	},
	menuItem: {
		color: "white",    
		"&:hover": {
			color: theme.palette.secondary.main
		}
	},
  mainMenuItemText:{
    fontWeight:'bold',
    // fontFamily:"'sofia-pro',sans-serif",
  },
	logoTitle: {
		fontSize: "1.2rem",
    margin: "auto",    
    color: "white"
  },
  logoAvatar: {
		width: 175,
		height: 75,
    marginRight: "-10px",
    marginTop:'5px',
    paddingRight: "20px",
  },
  submitButton:{        
    background: "#33cc99",
    color:"#1a1a1a",   
    "&:hover": {
      background: "#6ae6d0",
    }    
  },
	menuLinkSignin: {
    fontWeight:"bold",
		color: theme.palette.coral.main,
		"&:hover": {
			color: "rgba(255,139,164,1)",      
		}
	}
});
