import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_12 extends React.Component {
  state={
    course: 17,
    lecture: 12, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    One of the main pillars of our solver is an element stiffness matrix that can capture the influence of large deflections. In this section, we’ll build this stiffness matrix by implementing the technique proposed by Broughton and Ndumbaro. Their text, <i>The Analysis of Cable and Catenary Structures</i>, is our main source for the discussion in this section. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We’ll start by deriving the element transformation matrices and the linear version of the element stiffness matrix. We’ll parameterise the element displacements in a slightly different way to our previous discussion in the prerequisite truss analysis course. But we’ll quickly demonstrate equivalence between the two approaches by showing they ultimately yield the exact same linear element stiffness matrix.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    From here, we’ll introduce the influence of large deflections and pre-tension on the element stiffness matrix. This will yield an incremental non-linear stiffness matrix. The final step will be to use the transformation matrix to represent this local stiffness matrix in a global reference frame.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    This is the final derivation-based section of the course. From here, we’ll get on and implement what we’ve learned by building our Python solver.  
                  </Typography>              
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_12));
