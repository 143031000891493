
import React from "react";
import PrivateRoute from "../../../components/utils/PrivateRoute";
import { Route } from "react-router-dom";
import * as Course_17 from './index' //Import all lecture components for course 17

const courseRoutes_17 = (  
  <>
    <Route exact path="/course-17/lecture-1" component={Course_17.Lecture_1} />
    <Route exact path="/course-17/lecture-2" component={Course_17.Lecture_2} />
    <Route exact path="/course-17/lecture-3" component={Course_17.Lecture_3} />
    <PrivateRoute exact path="/course-17/lecture-4" component={Course_17.Lecture_4} />
    <PrivateRoute exact path="/course-17/lecture-5" component={Course_17.Lecture_5} />
    <PrivateRoute exact path="/course-17/lecture-6" component={Course_17.Lecture_6} />
    <PrivateRoute exact path="/course-17/lecture-7" component={Course_17.Lecture_7} />
    <Route exact path="/course-17/lecture-8" component={Course_17.Lecture_8} />
    <PrivateRoute exact path="/course-17/lecture-9" component={Course_17.Lecture_9} />
    <PrivateRoute exact path="/course-17/lecture-10" component={Course_17.Lecture_10} />
    <PrivateRoute exact path="/course-17/lecture-11" component={Course_17.Lecture_11} />
    <Route exact path="/course-17/lecture-12" component={Course_17.Lecture_12} />
    <PrivateRoute exact path="/course-17/lecture-13" component={Course_17.Lecture_13} />
    <PrivateRoute exact path="/course-17/lecture-14" component={Course_17.Lecture_14} />
    <PrivateRoute exact path="/course-17/lecture-15" component={Course_17.Lecture_15} />
    <PrivateRoute exact path="/course-17/lecture-16" component={Course_17.Lecture_16} />
    <PrivateRoute exact path="/course-17/lecture-17" component={Course_17.Lecture_17} />
    <Route exact path="/course-17/lecture-18" component={Course_17.Lecture_18} />
    <PrivateRoute exact path="/course-17/lecture-19" component={Course_17.Lecture_19} />
    <PrivateRoute exact path="/course-17/lecture-20" component={Course_17.Lecture_20} />
    <PrivateRoute exact path="/course-17/lecture-21" component={Course_17.Lecture_21} />
    <PrivateRoute exact path="/course-17/lecture-22" component={Course_17.Lecture_22} />
    <PrivateRoute exact path="/course-17/lecture-23" component={Course_17.Lecture_23} />
    <PrivateRoute exact path="/course-17/lecture-24" component={Course_17.Lecture_24} />
    <PrivateRoute exact path="/course-17/lecture-25" component={Course_17.Lecture_25} />
    <PrivateRoute exact path="/course-17/lecture-26" component={Course_17.Lecture_26} />
    <PrivateRoute exact path="/course-17/lecture-27" component={Course_17.Lecture_27} />
    <PrivateRoute exact path="/course-17/lecture-28" component={Course_17.Lecture_28} />
    <PrivateRoute exact path="/course-17/lecture-29" component={Course_17.Lecture_29} />
    <PrivateRoute exact path="/course-17/lecture-30" component={Course_17.Lecture_30} />
    <PrivateRoute exact path="/course-17/lecture-31" component={Course_17.Lecture_31} />
    <Route exact path="/course-17/lecture-32" component={Course_17.Lecture_32} />
    <PrivateRoute exact path="/course-17/lecture-33" component={Course_17.Lecture_33} />
    <PrivateRoute exact path="/course-17/lecture-34" component={Course_17.Lecture_34} />
    <PrivateRoute exact path="/course-17/lecture-35" component={Course_17.Lecture_35} />
    <PrivateRoute exact path="/course-17/lecture-36" component={Course_17.Lecture_36} />
    <PrivateRoute exact path="/course-17/lecture-37" component={Course_17.Lecture_37} />
    <PrivateRoute exact path="/course-17/lecture-38" component={Course_17.Lecture_38} />
    <PrivateRoute exact path="/course-17/lecture-39" component={Course_17.Lecture_39} />
    <Route exact path="/course-17/lecture-40" component={Course_17.Lecture_40} />
    <PrivateRoute exact path="/course-17/lecture-41" component={Course_17.Lecture_41} />
    <PrivateRoute exact path="/course-17/lecture-42" component={Course_17.Lecture_42} />
    <PrivateRoute exact path="/course-17/lecture-43" component={Course_17.Lecture_43} />
    <PrivateRoute exact path="/course-17/lecture-44" component={Course_17.Lecture_44} />
    <Route exact path="/course-17/lecture-45" component={Course_17.Lecture_45} />
    <PrivateRoute exact path="/course-17/lecture-46" component={Course_17.Lecture_46} />
    <PrivateRoute exact path="/course-17/lecture-47" component={Course_17.Lecture_47} />
    <PrivateRoute exact path="/course-17/lecture-48" component={Course_17.Lecture_48} />
    <PrivateRoute exact path="/course-17/lecture-49" component={Course_17.Lecture_49} />
    <PrivateRoute exact path="/course-17/lecture-50" component={Course_17.Lecture_50} />
    <Route exact path="/course-17/lecture-51" component={Course_17.Lecture_51} />
    <PrivateRoute exact path="/course-17/lecture-52" component={Course_17.Lecture_52} />
    <PrivateRoute exact path="/course-17/lecture-53" component={Course_17.Lecture_53} />
    <PrivateRoute exact path="/course-17/lecture-54" component={Course_17.Lecture_54} />
    <Route exact path="/course-17/lecture-55" component={Course_17.Lecture_55} />
    <PrivateRoute exact path="/course-17/lecture-56" component={Course_17.Lecture_56} />
    <PrivateRoute exact path="/course-17/lecture-57" component={Course_17.Lecture_57} />
    <PrivateRoute exact path="/course-17/lecture-58" component={Course_17.Lecture_58} />
    <PrivateRoute exact path="/course-17/lecture-59" component={Course_17.Lecture_59} />
    <PrivateRoute exact path="/course-17/lecture-60" component={Course_17.Lecture_60} />
    <PrivateRoute exact path="/course-17/lecture-61" component={Course_17.Lecture_61} />
    <Route exact path="/course-17/lecture-62" component={Course_17.Lecture_62} />    
  </>  
)
 
export default courseRoutes_17