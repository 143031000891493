import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-20/img1.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1=
`def plotStructure(label_offset=0.02, xMargin=0.2, yMargin=0.4): 

    fig = plt.figure() #Define a new figure
    axes = fig.add_axes([0.1,0.1,3,3])  #Add a set of axes
    fig.gca().set_aspect('equal', adjustable='box') #Make axis scales equal

    #Plot markers indicating support restraints----------
    for n, Node in enumerate(nodes):       
        node=n+1 #Node number                
        i_hor = 2*node-2 #horizontal DoF at this node
        i_ver = 2*node-1 #vertical DoF at this node                           
        ix = nodes[node-1,0] #x-coord of this node
        iy = nodes[node-1,1] #y-coord of this node
                
        if i_hor in restrainedIndex and i_ver in restrainedIndex:
            axes.plot([ix],[iy],'+',
                  markeredgecolor='black',
                  markersize=50,
                  markeredgewidth=3) 
        elif i_hor in restrainedIndex:
            axes.plot([ix],[iy],'_',
                  markeredgecolor='black',
                  markersize=50,
                  markeredgewidth=3) 
        elif i_ver in restrainedIndex:
            axes.plot([ix],[iy],'|',
                  markeredgecolor='black',
                  markersize=50,
                  markeredgewidth=3) 
            
    #Plot members----------------------------------------
    for mbr in members:  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member                

        ix = nodes[node_i-1,0] #x-coord of node i of this member
        iy = nodes[node_i-1,1] #y-coord of node i of this member
        jx = nodes[node_j-1,0] #x-coord of node j of this member
        jy = nodes[node_j-1,1] #y-coord of node j of this member        

        axes.plot([ix,jx],[iy,jy],color='#33cc99',linestyle='-',lw=2) 

        
    #Plot nodes------------------------------------------
    for n, node in enumerate(nodes):
        axes.plot([node[0]],[node[1]],'o',
                  markerfacecolor='white', 
                  markeredgecolor='black') 
        label = str(n+1) #The node number label string
        axes.text(node[0]+label_offset, node[1]+label_offset, label, fontsize=16) 

    
    #Housekeeping - set axis limits to provide margin around structure
    maxX = nodes.max(0)[0]
    maxY = nodes.max(0)[1]
    minX = nodes.min(0)[0]
    minY = nodes.min(0)[1]
    axes.set_xlim([minX-xMargin,maxX+xMargin])
    axes.set_ylim([minY-yMargin,maxY+yMargin])

    axes.set_xlabel('Distance (m)', fontsize=16)
    axes.set_ylabel('Distance (m)', fontsize=16)
    axes.set_title('Structure to analyse (initial position)', 
      fontsize=16, weight='bold')
    axes.grid()`

const codeString2 = 
`#Call the interact widget---------------------------
widgets.interact(plotStructure, 
                 label_offset=(0.01, 0.1, 0.01), 
                 xMargin=(0.2, 1, 0.1),
                 yMargin=(0.2, 1, 0.1))
plt.show()`


class Lecture_17_20 extends React.Component {
  state={
    course: 17,
    lecture: 20, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll write the code necessary to visualise the structure. This is important because we want to visually confirm that the structure was imported correctly before we continue any further with the analysis. The code to perform the plotting is captured inside a function called <code className={classes.code}>plotStructure()</code>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    By wrapping our plotting code in a function, we can wire the function up to some interactive widgets that call the plotting function every time the widgets are updated. The new updated values of the widgets can then be passed into our plotting function, updating the plot accordingly. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is a pattern we use repeatedly in this and other courses because it gives us a quick way of updating our plots using user-friendly widgets and makes our notebook more interactive. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    For this plot, the only parameters that we'll control with widgets are the x and y margin around the structure and the label offset distance. These are relatively trivial things to manage with widgets, but they'll help you understand how it all works if you haven't used widgets before.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The code block below defines the function. The function can be roughly divided into five sections. First, we do the initial figure setup on lines 3 to 5, adding a figure and set of axes and then making the axis scales equal. Then on lines 8 to 29, we cycle through each node, and if any of the DoFs at a node are restrained, we plot a marker indicating the nature of the restraint. Next, we plot the members on lines 32 to 41. Again, this is done by simply looping through each member and plotting a line between the nodes that define the member. On lines 45-50, we plot the nodes and finally, on lines 54 to 64, we take care of some housekeeping - adding axis labels and setting the margin limits. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that when we set the plot margin limits on lines 58 and 59 and when we add the node number labels on line 50, we make use of the function arguments, which are being controlled by the widgets.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>   

                  <Typography paragraph className={classes.bodytext}>
                    In the code below, we use the <code className={classes.code}>interact</code> method on <code className={classes.code}>ipywidgets</code> to generate our slider widgets. Interact is a shortcut method that allows us to set up simple widgets by passing their definition directly into the function. The function also takes the name of the plotting function as an argument. As mentioned above, this is the function that will be called every time a widget is updated. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The three widgets we're defining are <code className={classes.code}>label_offset</code> that can have values between <MathJax.Node inline formula={"0.01"} /> and <MathJax.Node inline formula={"0.1"} /> in steps of <MathJax.Node inline formula={"0.01"} />, <code className={classes.code}>xMargin</code> and <code className={classes.code}>yMargin</code>, which can both have values between <MathJax.Node inline formula={"0.2"} /> and <MathJax.Node inline formula={"1"} /> in steps of <MathJax.Node inline formula={"0.1"} />. All of these numerical values are found through trial and error and will somewhat depend on the scale of the structure you're plotting. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>   

                  <Typography paragraph className={classes.bodytext}>
                    After executing this code, we end up with some sliders and a plot of the 3-bar catenary, Fig. 1.
                  </Typography>                                                                 
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Slider widgets (top left) and plot of the structure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In the next lecture, we'll start blocking out the main convergence loop for our code. 
                  </Typography>
                                              
                </Grid>                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_20));
