import course1 from "../images/Courses/course-1.jpg";
import course2 from "../images/Courses/course-2.jpg";
import course3 from "../images/Courses/course-3.jpg";
import course4 from "../images/Courses/course-4.jpg";
import course5 from "../images/Courses/course-5.jpg";
import course6 from "../images/Courses/course-6.jpg";
import course7 from "../images/Courses/course-7.jpg";
import course8 from "../images/Courses/course-8.jpg";
import course9 from "../images/Courses/course-9.jpg";
import course10 from "../images/Courses/course-10.jpg";
import course11 from "../images/Courses/course-11.jpg";
import course12 from "../images/Courses/course-12.jpg";
import course13 from "../images/Courses/course-13.jpg";
import course14 from "../images/Courses/course-14.jpg";
import course15 from "../images/Courses/course-15.jpg";
import course16 from "../images/Courses/course-16.jpg";
import course17 from "../images/Courses/course-17.jpg";
import course18 from "../images/Courses/course-18.jpg";
import course19 from "../images/Courses/course-19.jpg";
import course20 from "../images/Courses/course-20.jpg";

import project1 from "../images/Projects/project-1.jpg";
import project2 from "../images/Projects/project-2.jpg";
import project3 from "../images/Projects/project-3.jpg";
import project4 from "../images/Projects/project-4.jpg";

import tool1 from "../images/Tools/solver-truss2D_600.jpg";
import tool2 from "../images/Tools/solver-truss3D_600.jpg";


export const returnImage = (id, type) => {

  if (type=='course'){
    if (id == 1){
      return course1
    } else if(id==2){
      return course2
    }  else if(id==3){
      return course3
    } else if(id==4){
      return course4
    } else if(id==5){
      return course6
    } else if(id==6){
      return course5
    } else if(id==7){
      return course7
    } else if(id==8){
      return course8
    } else if(id==9){
      return course9
    } else if(id==10){
      return course10
    } else if(id==11){
      return course11
    } else if(id==12){
      return course12
  }   else if(id==13){
      return course13
    } else if(id==14){
      return course14
    } else if(id==15){
      return course15
    } else if(id==16){
      return course16
    } else if(id==17){
      return course17
    } else if(id==18){
      return course18
    } else if(id==19){
      return course19
    } else if(id==20){
      return course20
    } else{
      return null
    }	
  } else if(type=='project'){
    if (id==1){
      return project1
    } else if(id==2){
      return project2
    } else if(id==3){
      return project3
    } else if(id==4){
      return project4
    } else{
      return null
    }
  } else if(type=='tool'){
    if (id == 1){
      return tool1
    } else if(id==2){
      return tool2
    } else {
      return null
    }
  }
  
};