import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-34/img1.jpg";

const codeString1 = 
`def plotResult(label_offset=0.02, 
               xMargin=0.2, 
               yMargin=0.2, 
               scaleFactor=1,                                              
               Load_Increment=100,
               Final_config=True):
        
    #Select the correct load increment to display
    if Final_config:
        #Select final load increment
        Load_Increment = -1 
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten() 
    else:
        #Load increment selected based on slider
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten() 
    
    #🚨 Initialise a new figure
    fig = plt.figure() 
    axes = fig.add_axes([0.1,0.1,3,3]) 
    fig.gca().set_aspect('equal', adjustable='box')
    
    
    #🚨 Plot original nodal positions and node number labels
    for n, node in enumerate(nodes):
        axes.plot([node[0]],[node[1]],'o', color='#33cc99') 
        label = str(n+1) #The node number label string
        axes.text(node[0]+label_offset, node[1]+label_offset, label, fontsize=16)
    
    
    #🚨 Plot members
    for n, mbr in enumerate(members):  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member   

        ix = nodes[node_i-1,0] #x-coord of node i of this member
        iy = nodes[node_i-1,1] #y-coord of node i of this member
        jx = nodes[node_j-1,0] #x-coord of node j of this member
        jy = nodes[node_j-1,1] #y-coord of node j of this member

        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member
                
        #Plot each member in undeformed position
        axes.plot([ix,jx],[iy,jy],color='#33cc99',linestyle='--',lw=2) 
        
    #🚨 Housekeeping - set axis limits to provide margin around structure
    maxX = nodes.max(0)[0]
    maxY = nodes.max(0)[1]
    minX = nodes.min(0)[0]
    minY = nodes.min(0)[1]
    axes.set_xlim([minX-xMargin,maxX+xMargin])
    axes.set_ylim([minY-yMargin,maxY+yMargin])
    axes.set_xlabel('Distance (m)', fontsize=16)
    axes.set_ylabel('Distance (m)', fontsize=16)
    if reactionsFlag:
        axes.set_title('Deflected shape, axial forces & reactions 
        (All SW added to reactions - member forces ignore half of 
        SW for members connected to supports)', 
        fontsize=16, weight='bold')
    else:
        axes.set_title('Deflected shape, axial forces & reactions 
        (Half of SW of members connected to supports not added to reactions)',
        fontsize=16, weight='bold')
    axes.grid()
                
#Call the interact widget
rows, convergedIncs = UG_FINAL.shape
widgets.interact(plotResult, 
                 label_offset=(0.01, 0.1, 0.01), 
                 xMargin=(0.2, 5, 0.2),
                 yMargin=(0.2, 5, 0.2),
                 scaleFactor=(1,5,0.5),                 
                 Load_Increment=(10,convergedIncs-1,1))
plt.show()`


class Lecture_17_34 extends React.Component {
  state={
    course: 17,
    lecture: 34, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll advance things by generating a plot of the undeformed structure. Since the code block below is partially repeated from the last lecture, I've highlighted the additions from this lecture with 🚨 icons. I'll use this convention for the rest of this section.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Much of the new code we've added to the <code className={classes.code}>plotResults()</code> function is actually just repeated from lecture 20. Remember, we've already plotted the undeformed structure. The new addition here is the inclusion of a conditional statement which controls the plot title. This conditional statement uses the <code className={classes.code}>reactionsFlag</code> we defined in lecture 31 at the end of the last section. We're changing the title based on whether or not self-wight has been taken into account. This is so that we can give the user some additional context to the plot. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the self-weight was taken into account and <code className={classes.code}>reactionsFlag==True</code>, the reactions were manually updated by us, so we add the following note to the title, <i>(All SW added to reactions - member forces ignore half of SW for members connected to supports)</i>. This way, the user will understand why they don't observe perfect force equilibrium at the supports - because we've manually added additional self-weight to the support reactions that wasn't considered in the stiffness method analysis (because it was force that was directly added to supports).
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On the other hand, if <code className={classes.code}>reactionsFlag==False</code>, we can add the note <i>Half of SW of members connected to supports not added to reactions</i>. This is added because the reactions have not been updated for self-weight.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Executing this code block now yields the following plot of the undeformed structure, Fig. 1.
                  </Typography>                  
                  
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Plot of the undeformed structure.</figcaption>
                </figure>
                
               
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_34));
