//Wrapper component for MUI-datatable to maintain table state between renders
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/utilityActions";
import MUIDataTable from "mui-datatables";

class TableWrapper extends React.Component {
	state = {
		tableState: null,
		rowsSelected: null //Controlled component so store details of rows selected in state
	};

	//Get the rows selected by user from state
	getRowsSelected = () => {
		if (this.state.tableState) {
			if (this.state.tableState.selectedRows.data.length > 0) {
				let array = [];
				this.state.tableState.selectedRows.data.map(val => {
					array.push(val.dataIndex);
				});
				this.setState({
					rowsSelected: array
				});
			} else {
				this.setState({
					rowsSelected: []
				});
			}
		} else {
			this.setState({
				rowsSelected: []
			});
		}
	};

	//Get the search text entered by the user from state
	getSearchText = () => {
		if (this.state.tableState) {
			if (this.state.tableState.searchText) {
				return this.state.tableState.searchText;
			} else {
				return undefined;
			}
		} else {
			return undefined;
		}
	};

	//Get any filters set by the user and update column options to persist filtering
	getColumnOptions = () => {
		if (this.state.tableState) {
			for (let i = 0; i < this.props.columns.length; i++) {
				//Persist any filtering
				this.props.columns[i].options[
					"filterList"
				] = this.state.tableState.filterList[i];

				//Persist column display
				this.props.columns[i].options["display"] = this.state.tableState.columns[
					i
				].display;

				//Persist any column sorting
				this.props.columns[i].options[
					"sortDirection"
				] = this.state.tableState.columns[i].sortDirection;
			}
			return this.props.columns;
		} else {
			return this.props.columns;
		}
	};

	//Intercept row delete function to catch the call, deselect rows and pass the delete call out to parent
	handleRowDelete = index => {
		//Passing onRowsDelete as an option causes onTableChange to be called which allows row selection to be updated
		this.setState({
			rowsSelected: []
		});
		//Pass on call to onRowsDelete to props from here
		this.props.options.onRowsDelete(index);
	};

	//Update state with currently selected rows
	handleRowSelect = (currentSelected, allSelected) => {
		//Pull out an array of selected rows
		let rows = allSelected.map(obj => {
			return obj.index;
		});

		this.setState({
			rowsSelected: rows
		});
	};

	render() {
		//Combine options passed in with options required to persist data state between renders
		const options = {
			...this.props.options, //Props passed into wrapper
			onRowsDelete: index => this.handleRowDelete(index), //Intercepty event handler to force row selection update
			onRowsSelect: (data, meta, index) => this.handleRowSelect(data, meta, index), //Intercept event hander for row select
			onTableInit: (action, tableState) => {
				this.setState({ tableState });
			},
			onTableChange: (action, tableState) => {
				this.setState({ tableState });
			},
			// rowsSelected: this.getRowsSelected(), //Use wrapper state to persist the rows selected between renders
			rowsSelected: this.state.rowsSelected, //Use wrapper state to persist the rows selected between renders
			searchText: this.getSearchText() //Use wrapper state to persist search results between renders
		};

		return (
			<MUIDataTable
				title={this.props.title}
				data={this.props.data} //Data passed directy in as props
				columns={this.getColumnOptions()} //Columns passed directly in as props but modified to persist filtering
				options={options} //Options passed in as props combined with state tableState update options
			/>
		);
	}
}

export default connect(null, actions)(TableWrapper);
