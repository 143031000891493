import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const codeString1 = 
`def solveDisplacements(Ks, F_inequilibrium):
    """
    Standard solving for structural displacements
    """
    restrainedIndex = [x - 1 for x in restrainedDoF] 
    forceVectorRed = copy.copy(F_inequilibrium)# Make a copy of forceVector
    forceVectorRed = np.delete(forceVectorRed,restrainedIndex,0) 
    U = Ks.I*forceVectorRed        
    
    #Build the global displacement vector 
    UG = np.zeros(nDoF) 
    c=0 #Initialise a counter to track how many restraints have been imposed
    for i in np.arange(nDoF):    
        if i in restrainedIndex:
            #Impose zero displacement
            UG[i] = 0        
        else:
            #Assign actual displacement
            UG[i] = U[c]
            c=c+1

    UG = np.array([UG]).T  
    
    return UG`


class Lecture_17_25 extends React.Component {
  state={
    course: 17,
    lecture: 25, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we're back on familiar ground, re-using code we first wrote when building our 2D truss solver. Again, the code is packaged as a function to make it easier to call repeatedly inside our main loop. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To summarise, the structure stiffness matrix is inverted and pre-multiplied by the reduced force vector on line 8. This is the magic line that <i>solves</i> the system of simultaneous equations describing the structure. Recall that the force vector is reduced by eliminating the elements that correspond to restrained degrees of freedom. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This operation yields a reduced vector of displacements, <code className={classes.code}>U</code>. To convert this back into a <i>fullsize</i> displacement vector that contains the zeros that correspond to restrained DoFs, we need to define a template global displacement vector, initially filled with zeros (line 11), <code className={classes.code}>UG</code>. Then we can cycle through it, inserting the non-zero displacement values from <code className={classes.code}>U</code> at the correct locations. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The result is a global displacement vector for the current analysis iteration, which we return at the bottom of the function. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Function to solve for displacements </Typography>

                  <CodeBlock>{codeString1}</CodeBlock> 

                  <Typography paragraph className={classes.bodytext}>
                    In the next lecture, we'll take the displacement we just calculated and calculate the corresponding axial forces.
                  </Typography>                                                                  
                </Grid>
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_25));
