import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-50/img1.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1=
`import bpy
import csv
import numpy as np
import bmesh
obdata = bpy.context.object.data
bm = bmesh.from_edit_mesh(obdata)

#Get selected vertices
vertices = np.empty((0,3),int)
for v in bm.verts:
    if v.select:
        indexNo = v.index+1
        xForceIndex = 2*indexNo-2
        yForceIndex = 2*indexNo-1
                
        vertex = np.array([indexNo, xForceIndex, yForceIndex])
        vertices = np.append(vertices, [vertex], axis=0)
        
#Export vertex coordinates to CSV file 
filename = "/Users/Sean/Desktop/Force-Data.csv"
# writing to csv file  
with open(filename, 'w') as csvfile:  
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(vertices) # writing the data rows  `


class Lecture_17_50 extends React.Component {
  state={
    course: 17,
    lecture: 50, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll export the DoFs for each node that has a point load applied to it. We won't concern ourselves with the magnitude or direction of point loads here, that's handled in our Jupyter Notebook. For the analysis of this structure, we'll apply point loads as indicated in Fig 1.
                  </Typography>                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                    <figcaption className={classes.caption}>Fig 1. Point load locations.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The code we need to write is just another version of what we wrote in the last lecture, without the complication of applying masks. We'll simply compute the x and y DoF numbers for the selected nodes and export them as we've done multiple times already. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Remember to take the exported file, <i>Force-Data.csv</i>, and deposit it with the other files in your data folder. The data folder should now have the following csv files ready for import into our notebook:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Vertices.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Edges.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Cables.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Restraint-Data.csv</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>Force-Data.csv</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    The simulation and data export steps we've demonstrated in this section can now be used to generate the data for any 2D structure. This greatly speeds up the modelling and analysis workflow. Now we're ready to move on and start the analysis in the next section.
                  </Typography>
                  
                </Grid> 

                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_50));
