import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { HtmlTooltip } from "../../../components/common/ToolTip";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InfoIcon from '@material-ui/icons/Info';
import {returnImage} from '../../../Data/ReturnImage';

//Custom styling
const styles = theme => ({  
	card: {    
    border: '1px solid #33cc9950',
    borderRadius:'15px',
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		// minHeight: 375,
		padding: 0,
    background: "linear-gradient(45deg, rgba(06,06,06,1) 0%, rgba(18,24,27,1) 70%, rgba(18,24,27,1) 100%)",    
    height:'100%', 
    width:'100%',        
    "&:hover": {
      border: '1px solid #f13f6450',
      transform: 'scale(101%)',     
      transition: 'transform 0.1s linear'         
		}
	},
	titleContainer: {
		display: "flex",
		justifyContent: "left"
  },
  subtitleContainer: {
		display: "flex",
    justifyContent: "left",
    paddingTop:'0px',
    paddingBottom:'50px'
	},
	iconContainer: {       
    display: "flex",         
    justifyContent: "flex-start",    
  },
  courseButton:{   
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    fontSize:"0.75rem",
    background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(51,204,153,1) 0%, rgba(0,156,103,1) 100%)",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    marginLeft:'auto',    
    color:"#1a1a1a",   
    "&:hover": {
      background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(69,226,173,1) 0%, rgba(106,230,208,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(51,204,153,0.2)"    
    }    
  },
  title: {
		color: "#33cc99",		
		paddingBottom: 0,
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    fontSize: "1.2rem",
	},
  comingSoonLabel:{
    color: "#33cc99",
		fontSize: 18,
		paddingBottom: 0,
    marginLeft:'auto'
  },
	subTitle: {
    textAlign: "left",    
		color: "white",
		fontSize: '1.1rem',    
    fontFamily:"'sofia-pro',sans-serif",
		paddingBottom: 0
	},
	cardAction: {
		display: "block",
		textAlign: "initial"
	},
	spinner: {
		marginTop: "15px",
		marginBottom: "15px",
		color: "white"
	}
});


class ToolCard extends React.Component {

  //Return the appropriate icons
  returnIconSet(tool){
    return(
      <React.Fragment> 
        <HtmlTooltip title={this.returnTooltipText(tool.access)}>            
            {this.returnLockIconSet(tool.access)}       
          </HtmlTooltip>    
          <HtmlTooltip title={this.returnTooltipText("info")}>            
            <a href={tool.parentCourse} target={"_blank"}>
              <InfoIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} /> 
            </a>                    
          </HtmlTooltip>
          {this.returnButton(tool.available)}
          {/* {this.TEMP_returnButton()} */}

      </React.Fragment>     
    )
  }

  //Return padlock based on tool access
  returnLockIconSet(access){
    if(access=='open'){
      return(<LockOpenIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} /> )
    } else {
      return(<LockIcon style={{ fontSize: 20, color: "#33cc99", marginLeft:"20px" }} /> )
    }
  };

  //! TEMPORARY - only in use while toolbox is members only
  TEMP_returnButton(){
    const { classes, tool} = this.props;
    if(this.props.auth){
      return(
        <Button 
          onClick={() => this.props.onClick(tool)}                     
          className={classes.courseButton} 
          size="medium" 
        >
            GO TO TOOLBOX
        </Button>  
      )
    } else {
      return(
        <Typography className={classes.comingSoonLabel}>COMING SOON</Typography> 
      )
    }  
  }

  //Return button or coming soon based on tool availability
  returnButton(available){
    const { classes, tool} = this.props; 
    if(available){
      return(
        <Button 
          onClick={() => this.props.onClick(tool)}                     
          className={classes.courseButton} 
          size="medium" 
        >
            GO TO TOOLBOX
        </Button>  
      )
    } else{
      return(
        // <Typography className={classes.comingSoonLabel}>COMING SOON</Typography> 
        <Typography className={classes.comingSoonLabel}>{tool.message}</Typography> 
      )
    }
  }

  //Return the appropriate text for the tooltip
	returnTooltipText(tip) {
		if (tip==="open") {
			return (
				<React.Fragment>					
					{
						"👍 Open access"
					}
				</React.Fragment>
			);
		} else if(tip==="private"){
      return (
				<React.Fragment>					
					{
						"🔒 This is a member only tool"
					}
				</React.Fragment>
			);
    }
    else if(tip==="info"){
      return (
        <React.Fragment>					
          {
            "📋 Reference course info page"
          }
        </React.Fragment>
      );
    }
		return;
	}

  
  render() {    
		const { classes, tool } = this.props; //Destructure data off the components props
		return (      
      <Card raised={true} className={classes.card}>       
          <CardMedia
            className={classes.media}            
            image={returnImage(tool.toolId,'tool')}
            style={{height: 0, paddingTop: '56.25%'}}            
            title={tool.title}
          />
          <CardContent className={classes.titleContainer}>
            <Typography className={classes.title}>{tool.title}</Typography>
          </CardContent>
          <CardContent className={classes.subtitleContainer}>
            <Typography className={classes.subTitle}><em>{tool.tagline}</em></Typography>
          </CardContent>
          <CardContent className={classes.iconContainer}> 
            {this.returnIconSet(tool)}                                  
          </CardContent>                    
      </Card>      
		);
	}


}
export default withStyles(styles)(ToolCard);