import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_45 extends React.Component {
  state={
    course: 17,
    lecture: 45, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    One of the pain points when it comes to implementing your own solvers is coming up with the geometry that defines your structure. Fortunately, we have access to an amazing open-source modelling tool that fits seamlessly into our workflow - Blender.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this section, we’ll use Blender and demonstrate how you can use it to generate and export the data that fully defines your structure. If you’re not familiar with Blender, I’ve included an appendix section at the end of the course that will get you up and running with the software. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In addition to manual modelling, in this section, we’ll also introduce some of Blender’s extended functionality - in particular, its cloth simulation tools. Blender has an extensive physics engine, and we can use this as a form-finding tool to generate the initial geometry of our cables. On larger, more complex structures, this is going to be a huge time saver. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    At the end of this section, you will have a better understanding of just how useful Blender can be to you as an Engineer and you’ll have a good understanding of how to harness its potential.   
                  </Typography>            
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_45));
