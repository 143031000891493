import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import history from "../../../history";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses.js';
import CourseCard from './CourseCard'
import DropdownMenu from "../../../components/common/DropdownMenu";

//Course filter options
const options=[
  {    
    filter: 'All courses' 
  },
  {  
    filter: 'Video & text' 
  },
  {    
    filter: 'Video only' 
  }  
]

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5)    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {
		// marginTop: theme.spacing(5),
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	}
});

class Courses extends React.Component {
  state = {
		filter: "All courses"
	};

  componentDidMount() {  
    window.scrollTo(0, 0);
  }

  //Handle course click
  handleCourseClick = (course)=>{        
    //Send user to coursepage
		history.push({
			pathname: "/" + course.path,
		});
  }

  //Required when combining Matrial UI <TextField/> with redux-form
	returnCourseList() {

    let displayCourses;

    if(this.state.filter=='Video only'){
      displayCourses = CourseData.courseList.filter(course=>{
        if((course.text==false)&&(course.video==true)){
          return true
        } else {
          return false
        }
      })
    } else if (this.state.filter=='Video & text'){      
      displayCourses = CourseData.courseList.filter(course=>{
        if((course.text==true)&&(course.video==true)){
          return true
        } else {
          return false
        }
      })
    } else {
      displayCourses = CourseData.courseList
    }

		return displayCourses.map(course => {
      if(course.visible){

        //Determine id of completed lectures for the current course
        let lectures = this.props.compLectures.filter(l =>{
          if(l.courseId == course.courseId && l.format=="lecture"){
            return l
          }     
        })    
        let ids = []
        lectures.forEach(l=>{
          ids.push(l.lectureId) 
        })  

        return (            
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={course.courseId}>              
              <CourseCard 
              user={this.props.auth}               
              course={course}
              ids={ids}
              onClick={course => this.handleCourseClick(course)}
            />
          </Grid>
        );
      } else{
        return null
      }      		
    });			   
	}


  //Called when team in changed in child component - initiate data swap over (to new team)
	onChangeCourse = (newFilter) => {
    this.setState({filter:newFilter})        
  }

  //Return team selection if multiple profiles are available for the user
	returnCourseSelect = () => {
		const { classes } = this.props;			
    return (
      <React.Fragment>        
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} >
          <DropdownMenu
            initialValue={'All courses'}
            options={options}
            onChangeValue={this.onChangeCourse}
          />
        </Grid>
      </React.Fragment>
    );	
  };


	render() {		
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={"COURSES"}
				>
        <Grid container justify="flex-end" spacing={4} style={{paddingLeft:"5%", paddingRight:"5%"}}>                
          {this.returnCourseSelect()} 
        </Grid>
        <Grid container justify="center" spacing={4} style={{paddingLeft:"5%", paddingRight:"5%", marginBottom:"335px"}}>                 
          {this.returnCourseList()}          
        </Grid>
        </Layout>
		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
    compLectures: state.completedLectures
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(Courses));
