import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Feedback from "../utils/Feedback";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CameraIcon from "@material-ui/icons/CameraAlt";
import DataIcon from "@material-ui/icons/Storage";
import TutorialIcon from "@material-ui/icons/School";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import FunctionsIcon from "@material-ui/icons/Functions";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import TableWrapper from "../../components/utils/TableWrapper"
import Papa from "papaparse"
import Truss3DScene from "../3D/StructureWorks/Truss3DScene";
import { Leva } from 'leva'
import Modal from "@material-ui/core/Modal";
import ToolData from '../../Data/tools';
import CourseImage from "../../images/Courses/course-13.jpg";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

//Extract data for the 3D Spaceframe solver
const pollingFrequency = 1; //The frequency of data polling in seconds
const freeElementLimit = ToolData.toolList[1].limits.elements
const freeRestraintLimit = ToolData.toolList[1].limits.restraints
const changeLog = ToolData.toolList[1].changeLog

const nodesPlaceholder = `Format: [ nodeId, xCoord (m), yCoord (m), zCoord (m) ]
So, input nodal coordinates like this...

1,0,6,3
2,4,6,3
...
...

Or, paste csv data directly (recommended)`

const elementsPlaceholder =`Format: 
[ memberId, nodeI, nodeJ, E (N/m^2), A (m^2), Stress (N/m^2), gamma (N/m^3)]
So, input member definitions like this...

1, 1, 2, 2E+11, 0.005, 2.75E+08, 78000
2, 2, 3, 2E+11, 0.005, 2.75E+08, 78000
...

Or, paste csv data directly (recommended)`

const restraintsPlaceholder =`Format: [ nodeId, Rx, Ry, Rz ]
TRUE => restrained, FALSE => free
e.g. pin at node 1 => 1, TRUE, TRUE, TRUE 
e.g. roller in x at node 5 => 5, FALSE, TRUE, TRUE
So, define restraints like this...

1, TRUE, TRUE, TRUE 
5, FALSE, TRUE, TRUE 
...
Or, paste csv data directly (recommended)`

const loadsPlaceholder =`Format: [ nodeId, Fx (N), Fy (N), Fz (N) ]
Force sign convention matches global axis system
So, define applied forces like this...

2, 0, 0, -10000
3, 0, 0, -30000
4, 0, 0, -5000
...
Or, paste csv data directly (recommended)`

//Round numbers to set decimal places
const roundOff = (num, places) => {
  const x = Math.pow(10,places);
  return Math.round(num * x) / x;
}

//Reactions table column configuration
const columnsReactions = [  
	{
		name: "nodeId",
		label: "Node Number",
		options: {
			filter: true,
			sort: true
		}
	},
  {
    name: "rType",
		label: "Support Type",
		options: {
			filter: true,
			sort: true
		}
  },
  {
		name: "xReaction",
		label: "Reaction Rx (N)",
		options: {
			filter: true,
			sort: true,
      customBodyRender: value => (roundOff(value,3))
		}
  },
  {
		name: "yReaction",
		label: "Reaction Ry (N)",
		options: {
			filter: false,
			sort: true,   
      customBodyRender: value => (roundOff(value,3))   
		}
	},
  {
		name: "zReaction",
		label: "Reaction Rz (N)",
		options: {
			filter: false,
			sort: true,   
      customBodyRender: value => (roundOff(value,3))   
		}
	}
];

//Axial forces table column configuration
const columnsAxial = [
  {
		name: "memberId",
		label: "Member Number",
		options: {
			filter: true,
			sort: true
		}
	},
  {
    name: "nodeI",
		label: "Node I",
		options: {
			filter: true,
			sort: true
		}
  },
  {
		name: "nodeJ",
		label: "Node J",
		options: {
			filter: true,
			sort: true,
		}
  },
  {
		name: "force",
		label: "Axial Force (N)",
		options: {
			filter: false,
			sort: true,  
      customBodyRender: value => (roundOff(value,3))    
		}
	},
  {
		name: "utilisation",
		label: "Stress Utilisation (%)",
		options: {
			filter: false,
			sort: true,        
		}
	}
];

//Nodal displacements table column configuration
const columnsDisp = [
  {
		name: "nodeId",
		label: "Node Number",
		options: {
			filter: true,
			sort: true
		}
	},
  {
    name: "xDisp",
		label: "x Displacement (m)",
		options: {
			filter: true,
			sort: true,
      customBodyRender: value => (roundOff(value,6))
		}   
  },
  {
		name: "yDisp",
		label: "y Displacement (m)",
		options: {
			filter: true,
			sort: true,
      customBodyRender: value => (roundOff(value,6))
		}
  },
  {
		name: "zDisp",
		label: "z Displacement (m)",
		options: {
			filter: true,
			sort: true,
      customBodyRender: value => (roundOff(value,6))
		}
  },
  {
		name: "mag",
		label: "Displacement Magnitude (m)",
		options: {
			filter: false,
			sort: true,  
      customBodyRender: value => (roundOff(value,6))    
		}
	},

];

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5),
    background: "linear-gradient(45deg, rgba(06,06,06,1) 0%, rgba(18,24,27,1) 70%, rgba(18,24,27,1) 100%)", 
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {
		fontSize: "1.75rem",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},	
	listItem: {
		paddingTop: "20px"
	},
  solveButton:{      
    background: "linear-gradient(31deg, rgba(241,63,100,1) 0%, rgba(241,63,100,1) 0%, rgba(255,93,127,1) 100%)",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    color:"#fff",       
    "&:hover": {
      background: "linear-gradient(31deg, rgba(241,63,100,1) 0%, rgba(255,74,111,1) 0%, rgba(255,139,164,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(241,63,100,0.2)"
    }, 
  },
  toolButton:{     
    background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(51,204,153,1) 0%, rgba(0,156,103,1) 100%)",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
    color:"#1a1a1a",   
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    "&:hover": {
      background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(69,226,173,1) 0%, rgba(106,230,208,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(51,204,153,0.2)"
    }, 
  },
  exampleButton:{       
    background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(51,204,153,1) 0%, rgba(0,156,103,1) 100%)",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
    color:"#1a1a1a",   
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    "&:hover": {
      background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(69,226,173,1) 0%, rgba(106,230,208,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(51,204,153,0.2)"
    }, 
       
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  modal: {
		position: "absolute",
		width: 800,
		backgroundColor: theme.palette.background.default,
		border: "2px solid #000",
		borderColor: theme.palette.secondary.main,
    borderRadius:"25px",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(5)
	},
  iframeContainer: {        
		overflow: "hidden",
		// Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
		paddingTop: "56.25%",
		position: "relative",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.75)",
    borderRadius:"15px"
  },
	iframe: {    
		border: "0",
		height: "100%",
		left: "0",
		position: "absolute",
		top: "0",
		width: "100%",
  },
  H1: {
    fontSize: "2.5rem",
    marginBottom:"20px",   
    color: "#33cc99", 
	},
	H2: {
		fontSize: "1.5rem",    
	},
	bodytext: {
		fontSize: "1.1rem"
	},
  image:{    
    // margin:"10px auto",
    marginBottom:"0px",
    borderRadius:"5px"
  },
  card: {    
    border: '1px solid #33cc9950',
    borderRadius:'15px',
		padding: '5px',
    background: "#2a2e35", 
    marginBottom:'15px'              		
	},
  sliderButton:{
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
    color:"#3c9"
  }
});
class SolverTruss3D extends React.Component {
  state={
    showDataEntry: true, //Show data entry block by default
    showAnalysisReport: false, //Don't show analysis report block by default  
    showChangeLog: false, //Don't show change log by default  
    nodes: null, //Store node definitions
    elements:null, //Store element definitions
    restraints: null, //Store Restraint definitions
    forces: null, //Store force definitions
    nodesError: null, //Store node data input error message
    elementsError: null, //Store element data input error message
    restraintsError: null, //Store restraint data input error message
    forcesError: null, //Store force data input error message   
    tutorialModalOpen: false, //Tutorial modal showing overview video
    membershipModalOpen: false, //Membership modal prompting signup for guest users
    termsModalOpen: true, //Modal for user to accept terms before use,
    solverErrorModalOpen: false, //Model to report error retreiving simulation results
    solverError:false, //Flag to indicate if there was a solver error 
    includeSW:false
  }

  componentDidMount() {  
    window.scrollTo(0, 0);
    this.props.clearResultsTruss3D()    
  }

  componentDidUpdate(){    
    if (this.props.truss3D.report){         
      clearInterval(this.dataPolling);
    }
  }

  componentWillUnmount(){
    this.props.clearResultsTruss3D()
  }

  //Return modal position style attributes
	getModalStyle = () => {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`
		};
	};

	//Switch modal open flag in state to open
	handleModalOpen = (modal) => {
    if(modal==='tutorialModal'){
      this.setState({
        tutorialModalOpen: true
      });
    } else if(modal==='membershipModal'){
      this.setState({
        membershipModalOpen: true
      });
    } else if(modal==='termsModal'){
      this.setState({
        termsModalOpen: true
      });
    } else if(modal==='errorModal'){
      this.setState({
        solverErrorModalOpen: true
      });
    }
		
	};

	//Switch modal open flag in state to closed
	handleModalClose = (modal) => {
    if(modal==="tutorialModal"){
      this.setState({
        tutorialModalOpen: false
      });
    } else if(modal==="membershipModal"){
      this.setState({
        membershipModalOpen: false
      });
    }	else if(modal==='termsModal'){
      this.setState({
        termsModalOpen: false
      });
    } else if(modal==='errorModal'){
      this.setState({
        solverErrorModalOpen: false
      });
    }
	};

  //Generate a random jobId
  makeid = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
   return result;
  }

  //Define a function to check for duplicate elements in an array
  hasDuplicates = (array) =>{
    return new Set(array).size !== array.length
  }

  //Parse and validate user input data
  cleanAndValidateData = () =>{    
    let error = false //Initialise an error flag
    let nodesRaw = this.state.nodes
    let elementsRaw = this.state.elements
    let restraintsRaw = this.state.restraints
    let forcesRaw = this.state.forces
    let restraintCount = 0

    //Check for empty fields
    error = this.checkEmptyFields(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return}  

    //Check for empty rows and rows with wrong number of elements
    error = this.checkRowElementNumber(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return} 

    //Make sure only numbers and true/false values submitted
    error = this.checkRowElementType(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return}     

    //Make sure no duplicate node numbers
    error = this.checkDuplicates(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return}   

    //Make sure only existing nodes used to define members
    error = this.checkMemberNodes(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return}    

    //Make sure restraints are on existing nodes
    error = this.checkRestraintNodes(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return}    

    //Make sure forces are applied to existing nodes  
    error = this.checkForceNodes(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)  
    if(error){return}

    //Make sure enough support restraints
    let [returned_error, returned_restraintCount] = this.checkRestraintNumber(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    error = returned_error
    restraintCount = returned_restraintCount
    if(error){return}    

    //Make sure all nodes are adequately restrained
    error = this.checkNodalRestraint(nodesRaw, elementsRaw, restraintsRaw, forcesRaw)
    if(error){return}
      
    return [nodesRaw, elementsRaw, restraintsRaw, forcesRaw, restraintCount]
  }

  //Parse cleaned data for onward transmission to API
  packageData = (nodesClean, elementsClean, restraintsClean, forcesClean) =>{

    let data = {
      nodes:[],
      members:[],
      restraints:[],
      appliedForces:[],
    }

    nodesClean.forEach(node=>{
      let row = {
        nodeId: node[0],
        xCoord: node[1],
        yCoord: node[2],
        zCoord: node[3]
      }
      data.nodes.push(row)
    })

    elementsClean.forEach(mbr =>{
      let row={
        memberId: mbr[0],
        nodeI: mbr[1],
        nodeJ: mbr[2],
        E: mbr[3],
        area: mbr[4],
        yieldStress: mbr[5],
        gamma: mbr[6]
      }
      data.members.push(row)
    })

    restraintsClean.forEach(r =>{
      let row = {
        nodeId: r[0],
        xRestraint: r[1],
        yRestraint: r[2],
        zRestraint: r[3]
      }
      data.restraints.push(row)
    })
    
    if(forcesClean){
      forcesClean.forEach(f=>{
        let row = {
          nodeId: f[0],
          xForce: f[1],
          yForce: f[2],
          zForce: f[3]
        }
        data.appliedForces.push(row)
      })
    }
    return data
  }

  //Submit analysis job
  handleAnalysisSubmit  = () => {
    this.props.clearResultsTruss3D()

    //Reset errors in state
    this.setState({
      nodesError:null,
      elementsError: null,
      restraintsError: null,
      forcesError: null,
    })

    let nodesClean, elementsClean, restraintsClean, forcesClean, restraintCount, data, jobId
  
    //Clean and validate data
    try{            
      let [r_nodesClean, r_elementsClean, r_restraintsClean, r_forcesClean, r_restraintCount] = this.cleanAndValidateData()      
      nodesClean = r_nodesClean
      elementsClean = r_elementsClean
      restraintsClean = r_restraintsClean
      forcesClean = r_forcesClean
      restraintCount = r_restraintCount
    } catch(err){                
      this.props.setFlash({type:"error", message:"Error validating user input data"})
      return
    }

    //Test against element and restraint limits for guest users
    try{      
      if(!this.props.auth){        
        if((elementsClean.length> freeElementLimit) && (restraintCount >freeRestraintLimit)){
          this.props.setFlash({type:"error", message:`You've exceeded the restraint (${freeRestraintLimit}) and element (${freeElementLimit}) limits for guest users`})
          this.setState({
            membershipModalOpen: true
          });
          return
        } else if(elementsClean.length> freeElementLimit){
          this.props.setFlash({type:"error", message:`You've exceeded the element limit (${freeElementLimit}) for guest users`})
          this.setState({
            membershipModalOpen: true
          });
          return
        } else if(restraintCount >freeRestraintLimit){
          this.props.setFlash({type:"error", message:`You've exceeded the restraint limit (${freeRestraintLimit}) for guest users`})
          this.setState({
            membershipModalOpen: true
          });
          return
        }
      }      
    } catch {      
      this.props.setFlash({type:"error", message:"Error checking structure size"})
      return
    }

    //If no errors validating data, package data for export
    try{
      data = this.packageData(nodesClean, elementsClean, restraintsClean, forcesClean)     
      jobId = this.makeid(10)      
      data['jobId'] = jobId
      data['jobType'] = 'truss3D'
      data['includeSW'] = this.state.includeSW      
    } catch{        
      this.props.setFlash({type:"error", message:"Error processing user input data"})
      return
    }
     
    //Submit job to API
    try{            
      this.props.submitTruss3D(data)    
      //Start polling database for results
      let i=0      
      this.dataPolling = setInterval(() => {            
        this.props.fetchResultsTruss3D(jobId)
        this.setState({
          showAnalysisReport:true,
          showDataEntry:false
        })  

        i+=1
        const itLimit=15 //Limit on how long we'll wait for results to be returned       
        if(i==itLimit){                
          clearInterval(this.dataPolling)                                              
          this.props.reportFailedAnalysisTruss3D(jobId)
          this.setState({
            solverErrorModalOpen:true
          })
        }
      }, pollingFrequency * 1000);
    } catch{        
      this.props.setFlash({type:"error", message:"Error submitting analysis job"})
    } 
  }

  //Check to see if an error message is required for attributes name and comment fields
	returnError(type) {
		if (type === "nodes" && this.state.nodesError) {
			return (
				<div style={{color:"#f44336"}}>
          <ErrorIcon style={{color:"#f44336", fontSize: 20}} />
					{this.state.nodesError}
				</div>
			);
		}

    if (type === "elements" && this.state.elementsError) {
			return (
				<div style={{color:"#f44336"}}>
          <ErrorIcon style={{color:"#f44336", fontSize: 20}} />
					{this.state.elementsError}
				</div>
			);
		}

    if (type === "restraints" && this.state.restraintsError) {
			return (
				<div style={{color:"#f44336"}}>
          <ErrorIcon style={{color:"#f44336", fontSize: 20}} />
					{this.state.restraintsError}
				</div>
			);
		}

    if (type === "forces" && this.state.forcesError) {
			return (
				<div style={{color:"#f44336"}}>
          <ErrorIcon style={{color:"#f44336", fontSize: 20}} />
					{this.state.forcesError}
				</div>
			);
		}
		return;
	}

  //Toggle visibility of data entry block
  toggleDataEntryVis = ()=>{
    this.setState({
      showDataEntry:!this.state.showDataEntry
    })
  }

  //Toggle visibility of change log block
  toggleChangeLogVis = ()=>{
    this.setState({
      showChangeLog:!this.state.showChangeLog
    })
  }

  //Required when combining Matrial UI <TextField/> with redux-form
	renderTextArea({ id, name, input, label, placeholder, ...custom }) {
		return (
        <TextField
        style={{
          backgroundColor: "#13131350"
        }}
        InputProps={{
            style: {
                color: "#33cc99",
                backgroundColor: "#2a2e35"
            }
        }}  
        InputLabelProps={{
          style:{
            color:"#33cc99"
          }
        }}                   
        color="secondary" 
        variant="outlined"
				id={id}
				name={name}
				label={label}
        placeholder={placeholder}
				multiline
				rows="10"
				fullWidth
        margin="normal"
				{...input}
				{...custom}        
			/>
		);
	}

  //Update state with form values
  handleChange = name => event => {	

    //Flush solver data and reset 3D view options if data entry area changed after results calculated
    if (this.props.truss3D.report){              
      this.props.clearResultsTruss3D()
      this.setState({        
        showAnalysisReport: false, 
        showWire:false, 
        showReactions:false, 
        showAxialForces:false, 
        showAxialStresses:false, 
        showDeflections:false, 
        sliderScale: 1
      })      
    }

    let data = Papa.parse(event.target.value, {dynamicTyping: true}).data
    this.setState({[name]:data})	
	};

  //Return the 3D view
  return3DView = () =>{
    const { classes } = this.props;	

    return(
      <Grid item xs={12} sm={11}>
        <Paper className={classes.paper} elevation={2}>
          <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography              
                variant="h2"
                align="left"
                className={classes.subTitle}
                color="secondary"              
              >
                STRUCTURE
              </Typography>
            </Grid>
            <Grid item xs={9} sm={9} md={9} >
              <Truss3DScene 
                nodes={this.state.nodes}
                elements={this.state.elements}
                restraints={this.state.restraints}
                forces={this.state.forces}              
                nodalDisplacements={this.props.truss3D.nodalDisplacements?this.props.truss3D.nodalDisplacements:null}
                reactions={this.props.truss3D.reactions?this.props.truss3D.reactions:null}
                memberForces={this.props.truss3D.memberForces?this.props.truss3D.memberForces:null}                                     
              />                                  
            </Grid>

            <Grid item xs={3} sm={3} md={3} style={{height:"700px"}}>
              <div className='Leva-panel' style={{ position: 'relative', zIndex: 100, }}>    
                  <Leva                                     
                    fill={true}                  
                    titleBar={false}
                    oneLineLabels={true}      
                    collapsed={false}        
                  />
              </div>
            </Grid>
            
            <Grid item >
              {this.returnHelperButtons()}
            </Grid>

          </Grid>        
        </Paper>
      </Grid>
    );
  }

  // //Save an image of the HTML canvas element
  // takeCanvasSnapShot = () =>{           
  //   const viewport = document.getElementById('viewport')
  //   const canvas = viewport.firstChild    

  //   // const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  //   // window.location.href=image; 

  //   const link = document.createElement('a')
  //   link.setAttribute('download', 'Screenshot.png')
  //   link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  //Return view button for the 3D view
  returnHelperButtons = () =>{
    const { classes } = this.props;	

    return(
      <Grid container direction="row" display="flex" justify="flex-start" spacing={2}>         
        <Grid item>  
          <Button 
            className={classes.exampleButton} 
            size="small"            
            onClick={modal => this.handleModalOpen("tutorialModal")}>   
            <TutorialIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />         
            TUTORIAL
          </Button> 
        </Grid> 

        <Grid item>  
          <Button 
            className={classes.exampleButton} 
            style={{color:'#1a1a1a'}}
            size="small"
            href={'https://docs.google.com/spreadsheets/d/1FmxJPeD_yA1AKOaWs_-vYf5IIV2LSzM9oZJAuyBxsNo/edit?usp=sharing'}
            target={'_blank'}
            >   
            <DataIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />         
            EXAMPLE DATA
          </Button>           
        </Grid> 
        <Grid item>           
          <Button 
            className={classes.exampleButton} 
            style={{color:'#1a1a1a'}}
            size="small"
            target={'_blank'}
            onClick={() =>this.props.download3DSpaceTemplate()}
            >  
            <DownloadIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />          
            MODELLING TEMPLATE
          </Button> 
        </Grid> 
        {/* <Grid item>           
          <Button 
            className={classes.exampleButton} 
            style={{color:'#1a1a1a'}}
            size="small"
            target={'_blank'}
            onClick={() => this.takeCanvasSnapShot()}
            >  
            <CameraIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />          
            SNAPSHOT
          </Button> 
        </Grid>  */}
                          
      </Grid> 
    );
  }

  //Return add for parent course and membership
  returnGuestAdd = () => {
    if(!this.props.auth){
      const { classes } = this.props;	
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2} style={{background: "linear-gradient(31deg, rgba(42,46,53,1) 0%, rgba(42,46,53,1) 0%, rgba(26,27,29,1) 100%)", border:"solid .5px rgba(51, 204, 153, .2)"}}>
            <Grid container direction="row" display="flex" justify="space-between" spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"              
                >
                  🎓 Learn how this toolbox works...by building your own
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} >
                <figure style={{width:"100%"}}>
                  <a href="https://www.degreetutors.com/the-direct-stiffness-method-for-truss-analysis-with-python/" target="_blank">
                    <img className={classes.image} src={CourseImage} /> 
                  </a>                                 
                </figure>                        
              </Grid>
    
              <Grid item xs={8} sm={8} md={8}>
                <Typography paragraph className={classes.bodytext}>
                  After completing this course you'll have your own 3D truss analysis toolbox, built from the ground up in Python. More importantly, you'll understand exactly how and why it works and have the skills to keep expanding it.
                </Typography> 
                <Typography paragraph className={classes.bodytext}>
                  Access this course or, unlock the complete DegreeTutors course library with an annual membership or one-off lifetime purchase of all current <b><u>and future</u></b> courses!                     
                </Typography> 
                <Grid container direction="row" display="flex" justify="flex-start" spacing={2} >         
                  <Grid item>  
                    <Button 
                      className={classes.exampleButton} 
                      size="small" 
                      style={{color:'#1a1a1a'}}          
                      href={'https://www.degreetutors.com/3d-truss-analysis-using-the-direct-stiffness-method/'}
                      target={'_blank'}>   
                      <ArrowBackIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />         
                      THIS COURSE 
                    </Button> 
                  </Grid> 

                  <Grid item>  
                    <Button 
                      className={classes.exampleButton} 
                      style={{color:'#1a1a1a'}}
                      size="small"
                      href={'https://www.degreetutors.com/membership/'}
                      target={'_blank'}
                      >   
                      <LockOpenIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />         
                      ALL ACCESS MEMBERSHIP
                    </Button>           
                  </Grid> 
                  <Grid item>           
                    <Button 
                      className={classes.exampleButton} 
                      style={{color:'#1a1a1a'}}
                      size="small"
                      target={'_blank'}
                      href={'https://www.degreetutors.com/all-access-lifetime-bundle/'}
                      >  
                      <TimerOffIcon style={{color:"#1a1a1a", fontSize: 20, paddingRight:'2px'}} />          
                      LIFETIME ALL ACCESS
                    </Button> 
                  </Grid>                  
                                    
                </Grid> 
              </Grid>                        

            </Grid>        
            
          </Paper>
        </Grid>
      );


    } else {
      return null
    }

  }

  //Return dataentry block
  returnDataEntry = () =>{
    const { classes } = this.props;	

    if(this.state.showDataEntry){
      return( 
        <Grid item xs={12} sm={11}>             
          <Paper className={classes.paper} elevation={2}>

            <Grid container direction="row" display="flex" justify="space-between">
              <Grid item >
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  DATA INPUT
                </Typography>
              </Grid>
              <Grid item>

              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.showDataEntry,
                })}
                onClick={this.toggleDataEntryVis}
                aria-expanded={this.state.showDataEntry}
                aria-label="show more"
              >
                <ExpandMoreIcon style={{color:"#f13f64"}}/>
              </IconButton>
              </Grid>
            </Grid>

            
              <Grid container direction="row" display="flex" justify="space-between" spacing={4}>
                <Grid item xs={10} sm={10} md={6} lg={3}>
                  <Field                                                      
                    name="nodes"
                    id="nodes"
                    label="1. Nodal coordinates"
                    placeholder={nodesPlaceholder}
                    component={this.renderTextArea}
                    onChange={this.handleChange("nodes")}
                  />
                  {this.returnError("nodes")}
                </Grid>   

                <Grid item xs={10} sm={10} md={6} lg={3}>
                  <Field
                    name="elements"
                    id="elements"
                    label="2. Element definitions"
                    placeholder={elementsPlaceholder}
                    component={this.renderTextArea}
                    onChange={this.handleChange("elements")}
                  />
                  {this.returnError("elements")}
                </Grid>  

                <Grid item xs={10} sm={10} md={6} lg={3}>
                  <Field
                    name="restraints"
                    id="restraints"
                    label="3. Restraint definitions"
                    placeholder={restraintsPlaceholder}
                    component={this.renderTextArea}
                    onChange={this.handleChange("restraints")}
                  />
                  {this.returnError("restraints")}
                </Grid>  

                <Grid item xs={10} sm={10} md={6} lg={3}>
                  <Field
                    name="loads"
                    id="loads"
                    label="4. Imposed load definitions"
                    placeholder={loadsPlaceholder}
                    component={this.renderTextArea}
                    onChange={this.handleChange("forces")}
                  />
                  {this.returnError("forces")}
                </Grid>                
              </Grid>            

            <Grid container direction="row" justify="flex-end" alignItems="center">  
            <Grid item style={{paddingRight:'20px'}}>
              <FormControlLabel
                labelPlacement="start"
                label={<span className={classes.sliderButton}>Include self-weight</span>}
                control={
                  <Switch                    
                    checked={this.state.includeSW}
                    onChange={() =>{this.setState({includeSW: !this.state.includeSW})}}
                    name="self-weight"                                        
                  />
                }                
              />              
            </Grid>
            <Grid item>                  
                <Button 
                  className={classes.solveButton} 
                  size="large"
                  onClick={() => this.handleAnalysisSubmit()} 
                >
                  <FunctionsIcon style={{color:"#fff", fontSize: 20, paddingRight:'2px'}} /> 
                  SOLVE
                </Button>   

              </Grid> 
            </Grid>

          </Paper>   
        </Grid>      
      );
    } else{
      return(
        <Grid item xs={12} sm={11}> 
          <Paper className={classes.paper} elevation={2}>
            <Grid container direction="row" display="flex" justify="space-between">
              <Grid item>
                <Typography
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  DATA INPUT
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.showDataEntry,
                  })}
                  onClick={this.toggleDataEntryVis}
                  aria-expanded={this.state.showDataEntry}
                  aria-label="show more"
                >
                  <ExpandMoreIcon style={{color:"#f13f64"}}/>
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  }

  //Return analysis report block
  returnReportBlock = () =>{
    const { classes } = this.props;

    if(this.state.showAnalysisReport){
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2}>

            <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
              <Grid item >
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  ANALYSIS REPORT
                </Typography>
              </Grid>                    
            </Grid>

            <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
              <Grid item xs={12} sm={12}>
                <List dense={true}>
                  {this.returnReportContents()}   
                </List> 
              </Grid>
            </Grid>
          </Paper> 
        </Grid>  
      );
    } else{
      return null
    }
  }

  //Return analysis report list items
  returnReportContents = ()=>{
    const { classes } = this.props;
    if(this.props.truss3D.report){
      return Object.keys(this.props.truss3D.report).map(item => {
        return(
          <ListItem key={item}>
            <ListItemIcon>
              {this.props.truss3D.flags[item]=='pass' ? <CheckCircleIcon color="secondary" />:<ErrorIcon style={{color:"#f44336"}} />} 
             
            </ListItemIcon>
            <ListItemText              
              primary={this.props.truss3D.report[item]}                           
            />
          </ListItem>
        );
      })
    }
    return null
  }

  //Return reactions block
  returnReactionsBlock = () =>{
    const { classes } = this.props;

    if(this.state.showAnalysisReport){
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2}>

            <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  REACTIONS
                </Typography>
              </Grid>                    
            </Grid>

            <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
                <TableWrapper
                  data={this.props.truss3D.reactions? this.props.truss3D.reactions:[]}
                  columns={columnsReactions}
                  options={{			
                    selectableRows:false,
                    download: this.props.auth? true : false,			
                    print: false,			
                  }}
                />      
              </Grid>
            </Grid>
          </Paper>   
        </Grid>
      );
    } else{
      return null
    }
  }

  //Return Axial Forces block
  returnAxialForcesBlock = () =>{
    const { classes } = this.props;

    if(this.state.showAnalysisReport){
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2}>

            <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
              <Grid item >
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  AXIAL FORCES
                </Typography>
              </Grid>                    
            </Grid>

            <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
                <TableWrapper
                  data={this.props.truss3D.memberForces?this.props.truss3D.memberForces:[]}
                  columns={columnsAxial}
                  options={{			
                    selectableRows:false,
                    download: this.props.auth? true : false,			
                    print: false,			
                  }}
                />      
              </Grid>
            </Grid>
          </Paper>   
        </Grid>
      );
    } else{
      return null
    }
  }

  //Return Axial Forces block
  returnDisplacementsBlock = () =>{
    const { classes } = this.props;

    if(this.state.showAnalysisReport){
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2}>

            <Grid container direction="row" display="flex" justify="space-between" spacing={6} style={{marginBottom:"10px"}}>
              <Grid item >
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  NODAL DISPLACEMENTS
                </Typography>
              </Grid>                    
            </Grid>

            <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
                <TableWrapper
                  data={this.props.truss3D.nodalDisplacements?this.props.truss3D.nodalDisplacements:[]}
                  columns={columnsDisp}
                  options={{			
                    selectableRows:false,
                    download: this.props.auth? true : false,			
                    print: false,			
                  }}
                />      
              </Grid>
            </Grid>
          </Paper>   
        </Grid>
      );
    } else{
      return null
    }
  }

  //Return Change Log
  returnChangeLogBlock = () =>{
    const { classes } = this.props;

    if(this.state.showChangeLog){
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2}>

            <Grid container direction="row" display="flex" justify="space-between">
              <Grid item >
                <Typography              
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  <span>CHANGE LOG <span style={{color:'#f13f64', fontSize:'medium'}}>(v {changeLog[0].version})</span></span>
                </Typography>
              </Grid>
              <Grid item>

              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.showChangeLog,
                })}
                onClick={this.toggleChangeLogVis}
                aria-expanded={this.state.showChangeLog}
                aria-label="show more"
              >
                <ExpandMoreIcon style={{color:"#f13f64"}}/>
              </IconButton>
              </Grid>
            </Grid>
            
            <Grid container direction="row" display="flex" justify="space-between" spacing={4}>
              <Grid item xs={10} sm={12}>              
                {this.returnLogContents()}
              </Grid>                                             
            </Grid>                      

          </Paper> 
        </Grid>
      );
    } else {
      return(
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper} elevation={2}>
            <Grid container direction="row" display="flex" justify="space-between">
              <Grid item>
                <Typography
                  variant="h2"
                  align="left"
                  className={classes.subTitle}
                  color="secondary"
                >
                  <span>CHANGE LOG <span style={{color:'#f13f64', fontSize:'medium'}}>(v {changeLog[0].version})</span></span>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.showChangeLog,
                  })}
                  onClick={this.toggleChangeLogVis}
                  aria-expanded={this.state.showChangeLog}
                  aria-label="show more"
                >
                  <ExpandMoreIcon style={{color:"#f13f64"}}/>
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  }

  //Map over the chamge log contents and return card for each release
  returnLogContents = () =>{
    const { classes } = this.props;
    return changeLog.map(rel =>{
      return(
        <React.Fragment key={rel.version}>
          <Card raised={true} className={classes.card}>
            <CardContent >
              <Typography className={classes.bodytext} >
                <span style={{color:'#33cc99'}}>Version:</span> {rel.version}
              </Typography>
              <Typography className={classes.bodytext}>
                <span style={{color:'#33cc99'}}>Date:</span> {rel.date}
              </Typography>
              <Typography className={classes.bodytext} >
                <span style={{color:'#33cc99'}}>Release notes:</span>
              </Typography>
              <ul>
              {rel.notes.map(note =>{
                return(
                  <li key={note.note} className={classes.bodytext}>{note.note}</li>
                )
              })}
              </ul>
            </CardContent>
          </Card>
        </React.Fragment>
      )
    })
  }

  render() {	    
    const { classes } = this.props;	

    return (		
      <React.Fragment>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.termsModalOpen}
          onClose={modal => this.handleModalClose("termsModal")}
        >
          <div style={this.getModalStyle()} className={classes.modal}>
            <h2 id="simple-modal-title">
              <span
                role="img"
                aria-label="suggestion"
                aria-labelledby="suggestion-lightbulb"
              >
                🔎  &nbsp;
              </span>
               Before you continue...
            </h2 >
            <p id="simple-modal-description" className={classes.bodytext} style={{color:"#33cc99"}}>
              By proceeding, you accept that the results provided by this calculator are for academic and educational purposes only. 
            </p>
            <p id="simple-modal-description" className={classes.bodytext} style={{color:"#33cc99"}}>
              Always employ your own engineering judgement when interpreting the results produced.
            </p>
            <div style={{marginTop:"25px",textAlign:'right'}}>
              <Button 
                className={classes.exampleButton} 
                style={{color:"inherit" }}
                size="large"   
                onClick={()=>{this.setState({termsModalOpen:false})}}             
                >   
                Continue
              </Button> 
            </div> 
                
          </div>
          
        </Modal>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.tutorialModalOpen}
          onClose={modal => this.handleModalClose("tutorialModal")}
        >
          <div style={this.getModalStyle()} className={classes.modal}>
            <h2 id="simple-modal-title">
              <span
                role="img"
                aria-label="suggestion"
                aria-labelledby="suggestion-lightbulb"
              >
                🕹 
              </span>
              Getting started
            </h2 >
            <p id="simple-modal-description" className={classes.bodytext} style={{color:"#33cc99"}}>
              <b>Watch this quick tutorial walkthrough to see how the toolbox works</b>
            </p>
            <div className={classes.iframeContainer}>
            <iframe width="640" height="360" className={classes.iframe} src="https://www.youtube.com/embed/j2gL6xrFVVc?si=gv2KODEOPIsqhQ_2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              {/* <iframe
                className={classes.iframe}
                src="https://player.vimeo.com/video/752291683?h=c7b423398d"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe> */}
            </div>       
          </div>
        </Modal>		

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.membershipModalOpen}
          onClose={modal => this.handleModalClose("membershipModal")}
        >
          <div style={this.getModalStyle()} className={classes.modal}>
            <h1 id="simple-modal-title" className={classes.H1}>
              <span
                role="img"
                aria-label="suggestion"
                aria-labelledby="suggestion-lightbulb"                
              >
                🔓 
              </span>
              Subscribe for Unlimited Access
            </h1>   
            <p id="simple-modal-description" className={classes.bodytext}>
              <b>Guest users are limited to structures with <span style={{color:"#33cc99"}}>{freeRestraintLimit}</span> restrained degrees of freedom and <span style={{color:"#33cc99"}}>{freeElementLimit} </span> members.</b>
            </p>

            <h2 className={classes.H2}>
              As a DegreeTutors All Access Member you get...
            </h2>   

            <ul>
              <li className={classes.bodytext} style={{marginTop:"15px"}}>Unlimited access to all analysis toolboxes. </li>  
              <li className={classes.bodytext} style={{marginTop:"15px"}}>Unlimited access to the DegreeTutors course library. </li> 
              <li className={classes.bodytext} style={{marginTop:"15px"}}>Access to text versions of courses (where available). </li> 
              <li className={classes.bodytext} style={{marginTop:"15px"}}>Access to our community member's area. </li>
              <li className={classes.bodytext} style={{marginTop:"15px"}}>Priority Q&A support. </li>                
            </ul>    

            <h2 className={classes.H2} style={{marginTop:"50px"}}>
              Find out more about the benefits of DegreeTutors membership...
            </h2> 

            <div style={{marginTop:"25px"}}>
              <Button 
                className={classes.exampleButton} 
                style={{color:"inherit"}}
                size="large"
                href="https://www.degreetutors.com/membership/"
                target="_blank"
                >   
                🔓 All Access Membership
              </Button> 
            </div>     
          </div>
        </Modal>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.solverErrorModalOpen}
          onClose={modal => this.handleModalClose("errorModal")}
        >
          <div style={this.getModalStyle()} className={classes.modal}>
          <h2 id="simple-modal-title">
              <span
                role="img"
                aria-label="suggestion"
                aria-labelledby="suggestion-lightbulb"
              >
                🤯  &nbsp;
              </span>
               Something's gone terribly wrong...
            </h2 >
            <p id="simple-modal-description" className={classes.bodytext} style={{color:"#33cc99"}}>
              Sorry about this but we seem to have hit a snag processing your data. Please try again a little later. 
            </p>
            <p id="simple-modal-description" className={classes.bodytext} style={{color:"#33cc99"}}>
              We're looking into the problem now. If you're currently signed in as a member, we'll email you when things are up and running again.
            </p>

            <p id="simple-modal-description" className={classes.bodytext} style={{color:"#33cc99"}}>
              It would <i>really</i> help diagnose the problem if you emailed your structure data to <a style={{color:"#f13f64"}} href="mailto:support@degreetutors.com">support@degreetutors.com</a>
            </p>
            <center><iframe src="https://giphy.com/embed/ljtfkyTD3PIUZaKWRi" width="480" height="400" frameBorder="0" className="giphy-embed" allowFullScreen></iframe></center>
            
          </div>
        </Modal>

        <Feedback />
        <Grid container justify="center" spacing={4}>
          
          {this.return3DView()}

          {this.returnGuestAdd()}

          {this.returnDataEntry()}            
            
          {this.returnReportBlock()}            
          
          {this.returnReactionsBlock()}
          
          {this.returnAxialForcesBlock()}
          
          {this.returnDisplacementsBlock()}

          {this.returnChangeLogBlock()} 

        </Grid>
      </React.Fragment>
    );
  }

  //------------------------USER INPUT ERROR CHECKING------------------------

  //USER INPUT ERROR CHECKING - Check for empty fields
  checkEmptyFields = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{    
    let error = false
    
    if(!nodesRaw){    
      error=true  
      this.setState({nodesError:'Please provide at least 3 nodes'})
    }    

    if(!elementsRaw){ 
      error=true       
      this.setState({elementsError:'Please provide at least 2 members'})
    }

    if(!restraintsRaw){     
      error=true   
      this.setState({restraintsError:'Please provide at least 2 nodal restraints'})
    }
    
    if((!forcesRaw) && (!this.state.includeSW)){      
      error=true  
      this.setState({forcesError:'Please provide at least 1 nodal force or toggle self-weight calculation'})      
    }    

    if(forcesRaw){
      if ((forcesRaw.length==0) && (!this.state.includeSW)){
        error=true  
        this.setState({forcesError:'Please provide at least 1 nodal force or toggle self-weight calculation'}) 
      }
    }
    return error
  }

  //USER INPUT ERROR CHECKING - Check for empty rows and rows with wrong number of elements
  checkRowElementNumber = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{
    let error = false

    nodesRaw.forEach(row =>{
      if(row.length!=4){
        error=true  
        this.setState({nodesError:'Each row must contain 4 values (check empty rows & trailing commas)'})
        return
      }
    })

    elementsRaw.forEach(row =>{
      if(row.length!=7){
        error=true  
        this.setState({elementsError:'Each row must contain 7 values (check empty rows & trailing commas)'})
        return
      }
    })

    restraintsRaw.forEach(row =>{
      if(row.length!=4){
        error=true  
        this.setState({restraintsError:'Each row must contain 4 values (check empty rows & trailing commas)'})
        return
      }
    })
    
    if(forcesRaw){
      forcesRaw.forEach(row =>{
        if(row.length!=4){
          error=true  
          this.setState({forcesError:'Each row must contain 4 values (check empty rows & trailing commas)'})
          return
        }
      })    
    }
    
    return error
  }

  //USER INPUT ERROR CHECKING - Make sure only numbers and true/false values submitted
  checkRowElementType = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{
    let error = false

    nodesRaw.forEach(row =>{
      row.forEach(el =>{
        if(typeof(el)!='number'){
          error=true  
          this.setState({nodesError:"All elements must be numbers (problem with '"+el+"')"})
        }
      })
    })    

    elementsRaw.forEach(row =>{
      row.forEach(el =>{
        if(typeof(el)!='number'){
          error=true  
          this.setState({elementsError:"All elements must be numbers (problem with '"+el+"')"})
        }
      })
    })
    
    restraintsRaw.forEach(row =>{      
      if(typeof(row[0])!='number'){       
        error=true  
        this.setState({restraintsError:`All elements must be numbers, 'true' or 'false' (problem with '${row[0]}')`})
        return 
      }

      if(typeof(row[1])!='boolean'){
        error=true  
        this.setState({restraintsError:`All elements must be numbers, 'true' or 'false' (problem with '${row[1]}')`})
        return
      }

      if(typeof(row[2])!='boolean'){       
        error=true  
        this.setState({restraintsError:`All elements must be numbers, 'true' or 'false' (problem with '${row[2]}')`})
        return 
      }  
      
      if(typeof(row[3])!='boolean'){       
        error=true  
        this.setState({restraintsError:`All elements must be numbers, 'true' or 'false' (problem with '${row[3]}')`})
        return 
      } 
    })    

    if(forcesRaw){
      forcesRaw.forEach(row =>{
        row.forEach(el =>{
          if(typeof(el)!='number'){
            error=true  
            this.setState({forcesError:"All elements must be numbers (problem with '"+el+"')"})
          }
        })
      })
    }    
    
    return error
  }

  //USER INPUT ERROR CHECKING - Make sure no duplicate node numbers
  checkDuplicates = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw)=>{
    let error = false

    let nodes = []
    nodesRaw.forEach(row =>{
      nodes.push(row[0])      
    }) 

    if(this.hasDuplicates(nodes)){
      error=true  
      this.setState({nodesError:'It looks like you have duplicate node numbers'})
    }

    if(error){return error} //Return early and trigger exception if errors


    //Make sure no duplicate element numbers
    let elements = []
    elementsRaw.forEach(row =>{
      elements.push(row[0])  
    })

    if(this.hasDuplicates(elements)){
      error=true  
      this.setState({elementsError:'It looks like you have duplicate element numbers'})
    }

    if(error){return error} //Return early and trigger exception if errors

    //Make sure no duplicate supports
    let supports = []
    restraintsRaw.forEach(row =>{      
      supports.push(row[0])     
    }) 
    
    if(this.hasDuplicates(supports)){
      error=true  
      this.setState({restraintsError:'It looks like you have assigned multiple restraints to the same node'})
    }

    if(error){return error} //Return early and trigger exception if errors

    //Make sure no elements with same nodeI and nodeJ
    elementsRaw.forEach(row =>{
      if(row[1]==row[2]){
        error = true
        this.setState({elementsError:`The same node has been used to define both ends of element ${row[0]}`})
      }
    })
    return error
  }

  //USER INPUT ERROR CHECKING - Make sure no duplicate node numbers
  checkMemberNodes = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw)=>{
    let error = false

    elementsRaw.forEach(row =>{
      let nodeI = row[1]
      let nodeJ = row[2]
      let nodeI_exists = false
      let nodeJ_exists = false

      //Check if nodeI is in nodes
      nodesRaw.forEach(node =>{
        if(nodeI == node[0]){          
          nodeI_exists = true
          return
        }
      })

      //Check if nodeJ is in nodes
      nodesRaw.forEach(node =>{
        if(nodeJ == node[0]){  
          nodeJ_exists = true
          return
        }
      })      

      //Test for error
      if((!nodeI_exists) || (!nodeJ_exists)){        
        error=true  
        this.setState({elementsError:`Nonexistent node(s) used to define element ${row[0]}`})
      }
    })
    return error
  }

  //USER INPUT ERROR CHECKING - Make sure restraints are on existing nodes
  checkRestraintNodes = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{
    let error = false

    restraintsRaw.forEach(row =>{   
      let restrainedNode = row[0]   
      let node_exists = false

      //Check if restrainedNode is in nodes
      nodesRaw.forEach(node =>{
        if(restrainedNode == node[0]){          
          node_exists = true
          return
        }
      })

      //Test for error 
      if(!node_exists){        
        error=true  
        this.setState({restraintsError:`Restraint added to nonexistent node(s)`})
      }      
    })  
    return error
  }

  //USER INPUT ERROR CHECKING - Make sure forces are applied to existing nodes 
  checkForceNodes = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{
    let error = false   
    
    if (forcesRaw){
      forcesRaw.forEach(row =>{
        let forcedNode = row[0]   
        let node_exists = false
  
        //Check if forcedNode is in nodes
        nodesRaw.forEach(node =>{
          if(forcedNode == node[0]){          
            node_exists = true
            return
          }
        })
  
        //Test for error 
        if(!node_exists){        
          error=true  
          this.setState({forcesError:`Force applied to nonexistent node(s)`})
        }         
      })
    }    
          
    return error
  }

  //USER INPUT ERROR CHECKING - Make sure enough support restraints
  checkRestraintNumber = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{
    let error = false    

    let restraintCount = 0
    restraintsRaw.forEach(row =>{ 
      row.forEach(el =>{
        if(el===true){          
          restraintCount+=1
        }
      })    
    })  

    if(restraintCount<4){
      error=true  
      this.setState({restraintsError:`Looks like not enough restraints provided`})
    }
    return [error, restraintCount]
  }

  //USER INPUT ERROR CHECKING - Make sure all nodes are adequately restrained
  checkNodalRestraint = (nodesRaw, elementsRaw, restraintsRaw, forcesRaw) =>{
    let error = false

    nodesRaw.forEach(node =>{
      let restrained = false

      //Count how many elements are connected to this node
      let count=0
      elementsRaw.forEach(el =>{
        if(el[1]==node[0]){count+=1}
        if(el[2]==node[0]){count+=1}        
      })

      //Test if a minimum of 2 members are connected to the node
      if(count>=2){
        restrained = true
      }

      //Test if node is restrained by a pin support
      restraintsRaw.forEach(re =>{  
        if(re[0] == node[0]){
          if((re[1]===true)&&(re[2]===true)){
            restrained = true
          } else if((re[2]===true)&&(re[3]===true)){
            restrained = true
          } else if((re[3]===true)&&(re[1]===true)){
            restrained = true
          }
        }
      }) 

      //Return error and message if node is unrestrained
      if(!restrained){
        error=true  
        this.setState({nodesError:`It looks like node ${node[0]} might be unrestrained`})
      }
    }) 
    return error
  }
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
    truss3D: state.truss3D
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(SolverTruss3D));