import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-7/img1.png";
import img2 from "../../../images/Lectures/C17-7/img2.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `\\begin{equation}
\\tag{1}
y=-\\frac{H}{w_s}\\left\\{\\cosh\\left[-\\frac{w_s}{H}\\left(x-\\frac{L}{2} \\right) \\right] - \\cosh\\left(\\frac{w_s L}{2H} \\right) \\right\\}
\\end{equation}`

const codeString1 = `# DEPENDENCIES
import math #Math functionality
import numpy as np #Numpy for working with arrays
import matplotlib.pyplot as plt #Plotting functionality 
import csv #Export data to csv file`;

const codeString2 =`#Input parameters
L = 10 #(m) Plan length
y_max = 2 #(m) Mid-span sag
sw = 981 #(N) Self-weight per meter of cable`

const codeString3 =`x = L/2 #(m) Location of target y_max
H=np.arange(5000,10000,0.1) #(N) #Range of H values to test

#Rearrange equation for y_max to be homogeneous (=0)
eqn=-y_max-(H/sw)*(np.cosh((-sw/H)*(x-(L/2)))-np.cosh((sw*L)/(2*H)))

fig = plt.figure() 
axes = fig.add_axes([0.1,0.1,2,1]) 
axes.plot(H,eqn,'-')
axes.set_xlim([H[0], H[-1]])
axes.set_xlabel('H')
axes.set_ylabel('Eqn')
axes.set_title('Graphical solution')
axes.grid()`

const codeString4 =`#Cycle through solutions and find value of H that satisfies the equation
for i in np.arange(0,len(eqn)-1):            
    if eqn[i]<0 and eqn[i+1]>0:
        print(f'The required value of H is: {round(H[i],3)} N')
        H=H[i]
    elif eqn[i]>0 and eqn[i+1]<0:
        print(f'The required value of H is: {round(H[i],3)} N')
        H=H[i]`

const codeString5 =`n=12 #Break span into n segments (on plan)
delX = L/n #Segment length (on plan)

#Coordinates
x=np.arange(0,L+delX,delX) 
y=-(H/sw)*(np.cosh((-sw/H)*(x-(L/2)))-np.cosh((sw*L)/(2*H))) 

#Plotting
fig = plt.figure() 
axes = fig.add_axes([0.1,0.1,2,1]) 
fig.gca().set_aspect('equal', adjustable='box')
axes.plot(x,-y,'-*')
axes.set_xlim([0, L])
axes.set_xlabel('x (m)')
axes.set_ylabel('y (m)')
axes.set_title('Catenary')
axes.grid()`

const codeString6 =`L_cat=0 #Initialise the length

#Cycle through each segments and determine the length
for i in np.arange(0,len(x)-1):
    dx = abs(x[i+1]-x[i])
    dy = abs(y[i+1]-y[i])
    L_cat += math.sqrt(dx**2 + dy**2)   

print(f'The cable length is approximately {round(L_cat,3)} m')`

const codeString7 =`V = 0.5*(L_cat*sw) #(N) Vertical reaction at support
T_max = math.sqrt(H**2+V**2) #(N) Cable tension at the support

print(f'The vertical reaction is {round(V,2)} N')
print(f'The maximum cable tension at supports is {round(T_max,2)} N')`

const codeString8 =`#Initialise containers
vertices = np.empty((0,2),int) #Nodal coordinates
edges = np.empty((0,2),int) #Segment definitions

#Cycle through nodes and determine coordinates and member definitions
for i in np.arange(0,len(x)):
    vertex = np.array([x[i], -y[i]])
    vertices = np.append(vertices, [vertex], axis=0)
    
    if(i<len(x)-1):
        edge = np.array([i+1, i+2])
        edges = np.append(edges, [edge], axis=0)
        
#Export vertex coordinates to CSV file 
filename = "/Users/Sean/Desktop/Vertices.csv" #UPDATE PATH
# writing to csv file  
with open(filename, 'w') as csvfile:  
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(vertices) # writing the data rows  
    
#Export edge/element definitions to CSV file 
filename = "/Users/Sean/Desktop/Edges.csv" #UPDATE PATH
# writing to csv file  
with open(filename, 'w') as csvfile:      
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(edges) # writing the data rows `


class Lecture_17_7 extends React.Component {
  state={
    course: 17,
    lecture: 7, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we’ll write some code that allows us to solve Eqn. 1 numerically for <MathJax.Node inline formula={"H"} />.
                  </Typography>     

                  <MathJax.Node formula={eqn1} className={classes.formula}/>             
                  
                  <Typography paragraph className={classes.bodytext}>
                    We'll do this by evaluating the equation repeatedly until we hit on a value of <MathJax.Node inline formula={"H"} /> that satisfies the equation. Remember that for reference, you can download the complete Jupyter Notebook for this lecture from the resources panel above. The notes hereafter will assume that you're following along and writing code in your own Jupyter Notebook.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We start by importing some dependencies that make life a little easier for us.
                  </Typography>

                  <CodeBlock>
                    {codeString1}
                  </CodeBlock> 
                  
                  <Typography paragraph className={classes.bodytext}>
                    Next, we can define the parameters we specified at the end of the previous lecture.
                  </Typography>

                  <CodeBlock>
                    {codeString2}
                  </CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Solve for the value of <MathJax.Node inline formula={"H"} /> to achieve the desired sag </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we can plot Eqn. 1 for a range of different values of <MathJax.Node inline formula={"H"} />. I've specified values between <MathJax.Node inline formula={"5000"} /> and <MathJax.Node inline formula={"10000"} /> in steps of <MathJax.Node inline formula={"0.1"} />. Note that by rearranging the equation to equal zero, I'm plotting a homogeneous version of the equation. Therefore, we can identify the value of <MathJax.Node inline formula={"H"} /> that satisfies the equation from the plot where the line crosses zero. 
                  </Typography>

                  <CodeBlock>
                    {codeString3}
                  </CodeBlock>                                                      
                              
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Plot of homogeneous catenary equation versus horizontal component of tensile force, <MathJax.Node inline formula={"H"} />.</figcaption>
                </figure>  


                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    We can see that the value of <MathJax.Node inline formula={"H"} /> is something close to <MathJax.Node inline formula={"6500"} />. To identify the specific value, we can loop through all of the values of <code className={classes.code}>eqn</code> calculated above and test to see at what value of <MathJax.Node inline formula={"H"} />, <code className={classes.code}>eqn</code> changes from being positive to negative. In doing so, it must have passed through zero.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Identify the specific value of <MathJax.Node inline formula={"H"} /> </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Although we can see graphically that the value of <code className={classes.code}>eqn</code> does indeed change from positive to negative in this case, we'll write our code to take into account the potential for <code className={classes.code}>eqn</code> to change from negative to positive should this arise for a different set of boundary conditions.
                  </Typography>

                  <CodeBlock>
                    {codeString4}
                  </CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    This code block outputs the following statement:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>The required value of <MathJax.Node inline formula={"H"} /> is: <MathJax.Node inline formula={"6434.0\\:N"} /></Typography></li>
                  </ul>

                  <Typography component="h2" className={classes.H2} > Plotting the catenary </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We can see that after looping through all values of <code className={classes.code}>eqn</code>, it changes sign when <MathJax.Node inline formula={"H=6434\\:N"} />. As such, this is the value of <MathJax.Node inline formula={"H"} /> that would develop for the specific problem parameters specified above. Now that we know <MathJax.Node inline formula={"H"} />, we can use Eqn. 1 to plot the catenary. We'll break the span into <MathJax.Node inline formula={"n=12"} /> linear segments, but you can break it into as many segments as you like.
                  </Typography>

                  <CodeBlock>
                    {codeString5}
                  </CodeBlock>                                           
                              
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Twelve segment catenary.</figcaption>
                </figure>  

                <Grid item xs={12} sm={12} md={10} >    

                  <Typography component="h2" className={classes.H2} > Calculate the catenary length </Typography>              

                  <Typography paragraph className={classes.bodytext}>
                    Next, we'll calculate the maximum value of the cable tension force (at the supports). To do this, we need to know the vertical component of the tension force. We already know the self-weight per unit curved length of cable, so now all we need to determine is the curved length. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We'll approximate this by calculating the length of the <MathJax.Node inline formula={"n"} /> segments we've used to approximate the catenary. Obviously, your approximation would be improved by increasing the number of segments. We'll cycle through each segment, calculate its length and add it to a running total of all segment lengths.
                  </Typography>

                  <CodeBlock>
                    {codeString6}
                  </CodeBlock> 

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>
                    The cable length is approximately <MathJax.Node inline formula={"10.991\\:m"} />
                      </Typography></li>
                  </ul>

                  <Typography component="h2" className={classes.H2} > Calculate the max tension at supports </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we can calculate the total self-weight and divide it by <MathJax.Node inline formula={"2"} /> to calculate the vertical component of the cable tension force at supports, <MathJax.Node inline formula={"V"} />. We can determine the resultant of <MathJax.Node inline formula={"V"} /> and <MathJax.Node inline formula={"H"} /> to determine the maximum cable tension, <MathJax.Node inline formula={"T_{max}"} />.
                  </Typography>

                  <CodeBlock>
                    {codeString7}
                  </CodeBlock> 

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>
                    The vertical reaction is <MathJax.Node inline formula={"5391.1 \\:N"} /></Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    The maximum cable tension at supports is <MathJax.Node inline formula={"8394.06 \\:N"} /></Typography></li>
                  </ul>

                  <Typography component="h2" className={classes.H2} > Export catenary coordinates and segment definitions </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Finally, we'll export the coordinates of the <MathJax.Node inline formula={"n+1"} /> vertices or nodes of the catenary and the definitions of the <MathJax.Node inline formula={"n"} /> segments or edges that make up the catenary. The segment definitions are simply the node numbers at each end of the segment. We'll use these a little later in the course. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The export code here is standard export boilerplate code. We're exporting the nodes and segments to two comma-separated value (csv) files names Vertices.csv and Edges.csv located on the desktop. Make sure to change the file path to reflect your computer (unless you also happen to be called Sean, of course!)
                  </Typography>                  

                  <CodeBlock>
                    {codeString8}
                  </CodeBlock> 
                  
                  <Typography paragraph className={classes.bodytext}>
                    This completes our study of linear cable behaviour. Over the last 4 lectures, we've seen how to derive and model linear cable behaviour. This linear model will later serve as a baseline to compare our non-linear solver behaviour against. 
                  </Typography>  

                  <Typography paragraph className={classes.bodytext}>
                    If there is one key takeaway message here, it's that all of this modelling is only valid if the cable’s geometry does not change under load. Once this assumption is not met, the behaviour becomes non-linear. This is what we'll start to explore in the next section.
                  </Typography>                                          
                              
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_7));
