//Public Footer
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
// import logoWhite from "../../images/Landing/Banner_DTLabs.png";
import logoWhite from "../../images/Landing/engineering-skills-logo-dark.png";
import Icon from "@mdi/react";
import { mdiMapMarker} from "@mdi/js";
// import HelloCube from "../../views/private/ThreeJS/HelloCube1"
import SpinningLogo from "../../components/3D/SpinningLogo"

//Custom styling
const styles = theme => ({
	footer: {
		width: "100%",
		// background: "#1a1a1a",
    borderTop:"1px solid #33cc99",
    background: "#2a2e35",
    marginTop: "50px",    
    paddingTop:"40px",
    boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.3)"
	},
  logoAvatar: {
		width: 250,		
    margin:'10px',
    marginRight:'0px'
  },
  title: {		
		color: "#33cc99",
		"&:hover": {
			color: "#33cc99"
		},
		fontSize: "35px"
	},
  marginTop:'10px'
});

class Footer extends React.Component {
 
	render() {
    const { classes } = this.props; //Destructure data off the components props
		return (			
			  <Grid container className={classes.footer} align="center">
        
          {/* Logo */}
          <Grid item xs={7} sm={8} md={4} align="left" style={{display:'flex', flexWrap: 'wrap'}}>                
            <Link to="/" >
              <img src={logoWhite} alt="DegreeTutors:Labs" className={classes.logoAvatar}/>
            </Link>

            <div style={{flexBasis: "100%", height:"0"}}></div>
              
            <Typography
              variant="caption"
              align="right"
              style={{ color: "white", marginLeft:"10px" }}
            >
              <Icon path={mdiMapMarker} title="Location" size={0.7} color={"white"} />{" "}
              Made in Ireland 🇮🇪 
            </Typography>  
            <Typography
              variant="caption"              
              style={{ color: "white", marginLeft:"5px" }}
            >              
              by Dr Seán Carroll 
            </Typography>                  
          </Grid>

          {/* Navigation links */}
          <Grid item xs={7} sm={8} md={4} align="left" >
            {/* <HelloCube/> */}
				  </Grid>

          {/* Legal stuff, terms, privacy links etc*/}
          <Grid item xs={7} sm={8} md={4} align="center">
            {/* <div style={{height:"125px"}}>
              <SpinningLogo/>
            </div> */}
				  </Grid>
				
          {/* Company and Copyright note */}
          <Grid item xs={7} sm={8} md={12} align="center">
					<Grid container style={{ marginTop: "10px", marginBottom: "10px" }}>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant="caption"
								align="center"
								style={{ color: "#818181", fontSize: "0.7rem" }}
							>
								EngineeringSkills, DegreeTutors and DegreeTutors:Labs are products by Mind Map Media Ltd. Copyright &copy;{" "}
								{new Date().getFullYear()} Mind Map Media Ltd. All rights reserved.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			  </Grid>
		);
  }
}

function mapStateToProps(state) {
	return { auth: state.auth };
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(Footer));