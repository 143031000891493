import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_1 extends React.Component {
  state={
    course: 17,
    lecture: 1, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    In this introductory lecture, we’ll take a tour of the course, section by section, to give you an idea of what to expect as you move through the course. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In terms of content, the course will be a combination of more traditional, written note-based lectures and code-along screencasts. For the earlier handwritten note-based lectures, you’re encouraged to build your own version of the notes along with each video. Building your own handwritten versions of the derivations will help you move at the right speed and allow you to add your own additional comments and observations along the way. This way, you will actually feel like you’ve taken a short lecture rather than just seen a video.                   
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    For the coding-based lectures, again, I would encourage you to code along with me and build your own version of the code in parallel with the course. All of the code I write during the course is available to be downloaded in the resources section of the relevant lectures. The lectures that have downloadable files are indicated with a paperclip in the lecture index on the right.
                  </Typography>


                  <Typography paragraph className={classes.bodytext}>
                    If you run into difficulty and your code is not behaving as expected based on the accompanying video lecture, you can download the working version of the code to help you identify where the issue is. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If you have questions as you’re working through the course, please ask those in the comments section of the relevant lecture, and I’ll address them directly. This way, all students can benefit from the record of our discussion. There is a link to the comments section for each lecture in the lecture resources panel above. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Ok, so let’s take a look at the course in a bit more detail.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 2 - ‘Heavy’ Cables - the Linear Solution </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In section 2, we’ll start our analysis of cable behaviour. Cables often undergo changes in their geometry under loading due to cable extension, shape distortion due to directly applied loads or flex in the cable supports. Due to their flexibility, cables are a classic example of a non-linear structure. In this section, we will establish a linear (closed-form) cable solution that ignores non-linear effects. This will provide a baseline case to test our non-linear code against later. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 3 - Getting Comfortable with Non-linearity </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In section 3, we’ll start to talk about non-linear structural behaviour before focusing on geometric non-linearity in particular. This section aims to take any mystery out of the term <i>‘geometric non-linearity’</i>. We’ll also explore, at a concept level, how we might set about solving for the behaviour of a non-linear structure. We’ll introduce the <b>Newton-Raphson Method</b>, which informs the main architecture of the solver code we’ll write later on.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 4 - The Non-linear Stiffness Matrix </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    One of the central elements of a matrix-based non-linear structural analysis is a stiffness matrix that can capture the influence of large deflections. In section 4, we’ll focus on deriving a form of non-linear element stiffness matrix that can do just that. Familiarity with the material in the pre-requisite course, discussed in the next lecture will be helpful here.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 5 - Building our 2D Solver Toolbox </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now that we have a stiffness matrix that can reflect progressive stiffening or softening due to large deformations and we understand, conceptually at least, how to iterate towards a solution, it’s time to build out the code that can bring these ideas to life. In section 5, we’ll do the bulk of our code development. In bitesize chunks, we’ll build our solver. Again, you can make things a lot easier for yourself here if you complete the build of the 2D linear solver in the pre-requisite course. By the end of this section, you’ll have a functioning non-linear solver. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 6 - Visualising the Results </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    After building a solver, we need to turn our attention to visualising the output results. This is the focus of section 6. We’ll build out the data visualisation that brings our solution to life and allows us to explore how the structure evolves towards its final equilibrium state. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 7 - ‘Heavy’ Cables - the Non-linear Solution </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In section 7, we’ll return to a conversation we started at the beginning of the course. We’ll use our new solver to simulate the cable we kicked the course off with. We’ll compare our code’s results with our earlier linear solution. We’ll be able to closely approximate the linear solution but also observe the emergence of non-linear behaviour as the axial stiffness of our cable is progressively reduced.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 8 - Modelling Initial Geometry in Blender </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In section 8, we’ll take a break from pure coding and detour over to Blender, the free 3D modelling tool we’ll use to generate structural geometry. If you’ve taken some of my other structural analysis courses, you’ll be familiar with how we use Blender. If you’re completely new to Blender, I’ve included an appendix section at the end of the course to help you get set up and familiar with the software.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 9 - Mixing Cables and Bars in the Same Model </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the final section of the course, we’ll expand our code to handle structures that consist of both bar and cable elements. One of the features of a cable is its inability to resist compression. Our code doesn’t yet capture this behaviour - we’ll address that in this final section of the course. We’ll also complete the modelling and analysis of a cable-stayed lattice tower - a classic example of a conventional and commonly found structure that utilises geometrically non-linear cables. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    By the end of the course, you’ll understand geometric non-linearity, why it’s an important behaviour to capture in your analyses, and you’ll understand when it can and cannot be ignored. You’ll also have built yourself a solver that can analyse structures that exhibit this complex behaviour. It’s worth mentioning here that the solver we’ll develop in this course is not meant as a replacement for commercial non-linear solvers such as SAP2000 – these commercial tools have huge feature-sets maintained by armies of developers! The objective here is to build your understanding of geometric non-linear behaviour, and the best way to do this is by implementing what you learn by building your own modest but focused solver.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So, now that you have a roadmap for the journey ahead, we can move on and briefly mention course prerequisites in the next lecture.
                  </Typography> 
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_1));
