import { FETCH_CUSTOMER_DATA } from "../actions/types";

export default function(state = {}, action) {
	switch (action.type) {
		case FETCH_CUSTOMER_DATA:
			return action.payload; //returns user object or false    
		default:
			return state;
	}
}
