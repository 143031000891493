import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_18 extends React.Component {
  state={
    course: 17,
    lecture: 18, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    Welcome to the building phase of the course. All of the derivations and text-based lectures are behind us. Now we can start bringing things to life by building out our Python solver.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    There are quite a few individual lectures in this section. This is because we’ll be keeping quite a tight focus in each lecture. This way, we’ll break up quite a large build into easy-to-digest bitesize chunks.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We’ll begin by blocking out the main structure of our code and establishing the main convergence loop. From inside this loop, we’ll call the main functions that build the stiffness matrix and solve for the nodal displacements and internal force system. Structuring our code in this modular fashion is going to be particularly helpful given the iterative nature of our solver.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Once we’ve implemented the main execution loop that allows for incremental convergence between the internal and external force systems, we’ll build the ability to break our external loading into smaller force increments. Allowing our code to seek convergence for smaller increments of loading will be key to achieving convergence in our analyses.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Once this section is complete, we’ll have a functioning solver that implements the Newton-Raphson strategy outlined at the end of section 3. Completing this section is a major milestone in the course.  
                  </Typography>                  
             
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_18));
