import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_32 extends React.Component {
  state={
    course: 17,
    lecture: 32, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    After the heavy-lifting of the previous section, we’ll tackle something a little less intensive in this section. We’re going to build out the data visualisation that brings our analysis results to life. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Without an intuitive visualisation, it’s very hard to derive any value from our solver’s output so far. So, we’ll focus on developing an information-dense visual representation of the behaviour of our structure. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    What we cover in this section will have a lot in common with previous DegreeTutors courses. This time, in addition to some aesthetic improvements, we’ll implement a feature that allows us to ‘step- through’ the converged stages of our solution. This allows us to visualise how our structure evolves into its final state.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this section, you’ll pick up a lot of plotting and structure-visualisation tips and practices that you can propagate to your other work. 
                  </Typography>            
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_32));
