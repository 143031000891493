import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-13/img1.png";
import img2 from "../../../images/Lectures/C19-13/img2.png";
import img3 from "../../../images/Lectures/C19-13/img3.png";
import img4 from "../../../images/Lectures/C19-13/img4.png";

const Eqn1 = `\\begin{align}
\\tag{1}
x=&\\frac{d}{1+\\frac{0.00217}{0.0035}}\\\\\\\\
x=&0.617\\:d
\\end{align} `

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';




class Lecture_19_13 extends React.Component {
  state={
    course: 19,
    lecture: 13, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >   
                  <Typography component="h2" className={classes.H2} > 1.0 Stress-resultants and the internal moment of resistance </Typography>               
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll start to consider the stresses that develop in a reinforced concrete section. We'll start by considering a simply supported beam. The beam is subject to a uniformly distributed load (UDL). This distributed load generates an external bending moment, <MathJax.Node inline formula={"M_{EXT}"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The beam generates a resistance to <MathJax.Node inline formula={"M_{EXT}"} /> through the development of internal normal stresses in the material. These normal stresses can be represented by stress resultant forces (simply stress multiplied by the area over which it develops); one in tension, <MathJax.Node inline formula={"F_T"} /> and one in compression, <MathJax.Node inline formula={"F_C"} />. The internal stress resultants, separated by a lever arm, <MathJax.Node inline formula={"z"} />, form a couple, <MathJax.Node inline formula={"M_{INT}"} />. It is this internal couple or bending moment that resists the externally applied bending moment, Fig. 1.
                  </Typography>
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Simply supported beam, with a cut at section <MathJax.Node inline formula={"X-X"} />, revealing the internal stress resultants, <MathJax.Node inline formula={"F_C"} /> and <MathJax.Node inline formula={"F_T"} /> and corresponding internal bending moment, <MathJax.Node inline formula={"M_{INT}"} /> which resists the externally applied loading.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    As mentioned earlier, concrete is strong in compression but comparatively weak in tension, with a tensile capacity of approximately <MathJax.Node inline formula={"1/10^{th}"} /> of its compression capacity. Therefore we must use reinforcing steel to provide tensile resistance. In fact, when considering the ultimate moment capacity of a concrete beam, we conservatively assume the concrete has no tensile capacity whatsoever and that the section is cracked between the extreme tensile fibre and the neutral axis.  
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Stress and strain distributions </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider again the simply supported reinforced concrete beam subject to a UDL. The external loading will induce stresses internally within the beam. When we make an imaginary cut (shown above in Fig 1), we <i>expose</i> the internal stresses acting on the exposed cross-section. The cross-section we are referring to is shown in Fig. 2.  
                  </Typography>                 
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. The red shaded area is the section under consideration in the following discussion on section analysis.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Before proceeding any further, we also define the <b>ultimate moment of resistance</b> of a reinforced concrete section as the maximum value of the internal moment that can be generated, beyond which either the concrete would fail in compression or the steel would yield in tension.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now, consider the cross-section identified in figures 1 and 2. The orientation of the loading is such that the top fibres of the beam will be in compression and the bottom fibres in tension. In Fig. 3 (a) we can see an elevation view of the section and its associated dimensions and in (b), we can see how the strain varies with height across the section. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Figures (c), (d) and (e) represent three possible stress distribution diagrams:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Figure (c) shows a linear stress distribution. As we saw previously, stress is not linearly proportional to strain in plain concrete. However, for relatively low levels of strain, we can reasonably approximate stress as being linearly proportional to strain. This assumption is synonymous with serviceability limit state analysis.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Figure (d) is referred to as the rectangular-parabolic stress block and is observed at the point of concrete compression failure. The stresses at the outermost (furthest from the neural axis) fibres have reached their compression limit (<MathJax.Node inline formula={"0.85\\:f_{ck}/1.5 = 0.567 f_{ck}"} />). </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Figure (e) represents the equivalent rectangular stress block. This stress block is an approximation of the rectangular-parabolic stress block. It yields almost identical numerical results while being much easier to manage numerically.</Typography></li>
                  </ul>                  
                                
                </Grid>
                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. (a) Concrete cross-section, (b) strain distribution and (c-e) three possible stress distributions.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In all three stress diagrams, the tensile stress is only developed at the level of the reinforcing steel, i.e. tensile stresses are only developed in the steel. The concrete in all cases is assumed to be cracked up to the height of the neutral axis and therefore not providing any tensile resistance. This is a fundamental assumption of ULS section analysis.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 3.0 Balanced section design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can start to think about the limits imposed by the material behaviour discussed in the previous lecture. Remember, our models of material behaviour dictate that the steel will yield at a strain value of <MathJax.Node inline formula={"\\epsilon_y = 0.00217"} />, while the concrete will crush at a compressive strain <MathJax.Node inline formula={"\\epsilon _{cu,2} = 0.0035"} />. Looking at the strain distribution in Fig. 3(b), using basic geometry (similar triangles), these two strain limits can be used to determine the position of the neutral axis as a function of the effective depth, <MathJax.Node inline formula={"d"} /> (distance from the top of the compression zone to the centre of the steel reinforcement):
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If the neural axis is in this position when the beam is subject to its ultimate load, in theory, the concrete will crush in compression, and the steel will yield in tension <b>simultaneously</b>. This is referred to as a <b>balanced section design</b>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is not a desirable mode of failure. Concrete compression failures are brittle failures providing little or no visible warning signs, whereas yielding of steel is a ductile failure. A ductile beam failure, one in which the steel yields before the concrete crushes, is preceded by a period of pronounced tensile cracking of the concrete. This provides a warning that a failure is possible, even likely. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    For this reason, we design concrete sections to fail due to yielding of the steel first. Practically we do this by placing limits on the position of the neutral axis, and we do this by controlling how much reinforcing steel we place in the cross-section. Put simply, if we put too much steel in our beam, the tensile force that can be developed in the steel reinforcement will be larger than the compressive force that the concrete can resist, so the concrete will fail first - a sudden, explosive, brittle failure.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The material properties and geometry of the section dictates that for a ductile failure (steel yielding first), the neutral axis, <MathJax.Node inline formula={"x\\leq 0.617\\:d"} />. However, the Eurocode specifies that <MathJax.Node inline formula={"x\\leq\\:0.45\\:d"} />. This means that when the steel has yielded, the concrete strain is far enough away from its ultimate value to allow further rotation of the section to occur. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To further emphasise the meaning of this limit on neutral axis depth, consider the strain distribution in Fig. 4. If we impose the condition that at the instant of failure, the steel will have yielded, the bottom strain value is fixed at <MathJax.Node inline formula={"\\epsilon_{yield} = 0.00217"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now, if the neutral axis was permitted to be as low as <MathJax.Node inline formula={"0.617\\: d"} />, this would result in the strain in the concrete being equal to the ultimate value of <MathJax.Node inline formula={"\\epsilon_{cu,2} = 0.0035"} />, or a balanced design, as discussed above. However, if we limit the neutral axis depth to <MathJax.Node inline formula={"0.45\\: d"} />, the maximum strain in the concrete will be the value shown, <MathJax.Node inline formula={"\\epsilon_1<0.0035"} />. This leaves an additional <i>capacity</i> strain of <MathJax.Node inline formula={"\\epsilon_2 = 0.0035-\\epsilon_1"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This allows rotation of the beam to continue after the steel has yielded but before the concrete crushes in compression. This further rotation is referred to as plastic rotation or <i>plastic hinge behaviour</i> and allows redistribution of moments to take place in the structure, provided it has sufficient structural redundancy. 
                  </Typography>                  
                                
                </Grid>
                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Limits on neutral axis depth.</figcaption>
                </figure>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_13));
