import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-18/img1.png";
import img2 from "../../../images/Lectures/C19-18/img2.png";

const Eqn1 = `\\begin{align*}
\\tag{1}
z_{bal} &=d-0.4x\\\\
&= d-0.4\\times 0.45d\\\\
 & = 0.82d
\\end{align*}`

const Eqn2 = `\\begin{equation}
\\tag{2}
M_{Rd} = F_{cc}\\times z_{bal} + F_{sc}\\times (d-d')
\\end{equation}`

const Eqn3 = `\\begin{equation}
\\tag{3}
M_{Rd} = 0.167\\:f_{ck}bd^2 + 0.87\\:f_{yk}\\:A_s'(d-d')
\\end{equation}`

const Eqn4 = `\\begin{equation}
\\tag{4}
\\boxed{A_s' = \\frac{M_{Ed} - M_{bal}}{0.87\\:f_{yk}(d-d')}}
\\end{equation}`

const Eqn5 = `\\begin{equation}
\\tag{5}
F_{st} = F_{cc} + F_{sc}
\\end{equation}`

const Eqn6 = `\\begin{equation}
\\tag{6}
0.87\\:f_{yk}\\:A_s = 0.204\\:f_{ck}bd + 0.87\\:f_{yk}\\:A_s'
\\end{equation}`

const Eqn7 = `\\begin{equation}
\\tag{7}
A_s = \\frac{0.167\\:f_{ck}\\:bd^2}{0.87\\:f_{yk}\\:z_{bal}} + A_s'
\\end{equation}`

const Eqn8 = `\\begin{equation}
\\tag{8}
\\boxed{A_s = \\frac{M_{bal}}{0.87\\:f_{yk}\\:z_{bal}} + A_s'}
\\end{equation} `

const Eqn9 = `\\begin{equation}
\\tag{9}
\\frac{d'}{d}<0.171
\\end{equation}`

const Eqn10 = `\\begin{equation}
\\tag{10}
\\frac{0.0035}{x} = \\frac{\\epsilon_{sc}}{x-d'}
\\end{equation}`

const Eqn11 = `\\begin{equation}
\\tag{11}
\\frac{d'}{x} = 1-\\frac{\\epsilon_{sc}}{0.0035}
\\end{equation}`

const Eqn12 = `\\begin{align}
\\frac{d'}{x} &< 1-\\frac{0.00217}{0.0035}\\\\\\\\
\\frac{d'}{x} &<0.38
\\end{align}`

const Eqn13 = `\\begin{equation}
\\frac{d'}{d}<0.171
\\end{equation}`


class Lecture_19_19 extends React.Component {
  state={
    course: 19,
    lecture: 19, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In our discussion of singly reinforced concrete sections so far, we've said that we must ensure a ductile failure by forcing the steel to yield before the concrete crushes. Practically, this means that the bending resistance of a beam is limited by the capacity of the concrete compression block. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Let's reconsider the reinforced concrete cross-section from <Link to="/course-19/lecture-15" className={classes.link} target="_blank">Example #3</Link>. We derived the maximum moment that the beam could withstand as <MathJax.Node inline formula={"M_{Rd,max} = 304.4\\: kNm"} />. Now, if <MathJax.Node inline formula={"M_{Ed} = 350\\: kNm"} />, we have two options; we could make the beam deeper, giving us a larger compression block and lever arm, or we could keep the overall beam depth the same and strengthen the compression block by adding compression reinforcement. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Since deepening the beam is often not a favourable option, usually because of fixed floor-to-floor heights in a building frame, for example, we often must resort to compression reinforcement. Such beams are referred to as <b>doubly reinforced</b>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To analyse the influence of compression reinforcement, consider the doubly reinforced cross-section and the associated stress and strain distributions in Fig 1. Note that the area of compression reinforcement is denoted as <MathJax.Node inline formula={"A_s'"} />. 
                  </Typography>                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Doubly reinforced concrete section showing stress and strain distribution at the ultimate limit state.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    At the ultimate limit state, the neutral axis will have reached its maximum allowable value of <MathJax.Node inline formula={"x=0.45d"} /> (otherwise, we wouldn't be designing compression reinforcement), so we can say,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now, to develop an expression for the moment of resistance, we can take moments about the line of action of the force in the tension reinforcement,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Subbing in <MathJax.Node inline formula={"z_{bal} = 0.82d"} /> and remembering <MathJax.Node inline formula={"x=0.45d"} />, we obtain,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If we now recognise that <MathJax.Node inline formula={"0.167\\:f_{ck}\\:bd^2"} /> is the maximum moment that can be developed by a singly reinforced section (<Link to="/course-19/lecture-14" className={classes.link} target="_blank">lecture 14</Link>), we can let <MathJax.Node inline formula={"M_{bal}=0.167\\:f_{ck}bd^2"} /> and solve for the area of compression reinforcement as:
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    (Remember that <MathJax.Node inline formula={"M_{Rd}"} /> is numerically equal to <MathJax.Node inline formula={"M_{Ed}"} />). We can now consider horizontal force equilibrium in order to obtain an expression for the area of tension reinforcement required,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    To obtain a convenient expression in terms of bending moment <MathJax.Node inline formula={"M_{bal}"} />, we can multiply both sides of the force equilibrium equation by <MathJax.Node inline formula={"z_{bal}=0.82d"} /> and rearrange to obtain,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The preceding analysis made one very important assumption; look again at equations 3 and 6. In both, not only did we assume that the tension reinforcement had yielded when <MathJax.Node inline formula={"M_{Rd}"} /> was reached, but we also assumed the compression reinforcement had yielded. In order for us to make this assumption, we must ensure that,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This ensures that the compression steel is placed close enough to the max compression strain at the surface of the concrete to ensure yielding occurs. This assumes that steel with a yield strength of <MathJax.Node inline formula={"500 \\:N/mm^2"} /> and Young's Modulus of <MathJax.Node inline formula={"200 \\:kN/mm^2"} /> is used. It also assumes the neutral axis is located at a depth of <MathJax.Node inline formula={"0.45d"} />. We can derive this criterion by considering the strain distribution at failure, Fig 2.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Doubly reinforced concrete section strain distribution at failure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    By observing the strain distribution, we can state the following,
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This can be rearranged to,
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If we now assume that the compression steel has yielded, <MathJax.Node inline formula={"\\epsilon_{sc} = 0.00217"} /> and we restate Eqn 11 as an inequality,
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Letting <MathJax.Node inline formula={"x=0.45d"} />, we have the criteria stated above,
                  </Typography>

                  <MathJax.Node formula={Eqn13} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If this condition is not satisfied, the strain in the compression steel must first be determined in order for the compression force to be determined.
                  </Typography>                               
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_19));
