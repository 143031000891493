import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1 = 
`#Initialise global displacement vector to zero (undeformed state)
UG = np.zeros([nDoF,1]) 

#Calculate initial transformation matrices based on undeformed position
TMs = calculateTransMatrices(UG)

#Calculate internal force system based on pre-tension in members
F_pre = initPretension() 

#Initialise container for incremental displacements for each iteration: [Xa], [Xb]
UG_inc = np.empty([nDoF,0])
UG_inc = np.append(UG_inc, UG, axis=1) #Add the initial (zero) displacement record

#Initialise container for incremental internal forces for each iteration: [Fa], [Fb]
F_inc = np.empty([nDoF,0])
F_inc = np.append(F_inc, F_pre, axis=1) #Add the initial pre-tension force record`

const codeString2 = 
`i=0 #Initialise an iteration counter (zeros out for each load increment)
inc=0 #Initialise load increment counter
notConverged = True #Initialise convergence flag`

const codeString3 = 
`while notConverged and i<10000: 
    
    #Calculate the cumulative internal forces Fi_total = Fa + Fb + Fc + ...    
    Fi_total = np.matrix(np.sum(F_inc,axis=1)).T #Sum across columns of F_inc     
        
    #Calculate the cumulative incremental displacements UG_total = Xa + Xb + Xc + ...
    UG_total = np.matrix(np.sum(UG_inc,axis=1)).T #Sum across columns of UG_inc  
   
    #Inequilibrium force vector used in this iteration F_EXT - Fi_total 
    F_inequilibrium = forceVector - Fi_total  
            
    #Build structure stiffness matrix based on current position
    Ks = buildStructureStiffnessMatrix(UG_total)     

    #Solve for global (incremental) displacement vector [Xn] for this iteration
    UG = solveDisplacements(Ks, F_inequilibrium)
    
    #Calculate a new transformation matrix for each member
    TMs = calculateTransMatrices(UG_total)
    
    #Calculate the internal force system based on new incremental displacements, [Fn]
    F_int = updateInternalForceSystem(UG)       
        
    #Save incremental displacements and internal forces for this iteration
    UG_inc = np.append(UG_inc, UG, axis=1)
    F_inc = np.append(F_inc, F_int, axis=1)       
        
    #Test for convergence
    notConverged = testForConvergence(i, convThreshold, F_inequilibrium)
    
    i+=1
    
    #If converged, save displacements, forces and increment external loading
    if not notConverged: 
        inc +=1
        
        """
        TO DO:
        Save a 'Snapshot' of the system at this converged load increment
        - Save displacements 
        - Save internal force system
        - Save member axial forces
        - Save the external forces
        
        - Add another external force increment
        - Reset the notConverged flag
        - Reset the iteration counter
        """    `


class Lecture_17_21 extends React.Component {
  state={
    course: 17,
    lecture: 21, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    This is quite an important lecture as we’ll lay out the main structure of our convergence loop. In other words, this lecture is going to focus on implementing the logic that we discussed back in lecture 11 when we were looking at the Newton-Rathson solution strategy. Before we build the loop that will perform our analysis iterations, we need to initialise some data containers. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Initialise data containers used within the convergence loop </Typography>

                  <Typography paragraph className={classes.bodytext}>

                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first data structure we define is <code className={classes.code}>UG</code>. This will hold the displacements for each DoF. Every time we reach convergence for a new load increment, we’ll add a new column of displacements to <code className={classes.code}>UG</code>. So, when our analysis is complete, <code className={classes.code}>UG</code> will contain a column of displacement data for each load increment. We can think of these columns of data as snapshots of the structures' displacement during the loading history. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we define the transformation matrix for each element in a data structure called <code className={classes.code}>TMs</code>. <code className={classes.code}>TMs</code> is set equal to the output from the function <code className={classes.code}>calculateTransMatrices()</code>. We haven't written this function yet. You'll notice that a lot of the code we write in this section will make reference to functions not yet written - we'll be writing them over the remainder of this section. This lecture is really just focusing on setting everything up. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we define <code className={classes.code}>F_pre</code> as the container holding the pre-tension value for each element. Again this is calculated using the function <code className={classes.code}>initPretension()</code>, which we'll write in the following lecture.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    <code className={classes.code}>UG_inc</code> is the vector of displacements calculated during a single iteration - remember, we perform multiple iterations to achieve convergence for a single load increment. During these iterations, the values of <code className={classes.code}>UG_inc</code> accumulate until convergence is achieved (review lecture 11 again if needed). After initialising an empty array for <code className={classes.code}>UG_inc</code> on line 11, we append the initial (zero) displacement of the structure on line 12.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, we repeat this process for <code className={classes.code}>F_inc</code>, the incremental forces calculated during each iteration. For <code className={classes.code}>F_inc</code>, the initial values are set equal to the pre-tension values we initialised previously. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography component="h2" className={classes.H2} > Main execution/convergence loop </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now we're ready to start building the main code loop. It would be a good idea to review lecture 11 again before working through the rest of this lecture. First, we initialise our iteration and load increment counters to zero on lines 1 and 2. On line 3, we declare a convergence flag and set it equal to <code className={classes.code}>True</code>. On each iteration, we will check if convergence has been achieved - if it has, the value of <code className={classes.code}>notConverged</code> will be changed to <code className={classes.code}>False</code>. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    We use a <code className={classes.code}>while</code> loop that will keep running as long as two conditions are satisfied;  <code className={classes.code}>notConverged</code> must be <code className={classes.code}>True</code>, and the number of iterations performed, tracked with the variable <code className={classes.code}>i</code>, must be less than <MathJax.Node inline formula={"10000"} />. This is to ensure a hard limit on our code - otherwise, it could potentially run indefinitely if convergence is never achieved.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Once inside the loop, the sequence of operations is as follows:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>
                    Line 4: Add up all of the incremental forces that have accumulated for each DoF over each analysis iteration, and store the accumulated system of internal forces as <code className={classes.code}>Fi_total</code>. This variable represents the current internal force system for the structure.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 7: Do the same thing for the incremental displacements that have accumulated over each analysis iteration and store as <code className={classes.code}>UG_total</code>. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 10: Calculate the remaining force imbalance and store it as <code className={classes.code}>F_inequilibrium</code>. This is the vector or forces that must be applied to the structure on this analysis iteration.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 13: Now, we can update our stiffness matrix for the structure, <code className={classes.code}>Ks</code>. We do this by calling a function (not yet written) and passing it the current set of displacements for the structure, <code className={classes.code}>UG_total</code>, calculated on line 7.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 16: Now that we've updated the stiffness matrix for the current displaced shape of the structure and calculated the current inequilibrium force vector, we can calculate the set of displacements for the current iteration, <code className={classes.code}>UG</code>. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 19: Next, the transformation matrix for each member is recalculated using <code className={classes.code}>UG_total</code>.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 22: At this point, we've calculated a new set of displacements induced by the inequilibrium force vector. These displacements can now be used to calculate the corresponding internal incremental forces, <code className={classes.code}>F_int</code>.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 25 & 26: The incremental displacements and forces generated during this analysis iteration are saved into <code className={classes.code}>UG_inc</code> and <code className={classes.code}>F_inc</code>.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                    Line 29: Our test for convergence is based on the inequilibrium force vector. We'll discuss this test in more detail when we write it later but for now, we simply pass the current inequilibrium force vector, <code className={classes.code}>F_inequilibrium</code>, into our test function, <code className={classes.code}>testForConvergence()</code>, along with a threshold value (a constant, we'll add later). This function will either return <code className={classes.code}>True</code> or <code className={classes.code}>False</code>, depending on whether our structure has converged or not for the current load increment. The value returned is assigned to our convergence flag, and this will dictate what we do next.</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    If <code className={classes.code}>testForConvergence()</code> returns <code className={classes.code}>True</code>, then the structure has not converged, and we can return to the start of the loop and complete another iteration. Note that next time around, <code className={classes.code}>Fi_total</code> and <code className={classes.code}>UG_total</code> will be updated to include the values of incremental force and displacement we've just calculated. This iteration process continues, progressively reducing the force imbalance and accumulating incremental deflections, until our <code className={classes.code}>testForConvergence()</code> function returns <code className={classes.code}>False</code> - indicating that the structure has converged. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the structure has converged, we will satisfy the condition on line 34 and enter the conditional code block. We'll write the code for this block in a later lecture - but you can get a sense of what we need to do by reading the code comments below. 
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    That's enough new code for this lecture; spend some time with this one because the logic we've just blocked out is the backbone of our iterative solver. Make sure you understand what's happening and can relate it back to our earlier discussion on the Newton-Raphson method in lecture 11. Once you fully grasp what's happening in the <code className={classes.code}>while</code> loop above, the rest of the code we have to write is all quite straightforward and similar to what we wrote in the 2D truss analysis course. From here on we're really just filling in gaps and writing functions we've referenced above. 
                  </Typography>
                                                             
                </Grid>

                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_21));
