import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-27/img1.png";
import img2 from "../../../images/Lectures/C19-27/img2.png";
import img3 from "../../../images/Lectures/C19-27/img3.png";

const Eqn1 = `\\begin{equation}
\\tag{1}
l_{eff} = l_n + a_1 + a_2
\\end{equation}  `

const Eqn2 = `\\begin{align}
P_l=1.35\\times 75 + 1.5\\times 50\\\\\\\\
P_l=176.25\\:kN
\\end{align}`

const Eqn3 = `\\begin{align}
P_r=1.35\\times 60 + 1.5\\times 45\\\\\\\\
P_r=148.5\\:kN
\\end{align}`

const Eqn4 = `\\begin{align}
w_d = 1.35\\times 10 + 1.5\\times 15\\\\\\\\
w_d = 36\\:kN/m
\\end{align}`

const Eqn5 = `\\begin{equation}
K = \\frac{M_{Ed}}{bd^2f_{ck}} = \\frac{766.12\\times 10^{6}}{500\\times 748^2\\times 30} = 0.091
\\end{equation}`

const Eqn6 = `\\begin{equation}
z = d\\Bigg[0.5+\\sqrt{0.25-\\frac{K}{1.134}}\\Bigg] = 0.91d = 681\\:mm
\\end{equation}`

const Eqn7 = `\\begin{equation*}
A_{s,req} = \\frac{M_{Ed}}{0.87\\:f_{yk}\\:z} = \\frac{766.12\\times10^6}{0.87\\times 500\\times 681} = 2586\\:mm^2
\\end{equation*}`

const Eqn8 = `\\begin{align}
F_{st} &= F_{c}\\\\\\\\
0.87\\: f_{yk} \\: A_{s,prov} &= 0.567\\: f_{ck}\\: b \\:0.8x\\\\\\\\
0.87\\times 500\\times 2950 &= 0.567\\times 30\\times 500\\times 0.8x\\\\\\\\
\\therefore x &= 189\\:mm
\\end{align}`

const Eqn9 = `\\begin{align}
\\delta_{clear} =& \\frac{500 - 2\\overbrace{(30+10)}^{\\text{cover+link}} - (6\\times 25)}{5}\\\\\\\\
=&54\\:mm
\\end{align}`

const Eqn10 = `\\begin{align}
V_{Ef} = 339.37\\:kN - 0.25\\:m\\times 36\\:kN/m = 330.37\\:kN. 
\\end{align}`

const Eqn11 = `\\begin{align}
v_{Ef} &= \\frac{F_{Ef}}{b\\: z}\\\\\\\\
v_{Ef} &= \\frac{330.37\\times 10^3\\:N}{500\\:mm\\times 0.9\\times 748\\:mm}\\\\\\\\
v_{Ef} &=0.98\\:N/mm^2 
\\end{align}`

const Eqn12 = `\\begin{align}
V_{Ed} = 339.37\\:kN - (0.25+0.748)\\:m\\times 36\\:kN/m = 303.44\\:kN. 
\\end{align}`

const Eqn13 = `\\begin{align}
\\frac{A_{sw}}{s} &= \\frac{V_{Ed}}{0.87 f_{yk}\\:z\\:\\cot\\:\\theta}\\\\\\\\
\\frac{A_{sw}}{s} &= \\frac{303.44\\times10^3}{0.87\\times 500\\times 0.9\\times 748 \\times 2.5}\\\\\\\\
\\frac{A_{sw}}{s} &= 0.41
\\end{align}`

const Eqn14 = `\\begin{align}
s_{max} &= 0.75d\\\\\\\\
s_{max} &= 0.75\\times 748\\\\\\\\
s_{max} &= 561\\:mm
\\end{align}`

const Eqn15 = `\\begin{align}
A_{sw,min} &= \\frac{0.08\\sqrt{f_{ck}}\\:b_w\\:s}{f_{yk}}\\\\\\\\
A_{sw,min} &= 241\\:mm^2
\\end{align}`


class Lecture_19_28 extends React.Component {
  state={
    course: 19,
    lecture: 28, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    To demonstrate the use of the shear design equations discussed in the last lecture, we'll consider the design of a simply supported beam. To tie together everything we've covered so far, and for a little extra practice, we'll also cover the bending design in this example.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider the simply supported beam in Fig. 1. Our task is to determine the maximum design bending and shear reinforcement required. The beam is <MathJax.Node inline formula={"0.5\\: m"} /> wide and <MathJax.Node inline formula={"0.8\\: m"} /> deep. We'll assume <MathJax.Node inline formula={"30\\: mm"} /> cover to reinforcement is required and that that the material properties are <MathJax.Node inline formula={"f_{ck} = 30\\: N/mm^2"} /> and <MathJax.Node inline formula={"f_{yk}=500\\:N/mm^2"} />.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Simply supported beam subject to point loads and a uniformly distributed load.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >    

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    A structural analysis must be performed in order to obtain the design bending moment and shear force values. However, we must first establish the design or effective span, <MathJax.Node inline formula={"l_{eff}"} />, which according to EC2 can be obtained as,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"l_n"} /> is the clear distance between the faces of supports and <MathJax.Node inline formula={"a_1"} /> and <MathJax.Node inline formula={"a_2"} /> are distances determined based on the support conditions. In this case, <MathJax.Node inline formula={"a_1"} /> and <MathJax.Node inline formula={"a_2"} /> are determined as the lesser of half the width of the support or half the overall height of the beam at the respective ends of the beam. Therefore the effective span is <MathJax.Node inline formula={"l_{eff} = 8 \\:m + 0.25\\: m + 0.25\\: m = 8.5 \\:m"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we need to determine the design actions using the partial factors from the STR limit state. Since, in this case, pattern loading is not a concern, the partial factor for all permanent actions will be <MathJax.Node inline formula={"1.35"} /> and the partial factor for variable actions will be <MathJax.Node inline formula={"1.5"} />. Therefore the left point load, <MathJax.Node inline formula={"P_l"} /> is,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The right point load is,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The design distributed load must also capture the influence of self-weight. Assuming a concrete self-weight of <MathJax.Node inline formula={"25\\:kN/m^3"} />, the self-weight distributed load is <MathJax.Node inline formula={"25\\:kN/m^3\\times 0.5\\:m\\times 0.8\\:m = 10\\:kN/m"} />, which gives a design distributed load of,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Following a basic equilibrium analysis, the bending moment and shear force diagram shown in Fig. 2 are obtained (FYI, you can build the Python calculator that produced these diagrams <Link to="/project-2/lecture-1" className={classes.link} target="_blank">here</Link>).
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Design bending moment and shear force diagram.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >  
                  <Typography component="h3" className={classes.H3} > 2.1 Bending design </Typography>                

                  <Typography paragraph className={classes.bodytext}>
                    From the bending moment diagram in Fig. 2 we can see that the design bending moment is <MathJax.Node inline formula={"M_{Ed}=766.12\\: kNm"} />. Noting that the specified cover to reinforcement is <MathJax.Node inline formula={"30\\:mm"} />, if we assume that a <MathJax.Node inline formula={"10\\: mm"} /> diameter outer link will be used and the main longitudinal reinforcement consists of H25 bars, the effective depth can be calculated as <MathJax.Node inline formula={"d = 800 - 30 - 10 - 25/2 = 748 \\:mm"} />. The usual design equations proceed as follows:
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Noting the cross-sectional area of a single H25 bar is <MathJax.Node inline formula={"491 \\:mm^2"} />, we would require <MathJax.Node inline formula={"6"} /> H25 bars, therefore <MathJax.Node inline formula={"A_{s,prov} = 2950\\:mm^2"} />. It is possible to achieve a slightly more efficient rebar arrangement by providing <MathJax.Node inline formula={"4"} /> H25 and <MathJax.Node inline formula={"2"} /> H20 bars <MathJax.Node inline formula={"(2592\\: mm^2)"} />, however, this may not be as desirable as it involves the use of two different (but similar in appearance) bar sizes which could lead to confusion on site. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    At this point, you may now ask the question, will providing an extra <MathJax.Node inline formula={"14 \\%"} /> more rebar result in an over-reinforced beam? To check this, we can simply check the neutral axis depth by comparing the force in the steel at failure with the force in the concrete and solving for the unknown neutral axis depth, <MathJax.Node inline formula={"x"} />.
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since <MathJax.Node inline formula={"x = 0.25d<0.45d"} />, the beam is not over-reinforced with the area of steel provided by <MathJax.Node inline formula={"6"} /> H25 bars.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, we should check the clear distance between bars which is calculated as:
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The minimum clear distance should be the greater of:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>the bar diameter</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>the aggregate size plus <MathJax.Node inline formula={"5 \\:mm"} /></Typography></li>
                    <li><Typography paragraph className={classes.bodytext}><MathJax.Node inline formula={"20 \\:mm"} /></Typography></li>
                  </ul>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The maximum aggregate size will be specified in the concrete mix design but is unlikely to be greater than <MathJax.Node inline formula={"20\\:mm"} />. So, clearly, the spacing is satisfactory. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Shear design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As this is a beam, we will be providing shear links therefore there is no need to determine the concrete's shear capacity, <MathJax.Node inline formula={"V_{Rd,c}"} />. Our first task is to confirm that the concrete compression strut will not crush. For this we require the shear force at the face of the support, <MathJax.Node inline formula={"V_{Ef}"} />. This will be obtained as the shear force at the centreline of the support, <MathJax.Node inline formula={"339.37\\:kN"} />, less the reduction due to the UDL applied over half of the support width,
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The corresponding shear stress developed over a vertical plane through the beam at this location, <MathJax.Node inline formula={"v_{Ef}"} />, is,
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Note that the <MathJax.Node inline formula={"z"} /> used in the previous equation is evaluated as <MathJax.Node inline formula={"0.9d"} /> and not the value for <MathJax.Node inline formula={"z"} /> that was obtained during the bending design, although both values are quite similar. Referring now to Fig. 2 from the previous lecture which contains the minimum and maximum vertical component of the strut resistance, in terms of stress, we can see that for a strut angle of <MathJax.Node inline formula={"22^{\\circ}"} />, the vertical component of the strut resistance is <MathJax.Node inline formula={"v_{Rd,max} = 3.64 \\:N/mm^2"} /> which is larger than the shear stress observed in the beam. From this, we can conclude that the beam is well within its comfortable range for shear and we adopt a strut angle of <MathJax.Node inline formula={"22^{\\circ}"} /> when calculating the area of shear reinforcement. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Remember that if <MathJax.Node inline formula={"v_{Ef}>3.64\\:N/mm^2"} />, we would then need to calculate the strut angle. If <MathJax.Node inline formula={"v_{Ef}>5.28\\:N/mm^2"} />, the stress for the maximum strut angle of <MathJax.Node inline formula={"45^{\\circ}"} />, we would need to increase the shear area to reduce the applied stress and/or increase the concrete strength before proceeding.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can calculate the required area of shear reinforcement based on the shear force at a distance <MathJax.Node inline formula={"d"} /> from the face of the support, <MathJax.Node inline formula={"V_{Ed}"} />. This is because shearing forces closer to the support are considered to be transmitted into the support through direct compression via the inclined strut.
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore using Eqn. 9 from the previous lecture (repeated below), we have,
                  </Typography>

                  <MathJax.Node formula={Eqn13} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The maximum longitudinal spacing of planes of links is,
                  </Typography>

                  <MathJax.Node formula={Eqn14} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, if we can adopt a spacing of <MathJax.Node inline formula={"s = 550 \\:mm"} />, this would yield a required area of steel (per plane of shear links) <MathJax.Node inline formula={"A_{sw,req} = 226\\: mm^2"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can now use Eqn. 10 from the previous lecture (repeated below) to check the minimum allowable area of steel:
                  </Typography>

                  <MathJax.Node formula={Eqn15} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore the minimum area governs. If <MathJax.Node inline formula={"10\\:mm"} /> diameter H10 links are used, this will equate to <MathJax.Node inline formula={"4"} /> link legs per plane of shear links. The primary reinforcement derived from our design can be neatly summarised with a sketch of the typical cross-section, Fig. 3. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that as we discussed previously, four top bars are placed on order to hold the links in position and complete the rebar cage. Also note the use of 2 additional bars on each side wall of the beam. These are often referred to as lacer bars and are generally placed in beams that are deeper than <MathJax.Node inline formula={"750\\: mm"} /> to limit cracking along the sides of the beam. Their maximum spacing should not exceed <MathJax.Node inline formula={"250\\: mm"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the case of lacer bars and top steel bars (not required by design i.e. not compression reinforcement), these will usually be specified by a competent R.C. detailing technician and do not usually need to be specified by the engineer (at least in general UK practice); they are shown here for information. 
                  </Typography>                                
                </Grid>
                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Design summary showing maximum designed bending and shear reinforcement and typical top and side-face (lacer) bars.</figcaption>
                </figure>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_28));
