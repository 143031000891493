import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-5/img1.png";
const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
\\frac{\\mathrm{d}^2y}{\\mathrm{d}x^2} = -\\frac{w(x)}{H}
\\end{equation}`

const eqn2 = `
\\begin{equation}
\\tag{2}
w_s \\delta s = w(x)\\delta x
\\end{equation}`

const eqn3 = `
\\begin{equation}
\\tag{3}
\\mathrm{d}s = \\sqrt{\\mathrm{d}x^2 + \\mathrm{d}y^2}
\\end{equation}`

const eqn4 = `
\\begin{equation}
\\tag{4}
w(x) = w_s\\frac{\\mathrm{d}s}{\\mathrm{d}x}
\\end{equation}`

const eqn5 = `
\\begin{equation}
\\tag{5}
w(x) = w_s\\frac{\\sqrt{\\mathrm{d}x^2 + \\mathrm{d}y^2}}{\\mathrm{d}x}
\\end{equation}`

const eqn6 = `
\\begin{equation}
\\tag{6}
w(x) = w_s\\left(1+\\left(\\frac{\\mathrm{d}y}{\\mathrm{d}x} \\right)^2 \\right)^{1/2}
\\end{equation}`

const eqn7 = `
\\begin{equation}
\\tag{7}
\\boxed{
H\\frac{\\mathrm{d}^2y}{\\mathrm{d}x^2} = -w_s \\left(1+\\left(\\frac{\\mathrm{d}y}{\\mathrm{d}x} \\right)^2 \\right)^{1/2}
}
\\end{equation}`

const eqn8 = `
\\begin{equation}
\\tag{8}
\\frac{\\mathrm{d}y}{\\mathrm{d}x} = A
\\end{equation}`

const eqn9 = `
\\begin{equation}
\\tag{9}
H\\frac{\\mathrm{d}A}{\\mathrm{d}x} =-w_s\\left(1+A^2 \\right)^{1/2}
\\end{equation}`

const eqn10 = `
\\begin{equation}
\\tag{10}
\\int \\frac{\\mathrm{d}A}{\\left(1+A^2 \\right)^{1/2}} = -\\int\\frac{w_s}{H}\\mathrm{d}x
\\end{equation}`

const eqn11 = `
\\begin{equation}
\\tag{11}
\\sinh^{-1} A= -\\frac{w_s}{H}x + C_1
\\end{equation}`

const eqn12 = `
\\begin{equation}
\\tag{12}
A = \\sinh\\left(-\\frac{w_s}{H} x + C_1 \\right)
\\end{equation}`

const eqn13 = `
\\begin{equation}
\\tag{13}
\\frac{\\mathrm{d}y}{\\mathrm{d}x} = \\sinh\\left(-\\frac{w_s}{H} x + C_1 \\right)
\\end{equation}`

const eqn14 = `
\\begin{equation}
\\tag{14}
\\int \\mathrm{d}y = \\int \\sinh\\left(-\\frac{w_s}{H} x + C_1 \\right) \\mathrm{d}x
\\end{equation}`

const eqn15 = `
\\begin{equation}
\\tag{15}
\\boxed{
y = -\\frac{H}{w_s}\\cosh\\left(-\\frac{w_s}{H}x+C_1 \\right) + C_2
}
\\end{equation}`



class Lecture_17_5 extends React.Component {
  state={
    course: 17,
    lecture: 5, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll convert the equation we obtained in the previous lecture to use, <MathJax.Node inline formula={"w_s"} />, the self-weight per unit curved length. Then, we'll integrate this equation twice to obtain an equation in <MathJax.Node inline formula={"y"} /> rather than its second derivative.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Recall from the last lecture,
                  </Typography>

                  <MathJax.Node formula={eqn1} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now consider the cable segment in Fig. 1 below in which <MathJax.Node inline formula={"\\delta s"} /> is the infinitesimal curved length. We know that regardless of whether we express the load in terms of <MathJax.Node inline formula={"\\delta s"} /> or <MathJax.Node inline formula={"\\delta x"} />, the total load on the cable is the same, therefore,
                  </Typography>

                  <MathJax.Node formula={eqn2} className={classes.formula}/>                                                       
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Infinitesimal length of cable.</figcaption>
                </figure>  
                

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    As <MathJax.Node inline formula={"\\delta x \\rightarrow 0"} />, we can approximate the curved cable segment as a straight line. Therefore we can approximate its length as,
                  </Typography>

                  <MathJax.Node formula={eqn3} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Based on Eqn. 2 and letting <MathJax.Node inline formula={"\\delta x \\rightarrow 0"} />, we have,
                  </Typography>

                  <MathJax.Node formula={eqn4} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Substituting Eqn. 3 into Eqn. 4, 
                  </Typography>

                  <MathJax.Node formula={eqn5} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Rearranging,
                  </Typography>

                  <MathJax.Node formula={eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The last step is to substitute Eqn. 6 into Eqn. 1,
                  </Typography>

                  <MathJax.Node formula={eqn7} className={classes.formula}/>

                  <Typography component="h2" className={classes.H2} > Integrating the governing differential equation </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now that we have an equation with the desired terms, namely <MathJax.Node inline formula={"H"} />, <MathJax.Node inline formula={"w_s"} /> and <MathJax.Node inline formula={"y"} /> (or its second derivative), we can integrate it twice with respect to <MathJax.Node inline formula={"x"} /> to obtain the expression for cable deflection we're looking for. Before starting the integration, we can make a trivial substitution,  to make the equations a little less hectic looking. Let,
                  </Typography>

                  <MathJax.Node formula={eqn8} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    so, Eqn. 7 becomes,
                  </Typography>

                  <MathJax.Node formula={eqn9} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Next, we rearrange this expression to facilitate the integration of both sides,  
                  </Typography> 

                  <MathJax.Node formula={eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The left-hand side of this equation is a <i>standard integral</i> that we can read off tables of integrals. The right-hand side is a straightforward integration,
                  </Typography>

                  <MathJax.Node formula={eqn11} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Rearranging for <MathJax.Node inline formula={"A"} />,
                  </Typography>

                  <MathJax.Node formula={eqn12} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now reversing our trivial substitution from Eqn. 8, 
                  </Typography>

                  <MathJax.Node formula={eqn13} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Integrating again,
                  </Typography>

                  <MathJax.Node formula={eqn14} className={classes.formula}/>

                  <MathJax.Node formula={eqn15} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    This equation is the catenary equation we've been looking for. It describes the shape of a cable hanging under its own weight. However, it still contains two unknown constants of integration, <MathJax.Node inline formula={"C_1"} /> and <MathJax.Node inline formula={"C_2"} />. So we could call it a <i>general</i> catenary equation. We still need to use some boundary conditions to evaluate <MathJax.Node inline formula={"C_1"} /> and <MathJax.Node inline formula={"C_2"} />. We'll do this in the next lecture.
                  </Typography>                                    
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_5));
