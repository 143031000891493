import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-30/img1.jpg";
import img2 from "../../../images/Lectures/C17-30/img2.jpg";

const codeString1 = 
`print(f"OUTSTANDING FORCE IMBALANCE")
for i in np.arange(0,nDoF):     
    if i not in restrainedIndex:
        print(f'Remaining force imbalance at DoF {i} is {round(F_inequilibrium[i,0]/1000,3)} kN')`

const codeString2 = 
`print("REACTIONS")
f_int = FI_FINAL[:,-1]
for i in np.arange(0,len(restrainedIndex)):           
    index = restrainedIndex[i]
    print("Reaction at DoF {one}: {two} kN".format(
        one = index+1, 
        two = round(f_int[index].item()/1000,2)))`

const codeString3 = 
`print("MEMBER FORCES (incl. any pre-tension)")    
for n, mbr in enumerate(members):    
    print("Force in member {one} (nodes {two} to {three}) is {four} kN".format(
        one = n+1, 
        two=mbr[0], 
        three=mbr[1], 
        four=round(mbrForces[n]/1000,2)))`


class Lecture_17_30 extends React.Component {
  state={
    course: 17,
    lecture: 30, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now that our solver is running, we need to turn our attention to the results that it produces. In the next section, we'll start writing code to plot the structure. In this lecture, we'll write some code to print out the results in text format. If you've completed the 2D truss analysis course, a lot of what we write here will be familiar.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Summary output </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first thing we want to see is the remaining force imbalance for each DoF. This is done quite simply by iterating through each DoF, and checking to see if each is a restrained DoF. If not, we can generate a print statement that reports the value from <code className={classes.code}>F_inequilibrium</code> for that DoF.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can plot the reactions. This time we cycle through the restrained indices and extract the reactions from the final column of data in <code className={classes.code}>FI_FINAL</code>, the container holding our snapshots of the internal force system. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    After this, we can plot the axial force for each member by extracting directly from <code className={classes.code}>mbrForces</code>.
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, we can generate statements for the x and y displacements at each node. We cycle through each node and identify the index of the horizontal and vertical DoF at the node. These can be used to identify the relevant displacements from the final column of displacement data held in <code className={classes.code}>UG_FINAL</code>. When the code block is executed, we generate the following output statements, Fig. 1.
                  </Typography>
                                               
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Summary text output for simulation of linear structural behaviour.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We can see that the results are exactly as we would expect; because the deflections are small by comparison to the scale of the structure, the behaviour is linear and could be adequately described by a simple linear model. This is good to see, but it doesn't really test our code.  
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So, let's rerun the simulation, but this time, reduce the cross-sectional area from <MathJax.Node inline formula={"0.0025\\:m^2"} /> to <MathJax.Node inline formula={"0.0000025\\:m^2"} />. This will yield much larger deflections which we know will result in non-linear behaviour. This time our text output shows slightly more interesting results, Fig. 2. 
                  </Typography>
                                                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Summary text output for simulation of non-linear structural behaviour.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Focusing initially on the deflection, we can see that nodes 2 and 3 have deflected vertically by approximately <MathJax.Node inline formula={"0.171\\:m"} />. This can now be considered significant compared to the overall scale of the structure. As a result, notice that while the vertical reaction is still <MathJax.Node inline formula={"10\\:kN"} /> at nodes 1 and 4, the horizontal reactions have reduced to <MathJax.Node inline formula={"9.08\\:kN"} /> because the inclined members have significantly changed their angle of orientation; now hanging more vertically. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Also, note that there is some residual force imbalance in the structure. As this is a numerical approximation, it is expected that there may be some remaining inequilibrium. You need to make a judgement about the acceptability of this. In this case, as the force imbalance is only <MathJax.Node inline formula={"2\\:N"} /> , we can consider this small and insignificant compared to the magnitude of the forces developed within the structure. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This test output has given us a good understanding of how our structure responds to the applied loads. However, this structure only consists of 3 bars, for larger structures with hundreds or thousands of elements, a text output will be less helpful. This is where a graphical representation of the results is more valuable. We'll build this in the next section. One last thing we need to do before we finish this section is implement the ability to take self-weight into account. We'll do this in the next and final lecture in this section. 
                  </Typography>                                                
                </Grid>
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_30));
