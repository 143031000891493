import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-56/Final_CoC.png";
import img2 from "../../../images/Lectures/C17-56/Sean.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_56 extends React.Component {
  state={
    course: 17,
    lecture: 56, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >

                  {/* <Typography component="h2" className={classes.H1} > H1 Title </Typography> */}

                  <Typography paragraph className={classes.bodytext}>
                    Well done for completing the course! I hope you found working through the course helpful and rewarding.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    By now, you should have a very good understanding of the particular challenges presented by cable structures and geometric non-linearity in general. More importantly, you’ve equipped yourself with some powerful tools to start unlocking the behaviour of these structures.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    You shouldn’t consider the code we’ve implemented here as a finish line - there are always ways to improve and expand the codes we write in these courses. I do hope you continue to work on this code and develop it further. The understanding you’ve developed during this course leaves you in an excellent position to continue exploring non-linear behaviour. I think it’s one of the most interesting facets of structural engineering.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    If you’re keen to continue exploring geometric non-linearity with me, the next step is 3D cablenet structures. We cover that in this course, <Link to="/course-18/lecture-1" className={classes.link} target="_blank">Modelling and analysis of non-linear 3D cablenet tensile structures in Python</Link>. We’ll also be expanding our use of Blender for structural modelling and 3D form-finding
                  </Typography>
                                   

                  <Typography component="h2" className={classes.H2} > 🏆 Certificate of Completion </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, don’t forget to claim your Certificate of Completion.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    You’ve developed a body of knowledge and skills that are relatively rare, so let people know by posting your Certificate of Completion on your LinkedIn profile or workplace CPD portfolio.
                  </Typography>
                </Grid>

                <img style={{width:"30%", margin:"auto"}}src={img1} />
                
                <Grid item xs={12} sm={12} md={10} >  
                <Typography component="h2" className={classes.H2} > 🙏 Course Feedback </Typography> 

                  <Typography paragraph className={classes.bodytext}>
                    Please also take a couple of minutes to provide some feedback and provide a rating for the course. Feedback helps me improve my courses and also helps inform other students before they take a course.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                  <Link to="/review-certificate-request" className={classes.link} target="_blank">To leave some feedback and request your certificate of completion, just fill out this form</Link>. Your request will be manually reviewed to confirm you’ve completed the course and your personalised certificate will be emailed to you. You should receive your certificate within 24 hours (remember to keep an eye on your junk email folder).
                  </Typography>                                                     
                  
                  <Typography paragraph className={classes.bodytext}>
                    Well done again on completing the course and I hope to see you on another DegreeTutors course very soon. 
                  </Typography>

                  <img style={{width:"15%", margin:"auto"}}src={img2} />
                                                                                                                        
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_56));
