import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-33/img1.jpg";

const codeString1 = 
`def plotResult(label_offset=0.02, 
               xMargin=2, 
               yMargin=1.2, 
               scaleFactor=1,                                              
               Load_Increment=100,
               Final_config=True):
        
    #Select the correct load increment to display
    if Final_config:
        #Select final load increment
        Load_Increment = -1 
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten() 
    else:
        #Load increment selected based on slider
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten() 
        
#Call the interact widget----------------------------------------------------------------
rows, convergedIncs = UG_FINAL.shape
widgets.interact(plotResult, 
                 label_offset=(0.01, 0.1, 0.01), 
                 xMargin=(0.2, 5, 0.2),
                 yMargin=(0.2, 5, 0.2),
                 scaleFactor=(1,5,0.5),                 
                 Load_Increment=(10,convergedIncs-1,1))
plt.show()`


class Lecture_17_33 extends React.Component {
  state={
    course: 17,
    lecture: 33, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll take the first steps toward setting up our results plotting code. We're going to use the same structure as our previous plot; we'll wrap our code in a function and call it using <code className={classes.code}>widgets.interact()</code>. This plotting function will expand over the course of this section as we layer on additional complexity. 
                  </Typography>                  

                  <Typography paragraph className={classes.bodytext}>
                    As already mentioned, the overall structure of our plotting code is the same as our pre-analysis plot. However, there are some differences; the first is that we've added a couple of new widget controls, <code className={classes.code}>Load_Increment</code> and <code className={classes.code}>Final_config</code>. <code className={classes.code}>Load_Increment</code> will allow us to control which increment of loading we want to plot. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll also set up a boolean widget called <code className={classes.code}>Final_config</code>. If <code className={classes.code}>Final_config</code> is <code className={classes.code}>True</code>, then we will plot the data for the final load increment. If it's <code className={classes.code}>False</code>, we'll use the value of the <code className={classes.code}>Load_Increment</code> slider to select which data to plot. This is the benefit of saving snapshots of data for each converged load increment. We can visualise the structure's response at any point during the loading history and observe how it evolves into its final equilibrium position.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On lines 9 to 21, we are using the values passed in for <code className={classes.code}>Final_config</code> and <code className={classes.code}>Load_Increment</code> to extract the relevant data to plot and save it onto new variables with more convenient names. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is all we'll do inside the <code className={classes.code}>plotResults()</code> function for now. The remaining code in the block below simply identifies the number of columns in <code className={classes.code}>UG_FINAL</code> and calls <code className={classes.code}>interact()</code>, as we saw in lecture 20.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, note that we've also included a <code className={classes.code}>scaleFactor</code> control. We'll use this later to control the scale of the deflections in our plot.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    The code block above can go at the very end of the notebook. Once it's executed we should see the 6 widgets created - ready to control the plot we build in the next lecture. 
                  </Typography>
                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Widgets to control the results plot.</figcaption>
                </figure> 

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_33));
