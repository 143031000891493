import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import history from "../../../history";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import ProjectData from '../../../Data/projects.js';
import ProjectCard from './ProjectCard'

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5)    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {
		// marginTop: theme.spacing(5),
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	}
});

class Projects extends React.Component {

  componentDidMount() {  
    window.scrollTo(0, 0);
  }

  //Handle course click
  handleProjectClick = (project)=>{       
    //Send user to coursepage
		history.push({
			pathname: "/" + project.path,
		});
  }

  //Required when combining Matrial UI <TextField/> with redux-form
	returnProjectList() {
		return ProjectData.projectList.map(project => {
          if(project.visible){
            //Determine id of completed lectures for the current course
            let lectures = this.props.compLectures.filter(l =>{
              if(l.courseId == project.projectId && l.format=="project"){
                return l
              }     
            })    
            let ids = []
            lectures.forEach(l=>{
              ids.push(l.lectureId) 
            })  

            return (            
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={project.projectId}>              
                 <ProjectCard    
                  user={this.props.auth}             
                  ids={ids}
                  project={project}
                  onClick={project => this.handleProjectClick(project)}
                />
              </Grid>
            );
          } else{
            return null
          }      		
      	});			   
	}

  

	render() {		
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={"PYTHON PROJECTS"}
				>
        <Grid container justify="center" spacing={4} style={{paddingLeft:"5%", paddingRight:"5%", marginBottom:"325px"}}>                    
          {this.returnProjectList()}                    
        </Grid>
        </Layout>
		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
    compLectures: state.completedLectures
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(Projects));
