import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-55/img1.jpg";
import img2 from "../../../images/Lectures/C17-55/img2.jpg";
import img3 from "../../../images/Lectures/C17-55/img3.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1=
`#Constants
E = 70*10**9 #(N/m^2)
swt = True #Take self-weight into account 

areaCable = 0.00004 #(m^2) 
areaBar = 0.002 #(m^2)
gammaCable = 10 #(kg/m)10 Cable mass per unit length
gammaBar = 40 #(kg/m) Bar mass per unit length

preTension = 10 #Pre-tension applied to all cable elements
P = 500 #(N)🚨 Point load magnitude (and direction via sign)
pointLoadAxis = 'x' #🚨The GLOBAL axis along which point loads are applied

nForceIncrements = 5000 #Initial number of force increments for applied loading
convThreshold = 30 #(N) Threshold on maximum value of F_inequilibrium
itLimit = 10000 #The maximum number of iterations to achieve convergence
checkSlackAfter = 100`


class Lecture_17_55 extends React.Component {
  state={
    course: 17,
    lecture: 55, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this final lecture, we won't develop any new code - the hard work is done! The main aim of this lecture is simply to run another model through our code and see how it handles it. The Blender file and Jupyter Notebook are available, as usual, in the resources panel. You should consider this lecture as a prompt to go and build your own test models and get them converging in your new solver notebook. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > The structure </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The model we're going to analyse in this lecture is a simple cable-stayed lattice tower, Fig 1. These are very common structures, that you've no doubt come across at some point. The model will naturally include both bar and cable elements. We're not basing the model on any one structure, this model was simply borne out of some exploratory modelling in Blender - something I strongly encourage you to do following this lecture. 
                  </Typography>
                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. The Blender model of a cable-stayed lattice tower.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The tower is <MathJax.Node inline formula={"30\\:m"} /> high and <MathJax.Node inline formula={"1\\:m"} /> wide and is stabilised by 6 cables at third-points along its height. The four nodes at ground level will be modelled as pins and we’ll apply a lateral force along the right edge of the tower in the positive x-direction, Fig 2.
                  </Typography>
                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Support consitions and applied loading.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The modelling process uses basic vertex modelling operations in Blender. I won't document the process here, instead, you should watch the video to get a sense of how to build something similar. The data defining the structure is exported using the same scripts we wrote earlier in the course. The process of going from exploratory modelling to full simulation really is incredibly fast, once you familiarise yourself with the most basic Blender operations.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Simulation parameters </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The simulation parameters are presented below. This is all we need to update in our solver notebook for any new simulation. The point load magnitude is set at <MathJax.Node inline formula={"500\\:N"} /> along the x-axis. Through trial and error, a combination of <MathJax.Node inline formula={"5000"} /> force increments and a convergence threshold of <MathJax.Node inline formula={"30\\:N"} /> have been found to lead to a converging model.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>
                  
                  <Typography component="h2" className={classes.H2} > Simulation results </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Running the simulation with these parameters leads to a final force imbalance of only <MathJax.Node inline formula={"10\\:N"} />, which gives us confidence in the final results. Figure 3 shows the deflected shape in force distribution within the structure. Qualitatively the behaviour is as expected considering the applied loading. We can see the self-weight of the cables causes them to sag, with the left to right deflection of the structure leading to larger sag on the right-side cables. The axial forces in the lattice tower are also reducing in magnitude as we move up the tower and the overturning moment generated by the lateral forces is reduced. So, from a qualitative perspective, the model output passes the <i>sniff test</i>. 
                  </Typography>                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Deflected shape and axial force distribution.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    If this analysis was part of a wider analysis and design exercise, the next task might be to alter the loading or structural properties or to assess the acceptability of these results against some design criterion. However, that's not something we'll pursue any further here. At this point, we're done! 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Well done - you've built yourself quite a handy little toolbox.
                  </Typography>                                  
                </Grid> 

                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_55));
