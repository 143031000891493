export default {
  toolList:[
    {
      toolId: 1,
      title: "🛠️ 2D Truss Analysis Toolbox",
      tagline: "A 2D planar truss analysis toolbox. For calculating reactions, member forces and deflections in trusses that consist of axially loaded members subject to nodal loading.",
      path: "structureworks/truss2d",
      visible: true, //Is tool card visible
      access: 'open', //Is tool open to non-members (controlled by Private Route)
      message: 'BACK SOON', //message to show in button location when tool is not available
      available: true, //Is tool ready to be accessed
      parentCourse: 'https://www.degreetutors.com/the-direct-stiffness-method-for-truss-analysis-with-python/',
      limits: {
        elements: 25,
        restraints: 3
      },
      changeLog: [
        {
          version: '1.0.2',          
          date: '17th September 2022',
          notes: [
            {
              note: 'Minor improvements to 3D viewport including light background and ground plane grid.'
            }
          ],
        },
        {
          version: '1.0.1',          
          date: '8th September 2022',
          notes: [
            {
              note: 'Improved error handling to provide better user feedback if analysis data is not returned due to backend error.'
            }
          ],
        },
        {
          version: '1.0.0',          
          date: '12th January 2022',
          notes: [
            {
              note: 'Initial release.'
            }
          ],
        }        
      ]
    },
    {
      toolId: 2,
      title: "🛠️ 3D Space Frame Analysis Toolbox",
      tagline: "A toolbox for analysing 3D space frame trusses. Use this toolbox to calculate reactions, member forces and deflections in 3-dimensional structures that consist of axially loaded members subject to nodal loading.",
      path: "structureworks/truss3d",
      visible: true,
      access: 'open',
      message: 'COMING SOON',
      available: true,
      parentCourse: 'https://www.degreetutors.com/3d-truss-analysis-using-the-direct-stiffness-method/',
      limits: {
        elements: 320,
        restraints: 12
      },
      changeLog: [
        {
          version: '1.0.0',          
          date: '22nd September 2022',
          notes: [
            {
              note: 'Initial release.'
            }
          ],
        }        
      ]
    },
  ]
}

