import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_1 extends React.Component {
  state={
    course: 19,
    lecture: 1, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Welcome to this course on the fundamentals of reinforced concrete design to Eurocode 2. The aim of this course is to provide an introduction to the behaviour of reinforced concrete in bending and shear. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    By the end of the course you should understand the mechanical models we use to characterise reinforced concere behaviour. You’ll also develop an understanding of the rules and analysis techniques prescribed by Eurocode 2 for the analysis and design reinforced concrete structures. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    This first section covers some basic housekeeping - the core content starts in the next section.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 2 - Actions and Limit State Design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This course will focus on the design of reinforced concrete structures. But before we can design anything, we first need to determine what forces we’re designing for. Since we’re in the realm of code-based design, we need to carefully determine the design loads on our structures. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In this section, we’ll focus on determining design loading (or <i>actions</i>) in line with the guidance provided in the relevant Eurocodes. This will require us to consider design limit states - a fundamental concept that applies to the design of structures in any material, not just reinforced concrete. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 3 - Bending of Reinforced Concrete </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Probably the most fundamental reinforced concrete design exercise is designing for flexure. In section 3, we’ll focus on developing an understanding of the flexural behaviour of reinforced concrete. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    After completing this section, you’ll clearly understand how the concrete and steel reinforcement act compositely to develop an internal moment of resistance to counter the externally applied forces. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We’ll consider singly reinforced, double reinforced, over-reinforced and flanged cross-sections. In each case, we’ll complete some design exercises to put the theory into context.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Section 4 - Shear Resistance of Reinforced Concrete </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    After designing for bending, designing to accommodate shearing forces is the next critical task. In this section, we’ll again use basic mechanics to develop a simple model of shear resistance. The model we’ll focus on is called the <b>Variable Strut Inclination Model</b>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    After explaining the fundamental mechanics at play, we’ll work through some design examples to bed-in the theory discussed. After using the variable strut model to design vertical shear reinforcement, we’ll apply it to designing for transverse shear forces that develop at the web-flange interface in flanged beams, first introduced in the previous section.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Once complete, you’ll understand the mechanics of shear reinforcement and how to design for direct and transverse shear in reinforced concrete beams.
                  </Typography>                  

                  <Typography component="h2" className={classes.H2} > Section 5 - Automating section analysis in Python </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the final section of the course we’ll explore how we can automate our calculations and speed up the analysis and design process. Most student and professional engineers will at some point have used a spreadsheet to automate their work. In this section we’ll go one step further and use the popular programming language, Python. 
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    This section is optional - we won’t be covering anything new from a concrete analysis point of view, just revisiting material already covered and sprinkling some Python magic on top. In particular, we’ll return to our flexure calculations and use the worked examples from section 3 as a roadmap to help us build up some analysis scripts. 
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    The main aim of this section is to get you thinking about how you can use programming as a simple tool to speed up your engineering calculations and analysis workflows. You’ll find that with a little practice, scripting in Python (or any other language you like) is far superior to a spreadsheet when it comes to managing engineering calculations. 
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    Once you’ve finished this section, you should be comfortable expanding your analysis scripts to cover other analyses from the course. But the big win from this section will be the application of scripting to your other engineering analyses and workflows.
                  </Typography>
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_1));
