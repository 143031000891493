import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const eqn1 = `\\begin{equation}
\\tag{1}
\\begin{bmatrix}
R\\\\
S
\\end{bmatrix} = 
\\begin{bmatrix}
\\frac{L_0+u}{L_0+e}\\\\
\\frac{v}{L_0+e}
\\end{bmatrix}
\\begin{bmatrix}
P
\\end{bmatrix}
\\end{equation}`

const eqn2 = `\\begin{equation}
\\tag{2}
\\begin{bmatrix}
F_{x,i}\\\\
F_{y,i}\\\\
F_{x,j}\\\\
F_{y,j}\\\\
\\end{bmatrix} = 
\\begin{bmatrix}
T
\\end{bmatrix}^T
\\begin{bmatrix}
R\\\\
S
\\end{bmatrix}
\\end{equation}`

const eqn3 = `\\begin{equation}
\\tag{3}
\\begin{bmatrix}
F_{x,i}\\\\
F_{y,i}\\\\
F_{x,j}\\\\
F_{y,j}\\\\
\\end{bmatrix} = 
\\begin{bmatrix}
T
\\end{bmatrix}^T
\\begin{bmatrix}
\\frac{L_0+u}{L_0+e}\\\\
\\frac{v}{L_0+e}
\\end{bmatrix}
\\begin{bmatrix}
P_o
\\end{bmatrix}
\\end{equation}`

const eqn4 = `\\begin{equation}
\\tag{4}
\\begin{bmatrix}
F_{x,i}\\\\
F_{y,i}\\\\
F_{x,j}\\\\
F_{y,j}\\\\
\\end{bmatrix} = 
\\begin{bmatrix}
T
\\end{bmatrix}^T
\\begin{bmatrix}
1\\\\
0
\\end{bmatrix}
\\begin{bmatrix}
P_o
\\end{bmatrix}
\\end{equation}`



const codeString1 = 
`def initPretension():
    """
    P = axial pre-tension specified for each bar
    Calculate the force vector [Fa] for each bar [Fa] = [T'][AA'][P]
    Combine into an overall vector representing the internal force system and return    
    """
    F_pre = np.array([np.zeros(len(forceVector))]).T #Initialse internal force vector
        
    for n, mbr in enumerate(members):         
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member
        
        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member 
        
        #Determine internal pre-tension in global coords
        TM = TMs[n,:,:]        
        AAp = np.array([[1],[0]])
        P = P0[n]        
        F_pre_global = np.matmul(TM.T,AAp)*P                         
          
        #Add member pre-tension to overall record
        F_pre[ia,0] = F_pre[ia,0] + F_pre_global[0][0]
        F_pre[ib,0] = F_pre[ib,0] + F_pre_global[1][0]
        F_pre[ja,0] = F_pre[ja,0] + F_pre_global[2][0]
        F_pre[jb,0] = F_pre[jb,0] + F_pre_global[3][0]

    return F_pre`


class Lecture_17_23 extends React.Component {
  state={
    course: 17,
    lecture: 23, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll write the function <code className={classes.code}>initPretension()</code>. This will take a specified axial pre-tension for each member and build the corresponding internal force system for the structure. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Before writing the code, let's recap some of the basic theory we covered in the previous section. Recall that for a deformed element, we derived the relationship between the axial force (along the deformed axis), <MathJax.Node inline formula={"P"} /> and its orthogonal local components, <MathJax.Node inline formula={"R"} /> and <MathJax.Node inline formula={"S"} /> as,
                  </Typography>

                  <MathJax.Node formula={eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We've also seen that the relationship between forces in the local and global coordinate system is given by,
                  </Typography>

                  <MathJax.Node formula={eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Let's now assume that the axial force, <MathJax.Node inline formula={"P"} />, is actually some value of pre-tension, <MathJax.Node inline formula={"P_o"} />. Now, for every member, we would like to determine the pre-tension force in global coordinates; we can do this by combining Eqns. 1 and 2,
                  </Typography>

                  <MathJax.Node formula={eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now, since this pre-tension force vector is only calculated once at the beginning of the analysis before the structure has deflected, <MathJax.Node inline formula={"u=v=e=0"} />, so Eqn. 3, marginally simplifies to, 
                  </Typography>

                  <MathJax.Node formula={eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Equation 4 can now be used to calculate the global nodal forces, for each element, due to pre-tension in that element. And since we can easily identify the indices of the DoFs for each element, we can plug these nodal forces into one big pre-tension force vector for the whole structure. Now we can write the code to actually do it.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Function to calculate pre-tension force vector for the system </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This function definition follows a pretty familiar pattern; we start by defining a container or data structure to hold the force vector we’re about to calculate, <code className={classes.code}>F_pre</code>. Then, we cycle through each member and extract the node numbers and DoF indices. These indices can be used to populate the correct locations within <code className={classes.code}>F_pre</code>, the nodal forces for each member. Remember, the indices are basically the addresses within our '<i>whole structure</i>' data containers, where we can slot in data for a given member. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On lines 20 to 23, we implement Eqn. 4, from above, calculating the element's nodal pre-tension forces in global coordinates. Then we simply update the relevant values in <code className={classes.code}>F_pre</code>, being careful not to override values but rather add to values already present. Finally, we can return the global vector of pre-tension forces at the end.
                  </Typography>    

                  <CodeBlock>{codeString1}</CodeBlock>  

                  <Typography paragraph className={classes.bodytext}>
                    Remember, this is just a function definition we're writing here. We actually called this function in the code we wrote in lecture 21.
                  </Typography>                  
                                                                                                       
                </Grid>                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_23));
