import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const codeString1 =
`#Constants
E = 70*10**9 #(N/m^2) Young's modulus 
A = 0.0025 #(m^2) Cross-sectional area 

gamma = 0 #(kg/m) Cable mass per unit length
Areas = A*np.ones([len(members)]) #An array to hold individual area for each member 
P0 = 0*np.ones([len(members)]) #An array to hold individual member pre-tension values
P = -10000 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

convThreshold = 30 #(N) 🚨 Threshold on maximum value of F_inequilibrium`

const codeString2 = 
`def testForConvergence(it, threshold, F_inequilibrium):
    """
    Test if structure has converged by comparing the maximum force in the equilibrium 
    force vector against a threshold for the simulation.
    """
    notConverged = True #Initialise a local convergence flag
    maxIneq = 0
    if it>0:        
        maxIneq = np.max(abs(F_inequilibrium[freeDoF])) 
        if(maxIneq<threshold):
            notConverged = False
    
    return notConverged, maxIneq`


class Lecture_17_27 extends React.Component {
  state={
    course: 17,
    lecture: 27, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll build a simple function to test whether or not our structure has converged. Recall that this is a key function we call at the end of the main loop (lecture 21) to test whether or not a further analysis iteration is required. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The logic behind the test is quite basic. We'll consider the structure to have converged when the maximum force value within the inequilibrium force vector, <code className={classes.code}>F_inequilibrium</code>, has fallen below some threshold specified as a simulation parameter. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This threshold will have a significant impact on our simulation, set too low, and we could make it too difficult for the structure to converge. If the structure does converge, the lower the threshold, the more analysis iterations will be required for every load increment. This could significantly increase our simulation time. On the other hand, if it's too high, the structure will be judged to have converged without being sufficiently close to equilibrium. Care should be taken, and some experimentation should be carried out to determine a suitable threshold value for any given simulation. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The threshold variable, <code className={classes.code}>convThreshold</code>, can be defined in the manual data entry block at the top of the code. The full data entry block is repeated below, and the value of <code className={classes.code}>convThreshold</code> is set at <MathJax.Node inline formula={"30\\:N"} /> initially. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography component="h2" className={classes.H2} > Function to test for convergence </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can build the <code className={classes.code}>testForConvergence()</code> function. Naturally, it will need to take as its arguments the current inequilibrium force vector, <code className={classes.code}>F_inequilibrium</code>, the convergence threshold, <code className={classes.code}>convThreshold</code>, and we'll also pass in the current iteration number. 
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    First, we initialise a local version of the convergence flag to <code className={classes.code}>True</code> (line 6 below) and initialise, to zero, a variable to hold the maximum value of the inequilibrium force vector (line 7). Then, provided we are on the second iteration or higher, we extract the maximum value of <code className={classes.code}>F_inequilibrium</code>. Note that we only extract from the free DoFs within <code className={classes.code}>F_inequilibrium</code> as we don't want to extract reaction forces. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Then, if the maximum value of this subset of <code className={classes.code}>F_inequilibrium</code> values is smaller than the threshold, we can switch the <code className={classes.code}>notConverged</code> flag to <code className={classes.code}>False</code>, indicating convergence. Otherwise, the flag stays at its default value, indicating that the structure has not converged sufficiently. In other words, disagreement between the internal and external force systems is still too high. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The flag value, as well as the maximum inequilibriun force, is then returned from the function. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    In the next lecture, we will write a function that we haven't called yet. This function will calculate the axial force in each member based on the current displacement for the structure. If this sounds familiar, it should because we've already done something very similar in the previous lecture. In fact, we will reuse a lot of the same code, but it will be convenient to have a standalone function to perform this task as we move forward. 
                  </Typography>
                                              
                </Grid>
                
               
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_27));
