import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/utilityActions";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

const styles = theme => ({
	close: {
		padding: theme.spacing(0.5)
	},
	icon: {
		fontSize: 20,
		opacity: 0.9,
		marginRight: theme.spacing()
	},
  snackbarStyleViaContentProps: {
    backgroundColor: "#25252550",
    border:"1px solid #33cc99",
    borderRadius:"5px",
    color:"#33cc99"
  },
});

class Feedback extends React.Component {
	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.props.clearFlash();
	};

	returnIcon = () => {
		const { classes } = this.props;
		if (this.props.flash.type === "warning") {
			return <WarningIcon color="secondary" className={classes.icon} />;
		} else if (this.props.flash.type === "success") {
			return <CheckCircleIcon color="secondary" className={classes.icon} />;
		} else if (this.props.flash.type === "error") {
			return <ErrorIcon color="secondary" className={classes.icon} />;
		} else if (this.props.flash.type === "info") {
			return <InfoIcon color="secondary" className={classes.icon} />;
		}
		return null;
	};

	render() {
		const { classes } = this.props;
		return (
			<Snackbar        
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}        
				open={this.props.flash.message ? true : false}
				autoHideDuration={6000}
				onClose={this.handleClose}
				ContentProps={{
					"aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
				}}
				message={
					<span >
						{this.returnIcon()}
						{<span id="message-id" >{this.props.flash.message}</span>}
					</span>
				}
				action={[
					<IconButton
						key="close"						
            style={{color:"#33cc99"}}
						aria-label="close"
						className={classes.close}
						onClick={this.handleClose}
					>
						<CloseIcon />
					</IconButton>
				]}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		flash: state.flash
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(Feedback));
