import React, { useRef, useState, useEffect } from 'react';
import Colorbar from './Colorbar';
 
const ColorbarWrapper = ({data}) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)
 
  useEffect(() => {
    if (!chart) {
      setChart(new Colorbar(chartArea.current, data))
    }
    else {
      chart.update(data)
    }
  }, [chart, data])
 
  return (
    <div className="chart-area" ref={chartArea}></div>
  )
}
 
export default ColorbarWrapper