import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import authReducer from "./authReducer";
import flashReducer from "./flashReducer";
import errorsReducer from "./errorsReducer";
import subscriberDataReducer from "./subscriberDataReducer";
import activeUserReducer from "./activeUserReducer"
import solverUsageReducer from "./solverUsageReducer"
import truss2DReducer from "./analysisTruss2DReducer";
import truss3DReducer from "./analysisTruss3DReducer";
import completedLecturesReducer from "./completedLecturesReducer";

export default combineReducers({
	auth: authReducer,
	form: reduxForm,
	flash: flashReducer,
	errors: errorsReducer,
  expiryData: subscriberDataReducer,
  truss2D: truss2DReducer,
  truss3D: truss3DReducer,
  activeUsers: activeUserReducer,
  solverUsage: solverUsageReducer,
  completedLectures: completedLecturesReducer
});
