import React from "react";
import classNames from "classnames";
import * as actions from "../../actions";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Avatar from "@material-ui/core/Avatar";
import { topList, btmList, btmListPublic, styles } from "./DrawerConfig";
import Footer from "./Footer"
// import logoWhite from "../../images/Landing/Banner_white_small.png";
import logoWhite from "../../images/Landing/engineering-skills-logo-dark.png";

import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MessageIcon from '@material-ui/icons/Message';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { mdiAccountCog } from "@mdi/js";
import Icon from "@mdi/react";

const iconStyle = {
	color: "#33CC99"
};

class Layout extends React.Component {
	state = {
		open: this.props.menuOpenByDefault==false?false:true,
		helpModalOpen: false
  };
  
  componentDidMount(){   
    //Collapse sidebar by default on mobile
    const mobileWidth = 767
    if(window.innerWidth<mobileWidth){
      this.setState({
        open:false
      })
    }             
    this.props.fetchCompletions() //Record of lectures completed    
  }

  handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	//Return modal position style attributes
	getModalStyle = () => {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`
		};
	};

	//Switch modal open flag in state to open
	handleModalOpen = () => {
		this.setState({
			helpModalOpen: true
		});
	};

	//Switch modal open flag in state to closed
	handleModalClose = () => {
		this.setState({
			helpModalOpen: false
		});
	};

	//Required when combining Matrial UI <TextField/> with redux-form
	renderTextArea({ id, name, input, label, ...custom }) {
		return (
			<TextField
				id={id}
				name={name}
				label={label}
				multiline
				rows="5"
				fullWidth
				{...input}
				{...custom}
			/>
		);
	}

	//Handle feedbackform submit
	onFormSave = values => {
		this.props.sendFeedback(values);
		this.handleModalClose();
		this.props.change("feedback", null); //Set the attribute title field to blank
	};

  //Return app bar menus based on whether user is loggin in or not
  returnAppBarMenu = ()=>{
    const { classes } = this.props;
	
    if(this.props.user){
      const { username, avatar } = this.props.user;
      return(
        <React.Fragment>
          {/* <Typography
            variant="h6"
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={modal => this.handleModalOpen("helpModalOpen")}
          >           
            💬
          </Typography> */}
  
          <Avatar
            alt={username}
            src={avatar}
            className={classes.avatar}
            style={{ marginRight: "10px" }}
          />
          <Typography
            variant="button"
            color="inherit"
            noWrap
            style={{ marginRight: "25px", color: "white", fontWeight:"bold" }}
          >
            Signed in as {username}
          </Typography>
        </React.Fragment>
      );
    } else{
      return(
        <Button className={classes.menuButton} style={{ marginRight: "0px" }}>
          <Link to="/login" className={classes.menuLinkSignin}>
            Member Sign In
          </Link>
        </Button>
        
      ); 
    }    
  }

  returnAccountLink = () =>{
    const { classes } = this.props;
    if(this.props.user){
      if(this.props.user.stripeCustomerId){                
        return (
          <List>        
            <ListItem
              button
              key={"account"}
              component={Link}
              to={"/stripebilling"}
              className={classes.menuItem}
              target={"_blank"}	
            >
              <ListItemIcon style={iconStyle}>
                <Icon path={mdiAccountCog} size={1} color={"#33cc99"} />
              </ListItemIcon>
              <ListItemText style={{fontWeight:"bold"}} primary={<Typography className={classes.mainMenuItemText}>ACCOUNT</Typography>} />
            </ListItem>
          </List>
        );
      }
    }
  }
  

	render() {
		const { classes, theme, children } = this.props;		

		return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline />

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.helpModalOpen}
            onClose={this.handleModalClose}
          >
            <div style={this.getModalStyle()} className={classes.modal}>
              <h2 id="simple-modal-title">
                <span
                  role="img"
                  aria-label="suggestion"
                  aria-labelledby="suggestion-lightbulb"
                >
                  📮 
                </span>
                Spotted a mistake or typo? 
              </h2>
              <p id="simple-modal-description">
                <b>Let me know by submitting a comment here.</b>
              </p>
              <p id="simple-modal-description">
                <em>Remeber to submit lecture questions through the Podia Lecture link below.</em>
              </p>
              <form
                id="feedbackForm"
                onSubmit={this.props.handleSubmit(
                  values => {
                    this.onFormSave(values);
                  } //wrapped in arrow function so it won't be called on initial load
                )}
              >
                <Grid container justify="center" spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Field
                      name="feedback"
                      id="feedback"
                      // label="After completing this programme, the student should..."
                      component={this.renderTextArea}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          type="submit"
                          form="feedbackForm"
                          color="primary"
                          className={classes.submitButton}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Modal>

          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: this.state.open
            })}
          >
            <Toolbar disableGutters={!this.state.open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.open
                })}
              >
                <MenuIcon style={{color:"#f13f64"}}/>
              </IconButton>              
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.grow}
                style={{ color: "#33cc99", fontFamily:"'sofia-pro',sans-serif", }}
              >
                {this.props.pageTitle}
              </Typography>              

              {this.returnAppBarMenu()}

              
            </Toolbar>
          </AppBar>

          <Drawer                    
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open
              })
            }}
            open={this.state.open}            
          > 
            <div className={classes.toolbar}>
              <Avatar
                alt={"EngineeringSkills"}
                variant="square"
                src={logoWhite}
                className={classes.logoAvatar}
              />

              <IconButton onClick={this.handleDrawerClose} style={{color:"#f13f64"}}>
                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />
            
            
            {topList(classes)}

            {this.returnAccountLink()}

            {this.props.user?btmList(this.props):btmListPublic(this.props)}                           
          </Drawer>
          
          <main className={classes.content}>          
            <div className={classes.toolbar} />
              <div style={{paddingLeft:"24px", paddingRight:"24px"}}>
                {children}  
              </div>    
              <Footer />                          
          </main>                     
        </div>
        
      </React.Fragment>
		);
	}
}

const formWrapped = reduxForm({
	form: "feedbackForm",
	enableReinitialize: true
})(Layout);

const selector = formValueSelector("feedbackForm"); //Create a selector to extract form values

function mapStateToProps(state) {
	return {
		feedback: selector(state, "feedback"),
		activity: state.activity
	};
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles, { withTheme: true })(formWrapped));

// export default withStyles(styles, { withTheme: true })(Layout);
