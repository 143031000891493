
import React from "react";
import PrivateRoute from "../../../components/utils/PrivateRoute";
import { Route } from "react-router-dom";
import * as Course_19 from './index' //Import all lecture components for course 19

const courseRoutes_19 = (  
  <>
    <Route exact path="/course-19/lecture-1" component={Course_19.Lecture_1} />
    <Route exact path="/course-19/lecture-2" component={Course_19.Lecture_2} />
    <Route exact path="/course-19/lecture-3" component={Course_19.Lecture_3} />
    <Route exact path="/course-19/lecture-4" component={Course_19.Lecture_4} />
    <PrivateRoute exact path="/course-19/lecture-5" component={Course_19.Lecture_5} />
    <PrivateRoute exact path="/course-19/lecture-6" component={Course_19.Lecture_6} />
    <PrivateRoute exact path="/course-19/lecture-7" component={Course_19.Lecture_7} />
    <PrivateRoute exact path="/course-19/lecture-8" component={Course_19.Lecture_8} />
    <PrivateRoute exact path="/course-19/lecture-9" component={Course_19.Lecture_9} />
    <PrivateRoute exact path="/course-19/lecture-10" component={Course_19.Lecture_10} />
    <Route exact path="/course-19/lecture-11" component={Course_19.Lecture_11} />
    <PrivateRoute exact path="/course-19/lecture-12" component={Course_19.Lecture_12} />
    <Route exact path="/course-19/lecture-13" component={Course_19.Lecture_13} />
    <Route exact path="/course-19/lecture-14" component={Course_19.Lecture_14} />
    <PrivateRoute exact path="/course-19/lecture-15" component={Course_19.Lecture_15} />
    <PrivateRoute exact path="/course-19/lecture-16" component={Course_19.Lecture_16} />
    <PrivateRoute exact path="/course-19/lecture-17" component={Course_19.Lecture_17} />
    <PrivateRoute exact path="/course-19/lecture-18" component={Course_19.Lecture_18} />
    <PrivateRoute exact path="/course-19/lecture-19" component={Course_19.Lecture_19} />
    <PrivateRoute exact path="/course-19/lecture-20" component={Course_19.Lecture_20} />
    <PrivateRoute exact path="/course-19/lecture-21" component={Course_19.Lecture_21} />
    <PrivateRoute exact path="/course-19/lecture-22" component={Course_19.Lecture_22} />
    <PrivateRoute exact path="/course-19/lecture-23" component={Course_19.Lecture_23} />
    <PrivateRoute exact path="/course-19/lecture-24" component={Course_19.Lecture_24} />
    <Route exact path="/course-19/lecture-25" component={Course_19.Lecture_25} />
    <PrivateRoute exact path="/course-19/lecture-26" component={Course_19.Lecture_26} />
    <PrivateRoute exact path="/course-19/lecture-27" component={Course_19.Lecture_27} />
    <PrivateRoute exact path="/course-19/lecture-28" component={Course_19.Lecture_28} />
    <PrivateRoute exact path="/course-19/lecture-29" component={Course_19.Lecture_29} />
    <PrivateRoute exact path="/course-19/lecture-30" component={Course_19.Lecture_30} />
    <PrivateRoute exact path="/course-19/lecture-31" component={Course_19.Lecture_31} />
    <Route exact path="/course-19/lecture-32" component={Course_19.Lecture_32} />
    <PrivateRoute exact path="/course-19/lecture-33" component={Course_19.Lecture_33} />
    <PrivateRoute exact path="/course-19/lecture-34" component={Course_19.Lecture_34} />
    <PrivateRoute exact path="/course-19/lecture-35" component={Course_19.Lecture_35} />
    <PrivateRoute exact path="/course-19/lecture-36" component={Course_19.Lecture_36} />
    <PrivateRoute exact path="/course-19/lecture-37" component={Course_19.Lecture_37} />
    <PrivateRoute exact path="/course-19/lecture-38" component={Course_19.Lecture_38} />
    <PrivateRoute exact path="/course-19/lecture-39" component={Course_19.Lecture_39} />
    <PrivateRoute exact path="/course-19/lecture-40" component={Course_19.Lecture_40} />
    <PrivateRoute exact path="/course-19/lecture-41" component={Course_19.Lecture_41} />
  </>  
)
 
export default courseRoutes_19