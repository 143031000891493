import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-15/img1.png";
import img2 from "../../../images/Lectures/C17-15/img2.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
e = \\sqrt{(L_o+u)^2 + v^2}-L_o
\\end{equation}`

const eqn2 = `
\\begin{equation}
\\tag{2}
P=P_o + \\frac{EA}{L_o}e
\\end{equation}`

const eqn3 = `
\\begin{equation}
\\tag{3}
\\delta e = \\frac{\\partial e}{\\partial u}\\delta u + \\frac{\\partial e}{\\partial v} \\delta v
\\end{equation}`

const eqn4 = `
\\begin{equation}
\\tag{4}
\\frac{\\partial e}{\\partial u} =\\frac{L_o+u}{L_o+e}
\\end{equation}`

const eqn5 = `
\\begin{equation}
\\tag{5}
\\frac{\\partial e}{\\partial v} = \\frac{v}{L_o+e}
\\end{equation}`

const eqn6 = `
\\begin{equation}
\\tag{6}
[\\delta e] = \\underbrace{\\begin{bmatrix}
\\frac{L_o+u}{L_o+e} & \\frac{v}{L_o+e}
\\end{bmatrix}}_{[AA]}
\\begin{bmatrix}
\\delta u \\\\
\\delta v
\\end{bmatrix}
\\end{equation}`

const eqn7 = `
\\begin{equation}
\\tag{7}
\\boxed{
[\\delta e] = [AA][\\delta U]
}
\\end{equation}`

const eqn8 = `
\\begin{equation}
\\tag{8}
R = P\\cos \\theta
\\end{equation}`

const eqn9 = `
\\begin{equation}
\\tag{9}
R = P\\:\\frac{L_o+u}{L_o+e}
\\end{equation}`

const eqn10 = `
\\begin{equation}
\\tag{10}
S = P\\sin \\theta
\\end{equation}`

const eqn11 = `
\\begin{equation}
\\tag{11}
S = P\\:\\frac{v}{L_o+e}
\\end{equation}`

const eqn12 = `
\\begin{equation}
\\tag{12}
\\begin{bmatrix}
R\\\\
S
\\end{bmatrix}=
\\begin{bmatrix}
\\frac{L_o+u}{L_o+e} \\\\
\\frac{v}{L_o+e}
\\end{bmatrix}
[P]
\\end{equation}`

const eqn13 = `
\\begin{equation}
\\tag{13}
\\boxed{
[R]=[AA]^T[P]
}
\\end{equation}`




class Lecture_17_15 extends React.Component {
  state={
    course: 17,
    lecture: 15, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    Over the next two lectures, we'll derive the local element stiffness matrix that allows us to capture the influence of large deflections within the stiffness matrix. We'll start by considering the change in length of an element as it undergoes a deformation from <MathJax.Node inline formula={"AB"} /> to <MathJax.Node inline formula={"A'B'"} />, Fig. 1.
                  </Typography>                                          
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Bar element undergoing displacement from <MathJax.Node inline formula={"AB"} /> to <MathJax.Node inline formula={"A'B'"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    What we're interested in is the change in length or elongation of the element as it undergoes this displacement. Straight away, we can contrast this with our previous linear stiffness matrix derivation that simply considered elongation of the element along its <i>undeformed</i> axis.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The global nodal displacements, <MathJax.Node inline formula={"[X] = [x_a, y_a, x_b, y_b]^T"} />, and local displacements, <MathJax.Node inline formula={"[U]=[u, v]^T"} /> are shown in Fig. 1. If the original undeformed length of the bar is <MathJax.Node inline formula={"L_o"} />, then the elongation of the bar is given by the new length, minus the original length,
                  </Typography>

                  <MathJax.Node formula={eqn1} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The axial force associated with this elongation is <MathJax.Node inline formula={"e\\times EA/L_o"} />. If we also assume that the bar has some initial pre-tension force, <MathJax.Node inline formula={"P_o"} />, then the total axial force in the deformed bar is,
                  </Typography>

                  <MathJax.Node formula={eqn2} className={classes.formula}/>

                  <Typography component="h2" className={classes.H2} > Incremental change in <MathJax.Node inline formula={"u"} /> and <MathJax.Node inline formula={"v"} /> </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Next, we'll determine the influence of an incremental change in <MathJax.Node inline formula={"u"} /> and <MathJax.Node inline formula={"v"} />. This will lead us to a matrix that relates an incremental change in <MathJax.Node inline formula={"u"} /> and <MathJax.Node inline formula={"v"} />, <MathJax.Node inline formula={"\\delta u"} /> and <MathJax.Node inline formula={"\\delta v"} /> respectively, with an incremental change in elongation, <MathJax.Node inline formula={"\\delta e"} />. This relationship will prove helpful a little later in the derivation.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Our expression for <MathJax.Node inline formula={"e"} />, Eqn. 1, is a function of <MathJax.Node inline formula={"u"} /> and <MathJax.Node inline formula={"v"} />. So using the mathematics of incremental change, we can obtain the incremental change in <MathJax.Node inline formula={"e"} /> due to simultaneous incremental changes in <MathJax.Node inline formula={"u"} /> and <MathJax.Node inline formula={"v"} /> with the following expression,
                  </Typography>

                  <MathJax.Node formula={eqn3} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Evaluating the partial derivatives,
                  </Typography>

                  <MathJax.Node formula={eqn4} className={classes.formula}/>

                  <MathJax.Node formula={eqn5} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Therefore we can represent Eqn. 3 in matrix form as, 
                  </Typography>

                  <MathJax.Node formula={eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    In condensed format, we could write,
                  </Typography>      
                  
                  <MathJax.Node formula={eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We will leave this expression to one side for now and return to it a little later.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Relating axial force <MathJax.Node inline formula={"P"} /> with force components <MathJax.Node inline formula={"R"} /> and <MathJax.Node inline formula={"S"} /> </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Consider the axial force <MathJax.Node inline formula={"P"} /> in the deformed element and the orthogonal force components <MathJax.Node inline formula={"R"} /> and <MathJax.Node inline formula={"S"} /> aligned with the undeformed element’s local axes, Fig. 2.
                  </Typography>                          
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Axial force and force components in the deformed element.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    Using basic geometry, we can state that,
                  </Typography>

                  <MathJax.Node formula={eqn8} className={classes.formula}/>

                  <MathJax.Node formula={eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Similarly, 
                  </Typography>

                  <MathJax.Node formula={eqn10} className={classes.formula}/>

                  <MathJax.Node formula={eqn11} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Combining equations 9 and 11 in matrix form, 
                  </Typography>

                  <MathJax.Node formula={eqn12} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Notice the reoccurrence of the vector <MathJax.Node inline formula={"[AA]"} />; we can write a condensed version of Eqn. 12, 
                  </Typography>

                  <MathJax.Node formula={eqn13} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Again, just like Eqn. 7, this relationship between <MathJax.Node inline formula={"R"} />, <MathJax.Node inline formula={"S"} /> and <MathJax.Node inline formula={"P"} /> will come in handy during the next phase of our derivation in the next lecture.
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Let's recap what we've achieved in this lecture:
                  </Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}> We've identified the elongation, <MathJax.Node inline formula={"e"} />, measured along the deformed axis of the element. This is fundamentally different to the derivation in the previous lecture that considered elongation along an undeformed axis only. </Typography></li>
                    
                    <li><Typography paragraph className={classes.bodytext}>We identified the axial force associated with this elongation and also baked in the ability to represent an initial pre-tension force.</Typography></li>
                    
                    <li><Typography paragraph className={classes.bodytext}>
                    We identified the influence of an incremental change in deformation; in other words, what influence does an incremental change in <MathJax.Node inline formula={"u"} /> and <MathJax.Node inline formula={"v"} /> have on element elongation <MathJax.Node inline formula={"e"} />.</Typography></li>
                    
                    <li><Typography paragraph className={classes.bodytext}>We related the axial force along the deformed axis, <MathJax.Node inline formula={"P"} />, with its orthogonal components aligned with the undeformed axis, <MathJax.Node inline formula={"R"} /> and <MathJax.Node inline formula={"S"} />. </Typography></li>
                  </ol>

                  <Typography paragraph className={classes.bodytext}>
                    Points 3 and 4 resulted in two equations, 7 and 13, that, although appearing somewhat unimportant now, will plug into various parts of the derivation to come in the next lecture. 
                  </Typography>                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_15));
