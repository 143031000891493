import axios from "axios";
import { FETCH_FLASH, FETCH_ERRORS, FETCH_CUSTOMER_DATA, FETCH_2DTRUSS, DELETE_2DTRUSS } from "./types";




// ========================================================
export const submitTruss2D = values => async dispatch => {
	const res = await axios.post("/api/truss2d/submitjob", values);

	//Destructure returned data
	const { flash, errors } = res.data;

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}
};

// ========================================================
export const fetchResultsTruss2D = jobId => async dispatch => {
	const res = await axios.get(`/api/truss2d/fetch/${jobId}`);

  //Destructure returned data
	const {data, flash, errors } = res.data;

  //Dispatch results data
  if(data){
    dispatch({ type: FETCH_2DTRUSS, payload: data });
  }

  // Dispatch flash messaging
	if (flash) {    
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings    
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}
};

// ========================================================
export const reportFailedAnalysisTruss2D = jobId => async dispatch => {  
  await axios.get(`/api/truss2d/failed/${jobId}`);
};

// ========================================================
export const clearResultsTruss2D = () => {
  return {
    type: DELETE_2DTRUSS,
    payload: null
  };
};
