import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-4/img1.png";
import img2 from "../../../images/Lectures/C17-4/img2.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
y(x) = f(L,H,w_s)
\\end{equation}`

const eqnA = `
\\begin{equation}
\\sum F_y=0\\:\\:(\\uparrow +)
\\end{equation}`

const eqn2 = `
\\begin{equation}
\\tag{2}
V+\\delta V-w(x)\\delta x-V=0
\\end{equation}`

const eqn3 = `
\\begin{equation}
\\tag{3}
\\frac{\\delta V}{\\delta x} = w(x)
\\end{equation}`

const eqn4 = `
\\begin{equation}
\\tag{4}
\\frac{\\mathrm{d}V}{\\mathrm{d}x} = w(x)
\\end{equation}`

const eqn5 = `
\\begin{equation}
\\tag{5}
\\tan \\theta = \\frac{V}{H} = -\\frac{\\mathrm{d}y}{\\mathrm{d}x}
\\end{equation}`

const eqn6 = `
\\begin{equation}
\\tag{6}
V = -H\\frac{\\mathrm{d}y}{\\mathrm{d}x}
\\end{equation}`

const eqn7 = `
\\begin{equation}
\\tag{7}
\\frac{\\mathrm{d}V}{\\mathrm{d}x} = -H\\frac{\\mathrm{d}^2y}{\\mathrm{d}x^2}
\\end{equation}`

const eqn8 = `
\\begin{equation}
\\tag{8}
\\boxed{
\\frac{\\mathrm{d}^2y}{\\mathrm{d}x^2} = -\\frac{w(x)}{H}
}
\\end{equation}`

const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_4 extends React.Component {
  state={
    course: 17,
    lecture: 4, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                

                  <Typography paragraph className={classes.bodytext}>
                  In this lecture, we're going to derive a differential equation that describes the catenary shape of a  cable, hanging under its own weight. Our derivation is going to rely on a couple of important assumptions:
                  </Typography>

                  <ol>
                    <li>
                      <Typography paragraph className={classes.bodytext}>
                        we will ignore any elongation of the cable
                      </Typography>
                    </li>

                    <li>
                      <Typography paragraph className={classes.bodytext}>
                        we will assume rigid supports
                      </Typography>
                    </li>
                  </ol>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Both of these assumptions allow us to neglect any change in the shape of the hanging cable. The fact that we're assuming no change in the geometry of the cable under load, means we can assume linear behaviour and derive a closed-form solution. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Consider the cable below, hanging under its own weight, Fig. 1. Note that the self-weight, <MathJax.Node inline formula={"w_s"} /> is the self-weight per unit length along the curved length of the cable.
                  </Typography>
                      
                </Grid> 

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Cable hanging under its own weight.</figcaption>
                </figure>   

                <Grid item xs={12} sm={12} md={10} > 

                  <Typography paragraph className={classes.bodytext}>
                    We can say that the vertical deflection of the cable will be some function of the cable span, <MathJax.Node inline formula={"L"} />, self-weight, <MathJax.Node inline formula={"w_s"} /> and horizontal reaction, <MathJax.Node inline formula={"H"} />,  
                  </Typography>   

                  <MathJax.Node formula={eqn1} className={classes.formula}/>  

                  <Typography paragraph className={classes.bodytext}>Our task over the next couple of lectures is to determine this function, <MathJax.Node inline formula={"f(L,H,w_s)"} />. The process will involve 3 steps:</Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}>
                      we start by deriving the governing differential equation for the deflected shape.
                      </Typography></li>  
                    
                    <li><Typography paragraph className={classes.bodytext}>
                      next, we'll modify this equation to account for the self-weight along the curved length and then integrate the equation.
                      </Typography></li>  
                    
                    <li><Typography paragraph className={classes.bodytext}>
                      finally, we'll use boundary conditions to solve for the unknown constants of integration.
                      </Typography></li>  
                  </ol>        

                  <Typography component="h2" className={classes.H2} > The governing differential equation </Typography>     
                  
                  <Typography paragraph className={classes.bodytext}>
                    The usual approach to deriving a governing differential equation is to start by considering equilibrium of an infinitesimally small part of the structure. So in this case, consider the infinitesimal cable segment, with projected length, <MathJax.Node inline formula={"\\delta x"} />, Fig. 2.
                  </Typography>                                    

                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Infinitesimal length of cable.</figcaption>
                </figure>  

                <Grid item xs={12} sm={12} md={10} >

                  <Typography paragraph className={classes.bodytext}>
                    Note here that we're now specifying the distributed self-weight load per unit projected length, <MathJax.Node inline formula={"w(x)"} />. The projected length, <MathJax.Node inline formula={"\\delta x"} />, is the curved length, projected onto a horizontal plane. As this length is infinitesimally small, we'll assume <MathJax.Node inline formula={"w(x)"} /> is constant.   
                  </Typography>                  
                  
                  <Typography paragraph className={classes.bodytext}>
                    The other thing we note about the cable segment in Fig. 2 is that since the cable segment is in equilibrium, the horizontal component of the cable tension force is constant, <MathJax.Node inline formula={"H"} />.   
                  </Typography>                  
                  
                  <Typography paragraph className={classes.bodytext}>
                    Next, we can evaluate the sum of the forces in the vertical direction,
                  </Typography>

                  <MathJax.Node formula={eqnA} className={classes.formula}/>

                  <MathJax.Node formula={eqn2} className={classes.formula}/>

                  <MathJax.Node formula={eqn3} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    As <MathJax.Node inline formula={"\\delta x \\rightarrow 0"} />, we have,                    
                  </Typography>

                  <MathJax.Node formula={eqn4} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    From Fig. 2 above, we can say that,  
                  </Typography>  

                  <MathJax.Node formula={eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore,
                  </Typography>

                  <MathJax.Node formula={eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now differentiating both sides with respect to <MathJax.Node inline formula={"x"} /> yields,  
                  </Typography> 

                  <MathJax.Node formula={eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, we can substitute Eqn. 4 into Eqn. 7,
                  </Typography>

                  <MathJax.Node formula={eqn8} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    to obtain the governing differential equation for the deflected shape of the cable. It's important to recognise that this equation is in terms of <MathJax.Node inline formula={"w(x)"} />, the load per unit projected length. We want an equation in terms of <MathJax.Node inline formula={"w_s"} />, the load per unit curved length (of cable). We'll tackle this in the next lecture.  
                  </Typography>                  

                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_4));
