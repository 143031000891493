import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-53/img1.png";
import img2 from "../../../images/Lectures/C17-53/img2.png";
import img3 from "../../../images/Lectures/C17-53/img3.png";

const codeString1 = 
`#Constants
E = 70*10**9 #(N/m^2)
swt = True #Take self-weight into account 

areaCable = 0.00002 #🚨 (m^2) 
areaBar = 0.002 # (m^2)
gammaCable = 20 #🚨 (kg/m) Cable mass per unit length
gammaBar = 50 #🚨 (kg/m) Bar mass per unit length

preTension = 10 #🚨 (N) Pre-tension applied to all cable elements
P = -1000 #🚨 (N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

nForceIncrements = 6000 #🚨 Initial number of force increments for applied loading
convThreshold = 30 #🚨(N) Threshold on maximum value of F_inequilibrium
itLimit = 10000 #Maximum no. iterations allowed to achieve convergence`

const codeString2=
`gammaCable = 100 #(kg/m) Cable mass per unit length
gammaBar = 1 #(kg/m) Bar mass per unit length`


class Lecture_17_53 extends React.Component {
  state={
    course: 17,
    lecture: 53, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll analyse the structure we modelled in Blender in the previous section. This will test the edits we made in the previous lecture, as we'll be importing some cable definitions this time. So, make sure to take all of the csv files we generated in section 8 and put them in the data folder so they can be imported. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We don't need to make any edits to our code in this lecture other than updating some simulation parameters, see below. Note that we've now given cable and bar elements different cross-sectional areas and self-weights. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    The parameter values above are somewhat arbitrary as we're not trying to match a specific structure. The values of <code className={classes.code}>nForceIncrements</code> and <code className={classes.code}>convThreshold</code> in particular have been determined based on trial and error. This is a typical approach when analysing a new structure with this code. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can see from the pre-analysis plot that our cables have been correctly identified, Fig 1.
                  </Typography>                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Pre-analysis plot.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The code has no issue converging for this set of parameters and concludes with a maximum residual force imbalance of only <MathJax.Node inline formula={"11\\:N"} />. The structure’s response is best summarised with the main results plot, Fig 2. As expected for this configuration, all cable elements are in tension, while the truss deck has a mix of tension and compression elements. It's also interesting to see that the main suspension cable has adopted a faceted shape due to the point loads induced by the hanger cables. It is the relatively large deflection of the main suspension cable that justifies our use of a non-linear solution for this structure.
                  </Typography>                                              
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Results plot.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >   

                  <Typography component="h2" className={classes.H2} > Compression in cable elements </Typography>               

                  <Typography paragraph className={classes.bodytext}>
                    One final issue we still need to address with our solver is what to do when compression forces develop in cable elements. As we know, a cable element is not capable of resisting compression forces. But our solver doesn't enforce this behaviour yet. To demonstrate the problem, we'll artificially induce compression in some stay cables by changing the bar and cable element self-weights. All other parameters can remain the same.
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Running the analysis again we can see that the stay cables between nodes <MathJax.Node inline formula={"6"} /> and <MathJax.Node inline formula={"16"} /> and nodes <MathJax.Node inline formula={"24"} /> and <MathJax.Node inline formula={"36"} /> are barely visible. If we now inspect the detailed text output (use Ctrl + F to find the relevant output statement), sure enough, we see that these elements both develop compression forces of <MathJax.Node inline formula={"90\\:N"} />. While this is not a very significant force, we would still rather that it didn't develop and the force distribution was updated to reflect this. We'll write a fix for this in the next lecture.
                  </Typography>

                  

                              
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Results plot with compression developing in cable elements.</figcaption>
                </figure>                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_53));
