import React, { useRef, useState } from 'react';
import * as THREE from 'three'
import {round} from 'mathjs'
import {  Html, useGLTF, Instances, Instance } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { LineDashedMaterial } from 'three';

function showNodeNumbers(n, show){
  if(show){
    return(
      <Html 
        zIndexRange={[100, 0]}        
      >
        <div style={{color:'#33cc99', fontWeight:'bold', fontSize:'1.0em', padding:'3px'}}>
          {n}
        </div>
      </Html>
    );
  } else{
    return null
  }
  
}

//Node component definition
export function Node({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/node.gltf')  
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0, 0.1]}>
        <mesh geometry={nodes.Circle001.geometry} material={materials.Green} />
        <mesh 
          geometry={nodes.Circle001_1.geometry} 
          material={materials.Red} 
          castShadow={false}          
        >
          {showNodeNumbers(props.number, props.showNodeNumbers)}
        </mesh>
      </group>
    </group>
  )
}

//Element component definition
export function Element({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/element.gltf')  

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.element.geometry} material={materials.Red} castShadow={false} />
    </group>
  )
}

//Pin component definition
export function Pin({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/pin.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.pin.geometry} material={materials.Green} position={[0, 0, 0.1]} castShadow={false}/>
    </group>
  )
}

//Roller component definition
export function Roller({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/roller.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.roller.geometry} material={materials.Green} position={[0, 0, 0.1]} castShadow={false}/>
    </group>
  )
}

//Applied force component definition
export function Force({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/force_2.gltf')
  return (
    <group ref={group} {...props} dispose={null} >
      <mesh geometry={nodes.force_head.geometry} material={nodes.force_head.material} />
      <mesh geometry={nodes.force_shaft.geometry} material={nodes.force_shaft.material} position={[0, 6.58, 0]}>
        <Html 
          center={true} 
          zIndexRange={[100, 0]}                           
        >
          <div style={{color:'#2E78CC', fontWeight:'bold', fontSize:'1.1em', width:'100px', textAlign:'center', transform: props.offset=='above'? 'translate3d(0%, -20%, 0)' : 'translate3d(0%, 90%, 0)'}}>
            <p>{`${Math.abs(props.value).toLocaleString('en')} N`}</p>
          </div>
        </Html>
      </mesh>
    </group>
  )
}

//Reaction force component definition
export function ReactionForce({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/reaction.gltf')
  return (
    <group ref={group} {...props} dispose={null} >
      <mesh geometry={nodes.reaction_head.geometry} material={nodes.reaction_head.material} />
      <mesh geometry={nodes.reaction_shaft.geometry} material={nodes.reaction_shaft.material} position={[0, 6.58, 0]}>
        <Html 
          center={true} 
          zIndexRange={[100, 0]}                           
        >
          <div style={{color:'#33cc99', fontWeight:'bold', fontSize:'1.1em', width:'100px', textAlign:'center', transform: props.offset=='above'? 'translate3d(0%, -20%, 0)' : 'translate3d(0%, 90%, 0)'}}>
            <p>{`${Math.abs(round(props.value,1)).toLocaleString('en')} N`}</p>
          </div>
        </Html>
      </mesh>
    </group>
  )
}

//Wireframe sphere component definition
export function WireSphere(props){
  return(
    <>
      <mesh {...props} >      
        <sphereBufferGeometry attach="geometry" args={[.2,8,8]}/>
        <meshPhongMaterial         
          flatShading={true}
          roughness={1}
          metalness = {0.5}
          shininess = {100}
          attach="material" 
          color={props.color}
        />
        {showNodeNumbers(props.number, props.showNodeNumbers)}
      </mesh>
      
      <DefLabel
        {...props}        
      />
    </>
  );
}

//Return Deflection label
function DefLabel({...props}){  
  if(props.showDefAnnotations){
    return(
      <mesh position={props.position}>
        <Html 
          zIndexRange={[100, 0]}                         
          center={true}
          style={{
            background: '#00000077',
            border: '1px solid #33cc9977',
            color: '#33cc99',            
            width:'100px',
            borderRadius: '5px',
          }}
        >
          <div style={{color:'#33cc99', fontWeight:'bold', fontSize:'0.9em', padding:'3px'}}>
            dx: {roundOff(props.dx,6)} m <br/>
            dy: {roundOff(props.dy,6)} m
          </div>
        </Html>
      </mesh>
    );
  } else {
    return null
  }
}

//Element rendered as instance meshes with color based on axial force magnitude
//Ref: https://codesandbox.io/s/floating-instanced-shoes-h8o2d?file=/src/App.js:1297-1315
export function ElementsAxialForces(props){  
  //This can call useGLTF because ElementsTest is a function component rather than just a function
  const { nodes, materials } = useGLTF('/element_blank.gltf')
  useGLTF.preload('/element_blank.gltf') 
    
  //Return each member as an instanced object
  return(
    <Instances geometry={nodes.element_blank.geometry} material={materials.White}>
      {props.mbrs.map((m,i) => ( 
        <React.Fragment key={i}> 
          <Instance           
            key={i}
            rotation={[0,0,m.theta]} 
            position={[m.xi-props.dx,m.yi-props.dy,0]} 
            scale={[m.length, props.scaleFactor, props.scaleFactor]} 
            color={m.color}
          />                
          <ForceLabel                              
            position={[m.xCentre-props.dx,m.yCentre-props.dy,0]} 
            m={m} 
            i={i} 
            showAxialAnnotations={props.showAxialAnnotations}
          />                      
        </React.Fragment>   
      ))}     
    </Instances>
  );
}

//Return the axial force label
function ForceLabel({ ...props }){    
  if(props.showAxialAnnotations){
    return(
      <mesh position={props.position}>
        <Html 
          zIndexRange={[100, 0]}                         
          center={true}
          style={{
            background: '#00000077',
            border: '1px solid #33cc9977',
            color: '#33cc99',
            borderRadius: '5px',
            width:'70px',
            textAlign:'center'
          }}
        >
          <div style={{color:'#33cc99', fontWeight:'bold', fontSize:'0.9em', padding:'3px'}}>
            {`${roundOff(props.m.memberForce,0).toLocaleString('en')} N`}
          </div>
        </Html>
      </mesh>
    );
  } else {
    return null
  }
}

//Node component definition (Node without color)
export function WhiteNode({ ...props }) {  
  const group = useRef()
  const { nodes, materials } = useGLTF('/node_blank.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.node_blank.geometry} material={materials.White} position={[0, 0, 0.1]}>
        {showNodeNumbers(props.number, props.showNodeNumbers)}        
      </mesh> 
    </group>
  )  
}

//Round numbers to set decimal places
const roundOff = (num, places) => {
  const x = Math.pow(10,places);
  return Math.round(num * x) / x;
}