import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../../../actions";
import Grid from "@material-ui/core/Grid";
import Feedback from "../../../components/utils/Feedback";
import LayoutPublic from "../../../components/common/LayoutPublic";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";
import Particles from "react-particles-js";
import SpinningLogo from "../../../components/3D/SpinningLogo"

const colorRed = red[500];

function Copyright() {
	return (
		<div style={{ padding: "10px" }}>
			<Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
				Copyright &copy; {new Date().getFullYear()} Mind Map Media Ltd. All rights
				reserved.
			</Typography>
		</div>
	);
}

//Config for particles.js
const particleConfig = {
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
				value_area: 800
			}
		},
		color: {
			value: "#33cc99"
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000"
			},
			polygon: {
				nb_sides: 5
			},
			image: {
				src: "img/github.svg",
				width: 100,
				height: 100
			}
		},
		opacity: {
			value: 0.06313181133058181,
			random: false,
			anim: {
				enable: false,
				speed: 0.5,
				opacity_min: 0.1,
				sync: false
			}
		},
		size: {
			value: 3,
			random: true,
			anim: {
				enable: false,
				speed: 40,
				size_min: 0.1,
				sync: false
			}
		},
		line_linked: {
			enable: true,
			distance: 150,
			color: "#33cc99",
			opacity: 0.11048066982851817,
			width: 1
		},
		move: {
			enable: true,
			speed: 2,
			direction: "none",
			random: false,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200
			}
		}
	},
	interactivity: {
		detect_on: "canvas",
		events: {
			onhover: {
				enable: false,
				mode: "repulse"
			},
			onclick: {
				enable: false,
				mode: "push"
			},
			resize: true
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1
				}
			},
			bubble: {
				distance: 400,
				size: 40,
				duration: 2,
				opacity: 8,
				speed: 3
			},
			repulse: {
				distance: 200,
				duration: 0.4
			},
			push: {
				particles_nb: 4
			},
			remove: {
				particles_nb: 2
			}
		}
	},
	retina_detect: true
};

//Custom styling
const styles = theme => ({
	particleBackground: {
    background: '#1a1a1a',
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "fixed !important",
		opacity: "100%",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -2
  },
  outsidePaper:{
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8), 
  },
	paper: {       
    background: '#25252550',    
		marginTop: theme.spacing(),
		display: "flex",
		flexDirection: "column",
    alignItems: "center",
    color:'#33cc99',
    borderRadius:'25px',    
	},
	avatar: {
		margin: '50px',
		width: '25%',
		height: '25%'
  }
});

class ResetPassword extends React.Component {
	componentDidMount() {
		//Reset data input errors for login form
		this.props.fetchUser();
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		this.props.clearFlash();
	}

	//Send login request
	onSubmit = values => {
		this.props.resetPasswordRequest(values);
		this.props.change("email", null); //Set the attribute title field to blank
	};

	//Required when combining Matrial UI <TextField /> with redux-form
	renderTextField({ errors, id, name, input, label, ...custom }) {
		let filteredErrors = [];
		if (typeof errors !== "undefined") {
			if (errors.length > 0) {
				//Identify the relevant errors
				filteredErrors = errors.filter(err => {
					return err.param === id;
				});
			}
		}

		if (typeof filteredErrors !== "undefined") {
			if (filteredErrors.length > 0) {
				for (let i = 0; i < filteredErrors.length; i++) {
					if (filteredErrors[i].param === id) {
						return (
							<TextField
                style={{
                  backgroundColor: "#13131350"
                }}
                InputProps={{
                    style: {
                        color: "#33cc99"
                    }
                }}  
                InputLabelProps={{
                  style:{
                    color:'red'
                  }
                }}   
                color="secondary"    
								error
								label={filteredErrors[i].msg}
								id="outlined-error"
								variant="outlined"
								fullWidth
								// autoFocus
								margin="normal"
								{...input}
								{...custom}
							/>
						);
					}
				}
			}
		}
		return (
			<TextField
        style={{
          backgroundColor: "#13131350",                     
        }}
        InputProps={{
            style: {
                color: "#33cc99",                          
            }
        }}
        InputLabelProps={{
          style:{
            color:'#33cc99'
          }
        }}
        color="secondary"
				required
				id={id}
				name={name}
				label={label}
				variant="outlined"
				fullWidth
				// autoFocus
				margin="normal"
				{...input}
				{...custom}
			/>
		);
	}

	// Return error message if calidation error returned
	returnErrorText(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return (
						<Typography variant="body2" style={{ color: colorRed }}>
							{errors[i].msg}
						</Typography>
					);
				}
			}
		}
		return;
	}

	render() {
		const { classes } = this.props; //Destructure data off the components props
		return (
			<React.Fragment>
				<LayoutPublic />
				<Particles className={classes.particleBackground} params={particleConfig} />
				<Feedback />
						      
        <Grid container justify="center" spacing={4}>
					<Grid item xs={10} sm={10} md={4} lg={4}>
						<CssBaseline />

            <div className={classes.outsidePaper}>
						<Paper className={classes.paper} elevation={2}>	

                <div >
                  <SpinningLogo/>
                </div>						
								               
								<Typography component="h1" variant="h5">
									RESET YOUR PASSWORD
								</Typography>

                <form   
                  style={{marginTop:"50px"}}                                             
									noValidate
									onSubmit={this.props.handleSubmit(
										values => this.onSubmit(values) //wrapped in arrow function so it won't be called on initial load
									)}
								>
									<Grid container justify="center">
										<Grid item xs={11} sm={11}>
											<Field                        
												name="email"
												id="email"
												label="Email"
                        errors={this.props.errors}                        
												component={this.renderTextField}
											/>
																			                    
											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "50px" }}>
													<Button
														size="large"
														type="submit"
														fullWidth
														variant="contained"
														color="secondary"
														className={classes.submit}
														style={{ color: "white", backgroundColor:"#f13f64" }}
													>
														Submit
													</Button>
												</Grid>
											</Grid>
											<Grid container style={{ marginBottom: "50px" }}>
												{/* <Grid item xs>
													<Link to="/resetpassword">
														<Typography variant="body2" style={{color:"#8e8e8e"}}>
															Forgot password?
														</Typography>
													</Link>
												</Grid>
                      <Grid item>
											<Link to="/register">
												<Typography variant="body2" style={{color:"#8e8e8e"}}>
													Don't have an account? Sign Up
												</Typography>
											</Link>
										  </Grid> */}
											</Grid>
										</Grid>
									</Grid>
                  <Copyright />
								</form>														
						</Paper>
            </div>
					</Grid>
				</Grid>
      
      </React.Fragment>
		);
	}
}

//Create a formWrapped version of Login component
const formWrapped = reduxForm({
	form: "resetForm"
})(ResetPassword);

const selector = formValueSelector("resetForm"); //Create a selector to extract form values

function mapStateToProps(state) {
	return {
		errors: state.errors,
		email: selector(state, "email")
	};
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(formWrapped));
