import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-26/img1.png";
import img2 from "../../../images/Lectures/C19-26/img2.png";


const Eqn1 = `\\begin{equation}
\\tag{1}
F_{Rd} = \\frac{f_{ck}}{1.5}\\times b_w\\:z\\:\\cos\\theta
\\end{equation} `

const Eqn2 = `\\begin{equation}
\\tag{2}
V_{Rd,max} = \\frac{f_{ck}}{1.5}\\times b_w\\:z\\:\\cos\\theta\\times \\sin\\theta
\\end{equation}`

const Eqn3 = `\\begin{equation}
\\tag{3}
\\cos\\theta\\:\\sin\\theta = \\frac{1}{\\cot\\theta+\\tan\\theta}
\\end{equation}`

const Eqn4 = `\\begin{equation}
\\tag{4}
V_{Rd,max} = \\frac{f_{ck}\\:b_wz}{1.5(\\cot\\theta+\\tan\\theta)}
\\end{equation}`

const Eqn5 = `\\begin{equation}
\\tag{5}
V_{Rd,max} = \\frac{0.36\\:b_wd\\left(1-\\frac{f_{ck}}{250}\\right)}{\\cot\\theta+\\tan\\theta}
\\end{equation} `

const Eqn6 = `\\begin{equation}
\\tag{6}
\\boxed{V_{Rd,max} = 0.18\\:b_wd\\left(1-\\frac{f_{ck}}{250}\\right)f_{ck}\\:\\sin 2\\theta}
\\end{equation}`

const Eqn7 = `\\begin{equation}
\\tag{7}
\\boxed{\\theta = 0.5\\:\\sin^{-1}\\left[\\frac{V_{Ed}}{0.18\\:b_wd\\left(1-\\frac{f_{ck}}{250}\\right)f_{ck}}\\right]}
\\end{equation} `

const Eqn8 = `\\begin{equation}
\\tag{8}
V_{Rd,s} = V_{Ed}\\times \\frac{s}{z\\:\\cot\\theta}
\\end{equation}`

const Eqn9 = `\\begin{equation}
\\tag{9}
\\boxed{\\frac{A_{sw}}{s} = \\frac{V_{Ed}}{0.87 f_{yk}\\:z\\:\\cot\\theta}}
\\end{equation} `

const Eqn10 = `\\begin{equation}
\\tag{10}
\\boxed{\\frac{A_{sw,min}}{s} =\\frac{0.08\\sqrt{f_{ck}}\\:b_w}{f_{yk}}}
\\end{equation}`

const Eqn11 = `\\begin{equation}
\\Delta F_{td} = 0.5V_{Ed}\\:\\cot\\theta
\\end{equation} `


class Lecture_19_27 extends React.Component {
  state={
    course: 19,
    lecture: 27, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In cases where the concrete alone is not sufficient to withstand the applied shear force, <MathJax.Node inline formula={"V_{Rd,c}<V_{Ed}"} />, we must derive a method for determining the required area of shear reinforcement, <MathJax.Node inline formula={"A_{sw}"} /> within a single plane of links and also the spacing of these planes, longitudinally along the beam. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Eurocode 2 describes a model of shear resistance based on truss behaviour. This is known as the truss method or <i>variable strut inclination method</i>. To understand the design procedure that follows, we must first understand the truss analogy for concrete beam shear resistance, Fig. 1.
                  </Typography> 
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Truss model for shear resistance of a reinforced concrete beam.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    According to this model, the shear resistance of a reinforced concrete beam is analogous to a truss in which the longitudinal tensile reinforcement acts as the bottom chord resisting tension, the vertical links behave as internal tension members, and the diagonal compression force is resisted by the concrete acting as an inclined strut. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In design, our task is first to determine the angle of the compression strut, <MathJax.Node inline formula={"\\theta"} />, then to ensure it is capable of withstanding the compressive force generated by the external forces. Then, we determine a suitable area of vertical tensile (shear) reinforcement to resist the vertical tension force developed. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that this model of shear resistance assumes no contribution from the concrete (<MathJax.Node inline formula={"V_{Rd,c}"} />) towards resisting the shear force, i.e. all shear resistance is provided by links. Finally, we must account for the additional tension force developed in the bottom tension reinforcement arrising from the truss idealisation. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 1.0 The concrete compression strut </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    With reference to Fig 1, the cross-sectional area of the compression strut is <MathJax.Node inline formula={"b_w\\:z\\:\\cos\\theta"} />. In this case, the design compressive stress of the concrete is <MathJax.Node inline formula={"f_{cd} = f_{ck}/1.5"} />. Note that we're dividing by the material partial factor for concrete <MathJax.Node inline formula={"(\\gamma_c=1.5)"} /> only and not also multiplying by the empirical factor, <MathJax.Node inline formula={"0.85"} />, that we saw previously when considering bending design. So, the ultimate capacity of the inclined compression strut is:
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The vertical component of this force is denoted as <MathJax.Node inline formula={"V_{Rd,max}"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Utilising the trigonometric identity:
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    we obtain,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    To allow for concrete strength reduction due to shear cracking, <MathJax.Node inline formula={"V_{Rd,max}"} /> is reduced by a non-dimensional strength reduction factor, <MathJax.Node inline formula={"\\nu_1 = 0.6(1-f_{ck}/250)"} />. If we also make the substitution <MathJax.Node inline formula={"z=0.9d"} /> (see Fig. 1), we obtain:
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since <MathJax.Node inline formula={"(\\cot \\theta+\\tan\\theta)^{-1} = 0.5\\:\\sin 2\\theta"} />, Eqn. 5 can be simplified to:
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Remember, this is the maximum magnitude of <b>vertical</b> force that can be accommodated before the <b>inclined</b> compression strut fails. Note that <MathJax.Node inline formula={"V_{Rd,max}"} /> is a function of the compression strut angle, <MathJax.Node inline formula={"\\theta"} />. To determine the actual angle of the compression strut, we make the statement that <MathJax.Node inline formula={"V_{Ed} = V_{Rd,max}"} /> at the ultimate limit state. We can therefore solve for <MathJax.Node inline formula={"\\theta"} />:
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now, EC2 imposes an upper and lower limit on allowable <MathJax.Node inline formula={"\\theta"} /> values; <MathJax.Node inline formula={"\\theta_{max} = 45^{\\circ}"} /> and <MathJax.Node inline formula={"\\theta_{min} = 22^{\\circ}"} />. These can be considered boundary cases, thus, if <MathJax.Node inline formula={"\\theta"} /> evaluates to a value <MathJax.Node inline formula={"<22^{\\circ}"} />, then the minimum value of <MathJax.Node inline formula={"22^{\\circ}"} /> should be adopted in further calculations. However, if a value of <MathJax.Node inline formula={"\\theta>45^{\\circ}"} /> is obtained, this indicates that the compression strut is over-stressed and either the beam's dimensions and/or the concrete strength should be increased.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Values of <MathJax.Node inline formula={"V_{Rd,max}"} /> can be readily tabulated for both boundary angles as a function of concrete strength. Figure 2 contains such a table in terms of shear stress rather than shear force. Note that some texts present this discussion in terms of force (as above) while some use stress.
                  </Typography>                 
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Minimum and maximum concrete strut capacity in terms of stress.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    To summarise our progress thus far; we can determine the minimum and maximum values of the vertical component of the inclined compression strut force, either by evaluating Eqn. 6 with <MathJax.Node inline formula={"\\theta"} /> equal to <MathJax.Node inline formula={"22^{\\circ}"} /> and <MathJax.Node inline formula={"45^{\\circ}"} /> respectively, or, by determining the appropriate shear stress from Fig. 2 and determining the corresponding force. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the design shear force at the face of the support, <MathJax.Node inline formula={"V_{Ef}"} />, is less than the vertical component of the minimum strut resistance, we adopt the minimum strut resistance and proceed. However, if <MathJax.Node inline formula={"V_{Ef}"} /> is greater than the vertical component of the maximum strut resistance, we must increase the concrete strength and or beam dimensions. If <MathJax.Node inline formula={"V_{Rd,max\\: \\theta = 22^{\\circ}}<V_{Ed}<V_{Rd,max\\: \\theta = 45^{\\circ}}"} />, then the angle of the strut is determined using Eqn. 7 before proceeding.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Vertical shear links </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now that we've confirmed that the diagonal compression strut will not crush and we have determined the strut angle, we can move on to determine the area of steel required to resist the design shear force <MathJax.Node inline formula={"V_{Ed}"} />. Note that the shear force at a distance <MathJax.Node inline formula={"d"} /> <b>from the face of the support</b> can be used to calculate the required area of shear reinforcement.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider the compression strut and shear link arrangement illustrated in Fig. 1 above.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Noting that the inclined strut spans a horizontal distance <MathJax.Node inline formula={"z\\:cot\\theta"} /> , the vertical force that must be resisted by an individual plane of links is reduced proportionally by a factor of <MathJax.Node inline formula={"s/z\\:cot\\theta"} />, where <MathJax.Node inline formula={"s"} /> is the longitudinal spacing of link planes. Considering vertical force equilibrium, we can state that the design shear resistance of a plane of shear links, <MathJax.Node inline formula={"V_{Rd,s}"} /> is:
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If we substitute <MathJax.Node inline formula={"V_{Rd,s} = 0.87f_{yk}A_{sw}"} /> where <MathJax.Node inline formula={"A_{sw}"} /> is the total cross-sectional area of steel in a single plane of shear links, we obtain:
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Equation 9 can be used to determine a suitable arrangement of link legs in a single plane as well as the longitudinal spacing of the planes. Note that the longitudinal spacing <MathJax.Node inline formula={"s"} /> must not exceed <MathJax.Node inline formula={"0.75d"} />. Therefore a practical way to proceed in design would be to determine <MathJax.Node inline formula={"s_{max}"} /> and select a trial value for <MathJax.Node inline formula={"s"} /> equal to the closest multiple of say <MathJax.Node inline formula={"25\\:mm"} /> below <MathJax.Node inline formula={"s_{max}"} />. This would be used in Eqn. 9 to determine <MathJax.Node inline formula={"A_{sw}"} />. Note also that we typically try to select spacing values to the nearest <MathJax.Node inline formula={"25\\:mm"} /> for ease of construction on site. Finally, note that EC2 specifies a minimum area of shear reinforcement in a single plane such that:
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography component="h2" className={classes.H2} > 3.0 Additional longitudinal reinforcement </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So far, we have discussed the compression force in the inclined concrete strut and its vertical component, <MathJax.Node inline formula={"V_{Rd,max}"} />. There is also a horizontal force component that must be accommodated. If we again note that <MathJax.Node inline formula={"V_{Rd,max}=V_{Ed}"} />, we can state that the horizontal force component is <MathJax.Node inline formula={"V_{Ed}\\:\\cot\\theta"} />. We make the assumption that half of this tensile force must be resisted by the tensile reinforcement. Thus the additional tensile force generated due to shear is:
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore we must provide an additional area of reinforcement above that which is required to resist bending. In practice, however, high shear forces often occur near supports where the bending moment is quite low. As a result, the longitudinal reinforcement provided in the shear critical region is often much higher than that required to resist the bending moment actually experienced in this region. This additional tensile force is accommodated by requiring the longitudinal tension steel to be embedded or anchored further into the span than required by bending alone.
                  </Typography>                 
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_27));
