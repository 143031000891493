import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

class Lecture_19_5 extends React.Component {
  state={
    course: 19,
    lecture: 5, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >       
                  <Typography component="h2" className={classes.H2} > Codes of Practice and Design Guidance Documents </Typography>           
                  
                  <Typography paragraph className={classes.bodytext}>
                    As professional engineers, our work is almost always guided by codes of practice (sometimes the term <i>standard</i> will be used) and design guidelines. These guidelines and codes of practice enforce a certain uniformity on the design process. Rather than restricting the engineer, this uniformity greatly increases the speed and efficiency of our work. It enhances our ability to collaborate and ultimately produce safe and structurally efficient designs. If first principles mechanics and fundamental engineering principles are the bedrock of analysis and design, codes of practice are a unifying layer that sits on top, making sure we're all, more or less, designing to the same levels of safety and efficiency. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    I've mentioned two classes of documents, codes of practice and design guidelines. I make a loose distinction between the two; codes of practice, for example, the suite of Eurocodes, are generally developed and maintained by pan-national organisations and act as a source of accepted design principles and rules. We typically design <i>in accordance with</i> a certain suite of codes of practice. Doing so, is shorthand for saying that we've adopted the procedures, principles, and norms set out in that suite of codes. Therefore the client or any other third party can infer a huge amount of information about how the structure has been designed and built. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Design guidelines are very similar to codes of practice and in many cases are derived from codes of practice but don't generally exist as a single unifying suite of documents with overarching design philosophies. They are often produced by different organisations within different industries and tend to provide guidance on topics not explicitly addressed in the codes of practice. Niche areas are also often addressed by design guidelines before eventually being addressed by codes of practice. Examples of design guidlines are the <i>Design Manuals</i> produced by <a href="https://www.istructe.org/" className={classes.link} target="_blank">The Institution of Structural Engineers</a> (IStructE), for example, the <i>Green book</i> for concrete design, and the excellent suite of handbooks produced by <a href="https://www.concretecentre.com/" className={classes.link} target="_blank">The Concrete Centre</a> in the UK. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    These documents also evolve over time, sometimes due to the evolving state of research in a field and sometimes due to organisational changes. For example, much of my design work as a student and graduate engineer was guided by British Standards. I still have many dog-eared copies of different standards on my shelf; BS8110 for Reinforced Concrete Design, BS5950 for Steel Design and BS6399 for loading. Some of these may still be used in your part of the world. But for me, these have been replaced, some years ago now, by Eurocodes. Since these are what I'm now most familiar with, all of the material in this course will be based on Eurocodes.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Eurocodes </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The Eurocodes are produced by the Comité Européen de Normalisation (CEN). This is the European standards agency and it is made up of a number of national standards agencies that produce national standards with the Eurocodes (Euronorms) as their basis. Nationalisation of the core code allows for localisation of various parameters where necessary in the code of practice. These are known as Nationally Determined Parameters (NDPs) and are contained within National Annexes (NAs) to the core Eurocode document. Always refer to the NAs that relate to your region. In the UK, the Eurocodes are published as National Standards by the British Standards Institute (BSI). The suite of standards is as follows:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Basis of structural design (Eurocode 0)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Actions on structures (Eurocode 1)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of concrete structures (Eurocode 2)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of steel structures (Eurocode 3)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of composite steel and concrete structures (Eurocode 4)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of timber structures (Eurocode 5)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of masonry structures (Eurocode 6)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Geotechnical design (Eurocode 7)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of structures for earthquake resistance (Eurocode 8)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Design of aluminium structures (Eurocode 9)</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                  The Eurocodes most relevant to our discussion in this course will be <i>Basis of structural design (EC0)</i>, <i>Actions on structures (EC1)</i> and obviously <i>Design of concrete structures (EC2)</i>.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.1 Basis of structural design (EC0) </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is unofficially given the designation EC0 or Eurocode 0. In the UK, this is published as BS EN 1990. This contains the principles and information for the design of civil engineering structures that is <b>material independent</b>. All of the other Eurocodes must therefore be used in conjunction with EC0 which can be considered an overarching code.  
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Actions on structures (EC1) </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This deals with determining design actions (loading) on structures. Eurocodes generally use the term <i>action</i> in place of force or loading. Published in the UK as BS EN 1991, this standard has the following parts:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Part 1-1: General actions - densities, self-weight and imposed loads</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-2: General actions - actions on structures exposed to fire</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-3: General actions - snow loads</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-4: General actions - wind loads</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-5: General actions - thermal actions</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-6: General actions - actions during execution</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-7: General actions - accidental actions</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 2: Traffic loads on bridges</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 3: Actions induced by cranes and machinery</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 4: Actions on structures - silos and tanks</Typography></li>
                  </ul>

                  <Typography component="h3" className={classes.H3} > 2.3 Design of concrete structures (EC2) </Typography>

                  <Typography paragraph className={classes.bodytext}>
                  This is obviously the main document that will govern our design in this course. It is published in the UK as BS EN 1992 and consists of the following parts:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>Part 1-1: General rules, and rules for buildings</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-2: Structural fire design</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-3: Precast concrete elements and structures</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-4: Lightweight aggregate concrete with closed structure</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-5: Structures with unbonded and external prestressing tendons</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 1-6: Plain concrete structures</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 2: Reinforced and prestressed concrete bridges</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 3: Liquid retaining and containing structures</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Part 4: Design of fastenings for use in concrete</Typography></li>
                  </ul>

                  <Typography component="h2" className={classes.H2} > 3.0 Useful design guidelines and references </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In addition to the core Eurocode documents, there is a huge number of additional design guidance documents that you may find helpful. As mentioned above, those produced by The Institution of Structural Engineers, and the Concrete Centre are particularly helpful. Some examples include:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}><i>Manual for the design of building structures to Eurocode 1 and the Basis of Structural Design</i> (The Institution of Structural Engineers)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><i>Manual for the design of Concrete Building Structures to Eurocode 2</i> (The Institution of Structural Engineers)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><i>Concrete Buildings Scheme Design Manual</i> (The Concrete Centre)</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><i>Concise Eurocode 2</i> (The Concrete Centre)</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    It's always helpful to have some reference texts to hand too. I've always found <i>Reinforced Concrete Design to Eurocode 2</i> by Mosley, Bungey and Hulse to be an excellent and concise resource. A more extensive resource text is <i>Reinforced Concrete Design to Eurocode 2</i> by Toniolo and di Prisco, published by Springer. And of course, the little red <i>Structural Engineer's Pocket Book</i> by Fiona Cobb should never be allowed to stray too far from your desk!
                  </Typography>  

                  <Typography paragraph className={classes.bodytext}>
                    Now that we've established the guidance framework that we're operating within, we'll move on in the next lecture to start discussing how to determine the loads or actions that we need to design for.
                  </Typography>

                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_5));
