import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-10/img1.png";
import img2 from "../../../images/Lectures/C17-10/img2.png";
import img3 from "../../../images/Lectures/C17-10/img3.jpg";
import img4 from "../../../images/Lectures/C17-10/img4.png";
import img5 from "../../../images/Lectures/C17-10/img5.png";
import img6 from "../../../images/Lectures/C17-10/img6.jpg";
import img7 from "../../../images/Lectures/C17-10/img7.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1=`\\begin{equation}
\\tag{1}
F_{EXT} = 
\\begin{bmatrix}
-10\\\\
10\\\\
0\\\\
-10\\\\
0\\\\
-10\\\\
10\\\\
10
\\end{bmatrix}
\\end{equation}`

const eqn2=`\\begin{equation}
\\tag{2}
[\\delta] = [K]^{-1}[F_{EXT}]
\\end{equation}`

const eqn3=`\\begin{equation}
\\tag{3}
P=\\frac{EA}{L}\\Delta L
\\end{equation}`

const eqn4=`\\begin{equation}
\\tag{4}
F_{INT} = 
\\begin{bmatrix}
-10\\\\
10\\\\
0\\\\
-10\\\\
0\\\\
-10\\\\
10\\\\
10
\\end{bmatrix}
\\end{equation}`

const eqn5=`\\begin{equation}
\\tag{5}
F_{EXT} - F_{INT} = 
\\begin{bmatrix}
0\\\\
0\\\\
0\\\\
0\\\\
0\\\\
0\\\\
0\\\\
0
\\end{bmatrix}
\\end{equation}`

const eqn6=`\\begin{equation}
\\tag{6}
F_{EXT} - F_{INT} \\neq 0
\\end{equation}`

const eqn7=`\\begin{equation}
\\tag{7}
\\sqrt{10^2+9.08^2} = 13.51 = \\text{tension in inclined member}
\\end{equation}`

const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_10 extends React.Component {
  state={
    course: 17,
    lecture: 10, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

              <Grid item xs={12} sm={12} md={10} >                  
                <Typography paragraph className={classes.bodytext}>
                  As we've already said, geometric non-linearity occurs when a structure under load, experiences large deflections that lead to a significant change in the shape of the structure. This is one of the reasons we often caveat our simplified analyses by stating that the results are only valid for <i>small deflections</i>. To get a better picture of how a change in geometry leads to non-linear behaviour, let's consider a simple 3-bar catenary, Fig. 1.
                </Typography>                                                  
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img1} />                  
                <figcaption className={classes.caption}>Fig 1. 3-bar catenary subject to 2 external point loads.</figcaption>
              </figure>  

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  Keep in mind that a linear analysis of this structure will assume that the deflections induced are small. Let's start by first constructing the vector of externally applied forces, <MathJax.Node inline formula={"[F_{EXT}]"} />. We'll assume that horizontal forces are positive to the right and vertical forces are positive upwards.
                </Typography>

                <MathJax.Node formula={eqn1} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  If we construct the stiffness matrix for the structure, <MathJax.Node inline formula={"[K]"} />, we would be able to obtain the vector of nodal displacements, <MathJax.Node inline formula={"[\\delta]"} />, in the usual way,
                </Typography>

                <MathJax.Node formula={eqn2} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Using the nodal displacements, we could then work out the change in length of each member, <MathJax.Node inline formula={"\\Delta L"} />, and from this, we could calculate the internal axial force in each member, <MathJax.Node inline formula={"P"} />,
                </Typography>
                
                <MathJax.Node formula={eqn3} className={classes.formula}/>

                <Typography paragraph className={classes.bodytext}>
                  Now, if the geometry of the structure remains unchanged (because the deflections are small remember), we can work out the orthogonal components of each member's axial force and use these to build a vector of internal forces, <MathJax.Node inline formula={"F_{INT}"} />.
                </Typography>                                                                   
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img2} />                  
                <figcaption className={classes.caption}>Fig 2. Inernal member forces and their orthogonal components.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                The internal member force system that arises from our linear analysis (remember, no change in shape) is,
                </Typography>

                <MathJax.Node formula={eqn4} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Clearly, we can see that the internal force system perfectly balances the externally applied forces. In other words, the structure is in equilibrium, assuming the displacements remain negligibly small. 
                </Typography>

                <MathJax.Node formula={eqn5} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  If I use the solver toolbox we're about to build to model the structure and set the axial stiffness sufficiently high so that deflections remain negligible, the solution produced is in agreement with the linear behaviour we discussed above, Fig 3. 
                </Typography>                                                                  
              </Grid>

              <figure style={{width:"80%"}}>
                <img className={classes.image} src={img3} />                  
                <figcaption className={classes.caption}>Fig 3. Analysis results when axial stiffness is set high enough to eliminate significant deflection.</figcaption>
              </figure> 


              <Grid item xs={12} sm={12} md={10} >       

                <Typography component="h2" className={classes.H2} > Non-linear behaviour </Typography>           

                <Typography paragraph className={classes.bodytext}>
                  Now let's consider the same structure with significantly reduced member stiffness. This time the deflections are sufficient to cause a significant change to the structure’s geometry, Fig. 4.
                </Typography>                                               
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img4} />                  
                <figcaption className={classes.caption}>Fig 4. 3-bar catenary subject to 2 external point loads with reduced member axial stiffness.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} > 

                <Typography paragraph className={classes.bodytext}>
                  We might naively assume that the vector of external forces remains the same as before, given by Eqn. 1. However, if we now focus on node 1, we see that the inclined member no longer makes an angle of <MathJax.Node inline formula={"45^\\circ"} /> with the horizontal axis. It makes a larger angle, <MathJax.Node inline formula={"\\theta"} />, and as a result, the horizontal component of the reaction,  <MathJax.Node inline formula={"H_1"} /> must now be some value less than <MathJax.Node inline formula={"10"} />. In fact, we can see that the value of  <MathJax.Node inline formula={"H_1"} /> is directly dependent on <MathJax.Node inline formula={"\\theta"} />, i.e. it depends on the shape of the structure, Fig. 5. 
                </Typography>                     
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img5} />                  
                <figcaption className={classes.caption}>Fig 5. Large deflections altering the angle of inclination of the member at joint 1.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} >  

                  <Typography paragraph className={classes.bodytext}>
                    So, if we assumed the external forces vector was given by Eqn. 1, perfectly acceptible if we thought the behaviour was linear, we now find that the actual internal force vector no longer perfectly balances it, 
                  </Typography>

                  <MathJax.Node formula={eqn6} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    So, our model of the structure’s behaviour is no longer valid since it is not accurately predicting what we've observed. We know that if the structure is in a state of static equilibrium, at all times, the internal and external force systems must balance. The solution toolbox we're about to build will use this as its governing criteria; it will seek to minimise <MathJax.Node inline formula={"F_{INT}-F_{EXT}"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we run this structure with its reduced member stiffness through our toolbox again (the same toolbox that generated Fig. 3), we obtain the following results, Fig. 6.
                  </Typography>                                                                    
              </Grid>

              <figure style={{width:"80%"}}>
                <img className={classes.image} src={img6} />                  
                <figcaption className={classes.caption}>Fig 6. Analysis results when axial stiffness is reduced to produce significant deflection.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  Note that the non-linear analysis performed by our toolbox has yielded a valid result, indicated by the force equilibrium at joint 1; compare the resultant reaction force with the axial force in the inclined member,
                </Typography>

                <MathJax.Node formula={eqn7} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  So, we can see that any structure that undergoes a large change in shape resulting from deflections under load, can exhibit geometric non-linearity as demonstrated above. 
                </Typography>

                <Typography paragraph className={classes.bodytext}>
                  The non-linear behaviour we observed above was due to the large elastic deformation of the inclined members. However, cable/catenary structures are also susceptible to changes in geometry in the absence of large elastic deformation. This is because the individual members only exhibit axial stiffness. As an example, consider the 6-bar catenary below, Fig. 7, subject to 4 point loads.
                </Typography>                                                             
              </Grid>

              <figure style={{width:"80%"}}>
                <img className={classes.image} src={img7} />                  
                <figcaption className={classes.caption}>Fig 7. 6-bar catenary subject to 4 point loads.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  Note that it's the articulation of the structure in addition to the location of applied loads that leads to a change in the shape of the structure, rather than elastic deformation. This analysis was performed with the original (large) member stiffnesses. However, the behaviour was significantly non-linear, requiring many iterations to converge upon equilibrium. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  This example also hints at another feature of cable structures that we'll explore further a little later; a pre-tension must be applied to the structure to provide its initial stiffness and to avoid rigid body rotation of the elements under the applied loads. Without the additional stiffness provided by an initial pre-tension, we would end up with a singular matrix that could not be inverted. We'll explore the influence of pre-tension in the next section of the course. Before we do that, in the next lecture, we'll start to build up a strategy for iterating towards equilibrium of the structure using the Newton-Raphson method. 
                </Typography>
              </Grid>
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_10));
