import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-10/img1.png";
import img2 from "../../../images/Lectures/C19-10/img2.png";
import img3 from "../../../images/Lectures/C19-10/img3.png";
import img4 from "../../../images/Lectures/C19-10/img4.png";

const Eqn1 = `\\begin{equation}
\\tag{1}
\\sigma_{max} = \\frac{N_{Ed}}{A} + \\frac{M_{Ed}\\:y}{I}
\\end{equation}`

const Eqn2 = `\\begin{align}
w_{max} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{max} &= 1.35\\times 25 + 1.5\\times 12.5\\\\\\\\
w_{max} &= 52.5\\:kN/m
\\end{align}`

const Eqn3 = `\\begin{align}
w_{min} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{min} &= 1.35\\times 25 + 0\\times 12.5\\\\\\\\
w_{min} &= 33.75\\:kN/m
\\end{align}`

const Eqn4 = `\\begin{align}
w_{max,w} &= \\gamma_Q\\:\\psi_0\\:q_{k,wind}\\\\\\\\
w_{max,w} &= 1.5\\times 0.5\\times 6\\\\\\\\
w_{max,w} &= 4.5\\:kN/m
\\end{align}`

const Eqn5 = `\\begin{align}
P &= 1\\times 0.6\\times 10 \\times 25\\\\\\\\
P &= 150\\:kN
\\end{align}`

const Eqn6 = `\\begin{align}
N_{Ed} &= (33.75\\times 4) + (52.5\\times 7) + 202.5\\\\\\\\
N_{Ed} &= 705\\:kN
\\end{align}`

const Eqn7 = `\\begin{align}
M_{Ed} &= (52.5\\times 7\\times 3.5) + (4.5\\times 10\\times 5) -(33.75\\times 4\\times 2)\\\\\\\\
M_{Ed} &= 1241.25\\:kNm
\\end{align}`

const Eqn8 = `\\begin{align}
I &= \\frac{600\\times1000^3}{12}\\\\\\\\
I &=5\\times 10^{10}
\\end{align}`

const Eqn9 = `\\begin{align}
\\sigma_{max} &= \\frac{705\\times 10^3}{1000\\times 600} + \\frac{1241.25\\times10^6 \\times 500}{5\\times 10^{10}}\\\\\\\\
\\sigma_{max} &= 1.18 + 12.41 \\:N/mm^2\\\\\\\\
\\sigma_{max} &= 13.59\\:N/mm^2\\:(<15\\:N/mm^2)
\\end{align}`

const Eqn10 = `\\begin{align}
N_{Ed} &= 52.5\\times 11 + 202.5\\\\\\\\
N_{Ed} &= 780\\:kN
\\end{align}`

const Eqn11 = `\\begin{align}
M_{Ed} &= (52.5\\times 11 \\times 1.5) +(4.5\\times 10\\times 5) \\\\\\\\
M_{Ed} &= 1091.25\\:kNm
\\end{align}`

const Eqn12 = `\\begin{align}
\\sigma_{max} &= \\frac{780\\times 10^3}{1000\\times 600} + \\frac{1091.25\\times10^6 \\times 500}{5\\times 10^{10}}\\\\\\\\
\\sigma_{max} &= 1.3 + 10.91 \\:N/mm^2\\\\\\\\
\\sigma_{max} &= 12.21\\:N/mm^2\\:(<15\\:N/mm^2)
\\end{align}`


class Lecture_19_10 extends React.Component {
  state={
    course: 19,
    lecture: 10, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >  
                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>                
                  <Typography paragraph className={classes.bodytext}>
                    Consider the reinforced concrete podium structure shown in Fig. 1 (a) below. It consists of a cantilever column and deck, supporting a lightweight steel frame structure. The column cross-section is also shown. The characteristic permanent, variable and wind loading is shown in Fig 1 (b).
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the maximum compressive stress in the column should not exceed <MathJax.Node inline formula={"15\\:N/mm^2"} />, confirm that the column satisfies the STR limit state. 
                  </Typography>                                                                    
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. (a) R.C. podium supporting a lightweight steel frame structure, (b) characteristic loading applied to the R.C. structure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >             
                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>   

                  <Typography paragraph className={classes.bodytext}>
                    This problem requires us to determine the most onerous combination of all three loads and to determine the maximum compressive stress in the column. The compressive stress, <MathJax.Node inline formula={"\\sigma_{max}"} />, will arise due to direct compression and bending. However, different combinations of actions will maximise the axial force and bending moment in the column. So, we must consider different combinations and check the maximum stress developed from each one. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the axial force in the column is <MathJax.Node inline formula={"N_{Ed}"} /> and the bending moment is <MathJax.Node inline formula={"M_{Ed}"} />, we can calculate the maximum compressive stress from,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"A"} /> is the cross-sectional area of the column, <MathJax.Node inline formula={"y"} /> is the distance from the section’s neutral axis to the extreme fibre of the cross-section, <MathJax.Node inline formula={"y=500\\:mm"} /> in this case, and <MathJax.Node inline formula={"I"} /> is the relevant second moment of area of the section.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.1 Case 1: maximising bending </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll start by identifying the design loads that maximise the bending moment in the column and, therefore, the normal stress due to bending. This is achieved by applying
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>the maximum loading on the right outstand </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>the minimum loading on the left outstand</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>the maximum wind loading acting to the right</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    Referring to the table of partial factors (repeated below again), the maximum vertical loading is calculated as,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The minimum vertical loading is calculated as,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Partial factors and combinations for ultimate limit states EQU, STR and GEO (repeated from lecture 4).</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Similarly to the previous example, note the use of the single source principle again, forcing a value of <MathJax.Node inline formula={"\\gamma_G=1.35"} /> rather than <MathJax.Node inline formula={"\\gamma_G=1"} />, as we might initially expect from  the table of factors. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As the variable wind loading, <MathJax.Node inline formula={"q_{k,wind}"} />, is an accompanying variable action (we have selected <MathJax.Node inline formula={"q_k"} /> as the leading variable action in this case), we must apply the combination factor, <MathJax.Node inline formula={"\\psi_0"} /> from the table in <Link to="/course-19/lecture-6" className={classes.link} target="_blank">lecture 6</Link>, repeated below. Since this is a wind load, <MathJax.Node inline formula={"\\psi_0=0.5"} />.
                  </Typography>
                  
                  <MathJax.Node formula={Eqn4} className={classes.formula}/>             
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Values of <MathJax.Node inline formula={"\\psi"} /> factors for buildings.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Finally, we can't neglect the self-weight of the column itself. If we allow <MathJax.Node inline formula={"25\\:kN/m^3"} /> for reinforced concrete, the load imposed by the column self-weight is,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Applying <MathJax.Node inline formula={"\\gamma_G=1.35"} />, we obtain a ULS force of <MathJax.Node inline formula={"P_{max} = 202.5\\:kN"} />. The loading for this case is summarised in Fig. 4.
                  </Typography>
             
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Ultimate limit state loading for STR load case 1.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The axial load at the base of the column can now be calculated as,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Similarly, the bending moment is,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The second moment of area about the bending axis is,
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Using Eqn. 1, the maximum compressive stress at the base of the column is therefore, 
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, load case 1 satisfies the STR ultimate limit state. We can also see that bending has, by far, the bigger impact on the compressive stress, so this load case that sought to maximise the bending moment is likely to be the worst case. However, just to be sure, we will examine a second load case that seeks to maximise the axial load. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Case 2: maximising direct compression </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To generate the loading, we simply need to apply the maximum loading across both cantilever outstands. We can also maintain the maximum wind loading already calculated. The axial load is therefore,
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The bending moment is,
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, the stress is calculated as,
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    As suspected, case 1 was more onerous than case 2 and both were within the limit specified, so, <i>based on this simple stress check</i>, the column satisfies the STR ultimate limit state.
                  </Typography>
                                
                </Grid>


              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_10));
