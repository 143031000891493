import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-29/img1.png";
import img2 from "../../../images/Lectures/C19-29/img2.png";
import img3 from "../../../images/Lectures/C19-29/img3.png";

const Eqn1 = `\\begin{equation}
\\tag{1}
F = \\frac{M}{d-h_f/2}
\\end{equation}`

const Eqn2 = `\\begin{equation}
\\tag{2}
\\Delta F = \\frac{\\Delta M}{d-h_f/2}
\\end{equation}`

const Eqn3 = `\\begin{align}
\\Delta F_d &= \\Delta F \\times \\frac{b_f}{b_{eff}}\\\\\\\\
\\tag{3}
\\Delta F_d &= \\frac{\\Delta M}{d-h_f/2}\\times \\frac{b_f}{b_{eff}}
\\end{align}`

const Eqn4 = `\\begin{equation}
\\tag{4}
\\boxed{v_{Ed} = \\frac{\\Delta F_d}{h_f\\: \\Delta x}}
\\end{equation}`

const Eqn5 = `\\begin{equation}
\\tag{5}
\\frac{\\mathrm{d}M}{\\mathrm{d}x} = V
\\end{equation}`

const Eqn6 = `\\begin{equation}
\\tag{6}
\\boxed{v_{Ed} \\leq \\frac{\\nu_1f_{ck}}{1.5(\\cot\\theta_f+\\tan\\theta_f)}}
\\end{equation}`

const Eqn7 = `\\begin{equation}
\\tag{7}
\\boxed{\\theta_f = 0.5\\:\\sin^{-1}\\left[\\frac{v_{Ed}}{0.2\\:\\left(1-\\frac{f_{ck}}{250}\\right)f_{ck}}\\right]}
\\end{equation}`

const Eqn8 = `\\begin{equation}
\\tag{8}
v_{Ed}\\leq 0.4\\frac{f_{ctk}}{1.5}
\\end{equation}`

const Eqn9 = `\\begin{equation}
\\tag{9}
A_{s,min}=0.26\\:bd_f\\frac{f_{ctm}}{f_{yk}}\\:\\text{but}\\: >0.0013\\:bd_f
\\end{equation}`

const Eqn10 = `\\begin{equation}
\\tag{10}
\\frac{F_{tran}}{V_{Ed}} = \\frac{1}{\\cot \\theta_f}
\\end{equation}`

const Eqn11 = `\\begin{equation}
\\tag{11}
\\frac{0.87\\:f_{yk}\\:A_{sf}}{v_{Ed}\\:h_f\\:s_f} = \\frac{1}{\\cot \\theta_f}
\\end{equation}`

const Eqn12 = `\\begin{equation}
\\tag{12}
\\boxed{\\frac{A_{sf}}{s_f} = \\frac{v_{Ed}\\:h_f}{0.87\\:f_{yk}\\:\\cot{\\theta_f}}}
\\end{equation}`

class Lecture_19_30 extends React.Component {
  state={
    course: 19,
    lecture: 30, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    When designing flanged beams, in addition to designing for vertical shear, using the techniques we've already seen, we need to design the beam to withstand the complimentary longitudinal shear stresses that develop along the vertical sections at the web-to-flange interface. To do this, we will again implement a variable strut inclination model of shear resistance. We'll develop this model and the associated design procedure in this lecture. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 1.0 Longitudinal shear and design shear stress, <MathJax.Node inline formula={"v_{Ed}"} /> </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Let's start by first visualising the planes on which these stresses develop. Fig 1(a) below shows a typical segment of a T-beam, while Fig 1(b) shows the flanges separated from the web. The vertical planes along which longitudinal shear stresses develop are highlighted in red. If this section is not reinforced, the beam will be prone to shearing along this plane. 
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. (a) Typical T-beam segment, (b) exploded T-beam, showing the vertical planes on which longitudinal shear stresses develop.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    If we consider some segment of T-beam with length, <MathJax.Node inline formula={"\\Delta_x"} />, Fig. 2, the shear force that develops along the planes of interest is denoted as <MathJax.Node inline formula={"\\Delta F_d"} />, and so it is the change in shear force over the segment length that we must determine.  
                  </Typography>
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Shear force, <MathJax.Node inline formula={"\\Delta F_d"} />, that develops along the planes of interest for longitudinal shear.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    If the lever arm for the cross-section is <MathJax.Node inline formula={"d-h_f/2"} />, then the force magnitude at a given cross-section, <MathJax.Node inline formula={"F"} />, can be obtained from the bending moment at the section, <MathJax.Node inline formula={"M"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore, the change in force, <MathJax.Node inline formula={"\\Delta F"} /> over some length, <MathJax.Node inline formula={"\\Delta_x"} />, can be obtained from the change in bending moment over the same length,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We can now determine the longitudinal shearing force that develops along the interface between the web and flange (over the length <MathJax.Node inline formula={"\\Delta x"} />), <MathJax.Node inline formula={"\\Delta F_d"} />, as a proportion of the overall force on the section, <MathJax.Node inline formula={"\\Delta F"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The corresponding shear stress, <MathJax.Node inline formula={"v_{Ed}"} /> is obtained as,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We can see from Eqn. 3 that the largest change in longitudinal shear force, <MathJax.Node inline formula={"\\Delta F_d"} />, occurs over the same distance that the largest change in moment occurs. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The largest change in bending moment occurs where the slope of the bending moment diagram is largest. Based on the fundamental relationship between bending and shear, this is also where the absolute value of the shear stresses is highest,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, on this basis, the maximum value of <MathJax.Node inline formula={"\\Delta_x"} /> should not exceed half the distance between the section of zero moment and the section of maximum moment. When the beam is subject to point loads, <MathJax.Node inline formula={"\\Delta_x"} /> should not exceed the distance between point loads. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Variable Strut Model </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As mentioned at the start of this lecture, in order to determine a suitable area of transverse shear reinforcement, we implement the same model of shear resistance as for vertical shear - the variable strut inclination model. Figure 3 below outlines the main components of the model. Note that in Fig 3, for clarity, only the model components that apply to the left flange are shown.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Variable strut inclination model of shear resistance for transverse shear in a T-beam.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We are again using the analogy of a truss to describe internal force transmission within the structure. The design longitudinal shear force, <MathJax.Node inline formula={"V_{Ed}=\\Delta F_d"} />, is shown on the plane of interest. The transverse shear reinforcement, <MathJax.Node inline formula={"A_{sf}"} />, that crosses the plane is shown along with its spacing, <MathJax.Node inline formula={"s_f"} />. Our task will be to determine a suitable combination of <MathJax.Node inline formula={"A_{sf}"} /> and <MathJax.Node inline formula={"s_f"} />. We can also see the inclined compression strut forming at an angle <MathJax.Node inline formula={"\\theta_f"} /> to the longitudinal axis of the beam. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Once we recognise the various components of the truss model and understand their function, the design procedure is more or less the same as for vertical shear; we check for crushing of the compression strut and then calculate the area and spacing of transverse shear reinforcement required to resist the transverse tensile force over the length <MathJax.Node inline formula={"\\Delta_x"} />.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.1 The inclined concrete compression strut </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We check the concrete compression strut in exactly the same way that we did for vertical shear. To avoid crushing of the compression strut, the longitudinal shear stress is limited as follows,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where the strength reduction factor is <MathJax.Node inline formula={"\\nu_1 = 0.6(1-f_{ck}/250)"} />. The angle of the compression strut is again limited by EC2 to the following ranges:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}><MathJax.Node inline formula={"26.5^{\\circ}\\leq \\theta_f \\leq 45^{\\circ}"} /> when the flange is in compression (sagging moment)</Typography></li>
                    <li><Typography paragraph className={classes.bodytext}><MathJax.Node inline formula={"38.6^{\\circ}\\leq \\theta_f \\leq 45^{\\circ}"} /> when the flange is in tension (hogging moment)</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    We again start by testing <MathJax.Node inline formula={"v_{Ed}"} /> with the lower angle, using Eqn. 6. Only if <MathJax.Node inline formula={"v_{Ed}"} /> exceeds the limit do we calculate the angle of the strut and use this to re-evaluate Eqn. 6. The angle of the strut can be calculated using,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This equation is derived using the same logic and approach that was used to derive the equivalent equation for vertical shear. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Transverse shear reinforcement </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If the design shear stress <MathJax.Node inline formula={"v_{Ed}"} /> is less than <MathJax.Node inline formula={"40\\%"} /> of the design concrete tensile strength, 
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"f_{ctk}"} /> is the characteristic tensile strength of concrete, then only the minimum area of transverse steel is required, given by,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"b=1000\\:mm"} /> and <MathJax.Node inline formula={"d_f"} /> is the effective depth of the flange. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In the event that <MathJax.Node inline formula={"A_{s,min}"} /> is not sufficient, an arrangement of <i>designed</i> transverse reinforcement must be calculated. If we let <MathJax.Node inline formula={"A_{sf}"} /> be the cross-sectional area of a single transverse reinforcement bar and <MathJax.Node inline formula={"s_f"} /> be the longitudinal spacing of the bars, as indicated in Fig 3, the force in a single bar is <MathJax.Node inline formula={"0.87\\:f_{yk}\\:A_{sf}"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now, the transverse tensile force, let's call it <MathJax.Node inline formula={"F_{tran}"} />, is related to the longitudinal shear force through the strut angle <MathJax.Node inline formula={"\\theta_F"} /> as follows,
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If we now consider the transverse shear force over a short length <MathJax.Node inline formula={"s_f"} />, and replace <MathJax.Node inline formula={"F_{tran}"} /> with the force in a single transverse reinforcement bar, Eqn. 10 becomes,
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    and rearranging, we obtain,
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This equation can be used to determine a suitable arrangement of transverse shear reinforcement. Note that the transverse reinforcement calculated using Eqn. 12 may also be the steel required for bending of the flange in the transverse direction (perpendicular to the T-beam). So, the designed reinforcement should be the larger of,
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>that obtained from Eqn. 12 </Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>half of that obtained from Eqn. 12 plus the reinforcement required for transverse bending of the flange</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    In the next lecture, we'll implement the equations discussed above with a transverse shear design example.
                  </Typography>                  
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_30));
