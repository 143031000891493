import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-41/img1.jpg";
import img2 from "../../../images/Lectures/C17-41/img2.jpg";
import img3 from "../../../images/Lectures/C17-41/img3.jpg";
import img4 from "../../../images/Lectures/C17-41/img4.jpg";
import img5 from "../../../images/Lectures/C17-41/img5.jpg";
import img6 from "../../../images/Lectures/C17-41/img6.jpg";
import img7 from "../../../images/Lectures/C17-41/img7.jpg";
import img8 from "../../../images/Lectures/C17-41/img8.jpg";
import img9 from "../../../images/Lectures/C17-41/img9.jpg";
import img10 from "../../../images/Lectures/C17-41/img10.jpg";
import img11 from "../../../images/Lectures/C17-41/img11.jpg";

const codeString1 = 
`forceVector[3] = 0
forceVector[11] = 0`

const codeString2 = 
`#Constants
E = 70*10**9 #(N/m^2)
A = 0.0000025 #(m^2)

gamma = 0 #(kg/m) Cable mass per unit length
swt = False #Self-weight into account flag
Areas = A*np.ones([len(members)]) #An array to hold individual area for each member 
P0 = 10*np.ones([len(members)]) #An array to hold individual member pre-tension values
P = -10000 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

nForceIncrements = 1000 #Number of force increments
convThreshold = 30 #(N) Threshold on maximum value of F_inequilibrium`

const codeString3 = 
`#Constants
E = 70*10**9 #(N/m^2)
A = 0.0000025 #(m^2)

gamma = 0 #(kg/m) Cable mass per unit length
swt = False #Self-weight into account flag
Areas = A*np.ones([len(members)]) #An array to hold individual area for each member 
P0 = 1000*np.ones([len(members)]) #An array to hold individual member pre-tension values
P = -10000 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

nForceIncrements = 1500 #Number of force increments
convThreshold = 100 #(N) Threshold on maximum value of F_inequilibrium`


class Lecture_17_41 extends React.Component {
  state={
    course: 17,
    lecture: 41, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now that we have a complete analysis notebook, we’ll take some time in this lecture to investigate how the code handles several different non-linear structures and loading arrangements. We'll run several analyses with different parameters to get a better understanding of non-linearity and how our solver handles it.  
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Case 1 </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We’ll start by modifying our three-bar catenary structure by subdividing the members and creating a six-bar structure. You can download the data csv files that define the structure from the resource panel above. Once downloaded, copy the data from the folder named <i>data - 6 bar</i> into the <i>data</i> folder beside your notebook. The notebook we're starting with in this lecture is the same one we finished the previous section with.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If you run the notebook down to the pre-analysis plot, you'll see the structure shown in Fig. 1 below. 
                  </Typography>                  
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. The 6-bar catenary with four vertical point loads.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    You'll notice that we now have four point loads applied at nodes 2, 3, 5 and 6. For our first analysis, we only want to apply the loads at nodes 3 and 5. So, we'll manually remove the loads at nodes 2 and 6 by overwriting their values within <code className={classes.code}>forceVector</code>. We'll do this temporarily by placing the code block below immediately after the <i>'Add point loads to global force vector'</i> code block; so, just after we've constructed the  <code className={classes.code}>forceVector</code>. We can simply comment out this code when we want to reinstate these point loads. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    If we re-plot the structure after making this modification we can see that the two point loads have been removed, leaving only the point loads at nodes 3 and 5, Fig 2. 
                  </Typography>                 
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. The 6-bar catenary with two vertical point loads.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now, before we do our first full code run-through, let's return the cross-sectional area to <MathJax.Node inline formula={"0.0025\\:m^2"} /> in the manual data entry code block.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we now try to run our code, we get hit with an error when we try to invert our stiffness matrix, Fig 3. This is occurring because our structure is now capable of rigid-body rotation. This means that elements in the structure can change position without undergoing any change in stress. This behaviour arises due to the extra articulation introduced by the new nodes 2, 4 and 6. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If part of a structure undergoes a rigid-body rotation, then the positions of two or more nodes are coupled, which means the rows of the element stiffness matrix are no longer linearly independent. As a result, our matrix becomes singular and cannot be inverted. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To overcome this, we need to develop additional off-axis stiffness. As we saw when we derived the non-linear stiffness matrix earlier, this can be provided by having a non-zero pre-tension in the cable elements. This makes sense intuitively as we can imagine that a way to eliminate free rigid-body rotation of the cable elements would be to pull the cable taut, this is the same as adding a pre-tension force. 
                  </Typography>                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. The error results from trying to invert the singular stiffness matrix.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    So, let's add a nominal uniform pre-tension of <MathJax.Node inline formula={"10\\:N"} /> in the manual data entry block and try to run our analysis again. Everything runs fine, and a cursory inspection of the results plot, Fig 4. shows that the expected linear behaviour of the structure is observed; the 6-bar structure behaves the same as the 3-bar version (when self-weight is ignored). 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Remember that the pre-tension was nominal, only <MathJax.Node inline formula={"10\\:N"} /> compared with applied loads of <MathJax.Node inline formula={"10\\:kN"} />. So, in this case, the pre-tension is something of a workaround to make the linear algebra work out. Technically, it's providing an additional off-axis stiffness that's rendering our structure stiffness matrix invertible. Still, the magnitude is tiny, so it has very little other significance on the behaviour of the structure. 
                  </Typography>
                                 
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Linear behaviour of the 6-bar structure with non-zero pre-tension added.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >           

                  <Typography component="h2" className={classes.H2} > Case 2 </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This time we'll repeat the analysis but revert back to the smaller cross-sectional area of <MathJax.Node inline formula={"0.0000025\\:m^2"} />. Recall that when we did this for the 3-bar structure, we observed our first case of non-linear behaviour due to large defections. The pre-tension of <MathJax.Node inline formula={"10\\:N"} /> can remain unchanged, so the full manual data entry block should be as follows.
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Running the code again, unsurprisingly we get the same behaviour we saw previously for the 3-bar version of the structure, Fig 5. The same large deflection results in reduced horizontal reactions as the vertical members increase their angle of inclination. 
                  </Typography>                 
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img5} />                  
                  <figcaption className={classes.caption}>Fig 5. Non-linear behaviour of the 6-bar structure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     
                  <Typography component="h2" className={classes.H2} > Case 3 </Typography>             
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this analysis, we'll reintroduce the two point loads on nodes 2 and 6 that we removed earlier. We can simply comment out or delete the temporary code we introduced to override the force values at these nodes. Our pre-analysis plot should now look like Fig. 1 again. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This loading arrangement is going to cause our structure to behave very non-linearly because the point loads on nodes 2 and 6 will lead to very significant changes in the shape of the structure. Our code will find it much more difficult to converge for this arrangement. Before running the code, let's change the cross-sectional area back to the larger value of <MathJax.Node inline formula={"0.0025\\:m^2"} />. With this larger area, the deflection is not due to the large elastic elongation of the members, as was the case previously. This time the large deflection and non-linearity result from the geometric configuration of the structure and applied loads. This arrangement of loading wants to immediately change the shape of the structure from the very instant to the first load increment is applied.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we try to run the code now, we run into more problems. We notice that there are no convergence messages being printed from our main loop. This tells us that the structure never converged for the first load increment. The <code className={classes.code}>while</code> loop ran until it hit its hard limit of <MathJax.Node inline formula={"10000"} /> iterations, at which point the code continued executing until an error was produced when we tried to generate the text output. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can see by inspecting the outstanding force imbalance, Fig. 6, that the maximum force imbalance was <MathJax.Node inline formula={"1794 \\:kN"} />. Considering our convergence threshold is currently set to <MathJax.Node inline formula={"30\\:N"} />, the structure never came close to convergence for even the first load increment. So, there's no point in investigating the details of the subsequent error message because our real problem is that the structure never converged!
                  </Typography>                                   
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img6} />                  
                  <figcaption className={classes.caption}>Fig 6. Force imbalance after structure failed to converge.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Lack of convergence is, unfortunately, a feature of these numerical solution strategies and in particular, the analysis of cables. Sometimes, the model simply won't converge. We can do several things to increase our chance of convergence, but running into a dead-end is always a possibility. Fortunately for this structure, we have a way out. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Before moving straight to the solution, let's consider the factors influencing our model's chances of convergence. 
                  </Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}>Pre-tension: The level of pre-tension changes the structure’s stiffness, and as a result, it will influence the chances of convergence. A larger stiffness will yield smaller deflections for each analysis iteration which will generally increase the likelihood of convergence. We can think of the pre-tension as increasing the structures' ability to maintain its initial shape. So, for our structure and loading arrangement, we might imagine that significantly increasing the pre-tension will help. </Typography></li>
                    <li><Typography paragraph className={classes.bodytext}>In a similar vein, we could increase the number of force increments, thereby reducing the deflection that takes place over a single iteration. This will also help but will also lead to more iterations and longer execution time.</Typography></li>                    
                  </ol>

                  <Typography paragraph className={classes.bodytext}>In our case, we can start by increasing the pre-tension to <MathJax.Node inline formula={"1000 \\:N"} />, way beyond the previous nominal value of <MathJax.Node inline formula={"10 \\:N"} />. Running the code again, we still don't observe convergence, but take a look at the force imbalances for the first load increment, Fig 7.</Typography>
                             
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img7} />                  
                  <figcaption className={classes.caption}>Fig 7. Force imbalance after pre-tension was increased to <MathJax.Node inline formula={"1000 \\:N"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The maximum force imbalance has been reduced to just <MathJax.Node inline formula={"45\\:N"} />, much closer to our convergence threshold of <MathJax.Node inline formula={"30\\:N"} />. This is a good sign - we're getting closer. Now let's try increasing the convergence threshold to <MathJax.Node inline formula={"50\\:N"} />. Running the code, we see that the structure will now converge for the first ten increments of load, although a very large number of iterations are required, Fig. 8. On the 11th iteration, the loop runs out of iterations before convergence is achieved. We also note that the largest force imbalance at the point we ran out of analysis iterations was <MathJax.Node inline formula={"50\\:N"} />. So, we're another step closer but still not there yet.
                  </Typography>                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img8} />                  
                  <figcaption className={classes.caption}>Fig 8. Convergence behaviour after pre-tension increased to <MathJax.Node inline formula={"1000 \\:N"} /> and convergence threshold increased to <MathJax.Node inline formula={"50\\:N"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                  In a final attempt to get our structure to converge, let's increase the convergence thrshold to <MathJax.Node inline formula={"100 \\:N"} /> and also increase the number of force increments to <MathJax.Node inline formula={"1500"} />. So, our data entry block should be as follows.
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, with these parameters, our code runs and converges for the full applied load. Interestingly, if you scroll down through the convergence output statements, you'll see that our solver must initially go through many iterations to achieve convergence for the early load increments. However, the behaviour of the structure eventually <i>settles</i> to the point where a single iteration is sufficient for each load increment, Fig 9. This is typical of structures that exhibit a high degree of non-linearity. In the case of this structure, a much greater variation in shape takes place for earlier load increments when compared with the later load increments.
                  </Typography>                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img9} />                  
                  <figcaption className={classes.caption}>Fig 9. Convergence behaviour after pre-tension increased to <MathJax.Node inline formula={"1000 \\:N"} />, convergence threshold increased to <MathJax.Node inline formula={"100 \\:N"} /> and number of force increments increased to <MathJax.Node inline formula={"1500"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now, we can visually inspect the final configuration of the structure, as well as how it got there (using the load increment slider), Fig. 10. We can see that the final shape of the structure is significantly different from its initial configuration. From what we know of geometric non-linearity, it makes sense to us now why our code faced such a challenge in converging.  
                  </Typography>          
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img10} />                  
                  <figcaption className={classes.caption}>Fig 10. Non-linear behaviour of the 6-bar structure after convergence for all applied loading.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    A final point to make here is that although we increased the convergence threshold to <MathJax.Node inline formula={"100 \\:N"} />, the maximum force imbalance for the structure in its final configuration is just <MathJax.Node inline formula={"22 \\:N"} />. This tells us that a higher threshold was required to achieve convergence for the earlier load increments where there were larger deflections on each load increment. Once the convergence behaviour stabilised, the force imbalance was reduced. Even so, we need to be careful with this parameter as it has the potential to give us false accuracy and ultimately invalidate our model output if set too high.
                  </Typography>                                                   
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img11} />                  
                  <figcaption className={classes.caption}>Fig 11. Summary text output.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    That's all we're going to cover in this lecture. By now you should have a greater appreciation for the strengths and weaknesses of the solver we've built and a better understanding of the parameters that influence its behaviour and accuracy. 
                  </Typography>
                </Grid>
               
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_41));
