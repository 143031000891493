import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../../../actions";
import Captcha from "../../../components/utils/Recaptcha";
import Grid from "@material-ui/core/Grid";
import Feedback from "../../../components/utils/Feedback";
import LayoutPublic from "../../../components/common/LayoutPublic";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import red from "@material-ui/core/colors/red";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Particles from "react-particles-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import CourseData from '../../../Data/courses.js';
import {returnImage} from '../../../Data/ReturnImage';

const colorRed = red[500];

const optionsRatings=[
  {    
    filter: '5' 
  },
  {  
    filter: '4' 
  },
  {    
    filter: '3' 
  },
  {    
    filter: '2' 
  },
  {    
    filter: '1' 
  }  
]


function Copyright() {
	return (
		<div style={{ padding: "10px" }}>
			<Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
				Copyright &copy; {new Date().getFullYear()} Mind Map Media Ltd. All rights
				reserved.
			</Typography>
		</div>
	);
}

//Config for particles.js
const particleConfig = {
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
				value_area: 800
			}
		},
		color: {
			value: "#33cc99"
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000"
			},
			polygon: {
				nb_sides: 5
			},
			image: {
				src: "img/github.svg",
				width: 100,
				height: 100
			}
		},
		opacity: {
			value: 0.06313181133058181,
			random: false,
			anim: {
				enable: false,
				speed: 0.5,
				opacity_min: 0.1,
				sync: false
			}
		},
		size: {
			value: 3,
			random: true,
			anim: {
				enable: false,
				speed: 40,
				size_min: 0.1,
				sync: false
			}
		},
		line_linked: {
			enable: true,
			distance: 150,
			color: "#33cc99",
			opacity: 0.11048066982851817,
			width: 1
		},
		move: {
			enable: true,
			speed: 2,
			direction: "none",
			random: false,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200
			}
		}
	},
	interactivity: {
		detect_on: "canvas",
		events: {
			onhover: {
				enable: false,
				mode: "repulse"
			},
			onclick: {
				enable: false,
				mode: "push"
			},
			resize: true
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1
				}
			},
			bubble: {
				distance: 400,
				size: 40,
				duration: 2,
				opacity: 8,
				speed: 3
			},
			repulse: {
				distance: 200,
				duration: 0.4
			},
			push: {
				particles_nb: 4
			},
			remove: {
				particles_nb: 2
			}
		}
	},
	retina_detect: true
};

//Custom styling
const styles = theme => ({
	particleBackground: {
    background: '#1a1a1a',
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "fixed !important",
		opacity: "100%",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -2
  },
  outsidePaper:{
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8), 
  },
	paper: {       
    background: '#25252550',    
		marginTop: theme.spacing(),
		display: "flex",
		flexDirection: "column",
    alignItems: "center",
    color:'#33cc99',
    borderRadius:'25px',    
	},
	avatar: {
		margin: '20px',
		width: '50%',
		height: '50%',
    borderRadius:'10px'
  },
  spinner: {
		color: "#33cc99"
	},
  dropdownStyle:{    
    backgroundColor:'#131313',        
  },
  select: {
    "& .MuiSvgIcon-root": {
      color: "#33cc99",
    },
  },
});


class CertificateRequest extends React.Component {
	state = {
		checked: false,
    renderReCaptcha: false,
    courseSelected: null,
    certRequest: false
	};

  returnCourseImage(courseTitle){
    const { classes } = this.props; 

    let courseId=null
    let filteredCourse = CourseData.courseList.filter(course=>{
      return course.title==courseTitle
    })          
    if (filteredCourse.length>0){      
      courseId = filteredCourse[0].courseId               
    }    
    let image = returnImage(courseId, 'course')


    if (image){
      return(
        <Avatar variant="square" className={classes.avatar} src={image}/>
      )
    }
    return null
  }

	handleChange = event => {
		this.setState({
			checked: event.target.checked
		});
	};

  handleChangeCert = event => {
		this.setState({
			certRequest: event.target.checked
		});
	};

	componentDidMount() {
    //Make sure recapcha is fully loaded before trying to render it
    this.checkRecaptcha = setInterval(() => {   
      //Check if recapcha library loaded    
      if(window.grecaptcha){    
        if(window.grecaptcha.render){        
          this.setState({renderReCaptcha: true})
          clearInterval(this.checkRecaptcha);
        }  
      }     
    }, 500);

		//Reset data input errors for login form
		this.props.fetchUser();
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		this.props.clearFlash();
	}

	//Send login request
	onSubmit = values => {
    values["certRequest"] = this.state.certRequest;
		values["noConsent"] = this.state.checked;
		this.props.certificateRequest(values);
	};

  //Return course list for dropdown menu
  getCourseList = ()=>{
    // Need to return an array of objects to map over    
    let courseOptions = CourseData.courseList.map(course=>{
      return({filter:course.title}) 
    })  
    return courseOptions
  }

	//Required when combining Matrial UI <TextField /> with redux-form
	renderTextField({ errors, id, name, input, label, ...custom }) {
		let filteredErrors = [];
		if (typeof errors !== "undefined") {
			if (errors.length > 0) {
				//Identify the relevant errors
				filteredErrors = errors.filter(err => {
					return err.param === id;
				});
			}
		}

		if (typeof filteredErrors !== "undefined") {
			if (filteredErrors.length > 0) {
				for (let i = 0; i < filteredErrors.length; i++) {
					if (filteredErrors[i].param === id) {
						return (
							<TextField
                style={{
                  backgroundColor: "#13131350"
                }}
                InputProps={{
                    style: {
                        color: "#fff"
                    }
                }}  
                InputLabelProps={{
                  style:{
                    color:'red'
                  }
                }}   
                color="secondary"   
								error
								label={filteredErrors[i].msg}
								id="outlined-error"
								variant="outlined"
								fullWidth
								// autoFocus
								margin="normal"
								{...input}
								{...custom}
							/>
						);
					}
				}
			}
		}
		return (
			<TextField
        style={{
          backgroundColor: "#13131350",                     
        }}
        InputProps={{
            style: {
                color: "#fff",                          
            }
        }}
        InputLabelProps={{
          style:{
            color:'#33cc99'
          }
        }}
        color="secondary"
				required
				id={id}
				name={name}
				label={label}
				variant="outlined"
				fullWidth
				// autoFocus
				margin="normal"
				{...input}
				{...custom}
			/>
		);
	}

  //Required when combining Matrial UI <TextField/> with redux-form
	renderTextArea({ errors, id, name, input, label, isRequired, ...custom }) {    
    let filteredErrors = [];
		if (typeof errors !== "undefined") {
			if (errors.length > 0) {
				//Identify the relevant errors
				filteredErrors = errors.filter(err => {
					return err.param === id;
				});
			}
		}

    //Print error messages if there are any
    if (typeof filteredErrors !== "undefined") {
			if (filteredErrors.length > 0) {
				for (let i = 0; i < filteredErrors.length; i++) {
					if (filteredErrors[i].param === id) {
						return (
							<TextField
                style={{
                  backgroundColor: "#13131350"
                }}
                InputProps={{
                    style: {
                        color: "#fff"
                    }
                }}  
                InputLabelProps={{
                  style:{
                    color:'red'
                  }
                }}   
                color="secondary"   
								error
                multiline
                rows="6"
								label={filteredErrors[i].msg}
								id="outlined-error"
								variant="outlined"
								fullWidth
								// autoFocus
								margin="normal"
								{...input}
								{...custom}
							/>
						);
					}
				}
			}
		}

    //Return different component based on whether field is mandatory or not
    if (isRequired==true){
      return (
        <TextField
          style={{
            backgroundColor: "#13131350",                     
          }}
          InputProps={{
              style: {
                  color: "#fff",                          
              }
          }}
          InputLabelProps={{
            style:{
              color:'#33cc99'
            }
          }}
          color="secondary"			
          required	           
          id={id}
          multiline
          rows="6"
          name={name}
          label={label}
          variant="outlined"
          fullWidth
          // autoFocus
          margin="normal"
          {...input}
          {...custom}
        />
      );
    } else{
      return (
        <TextField
          style={{
            backgroundColor: "#13131350",                     
          }}
          InputProps={{
              style: {
                  color: "#fff",                          
              }
          }}
          InputLabelProps={{
            style:{
              color:'#33cc99'
            }
          }}
          color="secondary"				    
          id={id}
          multiline
          rows="6"
          name={name}
          label={label}
          variant="outlined"
          fullWidth
          // autoFocus
          margin="normal"
          {...input}
          {...custom}
        />
      );
    }
		
	}

  //Required when combining Matrial UI <TextField/> with redux-form
	renderTextSelect({ options, value, id, name, input, label, ...custom }) {

		return (
			<TextField  
        style={{
          backgroundColor: "#13131350",                     
        }} 
        InputProps={{
          style: {            
            color:'#fff',                        
          }
        }}
        InputLabelProps={{
          style:{
            color:'#33cc99',                       
          }
        }}
        color="secondary"             
				value={value}        
				select
        variant="outlined"
				id={id}
				name={name}
				label={label}
				fullWidth
        margin="normal"
				{...input}
				{...custom}
			>
				{options.map(option => (
					<MenuItem 
            key={option.filter} 
            value={option.filter} 
            style={{color:"#fff"}}           
          >
            {option.filter}
					</MenuItem>
				))}
			</TextField>
		);
	}

  //Update state when course selection is changed
  onChangeCourse = (newCourse) => {       
    this.setState({courseSelected:newCourse.target.value})        
  }

	// Return error message if calidation error returned
	returnErrorText(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return (
						<Typography variant="body2" style={{ color: colorRed }}>
							{errors[i].msg}
						</Typography>
					);
				}
			}
		}
		return;
	}

  //Return recapcha component after a specified time delay
  returnReCaptcha(){
    const { classes } = this.props; //Destructure data off the components props
    if(this.state.renderReCaptcha){
      return(
        <Field type="text" name="captcharesponse" component={Captcha} />
    );
    } else{
      return(         
        <Grid container justify="center" spacing={4}>
          <Grid item xs={10} sm={10} md={12} style={{display:"flex"}}>
            <CircularProgress className={classes.spinner} />
            <Typography component="h1" style={{marginLeft:"25px", marginTop:"10px"}}>
              Waiting for recaptcha...🤖
            </Typography>
          </Grid>
        </Grid>
        
      );
    }    
  }

	//Return label for the consent
	returnLabel = () => {
		return (
			<Typography variant="body2" style={{color:"#8e8e8e"}}>
				Please tick if you would like your review published anonymously on DegreeTutors.com.
			</Typography>
		);
	};

  //Return label for the cert request check box
	returnLabelCertReq = () => {
		return (
			<Typography variant="body2" style={{color:"#8e8e8e"}}>
				I have completed the course and would like my certificate of completion
			</Typography>
		);
	};

	render() {
		const { classes } = this.props; //Destructure data off the components props
		return (
			<React.Fragment>
				<LayoutPublic />
				<Particles className={classes.particleBackground} params={particleConfig} />
				<Feedback />

				<Grid container justify="center" spacing={4}>
					<Grid item xs={10} sm={10} md={8} lg={6}>
						<CssBaseline />
						
            <div className={classes.outsidePaper}>
            <Paper className={classes.paper} elevation={2}>
								                
                	
								<Typography component="h1" variant="h5" style={{marginTop:"50px"}}  >
									COURSE REVIEW / CERTIFICATE OF COMPLETION
								</Typography>
                                
                {this.returnCourseImage(this.state.courseSelected)}

                <div style={{ padding: "20px" }}>
                  <Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
                    To request your course completion certificate, please complete and submit the form below.  
                  </Typography>
                  <Typography variant="body2" align="center" style={{color:"#8e8e8e"}}>
                    Please allow up to 24 hours for delivery and remember to check your junk email folder. 
                  </Typography>
                  
                </div>       

								<form
                  style={{marginTop:"50px"}}  
									noValidate
									onSubmit={this.props.handleSubmit(
										values => this.onSubmit(values) //wrapped in arrow function so it won't be called on initial load
									)}
								>
									<Grid container justify="center">
										<Grid item xs={11} sm={11}>
											<Field
												name="username"
												id="username"
												label="Name"
												errors={this.props.errors}
												component={this.renderTextField}
											/>

											<Field
												name="email"
												id="email"
												label="Email Address (same as your Podia sign in email)"
												errors={this.props.errors}
												component={this.renderTextField}
											/>

                      <Field          
                        name="course"
                        id="course"
                        label="Course"                             
                        options={this.getCourseList()}
                        component={this.renderTextSelect}                                              
                        onChange={this.onChangeCourse}                                      
                        className={classes.select} 
                        SelectProps={{                     
                          MenuProps:{ 
                            classes: { 
                              paper: classes.dropdownStyle } }
                        }}
                      />
                      {this.returnErrorText("course")}

                      <Field          
                        name="rating"
                        id="rating"
                        label="Rating (👍 = 5, 👎 = 1)"                             
                        options={optionsRatings}
                        component={this.renderTextSelect}                 
                        className={classes.select} 
                        SelectProps={{                     
                          MenuProps:{ 
                            classes: { 
                              paper: classes.dropdownStyle } }
                        }}
                      />
                      {this.returnErrorText("rating")}

                      <Field
												name="review"
												id="review"
												label="Review"
                        placeholder="Please tell us what you enjoyed about the course"
												errors={this.props.errors}
                        isRequired={true}
												component={this.renderTextArea}
											/>

                      <Field
												name="suggestion"
												id="suggestion"
												label="Any other suggestions?"
                        placeholder="Is there anything you’d suggest we change or improve?"
												errors={this.props.errors}
                        isRequired={false}
												component={this.renderTextArea}
											/>

                      <Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "14px" }}>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.certRequest}
																onChange={this.handleChangeCert}
																value="primary"
                                style={{color:"#33cc99"}}
															/>
														}
														label={this.returnLabelCertReq()}
													/>
													{/* {this.returnErrorText("tos")} */}
												</Grid>
											</Grid>

											                     

											<Grid container>
												<Grid item xs={12} sm={12} >
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.checked}
																onChange={this.handleChange}
																value="primary"
                                style={{color:"#33cc99"}}
															/>
														}
														label={this.returnLabel()}
													/>
													{/* {this.returnErrorText("tos")} */}
												</Grid>
											</Grid>

                      <Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "14px" }}>
                          {this.returnReCaptcha()}	
													{this.returnErrorText("captcharesponse")}
												</Grid>
											</Grid> 

											<Grid container>
												<Grid item xs={12} sm={12} style={{ marginTop: "50px" }}>
													<Button
                            // disabled
														size="large"
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														// style={{ color: "white" }}
														className={classes.submit}
                            style={{ color: "white", backgroundColor:"#f13f64" }}
													>
														Submit Review / Certificate Request
                          
													</Button>
												</Grid>
											</Grid>
											
										</Grid>
									</Grid>
                  <Copyright />
								</form>							
              </Paper>
            </div>													
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

//Create a formWrapped version of Login component
const formWrapped = reduxForm({
	form: "certificateForm"
})(CertificateRequest);

function mapStateToProps(state) {
	return { errors: state.errors };
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(formWrapped));
