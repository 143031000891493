import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_3 extends React.Component {
  state={
    course: 19,
    lecture: 3, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The first part of this course will focus exclusively on the core theory and manual calculations required to design reinforced concrete. In this way, the course follows a somewhat traditional path; we’ll introduce the core theory and mechanics and then work through some design exercises with <i>pen and paper</i>. If Python scripting doesn’t interest you, no problem! Simply focus on the first part of the course, and you’ll have covered all of the reinforced concrete content. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On the other hand, if you’re interested in sprinkling some Python magic over your manual calculations, you’ll want to stay tuned for the final section of the course. Once the theory has been covered in the first four sections of the course, we’ll focus on automating some of the calculations from this part of the course. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Much like other DegreeTutors courses, we’ll use Python within a Jupyter Notebook environment to do this. You don’t need any Python background knowledge to complete this part of the course, just some patience and a willingness to learn.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Getting started with Python </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If you're new to Python, your first task will be to get a development environment up and running. I've covered this in previous courses, so at this point I send you on a short detour to those lectures...  
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>
                      Start here with '<Link to="/course-11/lecture-2" className={classes.link} target="_blank">Our Approach to Python</Link>', everything I say there, also applies here.    
                    </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                      Then work through '<Link to="/course-11/lecture-3" className={classes.link} target="_blank">Getting started with Jupyter Notebooks</Link>'.
                    </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>
                      And finally, make the jump to Jupyter Lab, the environment I currently recommend, by watching, '<Link to="/course-18/lecture-3" className={classes.link} target="_blank">Moving to JupyterLab</Link>'.  
                    </Typography></li>
                  </ul>                 
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_3));
