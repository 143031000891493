import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-20/img1.png";

const Eqn1 = `\\begin{align}
F_{st} &= F_{cc} + F_{sc}\\\\\\\\
0.87\\times 500\\times 1960 &= (0.567\\times 25\\times 300\\times 0.8 x) + (0.87\\times 500\\times 603)\\\\\\\\
x&=174\\:mm
\\end{align}`

const Eqn2 = `\\begin{align}
\\frac{x}{d} &= 0.233\\:<\\:0.617\\:\\:\\therefore \\text{Tension steel will have yielded}\\\\\\\\
\\frac{d'}{x} &= 0.316\\:<\\:0.38\\:\\:\\therefore \\text{Compression steel will have yielded}
\\end{align}`

const Eqn3 = `\\begin{align}
M_{Rd} &= \\overbrace{(0.567\\times 25\\times 300\\times 174\\times 0.8)}^{F_{cc}}\\:\\overbrace{(747-0.4\\times 174)}^{\\text{lever arm}} + \\overbrace{(0.87\\times 500 \\times 603)}^{F_{sc}}\\:\\overbrace{(747-55)}^{\\text{lever arm}}\\\\
&=401+181.5\\\\\\\\
&=582.5\\:kNm
\\end{align}`

const Eqn4 = `\\begin{align}
0.567\\:f_{ck}\\:0.8x\\:b &= 0.87\\:f_{yk}\\:A_s\\\\\\\\
0.567\\times 25\\times 0.8x\\times 300 &= 0.87\\times 500\\times 1960\\\\\\\\
\\therefore x &=250.6\\:mm
\\end{align}`

const Eqn5 = `\\begin{align}
F_{st} &= 0.87\\:f_{yk}\\:A_s\\\\\\\\
F_{st} &= 852.6\\:kN
\\end{align}`

const Eqn6 = `\\begin{align}
M_{Rd} &= 852.6\\times (d-0.4x)\\\\\\\\
M_{Rd} &= 852.6\\:kN\\times 0.6468\\:m\\\\\\\\
M_{Rd} &= 551.5\\:kNm
\\end{align}`

const Eqn7 = `\\begin{align*}
F_{st} &= F_{cc} + F_{sc}\\\\\\\\
0.87\\times 500\\times A_s &= (0.567\\times 25\\times 300\\times 0.8 \\times 336) + (0.87\\times 500\\times 603)\\\\\\\\
A_s&=3232\\:mm^2
\\end{align*}`

const Eqn8 = `\\begin{align*}
M_{Rd} &= (0.567\\times 25\\times 300\\times 336\\times 0.8)(747-0.4\\times 336) + (0.87\\times 500 \\times 603)(747-55)\\\\
&=700.5+181.5\\\\
&=882\\:kNm
\\end{align*}`

const Eqn9 = `\\begin{equation}
\\frac{A_s}{A_c}\\leq 0.04
\\end{equation}`


class Lecture_19_21 extends React.Component {
  state={
    course: 19,
    lecture: 21, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography component="h2" className={classes.H2} > 1.0 Question  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The last example question demonstrated the typical design workflow, i.e. determining the required reinforcement to deliver a specified moment capacity. In this example, we'll work in reverse and determine the moment of resistance provided by a specified arrangement of reinforcement.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider the doubly reinforced beam cross-section in Fig. 1. Determine <MathJax.Node inline formula={"M_{Rd}"} /> and assume <MathJax.Node inline formula={"f_{ck} = 25\\: N/mm^2"} /> and <MathJax.Node inline formula={"f_{yk} = 500\\: N/mm^2"} />.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Doubly reinforced concrete section.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    First, we determine the depth of the neutral axis by considering force equilibrium for the section. As usual, we make the initial assumption that the tension and compression steel have yielded at the ultimate limit state. Noting that the tensile and compression steel areas are <MathJax.Node inline formula={"1960\\:mm^2"} /> and <MathJax.Node inline formula={"603\\:mm^2"} /> respectively.
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we need to confirm that our assumptions about yielding are correct:
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If the compression or tension steel had not yielded at the point that the compression block failed, then we would iterate through different possible values of <MathJax.Node inline formula={"x"} /> until a neutral axis depth is identified that allows force equilibrium to be achieved (as seen earlier). Having identified the neutral axis position, the geometry of the strain distribution diagram could be used to determine the steel strains at section failure. Then, the corresponding steel stresses are easily identified. Note, however, that if the tension steel has not yielded (when the concrete strain reaches its max value of <MathJax.Node inline formula={"0.0035"} />), then the section will be over-reinforced and undergo a brittle failure. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we can take moments about the line of action of the tension steel to determine the moment of resistance of the section,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This is the solution we were looking for - the moment of resistance when the compression reinforcement is taken into consideration. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} >2.1 Ignoring compression reinforcement </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    When concrete beams are being constructed, the reinforcement is usually constructed as a cage with longitudinal top and bottom bars. A typical beam that has been designed as singly reinforced will therefore very often contain bars that will behave as compression reinforcement, regardless of whether the design requires them. Thus the in-situ beam may have a higher bending resistance than originally intended. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To illustrate the point, let's now neglect the influence of compression reinforcement and design this beam as a   singly reinforced beam. Equating the forces in the section to identify the neutral axis,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We can confirm that this is less than <MathJax.Node inline formula={"0.45\\:d"} /> and so the beam is under-reinforced and the steel will fail first. The force in the steel at failure is therefore,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This is multiplied by the lever-arm in the usual way to obtain the moment of resistance,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, we have two different moment capacities for the same beam, depending on whether or not the steel provided in the compression zone is taken into account in the design.
                  </Typography> 

                  <Typography component="h3" className={classes.H2} > 2.2 Maximum capacity for ductile failure </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    One final question that you may be asking is, if we have 3 H16 bars as compression reinforcement (essentially strengthening the compression region), how much more tension steel could we add, and still achieve a ductile failure? And, what would the bending resistance be?
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we consider the force equilibrium equation again and this time set the neutral axis depth to its maximum allowable value of <MathJax.Node inline formula={"x=0.45d = 336 \\:mm"} />, we can solve for the area of tensile reinforcement to maintain equilibrium,  
                  </Typography> 

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can calculate <MathJax.Node inline formula={"M_{Rd}"} /> as above:
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We ought to confirm that we haven't contravened the upper limit on the area of reinforcement that can be provided,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The limit equates to <MathJax.Node inline formula={"4\\%"} /> of the gross section area which in this case is <MathJax.Node inline formula={"9600\\:mm^2"} />, so we're safely within the limit in this case. 
                  </Typography>                      
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_21));
