import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-8/img1.png";
import img2 from "../../../images/Lectures/C19-8/img2.png";


class Lecture_19_8 extends React.Component {
  state={
    course: 19,
    lecture: 8, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Serviceability limit states correspond to structural conditions that are deemed to be undesirable rather than catastrophic, e.g. if a floor vibrates too much (beyond specified limits), the floor won't collapse but is likely to cause a nuisance. Therefore it exceeds a serviceability limit state. Similarly, if a concrete slab deflects to a point where say, ceiling finishes on the underside begin to crack noticeably, we might conclude that the concrete slab, while still safe and within the ultimate limit state, has exceeded a serviceability limit state. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The Eurocode specifies three action combinations that can be applied to a structure and used to test if a serviceability limit state is likely to be exceeded:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}><b>The characteristic combination</b>: Used when considering damage to structural and non-structural elements (e.g. ceilings or partition walls). </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><b>The frequent combination</b>: Used when considering comfort criteria for users, criteria associated with the use of machinery, criteria for the avoidance of water ponding, etc. </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}><b>The quasi-permanent combination</b>: Used when considering the appearance of the structure, most often used in the evaluation of long-term deflections.</Typography></li>
                  </ul>  

                  <Typography paragraph className={classes.bodytext}>The partial factors for all serviceability combinations are equal to one. However, these combinations do make use of the <MathJax.Node inline formula={"\\psi"} /> factors we saw in a previous lecture, repeated below in Fig. 1. </Typography>                
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Values of <MathJax.Node inline formula={"\\psi"} /> factors for buildings.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The application of these <MathJax.Node inline formula={"\\psi"} /> factors is summarised in the table in Fig. 2 below.
                  </Typography> 
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Partial factors and combinations for characteristic, frequent and quasi-permanent serviceability limit states.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Testing whether or not a serviceability limit state has been exceeded is the same as for an ultimate limit state. The relevant applied loading is constructed using the combinations and factors from the tables above. Then, the structure’s response to this loading is judged against some limit criteria, such as a deflection limit. Provided the limit is not exceeded, the structure can be deemed not to have exceeded a serviceability limit state.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    For the remainder of this course, we will focus on ULS design. However, it's important at this stage to be aware of the requirement to perform SLS design checks.
                  </Typography>                  
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_8));
