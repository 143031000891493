import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const Eqn1 = `\\begin{equation}
F_C = F_S\\\\\\\\
\\end{equation}`

const Eqn2 = `\\begin{align*}
\\overbrace{0.567\\:f_{ck}}^\\text{Stress}\\:\\overbrace{0.8x\\:b}^\\text{Area} &= \\overbrace{0.87\\:f_{yk}}^\\text{Stress}\\:\\overbrace{A_s}^\\text{Area}\\\\\\\\
0.567\\times 30 \\times 0.8x\\times 400 &= 0.87\\times 500\\times 1960
\\end{align*}`

const Eqn3 = `\\begin{align*}
F_S &= 0.87\\times 500\\times 1960\\\\\\\\
&= 852.6\\:kN
\\end{align*} `

const Eqn4 = `\\begin{equation}
z=d-0.4x=587\\:mm
\\end{equation}`

const Eqn5 = `\\begin{align}
M_{Rd} &= 852.6\\:kN\\times 0.587\\:m \\\\\\\\
&= 500.5\\:kNm
\\end{align}`



class Lecture_19_16 extends React.Component {
  state={
    course: 19,
    lecture: 16, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >          

                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>        
                  
                  <Typography paragraph className={classes.bodytext}>
                    Having designed a reinforced concrete cross-section for a given design moment in the previous example, it's now a relatively straightforward task to determine the ultimate moment of resistance for a cross-section with a pre-defined area of reinforcing steel. Therefore, consider a reinforced concrete cross-section with width <MathJax.Node inline formula={"b = 400\\: mm"} />, height <MathJax.Node inline formula={"h = 700\\: mm"} /> and effective depth <MathJax.Node inline formula={"d = \\:650 mm"} />. It's reinforced with <MathJax.Node inline formula={"4"} /> H25 bars <MathJax.Node inline formula={"(1960\\:mm^2)"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Determine the ultimate moment of resistance, <MathJax.Node inline formula={"M_{Rd}"} />, assuming <MathJax.Node inline formula={"f_{ck} = 30 \\:N/mm^2"} /> and <MathJax.Node inline formula={"f_{yk} = 500\\: N/mm^2"} />. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first task is to determine the position of the neutral axis at the ultimate limit state. To do this, we simply equate the force in the steel, <MathJax.Node inline formula={"F_S"} />, with the force in the concrete, <MathJax.Node inline formula={"F_C"} />, at the point of failure. 
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We're assuming that the steel will yield before the concrete crushes. Therefore, the point of failure is when the steel reaches its design yield capacity. Equating the forces we have,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore we can solve for <MathJax.Node inline formula={"x"} />, which yields <MathJax.Node inline formula={"x=157 \\:mm"} />. Remember, Eurocode 2 limits the neutral axis depth to <MathJax.Node inline formula={"0.45d = 293\\: mm"} />. Since the neutral axis depth at the ultimate limit state is less than <MathJax.Node inline formula={"0.45d"} />, we can conclude that the beam is <i>under-reinforced</i> and failure at the ultimate limit state would indeed be ductile. Therefore the force in the steel at the point of failure is equal to the force required to cause yielding (our assumption was correct):
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    If we assume that the compression stress resultant, <MathJax.Node inline formula={"F_C"} />, acts halfway down the rectangular compression block, the lever arm can be calculated as, 
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, the ultimate moment of resistance is,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This solution was quite simple because we were able to assume (and then confirm) that the steel failed first and that the cross-section was under-reinforced (ductile failure). In the next example, we'll consider how to analyse the ultimate moment capacity of an over-reinforced section.
                  </Typography> 
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_16));
