//Public header
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../../actions/authActions";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import logoWhite from "../../images/Landing/Banner_DTLabs.png";
import logoWhite from "../../images/Landing/engineering-skills-logo-dark.png";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";

//Media Queries
const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1281 });
	return isDesktop ? children : null;
};
const Laptop = ({ children }) => {
	const isLaptop = useMediaQuery({ minWidth: 992, maxWidth: 1280 });
	return isLaptop ? children : null;
};
const Tablet = ({ children }) => {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	return isTablet ? children : null;
};
const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile ? children : null;
};

//Custom styling
const styles = theme => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	menuLink: {
		color: "#fff",
		"&:hover": {
			color: theme.palette.coral.main
		}
	},
	menuLinkSignin: {
		color: theme.palette.coral.main,
		"&:hover": {
			color: "#fff"
		}
	},
	menuLinkMobile: {
		color: "#2db39f",
		"&:hover": {
			color: "#2db39f"
		}
	},
	title: {
		flexGrow: 1,
		color: "#33cc99",
		"&:hover": {
			color: "#33cc99"
		},
		fontSize: "50px"
	},
	logoAvatar: {
		width: 200,		
    marginRight: "-10px",
    marginTop:'5px'
  },
  titleMobile: {
		flexGrow: 1,
		color: "#33cc99",
		"&:hover": {
			color: "#33cc99"
		},
		fontSize: "35px"
	},
  logoAvatarMobile:{
    width: 250,		
    marginRight: "-10px",
    marginTop:'5px'
  }
});

class LayoutPublic extends React.Component {
	state = {
		anchorEl: null
	};

	//Used for mobile responsive menu
	handleClick = event => {
		this.setState({
			anchorEl: event.currentTarget
		});
  };
  
  //Used for mobile responsive menu
	handleClose = () => {
		this.setState({
			anchorEl: null,
		});
	};

	//Function to return contents based on login state
	returnHeaderContents = () => {
		const { classes } = this.props; //Destructure data off the components props
		switch (this.props.auth) {
			case null:
				return;
			case false:
				return (
					<React.Fragment>					
						<Link to="/" className={classes.title}>
              <img src={logoWhite} alt="DegreeTutors:Labs" className={classes.logoAvatar}/>
						</Link>

						{/* <Button className={classes.menuButton}>
							<Link to="/features" className={classes.menuLink}>
								Features
							</Link>
						</Button>

						<Button className={classes.menuButton}>
							<Link to="/casestudy" className={classes.menuLink}>
								Case study
							</Link>
						</Button>

						<Button className={classes.menuButton}>
							<Link to="/requestdemo" className={classes.menuLink}>
								Demo
							</Link>
						</Button>

						<Button className={classes.menuButton}>
							<Link to="/pricing" className={classes.menuLink}>
								Pricing
							</Link>
						</Button>

						<Button className={classes.menuButton}>
							<Link to="/register" className={classes.menuLink}>
								Free Trial
							</Link>
						</Button> */}

            {/* <Button className={classes.menuButton} style={{ marginRight: "0px" }}>
							<Link to="/degreetutors-home/" className={classes.menuLinkSignin}>
								Home
							</Link>
						</Button> */}

						<Button className={classes.menuButton} style={{ marginRight: "0px" }}>
							<Link to="/login" className={classes.menuLinkSignin}>
								Sign In
							</Link>
						</Button>
					</React.Fragment>
				);
			default:
				return (
					<React.Fragment>						
            
						<Link to="/" className={classes.title}>
              <img src={logoWhite} alt="DegreeTutors:Labs" className={classes.logoAvatar}/>
						</Link>

						<Avatar
							alt={this.props.auth.username}
							src={this.props.auth.avatar}
							className={classes.avatar}
							style={{ marginRight: "10px" }}
						/>
						<Typography
							variant="button"
							className={classes.menuLink}
							style={{ marginRight: "25px" }}
						>
							Signed in as {this.props.auth.username}
						</Typography>

						<Button className={classes.menuButton}>
							<Link to="/courses" className={classes.menuLink}>
								Courses
							</Link>
						</Button>

						<Button onClick={this.props.logoutRequest} className={classes.menuLink}>
							Sign Out
						</Button>
					</React.Fragment>
				);
		}
	};

	//Function to return contents based on login state but using mobile friendly dropdown
	returnHeaderContentsMobile = () => {
		const { classes } = this.props; //Destructure data off the components props
    
		switch (this.props.auth) {
			case null:
				return;
			case false:
				return (
					<React.Fragment>
						
						<Link to="/" className={classes.titleMobile}>
              <img src={logoWhite} alt="DegreeTutors:Labs" className={classes.logoAvatarMobile}/>
						</Link>

						<Button
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={this.handleClick}
						>
							<Icon path={mdiMenu} title="Menu" size={1.5} color={"white"} />
						</Button>
						{/* <Menu
							id="simple-menu"
							anchorEl={this.state.anchorEl}
							keepMounted
							open={Boolean(this.state.anchorEl)}
							onClose={this.handleClose}
						>
							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/features" className={classes.menuLinkMobile}>
										Features
									</Link>
								</Typography>
							</MenuItem>

							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/casestudy" className={classes.menuLinkMobile}>
										Case study
									</Link>
								</Typography>
							</MenuItem>

							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/requestdemo" className={classes.menuLinkMobile}>
										Demo
									</Link>
								</Typography>
							</MenuItem>

							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/pricing" className={classes.menuLinkMobile}>
										Pricing
									</Link>
								</Typography>
              </MenuItem>
              
              <MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/login" className={classes.menuLinkMobile}>
										Sign In
									</Link>
								</Typography>
							</MenuItem>

							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/login" className={classes.menuLinkMobile}>
										Sign In
									</Link>
								</Typography>
							</MenuItem>
						</Menu> */}
            <Menu
							id="simple-menu"
							anchorEl={this.state.anchorEl}
							keepMounted
							open={Boolean(this.state.anchorEl)}
							onClose={this.handleClose}
						>							
							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
                <Link to="/degreetutors-home/" className={classes.menuLinkSignin}>
										HOME
									</Link>
								</Typography>
							</MenuItem>
						</Menu>
					</React.Fragment>
				);
			default:
				return (
					<React.Fragment>					
						<Link to="/" className={classes.titleMobile}>
						  <img src={logoWhite} alt="DegreeTutors:Labs" className={classes.logoAvatarMobile}/> 
						</Link>

						<Button
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={this.handleClick}
						>
							<Icon path={mdiMenu} title="Menu" size={1.5} color={"white"} />
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={this.state.anchorEl}
							keepMounted
							open={Boolean(this.state.anchorEl)}
							onClose={this.handleClose}
						>
							<MenuItem onClick={this.handleClose}>
								<Typography
									variant="button"
									className={classes.menuLinkMobile}
									style={{ marginRight: "25px" }}
								>
									Signed in as {this.props.auth.username}
								</Typography>
								<Avatar
									alt={this.props.auth.username}
									src={this.props.auth.avatar}
									className={classes.avatar}
									style={{ marginRight: "10px" }}
								/>
							</MenuItem>

							<MenuItem onClick={this.handleClose}>
								<Typography variant="button">
									<Link to="/dashboard" className={classes.menuLinkMobile}>
										Dashboard
									</Link>
								</Typography>
							</MenuItem>

							<MenuItem onClick={this.props.logoutRequest}>
								<Typography variant="button" className={classes.menuLinkMobile}>
									Sign Out
								</Typography>
							</MenuItem>
						</Menu>
					</React.Fragment>
				);
		}
	};

	render() {
		const { classes } = this.props; //Destructure data off the components props

		return (
			<div className={classes.root}>
				<AppBar
					position="static"
					style={{ background: "transparent", boxShadow: "none" }}
				>
					<Grid container justify="center">
						<Grid item xs={12} sm={10} md={10}>
							<Desktop>
								<Toolbar style={{ padding: "0px" }}>
									{this.returnHeaderContents()}
								</Toolbar>
							</Desktop>
							<Laptop>
								<Toolbar>{this.returnHeaderContentsMobile()}</Toolbar>
							</Laptop>
							<Tablet>
								<Toolbar>{this.returnHeaderContentsMobile()}</Toolbar>
							</Tablet>
							<Mobile>
								<Toolbar>{this.returnHeaderContentsMobile()}</Toolbar>
							</Mobile>
							{/* <Toolbar>{this.returnHeaderContents()}</Toolbar> */}
						</Grid>
					</Grid>
				</AppBar>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { auth: state.auth };
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(LayoutPublic));
