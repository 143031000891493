import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-35/img1.jpg";


const codeString = 
`def plotResult(label_offset=0.02, 
               xMargin=0.2, 
               yMargin=0.2, 
               scaleFactor=1,  
               scaleDivs=10, #🚨
               Load_Increment=100,
               Final_config=True):
        
    #Select the correct load increment to display
    if Final_config:
        #Select final load increment
        Load_Increment = -1 
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten()
    else:
        #Load increment selected based on slider
        ug = UG_FINAL[:,Load_Increment]
        fi = FI_FINAL[:,Load_Increment]
        forceVector = EXTFORCES[:,Load_Increment]
        mbrForces = np.array(MBRFORCES[:,Load_Increment]).flatten()
    
    fig = plt.figure() 
    axes = fig.add_axes([0.1,0.1,3,3]) 
    fig.gca().set_aspect('equal', adjustable='box')
    
    
    #Plot original nodal positions and node number labels
    for n, node in enumerate(nodes):
        axes.plot([node[0]],[node[1]],'o', color='#33cc99') 
        label = str(n+1) #The node number label string
        axes.text(node[0]+label_offset, node[1]+label_offset, label, fontsize=16) 
    
    
    #Create color scale for member forces
    if(mbrForces.min(0)<0 and mbrForces.max(0)<0): 
        #All member forces are compression
        norm = matplotlib.colors.TwoSlopeNorm(vmin = mbrForces.min(0), 
                  vcenter = mbrForces.min(0)+0.5*(mbrForces.max(0)-mbrForces.min(0)),
                  vmax = mbrForces.max(0))
        cmap = plt.cm.Reds_r #Define color scale to use
        #Define max a min forces for the colorbar
        cBarMaxForce = 0
        cBarMinForce = round(0.99*mbrForces.min(0)) 
        
    elif(mbrForces.min(0)>0 and mbrForces.max(0)>0):
        #All member forces are tension
        norm = matplotlib.colors.TwoSlopeNorm(vmin=0, 
                  vcenter=mbrForces.min(0)+0.5*(mbrForces.max(0)-mbrForces.min(0)),
                  vmax=mbrForces.max(0))#set midpoint of colormap to zero
        cmap = plt.cm.Blues #Define color scale to use
        #Define max a min forces for the colorbar
        cBarMaxForce = round(0.99*mbrForces.max(0))
        cBarMinForce = 0
        
    else:
        #Tension and compression member forces develop
        norm = matplotlib.colors.TwoSlopeNorm(vmin=mbrForces.min(0), 
                  vcenter=0, 
                  vmax=mbrForces.max(0))#set midpoint of colormap to zero
        cmap = plt.cm.seismic_r #Define color scale to use
        #Define max a min forces for the colorbar
        cBarMaxForce = round(0.99*mbrForces.max(0))
        cBarMinForce = round(0.99*mbrForces.min(0))  
    
    #Add colorbar to figure and format    
    sm = plt.cm.ScalarMappable(cmap=cmap, norm=norm)    

    #🚨 Format colorbar scale
    N = scaleDivs    
    delta = 100*round(((cBarMaxForce - cBarMinForce)/N)/100) #Round to 0.1 kN      
    if delta<1:
        delta = round(((cBarMaxForce - cBarMinForce)/N))
               
    ticks=list(np.round(np.arange(cBarMinForce, cBarMaxForce, delta)))
    cbar = fig.colorbar(sm, ticks=ticks, orientation='horizontal')
    cbar.ax.set_xlabel('Axial Force (kN)', fontsize = 16, weight='bold')  
    cbar.ax.set_xticklabels(['{:.2f}'.format(x/1000) for x in ticks], 
                        fontsize=16, 
                        weight='bold') 
    
    
    #Plot members
    for n, mbr in enumerate(members):  
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member   

        ix = nodes[node_i-1,0] #x-coord of node i of this member
        iy = nodes[node_i-1,1] #y-coord of node i of this member
        jx = nodes[node_j-1,0] #x-coord of node j of this member
        jy = nodes[node_j-1,1] #y-coord of node j of this member

        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member
                
        #Plot each member in undeformed position
        axes.plot([ix,jx],[iy,jy],color='#33cc99',linestyle='--',lw=2) 
        
    #Housekeeping - set axis limits to provide margin around structure
    maxX = nodes.max(0)[0]
    maxY = nodes.max(0)[1]
    minX = nodes.min(0)[0]
    minY = nodes.min(0)[1]
    axes.set_xlim([minX-xMargin,maxX+xMargin])
    axes.set_ylim([minY-yMargin,maxY+yMargin])
    axes.set_xlabel('Distance (m)', fontsize=16)
    axes.set_ylabel('Distance (m)', fontsize=16)
    if reactionsFlag:
        axes.set_title('Deflected shape, axial forces & reactions 
        (All SW added to reactions - member forces ignore half of 
        SW for members connected to supports)', 
        fontsize=16, weight='bold')
    else:
        axes.set_title('Deflected shape, axial forces & reactions 
        (Half of SW of members connected to supports not added to reactions)',
        fontsize=16, weight='bold')
    axes.grid()
                
#Call the interact widget
rows, convergedIncs = UG_FINAL.shape
widgets.interact(plotResult, 
                 label_offset=(0.01, 0.1, 0.01), 
                 xMargin=(0.2, 5, 0.2),
                 yMargin=(0.2, 5, 0.2),
                 scaleFactor=(1,5,0.5), 
                 scaleDivs=(5,30,1), #🚨
                 Load_Increment=(10,convergedIncs-1,1))
plt.show()`


class Lecture_17_35 extends React.Component {
  state={
    course: 17,
    lecture: 35, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll build the colour scale that will be used to indicate the axial force in each element. Again note that the new elements of code are highlighted below with a 🚨 icon.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The first update is to the list of control widgets; we add a new slider called <code className={classes.code}>scaleDivs</code> which will control the number of divisions on our colour scale. This update is made on line 5, passing the argument with a default value and on line 130 where the widget is defined. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, on lines 37 to 65 we'll define the parameters used to construct the colour scale. These will differ depending on the limits of the colour scale. There are three possible scenarios;
                  </Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}>All members are in compression; this would mean that the maximum and minimum values of the scale are both negative</Typography></li>
                    
                    <li><Typography paragraph className={classes.bodytext}>All members are in tension; in this case, the maximum and minimum values of the colour scale will be positive</Typography></li>
                    
                    <li><Typography paragraph className={classes.bodytext}>If there are both tension and compression elements, then the minimum value of the colour scale will be negative, and the maximum value will be positive. </Typography></li>
                  </ol>

                  <Typography paragraph className={classes.bodytext}>
                    Depending on which scenario we're dealing with, we'll need to construct a different normalisation to map our axial force values onto the colour scale. We normalise the axial force data using the <code className={classes.code}>TwoSlopeNorm</code> function, which requires us to set a mid-point and two outer limits for our normalised data.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We also define a colour map to use in the scale, reds for all compression, blues for all tension and transition from red to blue when we have compression and tension forces developing in the structure.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In this block, we also define limits for <code className={classes.code}>cBarMaxForce</code> and <code className={classes.code}>cBarMinForce</code>. These will be used to construct the tick values for the colour bar. We're defining these as <MathJax.Node inline formula={"99\\%"} /> of the actual max and min forces to avoid edge case errors where the number of ticks generated is mismatched by one from the number of tick labels generated. This is a tricky error to deal with as it only presents itself on occasion. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On line 68, we define object <code className={classes.code}>sm</code> using <code className={classes.code}>ScalarMappable</code>, which allows us to map the normalised (using <code className={classes.code}>norm</code> defined previously) force data to RGB colour values. With <code className={classes.code}>sm</code> defined, we can now add a colour bar to the plot (line 77), that maps the smallest force value to one end of the colour bar and the max value to the other end. The remaining code between lines 71 and 81 just adds and formats tick values for the colour bar. Note that we're making use of <code className={classes.code}>scaleDivs</code> (controlled by our widget slider) on line 71, to directly control the number of tick values on the colour bar. 
                  </Typography>                     
                  
                  <CodeBlock>{codeString}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Executing the code produces the same plot we saw in the previous lecture, but now we also have a colour bar that maps to axial forces that develop in the structure. We can also adjust the number of scale divisions using the <code className={classes.code}>scaleDivs</code> parameter. In the next lecture, we'll add the deformed structure to our plot.
                  </Typography>

                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Plot of the undeformed structure and axial force colour scale.</figcaption>
                </figure>
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_35));
