import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-48/img1.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1 = 
`import bpy
import csv
import numpy as np
import bmesh
obdata = bpy.context.object.data
bm = bmesh.from_edit_mesh(obdata)`

const codeString2 = 
`cables = np.empty((0,2),int)
for e in bm.edges:
    if e.select:
        cable = np.array([e.verts[0].index+1, e.verts[1].index+1])
        cables = np.append(cables,[cable], axis=0)`

const codeString3 = 
`#Export edge/element definitions to CSV file 
filename = "/Users/Sean/Desktop/Cables.csv"
# writing to csv file  
with open(filename, 'w') as csvfile:      
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(cables) # writing the data rows `


class Lecture_17_48 extends React.Component {
  state={
    course: 17,
    lecture: 48, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Within our models, we need to be able to differentiate between cable elements, which can only resist tension and bar elements, which can resist tension and compression. We haven't implemented this yet, but eventually, we'll distinguish between these different element types in our solver. So, in this lecture, we'll select the elements that we want to define as cables and export their definitions to a csv file called <i>Cables.csv</i>. By default, any element not defined explicitly as a cable will be considered a bar element. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll write this code in a second script file called <i>2_Cables</i>. The code to export cable definitions is almost identical to what we previously wrote for element definitions. However, this time we need to be able to select individual elements within the model, and we do this in <i>Edit</i> mode rather than <i>Object</i> mode. Therefore we need to make use of <code className={classes.code}>bmesh</code> a tool that gives us access to the object's mesh data in <i>Edit</i> mode.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can start by selecting the model in the 3D view and making sure we're in <i>Edit</i> mode (press <i>Tab</i> to switch between <i>Edit</i> and <i>Object</i> mode). Once in <i>Edit</i> mode, we can select the elements that we want to define as cables, Fig 1.
                  </Typography>
                                                   
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Elements to be defined and exported as cables.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    With these elements selected, we can switch over to the scripting layout, generate a new Python script called <i>2_Cables</i> and start coding. We start by importing the usual libraries and defining <code className={classes.code}>obdata</code> as we did before. However, now we define a new variable <code className={classes.code}>bm</code>, using <code className={classes.code}>obdata</code> and <code className={classes.code}>bmesh</code>. This gives us access to the individual mesh items (vertices and edges) in <i>Edit</i> mode.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    The structure of the remaining code follows the same pattern we've already seen. We loop through each edge in the model, using <code className={classes.code}>bm</code> this time. Before building the cable element definition, we first check if the current edge is one of the selected edges. Only if it's a selected edge do we append it onto our collection of cable edges. This way, when we’ve finished looping through all edges, only the ones selected in the 3D view will appear in the <code className={classes.code}>cables</code> array for export.
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Again, we'll generate <i>Cables.csv</i> on the desktop and then move it to the data folder with the other files we’ve already generated. The data export code is the same as the code we wrote in the previous lecture.
                  </Typography>
                  
                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    We'll move on to exporting the restraint data in the next lecture.
                  </Typography>
                  
                </Grid> 

                
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_48));
