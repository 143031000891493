import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-42/img1.png";
import img2 from "../../../images/Lectures/C17-42/img2.jpg";
import img3 from "../../../images/Lectures/C17-42/img3.jpg";

const codeString1 = 
`#Constants
E = 70*10**9 #(N/m^2)
A = 0.002 #(m^2)

gamma = 100 #(kg/m) Cable mass per unit length
swt = True #Take self-weight into account 
Areas = A*np.ones([len(members)]) #An array to hold individual area for each member 
P0 = 1*np.ones([len(members)]) #An array to hold individual member pre-tension values

P = 0 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied

nForceIncrements = 1000
convThreshold = 10 #(N) Threshold on maximum value of F_inequilibrium`

class Lecture_17_42 extends React.Component {
  state={
    course: 17,
    lecture: 42, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we're going to close the loop on a conversation we started back in lecture 7. You'll recall that back in lecture 7, after deriving the catenary equation, we solved it numerically for a 12-segment catenary, Fig 1.
                  </Typography>                  
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Twelve segment catenary (repeated from lecture 7).</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    From that analysis, we identified the vertical and horizontal reactions as <MathJax.Node inline formula={"5391.1 \\:N"} /> and <MathJax.Node inline formula={"8394.06 \\:N"} />, respectively. This was a linear analysis that ignored any change in the shape of the cable. The objective in this lecture is to demonstrate the same results using our iterative solver. This will serve as a validation of sorts, demonstrating that our code yields the same results as the closed-form solution when the axial stiffness of the cable is high enough to eliminate significant deflection.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    You should also recall that at the end of lecture 7, we saved Vertices.csv and Edges.csv to the desktop. We'll import those files into our notebook by placing them in the data folder in the same directory as our analysis notebook. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We also need to define the restrained DoFs. These will be DoF 1 and 2 for the left pin support and 25 and 26 for the right pin. You can either manually edit the previous Restraint-Data.csv file or download the file from the resources area in lectrue 43. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    There will be no externally applied forces in this analysis. So, make sure to remove Force-Data.csv from the data folder. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Input parameters </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The simulation parameters are shown in the code block below. Note that the Young's Modulus and cross-sectional area have been changed from our previous analysis. These values will result in an axial stiffness that all but eliminates appreciable deflection. So the cable in our simulation should satisfy the assumptions inherent in the catenary equation we derived earlier.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The self-weight is set to <MathJax.Node inline formula={"100 \\:kg/m"} /> to match our previous analysis, and the self-weight flag is set to <code className={classes.code}>True</code>. A nominal <MathJax.Node inline formula={"1 \\:N"} /> pre-tension is applied to overcome the problem of matrix singularity we discussed previously. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The convergence threshold is set at <MathJax.Node inline formula={"10 \\:N"} />, and we'll apply the loading (which is just due to self-weight) in <MathJax.Node inline formula={"1000"} /> increments.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    The pre-analysis plot of the structure is shown below, Fig 2.  
                  </Typography>                                                                        
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Twelve segment catenary pre-analysis plot.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    When running the code, we see that the model has no problem converging. By inspecting the results plot, Fig 3. we can see that the deflection is indeed negligible. Even multiplying it by a factor of 5 using the <code className={classes.code}>scaleFactor</code> slider has little or no impact. But more importantly, when we examine the reaction forces, we can see that they agree with our solution from lecture 7.
                  </Typography>
                                 
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Twelve segment catenary results plot.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    This agreement between solutions gives us confidence that our code is behaving correctly and gives us sensible results. In other words, because the elongation of the cable is negligible, the behaviour of the structure is linear and therefore, our solver's output matches that of a linear solution. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is one of the strengths of building a numerical solver like this. We don't need to state whether the structure experiences geometric non-linearity. We can simply run our simulation and let the behaviour of the structure evolve out of the simulation. The degree of non-linearity simply emerges from the analysis. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    By observing the colours of the members in Fig 3. we can also confirm that the magnitude of the tensile force in the cable increases as the cable becomes more vertical near the supports. Again, this is what we would expect following our preliminary discussion of cables at the beginning of the course. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This now leaves us in a good position to start experimenting with the introduction of non-linearity in the next lecture. 
                  </Typography>                            
                </Grid>
 
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_42));
