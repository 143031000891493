
import React, {useState, useRef, Suspense} from 'react';
import {acos, dot} from 'mathjs'
import * as d3 from "d3";
import { Canvas, useThree } from '@react-three/fiber';
import { useControls, folder } from 'leva'
import { a, useSpring } from '@react-spring/three';
import {  OrbitControls, 
          GizmoHelper, 
          GizmoViewcube, 
          GizmoViewport,
          OrthographicCamera,
          Line } from '@react-three/drei';
import {  Node, 
          Element, 
          Pin, 
          Roller, 
          Force, 
          ReactionForce, 
          WireSphere, 
          ElementsAxialForces, 
          WhiteNode} from './components/Truss_2D/Truss_2D_components'
import ColorbarWrapper from "../../Visualisations/Colormap/ColorbarWrapper";
import Grid from "@material-ui/core/Grid";

//Calculate scene scale and calculate Orthographic camera zoom parameters
function updateOrthoCamera(props){
  
  //Initialise parameters
  let dx = 0 // x-offset to center of bounding box
  let dy = 0 // y-offset to center of bounding box
  let bbWidth = 5 //bounding box width
  let bbHeight = 5 //bounding box height

  //If nodes have been passed
  if(props.nodes){
    if(props.nodes.length==1){
      dx = props.nodes[0][1]
      dy = props.nodes[0][2]
    } else {
      let xCoords = []
      let yCoords = []
      props.nodes.map(node=>{
        if(node.length>2){
          xCoords.push(node[1])
          yCoords.push(node[2])
        }
      })      
      let minX = Math.min(...xCoords)
      let maxX = Math.max(...xCoords)
      let minY = Math.min(...yCoords)
      let maxY = Math.max(...yCoords)
      
      bbWidth = maxX - minX == 0 ? 5 : maxX - minX
      bbHeight = maxY - minY == 0 ? 5 : maxY - minY
      dx = minX + (maxX - minX)/2
      // dy = minY + (maxY - minY)/2
    }
  } 

  let scaleFactor = 0.05*Math.min(bbWidth, bbHeight)
  return [dx, dy, bbWidth, bbHeight, scaleFactor]
}

//Return colorbar
const returnColorbar = (props) =>{    
  let colorScale = null
  let memberForces = props.memberForces?props.memberForces:null
  let forceValues = []    
  if(memberForces){
    memberForces.map(mbr =>{
      forceValues.push(mbr['force'])
    })
  }  

  //Determine a colorscale using D3
  if(forceValues.length>0){      
    colorScale = d3
      .scaleLinear()      
      .range(["red", "white", "blue"])
      .domain([Math.min(...forceValues), 0, Math.max(...forceValues)]);            

    return(
      <ColorbarWrapper
        data={forceValues}
      />
    );
  } else {
    return null
  }

  
}

//Contains all of the elements in our scene - gets passed to the canvas
function Scene(props){ 

  //Calculate scene scale and ortho camera zoom parameters
  let [dx, dy, bbWidth, bbHeight, scaleFactor] = updateOrthoCamera(props)

  const maps = {
    Binary: 'binary',
    Continuous: 'continuous'
  }

  const [{ showGrid2D, showWire2D, showNodeNumbers2D, scale, showForces, resetZoom, showReactions, showAxial, map, showAxialAnnotations, showDeflections, defScale, showDefAnnotations }, set] = useControls(()=>(
    {     
      Viewport: 
        folder({          
          showGrid2D:{value: false, label:'Toggle viewport grid'},
          resetZoom: {value: true, label:'Reset viewport on update'},
        },{ collapsed: false, color:'#3c9'}),
      Structure: 
        folder({
          showWire2D: { value: false, label: 'Show wireframe' },
          showNodeNumbers2D: { value: true, label: 'Show node numbers' },
          scale: { value: scaleFactor, min: 0.1*scaleFactor, max: 10*scaleFactor, step: .1, label:'Scale'},   
          showForces: {value: true, label:'Show applied forces'},          
        },{ collapsed: false, color:'#3c9'}),
      Reactions:
        folder({
          showReactions: {value: false, label: 'Show reactions'}
        },{ collapsed: true, color:'#3c9'}), 
      'Axial Forces':
        folder({
          showAxial: {value: false, label: 'Show axial forces', onChange: (value) => set({ showWire2D: value }), transient: false},
          map: { options: maps, label: 'Select force map', render: (get) => get('Axial Forces.showAxial')},
          showAxialAnnotations: { value: false, label: 'Show force values', render: (get) => get('Axial Forces.showAxial') },
        },{ collapsed: true, color:'#3c9'}), 
      Deflection:
        folder({
          showDeflections: {value: false, label: 'Show deflected shape'},          
          defScale: { value: 1, min: 1, max: 10000, step: 100, label:'Deflection scale', render: (get) => get('Deflection.showDeflections')},
          showDefAnnotations: {value: false, label: 'Show deflection values', render: (get) => get('Deflection.showDeflections') }
        },{ collapsed: true, color:'#3c9'})   
    })
  )

  scaleFactor=scale  
    
  //Update camera matrix
  const { camera, size: { width, height } } = useThree();
  if(resetZoom){
    camera.zoom = 0.7*Math.min(
      width / bbWidth,
      height / bbHeight
    );      
    camera.position.z = 100
    camera.updateProjectionMatrix();
  }
    
  //Return nodes
  const returnNodes = (props)=>{
    if(props.nodes){ 
      return props.nodes.map(node=>{      
        return <Node key={node[0]} number={node[0]} scale={scaleFactor} rotation={[0,0,0]} position={[node[1]-dx,node[2]-dy,0]} showNodeNumbers={showNodeNumbers2D} />          
      })
    }
  }

  //Return supports
  const returnSupports = (props) =>{        
    if((props.restraints) && (props.nodes)){      
      return props.restraints.map(restraint=>{   
        let Node =  props.nodes.find(node =>{return node[0]==restraint[0]})
        if(Node){
          let [ind, xCoord, yCoord] = Node
          if((restraint[1]) && (restraint[2])){     
            return <Pin key={restraint[0]} scale={scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,0]} />  //Pin
          } else if((!restraint[1]) && (restraint[2])){
            return <Roller key={restraint[0]} scale={scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,0]} />  //xRoller
          } else if((restraint[1]) && (!restraint[2])){
            return <Roller key={restraint[0]} scale={scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,0]} /> //yRoller
          } 
        }
      })
    }
  }

  //Return elements
  const returnElements = (props) =>{
    if((props.elements) && (props.nodes)){      
      return props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          
          let memberForce = null
          if(props.memberForces){
            memberForce = props.memberForces.find(force=>{return force['memberId'] == element[0]})
          }
          
          
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi] = NodeI
            let [indJ, xj, yj] = NodeJ
            let length = Math.pow(Math.pow((xi-xj),2) + Math.pow((yi-yj),2),0.5)

            // Angle of member with respect to horizontal axis
            let Dx = xj-xi  // x-component of vector along member
            let Dy = yj-yi  // y-component of vector along member              
            let memberVector = [Dx, Dy] // Member represented as a vector
            let theta = 0

            // Need to capture quadrant first then appropriate reference axis and offset angle
            if((Dx > 0) && (Dy == 0)){
              theta = 0
            } else if((Dx == 0) && (Dy > 0)){
              theta = Math.PI/2
            } else if((Dx < 0) && (Dy == 0)){
              theta = Math.PI
            } else if((Dx == 0) && (Dy < 0)){
              theta = 3*Math.PI/2
            }else if((Dx > 0) && (Dy > 0)){
              // 0<theta<90          
              let refVector = [1, 0] // Vector describing the positive x-axis          
              theta = acos(dot(refVector, memberVector)/length)
            } else if((Dx < 0) && (Dy > 0)){
              // 90<theta<180
              let refVector = [0, 1] // Vector describing the positive y-axis         
              theta = (Math.PI/2) + acos(dot(refVector, memberVector)/(length))
            } else if ((Dx < 0) && (Dy < 0)){
              // 180<theta<270
              let refVector = [-1, 0] // Vector describing the negative x-axis
              theta = Math.PI + acos(dot(refVector, memberVector)/(length))
            } else{
              // 270<theta<360
              let refVector = [0, -1] // Vector describing the negative y-axis          
              theta = (3*Math.PI/2) + acos(dot(refVector, memberVector)/(length))
            }
            return <Element key={element[0]} scale={[length, scaleFactor, scaleFactor]} rotation={[0,0,theta]} position={[xi-dx,yi-dy,0]} memberForce={memberForce ? memberForce['force'] : null}/>
          }
        }         
      })
    }
  }

  //Return Horizontal Forces
  const returnForces_x = (props) =>{
    if((props.forces) && (props.nodes)){
      return props.forces.map(force=>{   
        let Node =  props.nodes.find(node =>{return node[0]==force[0]})
        if(Node){
          let [ind, xCoord, yCoord] = Node
          if(force[1] >0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,0]} value={force[1]} color={'#2E78CC'}/>  //Force
          } else if(force[1] <0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,-Math.PI/2]} position={[xCoord-dx,yCoord-dy,0]} value={force[1]} color={'#2E78CC'}/>  //Force
          } else{
            return null
          }
        }
      })
     }
  }

  //Return Vertical Forces
  const returnForces_y = (props) =>{
    if((props.forces) && (props.nodes)){
      return props.forces.map(force=>{   
        let Node =  props.nodes.find(node =>{return node[0]==force[0]})
        if(Node){
          let [ind, xCoord, yCoord] = Node
          if(force[2] >0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,Math.PI]} position={[xCoord-dx,yCoord-dy,0]} value={force[2]} color={'#2E78CC'} offset='below'/>  //Force
          } else if(force[2] <0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,0]} value={force[2]} color={'#2E78CC'} offset='above'/>  //Force
          } else{
            return null
          }
        }
      })
     }
  }

  //Return horizontal reaction forces
  const returnReactions_x = props =>{    
    if((props.reactions) && (props.nodes)){
      return props.reactions.map(reaction=>{
        let Node =  props.nodes.find(node =>{return node[0]==reaction['nodeId']})
        let Restraint = props.restraints.find(restraint =>{return restraint[0]==reaction['nodeId']})
        if(Node){
          let [indN, xCoord, yCoord] = Node
          let [indR, restrainedInX, restrainedInY] = Restraint

          if((reaction['xReaction'] >0) && restrainedInX){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,0]} value={reaction['xReaction']} />  //Force
          } else if((reaction['xReaction'] <0) && restrainedInX){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,-Math.PI/2]} position={[xCoord-dx,yCoord-dy,0]} value={reaction['xReaction']} />  //Force
          } else{
            return null
          }
        }
      })
    }
  }

  //Return vertical reaction forces
  const returnReactions_y = props =>{
    if((props.reactions) && (props.nodes)){
      return props.reactions.map(reaction=>{
        let Node =  props.nodes.find(node =>{return node[0]==reaction['nodeId']})
        let Restraint = props.restraints.find(restraint =>{return restraint[0]==reaction['nodeId']})
        if(Node){
          let [indN, xCoord, yCoord] = Node
          let [indR, restrainedInX, restrainedInY] = Restraint

          if((reaction['yReaction'] >0) && restrainedInY){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,Math.PI]} position={[xCoord-dx,yCoord-dy,0]} value={reaction['yReaction']} offset='below'/>  //Force            
          } else if((reaction['yReaction'] <0) && restrainedInY){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,0]} value={reaction['yReaction']} offset='above'/>  //Force
          } else{
            return null
          }
        }
      })
    }  
  }

  //Return scene gizmos
  const returnGizmos = () =>{
    return(
      <>
        <GizmoHelper alignment="top-right" margin={[50, 50]}>        
          <GizmoViewcube labelColor="black" color="#33cc99" hoverColor="#6ae6d0"/>  
        </GizmoHelper>

        <GizmoHelper alignment="bottom-left" margin={[50, 50]} >
          <GizmoViewport axisColors={['#d65050', '#33cc99', '#2E78CC']} labelColor="black" />            
        </GizmoHelper>
      </>
    )
  }

  //Return nodes for wireframe view
  const returnWireNodes = (props) =>{
    if(props.nodes){ 
      return props.nodes.map(node=>{                
        return <WireSphere 
                key={node[0]} 
                number={node[0]} 
                scale={scaleFactor} 
                rotation={[0,0,0]} 
                position={[node[1]-dx,node[2]-dy,0]} 
                color={"#d65050"}
                showNodeNumbers={showNodeNumbers2D}
               />       
      })
    }
  }

  //Return elements for wireframe view
  const returnWireElements = (props) =>{

    if((props.elements) && (props.nodes)){      
      return props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi] = NodeI
            let [indJ, xj, yj] = NodeJ
            return(
              <Line
                key={element[0]}
                points={[[xi-dx, yi-dy, 0], [xj-dx, yj-dy, 0]]}  
                color="#d65050" 
                lineWidth={.5}  
                />
            );
          }
        }         
      })
    }
  }

  //Return deflected nodes
  const returnDeflectedNodes = (props)=>{
    if((props.nodes) && (props.nodalDisplacements)){ 
      return props.nodes.map(node=>{   
        let Def = props.nodalDisplacements.find(def =>{return def['nodeId']==node[0]})     
        let Dx = Def['xDisp']
        let Dy = Def['yDisp']        
        return <WireSphere 
                key={node[0]} 
                number={node[0]} 
                scale={scaleFactor} 
                rotation={[0,0,0]} 
                position={[(node[1]-dx)+(defScale*Dx),(node[2]-dy)+(defScale*Dy),0]} 
                color={"#33cc99"}
                showDefAnnotations={showDefAnnotations}
                dx={Dx}
                dy={Dy}
               />      
      })
    }
  }

  //Return deflected shape
  const returnDeflectedElements = (props) =>{  
    if((props.nodes)&& (props.elements) &&(props.nodalDisplacements)){
      return props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          let DefI = props.nodalDisplacements.find(def =>{return def['nodeId']==element[1]})
          let DefJ = props.nodalDisplacements.find(def =>{return def['nodeId']==element[2]})
          
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi] = NodeI
            let [indJ, xj, yj] = NodeJ
            let dxI = DefI['xDisp']
            let dyI = DefI['yDisp']
            let dxJ = DefJ['xDisp']
            let dyJ = DefJ['yDisp']            

            return(
              <Line
                key={element[0]}
                points={[[(xi-dx)+(defScale*dxI), (yi-dy)+(defScale*dyI), 0], [(xj-dx)+(defScale*dxJ), (yj-dy)+(defScale*dyJ), 0]]} 
                color="#33cc99"
                lineWidth={1}              
              />
            );
          }
        }         
      })
     
    }
  }

  //Return members coloured based on axial force
  const returnAxialForces = (props, scaleFactor, dx, dy, map) =>{
    let mbrs = []; //Initialise an empty array of member objects
    let mbr = {}; //Initialise an empty object to hold key:value pair data for each object
    let forceThreshold = null //Initialise a value for the min force threshold
    let forceValues = [] //Initialise a container to hold all force values (positive and negative)

    //Determine max axial force magnitude observed
    if((props.elements) && (props.memberForces)){            
      let forceMagnitudes = [] 
      props.elements.map(element=>{
        let mbrForce = props.memberForces.find(force=>{return force['memberId'] == element[0]})    
        
        forceMagnitudes.push(Math.abs(mbrForce['force']))
        forceValues.push(mbrForce['force'])
      })       
      forceThreshold = 0.0001*Math.max(forceMagnitudes)< 0.001 ? 0.0001*Math.max(forceMagnitudes) : 0.001
    }

    //Determine a colorscale using D3
    let colorScale = null
    if(forceValues.length>0){
      //Build color scale
		colorScale = d3
      .scaleLinear()      
      // .range(["red", "white", "blue"])
      .range(["red", "#8bfb6f", "blue"])
      .domain([Math.min(...forceValues), 0, Math.max(...forceValues)]);     
    }
     
    //Extract data to draw members
    if((props.elements) && (props.nodes) && (props.memberForces)){            
      //Compile object data for instancing later
      props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          
          let memberForce = null
          let mbrForce = null
          if(props.memberForces){
            mbrForce = props.memberForces.find(force=>{return force['memberId'] == element[0]})
            memberForce = mbrForce['force']
          }
                  
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi] = NodeI
            let [indJ, xj, yj] = NodeJ
            let length = Math.pow(Math.pow((xi-xj),2) + Math.pow((yi-yj),2),0.5)

            // Angle of member with respect to horizontal axis
            let Dx = xj-xi  // x-component of vector along member
            let Dy = yj-yi  // y-component of vector along member              
            let memberVector = [Dx, Dy] // Member represented as a vector
            let theta = 0

            // Need to capture quadrant first then appropriate reference axis and offset angle
            if((Dx > 0) && (Dy == 0)){
              theta = 0
            } else if((Dx == 0) && (Dy > 0)){
              theta = Math.PI/2
            } else if((Dx < 0) && (Dy == 0)){
              theta = Math.PI
            } else if((Dx == 0) && (Dy < 0)){
              theta = 3*Math.PI/2
            }else if((Dx > 0) && (Dy > 0)){
              // 0<theta<90          
              let refVector = [1, 0] // Vector describing the positive x-axis          
              theta = acos(dot(refVector, memberVector)/length)
            } else if((Dx < 0) && (Dy > 0)){
              // 90<theta<180
              let refVector = [0, 1] // Vector describing the positive y-axis         
              theta = (Math.PI/2) + acos(dot(refVector, memberVector)/(length))
            } else if ((Dx < 0) && (Dy < 0)){
              // 180<theta<270
              let refVector = [-1, 0] // Vector describing the negative x-axis
              theta = Math.PI + acos(dot(refVector, memberVector)/(length))
            } else{
              // 270<theta<360
              let refVector = [0, -1] // Vector describing the negative y-axis          
              theta = (3*Math.PI/2) + acos(dot(refVector, memberVector)/(length))
            }
            
            let color = getElementColor(memberForce, forceThreshold, map, colorScale)            

            //Package up data
            mbr.xi = xi
            mbr.yi = yi
            mbr.theta = theta
            mbr.length = length
            mbr.memberForce = memberForce            
            mbr.color = color
            mbr.xCentre = xi + 0.5*(xj-xi)
            mbr.yCentre = yi + 0.5*(yj-yi)
            mbrs.push(mbr)
            mbr={}
          }
        }         
      })    
    }
    return( 
      <ElementsAxialForces         
        {...props} 
        mbrs={mbrs}
        scaleFactor={scaleFactor}
        dx={dx}
        dy={dy} 
        showAxialAnnotations={showAxialAnnotations}
      />    
    ); 
  }

  //Return element color based on internal axial force magnitude
  const getElementColor = (force, threshold, map, colorScale) =>{   

    if(map=='binary'){
      if(Math.abs(force)<threshold){
        return 'white'
      }else if(force>threshold){        
        return 'blue'
      } else if(force<threshold){        
        return 'red'
      }
    } else {
      return colorScale(force)
    }   
}

  //Return nodes meshes with no color
  const returnWhiteNodes = (props)=>{
    if(props.nodes){ 
      return props.nodes.map(node=>{      
        return <WhiteNode key={node[0]} number={node[0]} scale={scaleFactor} rotation={[0,0,0]} position={[node[1]-dx,node[2]-dy,0]} showNodeNumbers={showNodeNumbers2D} />          
      })
    }

  }

  return(
    <>
      <ambientLight intensity={1.5}/> 
      <directionalLight intensity={1} position={[0, 10, 10]}/>
      <Suspense fallback={null}>    
        <> 
          {returnGizmos()}  
          {showGrid2D ? <gridHelper args={[50,50, `#f13f64`, `#2f2f2f`]}/> : null}         

          {showWire2D ? returnWireNodes(props) : returnNodes(props)}   
          {showWire2D ? returnWireElements(props) : returnElements(props)}  

          {showForces ? returnForces_x(props) : null}
          {showForces ? returnForces_y(props) : null}

          {showReactions ? null: returnSupports(props)} 
          {showReactions ? returnReactions_x(props): null}
          {showReactions ? returnReactions_y(props): null}
                                
          {showAxial ? returnAxialForces(props, scaleFactor, dx, dy, map): null}  
          {showAxial ? returnWhiteNodes(props): null}  


          {showDeflections ? returnDeflectedElements(props): null}
          {showDeflections ? returnDeflectedNodes(props): null}              
        </> 
      </Suspense>
      <OrbitControls/>  
    </>
  );
}

function Truss2DScene(props){  
  return(  
    <>
    <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
      <Grid item xs={12} sm={12} md={12} style={{height:"700px"}}>
      <Canvas style={{border:"solid .5px rgba(51, 204, 153, .2)", borderRadius:"10px", background:"linear-gradient(to bottom, #b7eaff 0%,#94dfff 100%)"}} >        
          <OrthographicCamera 
            makeDefault       
            position={[0,0,1]}
          />  
          <Scene 
            {...props}   
          />                    
        </Canvas>  
      </Grid> 
      <Grid item xs={12} sm={12} md={12}>        
        {returnColorbar(props)}
      </Grid>
    </Grid>
    </>  
  );
}

export default Truss2DScene;






