import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_60 extends React.Component {
  state={
    course: 17,
    lecture: 60, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >
                  <Typography paragraph className={classes.bodytext}>
                    One key concept to get comfortable with in Blender is the difference between <i>Object</i> mode and <i>Edit</i> mode.  In this lecture, we’ll demonstrate the two modes and leave you with a clear understanding of the difference between the two.
                  </Typography>
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_60));
