import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_57 extends React.Component {
  state={
    course: 17,
    lecture: 57, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    The six lectures in this appendix are for people who are not yet familiar with Blender and need a primer on how to use it. If you’ve never heard of Blender, it’s an open-source 3D modelling software. It’s free to use and is incredibly helpful for generating and visualising structural geometry.  I’ve been using it for over a decade, and it’s become an indispensable tool for me. It also has an extensive Python API, which means we can interact with it programmatically. As you can imagine, this is a major benefit for us since we’re already building in Python with our Jupyter Notebooks.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As these are not engineering theory or coding lectures, I won’t exhaustively document them in text format. Instead, I’ll give a brief synopsis of what we’ll cover in the lecture. For the most part, these lectures involve me walking you through the Blender interface, explaining things as I go. So, the most efficient way for you to cover the material is to watch each video and to have a Blender window open beside you so you can replicate what I’m doing. This approach will be particularly effective for the final two modelling lectures in the appendix. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In this first lecture, we’ll briefly discuss Blender in general terms and how it can help us as a structural modelling and visualisation tool. I’ll also point you toward some other learning resources in case you’re interested in diving deeper into Blender. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}></Typography>                             
                                
                </Grid>
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_57));
