import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_4 extends React.Component {
  state={
    course: 19,
    lecture: 4, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Before we can design any structural element, we must first determine what loading should be applied. This <b>design loading</b> should reasonably represent the worst-case loading that the element is likely to experience in service. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In this section, we focus on the process of establishing this design loading in accordance with the Eurocodes.  We’ll get into the detail of reinforced concrete design in the sections that follow, but what we cover in this section is key to designing code-compliant structures.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We’ll start by discussing the framework of codes and guidelines that apply to the design of reinforced concrete structures. We’ll discuss the suite of Eurocodes and introduce the ones most relevant to our study in this course. I’ll also highlight some additional publications you will find helpful as a reference as you design concrete structures beyond this course.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    From here, we’ll discuss <b>actions</b>, loosely speaking, this is a Eurocode term for forces. We’ll also discuss the various factors that we must apply to turn <b>characteristic actions</b> (raw, unfactored forces) into design actions.  
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Then, we’ll introduce the concept of <b>limit state design</b> and identify the various <b>ultimate</b> and <b>serviceability limit states</b> set out in the Eurocodes. Finally, we’ll tie together all the concepts covered by working through some examples at the end of the section.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    When you complete this section, you should clearly understand the terminology associated with the exercise of determining design loading. You should understand how to determine suitable characteristic actions and, by applying appropriate factors, turn these into design actions that can be used in subsequent calculations.
                  </Typography>                                                                 
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_4));
