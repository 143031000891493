import { FETCH_FLASH } from "../actions/types";

export default function(state = {}, action) {
	switch (action.type) {
		case FETCH_FLASH:
			return action.payload; //returns user object or false

		default:
			return state;
	}
}
