//Controls styling of the standard lecture component
export const styles = theme => ({
	H1: {
    fontSize: "2.5rem",
    marginBottom:"20px",    
    fontFamily:"'sofia-pro',sans-serif"
	},
	H2: {
		fontSize: "1.5rem",
    color: "#33cc99",
    // marginBottom:"10px",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    marginTop:'50px' 

	},
  H3: {
		fontSize: "1.15rem",
    color: "#fff",
    // marginBottom:"10px",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    marginTop:'50px' 

	},
	bodytext: {
		fontSize: "1.15rem",
    lineHeight:"1.8",
    color: "#b2becd",   
    fontFamily:"'sofia-pro',sans-serif",
    marginTop:"1.75rem",    
	},
  formula:{
    fontSize: "1.1rem",   
    color: "#e8eaed",   
  },
  link:{    
    color: theme.palette.coral.main,
		"&:hover": {
			color: "#fa2955"
		},
    textDecoration: "underline" 
  },
  image:{    
    margin:"25px auto",
    marginBottom:"0px",
    borderRadius:"5px",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.75)",
  },
  imageNoAlpha:{    
    margin:"25px auto",
    marginBottom:"0px",
    borderRadius:"5px",    
  },
  figure:{
    width:"800px"
  },
  caption:{
    textAlign:"center",
    fontSize: "1rem",
    fontFamily:"'sofia-pro',sans-serif",
    color: "#b2becd",
    lineHeight:"1.8",
  }
});
