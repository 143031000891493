import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutPublic from "../../../components/common/LayoutPublic";
import Feedback from "../../../components/utils/Feedback";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import AvatarCarroll from "../../../images/Landing/avatar_spcarroll1.png";
import Signature from "../../../images/Landing/Sean.png";
import Avatar from "@material-ui/core/Avatar";
import Particles from "react-particles-js";
import Paper from "@material-ui/core/Paper";


//Config for particles.js
const particleConfig = {
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
				value_area: 800
			}
		},
		color: {
			value: "#33cc99"
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000"
			},
			polygon: {
				nb_sides: 5
			},
			image: {
				src: "img/github.svg",
				width: 100,
				height: 100
			}
		},
		opacity: {
			value: 0.06313181133058181,
			random: false,
			anim: {
				enable: false,
				speed: 0.5,
				opacity_min: 0.1,
				sync: false
			}
		},
		size: {
			value: 3,
			random: true,
			anim: {
				enable: false,
				speed: 40,
				size_min: 0.1,
				sync: false
			}
		},
		line_linked: {
			enable: true,
			distance: 150,
			color: "#33cc99",
			opacity: 0.11048066982851817,
			width: 1
		},
		move: {
			enable: true,
			speed: 2,
			direction: "none",
			random: false,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200
			}
		}
	},
	interactivity: {
		detect_on: "canvas",
		events: {
			onhover: {
				enable: false,
				mode: "repulse"
			},
			onclick: {
				enable: false,
				mode: "push"
			},
			resize: true
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1
				}
			},
			bubble: {
				distance: 400,
				size: 40,
				duration: 2,
				opacity: 8,
				speed: 3
			},
			repulse: {
				distance: 200,
				duration: 0.4
			},
			push: {
				particles_nb: 4
			},
			remove: {
				particles_nb: 2
			}
		}
	},
	retina_detect: true
};

//Custom styling
const styles = theme => ({
	particleBackground: {
    background: '#1a1a1a',
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "fixed !important",
		opacity: "100%",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: -2
  },
  outsidePaper:{
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8), 
  },
	paper: {       
    background: '#25252550',    
		marginTop: theme.spacing(),
		display: "flex",
		flexDirection: "column",
    alignItems: "center",
    color:'#33cc99',
    borderRadius:'25px',    
	},
	avatar: {
		margin: '50px',
		width: '25%',
		height: '25%'
  },
  bodyText: {
		fontWeight: "400",
		color: "#fff",
		textAlign: "justify",
		fontSize: "1.1rem",
		flex: "grow",
    marginTop:"30px",
		marginBottom: "30px"
	},
  signature:{
    width: '30%',
		// height: '15%',
    marginBottom:"50px",
    padding:"5px"
  },
  heading:{
    padding:'50px',
    textAlign: "center",
  }
});



class CertificateRequestSubmitted extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}


	render() {    
    const { classes } = this.props; //Destructure data off the components props
		return (
      <React.Fragment>
				<LayoutPublic />
				<Particles className={classes.particleBackground} params={particleConfig} />
				<Feedback />

				<Grid container justify="center" spacing={4}>
					<Grid item xs={10} sm={10} md={4}>
						<CssBaseline />

            <div className={classes.outsidePaper}>
						  <Paper className={classes.paper} elevation={2}>										

								<Typography component="h1" variant="h5" className={classes.heading}>
                  THANKS FOR SUBMITTING YOUR REVIEW
								</Typography>

								
									<Grid container justify="center">
										<Grid item xs={11} sm={11}>
                    <Typography variant="body1" className={classes.bodyText}>
                      📜 If you've requested a course completion certificate, provided you’ve completed the course, we’ll email your course completion certificate within 24 hours.		 	
			 							</Typography>
                     <Typography variant="body1" className={classes.bodyText}>
                     🗑️ Remember to check your junk email folder if your certificate doesn't arrive within about 24 hours. 		 	
			 							</Typography>

                     <Typography variant="body1" className={classes.bodyText}>
			 								Thanks again for taking the time to leave a review!	                       	
			 							</Typography>
                     <img src={Signature} className={classes.signature} alt={"Dr Seán Carroll"}></img>
										</Grid>
									</Grid>
                
              
            </Paper>
          </div>
					
          </Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(CertificateRequestSubmitted);
