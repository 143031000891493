import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-41/Final_CoC.png";
import img2 from "../../../images/Lectures/C19-41/Sean.png";


class Lecture_19_41 extends React.Component {
  state={
    course: 19,
    lecture: 41, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Well done for completing the course! I hope you enjoyed it and found it helpful.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    You should now understand the fundamental models we use to describe the behaviour of reinforced concrete. There is certainly more to the story and more advanced analyses to cover - we’ll get there in due course. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    However, you’ll be surprised at how often the fundamentals we’ve covered here will pop up throughout your study of reinforced concrete design. A solid understanding of flexural and shear resistance underpins the majority of the analysis you’ll go on to do later. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If you’ve completed the Python section of the course, you’re in a good position to continue implementing some of the remaining course calculations. The shear design calculations would be an obvious place to start. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    That’s all for now. I will likely release a follow up course to this at some point in the future. So keep an eye out for that.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 🏆 Certificate of Completion </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, don’t forget to claim your Certificate of Completion.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    You’ve developed a valuable skillset and body of knowledge, so let people know by posting your Certificate of Completion on your LinkedIn profile or workplace CPD portfolio.
                  </Typography>                                    
                                
                </Grid>

                <img style={{width:"30%", margin:"auto"}}src={img1} />

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography component="h2" className={classes.H2} > 🙏 Course Feedback </Typography> 

                  <Typography paragraph className={classes.bodytext}>
                    Please also take a couple of minutes to provide some feedback and provide a rating for the course. Feedback helps me improve my courses and also helps inform other students before they take a course.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                  <Link to="/review-certificate-request" className={classes.link} target="_blank">To leave some feedback and request your certificate of completion, just fill out this form</Link>. Your request will be manually reviewed to confirm you’ve completed the course and your personalised certificate will be emailed to you. You should receive your certificate within 24 hours (remember to keep an eye on your junk email folder).
                  </Typography>                                                     

                  <Typography paragraph className={classes.bodytext}>
                    Well done again on completing the course and I hope to see you on another DegreeTutors course very soon. 
                  </Typography>

                  <img style={{width:"15%", margin:"auto"}}src={img2} />
                                
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_41));
