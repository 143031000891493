import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import App from "./components/App";
import {
	MuiThemeProvider,
	createMuiTheme,
	CssBaseline
} from "@material-ui/core";
import reducers from "./reducers";

import ReactGA from "react-ga";
// ReactGA.initialize("UA-105235522-1");
// ReactGA.initialize("");
if(process.env.NODE_ENV=='development'){
  ReactGA.initialize("");
} else {
  ReactGA.initialize("UA-105235522-1");
}


const theme = createMuiTheme({
	palette: {
    background: {
      // default: "#252525"
      // default: "#202020"      
      default: "#12181b"      
    },
    text: {
      primary: "#ffffff"
    },
		primary: {
			main: "#141421",
			light: "#6ae6d0"
		},
		secondary: { main: "#33CC99" },
		danger: { main: "#f44336", dark: "#b71c1c" },
		warning: { main: "#f9ab4e", dark: "#cc8c3f" },
		brightGreen: { main: "#2dc38f" },
		coral: { main: "#f13f64" }
	},
	typography: {
		useNextVariants: true,
    fontFamily: ['"Barlow"', 'SansSerif'].join(',') //For adding barlow as default font (google fonts imported in index.html)
	},
	overrides: {
		MUIDataTableBodyCell: {
			root: {        
				fontSize: "medium",
				padding: "0px",
				paddingLeft: "10px",
				paddingBottom: "10px",
				paddingTop: "10px",
        color:"#33cc99",     
        cursor:"pointer"     
			},      
		},
    MUIDataTablePagination: {
      root: {
        backgroundColor: "#1a1a1a",
        color: "#fff",
      }
    },   
    MuiButton:{
      textPrimary:{
        color: "#fff"
      }
    },     
    MUIDataTableToolbar: {
      root: {
        backgroundColor: "#1a1a1a",            
      },
      icon:{
        color:"#fff",
        '& :hover': {
          color: "#33cc99 !important"
        }
      },
      iconActive:{
        color: "#33cc99 !important"
      }
    },  
    MUIDataTableHeadCell: {
      fixedHeaderCommon: {
        backgroundColor: "#1a1a1a",
        color: "#fff",
      },
    }, 
    MUIDataTableSelectCell:{
      fixedHeaderCommon: {
        backgroundColor: "#1a1a1a",
        color: "#fff",
      },
      headerCell: {
        backgroundColor: "#1a1a1a",
        color: "#fff",
      },
    },
    MuiCheckbox:{
      root:{
        color:"#fff !important"
      }
    },
    MuiSelect:{
      icon:{
        color:"#fff",
      }
    },  
    MuiTableRow: {
      root: {
        backgroundColor:"#252525",
        '&:hover': {
          backgroundColor: '#1a1a1a !important'
        }
      }
    },
    MuiPaper:{
      root:{
        backgroundColor:"#252525"        
      }
    },
    // MuiList:{
    //   root: {
    //     backgroundColor:"#1a1a1a",
    //   }
    // },
    MuiListItemIcon:{
      root: {
        color:"",
      }
    },
    MuiFormLabel:{
      root:{
        color: "#33cc99",   
        "&.Mui-focused":{
          color: "#fff"
        }                               
      },                 
    },
    MUIDataTableViewCol:{
      title:{
        color: "#33cc99"
      }
    },        
    MUIDataTableSearch:{
      searchIcon:{
        color: "#33cc99"
      }
    },
    MuiIconButton:{
      root:{
        color: "#33cc99"
      }
    }   
	}
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//Set up the redux store and pass it all reducers
const store = createStore(
	reducers,
	{},
	composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
	//Provider component connects allows components to access data in redux store
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<App />
		</MuiThemeProvider>
	</Provider>,
	document.querySelector("#root")
);
