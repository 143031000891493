import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-12/img1.png";
import img2 from "../../../images/Lectures/C19-12/img2.png";
import img3 from "../../../images/Lectures/C19-12/img3.png";
import img4 from "../../../images/Lectures/C19-12/img4.png";

const Eqn1 = `\\begin{equation}
\\tag{1}
f_{cd} = \\frac{0.85\\:f_{ck}}{\\gamma_c}
\\end{equation} `

const Eqn2 = `\\begin{equation}
f_{cd} = \\frac{0.85\\times f_{ck}}{1.5} = 0.567\\:f_{ck} = 17\\:N/mm^2
\\end{equation} `

const Eqn3 = `\\begin{equation}
\\tag{2}
f_{yd} = \\frac{f_{yk}}{\\gamma_s}
\\end{equation} `

const Eqn4 = `\\begin{align*}
\\epsilon_y &= 500/(1.15\\times 200\\: \\times 10^3)\\\\
\\epsilon_y & =  0.00217
\\end{align*}`

const Eqn5 = `\\begin{equation}
\\tag{3}
E_{cm} = (1.25E_d-19)\\:kN/mm^2
\\end{equation}`

class Lecture_19_12 extends React.Component {
  state={
    course: 19,
    lecture: 12, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >     
                  <Typography component="h2" className={classes.H2} > 1.0 Stress-strain behaviour </Typography>             
                  
                  <Typography paragraph className={classes.bodytext}>
                    Reinforced concrete is a composite of steel and concrete, therefore the stress and strain behaviour of both materials must first be understood. Fig. 1(a) shows a typical stress versus strain curve for a concrete specimen in compression.
                  </Typography>                                                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. (a) Stress versus strain curve for plain concrete in compression and (b), stress versus strain for a steel specimen in tension.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Unlike a perfectly linearly elastic material, the stress-strain relationship for plain concrete is characterised by a parabolic curve until a compressive strain of approximately <MathJax.Node inline formula={"0.002"} /> is reached, denoted as <MathJax.Node inline formula={"\\epsilon_{c2}"} />. This is accompanied by an <b>ultimate design compressive stress</b>, <MathJax.Node inline formula={"f_{cd}"} />, of:
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"f_{ck}"} /> is the <b>characteristic compressive cylinder strength</b> of the concrete at 28 days (after casting), <MathJax.Node inline formula={"\\gamma_c"} /> is the material partial factor of safety for concrete and the factor <MathJax.Node inline formula={"0.85"} /> is an empirical factor that allows for long-term effects and the difference between the bending strength and the cylinder crushing strength of the concrete.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In much the same way that we use partial factors of safety applied to characteristic loading, we use partial material factors to represent the uncertainty of achieving the stated characteristic strength of the material (steel or concrete). For concrete, <MathJax.Node inline formula={"\\gamma_c = 1.5"} />, thus for a concrete mix with a specified cylinder strength of <MathJax.Node inline formula={"f_{ck}= 30\\: N/mm^2"} />, the design compressive strength is reduced to,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Note that after the design compressive stress is reached, no further stress increase is observed, yet the strain continues increasing until a maximum strain value of approximately <MathJax.Node inline formula={"0.0035"} /> is reached, denoted as <MathJax.Node inline formula={"\\epsilon_{cu,2}"} />. This is the ultimate compressive strain and represents the point at which concrete crushing will occur. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The horizontal portion of the stress-strain graph represents classic plastic behaviour, whereby the strain is increasing at a constant level of stress. This has significant implications for the design of concrete sections at the ultimate limit state, which we’ll discuss in the following lectures.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Figure 1(b) shows a typical stress-strain graph for a steel reinforcing bar in uniaxial tension. As can be seen from the diagram, steel is a linearly elastic material. The design yield stress is obtained as,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where the partial factor for steel is taken as <MathJax.Node inline formula={"\\gamma_s = 1.15"} />. The steel used in reinforcing bars throughout Europe is high yield strength steel with a characteristic yield strength of <MathJax.Node inline formula={"f_{yk} = 500 \\:N/mm^2"} /> and a Young's modulus of <MathJax.Node inline formula={"E = 200 \\:kN/mm^2"} />. Therefore the corresponding yield strain is simply obtained as,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography component="h2" className={classes.H2} > 2.0 Modulus of elasticity </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    You’ll be familiar with the concept of elasticity and the modulus of elasticity from basic mechanics; the modulus of elasticity (a.k.a Young’s Modulus) provides the constant of proportionality between stress and strain. For a perfectly linearly elastic material, stress is linearly proportional to strain. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As we can see from Fig 1(a), in the case of concrete, stress is not exactly linearly proportional to strain. However, for stresses below approximately <MathJax.Node inline formula={"1/3^{rd}"} /> of the ultimate compressive stress we typically assume linearly elastic behaviour. We therefore define the <b>secant modulus of elasticity</b>, <MathJax.Node inline formula={"E_{cm}"} />, or simply the secant modulus, as the average slope of the stress-strain curve between zero stress and <MathJax.Node inline formula={"40\\:\\%"} /> of the mean cylinder strength at 28 days, Fig. 2.
                  </Typography>                 
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Secant modulus and dynamic modulus, obtained from a concrete stress-strain curve.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    We can also see the dynamic modulus of elasticity, <MathJax.Node inline formula={"E_d"} />, defined on the figure above. This is obtained as a tangent to the stress-strain curve at the point of zero stress. The relationship between secant modulus and tangent modulus is approximated by the following expression,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Helpfully, we can obtain estimates of the secant modulus as a function of compressive strength. The following values can be found in part 1-1 of Eurocode 2.
                  </Typography>                  
                                
                </Grid>
                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Relationship between characteristic concrete strength and secant modulus.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >   

                <Typography component="h2" className={classes.H2} > 3.0 Tensile capacity of concrete </Typography>               
                  <Typography paragraph className={classes.bodytext}>
                    Since we're dealing with material properties in this lecture, it's a good opportunity to address the weakness of plain concrete in tension. Plain concrete has excellent compression resistance but comparatively poor tension capacity. This is why we must reinforce it with steel; so that the internal tensile stresses that develop can be resisted. Determining the location and size of this steel reinforcement is our main focus in this section.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As a rule of thumb, we can say that the tensile capacity of plain concrete is approximately <MathJax.Node inline formula={"1/10^{th}"} /> of its compression capacity. Once surface stresses exceed the tensile capacity, cracks generally begin to appear. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Eurocode 2 (part 1-1) again provides a relationship between characteristic compression strengths, <MathJax.Node inline formula={"f_{ck}/f_{ck,cube}"} /> (cylinder/cude) and mean tensile strength, <MathJax.Node inline formula={"f_{ctm}"} />. This information is provided here as a reference but similarly to our discussion of secant modulus, this will be more relevant for the analysis of reinforced concrete at the serviceability limit state. 
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Relationship between characteristic concrete strength and mean axial tensile strength, <MathJax.Node inline formula={"f_{ctm}"} />.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Having identified the fundamental material behaviour and relevant properties of steel and plain concrete individually, in the next lecture, we can start to consider how the two work together compositely to resist bending in a reinforced concrete beam.</Typography>                  
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_12));
