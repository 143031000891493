import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/authActions";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
	filterSelector: {
		width: "100%",
		float: "left",        
	},
  dropdownStyle:{    
    backgroundColor:'#131313',        
  },
  select: {
    "& .MuiSvgIcon-root": {
      color: "#33cc99",
    },
  },
});

class DropdownMenu extends React.Component {
	state = {
		filter: this.props.initialValue
	};

	componentDidMount() {
		this.setState({
			filter: this.props.initialValue
		});
	}

	handleChange = name => event => {		
    this.props.onChangeValue(event.target.value)
    this.setState({ [name]: event.target.value });
	};

	//Required when combining Matrial UI <TextField/> with redux-form
	renderTextSelect({ options, value, id, name, input, label, ...custom }) {
		return (
			<TextField   
        InputProps={{
          style: {            
            color:'#fff',                        
          }
        }}
        InputLabelProps={{
          style:{
            color:'#33cc99',                       
          }
        }}
        color="secondary"             
				value={value}        
				select
				id={id}
				name={name}
				label={label}
				fullWidth
				{...input}
				{...custom}
			>
				{options.map(option => (
					<MenuItem 
            key={option.filter} 
            value={option.filter} 
            style={{color:"#fff"}}           
          >
            {option.filter}
					</MenuItem>
				))}
			</TextField>
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<form id="filterSelectForm" className={classes.filterSelector}>
				<Field          
					name="filter"
					id="filter"
					label="Course Filter"          
					options={this.props.options}
					component={this.renderTextSelect}
					value={this.state.filter}
					onChange={this.handleChange("filter")}                 
          className={classes.select} 
          SelectProps={{                     
						MenuProps:{ 
              classes: { 
                paper: classes.dropdownStyle } }
					}}
				/>
			</form>
		);
	}
}

const formWrapped = reduxForm({
	form: "filterSelectForm",
	enableReinitialize: true
})(DropdownMenu);

function mapStateToProps(state, ownProps) {
	return {
		auth: state.auth,		
		initialValues: {
			filter: ownProps.initialValue
		}
	};
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles, { withTheme: true })(formWrapped));
