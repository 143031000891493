import React, {useState, useRef, Suspense, useCallback} from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import { a, useSpring } from '@react-spring/three';
// import DatGui, { DatBoolean, DatColor, DatNumber, DatString } from 'react-dat-gui';
// import 'react-dat-gui/dist/index.css';

function Cube(props){
  const [isHovered, setIsHovered] = useState(false) 
  const ref = useRef() //Gives us a reference to a DOM element
  
  const card = useLoader(GLTFLoader, `/courses/course_${props.course}.glb`)

  //Use react spring to animate scale transition
  const { size, rot } = useSpring({
    size: isHovered ? [1.15,1.15,1.15] : [1,1,1],   
    rot: isHovered ? [0,Math.PI,0] : [0,0,0]
  })  

  const handleClick = ()=>{
    console.log("clicked")
  }

  return(
    <a.primitive       
      ref={ref}
      scale={size}  
      rotation={rot} 
      position={props.position}
      object={card.scene}          
      onClick={()=>console.log("clicked")}             
      onPointerOut={()=>{setIsHovered(false)}}
      onPointerOver={()=>{setIsHovered(true)}}
    />
    
  );
}

//Contains all of the elements in our scene - gets passed to the canvas
function Scene(){   
  const yOffset = 4;    
  const [pos1, setPos1] = useState([0,0+yOffset,0]) 
  const [pos2, setPos2] = useState([4.4,-4.4+yOffset,0]) 
  const [pos3, setPos3] = useState([-4.4,-2.2+yOffset,0]) 
  const [pos4, setPos4] = useState([-4.4,-4.4+yOffset,0]) 
  const [pos5, setPos5] = useState([-4.4,-6.6+yOffset,0]) 
  const [pos6, setPos6] = useState([-4.4,-8.8+yOffset,0]) 
  const [pos7, setPos7] = useState([-2.2,-2.2+yOffset,0]) 
  const [pos8, setPos8] = useState([-2.2,-4.4+yOffset,0]) 
  const [pos9, setPos9] = useState([-2.2,-6.6+yOffset,0]) 
  const [pos10, setPos10] = useState([0,-2.2+yOffset,0]) 
  const [pos11, setPos11] = useState([2.2,-2.2+yOffset,0]) 
  const [pos12, setPos12] = useState([2.2,-4.4+yOffset,0]) 
  const [pos13, setPos13] = useState([2.2,-6.6+yOffset,0]) 
  const [pos14, setPos14] = useState([2.2,-8.8+yOffset,0]) 
  const [pos15, setPos15] = useState([4.4,-6.6+yOffset,0]) 
     
  return(
    <>
      <ambientLight intensity={0.7}/>
      <Suspense fallback={null}>
        <Cube position={pos1} course={1}/>
        <Cube position={pos2} course={2}/>
        <Cube position={pos3} course={3}/>
        <Cube position={pos4} course={4}/>
        <Cube position={pos5} course={5}/>
        <Cube position={pos6} course={6}/>
        <Cube position={pos7} course={7}/>
        <Cube position={pos8} course={8}/>
        <Cube position={pos9} course={9}/>
        <Cube position={pos10} course={10}/>
        <Cube position={pos11} course={11}/>
        <Cube position={pos12} course={12}/>
        <Cube position={pos13} course={13}/>
        <Cube position={pos14} course={14}/>
        <Cube position={pos15} course={15}/>
      </Suspense>      
      <OrbitControls
        enableZoom={true}   
        maxAzimuthAngle={0}
        minAzimuthAngle={0}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />  
    </>
  );
}

//Exported component
function LearningPathways(){ 
  return(    
    <div style={{height:"1100px"}}> 
    <Canvas
    camera={{
      position:[0,0,8],
    }}
    >
      <Scene/>
    </Canvas>  
    </div>  
  );
}

export default LearningPathways;