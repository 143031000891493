import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import history from "../../history";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Feedback from "../utils/Feedback";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Button from "@material-ui/core/Button";
import CourseData from '../../Data/courses.js';
import ProjectData from '../../Data/projects.js';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CodeBlock from "../../components/common/CodeBlock"
import ReplayIcon from '@material-ui/icons/Replay';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import DiscourseComments from "./DiscourseComments";


const codeString = `
def anAmazingFunctionName(param1, param2);
  
  for i in np.arange(0,101,1):
    
    return param1 + param2`;

const drawerWidthRight = 500;

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5)    
	},
	H1: {
    fontSize: "2.5rem",
    marginBottom:"20px"    
	},
	H2: {
		fontSize: "1.5rem",
    color: "#33cc99",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
	},
	bodytext: {
		fontSize: "1.1rem"
	},
  iframeContainer: {        
		overflow: "hidden",
		// Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
		paddingTop: "56.25%",
		position: "relative",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.75)",
    borderRadius:"10px"
  },
	iframe: {    
		border: "0",
		height: "100%",
		left: "0",
		position: "absolute",
		top: "0",
		width: "100%",
  },
  card: {    
    border: '1px solid #33cc9950',
    borderRadius:'5px',
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",				
    // background: "#1a1a1a", 
    background: "#2a2e35", 
    height:'100%', 
    width:'100%',    
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.75)",    
	},
  cardBtm: {      
    border: '1px solid #33cc9950',
    borderRadius:'5px',
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",				
    // background: "#1a1a1a", 
    background: "#2a2e35", 
    height:'100%', 
    width:'100%',  
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.75)",        
	},
  indexCard:{
    // marginLeft:"15px",
    // marginRight:"15px",
    // borderTop: '1px solid #33cc9950',
    // borderBottom: '1px solid #33cc9950',
    // borderRadius:'15px',					
    background: "#252525",     
  },
  titleContainer: {
		display: "flex",
		justifyContent: "left",    
  },
  subtitleContainer: {
		display: "flex",
    justifyContent: "left",
    paddingTop:'0px',    
  },
  title: {
		color: "#33cc99",
		fontSize: 25,
		paddingBottom: 0,
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
	},
  titleBtm: {
		color: "#f13f64",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
		fontSize: 25,
		paddingBottom: 0
	},
	subTitle: {    
		color: "white",
		fontSize: 20,	
    fontFamily:"'sofia-pro',sans-serif",	
    fontWeight:'bold',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  courseButton:{     
    background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(51,204,153,1) 0%, rgba(0,156,103,1) 100%)",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
    color:"#1a1a1a",   
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    "&:hover": {
      background: "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(69,226,173,1) 0%, rgba(106,230,208,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(51,204,153,0.2)"
    },           
  },
  completeButton:{        
    background: "linear-gradient(31deg, rgba(241,63,100,1) 0%, rgba(241,63,100,1) 0%, rgba(255,93,127,1) 100%)",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:"bold",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    color:"#fff",       
    "&:hover": {
      background: "linear-gradient(31deg, rgba(241,63,100,1) 0%, rgba(255,74,111,1) 0%, rgba(255,139,164,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(241,63,100,0.2)"
    },
  },
  resetButton:{
    background: "linear-gradient(31deg, rgba(241,63,100,1) 0%, rgba(241,63,100,1) 0%, rgba(255,93,127,1) 100%)",
    fontFamily:"'sofia-pro',sans-serif",
    // fontWeight:"bold",
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.5)",
    color:"#fff",       
    "&:hover": {
      background: "linear-gradient(31deg, rgba(241,63,100,1) 0%, rgba(255,74,111,1) 0%, rgba(255,139,164,1) 100%)",
      boxShadow: "4px 4px 11px -1px rgba(241,63,100,0.2)"
    },
  },
  drawerRight: {
		width: drawerWidthRight,
    flexShrink: 0,    
  },
  drawerOpenRight: {    
    backgroundColor:"#12181b", 
    // backgroundColor:"#2a2e35", 
    borderLeft:"1px solid #33cc9950",
		width: drawerWidthRight,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerCloseRight: {
    // backgroundColor:"#1a1a1a", 
    backgroundColor:"#12181b",   
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: 0,
  },
  indexIcon:{
    marginTop:"75px"
  },
  indexIconOpen:{
    marginTop:"75px"

  },
  indexIconClosed:{
    marginTop:"75px",
    marginLeft:"-100px"
  },
  menuItem: {        
		color: "#D3D3D3",
		"&:hover": {
			color: theme.palette.secondary.main
		}
	},
  indexItemText:{
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
  },
  indexItemSubText:{
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
    fontSize:"0.8rem"
  },
  menuItemActive: {
    color: "#f13f64",
		"&:hover": {
			color: theme.palette.secondary.main
		}
  },
  indexSectionHeader:{
    color:"#33cc99", 
    fontSize:"20px",
    // paddingLeft:"25px", 
    // background:"#252525",
    background:"#2a2e35",
    fontFamily:"'sofia-pro',sans-serif",
    fontWeight:'bold',
  }
});

class LectureComponent extends React.Component {
  
  state={
    rightOpen: false,
    open: true,
    isProject: null,
    course: null,
    lecture: null, 
    hasText:null,
    section: null,  
    lectureTitle: null,    
    lectureLinks: [],
    lectureFiles: [],
    lectureVimeo: null,
    lectureDuration: null,
    noLectures: null,
    lectureList: null,
    fileRedirectMsg: null,
    fileRedirectURL: null,
    expanded: false,  
  }

  //Load meta data for this lecture
  componentDidMount() {  
    window.scrollTo(0, 0);
    let course = null
    let lecture = null
    let isProject = false   
    
    //Check whether a course or project lecture is being rendered
    if(this.props.project){
      isProject = true
      //If child is a lecture from a Python project
      course = ProjectData.projectList.filter((course)=>{
        return course.projectId==this.props.course
      })
  
      lecture = course[0].lectures.filter((lecture)=>{
        return lecture.lectureId==this.props.lecture
      }) 
    } else {
      //Else child is a lecture from a course
      course = CourseData.courseList.filter((course)=>{
        return course.courseId==this.props.course
      })
      
      lecture = course[0].lectures.filter((lecture)=>{
        return lecture.lectureId==this.props.lecture
      })         
    }           

    this.setState({
      isProject:isProject,
      course: this.props.course,
      lecture: this.props.lecture,      
      hasText: course[0].text,
      completeLectures: course[0].completeLectures,
      hasDiscourse: course[0].discourse,
      discourseTopcId: lecture[0].topicId,
      section: lecture[0].section,
      lectureTitle: lecture[0].title,      
      lectureLinks: lecture[0].links,
      lectureFiles: lecture[0].files,
      lectureVimeo: lecture[0].vimeoURL,
      lectureDuration: lecture[0].duration,   
      noLectures: course[0].lectures.length,
      lectureList: course[0].lectures,
      fileRedirectMsg:course[0].fileRedirectMsg,
      fileRedirectURL:course[0].fileRedirectURL,      
    })
  } 

  //Handle the lecture navidation button clicks
  handleButtonClick = (target) => {  

    if(this.props.project){
      history.push({
        pathname: `/project-${this.state.course}/lecture-${target}`,
      });
    }else{
      history.push({
        pathname: `/course-${this.state.course}/lecture-${target}`,
      });
    }        
  }

  //Handle the lecture completion and navidation button clicks
  handleCompleteButtonClick = (target) => {  

    //Todo: Progress tracking - implement an action that records this lecture as complete for user    
    this.props.completeLecture(this.state.isProject?"project":"lecture",this.state.course, this.state.lecture)           

    if(this.props.project){
      if(target==0){
        history.push({
          pathname: `/projects`,
        });
      } else{
        history.push({
          pathname: `/project-${this.state.course}/lecture-${target}`,
        });
      }      
    }else{
      if(target==0){
        history.push({
          pathname: `/courses`,
        });
      } else{
        history.push({
          pathname: `/course-${this.state.course}/lecture-${target}`,
        });
      }      
    }   
  }

  //Handle the reset progress button click
  handleResetProgressButtonClick = () =>{
    this.props.undoCompleteLecture(this.state.isProject?"project":"lecture",this.state.course, this.state.lecture)
  }

  //Handle the expand click on the metadata card
  handleExpandClick = () => {    
    this.setState({
      expanded: !this.state.expanded})
  };

  //Return lecture video
  returnVideo(){
    const { classes } = this.props; 
    return(
      <div className={classes.iframeContainer}>
        <iframe
          className={classes.iframe}
          src={this.state.lectureVimeo}
          width="640"
          height="360"
          frameBorder="0"          
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  }

  //Return the metadata card for this lecture
  returnMetaCard(){    
    const { classes } = this.props; //Destructure data off the components props	
    return(
      <React.Fragment>
        <Card raised={false} className={classes.card}>
          
          <CardContent>
            {this.returnButtonSet()}              
          </CardContent>
          
          <CardContent className={classes.titleContainer}>
            <Typography className={classes.title}>{`${this.state.lecture}. ${this.state.lectureTitle}`}</Typography>
          </CardContent>

          <CardContent className={classes.subtitleContainer}>              
            <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"space-between"}}>
              <Typography className={classes.subTitle}><em>{this.state.section}</em></Typography>
          
              <CardActions disableSpacing>                
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="show more"
                >
                <ExpandMoreIcon style={{color:"#f13f64"}}/>
                </IconButton>
              </CardActions>
            </Grid>              
          </CardContent>          
       
        <Collapse in={this.state.expanded} timeout="auto" >
          <CardContent>
            <Grid container justify="center" spacing={4} >
              
              <Grid item xs={6} sm={6} md={6} >
                <Grid container spacing={4} >
                  <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-start", paddingBottom:"0px"}}>
                    <Typography className={classes.subTitle}>📂  Resources:</Typography>   
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-start", paddingTop:"0px"}}>
                    <ul>
                      {this.returnLectureFiles()}                      
                    </ul>   
                  </Grid> 
                </Grid> 
              </Grid>    

              <Grid item xs={6} sm={6} md={6} >
                <Grid container spacing={4} >
                  <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-start", paddingBottom:"0px"}}>
                    <Typography className={classes.subTitle}>🔗  Links:</Typography>   
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-start", paddingTop:"0px"}}>
                    <ul>         
                      {this.returnLectureLinks()}                                                                               
                    </ul>   
                  </Grid> 
                </Grid> 
              </Grid>   

            </Grid>   
            
          </CardContent>
        </Collapse>
        </Card>
      </React.Fragment>
    );
  }

  //Return list of lecture links
  returnLectureLinks(){
    const { classes } = this.props; 
    if(this.state.lectureFiles){  
      if(this.state.lectureLinks.length>0){      
        return(
          this.state.lectureLinks.map(link =>(        
              <li key={link.linkText}>
                <a  
                  style={{textDecoration:"none", color:"inherit"}}
                  href={link.url} 
                  target="_blank">
                  <Typography className={classes.menuItem} style={{textAlign:"left"}} >{link.linkText}</Typography>
                </a>
              </li>                    
          ))              
        );
      } else{
        return <li><Typography>No links for this lecture</Typography></li>
      }   
    }  
  }

  //Return list of lecture files
  returnLectureFiles(){
    const { classes } = this.props; 

    if(this.props.auth){
      if(this.state.lectureFiles){          
        if(this.state.lectureFiles.length>0){      
          return(
            this.state.lectureFiles.map(file =>(        
                <li key={file.linkText}>
                  <Link 
                    style={{textDecoration:"none", color:"inherit"}} 
                    to={{}}
                    href={{}}
                    onClick={(params) =>this.props.fileDownload(this.state.course, this.state.lecture, file.filename, this.state.isProject)}>
                    <Typography className={classes.menuItem} style={{textAlign:"left"}}>{file.linkText} </Typography>
                  </Link>                
                </li>                    
            ))              
          );
        } else{
          return <li><Typography>No resources for this lecture</Typography></li>
        }  
      }
    } else {
      if(this.state.lectureFiles){          
        if(this.state.lectureFiles.length>0){      
          return(            
            <li>
              <a style={{textDecoration:"none", color:"inherit"}}href={this.state.fileRedirectURL}>
                <Typography className={classes.menuItem} style={{textAlign:"left"}} >{this.state.fileRedirectMsg}</Typography>
              </a>
            </li>                                  
          );
        } else{
          return <li><Typography>No resources for this lecture</Typography></li>
        }  
      }
    }    
  }
  
  //Return lecture navigation buttons
  returnButtonSet(){
    const { classes } = this.props; //Destructure data off the components props	
        
    if((this.state.lecture>1)&&(this.state.lecture<this.state.noLectures)){
      //Return previous lecture and next lecture     
      return(
        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"space-between", marginTop:"0px", paddingTop:"0px"}}>
            <Button 
                className={classes.courseButton} 
                size="small"
                onClick={()=>this.handleButtonClick(this.state.lecture - 1)}
                > 
                  <ArrowBackRoundedIcon style={{ fontSize: 20 }} /> 
                  {`Lecture ${this.state.lecture - 1}`}                                   
            </Button> 
            <Button         
                className={classes.courseButton}             
                size="small"
                onClick={()=>this.handleButtonClick(this.state.lecture + 1)}
                > 
                  {`Lecture ${this.state.lecture + 1}`}
                  <ArrowForwardRoundedIcon style={{ fontSize: 20 }} />
            </Button>  
          </Grid>
        </React.Fragment>
      );

    } else if(this.state.lecture==1){
      //Return next lecture, no previous lecture
      return(
        <React.Fragment>    
          <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"0px", paddingTop:"0px"}}>
            <Button         
                className={classes.courseButton}             
                size="small"
                onClick={()=>this.handleButtonClick(this.state.lecture + 1)}
                > 
                  {`Lecture ${this.state.lecture + 1}`}
                  <ArrowForwardRoundedIcon style={{ fontSize: 20 }} />
            </Button>  
          </Grid>      
        </React.Fragment>
      );

    } else if(this.state.lecture==this.state.noLectures){
      //No next lecture, return previous lecture
      return(
        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-start", marginTop:"0px", paddingTop:"0px"}}>
            <Button 
                className={classes.courseButton} 
                size="small"
                onClick={()=>this.handleButtonClick(this.state.lecture - 1)}
                > 
                  <ArrowBackRoundedIcon style={{ fontSize: 20 }} />
                  {`Lecture ${this.state.lecture - 1}`}                                  
            </Button>      
          </Grid>     
        </React.Fragment>
      );
    }
    return null    
  }

  //Return the video and meta details card for this lecture
  returnVideoAndMetaCard = ()=>{
    const { classes } = this.props;     
    return(
      <Grid container justify="center" spacing={4}>
            <Grid item xs={12} sm={12} md={10} >
              {this.state.lectureVimeo && this.returnVideo()}
            </Grid>            

            <Grid item xs={12} sm={12} md={10} style={{textAlign: "center", marginTop:"0px", paddingTop:"0px"}}>
              {this.returnMetaCard()}
            </Grid>           
          </Grid>

    );
  }

  //Return right drawer burger button
  returnRightDrawerButton = ()=>{
    const { classes} = this.props;
      return(
        <Grid container justify="flex-start" spacing={2}>    
        
            <IconButton
                style={{marginLeft:'auto', marginTop:"0px", paddingTop:"0px"}}
                color="inherit"
                aria-label="Open drawer"
                onClick={this.toggleDrawerRight}               
              >
              <Typography style={{color:"#fff", fontWeight:"bold"}}>LECTURE INDEX</Typography>    
              <MenuIcon style={{color:"#f13f64"}}/>
            </IconButton>          
          </Grid>       
      );      
  }

  //Return the lecture index broken into sections
  returnLectureIndex = ()=>{        
    if(this.state.lectureList){      
      //Sort lectures into section so each section can be constructed individually with a header
      let sectionTitle = this.state.lectureList[0].section //First section title
      let section = []
      let allSections = []     
         
      for(let i=0; i<this.state.lectureList.length; i++){
        
        let lecture = this.state.lectureList[i]        

        if(lecture.section == sectionTitle){
          //Store the lecture id          
          section.push(lecture.lectureId)
        }else {
          //Start a new section          
          allSections.push(section)          
          section = []          
          sectionTitle = lecture.section                  
          section.push(lecture.lectureId)
        }

        //Store the last section (no new section title to trigger save operation)
        if(i==this.state.lectureList.length-1){
          allSections.push(section)
        }
      }     
            
      return(
        <React.Fragment>
          {allSections.map(section => (
            this.returnSection(section)
          ))}
        </React.Fragment>  
      );
    }
  }

  //Map overs array of arrays containing section ids and return section contents with header
  returnSection = (section)=>{    
    const { classes} = this.props;   
    return(
      <React.Fragment key={section[0]}>          
        <List className={classes.grow} >
          <Card raised={true} className={classes.indexCard}>
            <CardContent style={{ padding: "8px", backgroundColor:"#2a2e35" }}>     
              <Typography className={classes.indexSectionHeader}>{this.state.lectureList[section[0]-1].section}</Typography>
            </CardContent>
          </Card>
          {section.map(item => (
            
            <ListItem
              disabled={this.props.auth?false:this.state.lectureList[item-1].preview?false:true} //double ternery              
              button              
              className={classNames({
                [classes.menuItemActive]: this.state.lecture==item?true:false,
                [classes.menuItem]: this.state.lecture!=item?true:false
              })}
              key={item}
              component={Link}
              to={`/${this.props.project ? 'project':'course'}-${this.state.course}/lecture-${item}`}
            > {this.returnCompletionIcon(item)}
              {this.returnLectureTitle(item)}             
            </ListItem>
          ))}
        </List>             
      </React.Fragment>
    );
  }

  //Return the appropriate icon based on lecture completion recorded in component state
  returnCompletionIcon = (item) =>{
    //Identify which lectures have been completed for this course    
    let ids  = this.updateIndexCompletions(this.props.project?"project":"lecture", this.props.course) 
    let id = this.state.lectureList[item-1].lectureId    
    if(ids.includes(id)){
      return(
        <CheckCircleIcon style={{marginRight:"10px"}}/>
      )
    } else{
      return(
        <RadioButtonUncheckedIcon style={{marginRight:"10px"}}/>
      )
    }    
  }

  //Identify which lectures have been completed for this course
  updateIndexCompletions = (format, courseId) =>{
    let lectures = this.props.compLectures.filter(l =>{
      if(l.courseId == courseId && l.format==format){
        return l
      }     
    })    
    let ids = []
    lectures.forEach(l=>{
      ids.push(l.lectureId) 
    })    
    return ids
  }

  //Return title and include file icon where appropriate
  returnLectureTitle = (item)=>{
    const { classes} = this.props; 
    
    if(this.props.auth){
      //User is logged in
      if(this.state.lectureList[item-1].files.length>0){
        //Lecture has files
        return(        
          <React.Fragment>
            <ListItemText                             
              // primary={`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title} 📎`}
              primary={<Typography className={classes.indexItemText}>{`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title} 📎`}</Typography>}
              secondary={<Typography className={classes.indexItemSubText}><i>{`${this.state.lectureList[item-1].duration}`}</i></Typography>}
            />                                           
          </React.Fragment>
        );
      } else {
        //Lecture has no files
        return(
          <React.Fragment>
            <ListItemText                                 
              // primary={`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title}`}
              primary={<Typography className={classes.indexItemText}>{`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title}`}</Typography>}
              secondary={<Typography className={classes.indexItemSubText}><i>{`${this.state.lectureList[item-1].duration}`}</i></Typography>}
            />          
          </React.Fragment>
        );
      }
    } else {
      //User is not logged in
      if(this.state.lectureList[item-1].files.length>0){
        //Lecture has files
        if((this.state.lectureList[item-1].preview)&&(!this.state.isProject)){
          //Lecture is a preview and not part of a project
          return(        
            <React.Fragment>
              <ListItemText               
                primary={<Typography className={classes.indexItemText}>{`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title} 📎`}</Typography>}
                secondary={<Typography className={classes.indexItemSubText}><i>{`${this.state.lectureList[item-1].duration}`}</i> <span style={{color:"#33cc99"}}>(Preview)</span></Typography>}
              />                                           
            </React.Fragment>
          );
        } else {
          //Lecture is not a preview
          return(        
            <React.Fragment>
              <ListItemText                                          
                primary={<Typography className={classes.indexItemText}>{`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title} 📎`}</Typography>}
                secondary={<Typography className={classes.indexItemSubText}><i>{`${this.state.lectureList[item-1].duration}`}</i></Typography>}
              />                                           
            </React.Fragment>
          );
        }
      } else {
        //Lecture has no files
        if((this.state.lectureList[item-1].preview)&&(!this.state.isProject)){
          //Lecture is a preview and not part of a project
          return(        
            <React.Fragment>
              <ListItemText               
                primary={<Typography className={classes.indexItemText}>{`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title}`}</Typography>}
                secondary={<Typography className={classes.indexItemSubText}><i>{`${this.state.lectureList[item-1].duration}`}</i> <span style={{color:"#33cc99"}}>(Preview)</span></Typography>}
              />                                           
            </React.Fragment>
          );          
        } else {
          //Lecture is not a preview
          return(        
            <React.Fragment>
              <ListItemText                   
                primary={<Typography className={classes.indexItemText}>{`${this.state.lectureList[item-1].lectureId}. ${this.state.lectureList[item-1].title}`}</Typography>}
                secondary={<Typography className={classes.indexItemSubText}><i>{`${this.state.lectureList[item-1].duration}`}</i></Typography>}
              />                                           
            </React.Fragment>
          );
        }        
      }
    }  
  }

  //Return bottom button to complete and continue
  returnCompleteAndContinue = ()=>{
    const { classes } = this.props; //Destructure data off the components props	
    if(this.state.lectureList){          
      if(this.state.lecture<this.state.noLectures){
        return(
          <Grid container justify="center" spacing={4} style={{marginTop:'75px'}}>
            <Grid item xs={12} sm={12} md={10} >
              <Card raised={false} className={classes.cardBtm}>
                
                <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"space-between", marginTop:"0px", paddingTop:"0px"}}>              
                  <CardContent >                                
                    <Typography className={classes.subTitle} style={{bottom:"0"}}><em>Next lecture...</em></Typography>                                          
                  </CardContent> 
  
                  <CardContent>
                    {this.returnCompleteAndContinueButton()}              
                  </CardContent>   

                             
                </Grid>
  
                <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"space-between", marginTop:"0px"}} >
                  <CardContent className={classes.titleContainer} style={{paddingTop:"0px"}}>
                    <Typography className={classes.titleBtm}>
                      {`${this.state.lectureList[this.state.lecture].lectureId}. ${this.state.lectureList[this.state.lecture].title}`}
                    </Typography>                   
                  </CardContent>

                  <CardContent >  
                    {this.returnResetProgressButton()}                    
                  </CardContent>                  
                </Grid>
  
              </Card>
            </Grid>
          </Grid>
        )
      } else {        
        return (
          <Grid container justify="center" spacing={4} style={{marginTop:'130px'}}>
            <Grid item xs={12} sm={12} md={10} >
              <Card raised={false} className={classes.cardBtm}>
                
                <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"space-between", marginTop:"0px", paddingTop:"0px"}}>              
                  <CardContent >                                
                    <Typography className={classes.subTitle} style={{bottom:"0"}}><em>{this.props.project ? 'Project ':'Course '}complete</em></Typography>                                          
                  </CardContent> 
  
                  <CardContent>
                    {this.returnCompleteAndContinueButton()}              
                  </CardContent>              
                </Grid>  

                <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"0px"}} >                  
                  <CardContent >  
                    {this.returnResetProgressButton()}                    
                  </CardContent>                  
                </Grid>

              </Card>
            </Grid>
          </Grid>
        )
      } 
    } else{
      return null
    }
  }

  //Return complete and continue button
  returnCompleteAndContinueButton(){
    const { classes } = this.props; //Destructure data off the components props	
    if(this.state.lecture<this.state.noLectures){
      //Show complete and continue button
      return(
        <React.Fragment>    
          <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"0px", paddingTop:"0px"}}>
            <Button         
                className={classes.completeButton}             
                size="large"
                onClick={()=>this.handleCompleteButtonClick(this.state.lecture + 1)}
                > 
                  {this.props.auth? "Mark lecture complete and continue" : "Complete and continue"}                  
                  <CheckCircleIcon style={{ fontSize: 30, color:"fff" }} />                  
            </Button>  
          </Grid>      
        </React.Fragment>
      );
    } else {
      //No next lecture - this is the last lecture
      return(
        <React.Fragment>    
          <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"0px", paddingTop:"0px"}}>
            <Button         
                className={classes.completeButton}             
                size="large"
                onClick={()=>this.handleCompleteButtonClick(0)}
                > 
                  {this.props.auth? "Mark lecture complete" : "Complete"}                                
                  <CheckCircleIcon style={{ fontSize: 30, color:"fff" }} />                  
            </Button>  
          </Grid>      
        </React.Fragment>
      );
    }
  }

  //Return reset lecture progress button
  returnResetProgressButton(){
    const { classes } = this.props; //Destructure data off the components props	
    
    //Only return button for logged in users
    if(this.props.auth){
      return(      
        <React.Fragment> 
          <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"0px", paddingTop:"0px"}}>
            <Button         
                className={classes.resetButton}             
                size="small"
                onClick={()=>this.handleResetProgressButtonClick()}
                style={{display:"flex", justifyContent:"space-between"}}
                >                 
                  {`Reset lecture progress`}
                  <ReplayIcon style={{  color:"fff" }}/>                            
            </Button>   
          </Grid>           
        </React.Fragment>
      )
    }
    return null
  }

  //Return right drawer
  returnRightDrawer = ()=>{
    const { classes} = this.props;
    return(
      <Drawer
        anchor="right"
        open={this.state.rightOpen}
        variant="permanent"
        className={classNames(classes.drawerRight, {
          [classes.drawerOpenRight]: this.state.rightOpen,
          [classes.drawerCloseRight]: !this.state.rightOpen
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpenRight]: this.state.rightOpen,
            [classes.drawerCloseRight]: !this.state.rightOpen
          })
        }}
      >
        <Grid container justify="flex-start" spacing={2}>
          <Grid item
            className={classNames(classes.indexIcon, {
                [classes.indexIconOpen]: this.state.rightOpen,
                [classes.indexIconClosed]: !this.state.rightOpen
              })}
          >
            <IconButton onClick={this.toggleDrawerRight} >
                {this.state.rightOpen ? <ChevronRightIcon style={{color:"#f13f64"}} /> : <ChevronLeftIcon style={{color:"#f13f64"}} />}
            </IconButton>
          </Grid>
        </Grid>

        <Grid container justify="center" spacing={2}>

          <Grid item style={{ width:"100%", marginTop: "-15px" }}>            
              {this.returnLectureIndex()}            
          </Grid>
          
        </Grid>
      </Drawer>
    );
  }

  //Toggle open/closed state of right drawer
	toggleDrawerRight = () => {
		this.setState({
			rightOpen: !this.state.rightOpen
		});
	};

  //Return summy lecture content
  returnDummyContent = () =>{
    const { classes, children } = this.props;    
    return(       
          <React.Fragment>
            <Grid container justify="center" spacing={4} >
              <Grid item xs={12} sm={12} md={10} >
                <div style={{filter: 'blur(8px)'}}>
                  <Typography component="h2" className={classes.H1} > This will eventually be a helpful heading </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is also eventually going to be a great lecture, but for now it's just filler! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </Typography>                 
                </div>

                <Typography 
                  component="h2" 
                  className={classes.H1} 
                  style={{textAlign:'center', 
                          color:'#fff', 
                        }}> 
                          <em>Text lecture coming soon</em> 🏗️
                </Typography>
                
                <div style={{filter: 'blur(8px)'}}>
                  <CodeBlock>
                    {codeString}
                  </CodeBlock>   

                  <Typography component="h2" className={classes.H2} > A soon to be amazing subtitle! </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    More filler...for now! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </Typography>                                       
                </div>             
              </Grid>
            </Grid>            
          </React.Fragment>     
    );
  }

  returnDiscourseComments = () =>{
    const { classes, children } = this.props; 
    
    if((this.state.hasDiscourse)&&(this.props.auth)){
      return(
        <Grid container justify="center" spacing={4} style={{marginTop:'75px'}}>
          <Grid item xs={12} sm={12} md={10} >
            <Typography className={classes.title}>Comments and Q&A</Typography>
            <Typography className={classes.subTitle} style={{marginTop:'10px', marginBottom:'10px'}}><em>If you have a question on anything we covered in this lecture, post it below.</em></Typography>
            <DiscourseComments                                           
              topicId={this.state.discourseTopcId} 
            />
          </Grid>
        </Grid>
      ) 
    }
  }
 

  //Only return lecture content if it has been written otherwise return placeholder
  returnLectureContent = () =>{
    const { classes, children } = this.props;

    if(this.state.hasText){
      //All text lectures complete - no need to check
      return(  
        children        
      ); 
    } else{      
      //Only some lectures complete - check first
      if(this.state.completeLectures==null){
        return(this.returnDummyContent());
      }
      if(this.state.completeLectures.includes(this.state.lecture)){
        return(  
          children        
        );
      } else {
        return(this.returnDummyContent());
      }        
    }    
  }
  
	render() {	
    const { classes, children} = this.props;
		return (		
      <React.Fragment>
        <Feedback/>
        {this.returnRightDrawerButton()}
        {this.returnVideoAndMetaCard()}          
        {this.returnLectureContent()}        
        {this.returnCompleteAndContinue()}
        {this.returnDiscourseComments()}
        {this.returnRightDrawer()}           
      </React.Fragment>					     
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
    compLectures: state.completedLectures
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(LectureComponent));
