import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_32 extends React.Component {
  state={
    course: 19,
    lecture: 32, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this final section, we’ll use Python to implement the worked examples from section 3, Bending of Reinforced Concrete. In doing so, we’ll end up with two Jupyter Notebooks, one that calculates the required area of steel reinforcement for a given beam and loading specification and one that calculates the moment of resistance given a cross-section specification.  
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    We’ll start by implementing worked examples 3, 6 and 9, which focus on calculating the required area of steel for a given design moment. Together these will make up our first analysis notebook. 
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    Then, we’ll focus on examples 4, 5, 7 and 8, which are slightly more complex to implement. We’ll build our analysis notebook, bit by bit, progressively increasing the complexity as we go. 
                  </Typography>
                  <Typography paragraph className={classes.bodytext}>
                    The aim of this section is not necessarily to produce a notebook that can handle all possible combinations and permutations of rebar arrangement and section shape. Instead, this section acts more as a demonstration of what’s possible and helps to get you comfortable transferring from pen and paper calculations to code-based calculations. 
                  </Typography>                                  
                                
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_32));
