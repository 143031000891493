import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if (auth) {
				//If Authenticated, and email is admin email, render the component as requested
				if(auth.email==process.env.REACT_APP_ADMIN_EMAIL){
          return <Component {...rest} />;
        } else {
          return <Redirect to="/courses" />;
        }		
			} else if (auth === false) {
				//If auth request has been denied and false returned, redirect to login
				return <Redirect to="/login" />;
			} else {
				//If no auth data in state (yet), render the loading screen components
				return (
					<React.Fragment>
						<div style={{ flexGrow: 1 }}>
							<LinearProgress color="secondary" />
						</div>
						<div
							style={{
								height: "100vh",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Typography style={{fontFamily:"arial"}} color="secondary">
								Loading...
							</Typography>
						</div>
					</React.Fragment>
				);
			}
		}}
	/>
);

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
