import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1 = 
`def buildElementStiffnessMatrix(n, UG):
    """
    Build element stiffness matrix based on current position and axial force
    n = member index
    UG = vector of global cumulative displacements
    """
    
    #Calculate 'new' positions of nodes using UG
    node_i = members[n][0] #Node number for node i of this member
    node_j = members[n][1] #Node number for node j of this member 

    #Index of DoF for this member
    ia = 2*node_i-2 #horizontal DoF at node i of this member 
    ib = 2*node_i-1 #vertical DoF at node i of this member
    ja = 2*node_j-2 #horizontal DoF at node j of this member
    jb = 2*node_j-1 #vertical DoF at node j of this member 
    
    #Displacements
    d_ix = UG[ia,0]
    d_iy = UG[ib,0]
    d_jx = UG[ja,0]
    d_jy = UG[jb,0]
              
    #Extract current version of transformation matrix [T] 
    TM = TMs[n,:,:]        
                
    #Local displacements [u, v, w] using global cumulative displacements UG
    localDisp = np.matmul(TM,np.array([[d_ix, d_iy, d_jx, d_jy]]).T)
    u = localDisp[0].item()
    v = localDisp[1].item()
    
    #Calculate extension, e
    Lo = lengths[n]
    e = math.sqrt((Lo+u)**2 + v**2)-Lo
        
    #Calculate matrix [AA]
    a1 = (Lo+u)/(Lo+e)
    a2 = v/(Lo+e)
    AA = np.array([[a1,a2]])
    
    #Calculate axial load, P        
    P = P0[n] + (E*Areas[n]/Lo)*e
    
    #Calculate matrix [d]
    d11 = P*v**2
    d12 = -P*v*(Lo+u)
    d21 = -P*v*(Lo+u)
    d22 = P*(Lo+u)**2
    denominator = (Lo+e)**3
    
    d = (1/denominator)*np.array([[d11,d12],
                                  [d21,d22]])  
    
    #Calculate element stiffness matrix
    NL = np.matrix((AA.T*(E*Areas[n]/Lo)*AA)+d)
    k = TM.T*NL*TM      
    
    #Return element stiffness matrix in quadrants 
    K11 = k[0:2,0:2]
    K12 = k[0:2,2:4]
    K21 = k[2:4,0:2]
    K22 = k[2:4,2:4]
        
    return [K11, K12, K21, K22]`

const codeString2 = 
`def buildStructureStiffnessMatrix(UG):
    """
    Standard construction of Primary and Structure stiffness matrix
    Construction of non-linear element stiffness matrix handled in a child function
    """    
    Kp = np.zeros([nDoF,nDoF]) #Initialise the primary stiffness matrix
    
    for n, mbr in enumerate(members): 
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member
        
        #Construct (potentially) non-linear element stiffness matrix
        [K11, K12, K21,K22] = buildElementStiffnessMatrix(n, UG)            

        #Primary stiffness matrix indices associated with each node
        #i.e. node 1 occupies indices 0 and 1 (accessed in Python with [0:2])
        ia = 2*node_i-2 #index 0
        ib = 2*node_i-1 #index 1
        ja = 2*node_j-2 #index 2
        jb = 2*node_j-1 #index 3
        Kp[ia:ib+1,ia:ib+1] = Kp[ia:ib+1,ia:ib+1] + K11
        Kp[ia:ib+1,ja:jb+1] = Kp[ia:ib+1,ja:jb+1] + K12
        Kp[ja:jb+1,ia:ib+1] = Kp[ja:jb+1,ia:ib+1] + K21
        Kp[ja:jb+1,ja:jb+1] = Kp[ja:jb+1,ja:jb+1] + K22
         
    #Extract structure stiffness matrix
    restrainedIndex = [x - 1 for x in restrainedDoF] #Index for each restrained DoF

    #Delete rows and columns for restrained DoF
    Ks = np.delete(Kp,restrainedIndex,0) #Delete rows
    Ks = np.delete(Ks,restrainedIndex,1) #Delete columns
    Ks = np.matrix(Ks) # Convert Ks from numpy.ndarray to numpy.matrix
        
    return Ks`


class Lecture_17_24 extends React.Component {
  state={
    course: 17,
    lecture: 24, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Our approach to building the stiffness matrix in this lecture will be very similar to how we calculated the transformation matrices previously. We'll break up the task and split it across a <i>parent</i> and a <i>child</i> function. The parent function, <code className={classes.code}>buildStructureStiffnessMatrix()</code>, will be called from the main execution loop and will take the member number and current set of accumulated displacements as arguments. This will then loop through each element, and call the child function, <code className={classes.code}>buildElementStiffnessMatrix()</code> for each element. The child function will handle the actual calculation of the individual stiffness matrices while the parent function will take care of building these into the overall stiffness matrix for the structure. As it's defined first in the notebook, we'll walk through the child function first.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Function to build the local stiffness matrix for each member </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    In this function, we're implementing the calculation of the non-linear stiffness matrix we derived in lecture 17. Despite it actually looking like a long function, it's really just building up the matrix equation, bit by bit. The function takes the accumulated global displacements as an input argument so that the element displacement can be captured in the stiffness matrix. We also pass in the element number which is required to extract the correct transformation matrix from our 3D data structure storing all transformation matrices, <code className={classes.code}>TMs</code>. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Once we work down through the various steps of the calculation (documented pretty well with code comments, so I won't repeat the discussion), we compute the local element stiffness matrix on line 55 and transfer it to global coordinates on line 56. Then, for convenience, we split the stiffness matrix into four quadrants before returning it to the parent function at the end.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography component="h2" className={classes.H2} > Function to build the structure stiffness matrix </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The parent function implements our standard code for building a stiffness matrix. We saw this code in the prerequisite 2D truss analysis course. In summary, we initialise a 2D matrix of zeros as a template for our primary stiffness matrix, <code className={classes.code}>Kp</code>. Then, we loop through each element, calling the child function each time, calculating the non-linear stiffness matrix for the element. Once the four quadrants of the global element stiffness matrix are returned, we can slot them into the correct locations within the primary stiffness matrix, using the indices of the DoFs for each element. Again, being careful to add to, rather than overwrite, the data within the primary stiffness matrix. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can reduce the primary stiffness matrix down to the structure stiffness matrix, <code className={classes.code}>Ks</code> by deleting the rows and columns corresponding to restrained degrees of freedom. Finally, the parent function returns the structure stiffness matrix, <code className={classes.code}>Ks</code>. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    That's it for this lecture. In the next lecture, we'll write the function to solve the structure for the current iteration. 
                  </Typography>
                                                                 
                </Grid>                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_24));
