import React, {Suspense} from 'react';
import {asin, cos, atan2, dot, matrix, add, subtract, multiply, divide, subset, index, cross, sin} from 'mathjs'
import * as d3 from "d3";
import { Canvas, useThree } from '@react-three/fiber';
import { useControls, folder, button } from 'leva'
import {  OrbitControls, 
          GizmoHelper, 
          GizmoViewcube, 
          GizmoViewport,
          Sky,          
          OrthographicCamera,
          PerspectiveCamera,
          Line } from '@react-three/drei';
import {  Node3D,          
          Element3D,
          Pin,           
          Force, 
          ReactionForce, 
          WireSphere, 
          ElementsAxialForces, 
          WhiteNode} from './components/Truss_3D/Truss_3D_components'
import ColorbarWrapper from "../../Visualisations/Colormap/ColorbarWrapper";
import Grid from "@material-ui/core/Grid";

//Calculate scene scale and calculate Orthographic camera zoom parameters
function updateOrthoCamera(props){
  
  //Initialise parameters
  let dx = 0 // x-offset to center of bounding box
  let dy = 0 // y-offset to center of bounding box
  let dz = 0 // z-offset to center of bounding box
  let bbWidth = 5 //bounding box width (x)
  let bbHeight = 5 //bounding box height (y)
  let bbDepth = 5 //bounding box height (z)

  //If nodes have been passed
  if(props.nodes){
    if(props.nodes.length==1){
      dx = props.nodes[0][1]
      dy = props.nodes[0][2]
      dz = props.nodes[0][3]
    } else {
      let xCoords = []
      let yCoords = []
      let zCoords = []
      props.nodes.map(node=>{
        if(node.length>2){
          xCoords.push(node[1])
          yCoords.push(node[2])
          zCoords.push(node[3])
        }
      })      
      let minX = Math.min(...xCoords)
      let maxX = Math.max(...xCoords)
      let minY = Math.min(...yCoords)
      let maxY = Math.max(...yCoords)
      let minZ = Math.min(...zCoords)
      let maxZ = Math.max(...zCoords)
      
      bbWidth = maxX - minX == 0 ? 5 : maxX - minX
      bbHeight = maxY - minY == 0 ? 5 : maxY - minY
      bbDepth = maxZ - minZ == 0 ? 5 : maxZ - minZ
      // dx = minX + (maxX - minX)/2
      // dy = minY + (maxY - minY)/2
      // dz = minZ + (maxZ - minZ)/2
    }
  } 

  let scaleFactor = 0.05*Math.min(bbWidth, bbHeight, bbDepth)
  return [dx, dy, dz, bbWidth, bbHeight, bbDepth, scaleFactor]
}

//Calculate the centre of the structure bounding box to set OrbitControls target
function updateCameraPosition(props){
  //Initialise parameters
  let cx = 0 // x-offset to center of bounding box
  let cy = 0 // y-offset to center of bounding box
  let cz = 0 // z-offset to center of bounding box

  //If nodes have been passed
  if(props.nodes){
    if(props.nodes.length==1){
      cx = props.nodes[0][1]
      cy = props.nodes[0][2]
      cz = props.nodes[0][3]
    } else {  
      let xCoords = []
      let yCoords = []
      let zCoords = []
      props.nodes.map(node=>{
        if(node.length>2){
          xCoords.push(node[1])
          yCoords.push(node[2])
          zCoords.push(node[3])
        }
      })      
      let minX = Math.min(...xCoords)
      let maxX = Math.max(...xCoords)
      let minY = Math.min(...yCoords)
      let maxY = Math.max(...yCoords)
      let minZ = Math.min(...zCoords)
      let maxZ = Math.max(...zCoords)

      cx = minX + (maxX - minX)/2
      cy = minY + (maxY - minY)/2
      cz = minZ + (maxZ - minZ)/2
    }
  }
  return [cx, cy,cz]
}

//Return colorbar
const returnColorbar = (props) =>{    
  let colorScale = null
  let memberForces = props.memberForces?props.memberForces:null
  let forceValues = []    
  if(memberForces){
    memberForces.map(mbr =>{
      forceValues.push(mbr['force'])
    })
  }  

  //Determine a colorscale using D3
  if(forceValues.length>0){      
    colorScale = d3
      .scaleLinear()      
      // .range(["red", "white", "blue"])
      .range(["red", "#8bfb6f", "blue"])
      .domain([Math.min(...forceValues), 0, Math.max(...forceValues)]);            

    return(
      <ColorbarWrapper
        data={forceValues}
      />
    );
  } else {
    return null
  }
}

//Contains all of the elements in our scene - gets passed to the canvas
function Scene(props){ 
  //Calculate scene scale and ortho camera zoom parameters
  let [dx, dy, dz, bbWidth, bbHeight, bbDepth, scaleFactor] = updateOrthoCamera(props)
  let [orbTargetX, orbTargetY, orbTargetZ] = updateCameraPosition(props)

  const maps = {
    Continuous: 'continuous',
    Binary: 'binary'    
  }

  const [{showSky, mbrPBR, ambLight, mbrColor, nodeColor, metalness, roughness, showGrid3D, showWire, showNodeNumbers, scale, showForces, resetZoom, showReactions, showAxial, map, showAxialAnnotations, showDeflections, defScale, showDefAnnotations }, set] = useControls(()=>(
    { 'Viewport Options': 
        folder({            
          showSky:{value: true, label:'Show sky'},          
          ambLight:{value: 1, min:0, max:10, label: 'Ambient lighting'},          
          showGrid3D:{value: true, label:'Toggle viewport grid'},
          resetZoom: {value: true, label:'Reset viewport on update'},                 
          'Materials':folder({
            mbrPBR: {value: true, label:'Better materials'},
            mbrColor:{value: '#747474', label: 'Member colour'},
            nodeColor:{value: '#ff4343', label: 'Node colour'},
            metalness:{value:0.95, min:0, max:1, label: 'Metalness'},
            roughness:{value:0.1, min:0, max:1, label: 'Roughness'},
          },{ collapsed: true, color:'#7affd3'}),
        },{ collapsed: true, color:'#3c9'}),
      General: 
        folder({          
          scale: { value: scaleFactor, min: 0.1*scaleFactor, max: 10*scaleFactor, step: .1, label:'Scale'}, 
          showWire: { value: false, label: 'Show wireframe' },
          showNodeNumbers: { value: false, label: 'Show node numbers' },            
          showForces: {value: true, label:'Show applied forces'},
        },{ collapsed: false, color:'#3c9'}),
      Reactions:
        folder({
          showReactions: {value: false, label: 'Show reactions'}
        },{ collapsed: true, color:'#3c9'}), 
      'Axial Forces':
        folder({
          showAxial: {value: false, label: 'Show axial forces', onChange: (value) => set({ showWire: value, showSky: !value }), transient: false},
          map: { options: maps, label: 'Select force map', render: (get) => get('Axial Forces.showAxial')},
          showAxialAnnotations: { value: false, label: 'Show force values', render: (get) => get('Axial Forces.showAxial') },
        },{ collapsed: true, color:'#3c9'}), 
      Deflection:
        folder({
          showDeflections: {value: false, label: 'Show deflected shape'},          
          defScale: { value: 1, min: 1, max: 10000, step: 100, label:'Deflection scale', render: (get) => get('Deflection.showDeflections')},
          showDefAnnotations: {value: false, label: 'Show deflection values', render: (get) => get('Deflection.showDeflections') }
        },{ collapsed: true, color:'#3c9'})   
    })
  )

  scaleFactor=scale  

  //Update camera matrix
  const { camera, size: { width, height } } = useThree(); 
  if(resetZoom){
    camera.zoom = 0.4*Math.min(
      width / bbWidth,
      height / bbHeight
    );   
    camera.updateProjectionMatrix();
  }

  //Return nodes
  const returnNodes = (props)=>{
    if(props.nodes){ 
      return props.nodes.map(node=>{      
        return <Node3D key={node[0]} 
                      number={node[0]} 
                      scale={scaleFactor} 
                      rotation={[0,0,0]} 
                      position={[node[1]-dx,node[2]-dy,[node[3]-dz]]} 
                      showNodeNumbers={showNodeNumbers} 
                      color={nodeColor} 
                      metalness={metalness} 
                      roughness={roughness} 
                      mbrPBR={mbrPBR}/>                 
      })
    }
  }

  //Return supports [MODELS NEED TO BE UPDATED]
  const returnSupports = (props) =>{        
    if((props.restraints) && (props.nodes)){      
      return props.restraints.map(restraint=>{   
        let Node =  props.nodes.find(node =>{return node[0]==restraint[0]})
        if(Node){
          let [ind, xCoord, yCoord, zCoord] = Node
          if((restraint[1]) && (restraint[2]) && (restraint[3])){    //Pin XYZ
            return(
              <>
                <Pin key={restraint[0]} scale={scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />  
                <Pin key={restraint[0]+1} scale={scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />  
                <Pin key={restraint[0]+2} scale={scaleFactor} rotation={[0,-Math.PI/2,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />  
              </>
            ) 
          } else if((restraint[1]) && (!restraint[2]) && (!restraint[3])){            
            return <Pin key={restraint[0]} scale={scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />    //Pin X
          } else if((!restraint[1]) && (restraint[2]) && (!restraint[3])){            
            return <Pin key={restraint[0]+1} scale={scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />   //Pin Y
          } else if((!restraint[1]) && (!restraint[2]) && (restraint[3])){            
            return <Pin key={restraint[0]+2} scale={scaleFactor} rotation={[0,-Math.PI/2,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} /> //Pin Z
          } else if((restraint[1]) && (restraint[2]) && (!restraint[3])){   //Pin XY         
            return(
              <>
                <Pin key={restraint[0]} scale={scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />
                <Pin key={restraint[0]+1} scale={scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} /> 
              </>
            )
          } else if((restraint[1]) && (!restraint[2]) && (restraint[3])){ //Pin XZ             
            return(
              <>
                <Pin key={restraint[0]} scale={scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />
                <Pin key={restraint[0]+2} scale={scaleFactor} rotation={[0,-Math.PI/2,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />
              </>
            )           
          } else if((!restraint[1]) && (restraint[2]) && (restraint[3])){ //Pin YZ         
            return(
              <>
                <Pin key={restraint[0]+1} scale={scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />
                <Pin key={restraint[0]+2} scale={scaleFactor} rotation={[0,-Math.PI/2,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} />
              </>
            )            
          }
        }
      })
    }
  }

  //Return elements
  const returnElements = (props) =>{    
    if((props.elements) && (props.nodes)){      
      return props.elements.map(element=>{
        if((element.length>=5)){                       
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          
          let memberForce = null
          if(props.memberForces){
            memberForce = props.memberForces.find(force=>{return force['memberId'] == element[0]})
          }          
          
          if((NodeI) && (NodeJ)){              
            if((NodeI.length==4) && (NodeJ.length==4)){               
              let [indI, xi, yi, zi] = NodeI
              let [indJ, xj, yj, zj] = NodeJ
              let length = Math.pow(Math.pow((xi-xj),2) + Math.pow((yi-yj),2) + Math.pow((zi-zj),2),0.5)

              // Rotation matrix for the element
              let theta_x, theta_y, theta_z;          
              try{                                  
                let local_x_vector = subtract(matrix([xj, yj, zj]),matrix([xi, yi, zi]))
                let local_x_unit = divide(local_x_vector,length)                                
                let R_arbitrary = matrix([[1,0,0],[0,cos(Math.PI/4), -sin(Math.PI/4)],[0,sin(Math.PI/4),cos(Math.PI/4)]])   
                let R11 = subset(local_x_unit, index(0))
                let R21 = subset(local_x_unit, index(1))
                let R31 = subset(local_x_unit, index(2))                
                if((R11==1)&&(R21==0)&&(R31==0)){                  
                  R_arbitrary = matrix([[cos(Math.PI/4), 0, sin(Math.PI/4)],[0,1,0],[-sin(Math.PI/4),0,cos(Math.PI/4)]])  //Rotate about Y-axis
                } else if((R11==-1)&&(R21==0)&&(R31==0)){
                  R_arbitrary = matrix([[cos(Math.PI/4), 0, sin(Math.PI/4)],[0,1,0],[-sin(Math.PI/4),0,cos(Math.PI/4)]])  //Rotate about Y-axis
                }                
                let vector_in_plane = multiply(R_arbitrary,local_x_unit)//Apply arbitrary rotation to get another vector to define local x-y plane
                let local_y_vector = subtract(vector_in_plane, multiply(dot(vector_in_plane, local_x_unit),local_x_unit))                
                let ly_x = subset(local_y_vector, index(0))
                let ly_y = subset(local_y_vector, index(1))
                let ly_z = subset(local_y_vector, index(2))
                let yMag = Math.pow(Math.pow((ly_x),2) + Math.pow((ly_y),2) + Math.pow((ly_z),2),0.5)
                let local_y_unit = divide(local_y_vector,yMag)
                let local_z_unit = cross(local_x_unit,local_y_unit)
                let rotationMatrix = matrix([local_x_unit,local_y_unit,local_z_unit])
                let R33 = subset(local_z_unit, index(2))                       
                let R13 = subset(local_z_unit, index(0))
                let R23 = subset(local_z_unit, index(1))
                let R12 = subset(local_y_unit, index(0))
              
                //Euler angles [Rx,Ry,Rz] (intrinsic)
                theta_y = asin(R13)
                let R22 =  subset(local_y_unit, index(1))                
                let th = 10**-6
                if (Math.abs(R13-1)<th){ //theta_y = pi/2                              
                  //Gimbol lock case
                  theta_x = atan2(R21,R22)
                  theta_z = 0
                } else if (Math.abs(R13+1)<th){ //theta_y = -pi/2
                  //Gimbol lock case                  
                  theta_x = atan2(-R21,R22)
                  theta_z = 0                  
                } else{             
                  //Standard (non-gimbol lock) case
                  theta_x = -atan2(divide(R23,cos(theta_y)),divide(R33,cos(theta_y)))
                  theta_z = -atan2(divide(R12,cos(theta_y)),divide(R11,cos(theta_y)))
                }
              } catch{
                return
              }

              return <Element3D key={element[0]} 
                                  scale={[length, scaleFactor, scaleFactor]} 
                                  rotation={[theta_x,theta_y,theta_z]}                                  
                                  position={[xi-dx,yi-dy,zi-dz]} 
                                  memberForce={memberForce ? memberForce['force'] : null}
                                  color={mbrColor}
                                  metalness={metalness} 
                                  roughness={roughness}
                                  mbrPBR={mbrPBR}
                      />
            }
          }
        }         
      })
    }
  }

  //Return x-direction forces
  const returnForces_x = (props) =>{
    if((props.forces) && (props.nodes)){
      return props.forces.map(force=>{   
        let Node =  props.nodes.find(node =>{return node[0]==force[0]})
        if(Node){
          let [ind, xCoord, yCoord, zCoord] = Node
          if(force[1] >0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={force[1]} color={'#2E78CC'}/>  //Force
          } else if(force[1] <0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,-Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={force[1]} color={'#2E78CC'}/>  //Force
          } else{
            return null
          }
        }
      })
     }
  }

  //Return y-direction forces
  const returnForces_y = (props) =>{
    if((props.forces) && (props.nodes)){
      return props.forces.map(force=>{   
        let Node =  props.nodes.find(node =>{return node[0]==force[0]})
        if(Node){
          let [ind, xCoord, yCoord, zCoord] = Node
          if(force[2] >0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,Math.PI]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={force[2]} color={'#2E78CC'} offset='below'/>  //Force
          } else if(force[2] <0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={force[2]} color={'#2E78CC'} offset='above'/>  //Force
          } else{
            return null
          }
        }
      })
     }
  }

  //Return z-direction forces
  const returnForces_z = (props) =>{
    if((props.forces) && (props.nodes)){
      return props.forces.map(force=>{   
        let Node =  props.nodes.find(node =>{return node[0]==force[0]})
        if(Node){
          let [ind, xCoord, yCoord, zCoord] = Node
          if(force[3] >0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[-Math.PI/2,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={force[3]} color={'#2E78CC'} offset='below'/>  //Force
          } else if(force[3] <0){
            return <Force key={force[0]} scale={0.75*scaleFactor} rotation={[Math.PI/2,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={force[3]} color={'#2E78CC'} offset='above'/>  //Force
          } else{
            return null
          }
        }
      })
     }
  }

  //Return x-reaction forces
  const returnReactions_x = props =>{    
    if((props.reactions) && (props.nodes)){
      return props.reactions.map(reaction=>{
        let Node =  props.nodes.find(node =>{return node[0]==reaction['nodeId']})
        let Restraint = props.restraints.find(restraint =>{return restraint[0]==reaction['nodeId']})
        if(Node){
          let [indN, xCoord, yCoord, zCoord] = Node
          let [indR, restrainedInX, restrainedInY, restrainedInZ] = Restraint

          if((reaction['xReaction'] >0) && restrainedInX){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={reaction['xReaction']} />  //Force
          } else if((reaction['xReaction'] <0) && restrainedInX){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,-Math.PI/2]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={reaction['xReaction']} />  //Force
          } else{
            return null
          }
        }
      })
    }
  }

  //Return y-reaction forces
  const returnReactions_y = props =>{
    if((props.reactions) && (props.nodes)){
      return props.reactions.map(reaction=>{
        let Node =  props.nodes.find(node =>{return node[0]==reaction['nodeId']})
        let Restraint = props.restraints.find(restraint =>{return restraint[0]==reaction['nodeId']})
        if(Node){
          let [indN, xCoord, yCoord, zCoord] = Node
          let [indR, restrainedInX, restrainedInY, restrainedInZ] = Restraint

          if((reaction['yReaction'] >0) && restrainedInY){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,Math.PI]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={reaction['yReaction']} offset='below'/>  //Force            
          } else if((reaction['yReaction'] <0) && restrainedInY){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[0,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={reaction['yReaction']} offset='above'/>  //Force
          } else{
            return null
          }
        }
      })
    }  
  }

  //Return z-reaction forces
  const returnReactions_z = props =>{
    if((props.reactions) && (props.nodes)){
      return props.reactions.map(reaction=>{
        let Node =  props.nodes.find(node =>{return node[0]==reaction['nodeId']})
        let Restraint = props.restraints.find(restraint =>{return restraint[0]==reaction['nodeId']})
        if(Node){
          let [indN, xCoord, yCoord, zCoord] = Node
          let [indR, restrainedInX, restrainedInY, restrainedInZ] = Restraint

          if((reaction['zReaction'] >0) && restrainedInZ){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[-Math.PI/2,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={reaction['yReaction']} offset='below'/>  //Force            
          } else if((reaction['zReaction'] <0) && restrainedInZ){
            return <ReactionForce key={reaction['nodeId']} scale={0.75*scaleFactor} rotation={[Math.PI/2,0,0]} position={[xCoord-dx,yCoord-dy,zCoord-dz]} value={reaction['yReaction']} offset='above'/>  //Force
          } else{
            return null
          }
        }
      })
    }  
  }

  //Return scene gizmos
  const returnGizmos = () =>{
    return(
      <>
        {/* <GizmoHelper alignment="top-right" margin={[50, 50]}>        
          <GizmoViewcube labelColor="black" color="#33cc99" hoverColor="#6ae6d0"/>  
        </GizmoHelper> */}

        <GizmoHelper alignment="bottom-left" margin={[50, 50]} >
          <GizmoViewport axisColors={['#d65050', '#33cc99', '#2E78CC']} labelColor="black" />            
        </GizmoHelper>
      </>
    )
  }

  //Return nodes for wireframe view
  const returnWireNodes = (props) =>{
    if(props.nodes){ 
      return props.nodes.map(node=>{                
        return <WireSphere 
                key={node[0]} 
                number={node[0]} 
                scale={scaleFactor} 
                rotation={[0,0,0]} 
                position={[node[1]-dx,node[2]-dy,node[3]-dz]} 
                color={"#d65050"}
                showNodeNumbers={showNodeNumbers}
               />       
      })
    }
  }

  //Return elements for wireframe view
  const returnWireElements = (props) =>{

    if((props.elements) && (props.nodes)){      
      return props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi, zi] = NodeI
            let [indJ, xj, yj, zj] = NodeJ
            return(
              <Line
                key={element[0]}
                points={[[xi-dx, yi-dy, zi-dz], [xj-dx, yj-dy, zj-dz]]}  
                color="#d65050" 
                lineWidth={.5}  
                />
            );
          }
        }         
      })
    }
  }

  //Return deflected nodes
  const returnDeflectedNodes = (props)=>{
    if((props.nodes) && (props.nodalDisplacements)){ 
      return props.nodes.map(node=>{   
        let Def = props.nodalDisplacements.find(def =>{return def['nodeId']==node[0]})     
        let Dx = Def['xDisp']
        let Dy = Def['yDisp'] 
        let Dz = Def['zDisp']        
        return <WireSphere 
                key={node[0]} 
                number={node[0]} 
                scale={scaleFactor} 
                rotation={[0,0,0]} 
                position={[(node[1]-dx)+(defScale*Dx),(node[2]-dy)+(defScale*Dy),(node[3]-dz)+(defScale*Dz)]} 
                color={"#33cc99"}
                showDefAnnotations={showDefAnnotations}
                dx={Dx}
                dy={Dy}
                dz={Dz}
               />      
      })
    }
  }

  //Return deflected shape
  const returnDeflectedElements = (props) =>{  
    if((props.nodes)&& (props.elements) &&(props.nodalDisplacements)){
      return props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          let DefI = props.nodalDisplacements.find(def =>{return def['nodeId']==element[1]})
          let DefJ = props.nodalDisplacements.find(def =>{return def['nodeId']==element[2]})
          
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi, zi] = NodeI
            let [indJ, xj, yj, zj] = NodeJ
            let dxI = DefI['xDisp']
            let dyI = DefI['yDisp']
            let dzI = DefI['zDisp']
            let dxJ = DefJ['xDisp']
            let dyJ = DefJ['yDisp']   
            let dzJ = DefJ['zDisp']          

            return(
              <Line
                key={element[0]}
                points={[[(xi-dx)+(defScale*dxI), (yi-dy)+(defScale*dyI), (zi-dz)+(defScale*dzI)], [(xj-dx)+(defScale*dxJ), (yj-dy)+(defScale*dyJ), (zj-dz)+(defScale*dzJ)]]} 
                color="#33cc99"
                lineWidth={1}              
              />
            );
          }
        }         
      })
     
    }
  }

  //Return members coloured based on axial force
  const returnAxialForces = (props, scaleFactor, dx, dy, dz, map) =>{
    let mbrs = []; //Initialise an empty array of member objects
    let mbr = {}; //Initialise an empty object to hold key:value pair data for each object
    let forceThreshold = null //Initialise a value for the min force threshold
    let forceValues = [] //Initialise a container to hold all force values (positive and negative)

    //Determine max axial force magnitude observed
    if((props.elements) && (props.memberForces)){            
      let forceMagnitudes = [] 
      props.elements.map(element=>{
        let mbrForce = props.memberForces.find(force=>{return force['memberId'] == element[0]})            
        forceMagnitudes.push(Math.abs(mbrForce['force']))
        forceValues.push(mbrForce['force'])
      })       
      forceThreshold = 0.0001*Math.max(forceMagnitudes)< 0.001 ? 0.0001*Math.max(forceMagnitudes) : 0.001
    }

    //Determine a colorscale using D3
    let colorScale = null
    if(forceValues.length>0){
      //Build color scale
		colorScale = d3
      .scaleLinear()      
      .range(["red", "#8bfb6f", "blue"])
      .domain([Math.min(...forceValues), 0, Math.max(...forceValues)]);     
    }
     
    //Extract data to draw members
    if((props.elements) && (props.nodes) && (props.memberForces)){            
      //Compile object data for instancing later
      props.elements.map(element=>{
        if((element.length>=5)){          
          let NodeI = props.nodes.find(node =>{return node[0]==element[1]})                    
          let NodeJ = props.nodes.find(node =>{return node[0]==element[2]})
          
          let memberForce = null
          let mbrForce = null
          if(props.memberForces){
            mbrForce = props.memberForces.find(force=>{return force['memberId'] == element[0]})
            memberForce = mbrForce['force']
          }
                  
          if((NodeI) && (NodeJ)){
            let [indI, xi, yi, zi] = NodeI
            let [indJ, xj, yj, zj] = NodeJ
            let length = Math.pow(Math.pow((xi-xj),2) + Math.pow((yi-yj),2) + Math.pow((zi-zj),2),0.5)

            // Rotation matrix for the element
            let theta_x, theta_y, theta_z;          
            try{                                  
              let local_x_vector = subtract(matrix([xj, yj, zj]),matrix([xi, yi, zi]))
              let local_x_unit = divide(local_x_vector,length)                                
              let R_arbitrary = matrix([[1,0,0],[0,cos(Math.PI/4), -sin(Math.PI/4)],[0,sin(Math.PI/4),cos(Math.PI/4)]])   
              let R11 = subset(local_x_unit, index(0))
              let R21 = subset(local_x_unit, index(1))
              let R31 = subset(local_x_unit, index(2))                
              if((R11==1)&&(R21==0)&&(R31==0)){                  
                R_arbitrary = matrix([[cos(Math.PI/4), 0, sin(Math.PI/4)],[0,1,0],[-sin(Math.PI/4),0,cos(Math.PI/4)]])  //Rotate about Y-axis
              } else if((R11==-1)&&(R21==0)&&(R31==0)){
                R_arbitrary = matrix([[cos(Math.PI/4), 0, sin(Math.PI/4)],[0,1,0],[-sin(Math.PI/4),0,cos(Math.PI/4)]])  //Rotate about Y-axis
              }                
              let vector_in_plane = multiply(R_arbitrary,local_x_unit)//Apply arbitrary rotation to get another vector to define local x-y plane
              let local_y_vector = subtract(vector_in_plane, multiply(dot(vector_in_plane, local_x_unit),local_x_unit))                
              let ly_x = subset(local_y_vector, index(0))
              let ly_y = subset(local_y_vector, index(1))
              let ly_z = subset(local_y_vector, index(2))
              let yMag = Math.pow(Math.pow((ly_x),2) + Math.pow((ly_y),2) + Math.pow((ly_z),2),0.5)
              let local_y_unit = divide(local_y_vector,yMag)
              let local_z_unit = cross(local_x_unit,local_y_unit)
              let rotationMatrix = matrix([local_x_unit,local_y_unit,local_z_unit])
              let R33 = subset(local_z_unit, index(2))                       
              let R13 = subset(local_z_unit, index(0))
              let R23 = subset(local_z_unit, index(1))
              let R12 = subset(local_y_unit, index(0))
            
              //Euler angles [Rx,Ry,Rz] (intrinsic)
              theta_y = asin(R13)
              let R22 =  subset(local_y_unit, index(1))                
              let th = 10**-6
              if (Math.abs(R13-1)<th){                    
                //Gimbol lock case
                theta_x = atan2(R21,R22)
                theta_z = 0
              } else if (Math.abs(R13+1)<th){
                //Gimbol lock case                  
                theta_x = atan2(-R21,R22)
                theta_z = 0                  
              } else{             
                //Standard (non-gimbol lock) case
                theta_x = -atan2(divide(R23,cos(theta_y)),divide(R33,cos(theta_y)))
                theta_z = -atan2(divide(R12,cos(theta_y)),divide(R11,cos(theta_y)))
              }
            } catch{
              return
            }
            
            let color = getElementColor(memberForce, forceThreshold, map, colorScale)            

            //Package up data
            mbr.xi = xi
            mbr.yi = yi
            mbr.zi = zi
            mbr.theta_x = theta_x
            mbr.theta_y = theta_y
            mbr.theta_z = theta_z
            mbr.length = length
            mbr.memberForce = memberForce            
            mbr.color = color
            mbr.xCentre = xi + 0.5*(xj-xi)
            mbr.yCentre = yi + 0.5*(yj-yi)
            mbr.zCentre = zi + 0.5*(zj-zi)
            mbrs.push(mbr)
            mbr={}
          }
        }         
      })    
    }
    return( 
      <ElementsAxialForces         
        {...props} 
        mbrs={mbrs}
        scaleFactor={scaleFactor}
        dx={dx}
        dy={dy} 
        dz={dz} 
        showAxialAnnotations={showAxialAnnotations}
      />    
    ); 
  }

  //Return element color based on internal axial force magnitude
  const getElementColor = (force, threshold, map, colorScale) =>{   

    if(map=='binary'){
      if(Math.abs(force)<threshold){
        return 'white'
      }else if(force>threshold){        
        return 'blue'
      } else if(force<threshold){        
        return 'red'
      }
    } else {
      return colorScale(force)
    }   
}

  //Return nodes meshes with no color
  const returnWhiteNodes = (props)=>{
    if(props.nodes){ 
      return props.nodes.map(node=>{      
        return <WhiteNode key={node[0]} number={node[0]} scale={scaleFactor} rotation={[0,0,0]} position={[node[1]-dx,node[2]-dy,node[3]-dz]} showNodeNumbers={showNodeNumbers} />          
      })
    }

  }

  return(
    <>
      <ambientLight intensity={ambLight}/>                   
      <Suspense fallback={null}>    
        <>                
          {returnGizmos()}                      
          {showGrid3D ? <gridHelper args={[50,50, `#f13f64`, `#2f2f2f`]}/> : null}      
          
          {showSky ? <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} /> : null}
                    
          {showWire ? returnWireNodes(props) : returnNodes(props)}   
          {showWire ? returnWireElements(props) : returnElements(props)}  

          {showForces ? returnForces_x(props) : null}
          {showForces ? returnForces_y(props) : null}
          {showForces ? returnForces_z(props) : null}

          {showReactions ? null: returnSupports(props)} 
          {showReactions ? returnReactions_x(props): null}
          {showReactions ? returnReactions_y(props): null}
          {showReactions ? returnReactions_z(props): null}
                                
          {showAxial ? returnAxialForces(props, scaleFactor, dx, dy, dz, map): null}  
          {showAxial ? returnWhiteNodes(props): null}  


          {showDeflections ? returnDeflectedElements(props): null}
          {showDeflections ? returnDeflectedNodes(props): null}              
        </>         
      </Suspense>
      <OrbitControls target={[orbTargetX, orbTargetY, orbTargetZ]} />  
    </>
  );
}

function Truss3DScene(props){   
  
  const [{ perspective, windowHeight}, set] = useControls(()=>(
    {     
      'Viewport Options': 
        folder({  
          perspective:{value: 'Orthographic', options:['Orthographic', 'Perspective'], label:'View projection'},       
          windowHeight:{value: 700, min:700, max:1500, label:'Window height'}          
        },{ collapsed: true, color:'#3c9'})      
    })
  )

  return(  
    <>
    <Grid container direction="row" display="flex" justify="space-between" spacing={1}>
      <Grid item xs={12} sm={12} md={12} style={{height: windowHeight+"px"}}>        
        <Canvas           
          id={'viewport'}
          style={{border:"solid .5px rgba(51, 204, 153, .2)", 
          borderRadius:"10px",           
          }}           
        >
          
          {perspective=='Perspective' ? 
            <PerspectiveCamera makeDefault position={[100,100,100]}/> : 
            <OrthographicCamera makeDefault position={[100,100,100]}/> 
          } 
          
          <Scene {...props}/>                    
        </Canvas>  
      </Grid> 
      <Grid item xs={12} sm={12} md={12}>        
        {returnColorbar(props)}
      </Grid>
    </Grid>
    </>  
  );
}

export default Truss3DScene;