import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

export const HtmlTooltip = withStyles(theme => ({
	tooltip: {
    // background: '#1a1a1a',
    // borderRight: '1px solid #33cc9950',
		backgroundColor: "#1a1a1a90",
		color: "#fff",
		maxWidth: 300,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9"
	}
}))(Tooltip);
