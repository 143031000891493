import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-14/img1.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `
\\begin{equation}
\\tag{1}
R = k_l \\:u
\\end{equation}`

const eqn2 = `
\\begin{equation}
\\tag{2}
R=\\frac{EA}{L_o}u
\\end{equation}`

const eqnA = `
\\begin{equation}
\\begin{bmatrix}
R\\\\
S
\\end{bmatrix} = 
\\frac{EA}{L_o}
\\begin{bmatrix}
1&0\\\\
0&0
\\end{bmatrix}
\\begin{bmatrix}
u\\\\
v
\\end{bmatrix}
\\end{equation}`

const eqn3 = `
\\begin{equation}
\\tag{3}
[R] = [K_l][U]
\\end{equation}`

const eqn4 = `
\\begin{equation}
\\tag{4}
\\frac{EA}{L_o}
\\begin{bmatrix}
1 &-1\\\\
-1 &1
\\end{bmatrix}
\\end{equation}`

const eqn5 = `
\\begin{equation}
\\tag{5}
[L] = [T]^T[R]
\\end{equation}`

const eqn6 = `
\\begin{equation}
\\tag{6}
[L] = [T]^T[K_l][U]
\\end{equation}`

const eqn7 = `
\\begin{equation}
\\tag{7}
[L]=[T]^T[K_l][T][X]
\\end{equation}`

const eqn8 = `
\\begin{equation}
\\tag{8}
[K_G]=[T]^T[K_l][T]
\\end{equation}`

const eqn9 = `
\\begin{equation}
\\tag{9}
[K_G]=
\\begin{bmatrix}
-l_p & -l_q\\\\
-m_p & -m_q\\\\
l_p & l_q\\\\
m_p & m_q
\\end{bmatrix}
\\frac{EA}{L}
\\begin{bmatrix}
1&0\\\\
0&0
\\end{bmatrix}
\\begin{bmatrix}
-l_p & -m_p & l_p & m_p\\\\
-l_q & -m_q & l_q & m_q
\\end{bmatrix}
\\end{equation}`

const eqn10 = `
\\begin{align}
\\tag{10}
l_p &= \\cos \\theta_x = c\\\\
m_p &= \\cos \\theta_y = \\sin \\theta_x = s
\\end{align}`

const eqn11 = `
\\begin{equation}
\\tag{11}
[K_G]=
\\begin{bmatrix}
-c & -s\\\\
-s & -c\\\\
c & -s\\\\
s & c
\\end{bmatrix}
\\frac{EA}{L}
\\begin{bmatrix}
1&0\\\\
0&0
\\end{bmatrix}
\\begin{bmatrix}
-c & -s & c & s\\\\
-s & -c & -s & c
\\end{bmatrix}
\\end{equation}`

const eqn12 = `
\\begin{equation}
\\tag{12}
[K_G] = \\frac{EA}{L_o}
\\begin{bmatrix}
c^2 & cs & -c^2 & -cs \\\\
cs & s^2 & -cs & -s^2\\\\
-c^2 & -cs & c^2 & cs\\\\
-cs & -s^2 & cs & s^2
\\end{bmatrix}
\\end{equation}`


class Lecture_17_14 extends React.Component {
  state={
    course: 17,
    lecture: 14, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  In this lecture, we'll derive the standard element stiffness matrix before taking into account the contribution of non-linear behaviour in the next lecture. We start by considering a simple bar element in uniaxial tension due to the force <MathJax.Node inline formula={"R"} />. The total elongation is denoted by the local displacement, <MathJax.Node inline formula={"u"} />, Fig. 1.
                </Typography>                                                                
              </Grid>

              <figure className={classes.figure}>
                <img className={classes.imageNoAlpha} src={img1} />                  
                <figcaption className={classes.caption}>Fig 1. Bar element in uniaxial tension with axial elongation <MathJax.Node inline formula={"u"} />.</figcaption>
              </figure> 

              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  The force-displacement relationship is given by,
                </Typography>

                <MathJax.Node formula={eqn1} className={classes.formula}/>

                <MathJax.Node formula={eqn2} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  If we expand this to take into account the orthogonal local axis and represent both equations in matrix form, we have,
                </Typography>

                <MathJax.Node formula={eqnA} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  or in a condensed format,
                </Typography>

                <MathJax.Node formula={eqn3} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Note that the local stiffness matrix, in this case, differs from what we've seen in previous courses because we captured the change in length with the parameter <MathJax.Node inline formula={"u"} />. In previous derivations, we've captured the change in length as <MathJax.Node inline formula={"x_{b} - x_{a}"} />, which leads to a local stiffness matrix of the form,
                </Typography>

                <MathJax.Node formula={eqn4} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Parameterising the change in length with <MathJax.Node inline formula={"u"} /> is more convenient for us in the derivations to follow later in this section.
                </Typography>

                <Typography component="h2" className={classes.H2} > Global element stiffness matrix </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  The local <MathJax.Node inline formula={"[4\\times 4]"} /> element stiffness matrix relates forces and displacements represented in a local reference frame. To relate forces and displacements in a global reference frame, we must convert the local stiffness matrix into a global stiffness matrix. We've seen this derivation in the prerequisite course, but I'll repeat it here for convenience. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  Consider the relationship between the vector of global forces, <MathJax.Node inline formula={"[L]"} />, and local forces, <MathJax.Node inline formula={"[R]"} />,
                </Typography>

                <MathJax.Node formula={eqn5} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Now substitute Eqn. 3 into Eqn. 5,
                </Typography> 

                <MathJax.Node formula={eqn6} className={classes.formula}/>          
                
                <Typography paragraph className={classes.bodytext}>
                  Now, if we substitute the relationship between local and global displacements, <MathJax.Node inline formula={"U=[T][X]"} />, into Eqn. 6,
                </Typography>

                <MathJax.Node formula={eqn7} className={classes.formula}/>          
                
                <Typography paragraph className={classes.bodytext}>
                  From Eqn. 7, we recognise the global element stiffness matrix as,
                </Typography>

                <MathJax.Node formula={eqn8} className={classes.formula}/>          
                
                <Typography paragraph className={classes.bodytext}>
                  Expanding we have,
                </Typography>

                <MathJax.Node formula={eqn9} className={classes.formula}/>          
                
                <Typography paragraph className={classes.bodytext}>
                  For a given element, provided we know the coordinates of the nodes that define it, we can compute the direction cosines that make up the transformation matrix. If we know the axial stiffness, <MathJax.Node inline formula={"EA"} />, we can easily calculate <MathJax.Node inline formula={"[K_G]"} />. 
                </Typography>

                <Typography component="h2" className={classes.H2} > Equivalence with previous versions of the global stiffness matrix. </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  When we evaluated the global element stiffness matrix for an axially loaded member in the prerequisite course, <Link to="/course-11/lecture-17" className={classes.link} target="_blank">here</Link>, we parameterised the problem slightly differently. We began with a different transformation matrix and the local element stiffness matrix given by Eqn. 4. Of course, ultimately, we should arrive at the same global element stiffness matrix, regardless of how we parameterised the problem. So, let's demonstrate equivalence between the global element stiffness matrix we derived previously and the one yielded by evaluating Eqn. 9.
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  Before evaluating Eqn. 9, let's make the following substitutions,
                </Typography>

                <MathJax.Node formula={eqn10} className={classes.formula}/>          
                
                <Typography paragraph className={classes.bodytext}>
                  and recall from the previous lecture that <MathJax.Node inline formula={"m_q=l_p"} /> and <MathJax.Node inline formula={"l_q=-m_p"} />. These substitutions are a direct mapping between the direction cosines defined in the previous lecture and the elements of the transformation matrix we defined in the previous course. After making these substitutions, we can re-write Eqn. 9 as,
                </Typography>

                <MathJax.Node formula={eqn11} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  This evaluates to,
                </Typography>
                                
                <MathJax.Node formula={eqn12} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  As we can see, this is the standard linear global stiffness matrix for an axially loaded bar element that we've seen previously. So, by calculating the direction cosines, as we've defined them in the previous lecture and subsequently evaluating Eqn. 9, we can be confident that we obtain the standard linear element stiffness matrix. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  The derivation of this stiffness matrix assumed that the bar element was initially undeformed with zero initial stress. For a linear structural analysis, this is fine. But for structures experiencing geometric non-linearity, we need to capture the influence of large deflections. For cable structures, in particular, we also want to capture the stiffening influence of adding an initial pre-tension to the element. In the next lecture, we'll start to take these effects into account in our element stiffness matrix.
                </Typography>
                                                     
                            
                </Grid>
 
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_14));
