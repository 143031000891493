
import React from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, dark, dracula, monokai, atomOneDarkReasonable } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function CodeBlock(props) {

  const { children } = props;

  const codeStyle = {
    fontSize:"1.0rem", 
    fontFamily:"'attribute-mono',monospace", 
    fontWeight: '400',
    lineHeight:"1.5",
    // background:"#1a1a1a",
    background: "#2a2e35",
    borderRadius:"5px",
    padding:"15px", 
    boxShadow: "4px 4px 11px -1px rgba(0,0,0,0.75)",  
  }

  return (
    <SyntaxHighlighter 
      language="python" 
      style={atomOneDarkReasonable} 
      showLineNumbers={true} 
      customStyle={codeStyle}      
    >
      {children}
    </SyntaxHighlighter> 
  );
}

export default CodeBlock;