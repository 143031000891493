import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_62 extends React.Component {
  state={
    course: 17,
    lecture: 62, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >
                  <Typography paragraph className={classes.bodytext}>
                    Most conventional framed structures can be referred to as rectilinear - this is what we focused on in the previous lecture. But one of the benefits of modelling in Blender is that we can make use of its sophisticated modelling tools. Remember, Blender was not initially intended to be an engineering tool. Its primary use is as a modelling tool for 3D artists. So, it has excellent tools for generating organic forms. We’ll demonstrate some of these in this lecture and generate the twisting truss below. Blender makes this type of exploratory modelling with unusual shapes extremely easy and certainly much easier than trying to generate this with standard structural analysis software packages. 
                  </Typography>             
                </Grid>
                
                <figure style={{width:"80%"}}>
                  <div  class="sketchfab-embed-wrapper"> 
                    <iframe  
                      className={classes.image} 
                      style={{width:'100%', height:'450px'}}                      
                      title="Twisting Arched Space Frame" 
                      frameborder="0" 
                      allowfullscreen 
                      mozallowfullscreen="true" 
                      webkitallowfullscreen="true" 
                      allow="autoplay; fullscreen; xr-spatial-tracking" 
                      xr-spatial-tracking 
                      execution-while-out-of-viewport 
                      execution-while-not-rendered 
                      web-share 
                      src="ht/517163e914774fbdabc9078ce5e9bd0f/embed"> 
                    </iframe> 
                  </div>                  
                </figure>  

                <Grid item xs={12} sm={12} md={10} >
                  <Typography paragraph className={classes.bodytext}>
                    By the end of this final appendix lecture, you’ll have a good grasp of how to use Blender. You won’t be a Blender expert yet, and doing simple things may still be frustratingly slow. But remember, the earliest part of the learning curve is always the steepest. Persist, and before you know it, you’ll be whizzing around Blender, wondering how you ever managed without it. 
                  </Typography>             
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_62));
