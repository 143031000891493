import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../../../components/common/Layout"; //Main page layout component inclusing
import SolverTruss2D from "../../../../components/Solvers/SolverTruss2D"

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5)    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {
		// marginTop: theme.spacing(5),
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	}
});

//This is just the page on Labs that houses the 2D solver (solver is a child of this page)
class AnalysisTruss2D extends React.Component {
  componentDidMount() {  
    window.scrollTo(0, 0);
  }

	render() {	
    const { classes } = this.props;	
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={"2D TRUSS ANALYSIS"}          
				>
          <SolverTruss2D/>       
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,    
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(AnalysisTruss2D));
