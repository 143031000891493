import React from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import * as actions from "../../../../actions/";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5),
    backgroundColor:"#1a1a1a !important"  
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {		
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	},
  button:{
    background: "#33cc99",
    color:"#1a1a1a",   
    "&:hover": {
      background: "#6ae6d0",
    } 
  }
});

class EditAccount extends React.Component {
	state = {
		email: "",
		expiry: ""		
	};

	componentWillReceiveProps() {
		this.setState({
			email: this.props.initialValues.email,
			expiry: this.props.initialValues.expiry,			
		});
	}

  // Return error message if validation error returned
	returnErrorText(_key) {
		const { errors } = this.props;
		if (typeof errors !== "undefined" && errors.length > 0) {
			for (var i = 0; i < errors.length; i++) {
				if (errors[i].param === _key) {
					return (
						<div className="text-danger">
							<small>{errors[i].msg}</small>
						</div>
					);
				}
			}
		}
		return;
	}

  //Required when combining Matrial UI <TextField/> with redux-form
	renderTextField({ id, name, input, label, ...custom }) {
		return (
			<TextField
				id={id}
				name={name}
				label={label}
				fullWidth
				{...input}
				{...custom}
			/>
		);
	}

  onFormSave = values => {
		this.props.onSubmit(values); //Call onSubmit passed in from parent
	};

  render() {
		const { classes } = this.props;

		return (
      <form
          id="editAccountForm"
          onSubmit={this.props.handleSubmit(values => {
            this.onFormSave(values);
          })} //wrapped in arrow function so it won't be called on initial load
        >
          <Grid container direction="row" display="flex" justify="space-between">
            <Grid item>
              <Typography
                variant="h2"
                align="left"
                className={classes.subTitle}
                color="secondary"
              >
                Access Details
              </Typography>
              <Typography                
                align="left"
                className={classes.bodytext}              
              >
                After a user cancels their subscription in Podia, manually update their subscription expiry date below
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Field
                name="email"
                id="email"
                label="Account Email"
                component={this.renderTextField}
              />
              {this.returnErrorText("email")}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                name="expiry"
                id="expiry"
                label="Subscription expiry (YYYY-MM-DD)"
                component={this.renderTextField}
              />
              {this.returnErrorText("expiry")}
            </Grid>							
          </Grid>

          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
              <Button
                style={{ float: "right", marginTop: 20 }}
                variant="contained"
                type="submit"
                form="editAccountForm"
                color="primary"
                className={classes.button}
              >
                Update
              </Button>
            </Grid>
          </Grid>
      </form>
		);
	}
}

//Create a formWrapped version of Login component
const formWrapped = reduxForm({
	form: "editAccountForm",
	enableReinitialize: true
})(EditAccount);

const selector = formValueSelector("editAccountForm"); //Create a selector to extract form values

function mapStateToProps(state, ownProps) {
	return {
		auth: state.auth,
		errors: state.errors,
		email: selector(state, "email"),
		expiry: selector(state, "expiry"),
		initialValues: {
			email: ownProps.initialValues.email,
			expiry: ownProps.initialValues.expiry,
		}
	};
}

export default connect(
	mapStateToProps,
	actions
)(withStyles(styles)(formWrapped));
