import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1 = 
`def calculateTransMatrix(posI, posJ):
    """
    Takes in position of node i & j and returns transformation matrix for the member
    This will be recalculated as the structure deflects with each iteration
    """    
    T = np.zeros([2,4])
    ix = posI[0] #x-coord for node i 
    iy = posI[1] #y-coord for node i
    jx = posJ[0] #x-coord for node j
    jy = posJ[1] #y-coord for node j  

    dx = jx-ix #x-component of vector along member
    dy = jy-iy #y-component of vector along member
    length = math.sqrt(dx**2 + dy**2) #Magnitude of vector (length of member)
    
    #Direction cosines
    lp = dx/length
    mp = dy/length
    lq = -mp
    mq = lp
    
    T = np.array([[-lp, -mp, lp, mp],[-lq, -mq, lq, mq]]) #Transformatoin matrix

    return T`

    const codeString2 = 
    `def calculateTransMatrices(UG):
    """
    Calculate transformation matrices for each member based on current deformed shape
    Deformed shape is initial position plus cumulative displacements passed in as UG
    """
    #Initialise container to hold transformation matrices
    TransformationMatrices = np.zeros((len(members), 2, 4))
    
    for n, mbr in enumerate(members): 
        node_i = mbr[0] #Node number for node i of this member
        node_j = mbr[1] #Node number for node j of this member
        
        #Index of DoF for this member
        ia = 2*node_i-2 #horizontal DoF at node i of this member 
        ib = 2*node_i-1 #vertical DoF at node i of this member
        ja = 2*node_j-2 #horizontal DoF at node j of this member
        jb = 2*node_j-1 #vertical DoF at node j of this member   

        #New positions = initial pos + cum deflection
        ix = nodes[node_i-1,0] + UG[ia,0]
        iy = nodes[node_i-1,1] + UG[ib,0] 
        jx = nodes[node_j-1,0] + UG[ja,0]
        jy = nodes[node_j-1,1] + UG[jb,0]

        #Recalculate transformation matrix [T] using updated nodal positions
        TM = calculateTransMatrix([ix, iy], [jx, jy])
         
        #Store transformation matrix for current member
        TransformationMatrices[n,:,:] = TM
    
    return TransformationMatrices`

class Lecture_17_22 extends React.Component {
  state={
    course: 17,
    lecture: 22, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll write two standalone functions that together allow us to calculate the transformation matrix for each element. One function, <code className={classes.code}>calculateTransMatrices()</code>, is called from our main iterating <code className={classes.code}>while</code> loop. This function cycles through each member, collects the relevant information for each member and then calls a <i>child function</i>, <code className={classes.code}>calculateTransMatrix()</code>, which actually handles the calculation of the individual transformation matrix for a given member. I'll explain the child function first because this is the one that's defined first in the Jupyter Notebook. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Function to calculate single member transformation matrix based on nodal coordinates </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    This is a pretty simple function and just implements the construction of the element transformation matrix we discussed in lecture 13. The function takes as arguments the x and y coordinates of nodes <code className={classes.code}>i</code> and <code className={classes.code}>j</code>. After unpacking the individual coordinates, the member length is calculated, followed by the direction cosines for the local element axes. The direction cosines are then packaged into a transformation matrix that gets returned from the function. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock> 

                  <Typography component="h2" className={classes.H2} > Function to calculate all transformation matrices based on the deformed shape of the structure </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now we need to write the function that actually calls the previous function for every element within the structure. The function, <code className={classes.code}>calculateTransMatrices()</code>, takes as its input argument the current cumulative set of displacements for the structure. In doing so, we're recalculating the transformation matrices based on the current displaced shape of the structure. We start by initialising a container, a 3D NumPy array, to hold the transformation matrix for each element. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Then, we can iterate through each member, extract its original x and y location and then add the accumulated horizontal and vertical deflection (lines 20-23). These coordinates are then packaged and supplied as arguments to the child function (defined above), <code className={classes.code}>calculateTransMatrix()</code>. The child function calculates and returns the transformation matrix for the deformed element (line 26), and then we save this into our `TransformationMatrices` data structure on line 29. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, this function returns the complete 3D data structure containing all of the updated transformation matrices to whoever called the function. Remember, this function is called once when we initially calculate the transformation matrices and then on each analysis iteration within our <code className={classes.code}>while</code> loop. We discussed both of these in the last lecture. Again, this will be a trend for the rest of this section - we'll be writing functions that we first referenced in lecture 21. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock> 

                  <Typography paragraph className={classes.bodytext}>
                    Remember, when structuring your notebook, you must make sure that all functions are defined before they're called. So these two functions along with the rest of the functions we write in this section must be defined above (or before) the initialisation and iteration code we wrote in the previous lecture. In the next lecture we'll continue filling the gaps in our functions and write the pre-tension calculation function, <code className={classes.code}>initPretension()</code>. 
                  </Typography>                                                              
                </Grid>                 
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_22));
