import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`

const eqn1 = `\\begin{equation}
\\tag{1}
[\\delta R] = \\left\\{[AA]^T\\frac{EA}{L_o}[AA] + [d]\\right\\}[\\delta U]
\\end{equation}`

const eqn2 = `\\begin{equation}
\\tag{2}
[U] = [T][X]
\\end{equation}`

const eqn3 = `\\begin{equation}
\\tag{3}
[\\delta U] = [T][\\delta X]
\\end{equation}`

const eqn4 = `\\begin{equation}
\\tag{4}
[L] = [T]^T[R]
\\end{equation}`

const eqn5 = `\\begin{equation}
\\tag{5}
[\\delta L] = [T]^T[\\delta R]
\\end{equation}`

const eqn6 = `\\begin{equation}
\\tag{6}
[\\delta L] = [T]^T \\left\\{[AA]^T\\frac{EA}{L_o}[AA] + [d]\\right\\}[\\delta U]
\\end{equation}`

const eqn7 = `\\begin{equation}
\\tag{7}
[\\delta L] = \\underbrace{[T]^T \\left\\{[AA]^T\\frac{EA}{L_o}[AA] + [d]\\right\\}[T]}_{[K_G]}[\\delta X]
\\end{equation}`

const eqn8 = `\\begin{equation}
\\tag{8}
[K_G] = [T]^T \\left\\{[AA]^T\\frac{EA}{L_o}[AA] + [d]\\right\\}[T]
\\end{equation}`

const eqn9 = `\\begin{equation}
\\tag{9}
\\boxed{
[K_G] = [T]^T
\\left\\{
\\begin{bmatrix}
\\frac{L_o+u}{L_o+e}\\\\
\\frac{v}{L_o+e}
\\end{bmatrix}
\\frac{EA}{L_o}
\\begin{bmatrix}
\\frac{L_o+u}{L_o+e} & \\frac{v}{L_o+e}
\\end{bmatrix} + 
\\begin{bmatrix}
\\frac{Pv^2}{(L_o+e)^3} & \\frac{-Pv(L_o+u)}{(L_o+e)^3} \\\\
\\frac{-Pv(L_o+u)}{(L_o+e)^3} & \\frac{P(L_o+u)^2}{(L_o+e)^3}
\\end{bmatrix}
\\right\\}[T]
}
\\end{equation}`

const eqnA = `\\begin{equation}
P=P_o + \\frac{EA}{L_o}\\:e
\\end{equation}`

const eqn10 = `\\begin{equation}
\\tag{10}
[K_G] = [T]^T\\left\\{ 
\\begin{bmatrix}
1\\\\
0
\\end{bmatrix}
\\frac{EA}{L_o}
\\begin{bmatrix}
1 &0
\\end{bmatrix} + 
P_o
\\begin{bmatrix}
0 & 0 \\\\
0 & \\frac{1}{L}
\\end{bmatrix}
\\right\\}
[T]
\\end{equation}`

const eqn11 = `\\begin{equation}
\\tag{11}
\\begin{bmatrix}
\\delta R \\\\
\\delta S
\\end{bmatrix} = 
\\left\\{
\\underbrace{
\\frac{EA}{L_o}
\\begin{bmatrix}
1 &0\\\\
0 &0
\\end{bmatrix}}_{\\alpha} + 
\\underbrace{
P_o
\\begin{bmatrix}
0 & 0 \\\\
0 & \\frac{1}{L}
\\end{bmatrix}}_{\\beta}
\\right\\}
\\begin{bmatrix}
\\delta u\\\\
\\delta v
\\end{bmatrix}
\\end{equation}`

const eqn12 = `\\begin{equation}
\\tag{12}
\\delta S = \\frac{P_o}{L}\\:\\delta v
\\end{equation}`


class Lecture_17_17 extends React.Component {
  state={
    course: 17,
    lecture: 17, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
              <Grid item xs={12} sm={12} md={10} >                  

                <Typography paragraph className={classes.bodytext}>
                  In the previous lecture, we defined an incremental non-linear local stiffness matrix;   incremental in the sense that it related incremental changes in local forces, <MathJax.Node inline formula={"[\\delta R]"} />, with incremental changes in local displacements, <MathJax.Node inline formula={"[\\delta U]"} />,
                </Typography>

                <MathJax.Node formula={eqn1} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Next, we'll use the transformation matrix to compute the global element stiffness matrix. We know that the local and global nodal displacements are related through the transformation matrix, 
                </Typography>

                <MathJax.Node formula={eqn2} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Therefore the incremental displacement changes are also linked by the transformation matrix,
                </Typography>

                <MathJax.Node formula={eqn3} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  The local and global forces are similarly related,
                </Typography>

                <MathJax.Node formula={eqn4} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  As are incremental changes in these forces,
                </Typography>

                <MathJax.Node formula={eqn5} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  If we now substitute Eqn. 1 into Eqn. 5, we have,
                </Typography>

                <MathJax.Node formula={eqn6} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Now we can sub Eqn. 3 into Eqn. 6, 
                </Typography>

                <MathJax.Node formula={eqn7} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Equation 7 relates an incremental change in the global nodal forces with an incremental change in the global nodal displacements. From this, we recognise the global incremental element stiffness matrix, <MathJax.Node inline formula={"[K_G]"} />,
                </Typography>  

                <MathJax.Node formula={eqn8} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  Expanding this expression, we have,
                </Typography>

                <MathJax.Node formula={eqn9} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  We can see from Eqn. 9 that we now have a stiffness matrix that takes into account the elongation, <MathJax.Node inline formula={"e"} />, along the deformed axis and also the influence of any initial pre-tension, <MathJax.Node inline formula={"P_o"} />; remember from Eqn. 2 in lecture 15 that the <MathJax.Node inline formula={"P"} /> in Eqn. 9 is actually,
                </Typography>

                <MathJax.Node formula={eqnA} className={classes.formula}/>

                <Typography component="h2" className={classes.H2} > Equivalence with the linear element stiffness matrix </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  At this point, we have the stiffness matrix that we will use in our solver to capture the non-linear behaviour of our structure. Before we conclude this section, it's worth briefly considering how the stiffness matrix in Eqn. 9 relates to our standard linear stiffness matrix, discussed in lecture 14. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  For a linear analysis, we could let <MathJax.Node inline formula={"u=v=e=0"} />, therefore the stiffness matrix from Eqn. 9 would reduce to,
                </Typography>

                <MathJax.Node formula={eqn10} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  If we now focus on the local element stiffness matrix and its role in relating local incremental forces with incremental displacements, we have,
                </Typography>

                <MathJax.Node formula={eqn11} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  The portion of the stiffness matrix identified as <MathJax.Node inline formula={"\\alpha"} /> is the standard linear stiffness matrix we saw in lecture 14. Now, if the pre-tension is zero, <MathJax.Node inline formula={"P_o=0"} />, the local non-linear stiffness matrix from Eqn. 9 reduces to the linear version as we would expect. If the pre-tension is not equal to zero, then the <MathJax.Node inline formula={"\\beta"} /> portion of the stiffness matrix makes a contribution. In particular, the pre-tension will increase the 'off-axis' stiffness,
                </Typography>

                <MathJax.Node formula={eqn12} className={classes.formula}/>
                
                <Typography paragraph className={classes.bodytext}>
                  So, by applying some no-zero pre-tension to our cable elements, we can clearly see that we will increase the stiffness of the overall cable structure by enhancing its off-axis stiffness. This is consistent with how we intuitively expect a pre-tensioned cable to behave when subject to a non-axial load. 
                </Typography>

                <Typography paragraph className={classes.bodytext}>
                  Put another way, imagine hanging a weight from a taut cable. The degree to which the cable sags will be influenced by the level of pre-tension in the cable. If the cable was pulled tight initially, with a large pre-tension, the off-axis stiffness provided by the pre-tension will reduce the deflection or sag induced by the load hung from the cable. The portion of the stiffness matrix identified as <MathJax.Node inline formula={"\\beta"} /> above is modelling this behaviour. 
                </Typography>
                
                <Typography paragraph className={classes.bodytext}>
                  Now that we've derived the stiffness matrix, we're ready to start building our toolbox in the next section. 
                </Typography>
          
                </Grid>

                
            </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_17));
