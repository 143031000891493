import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-17/img1.png";
import img2 from "../../../images/Lectures/C19-17/img2.png";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString =``

const codeString1 = `import math #...........................Math functionality
import numpy as np #....................Numpy for working with arrays
import matplotlib.pyplot as plt #Plotting functionality 

#Constants
fck = 30 #(N/mm^2) Characteristic compressive strength of concrete
fyk = 500 #(N/mm^2) Characteristic yield strength of steel reinforcement
As = 6430 #(mm^2) Area of steel
b = 400 #(N/mm^2) Width of the beam
d = 650 #(mm) Effective depth
ep_cu2 = 0.0035 #Maximum strain for concrete
E = 200000 #(N/mm^2) Young's modulus for steel reinforcement`

const codeString2 =`#Build an array of possible neutral axis depth values
fac = np.arange(0.617,0.79,0.00001)
X = d*fac`

const codeString3 =`#Determine the difference in concrete and steel forces for all values of neutral axis
deltaF = np.array([]) #Empty array to hold all force difference values
for i, x in enumerate(X):
    F_C = 0.567*fck*b*0.8*x #(N) Force in the concrete
    ep_st = ep_cu2*((d-x)/x) # Strain in the steel
    sig_st = E*ep_st #(N/mm^2) Stress in the steel
    F_S = 0.87*sig_st*As #(N) Force in the steel
    deltaF = np.append(deltaF,F_C-F_S) #Save the difference in concrete and steel forces`

const codeString4 =`#Identify the value of neutral axis at which the force difference is a minimum
for i in np.arange(0,len(deltaF)-1):            
    if deltaF[i]<0 and deltaF[i+1]>0:
        print(f'The required value of NA depth is : {round(fac[i]*d,2)} mm')
        Fac = fac[i]
    elif deltaF[i]>0 and deltaF[i+1]<0:
        print(f'The required value of NA depth is : {round(fac[i]*d,2)} mm')        
        Fac = fac[i]`

const codeString5 =`# Plot the results
fig = plt.figure() 
axes = fig.add_axes([0.1,0.1,2,1]) 
axes.plot(fac*d,deltaF,'-')
axes.plot([Fac*d, Fac*d],[deltaF.min(), deltaF.max()],'--r', lw=0.75)
axes.plot([fac.min()*d, fac.max()*d],[0, 0],'--r', lw=0.75)
axes.set_xlim([fac[0]*d, fac[-1]*d])
axes.set_xlabel('x (mm)')
axes.set_ylabel('Force difference (N)')
axes.set_title('Graphical solution')
axes.grid()`



const Eqn1 = `\\begin{align}
F_S &= 0.87\\:f_{yk}\\: A_s\\\\\\\\
F_S &= 0.87\\times 500 \\times 6430\\\\\\\\
F_S &= 2797.05\\:kN
\\end{align}`

const Eqn2 = `\\begin{equation}
F_C = 0.567\\:f_{ck}\\:0.8x\\:b = 2,797,050\\\\\\\\
\\end{equation}`

const Eqn3 = `\\begin{align}
F_C&= 0.567\\: f_{ck}\\: b\\: 0.8x\\\\\\\\
\\overbrace{F_C}^{\\text{?}} &= 0.567\\times 30 \\times 400\\times 0.8\\times \\overbrace{x}^{\\text{?}}
\\end{align}`

const Eqn4 = `\\begin{align}
x=&0.62\\:d\\\\\\\\
&= 0.62\\times 650\\\\\\\\
&=403\\:mm
\\end{align}`

const Eqn5 = `\\begin{align}
F_C&= 0.567\\: f_{ck}\\: b\\: 0.8x\\\\\\\\
 &= 0.567\\times 30 \\times 400\\times 0.8\\times 403\\\\\\\\
 &= 2193.61\\:kN
\\end{align}`

const Eqn6 = `\\begin{align}
\\frac{\\epsilon_{st}}{d-x} &= \\frac{0.0035}{x}\\\\\\\\
\\epsilon_{st} &= 0.0035\\left(\\frac{650-403}{403}\\right)\\\\\\\\
\\epsilon_{st} &=0.002145
\\end{align}`

const Eqn7 = `\\begin{align}
f_{st} &= E\\times \\epsilon_{st}\\\\\\\\
f_{st} &= (200\\times 10^3)(0.002145)\\\\\\\\
f_{st} &= 429\\:N/mm^2
\\end{align}`

const Eqn8 = `\\begin{align}
F_S = 0.87\\:f_{st}\\:A_s\\\\\\\\
F_S = 0.87\\times 429 \\times 6430\\\\\\\\
F_S = 2399.9\\:kN
\\end{align}`

const Eqn9 = `\\begin{align}
x &= 0.635\\:d\\\\\\\\
x &= 412.8\\:mm
\\end{align}`

const Eqn10 = `\\begin{align}
F_C&= 0.567\\: f_{ck}\\: b\\: 0.8x\\\\\\\\
 &= 0.567\\times 30 \\times 400\\times 0.8\\times 412.8\\\\\\\\
 &= 2246.95\\:kN
\\end{align}`

const Eqn11 = `\\begin{align}
\\epsilon_{st} &= 0.0035\\left(\\frac{650-412.8}{412.8}\\right)\\\\\\\\
\\epsilon_{st} &= 0.002011
\\end{align}`

const Eqn12 = `\\begin{align}
F_S = 0.87\\times f_{st}\\times A_s\\\\\\\\
F_S = 2249.95\\:kN
\\end{align}`

const Eqn13 = `\\begin{align}
z &= d-0.4x\\\\\\\\
z &=650-0.4\\times 412.8\\\\\\\\
x &= 484.9\\:mm
\\end{align}`

const Eqn14 = `\\begin{align}
M_{Rd} &= 2248.45\\times 0.4849\\\\\\\\
&= 1090.3\\:kNm
\\end{align}`

const Eqn15 = `\\begin{align}
M_{Rd,max} &= 0.167\\: f_{ck}\\:bd^2\\\\\\\\
&= 0.167\\times 30 \\times 400\\times 650^2\\\\\\\\
&= 846.7\\:kNm
\\end{align}`

const Eqn16 = ``

const Eqn17 = ``

const Eqn18 = ``

const Eqn19 = ``

const Eqn20 = ``


class Lecture_19_18 extends React.Component {
  state={
    course: 19,
    lecture: 18, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                                                                  

                  <Typography paragraph className={classes.bodytext}>
                    As I mentioned in the previous lecture, there are two ways we can automate the process of finding <MathJax.Node inline formula={"x"} />; we could set the calculation up in Excel and use the Goal Seek function to identify the value of <MathJax.Node inline formula={"x"} /> that minimises the difference between <MathJax.Node inline formula={"F_C"} /> and <MathJax.Node inline formula={"F_S"} />, or we could write some python code to do the same thing. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The Excel option only involves a couple of mouse clicks (once the equations are set up) and is demonstrated in the video lecture, so I won’t spend any time discussing it further here. Instead, we’ll talk through the process of writing a Python script. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    As usual for a DegreeTutors course, I’ll be writing my code inside a Jupyter notebook (within Jupyter Lab), but you should code in whatever environment you’re comfortable with. If you need help setting up a Python coding environment, you can watch <Link to="/course-11/lecture-3" className={classes.link} target="_blank">this lecture</Link> where I talk you through setting up a Jupyter notebook.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > A Python script to solve for <MathJax.Node inline formula={"x"} /> </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now let's write out a quick Python script to solve for the correct value of <MathJax.Node inline formula={"x"} />. We'll start by importing some dependencies and defining constants.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can build an array of possible neutral axis depths <code>X</code>.
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we can cycle through each of the neutral axis depths and compute the difference in forces developed in the steel and concrete. We'll save these in an array of values called <code>deltaF</code>.
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can cycle through each value of <code>deltaF</code> and identify when the value changes sign from positive to negative or vice versa. This means the value must have passed through zero. We can pinpoint this value of <code>deltaF</code> and the corresponding neutral axis depth as the solution we're looking for.
                  </Typography>

                  <CodeBlock>{codeString4}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    This code block outputs the following statement:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>The required value of NA depth is : 412.95 mm</Typography></li>
                  </ul>

                  <Typography paragraph className={classes.bodytext}>
                    Having found the required value of neutral axis depth, the solution process continues as discussed in the previous lecture. Finally, we'll plot a graphical solution.
                  </Typography>

                  <CodeBlock>{codeString5}</CodeBlock>
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Graphical solution.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The blue line above is the value of <code>deltaF</code> and the red <i>cross-hairs</i> identified where it passes through zero. When compared with the couple of lines used in Excel to achieve the same result, the Python script may look like a lot of work, and to be fair, relatively speaking, it is. But adopting a Python first approach will greatly increase our flexibility for more complex analyses later. 
                  </Typography>                                       
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_18));
