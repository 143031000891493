import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-14/img1.png";

const Eqn1 = `\\begin{equation}
\\tag{1}
M_{Rd} = F_{C}\\times z = F_T \\times z
\\end{equation}`

const Eqn2 = `\\begin{equation}
\\tag{2}
F_C = \\overbrace{0.567\\:f_{ck}}^{\\text{stress}}\\:\\overbrace{b\\:(0.8x)}^{\\text{area}}
\\end{equation}`

const Eqn3 = `\\begin{equation}
\\tag{3}
M_{Rd} = [0.567\\:f_{ck}\\:b\\:0.8x]\\:[d-0.4\\:x]
\\end{equation}`

const Eqn4 = `\\begin{equation}
\\tag{4}
\\boxed{M_{Rd} = 0.167\\: f_{ck}\\:bd^2}
\\end{equation} `

const Eqn5 = `\\begin{equation}
M_{Rd} = K_{lim}\\: f_{ck}\\:bd^2
\\end{equation} `

const Eqn6 = `\\begin{align}
\\tag{5}
M_{Rd} = &0.567\\:f_{ck}\\:b\\:\\overbrace{[2(d-z)]}^{=0.8x}\\times z\\\\\\\\
M_{Rd} = &1.134\\:f_{ck}\\:b(d-z)z
\\end{align}`

const Eqn7 = `\\begin{equation}
\\tag{6}
\\Bigg(\\frac{z}{d}\\Bigg)^2 - \\Bigg(\\frac{z}{d}\\Bigg) + \\frac{K}{1.134} = 0;
\\end{equation} `

const Eqn8 = `\\begin{equation}
\\tag{7}
\\boxed{z = d\\Bigg[0.5+\\sqrt{0.25-\\frac{K}{1.134}}\\Bigg]}
\\end{equation}`

const Eqn9 = `\\begin{equation}
\\tag{8}
M_{Ed} = M_{Rd} = F_T\\times z
\\end{equation} `

const Eqn10 = `\\begin{equation}
F_T = \\frac{f_{yk}}{\\gamma_s}\\:A_s = 0.87\\:f_{yk}\\:A_s
\\end{equation}`

const Eqn11 = `\\begin{equation}
\\boxed{A_s = \\frac{M_{Ed}}{0.87\\:f_{yk}\\:z}}
\\end{equation}`

const Eqn12 = `\\begin{align*}
&M_{Rd,max} = 0.167\\: f_{ck}\\:bd^2\\\\\\\\
&K = \\frac{M_{Ed}}{bd^2f_{ck}}\\\\\\\\
&z = d\\Bigg[0.5+\\sqrt{0.25-\\frac{K}{1.134}}\\Bigg]\\\\\\\\
&A_s = \\frac{M_{Ed}}{0.87\\:f_{yk}\\:z}
\\end{align*} `



const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_14 extends React.Component {
  state={
    course: 19,
    lecture: 14, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >     
                  <Typography component="h2" className={classes.H2} > 1.0 Ultimate moment of resistance - singly reinforced section </Typography>             
                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll develop the design equations necessary to determine the section moment capacities. Let's start by considering the R.C. beam cross-section shown in Fig. 1 below. 
                  </Typography>
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Reinforced concrete section showing stress and strain distribution at the ultimate limit state.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The beam from which the cross-section was obtained is subject to a bending moment that induces compression on the top of the section. The beam is reinforced with steel in the tension zone only. A beam reinforced in this way is referred to as a singly reinforced beam, as opposed to a beam that has additional (designed) reinforcement in the compression region, referred to as a doubly reinforced beam. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The strain and stress distribution at failure are also shown. The task now is to determine an expression for the ultimate moment capacity of this section that ensures a ductile failure, i.e. where the steel yields in tension before the concrete crushes in compression.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Let’s state at the outset that the internal bending moment is equal in magnitude to the externally applied bending moment, thus <MathJax.Node inline formula={"M_{INT} = M_{EXT}"} />. To be consistent with Eurocode nomenclature, we will refer to external bending moments as design moments (as in, moments we are designing for), denoted by <MathJax.Node inline formula={"M_{Ed}"} /> (where the subscript <MathJax.Node inline formula={"E"} /> stands for the <i>effect of actions</i>). An internal bending moment shall be referred to as a moment of resistance, denoted by <MathJax.Node inline formula={"M_{Rd}"} />. Therefore, at risk of stating the obvious, we seek to achieve <MathJax.Node inline formula={"M_{Rd}\\geq M_{Ed}"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now, as we've already said, the moment of resistance generated by the beam at the cross-section under consideration is:
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"F_C"} /> and <MathJax.Node inline formula={"F_T"} /> are the compression and tension stress resultants acting on the cross-section and <MathJax.Node inline formula={"z"} /> is the lever-arm between them.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The compressive stress resultant (in the concrete), <MathJax.Node inline formula={"F_C"} /> is simply the stress magnitude multiplied by the area over which it acts,
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Noting that the lever-arm is <MathJax.Node inline formula={"z=d-0.4\\:x"} />, we can obtain an expression for the internal moment of resistance as a function of the neutral axis depth <MathJax.Node inline formula={"x"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Remembering that at the point of failure, the neutral axis is limited to a depth of <MathJax.Node inline formula={"x=0.45\\:d"} />, we can substitute this into the Eqn. 3, the expression then simplifies to:
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This equation can be interpreted as:
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where <MathJax.Node inline formula={"K_{lim} = 0.167"} />. This is the maximum bending moment that can be generated in a singly reinforced beam while still respecting the neutral axis depth limit, ensuring a ductile failure.
                  </Typography> 

                  <Typography component="h2" className={classes.H2} > 2.0 Area of steel reinforcement, <MathJax.Node inline formula={"A_s"} /> </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    So far, we've established the ultimate moment our beam can safely resist. Notice that this simply required that we state the characteristic cylinder strength and cross-section dimensions. However, we still need to determine how much steel reinforcement is required to generate this moment of resistance. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Remember, if we don't put enough steel into the beam, the steel we do provide will yield too soon, i.e. before the ultimate moment capacity can be generated. If, on the other hand,  we put too much steel in, the beam will continue to resist loading until the concrete crushes in compression; this would give a higher moment capacity but a brittle (unsafe) failure. So we need to identify the specific area of steel required.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider again the section in Fig. 1 and Eqn. 3 above. If we replace the lever-arm with <MathJax.Node inline formula={"z"} /> and instead of stating the height of the stress block as <MathJax.Node inline formula={"0.8x"} />, we use the equivalent expression, <MathJax.Node inline formula={"2(d-z)"} />, we can write an equivalent expression for <MathJax.Node inline formula={"M_{Rd}"} /> in terms of the lever arm <MathJax.Node inline formula={"z"} />, rather than <MathJax.Node inline formula={"x"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now, letting <MathJax.Node inline formula={"K = M_{Ed}/(bd^2f_{ck})"} /> and imposing the limit that <MathJax.Node inline formula={"K\\:\\leq 0.167"} />, rearranging we get a quadratic equation in <MathJax.Node inline formula={"z"} />,
                  </Typography> 

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Solving this quadratic for <MathJax.Node inline formula={"z"} />, we obtain:
                  </Typography>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Using Eqn. 7, we can obtain an expression for the lever arm, <MathJax.Node inline formula={"z"} />, based on the externally applied design moment, geometry of the beam and its material properties. The required area of steel can now be simply determined by considering the steel stress at failure:
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    since
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    we have:
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So in summary, the key equations when designing a singly reinforced beam for a ductile failure are:
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Over the next couple of lectures, we'll put these equations to use with some worked examples.
                  </Typography>
                                
                </Grid>                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_14));
