import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-9/img1.png";
import img2 from "../../../images/Lectures/C19-9/img2.png";
import img3 from "../../../images/Lectures/C19-9/img3.png";
import img4 from "../../../images/Lectures/C19-9/img4.png";

const Eqn1 = `\\begin{align}
w_{max} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{max} &= 1.1\\times 70 + 1.5\\times 40\\\\\\\\
w_{max} &= 137\\:kN/m
\\end{align}`

const Eqn2 = `\\begin{align}
w_{min} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{min} &= 0.9\\times 70 + 0\\times 40\\\\\\\\
w_{min} &= 63\\:kN/m
\\end{align}`

const Eqn3 = `\\begin{align}
\\sum M_B &= 0\\:\\: (\\curvearrowright+)\\\\\\\\
(R_A\\times 16) + (137\\times 8\\times 4)&= (63\\times 16\\times 8)\\\\\\\\
R_A &= 230\\:kNm
\\end{align}`

const Eqn4 = `\\begin{align}
w_{max} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{max} &= 1.35\\times 70 + 1.5\\times 40\\\\\\\\
w_{max} &= 154.5\\:kN/m
\\end{align}`

const Eqn5 = `\\begin{align}
w_{min} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{min} &= 1.35\\times 70 + 0\\times 40\\\\\\\\
w_{min} &= 94.5\\:kN/m
\\end{align}`

const Eqn6 = `\\begin{align}
\\sum M_B &= 0\\:\\: (\\curvearrowright+)\\\\\\\\
(R_A\\times 16) + (94.5\\times 8\\times 4)&= (154.5\\times 16\\times 8)\\\\\\\\
R_A &= 1047\\:kN
\\end{align}`

const Eqn7 = `\\begin{align}
a &= \\frac{\\pi\\times 750^2}{4}\\\\\\\\
a &= 441786\\:mm^2
\\end{align}`

const Eqn8 = `\\begin{align}
\\sigma_c &= \\frac{1047\\times 10^3}{441786}\\\\\\\\
\\sigma_c &= 2.4\\:N/mm^2 \\:<\\sigma_{all}
\\end{align}`

const Eqn9 = `\\begin{align}
w_{max} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{max} &= 1.0\\times 70 + 1.3\\times 40\\\\\\\\
w_{max} &= 122\\:kN/m
\\end{align}`

const Eqn10 = `\\begin{align}
w_{min} &= \\gamma_G\\:g_k + \\gamma_Q\\:q_k\\\\\\\\
w_{min} &= 1.0\\times 70 + 0\\times 40\\\\\\\\
w_{min} &= 70\\:kN/m
\\end{align}`

const Eqn11 = `\\begin{align}
q_{bear} &= \\frac{836}{0.441786}\\\\\\\\
q_{bear} &=1892.3\\:kN/m^2 \\:>>q_{all}
\\end{align}`

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_19_9 extends React.Component {
  state={
    course: 19,
    lecture: 9, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >    

                <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>              

                  <Typography paragraph className={classes.bodytext}>
                    Consider the pile/ground-beam structure we saw previously, Fig 1. With the additional structural and loading information provided, confirm that:
                  </Typography>

                  <ul>
                    <li><Typography paragraph className={classes.bodytext}>the ground beam does not exceed the EQU limit state.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>the rear pile does not exceed the STR limit state.</Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>the strata onto which the rear pile bears satisfies the GEO limit state.</Typography></li>
                  </ul>         
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Foundation structure.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >   
                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>  

                  <Typography component="h3" className={classes.H3} > 2.1 Ground beam - EQU </Typography>             

                  <Typography paragraph className={classes.bodytext}>
                    The EQU limit state is concerned with instability arising from the structure behaving as a rigid body. So, as we look at this structure, it becomes apparent that we need to confirm that if the ground beam did behave as a rigid body (however unlikely that may be), it would not rotate about point <MathJax.Node inline formula={"B"} />. In other words, under EQU loading, no uplift should occur at <MathJax.Node inline formula={"A"} />.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The most onerous loading pattern for this case is when the maximum loading, <MathJax.Node inline formula={"w_{max}"} />, is applied between <MathJax.Node inline formula={"B"} /> and <MathJax.Node inline formula={"C"} /> and the minimum, <MathJax.Node inline formula={"w_{min}"} />, is applied between <MathJax.Node inline formula={"A"} /> and <MathJax.Node inline formula={"B"} />. For <MathJax.Node inline formula={"w_{max}"} />, both the permanent and variable actions are unfavourable and therefore attract the highest possible partial factors from the Table in Fig. 2 below.
                  </Typography> 
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Partial factors and combinations for ultimate limit states EQU, STR and GEO (repeated from lecture 4).</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >    
                  <MathJax.Node formula={Eqn1} className={classes.formula}/>              

                  <Typography paragraph className={classes.bodytext}>
                    In the case of <MathJax.Node inline formula={"w_{min}"} />, both permanent and variable actions are favourable, 
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>  

                  <Typography paragraph className={classes.bodytext}>
                    Now we can simply evaluate the equilibrium of the ground beam under this EQU loading, Fig. 3 and confirm that uplift doesn't occur at <MathJax.Node inline formula={"A"} />.
                  </Typography>
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Structure subject to EQU loading for the ground beam.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    To identify the reaction at <MathJax.Node inline formula={"A"} />, we can evaluate the sum of the moments about <MathJax.Node inline formula={"B"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since we assumed upwards pointing forces were positive and <MathJax.Node inline formula={"R_A>0"} />, the reaction is pointing upwards, and uplift is not predicted at A; the ground beam, therefore, does not exceed the EQU limit state.
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Rear pile - STR </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To confirm that the ground beam does not exceed the STR limit state, a simple check would be to confirm that the maximum compressive stress in the pile remains within the limit specified. In this case the most onerous load pattern is when the span from <MathJax.Node inline formula={"A"} /> to <MathJax.Node inline formula={"B"} /> experiences <MathJax.Node inline formula={"w_{max}"} /> and <MathJax.Node inline formula={"B"} /> to <MathJax.Node inline formula={"C"} /> experiences <MathJax.Node inline formula={"w_{min}"} />. 
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Structure beam subject to STR loading for the rear pile.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Referencing the second row from the table in Fig. 2 above, 
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    When calculating <MathJax.Node inline formula={"w_{min}"} />, we observe the single source principle, which states that all permanent loads from the same source attract the same partial factor. As a result, the partial factor applied to <MathJax.Node inline formula={"g_k"} /> will be <MathJax.Node inline formula={"1.35"} />, even though we seek to minimise <MathJax.Node inline formula={"w_{min}"} />. Remember, the single source principle applied to STR and GEO limit states only.
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The vertical reaction at <MathJax.Node inline formula={"A"} /> is determined again by taking moments about support <MathJax.Node inline formula={"B"} />, 
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, we can calculate the pile cross-sectional area, <MathJax.Node inline formula={"a"} />, to determine the compressive stress, <MathJax.Node inline formula={"\\sigma_c"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore the rear pile satisfies the STR limit state. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.3 Bearing strata - GEO </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The loading pattern required for this design check is the same as for the STR check we just completed. However, the partial factors used to determine <MathJax.Node inline formula={"w_{max}"} /> and <MathJax.Node inline formula={"w_{min}"} /> will be those for the GEO limit state. Therefore <MathJax.Node inline formula={"w_{max}"} /> is obtained as, 
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>while <MathJax.Node inline formula={"w_{min}"} /> is obtained as,</Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The reaction at <MathJax.Node inline formula={"A"} /> is obtained the same way as shown above and evaluates to <MathJax.Node inline formula={"R_A=836 \\:kN"} />. To check if the bearing strata satisfies the GEO limit state, we will simply calculate the end bearing pressure, <MathJax.Node inline formula={"q_{bear}"} />, by dividing <MathJax.Node inline formula={"R_A"} /> by the pile cross-sectional area, <MathJax.Node inline formula={"a_p"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since the bearing pressure is larger than the stated allowable bearing pressure, the GEO limit state is not satisfied, and the pile must be redesigned to reduce the bearing pressure developed in the supporting strata.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that the design calculations performed as part of each limit state check above are somewhat superficial (<i>back of the envelope</i>) and don't represent detailed designs. For example, there is more to designing a reinforced concrete pile than simply checking the compressive stress. The important part of this example question was correctly determining the design loading.
                  </Typography>

                  
                                
                </Grid>                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_9));
