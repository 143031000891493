export default {
  projectList:[
    {
      projectId: 4,
      title: "Simulating Crowd-induced Vibrations using the Duhamel Integral",
      tagline: "In this Python project, we build a crowd-induced vibration simulation using the Duhamel Integral in Python.",
      duration: 3.5,
      lectures: 8,
      path: "project-4/lecture-1",
      video: true,
      text: true,
      visible: true,
      access: 'open',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "https://courses.degreetutors.com/simulating-crowd-induced-vibrations-using-the-duhamel-integral",
      discourse: true,
      lectures:[
        {
          lectureId: 1,
          section: "The Duhamel Integral",
          title: "The Duhamel Integral",          
          vimeoURL: "https://player.vimeo.com/video/669421640?h=08f6704463",
          duration: "19:49",
          preview: true,
          topicId: 896,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172081-the-duhamel-integral/3543979-1-0-the-duhamel-integral"
            }
          ],
          files:[
            {
              linkText: "Lecture 1 Jupyter Notebook",
              filename: "Lecture 1.zip",                       
            }
          ]
        },
        {
          lectureId: 2,
          section: "The Duhamel Integral",
          title: "Analytical Solution",          
          vimeoURL: "https://player.vimeo.com/video/669423819?h=b0d5aa9517",
          duration: "22:51",
          preview: true,
          topicId: 897,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172081-the-duhamel-integral/3543940-2-0-duhamel-integral-analytical-solution"
            }
          ],
          files:[]
        },        
        {
          lectureId: 3,
          section: "The Duhamel Integral",
          title: "Numerical Solution",          
          vimeoURL: "https://player.vimeo.com/video/669425985?h=e22fd5f405",
          duration: "26:25",
          preview: true,
          topicId: 898,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172081-the-duhamel-integral/3543964-3-0-duhamel-integral-numerical-solution"
            }
          ],
          files:[]
        },        
        {
          lectureId: 4,
          section: "The Duhamel Integral",
          title: "Validating our numerical solution code",          
          vimeoURL: "https://player.vimeo.com/video/669602165?h=4a137a73a6",
          duration: "06:34",
          preview: true,
          topicId: 899,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172081-the-duhamel-integral/3546611-4-0-validating-our-numerical-solution-code"
            }
          ],
          files:[]
        },       
        {
          lectureId: 5,
          section: "Simulating Crowd Vibration",
          title: "An introduction to dynamic crowd loading",          
          vimeoURL: "https://player.vimeo.com/video/669492191?h=ff7a809d85",
          duration: "36:41",
          preview: true,
          topicId: 900,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172080-simulating-crowd-vibration/3545156-5-0-an-introduction-to-dynamic-crowd-loading"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Simulating Crowd Vibration",
          title: "Dynamic analysis: Bridge + 1 pedestrian",          
          vimeoURL: "https://player.vimeo.com/video/669496074?h=ae5da2d520",
          duration: "27:51",
          preview: true,
          topicId: 901,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172080-simulating-crowd-vibration/3545085-6-0-dynamic-analysis-bridge-1-pedestrian"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Simulating Crowd Vibration",
          title: "Dynamic analysis: Bridge + N pedestrian crowd",          
          vimeoURL: "https://player.vimeo.com/video/669519600?h=340bdfe9d5",
          duration: "26:18",
          preview: true,
          topicId: 902,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172080-simulating-crowd-vibration/3545570-7-0-dynamic-analysis-bridge-n-pedestrian-crowd"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Simulating Crowd Vibration",
          title: "Animating bridge response & traffic flow",          
          vimeoURL: "https://player.vimeo.com/video/669602535?h=7be710590b",
          duration: "35:42",
          preview: true,
          topicId:903,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/view/courses/simulating-crowd-induced-vibrations-using-the-duhamel-integral/1172080-simulating-crowd-vibration/3546729-8-0-animating-bridge-response-traffic-flow"
            }
          ],
          files:[]
        },
      ]
    },
    {
      projectId: 3,
      title: "Building a Beam Deflection Calculator",
      tagline: "In this Python project, we build a Beam Deflection Calculator using numerical integration.",
      duration: 1.9,
      lectures: 8,
      path: "project-3/lecture-1",
      video: true,
      text: false,
      visible: true,
      access: 'open',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "https://courses.degreetutors.com/building-a-beam-deflection-calculator",
      discourse: true,
      lectures:[
        {
          lectureId: 1,
          section: "Beam Deflection Calculator Project",
          title: "Project overview and demo",          
          vimeoURL: "https://player.vimeo.com/video/616729596?h=0a61244c2b",
          duration: "9:48",
          preview: true,
          topicId: 887,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2786260-1-project-overview-and-demo"
            }
          ],
          files:[
            {
              linkText: "Lecture 1 Jupyter Notebook",
              filename: "Lecture 1.zip",                       
            }
          ]
        },
        {
          lectureId: 2,
          section: "Beam Deflection Calculator Project",
          title: "Beam deflection using numerical integration",          
          vimeoURL: "https://player.vimeo.com/video/616732155?h=ba36333da9",
          duration: "21:41",
          preview: true,
          topicId: 888,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2786265-2-beam-deflection-using-numerical-integration"
            }
          ],
          files:[]
        },        
        {
          lectureId: 3,
          section: "Beam Deflection Calculator Project",
          title: "Solving for beam rotations and deflections",          
          vimeoURL: "https://player.vimeo.com/video/616735367?h=e1eea89b46",
          duration: "27:56",
          preview: true,
          topicId: 889,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2789122-3-solving-for-beam-rotations-and-deflections"
            }
          ],
          files:[]
        },        
        {
          lectureId: 4,
          section: "Beam Deflection Calculator Project",
          title: "How to find the correct initial beam rotation",          
          vimeoURL: "https://player.vimeo.com/video/616740110?h=7cc1565ccb",
          duration: "13:42",
          preview: true,
          topicId: 890,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2789209-4-how-to-find-the-correct-initial-beam-rotation"
            }
          ],
          files:[]
        },       
        {
          lectureId: 5,
          section: "Beam Deflection Calculator Project",
          title: "How to minimise the deflection error",          
          vimeoURL: "https://player.vimeo.com/video/616742168?h=8a1e822301",
          duration: "12:57",
          preview: true,
          topicId: 891,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2789585-5-how-to-minimise-the-deflection-error"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Beam Deflection Calculator Project",
          title: "Dealing with overhang cantilever deflections",          
          vimeoURL: "https://player.vimeo.com/video/616744490?h=70eb993fb4",
          duration: "9:49",
          preview: true,
          topicId: 892,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2789758-6-dealing-with-overhang-cantilever-deflections"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Beam Deflection Calculator Project",
          title: "Plotting the beam deflected shape",          
          vimeoURL: "https://player.vimeo.com/video/616746659?h=c0c78e4695",
          duration: "9:38",
          preview: true,
          topicId: 893,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2789798-7-plotting-the-beam-deflected-shape"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Beam Deflection Calculator Project",
          title: "Validating our beam deflections with Macauley’s Method",          
          vimeoURL: "https://player.vimeo.com/video/616748942?h=80f1f30c33",
          duration: "6:18",
          preview: true,
          topicId: 894,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-beam-deflection-calculator/934131-default-section/2789936-8-validating-our-beam-deflections-with-macauley-s-method"
            }
          ],
          files:[
            {
              linkText: "Lecture 8 Jupyter Notebook",
              filename: "Lecture 8.zip",                       
            }
          ]
        },
      ]
    },
    {
      projectId: 2,
      title: "Building a Shear Force and Bending Moment Diagram Calculator in Python",
      tagline: "In this short Python Project, build a simple calculator for statically determinate beams",
      duration: 2.75,
      lectures: 9,
      path: "project-2/lecture-1",
      video: true,
      text: false,
      visible: true,
      access: 'open',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "https://courses.degreetutors.com/building-a-shear-force-bending-moment-diagram-calculator",
      discourse: true,
      lectures:[
        {
          lectureId: 1,
          section: "Shear and Moment Calculator Project",
          title: "Introduction and project setup",          
          vimeoURL: "https://player.vimeo.com/video/611475115?h=e997a6940f",
          duration: "17:15",
          preview: true,
          topicId: 877,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2666793-1-introduction-and-project-setup"
            }
          ],
          files:[]
        },
        {
          lectureId: 2,
          section: "Shear and Moment Calculator Project",
          title: "Reactions due to point loads",          
          vimeoURL: "https://player.vimeo.com/video/611480966?h=622d828ab3",
          duration: "22:16",
          preview: true,
          topicId: 878,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2666821-2-reactions-due-to-point-loads"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Shear and Moment Calculator Project",
          title: "Shear and moment due to point loads",          
          vimeoURL: "https://player.vimeo.com/video/611487977?h=3c824a24f6",
          duration: "16:46",
          preview: true,
          topicId: 879,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2666694-3-shear-and-moment-due-to-point-loads"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Shear and Moment Calculator Project",
          title: "Shear and moment diagrams with Plotly",          
          vimeoURL: "https://player.vimeo.com/video/611493180?h=9a4e322541",
          duration: "22:55",
          preview: true,
          topicId: 880,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2666736-4-shear-and-moment-diagrams-with-plotly"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Shear and Moment Calculator Project",
          title: "Dealing with applied moments",          
          vimeoURL: "https://player.vimeo.com/video/611498043?h=64c78cdf6e",
          duration: "17:10",
          preview: true,
          topicId: 881,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2667103-5-dealing-with-applied-moments"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Shear and Moment Calculator Project",
          title: "Dealing with uniformly distributed loads",          
          vimeoURL: "https://player.vimeo.com/video/611503669?h=ca39630778",
          duration: "23:59",
          preview: true,
          topicId: 882,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2667226-6-dealing-with-uniformly-distributed-loads"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Shear and Moment Calculator Project",
          title: "Reactions due to linearly varying distributed loads",          
          vimeoURL: "https://player.vimeo.com/video/611510920?h=ab3b775072",
          duration: "13:31",
          preview: true,
          topicId: 883,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2666764-7-reactions-due-to-linearly-varying-distributed-loads"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Shear and Moment Calculator Project",
          title: "Shear and moments due to linearly distributed loads",          
          vimeoURL: "https://player.vimeo.com/video/611516952?h=795ce6dfcf",
          duration: "21:44",
          preview: true,
          topicId: 884,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2668983-8-shear-and-moments-due-to-linearly-distributed-loads"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Shear and Moment Calculator Project",
          title: "Testing our shear force and bending moment calculator",          
          vimeoURL: "https://player.vimeo.com/video/611525925?h=bf3af87387",
          duration: "9:09",
          preview: true,
          topicId: 885,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/building-a-shear-force-bending-moment-diagram-calculator/897883-default-section/2668993-9-testing-our-shear-force-and-bending-moment-calculator"
            }
          ],
          files:[
            {
              linkText: "Lecture 9 Jupyter Notebook",
              filename: "Lecture 9.zip",                       
            }
          ]
        },
      ]
    },
    {
      projectId: 1,
      title: "Building a Mohr’s Circle Calculator for Stress Analysis in Python",
      tagline: "Perform 2D stress analysis and use Mohr’s Circle to visualise the complete stress field",
      duration: 3.5,
      lectures: 9,
      path: "project-1/lecture-1",
      video: true,
      text: false,
      visible: true,
      access: 'open',
      fileRedirectMsg: "Sign in to download resources",
      fileRedirectURL: "https://www.degreetutors.com/building-a-mohrs-circle-calculator-for-stress-analysis-in-python/",
      discourse: true,
      lectures:[
        {
          lectureId: 1,
          section: "Building a Mohr’s Circle Calculator",
          title: "Understanding the 2D stress element",          
          vimeoURL: "https://player.vimeo.com/video/638673287?h=e7c804489d",
          duration: "25:13",
          preview: true,
          topicId: 867,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310670-1-0-the-2d-stress-element"
            }
          ],
          files:[{
            linkText: "Lecture 1 Jupyter Notebook",
            filename: "Lecture 1.zip",                       
          }]
        },
        {
          lectureId: 2,
          section: "Building a Mohr’s Circle Calculator",
          title: "The stress transformation equations",          
          vimeoURL: "https://player.vimeo.com/video/638677581?h=e9b7ec1a43",
          duration: "23:25",
          preview: true,
          topicId: 868,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310673-2-0-the-stress-transformation-equations"
            }
          ],
          files:[]
        },
        {
          lectureId: 3,
          section: "Building a Mohr’s Circle Calculator",
          title: "Visualising transformation equations",          
          vimeoURL: "https://player.vimeo.com/video/638679341?h=d8eebf098a",
          duration: "30:59",
          preview: false,
          topicId: 869,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310680-3-0-visualising-the-transformation-equations"
            }
          ],
          files:[]
        },
        {
          lectureId: 4,
          section: "Building a Mohr’s Circle Calculator",
          title: "Principal stresses and principal planes",          
          vimeoURL: "https://player.vimeo.com/video/638680721?h=b4c61aaa8a",
          duration: "28:32",
          preview: true,
          topicId: 870,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310713-4-0-principal-stresses-and-principal-planes"
            }
          ],
          files:[]
        },
        {
          lectureId: 5,
          section: "Building a Mohr’s Circle Calculator",
          title: "Finding maximum shear stresses",          
          vimeoURL: "https://player.vimeo.com/video/638682829?h=4bbc2de5b0",
          duration: "25:21",
          preview: true,
          topicId: 871,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310704-5-0-maximum-shear-stress"
            }
          ],
          files:[]
        },
        {
          lectureId: 6,
          section: "Building a Mohr’s Circle Calculator",
          title: "2D stress analysis worked example",          
          vimeoURL: "https://player.vimeo.com/video/638682063?h=4505cf8d28",
          duration: "15:40",
          preview: true,
          topicId: 872,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310697-6-0-stress-analysis-worked-example"
            }
          ],
          files:[]
        },
        {
          lectureId: 7,
          section: "Building a Mohr’s Circle Calculator",
          title: "Stress analysis Python worked example",          
          vimeoURL: "https://player.vimeo.com/video/638684154?h=c08f65f988",
          duration: "21:35",
          preview: true,
          topicId: 873,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310723-7-0-stress-analysis-worked-example-coded-solution"
            }
          ],
          files:[]
        },
        {
          lectureId: 8,
          section: "Building a Mohr’s Circle Calculator",
          title: "Understanding Mohr's circle of stress",          
          vimeoURL: "https://player.vimeo.com/video/638685267?h=40025c1abd",
          duration: "16:51",
          preview: true,
          topicId: 874,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2310722-8-0-mohr-s-circle-of-stress"
            }
          ],
          files:[]
        },
        {
          lectureId: 9,
          section: "Building a Mohr’s Circle Calculator",
          title: "Mohr's circle worked example in Python",          
          vimeoURL: "https://player.vimeo.com/video/638686035?h=73edf9343a",
          duration: "26:29",
          preview: true,
          topicId: 875,
          links:[
            {
              linkText: "Podia lecture and comments",
              url: "https://courses.degreetutors.com/courses/2d-stress-analysis-and-mohr-s-circle-of-stress/789618-default-section/2311287-9-0-mohr-s-circle-example-solution"
            }
          ],
          files:[]
        },
      ]
    }
  ]
}

