import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-9/img1.png";
import img2 from "../../../images/Lectures/C17-9/img2.png";
import img3 from "../../../images/Lectures/C17-9/img3.png";

const eqn1 = `\\begin{equation}
\\tag{1}
F=k\\times \\delta
\\end{equation}`

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';


class Lecture_17_9 extends React.Component {
  state={
    course: 17,
    lecture: 9, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    Let's start our discussion of non-linear behaviour by first thinking about linear behaviour. Hooke's law states that for a linear material or structure, the applied force, <MathJax.Node inline formula={"F"} />, is linearly proportional to the displacement, <MathJax.Node inline formula={"\\delta"} />, it induces. The constant of proportionality that links force and displacement is the stiffness of the structure, <MathJax.Node inline formula={"k"} />,
                  </Typography>

                  <MathJax.Node formula={eqn1} className={classes.formula}/>
                  
                  <Typography paragraph className={classes.bodytext}>
                    If  <MathJax.Node inline formula={"k"} /> remains constant, Eqn. 1 represents a linear relationship between force and displacement. In other words, if we double the applied load, we would observe double the displacement. Graphing this relationship would result in an inclined straight line. The slope of this line is equal to the stiffness of the structure, Fig. 1.
                  </Typography>                                                                   
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Plot of force versus displacement for a linear structure.</figcaption>
                </figure>  

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    In most cases, assuming linear behaviour is perfectly acceptable. However, if the stiffness changes during the loading history, the behaviour becomes non-linear, since a plot of force versus deflection can no longer be a straight line. There are two classes of non-linear behaviour; softening and stiffening.
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Non-linear softening </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    If the stiffness of the structure reduces as more load is applied, we refer to this as non-linear softening. Graphically this is represented by a curved line with a progressively reducing slope, Fig. 2.
                  </Typography>      
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Plot of force versus displacement for a structure experiencing non-linear softening.</figcaption>
                </figure>  

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography paragraph className={classes.bodytext}>
                    We can see from Fig. 2 that as more load is applied, the same increment of load produces larger and larger deflections. If we were to assume that the initial stiffness, <MathJax.Node inline formula={"K_0"} />, was constant, we would significantly underestimate the final deflection. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Non-linear stiffening </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Non-linear stiffening is the opposite behaviour. In this case, the stiffness of the structure progressively increases as more load is applied. Assuming the initial stiffness remains constant will overestimate the final deflection, Fig. 3.
                  </Typography>                                     
                              
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Plot of force versus displacement for a structure experiencing non-linear stiffening.</figcaption>
                </figure>  

                <Grid item xs={12} sm={12} md={10} >         

                  <Typography component="h2" className={classes.H2} > Sources of non-linearity </Typography>         

                  <Typography paragraph className={classes.bodytext}>
                    The previous plots of force versus displacement show us the outward result of non-linear behaviour. But we ought to dig a little deeper to understand what gives rise to these force-displacement relationships. There are two underlying causes for the non-linear behaviour we observe most often in structures:
                  </Typography>

                  <ol>
                    <li><Typography paragraph className={classes.bodytext}>Material non-linearity </Typography></li>

                    <li><Typography paragraph className={classes.bodytext}>Geometric non-linearity</Typography></li>
                  </ol>
                  
                  <Typography paragraph className={classes.bodytext}>
                    Material non-linearity is associated with plastic hinge formation. This is when, at a material level, stress is no longer proportional to strain, and is typically observed when steel exceeds its yield stress limit. We observe and indeed make use of plastic hinge formation in structural steel design, and in particular in the design of long-span steel frame structures. You can read more about plastic hinge formation in <a href="https://www.degreetutors.com/yielding-plastic-deformation-and-moment-redistribution-in-beams/" className={classes.link} target="_blank">this tutorial</a>. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We also see material non-linearity in reinforced concrete structures in the form of creep. Creep is a long-term plastic deflection that is particularly prevalent in flat slabs. Although material non-linearity may occur on cable structures, it is not the dominant source of non-linear behaviour, and we won't consider it any further here. 
                  </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    The driver of non-linear behaviour in cable structures is geometric non-linearity. Geometric non-linearity arises due to changes in structural geometry under load. We will explore this in more detail in the next lecture.
                  </Typography>                                                    
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_9));
