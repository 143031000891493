import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-44/img1.jpg";
import img2 from "../../../images/Lectures/C17-44/img2.jpg";
import img3 from "../../../images/Lectures/C17-44/img3.jpg";
import img4 from "../../../images/Lectures/C17-44/img4.jpg";
import img5 from "../../../images/Lectures/C17-44/img5.jpg";
import img6 from "../../../images/Lectures/C17-44/img6.jpg";
import img7 from "../../../images/Lectures/C17-44/img7.jpg";
import img8 from "../../../images/Lectures/C17-44/img8.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1 = 
`#Constants
E = 200*10**9 #🚨 (N/m^2)
A = 0.005 #🚨 (m^2) 

gamma = 100 #(kg/m) Cable mass per unit length
swt = False #🚨 Don't take self-weight into account 
Areas = A*np.ones([len(members)]) #An array to hold individual area for each member 
P0 = 0*np.ones([len(members)]) #🚨 An array to hold individual member pre-tension values

P = 0 #(N) Point load magnitude (and direction via sign)
pointLoadAxis = 'y' #The GLOBAL axis along which point loads are applied
nForceIncrements = 1000

convThreshold = 10 #(N) Threshold on maximum value of F_inequilibrium`

const codeString2 = 
`forceVector[3] = -5000
forceVector[5] = -30000
forceVector[7] = -10000`

class Lecture_17_44 extends React.Component {
  state={
    course: 17,
    lecture: 44, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this lecture, we'll compare the output from our non-linear analysis code with the output from a standard linear truss analysis. For this comparison, we'll use the 2D truss analysis code that we developed in the prerequisite course. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The aim is to show again that when the structure’s behaviour is linear, our non-linear solver has no problem describing the behaviour. As non-linearity is introduced through large deflections, we'll see both solutions start to diverge as our newer code captures the geometric non-linearity. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The other thing to point out here is that although our discussion up to this point has focused on the solution of cable structures which, in reality, can only resist tension, the code we've developed can equally be applied to structures that develop compression. So, there is nothing stopping us from applying it to a simple truss. Obviously, we'll ignore practical considerations like buckling; this analysis is simply a comparison of the different solver outputs.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We'll base the comparison in this lecture on the truss we analysed back in <Link to="/course-11/lecture-53" className={classes.link} target="_blank">lecture 53</Link> of the 2D truss analysis course. If you want to follow along, you can download the linear analysis notebook from that lecture’s resource panel. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Linear structure and linear code </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The structure we're focusing on is an inverted bowstring truss with a range of point loads applied, Fig 1.
                  </Typography>                  
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Inverted bowstring truss from lecture 53 of <i>The Direct Stiffness Method for Truss Analysis with Python</i>. </figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    This structure was first analysed with each element having a cross-sectional area of <MathJax.Node inline formula={"0.005\\:m^2"} />. The deflections observed were negligible and imperceptible compared to the scale of the structure. We can see this by inspecting the text output below, Fig 2. Such small deflections mean that the geometry of the structure is not appreciably changing under load, and as such, a linear analysis is perfect for this structure. 
                  </Typography>                                                 
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Text output from the original linear analysis.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     

                  <Typography component="h2" className={classes.H2} > Linear structure and non-linear code </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now let's analyse the structure using our new solver. Based on everything we’ve seen in the course up to this point, we expect this analysis to yield the same results. To analyse this structure in our new code, we need to define the structure and loading using csv files. You can download these files from the resources area above. The data input area should be modified, as shown below, to match the original simulation parameters. Again, 🚨 symbols have been used to highlight the changes.
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    We also need to manually insert the point loads into the <code className={classes.code}>forceVector</code>. This is done the same way we manually eliminated force values previously when looking at the 6-bar structure. Place the following code immediately after the code block that defines <code className={classes.code}>forceVector</code>.
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can run the analysis and observe the results, Fig 3. 
                  </Typography>                  
                </Grid>  

                <figure className={classes.figure}>
                  <img className={classes.image} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. Results from analysis of the linear truss model with a non-linear solver.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The code obviously has no problem converging for this structure. We can see this from the fact that there is negligible or no residual force imbalance. We can also compare the details of the text output to see that we have indeed replicated the results from the previous analysis, as expected. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that the node numbering in the original analysis differs from the node numbering used to define the structure for the second analysis. You can compare results by mapping members and node numbers between Fig 1 and Fig 3. For example, in the second analysis, the tensile force in the member between nodes 1 and 7 is <MathJax.Node inline formula={"33.59\\:kN"} />. The same member in the first analysis was defined by nodes 1 and 8. From Fig 1, we can see the tensile force was also <MathJax.Node inline formula={"33.59\\:kN"} />.
                  </Typography>
                                  
                </Grid> 

                <figure className={classes.figure}>
                  <img className={classes.image} src={img4} />                  
                  <figcaption className={classes.caption}>Fig 4. Text output from the analysis of the linear structure using the non-linear solver.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now we'll repeat the same two analyses, except this time, we'll invite geometric non-linearity by reducing the cross-sectional area for both analyses to <MathJax.Node inline formula={"0.000001\\:m^2"} />. This is, of course, completely unrealistic but it served to demonstrate divergence between the models.  
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Non-linear structure and linear code </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    When we run the analysis in our original linear solver, we can see that it predicts very large deflections, Fig 5. Deflections of this magnitude are clearly not practical. If observing anything like this, in reality, the finer points of your analysis code would be the least of your worries! 
                  </Typography>
                             
                </Grid> 

                <figure className={classes.figure}>
                  <img className={classes.image} src={img5} />                  
                  <figcaption className={classes.caption}>Fig 5. Results from analysis of the non-linear truss model with a linear solver.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Nevertheless, we can highlight the problem with the analysis by again looking at the detailed text output, Fig 6. We can see that despite the fact that the geometry of the structure has changed very significantly, all of the member forces and reactions remain unchanged. So, as we expected, the linear analysis has not captured the evolving geometry of the structure under loading. 
                  </Typography>
                                   
                </Grid> 

                <figure className={classes.figure}>
                  <img className={classes.image} src={img6} />                  
                  <figcaption className={classes.caption}>Fig 6. Text output from the analysis of the non-linear structure using the linear solver.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >     

                  <Typography component="h2" className={classes.H2} > Non-linear structure and non-linear code </Typography>             

                  <Typography paragraph className={classes.bodytext}>
                    Repeating the analysis using our non-linear solver we again see that the code converges. We note that the number of iterations required to achieve convergence on each load increment progressively increases, from 1 in the earlier increments of loading to 9 iterations in the final increments. We also note a maximum final force inequilibrium of <MathJax.Node inline formula={"9\\:N"} />. We can observe the results graphically, Fig 7, although we can't discern much difference from the previous linear analysis. 
                  </Typography>
                      
                </Grid> 

                <figure className={classes.figure}>
                  <img className={classes.image} src={img7} />                  
                  <figcaption className={classes.caption}>Fig 7. Results from analysis of the non-linear truss model with a non-linear solver.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Of more interest is the detailed text output, Fig. 8. We can see that there is definitely a change in the force distribution within the structure. We note that the reactions have changed to reflect the final geometry of the structure. We can also see changes in the internal force distribution. Returning to the member between nodes 1 and 7 (numbering from the structure as defined in the new code), we can see that the axial force has increased to <MathJax.Node inline formula={"35.54\\:kN"} />. 
                  </Typography>
                                  
                </Grid> 

                <figure className={classes.figure}>
                  <img className={classes.image} src={img8} />                  
                  <figcaption className={classes.caption}>Fig 8. Text output from the analysis of the non-linear structure using the non-linear solver.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    This lecture has again demonstrated the fact that the changing geometry of the structure under load must be considered if it starts to become significant by comparison to the scale of the structure. We can also recognise at this stage in the course that non-linearity is not a binary phenomenon, whereby a structure is either linear or non-linear. Non-linear behaviour exists on a continuum, and as such, it emerges to different degrees depending on the original stiffness of the undeformed structure. 
                  </Typography>

                </Grid> 
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_44));
