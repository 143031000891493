import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C17-47/img1.jpg";
import img2 from "../../../images/Lectures/C17-47/img2.jpg";

const tex = `\\boxed{f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi}`
const codeString = 'def functionName(param1, param2);\nreturn param1 + param2';

const codeString1 = 
`import bpy
import csv
import numpy as np
obdata = bpy.context.object.data`

const codeString2 = 
`#Extract all vertex coords and package for export
vertices = np.empty((0,2),int)
for v in obdata.vertices:
    vertex = np.array([v.co.x, v.co.y])
    vertices = np.append(vertices, [vertex], axis=0)`

const codeString3 = 
`#Extract all edges (vertex i and vertex j) and package for export
edges = np.empty((0,2),int)
for e in obdata.edges:
    edge = np.array([e.vertices[0]+1, e.vertices[1]+1])
    edges = np.append(edges, [edge], axis=0)`

const codeString4 = 
`#Export vertex coordinates to CSV file 
filename = "/Users/Sean/Desktop/Vertices.csv"
# writing to csv file  
with open(filename, 'w') as csvfile:  
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(vertices) # writing the data rows  
    
#Export edge/element definitions to CSV file 
filename = "/Users/Sean/Desktop/Edges.csv"
# writing to csv file  
with open(filename, 'w') as csvfile:      
    csvwriter = csv.writer(csvfile)  # creating a csv writer object  
    csvwriter.writerows(edges) # writing the data rows `

class Lecture_17_47 extends React.Component {
  state={
    course: 17,
    lecture: 47, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    Now that we have a model to work with, we need to export the data defining it into some csv files that we can bring over into our Jupyter Notebook. In this lecture, we'll focus on writing the script that generates the Vertices.csv and Edges.csv files. These are the same file we've been using to define our structures up to this point in the course. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > Apply your transforms! </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Before we export any geometry from Blender, it's critical that we apply any transforms that we've made to our model in Object mode. This will ensure that the geometry that we export does not have any additional transforms added to it upon export. After you import your geometry into your Jupyter Notebook, if it doesn't look the same as it did when you exported it from Blender, <MathJax.Node inline formula={"99\\%"} /> of the time, this will be because you forgot to apply your transforms before generating the csv files.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To apply all transforms, with the 3D model selected in object mode, type <i>Control + A</i> and select <i>All Transforms</i>. When you do, you should see that all location and rotation transforms return to zero, and all scale transforms return to 1 in the <i>'N'</i> panel on the right side of the 3D view, Fig 1. With this out of the way, we can start coding. 
                  </Typography>                    
                                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Applying <i>All transforms</i> to the 3D object before data export.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    One of the great features of Blender is that it has an extensive Python API. It can be a little tricky to get to grips with, but when you do, you can interact programmatically with literally everything inside Blender - including the model geometry data. We will access the location data for each vertex and the vertex numbers that define each edge in our model and export them to a csv file. To start writing some Python, we need to switch to the scripting layout and generate a new blank file. We'll name it <i>1_Model</i>, Fig 2. 
                  </Typography>                  
                </Grid> 

                <figure style={{width:"80%"}}>
                  <img className={classes.image} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. The Python scripting layout in Blender.</figcaption>
                </figure>


                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    All of our Python scripts usually start with the same library imports; <code className={classes.code}>bpy</code> is required to allow us to interact with the Blender API. <code className={classes.code}>csv</code> and <code className={classes.code}>numpy</code> serve their usual purpose. We define a variable <code className={classes.code}>obdata</code> to give us a convenient handle to the object data. 
                  </Typography>

                  <CodeBlock>{codeString1}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can cycle through all of the vertices in <code className={classes.code}>obdata</code> and extract the x and y coordinates of each. These are then appended into a container holding all of the coordinate pairs. The style and structure of the code is very similar to what we've been doing over in our Jupyter Notebook. 
                  </Typography>

                  <CodeBlock>{codeString2}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    We can more or less repeat this operation for all of the edges in <code className={classes.code}>obdata</code>. This time we're appending the vertex numbers that define each edge. One detail to be aware of is that vertex numbers start at zero in Blender. In our Jupyter Notebook, we've written our code in such a way that assumes vertex numbers start at one. So, on line 4, we're adding +1 to each vertex number, so they match what our Jupyter Notebook expects.
                  </Typography>

                  <CodeBlock>{codeString3}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    Now it's just a matter of exporting <code className={classes.code}>vertices</code> and <code className={classes.code}>edges</code> into csv files. To do this, we employ some boilerplate Python code. You'll notice below that I'm specifying that the files be generated on my desktop, e.g. <code className={classes.code}>/Users/Sean/Desktop/Vertices.csv</code>. Make sure to replace this path, in both cases, with an appropriate path for your computer. 
                  </Typography>

                  <CodeBlock>{codeString4}</CodeBlock>

                  <Typography paragraph className={classes.bodytext}>
                    We can have Blender run the script by pressing the play button at the top of the scripting window. We can see from the green tick in the context menu in the very bottom left of the screen that the script ran runs without errors. You should now have two new csv files, located wherever you specified in the script. These can be treated exactly like the other data files we've worked with throughout the course - so copy them into a folder called <i>data</i> in the same directory as your Jupyter Notebook. The remaining files we need to generate will, for the most part, just be variations of this file. We'll tackle these over the next three lectures. 
                  </Typography>
                  
                </Grid> 
                
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_17_47));
