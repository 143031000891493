import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-30/img1.png";

const Eqn1 = `\\begin{align}
l_{eff} &= l_n + a_1 + a_2\\\\\\\\
l_{eff} &= 5 + 0.5(0.3+0.3)\\\\\\\\
l_{eff} &=5.3\\:m
\\end{align}`

const Eqn2 = `\\begin{align}
b_1 = b_2 &= 0.5(2.75-0.25)\\\\\\\\
&=1.25m
\\end{align}`

const Eqn3 = `\\begin{align}
b_{eff,1} = b_{eff,2} & = 0.2\\overbrace{(1.25)}^{b_{i}} + 0.1\\overbrace{(5.3)}^{l_0}\\\\\\\\
&= 0.78\\:m\\:(<0.2l_0)
\\end{align}`

const Eqn4 = `\\begin{align}
b_{eff} &= b_w + 2(b_{eff,i})\\\\\\\\
&= 0.25+2(0.78)\\\\\\\\
b_{eff} &=1.81\\:m
\\end{align}`

const Eqn5 = `\\begin{align}
M_{Ed} &= \\frac{w_{Ed}\\:l_{eff}^2}{8}\\\\\\\\
&=\\frac{75\\times 5.3^2}{8}\\\\\\\\
M_{Ed} &= 263.3\\:kNm
\\end{align}`

const Eqn6 = `\\begin{align}
d = 475-30-10-\\frac{20}{2}\\\\\\\\
d = 425\\:mm
\\end{align}`

const Eqn7 = `\\begin{equation}
K=\\frac{M_{Ed}}{bd^2f_{ck}} = \\frac{263.3\\times 10^6}{1810\\times 425^2\\times 30}=0.027
\\end{equation}`

const Eqn8 = `\\begin{equation}
z = d\\Bigg[0.5+\\sqrt{0.25-\\frac{K}{1.134}}\\Bigg] = 0.98d
\\end{equation}`

const Eqn9 = `\\begin{equation}
z=0.95d = 404\\:mm
\\end{equation}`

const Eqn10 = `\\begin{align}
s &= 2(d-z) \\\\\\\\
s&= 42\\:mm\\:\\:(<h_f)
\\end{align}`

const Eqn11 = `\\begin{equation}
A_{s,req} = \\frac{M_{Ed}}{0.87f_{yk}z} = \\frac{263.3\\times 10^6}{0.87\\times 500\\times 404} = 1498\\:mm^2
\\end{equation}`

const Eqn12 = `\\begin{align}
\\delta_{clear} &= 250-\\overbrace{2(30)}^{\\text{cover}}-\\overbrace{2(10)}^{\\text{links}}-\\overbrace{2(32)}^{\\text{bars}}\\\\\\\\
\\delta_{clear} &= 106\\:mm
\\end{align}`

const Eqn13 = `\\begin{align}
V_{Ef} &= \\frac{w_{Ed}\\:l_{eff}}{2}-w_{Ed}\\frac{0.3}{2}\\\\\\\\
 &= \\frac{75\\times 5.3}{2} - 75\\times 0.15\\\\\\\\
V_{Ef} &= 187.5\\:kNm
\\end{align}`

const Eqn14 = `\\begin{align}
v_{Ef} &= \\frac{V_{Ed}}{b_w\\:0.9d}\\\\\\\\
&=\\frac{187.5\\times 10^3}{250\\times 0.9\\times 419}\\\\\\\\
v_{Ef} &= 1.99\\:N/mm^2
\\end{align}`

const Eqn15 = `\\begin{align}
V_{Ed} &= 198.75-75(0.419+0.15)\\\\\\\\
V_{Ed} &= 156.1\\:kN
\\end{align}`

const Eqn16 = `\\begin{align}
\\frac{A_{sw}}{s} = \\frac{V_{Ed}}{0.87f_{yk}z\\cot\\theta} &= \\frac{156.1\\times 10^3}{0.87\\times 500\\times 0.9\\times 419\\times 2.5}\\\\
\\frac{A_{sw}}{s} &= 0.38
\\end{align}`

const Eqn17 = `\\begin{equation}
s_{req} = \\frac{A_{sw}}{0.38}=\\frac{157}{0.38}=413\\:mm
\\end{equation}`

const Eqn18 = `\\begin{equation}
A_{sw,min} = \\frac{0.08\\sqrt{f_{ck}}b_w s}{f_{yk}} = \\frac{0.08\\sqrt{30}\\times 250\\times 300}{500}=65\\:mm^2
\\end{equation}`

const Eqn19 = `\\begin{equation}
\\Delta x = l_{eff}/4 = 1.325\\:m
\\end{equation}`

const Eqn20 = `\\begin{align}
M_1 &= (V_a\\times 1.325) - (0.5\\times w_{Ed} \\times 1.325^2)\\\\\\\\
M_1 &=197.6\\:kNm
\\end{align}`

const Eqn21 = `\\begin{align}
\\Delta F_d &= \\frac{\\Delta M}{d-h_f/2}\\times \\frac{b_f}{b_{eff}} \\\\\\\\
\\Delta F_d &= \\frac{197.6\\times 10^6}{419-\\frac{175}{2}}\\times \\frac{0.78}{1.81}\\\\\\\\
\\Delta F_d &=256.3\\:kN
\\end{align}`

const Eqn22 = `\\begin{align}
v_{Ed} &=\\frac{\\Delta F_d}{h_f\\:\\Delta x}\\\\\\\\
v_{Ed} &= \\frac{256.3\\times 10^3}{175\\times 1325}\\\\\\\\
v_{Ed} &=1.11\\:N/mm^2
\\end{align}`

const Eqn23 = `\\begin{align}
\\nu_1 &= 0.6\\left(1-\\frac{f_{ck}}{250}\\right)\\\\\\\\
\\nu_1 &= 0.6\\left(1-\\frac{30}{250}\\right)\\\\\\\\
\\nu_1 &= 0.528
\\end{align}`

const Eqn24 = `\\begin{align}
v_{Rd, max, 26.5^{\\circ}} &= \\frac{\\nu_1 f_{ck}}{1.5(\\cot \\theta_f + \\tan \\theta_f)}\\\\\\\\
v_{Rd, max, 26.5^{\\circ}} &= \\frac{0.528\\times 30}{1.5(\\cot 26.5^{\\circ} + \\tan 26.5^{\\circ})}\\\\\\\\
v_{Rd, max, 26.5^{\\circ}} &= 4.217\\:N/mm^2
\\end{align}`

const Eqn25 = `\\begin{align}
\\frac{A_{sf}}{s_f} &= \\frac{v_{Ed}\\:h_f}{0.87\\:f_{yk}\\:\\cot{\\theta_f}}\\\\\\\\
\\frac{A_{sf}}{s_f} &= \\frac{1.11\\times 175}{0.87\\times 500\\times \\cot 26.5^{\\circ}}\\\\\\\\
\\frac{A_{sf}}{s_f} &= 0.223
\\end{align}`

const Eqn26 = `\\begin{align}
A_{s,min} &= 0.26\\:b d_f\\frac{f_{ctm}}{f_{yk}}\\\\\\\\
A_{s,min} &= 0.26\\times 1000\\times 175\\times \\frac{2.9}{500}\\\\\\\\
A_{s,min} &= 264 \\:mm^2/m
\\end{align}`


class Lecture_19_31 extends React.Component {
  state={
    course: 19,
    lecture: 31, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    In this example, we'll cover the design of a simply supported T-beam. Again, for some extra practice, we'll cover the bending, vertical shear and transverse shear reinforcement design. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 1.0 Question </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Consider the simply supported beam and slab configuration shown in plan and section below. The beams supporting the slab will be designed as flanged sections. Determine the bending, vertical shear and transverse shear reinforcement required to withstand the design bending moment and shear forces. The applied loads may be considered the design loads and the following material properties should be assumed, <MathJax.Node inline formula={"f_{ck}=30\\:N/mm^2"} />, <MathJax.Node inline formula={"f_{yk}=500\\:N/mm^2"} />. <MathJax.Node inline formula={"30\\:mm"} /> cover to reinforcement should be assumed.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. (a) Side elevation of the simply supported beam and slab arrangement and (b) typical section through beam and slab.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  

                  <Typography component="h2" className={classes.H2} > 2.0 Solution </Typography>
                  
                  <Typography paragraph className={classes.bodytext}>
                    We again start by establishing the design span using,
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Next, we can determine the effective flange width. Since this is a simply supported beam, the distance between points of zero moment is the design span, <MathJax.Node inline formula={"l_0=5.3\\:m"} />. As the beams are at regularly spaced intervals, 
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore, 
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So, the effective flange width is,
                  </Typography>

                  <MathJax.Node formula={Eqn4} className={classes.formula}/>

                  <Typography component="h3" className={classes.H3} > 2.1 Bending design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can start the bending design by determining the design moment,
                  </Typography>

                  <MathJax.Node formula={Eqn5} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    As the design moment will be a sagging moment, the compression block will develop in the flange. We start by assuming that the compression block remains within the flange, therefore, <MathJax.Node inline formula={"b=1.81\\:m"} />. To estimate the effective depth, we'll assume <MathJax.Node inline formula={"20\\:mm"} /> diameter main reinforcement and <MathJax.Node inline formula={"10\\:mm"} /> links,
                  </Typography>

                  <MathJax.Node formula={Eqn6} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The lever-arm calculation follows,
                  </Typography>

                  <MathJax.Node formula={Eqn7} className={classes.formula}/>

                  <MathJax.Node formula={Eqn8} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Since <MathJax.Node inline formula={"z"} /> evaluates to a number larger than the limit <MathJax.Node inline formula={"(0.95d)"} />, we adopt the limit value,
                  </Typography>

                  <MathJax.Node formula={Eqn9} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now we can confirm that the stress block is indeed confined to the flange by evaluating its height, <MathJax.Node inline formula={"s"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn10} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore we were correct to assume the compression block remained within the flange. Finally, we can calculate the required area of steel reinforcement,
                  </Typography>

                  <MathJax.Node formula={Eqn11} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Keeping in mind the width of the beam at only <MathJax.Node inline formula={"250\\:mm"} />, we will specify <MathJax.Node inline formula={"2"} /> H32 bars, providing an area of <MathJax.Node inline formula={"1610\\:mm^2"} />. The clear distance between bars is easily calculated,
                  </Typography>

                  <MathJax.Node formula={Eqn12} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    This is an acceptable clear distance. Had we opted for <MathJax.Node inline formula={"3"} /> longitudinal bars, the spacing would start to become quite tight. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that when we originally estimated the effective depth, we speculatively assumed <MathJax.Node inline formula={"20\\:mm"} /> diameter reinforcement but ended up specifying <MathJax.Node inline formula={"32\\:mm"} /> bars, resulting in a slightly smaller effective depth. This inconsistency has a negligible impact on the final design, especially as <MathJax.Node inline formula={"A_{s,prov}>A_{s,req}"} />. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.2 Vertical shear design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Let's start by evaluating the shear at the face of the support, <MathJax.Node inline formula={"V_{Ef}"} />, and confirming that the stress in the compression strut is within limits. 
                  </Typography>

                  <MathJax.Node formula={Eqn13} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The corresponding stress, <MathJax.Node inline formula={"v_{Ef}"} /> is,
                  </Typography>

                  <MathJax.Node formula={Eqn14} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Note that the effective depth <MathJax.Node inline formula={"d"} />, has been recalculated based on the actual bending rebar provided, <MathJax.Node inline formula={"d=475-30-10-32/2 = 419mm"} />.
                  </Typography>  

                  <Typography paragraph className={classes.bodytext}>
                    With <MathJax.Node inline formula={"f_{ck}=30\\:N/mm^2"} />, the strut capacity at the minimum strut angle of <MathJax.Node inline formula={"22^{\\circ}"} /> is <MathJax.Node inline formula={"v_{Rd, max, \\:22^{\\circ}} = 3.64\\:N/mm^2"} />. As this is larger than <MathJax.Node inline formula={"v_{Ef}"} />, we can conclude that the compression strut is safe and adopt a stut angle of <MathJax.Node inline formula={"22^{\\circ}"} /> for subsequent calculations.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    To calculate the required vertical shear reinforcement, we need to determine the shear stress at a distance <MathJax.Node inline formula={"d"} /> from the face of the support, <MathJax.Node inline formula={"V_{Ed}"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn15} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The ratio of shear reinforcement, <MathJax.Node inline formula={"A_{sw}"} />, to spacing, <MathJax.Node inline formula={"s"} />, is then determined,
                  </Typography>

                  <MathJax.Node formula={Eqn16} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Let's assume <MathJax.Node inline formula={"2"} /> H10 link legs, providing <MathJax.Node inline formula={"A_{sw}=157\\:mm^2"} />, therefore the required spacing would be, 
                  </Typography>

                  <MathJax.Node formula={Eqn17} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The maximum spacing should not exceed <MathJax.Node inline formula={"0.75d=314\\:mm"} />, therefore, we will provide <MathJax.Node inline formula={"2"} /> H10 link legs at <MathJax.Node inline formula={"300\\:mm"} /> longitudinal spacing. In other words, we will specify a single H10 enclosing stirrup every <MathJax.Node inline formula={"300\\:mm"} /> along the beam.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As a final check, we can confirm we're specifying an area of steel in excess of the minimum,
                  </Typography>

                  <MathJax.Node formula={Eqn18} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore we're meeting the minimum reinforcement requirement. 
                  </Typography>

                  <Typography component="h3" className={classes.H3} > 2.3 Transverse shear design </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    The distance over which the change in longitudinal shear force is calculated, <MathJax.Node inline formula={"\\Delta x"} /> will be half the distance between the points of zero and max moment. So for a simply supported beam,
                  </Typography>

                  <MathJax.Node formula={Eqn19} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The bending moment at this location, <MathJax.Node inline formula={"M_1"} />, can be easily determined using simple statics,
                  </Typography>

                  <MathJax.Node formula={Eqn20} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore the change in moment is <MathJax.Node inline formula={"\\Delta M=197.6\\:kNm"} />. From here, we can calculate the change in longitudinal shear force, 
                  </Typography>

                  <MathJax.Node formula={Eqn21} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    The longitudinal shear stress is,
                  </Typography>

                  <MathJax.Node formula={Eqn22} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}><b>Compression strut check</b></Typography>

                  <Typography paragraph className={classes.bodytext}>
                    As we're dealing with a sagging moment, the angle of the compression strut in the flange is limited to the following range, <MathJax.Node inline formula={"26.5^{\\circ}\\leq \\theta_f \\leq 45^{\\circ}"} />. So, following the same approach we took for vertical shear, we start by determining the strut capacity for the minimum strut angle of <MathJax.Node inline formula={"26.5^{\\circ}"} />, <MathJax.Node inline formula={"v_{Rd, max, 26.5^{\\circ}}"} />. 
                  </Typography>

                  <MathJax.Node formula={Eqn23} className={classes.formula}/>

                  <MathJax.Node formula={Eqn24} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Now that we've confirmed <MathJax.Node inline formula={"v_{Ed}<v_{Ed, max, 26.5^{\\circ}}"} />, just as we did with the vertical shear analysis, we conclude that the compression strut is safe and adopt the minimum strut angle for further calculations.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}><b>Transverse reinforcement</b></Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We determine the required steel area to spacing ratio first,
                  </Typography>

                  <MathJax.Node formula={Eqn25} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    So let's try a spacing of <MathJax.Node inline formula={"300\\:mm"} /> between bars, this would require <MathJax.Node inline formula={"A_{sf}=300\\times 0.223=67\\:mm^2"} />. On this basis, we could provisionally settle on H10 bars at <MathJax.Node inline formula={"300\\:mm"} /> centres, providing an area of steel <MathJax.Node inline formula={"A_{s_prov}=262\\:mm^2/m"} />. The last thing we need to do is check <MathJax.Node inline formula={"A_{s,min}"} />,
                  </Typography>

                  <MathJax.Node formula={Eqn26} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    Therefore to satisfy minimum area requirements, we should reduce the spacing of transverse reinforcement to say <MathJax.Node inline formula={"250\\:mm"} />. This will increase the provided area of steel to <MathJax.Node inline formula={"A_{s,prov}=314\\:mm^2/m"} />. Keep in mind that, in reality, the spacing and perhaps the bar diameter used in the transverse reinforcement will be influenced by the top layer of steel specified for the slab. 
                  </Typography>                  
                                
                </Grid>

              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_31));
