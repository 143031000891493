import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import history from "../../../history";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import ToolData from '../../../Data/tools';
import ToolCard from './ToolCard'



//Custom styling
const styles = theme => ({
	paper: {
    padding: theme.spacing(5)    
	},
	header: {
		fontSize: "2.5rem"
	},
	subTitle: {
		// marginTop: theme.spacing(5),
		fontSize: 40
	},
	subsubTitle: {
		marginTop: theme.spacing(5)
	},
	subheading: {
		fontSize: "1.5rem"
	},
	bodytext: {
		fontSize: "1.1rem"
	},
	listItem: {
		paddingTop: "20px"
	}
});

class Tools extends React.Component {

  componentDidMount() {  
    window.scrollTo(0, 0);
  }

  //Handle course click
  handleProjectClick = (tool)=>{  
  // Send user to tool page
		history.push({
			pathname: "/" + tool.path,
		});
  }

  //Required when combining Matrial UI <TextField/> with redux-form
	returnToolList() {
		return ToolData.toolList.map(tool => {
          if(tool.visible){
            return (            
              <Grid item xs={12} sm={6} md={6} lg={6} xl={3} key={tool.toolId} style={{marginTop: "100px", marginBottom: "150px" }}>              
                 <ToolCard                
                  tool={tool}
                  auth={this.props.auth}
                  onClick={tool => this.handleProjectClick(tool)}
                />
              </Grid>
            );
          } else{
            return null
          }      		
      	});			   
	}


	render() {		
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={"SOLVERS"}
				>
        <Grid container justify="center" spacing={4} style={{paddingLeft:"5%", paddingRight:"5%", marginBottom:"325px"}}>                    
          {this.returnToolList()}                    
        </Grid>
        </Layout>
		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}

export default connect(mapStateToProps, actions)(withStyles(styles)(Tools));
