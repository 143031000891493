import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LectureComponent from "../../../components/common/LectureComponent"
import Typography from "@material-ui/core/Typography";
import Layout from "../../../components/common/Layout"; //Main page layout component inclusing
import CourseData from '../../../Data/courses';
import { styles } from "../../../components/common/LectureConfig";
import CodeBlock from "../../../components/common/CodeBlock"
import MathJax from "react-mathjax"; //https://codesandbox.io/s/yo646?file=/src/index.js:82-95

import img1 from "../../../images/Lectures/C19-25/img1.png";
import img2 from "../../../images/Lectures/C19-25/img2.png";
import img3 from "../../../images/Lectures/C19-25/img3.png";

const Eqn1 = `\\begin{equation}
\\boxed{V_{Rd,c} = \\left[0.12\\:k\\:(100\\rho_1f_{ck})^{1/3}\\right]b_wd}
\\end{equation}`

const Eqn2 = `\\begin{align}
k=&\\left(1+\\sqrt{\\frac{200}{d}}\\right)\\leq 2.0\\:\\:\\text{(d is in mm)}\\\\
\\rho_1 =& \\frac{A_{s1}}{b_wd}\\:\\:\\leq 0.02
\\end{align}`

const Eqn3 = `\\begin{equation}
V_{Rd,c} = 0.035\\:k^{3/2}\\sqrt{f_{ck}}\\:b_wd
\\end{equation}`

class Lecture_19_26 extends React.Component {
  state={
    course: 19,
    lecture: 26, 
    courseTitle: null   
  }

   //Load course title into state for app bar title
   componentDidMount() {       
    const course = CourseData.courseList.filter((course)=>{
      return course.courseId==this.state.course
    })     

    this.setState({
      courseTitle: course[0].title,     
    })
  }

	render() {	
    const { classes } = this.props;
		return (			
				<Layout        
					user={this.props.auth}
					onLogout={this.props.logoutRequest}
					pageTitle={this.state.courseTitle}
          menuOpenByDefault={false}
				>
          <LectureComponent
            course = {this.state.course}
            lecture = {this.state.lecture}          
          >
            <MathJax.Provider ><div>
              {/* --------------START OF LECTURE CONTENT-------------- */}                    

              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={12} md={10} >           
                  <Typography component="h2" className={classes.H2} > 1.0 Principal stresses and cracking  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Reinforced concrete structures must be designed to resist the shear forces induced by external loading. As a basis for the discussion, we will concentrate on the design of beams for vertical shear. As a starting point, consider a homogeneous, simply supported beam under the action of a uniformly distributed load, Fig 1. 
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img1} />                  
                  <figcaption className={classes.caption}>Fig 1. Simply supported beam showing isostatic lines and indicative crack pattern (top), qualitative shear force and bending moment diagrams (bottom).</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                  
                  <Typography paragraph className={classes.bodytext}>
                    The green lines overlayed ontop of the beam are called isostatic lines and indicate the direction of the principal stresses. The principal stresses are the maximum and minimum values of normal stress at a point and are developed on principal planes. As a side note; the shear stresses are zero on principal planes. If you want a refresher on 2D stress analysis, you can work through <Link to="/project-1/lecture-1" className={classes.link} target="_blank">this free Python project on stress analysis</Link>. A basic understanding of principal stresses should suffice for the following discussion. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Note that there are dashed and solid lines on the beam. The dashed lines indicate the orientation of the <b>principal tensile stresses</b> and form a <i>catenary</i> shape. The solid <i>arching</i> lines denote <b>principal compressive stresses</b>. Another feature of principal stresses is that they develop on mutually perpendicular planes. As such, the tension and compression isostatic lines intersect at <MathJax.Node inline formula={"90^\\circ"} />. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Figure 1 also shows a qualitative shear force and bending moment diagram under the beam. Note that at the mid-span position, where the moment is a maximum, the principal stresses are horizontal. Thus, the normal stresses due to bending at this location are principal stresses. At the mid-span the shear force is zero, so it makes sense that the shear stresses on a vertical plane (also a principal plane, at this position along the beam) are also zero (remember, no shear stress on principal planes). 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    If we now focus on the ends of the beam near supports, the bending moment reduces to zero and the shear force becomes a maximum. The isostatic lines are now generally orientated at an angle approaching <MathJax.Node inline formula={"45^\\circ"} /> to the horizontal. This means that the principal planes are also orientated at approximately <MathJax.Node inline formula={"45^\\circ"} />. This means that the planes on which the maximum shear stress develops are now approximately vertical, because the planes of maximum shear stress occur at <MathJax.Node inline formula={"45^\\circ"} /> to the principal planes. This makes sense to us when we observe the <u>vertical</u> shear force reaching a maximum as we move towards the supports - so the shear stress on a corresponding <u>vertical</u> plane should also be a maximum. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Now that we have a general understanding of how the orientation of the principal stresses changes and correlates with the shear force and bending moment across the beam, next, consider the infinitesimal element (pink square) identified at the intersection of two isostatic lines on the left of the beam. This is extracted and scaled up on the left of the beam. The larger 2D stress element has the principal stresses shown acting on it. Note the direction of the principal tensile stress. When this exceeds the concrete's tensile capacity (remember, about <MathJax.Node inline formula={"1/10^{th}"} /> of the compression capacity), a crack will form and propagate in the perpendicular direction. The principal tensile stresses, when large enough, serve to open up cracks in the tension zone of the beam. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    Finally, note the crack pattern shown on the beam. The cracks form perpendicular to the tensile isostatic lines because it's the principal tensile stress causing the cracks to open up. The cracks near supports, where the shear force is large, are inclined, while the cracks in the centre of the beam where the shear is low or zero but the bending moment is large, are vertical. So, although we refer to diagonal cracking near supports as shear cracking, it is the principal tensile stress that induces cracks. We think of it as shear cracking because it occurs in a zone of high shear force.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    One final point to note on cracking relates to where the cracks start. If the tensile capacity of the concrete happens to be exceeded near the mid-span of the beam, where bending is a maximum and shear is a minimum, cracking will begin at the outer surface of the beam and propagate inwards towards the neutral axis. Remember, since flexure is dominant in this region, the maximum stresses occur at the extreme fibres of the section. 
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    On the other hand, if the concrete tensile capacity is exceeded by the principal tensile stresses near the support first, where the shear force dominates (over bending), the cracks will open up first at the location of the neutral axis and propagate outwards towards the beam's bottom tension surface. Remember, the typical parabolic shear stress distribution is at a maximum at the neutral axis - hence this is the region that experiences the highest magnitude principal tensile stress. 
                  </Typography>

                  <Typography component="h2" className={classes.H2} > 2.0 Shear reinforcement </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We now have a good understanding of the stresses and resulting cracking that occurs in a beam and how this relates back to the shear force and bending moment induced by external loading. Now we can think about how to reinforce a concrete beam to withstand the induced stresses. Fig 2. below shows the same beam from Fig 1, except this time we've indicated the location of steel reinforcement with blue lines.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We note first the longitudinal (horizontal) reinforcement at the bottom of the beam. This is the main bending reinforcement we've been designing so far. This reinforcement is designed based on the maximum bending moment, in the centre of the beam in this case. Notice that it traverses the cracks. As well as resisting the tension force developed in the tension zone, it's also serving to tie the cracked concrete together, <i>bridging</i> over the cracks.
                  </Typography>                  
                                
                </Grid>

                <figure className={classes.figure}>
                  <img className={classes.imageNoAlpha} src={img2} />                  
                  <figcaption className={classes.caption}>Fig 2. Reinforced concrete beam showing, isostatic lines, crack pattern and indicative longitudinal and shear reinforcement.</figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} > 
                  <Typography paragraph className={classes.bodytext}>Figure 3 below shows a more realistic representation of a simply supported beam and its reinforcement cage. Note that the frequency of shear links is reduced in the central portion of the span where the shear force is lower.</Typography>

                  <Typography paragraph className={classes.bodytext}>Also note the presence of <i>u-bars</i> at each end of the beam. We haven’t discussed these yet. The purpose of these bars is to anchor the main tension reinforcement and resist the bar’s tendency to <i>pull out</i> of the concrete block. For clarity, the side-face bars have been ommited.</Typography>
                </Grid>

                <figure style={{width:"80%"}}>
                  <img className={classes.imageNoAlpha} src={img3} />                  
                  <figcaption className={classes.caption}>Fig 3. </figcaption>
                </figure>

                <Grid item xs={12} sm={12} md={10} >                     

                  <Typography component="h2" className={classes.H2} > 3.0 Concrete shear capacity without shear reinforcement </Typography>               

                  <Typography paragraph className={classes.bodytext}>
                    Before we cover how to design shear reinforcement, we'll first briefly cover the shear capacity of concrete without shear reinforcement. Although we will never design a beam with no shear reinforcement, reinforced concrete slabs (covered in a later course) will routinely rely on the concrete's inherent shear capacity, <MathJax.Node inline formula={"V_{Rd,c}"} />.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                  Note that the uppercase <MathJax.Node inline formula={"V"} /> indicates a force whereas a lowercase <MathJax.Node inline formula={"v"} /> indicates a stress. We denote the design shear force at the ultimate limit state as <MathJax.Node inline formula={"V_{Ed}"} />. Technically, if <MathJax.Node inline formula={"V_{Rd,c}>V_{Ed}"} /> no shear reinforcement is required by design. However, for practical purposes, when designing beams a nominal area of shear reinforcement is always provided even when <MathJax.Node inline formula={"V_{Rd,c}>V_{Ed}"} />. To practically build steel reinforcement cages, at least one enclosing shear link will always be provided.
                  </Typography>

                  <Typography paragraph className={classes.bodytext}>
                    We can calculate <MathJax.Node inline formula={"V_{Rd,c}"} />, the design shear resistance of the concrete section without shear reinforcement, using the following <b>empirical</b> equations:
                  </Typography>

                  <MathJax.Node formula={Eqn1} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    where
                  </Typography>

                  <MathJax.Node formula={Eqn2} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                  <MathJax.Node inline formula={"A_{s1}"} /> is the area of tensile reinforcement that extends beyond the section being considered by at least a full anchorage length (discussed later) plus one effective depth, <MathJax.Node inline formula={"d"} />. <MathJax.Node inline formula={"b_w"} /> is the smallest width of the section in the tensile region (typically the width of the beam web). Note that <MathJax.Node inline formula={"V_{Rd,c}"} /> should not be less than:
                  </Typography>

                  <MathJax.Node formula={Eqn3} className={classes.formula}/>

                  <Typography paragraph className={classes.bodytext}>
                    We'll leave this to one side for now and focus in the following lecture on how to calculate the area and longitudinal spacing of shear reinforcement. 
                  </Typography> 
                </Grid>
              </Grid>
            
            {/* --------------END OF LECTURE CONTENT-------------- */}        
            </div></MathJax.Provider>
          </LectureComponent>
        </Layout>		
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}
export default connect(mapStateToProps, actions)(withStyles(styles)(Lecture_19_26));
